import * as React from 'react';
import axios from "axios";
import config from "config.js"
import Collapse from "@material-ui/core/Collapse";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import moment from 'moment';
import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";

import Form from "./Components/SchoolForm.js"
import StudentList from './Components/StudentList.js';
import SchoolDetails from './Components/SchoolDetails.js';

const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
}));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function Schools() {
    const classes = useStyles();
    const columns = [
        {
            field: "updatedAt",
            title: "Date",
            render: rowData => moment(rowData.createdAt).format("DD/MM/YYYY") != "Invalid date" ? <span> {moment(rowData.createdAt).format("DD/MM/YYYY") + " " + moment(rowData.createdAt).format("hh:mm:ss A")}</span> : rowData.time,
        },
        {
            field: "name",
            title: "Name",
        },
        {
            field: "branch",
            title: "Branch",
        },
        {
            field: "contact_number",
            title: "Contact Number",
        }
    ];
    const options = {
        showTitle: false,
        actionsColumnIndex: -1,
        exportButton: false,
    };



    const Actions = [
        {
            icon: "visibility_icon",
            tooltip: 'View Details',
            onClick: (event, rowData) => viewSchool(rowData._id),
        },
        {
            icon: "list_lt_icon",
            tooltip: 'View students',
            onClick: (event, rowData) => viewStudents(rowData._id),
        },
        {
            icon: "edit_icon",
            tooltip: 'Edit',
            onClick: (event, rowData) => editSchool(rowData._id),
        },

    ]



    const [schools, setSchools] = React.useState([])
    const [snackBarType, setSnackBarType] = React.useState("error");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [respAlert, setRespAlert] = React.useState(null);

    const [schoolForm, setSchoolForm] = React.useState(null);
    const [studentList, setStudentList] = React.useState(null);


    const [loader, setLoader] = React.useState(true)
    const [schoolsList, setSchoolList] = React.useState(false)
    const [form, setForm] = React.useState(false)
    const [studentsList, setStudentsList] = React.useState(false)


    function openSnackBar(msg, success) {
        //function to tost snackbar message
        setOpenSnack(true);
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }


    const openAlert = (type, title, text) => {
        //function to open sweet alert
        setRespAlert(
            <SweetAlert
                type={type}
                style={{ display: "block" }}
                title={title}
                onConfirm={() => {
                    setRespAlert(null);
                    backToHome()
                }}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                {text}
            </SweetAlert>)
    }

    const backToHome = () => {
        //function to come to home
        updateView(1)
        setSchoolForm(null)
        setStudentList(null)
        fetchSchools()
    }


    const fetchSchools = async (initial) => {
        //function to fetch all schools
        try {
            let resp = await axios.get(`${config.url().baseURL}/fetch-all-schools`)
            if (resp.data.err) openSnackBar(resp.data.msg)
            else {
                if (initial) updateView(1)
                setSchools(resp.data.data)
            }
        } catch (error) {
            openSnackBar("Unable to fetch schools.")
        }
    }



    const addNewSchool = async () => {
        //function to open form
        setSchoolForm(<Form snack={openSnackBar} alert={openAlert} back={backToHome} />)
        updateView(2)
    }

    const editSchool = async (id) => {
        //function to handle edit school click
        setSchoolForm(<Form snack={openSnackBar} alert={openAlert} back={backToHome} edit={true} id={id} />)
        updateView(2)
    }

    const viewSchool = async (id) => {
        //function to handle edit school click
        setSchoolForm(<Form snack={openSnackBar} alert={openAlert} back={backToHome} view={true} id={id} />)
        updateView(2)
    }
    const viewStudents = async (id) => {
        //function to view student list
        setStudentList(<StudentList snack={openSnackBar} alert={openAlert} back={backToHome} id={id} />)
        updateView(3)
    }



    const updateView = (id) => {
        //function to update view
        //0:-loader , 1:-school table, 2:-schoolForm, 3:- studentList
        console.log(id)
        switch (id) {
            case 0:
                setLoader(true)
                setSchoolList(false)
                setForm(false)
                setStudentsList(false)
                break;
            case 1:
                setLoader(false)
                setSchoolList(true)
                setForm(false)
                setStudentsList(false)
                break;
            case 2:
                setLoader(false)
                setSchoolList(false)
                setForm(true)
                setStudentsList(false)
                break;
            case 3:
                setLoader(false)
                setSchoolList(false)
                setForm(false)
                setStudentsList(true)
                break;
            case 4:
                setLoader(false)
                setSchoolList(false)
                setForm(false)
                setStudentsList(false)
                break;
            default:
                break;
        }
    }


    React.useEffect(() => { fetchSchools(true) }, [])
    return (
        <>
            {respAlert}
            <Collapse
                in={loader}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
            </Collapse>
            <Collapse
                in={schoolsList}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Schools</h4>
                        {/* {
                                addBtn ?
                                    <Button onClick={newAdjustment} color="secondary" size="sm" className={classes.addButton}>
                                        New Adjustment
                                    </Button>
                                    :
                                    null
                            } */}
                        <Button color="secondary" onClick={addNewSchool} size="sm" className={classes.addButton}>
                            New School
                        </Button>
                    </CardHeader>
                    <CardBody>
                        <MaterialTable components={{ Container: (props) => <Paper {...props} elevation={0} /> }} data={schools} columns={columns} options={options} actions={Actions} />
                    </CardBody>
                </Card>
            </Collapse>
            <Collapse
                in={form}
                style={{ transformOrigin: "0 0 0" }}
                {...(true ? { timeout: 500 } : {})}>
                {schoolForm}
            </Collapse>
            <Collapse
                in={studentsList}
                style={{ transformOrigin: "0 0 0" }}
                {...(true ? { timeout: 500 } : {})}>
                {studentList}
            </Collapse>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </>
    );
}