import * as React from 'react';
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import Collapse from "@material-ui/core/Collapse";
import { useParams } from 'react-router-dom'

const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;

export default function CardPinChange() {
    const urlParams = useParams()
    const [userDetails, setUserDetails] = React.useState(null);

    // React.useEffect(() => {
    //     getUrlData();
    // }, []);

    // const getUrlData = () => {
    //     //function to get url params
    //     const { Card, client, SessID, Mobile, Lang } = urlParams;
    //     const details = { Card, client, SessID, Mobile, Lang, }
    //     setUserDetails(details)
    //     console.log(`http://192.168.120.186:2230/PinVerification.aspx?Card=${details.Card}&client=${details.client}&SessID=${details.SessID}&Mobile=${details.Mobile}&Lang=${details.Lang}`)
    // }


    return (
        <div>
            <Collapse
                in={userDetails == null}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
            </Collapse>
            <Collapse
                in={userDetails}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                {
                    userDetails ?
                        <iframe width="100%" height="100%" src={`http://192.168.120.186:2230/PinVerification.aspx?Card=${userDetails.Card}&client=${userDetails.client}&SessID=${userDetails.SessID}&Mobile=${userDetails.Mobile}&Lang=${userDetails.Lang}`} />
                        : null
                }

            </Collapse>
        </div>
    );
}