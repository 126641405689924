import * as React from 'react';
import axios from "axios";
import config from "config.js"
import DropZone from 'react-dropzone';
import Button from "components/CustomButtons/Button.js";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import bell from 'variables/bell.mp3';
import Collapse from "@material-ui/core/Collapse";


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

export default function IdUpload(props) {
    const [uploadStatus, setUploadStatus] = React.useState(0);
    const [failedDocs, setFailedDocs] = React.useState([]);

    const [progress, setProgress] = React.useState(0);
    const [filesCount, setFilesCount] = React.useState(0);
    const [uploaded, setUploaded] = React.useState(0);


    const employeeIdUpload = async (images) => {
        setUploadStatus(1);
        let formSent = new FormData();
        let addedCount = 0
        let uploadedFiles = 0
        let totalFiles = 0
        let uploadingFiles = []
        if (images.length > 100) {
            props.openSnackBar(`Selected 100 documents out of  ${images.length}.`, true)
            setFilesCount(100)
            totalFiles = 100
            images.splice(100)
        }
        else {
            totalFiles = images.length
            setFilesCount(images.length)
            props.openSnackBar(`Selected ${images.length} documents.`, true)
        }

        const calculateProgress = async (count) => {
            addedCount = 0
            formSent = new FormData();
            uploadedFiles = uploadedFiles + count
            setUploaded(uploadedFiles)
            let percentage = (uploadedFiles / totalFiles) * 100
            setProgress(percentage)
            if (totalFiles == uploadedFiles) {
                setUploadStatus(2)
                props.openSnackBar(`${uploadedFiles} documents uploaded.`, true)
                new Audio(bell).play();
            }
        }

        for (let index = 0; index < images.length; index++) {
            let doc = images[index]
            if (/\.(jpe?g|png|gif|pdf)$/.test(doc.name) === true && doc.size < 2097152) {
                formSent.append('id', doc)
                uploadingFiles.push(doc.name)
                addedCount++
            }
            else props.openSnackBar("Invalid files found.")

            if (images.length == 1 || ((images.length % 2) == 1 && index + 1 == images.length)) {
                try {
                    let resp = await axios({ method: 'post', url: `${config.url().baseURL}/employee-id-upload/${props.batchEmpId}`, data: formSent, maxContentLength: Infinity, maxBodyLength: Infinity })
                    if (!resp['data']['err']) {
                        await calculateProgress(1)
                    } else {
                        props.openSnackBar(`Unable to upload ${uploadingFiles.toString()}, ${resp['data']['msg']}`);
                        let tempFiles = failedDocs
                        tempFiles.push(uploadingFiles)
                        setFailedDocs(tempFiles)
                        await calculateProgress(1)
                    }
                } catch (error) {
                    props.openSnackBar(`Unable to upload ${uploadingFiles.toString()}`);
                    await calculateProgress(1)
                }
            } else if (addedCount == 2) {
                try {
                    let resp = await axios({ method: 'post', url: `${config.url().baseURL}/employee-id-upload/${props.batchEmpId}`, data: formSent, maxContentLength: Infinity, maxBodyLength: Infinity })
                    if (!resp['data']['err']) {
                        await calculateProgress(2)
                    } else {
                        props.openSnackBar(`Unable to upload ${uploadingFiles.toString()}, ${resp['data']['msg']}`);
                        let tempFiles = failedDocs
                        tempFiles.push(uploadingFiles)
                        setFailedDocs(tempFiles)
                        await calculateProgress(2)
                    }
                } catch (error) {
                    props.openSnackBar(`Unable to upload ${uploadingFiles.toString()}`);
                    let tempFiles = failedDocs
                    tempFiles.push(uploadingFiles)
                    setFailedDocs(tempFiles)
                    await calculateProgress(2)
                }
            }

        }
    }
    return (
        <>
            <Collapse
                in={uploadStatus == 0}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <h3>Upload IDs Of Employees</h3>
                <DropZone onDrop={employeeIdUpload} >
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button size="sm" color="primary">
                                <strong>Upload</strong>
                            </Button>
                        </div>
                    )}
                </DropZone>
            </Collapse>
            <Collapse
                in={uploadStatus == 1}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <h3>Uploading Documents</h3>
                <BorderLinearProgress variant="determinate" value={progress} />
                <h4>Uploaded {uploaded}/{filesCount}</h4>
            </Collapse>

            <Collapse
                in={uploadStatus == 2}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <h3>{filesCount - failedDocs.length} Documents are uploaded </h3>
                {
                    failedDocs.length != 0 ? <h3>{failedDocs.toString()} Documents failed to upload. </h3> : null
                }
                <Button size="md" onClick={() => { setUploadStatus(0); setProgress(0) }} color="success">
                    Ok
                </Button>
            </Collapse>




        </>
    );
}