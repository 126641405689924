import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

const ImageUpload = ({ uploadedImage ,label,onDelete}) => {
    return (
        <div>
            {uploadedImage && (
                <div>
                    {typeof uploadedImage === "object" ? (
                        <img
                            src={URL.createObjectURL(uploadedImage)}
                            alt="Uploaded"
                            style={{ maxWidth: '90%', height: '90%' }}
                        />
                    ) : (
                       <div>
                         <img
                            src={uploadedImage}
                            alt="Uploaded"
                            style={{ maxWidth: '90%', height: '90%' }}
                        />
                        {/* <label>{label}</label> */}
                       </div>
                    )}                   
                    <div>
                        <DeleteIcon color='error' onClick={onDelete}>Delete</DeleteIcon>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageUpload;