import * as React from 'react';
import axios from "axios";
import config from "config.js"
import Collapse from "@material-ui/core/Collapse";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import moment from 'moment';
import Paper from "@material-ui/core/Paper";
import MainForm from './Components/MainForm';
import MaterialTable from "material-table";
import { Modal } from '@mui/material';
//import MainForm from './Components/MainForm';

import './Components/school.css'

import Form from "./Components/SchoolForm.js"
import BfcForm from "./Components/BfcForm.js"
import StudentList from './Components/BillerList.js';
import SchoolDetails from './Components/SchoolDetails.js';
import ProductForm from './Components/BfcBillerForm';
import Switch from '@mui/material/Switch';
import { Dialog } from '@mui/material';
import { DialogActions, MenuItem, Select, TextField } from '@material-ui/core';

const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
}));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function BillerEdit() {


  

    const classes = useStyles();
    const columns = [
        // {
        //     field: "updatedAt",
        //     title: "Date",
        //     render: rowData => moment(rowData.createdAt).format("DD/MM/YYYY") != "Invalid date" ? <span> {moment(rowData.createdAt).format("DD/MM/YYYY") + " " + moment(rowData.createdAt).format("hh:mm:ss A")}</span> : rowData.time,
        // },
        {
            field: "name",
            title: "Name",
        },
        {
            field: "issuerId",
            title: "Issuer Id",
        },
        {
            field: "issuerName",
            title: "Issuer Name",
        },
        {
            field: "status",
            title: "Status",
        }
        // {
        //     field: 'inHome',
        //     headerName: 'In Home',
        //     type: 'boolean',
        //     width: 120,
        //     renderCell: (params) => (
        //       <Switch

        //         // onChange={(event) => handleToggleInHome(event, params.row)}
        //         color="primary"
        //         disabled={!params.row.isActive}
        //       />
        //     ),
        //   },
        // {
        //     field: 'isActive',
        //     title: 'Active',
        //     type: 'boolean',
        //     width: 120,
        //     renderCell: (params) => (
        //         <Switch checked={params.value} color="primary" />
        //     ),
        // },
    ];
    const options = {
        showTitle: false,
        actionsColumnIndex: -1,
        exportButton: false,
    };



    const Actions = [
        // {
        //     icon: "visibility_icon",
        //     tooltip: 'View Details',
        //     onClick: (event, rowData) => viewSchool(rowData.billRef),
        // },
        {
            icon: "visibility_icon",
            tooltip: 'view',
            onClick: (event, rowData) => {
                editBiller(rowData.billRef)
                
            },
        },
        // {
        //     icon: "list_lt_icon",
        //     tooltip: 'View students',
        //     onClick: (event, rowData) => viewStudents(rowData),
        // },

        {
            icon:"delete_icon",
            tooltip: 'Delete Biller',
            onClick: (event, rowData) => {
                deleteBiller(rowData.billRef)
                
            },
        }
        
        // {
        //     icon: ({ rowData }) => (
        //       <Switch
        //         checked={rowData.inHome}
        //         onChange={(event) => handleToggleInHome(event, rowData)}
        //         color="primary"
        //         disabled={!rowData.isActive}
        //       />
        //     ),
        //     tooltip: 'Toggle In Home',
        //     onClick: (event, rowData) => handleToggleInHome(event, rowData),
        //   },
        //   {
        //     icon: ({ rowData }) => (
        //       <Switch
        //         checked={rowData.isActive}
        //         onChange={(event) => handleToggleActive(event, rowData)}
        //         color="primary"
        //       />
        //     ),
        //     tooltip: 'Toggle Active',
        //     onClick: (event, rowData) => handleToggleActive(event, rowData),
        //   },



    ]


    const handleToggleInHome = (event, rowData) => {
        // Function to handle toggling In Home
        // Implement your logic here
    };

    const handleToggleActive = (event, rowData) => {
        // Function to handle toggling Active
        // Implement your logic here
    };
    const [schools, setSchools] = React.useState([])
    const [snackBarType, setSnackBarType] = React.useState("error");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [respAlert, setRespAlert] = React.useState(null);

    const [schoolForm, setSchoolForm] = React.useState(null);
    const [studentList, setStudentList] = React.useState(null);


    const [loader, setLoader] = React.useState(true)
    const [schoolsList, setSchoolList] = React.useState(false)
    const [form, setForm] = React.useState(false)
    const [studentsList, setStudentsList] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const [billerType, setBillerType] = React.useState('BFC_Pay')
    const [billerTypeText, setBillerTypeText] = React.useState('BFC_Pay')
    const [billerList, setBillerList] = React.useState(['BFC_Pay', 'PIE', 'Sadad', 'Other'])
    const [child, setChild] = React.useState()
    function openSnackBar(msg, success) {
        //function to tost snackbar message
        setOpenSnack(true);
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg)?.replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }


    const openAlert = (type, title, text) => {
        //function to open sweet alert
        setRespAlert(
            <SweetAlert
                type={type}
                style={{ display: "block" }}
                title={title}
                onConfirm={() => {
                    setRespAlert(null);
                    backToHome()
                }}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                {text}
            </SweetAlert>)
    }

    const backToHome = () => {
        //function to come to home
        updateView(1)
        setChild(null)
        setStudentList(null)
        fetchSchools()
    }

    const handleOpen = () => {
        setOpen(true);

    };
    const handleCheckView = () => {
        if (billerTypeText === 'BFC_Pay') {
            setSchoolForm(<BfcForm snack={openSnackBar} alert={openAlert} back={backToHome} billerType={billerTypeText} />)
            updateView(2)
        } else {
            setSchoolForm(<Form snack={openSnackBar} alert={openAlert} back={backToHome} billerType={billerTypeText} />)
            updateView(2)
        }
    }

    const handleClose = () => {
        setOpen(false);
        setForm(true)
        // setSchoolForm(<BfcForm snack={openSnackBar} alert={openAlert} back={backToHome}billerType={billerTypeText} />)
        handleCheckView()

    };

    // const handleSubmit = async () => {
    //    // setOpen(false)
    //     setChild(<MainForm type={billerTypeText} back={backToHome} snack={openSnackBar} alertPop={openAlert} />)
    // }

    const editBiller = async(billRef)=>{
        
        setChild(<MainForm type={"Edit"} back={backToHome} snack={openSnackBar} alertPop={openAlert} billRef={billRef} />)
        updateView(2)
    }

    const deleteBiller = async(billRef)=>{
        console.log(billRef);
        try {
            const resp = await axios.post(`${config.url().app_URL}/bill/remove-biller`,{billRef})
            console.log(resp);
            if(resp.data.status.err){
                openSnackBar(resp.data.status.message)
            }else{
                fetchSchools()
                openAlert("success", "Biller data Deleted", resp.data.status.message)
            }
        } catch (err) {
            console.log(err);
            openSnackBar("Something Went Wrong Please Try Again")
        }
    }


    const fetchSchools = async (initial) => {
        //function to fetch all schools
        try {
            const resp = await axios.get(`${config.url().app_URL}/bill/get-all-category`)
            console.log(resp);
            if (resp.data.status.err) openSnackBar(resp?.data?.status.message)
            else {
                if (initial) updateView(1)
                setSchools(resp.data.data)
            }
        } catch (error) {
            openSnackBar("Unable to fetch biller.")
        }
    }



    const addNewSchool = async () => {
        //function to open form
        handleOpen()

    }


    const editSchool = async (id) => {
        //function to handle edit school click
        setSchoolForm(<Form snack={openSnackBar} alert={openAlert} back={backToHome} edit={true} id={id} />)
        updateView(2)
    }

    const viewSchool = async (id) => {
        //function to handle edit school click
        setSchoolForm(<ProductForm snack={openSnackBar} alert={openAlert} back={backToHome} view={true} id={id} />)
        updateView(2)
    }
    const viewStudents = async (rowData) => {
        //function to view student list
        setChild(<StudentList snack={openSnackBar} alert={openAlert} back={backToHome} billRef={rowData.billRef} productCode={rowData.productCode} />)
        updateView(3)
    }



    const updateView = (id) => {
        //function to update view
        //0:-loader , 1:-school table, 2:-schoolForm, 3:- studentList
        console.log(id)
        switch (id) {
            case 0:
                setLoader(true)
                setSchoolList(false)
                setForm(false)
                setStudentsList(false)
                break;
            case 1:
                setLoader(false)
                setSchoolList(true)
                setForm(false)
                setStudentsList(false)
                break;
            case 2:
                setLoader(false)
                setSchoolList(false)
                setForm(true)
                setStudentsList(false)
                break;
            case 3:
                setLoader(false)
                setSchoolList(false)
                setForm(false)
                setStudentsList(true)
                break;
            case 4:
                setLoader(false)
                setSchoolList(false)
                setForm(false)
                setStudentsList(false)
                break;
            default:
                break;
        }
    }


    React.useEffect(() => { fetchSchools(true) }, [])
    return (
        <>
            {respAlert}
            <Collapse
                in={loader}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
            </Collapse>
            <Collapse
                in={!child && !loader}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Biller</h4>
                        {/* {
                                addBtn ?
                                    <Button onClick={newAdjustment} color="secondary" size="sm" className={classes.addButton}>
                                        New Adjustment
                                    </Button>
                                    :
                                    null
                            } */}
                        {/* <Button color="secondary" onClick={handleSubmit} size="sm" className={classes.addButton}>
                            New Biller
                        </Button> */}
                    </CardHeader>
                    <CardBody>
                        <MaterialTable components={{ Container: (props) => <Paper {...props} elevation={0} /> }} data={schools} columns={columns} options={options} actions={Actions} />
                    </CardBody>
                </Card>

            </Collapse>
            <Collapse
                in={child}
                style={{ transformOrigin: "0 0 0" }}
                {...(true ? { timeout: 500 } : {})}>
                {child}
            </Collapse>

            {/* <Modal open={open} onClose={() => { setOpen(false) }}>
                <Card style={{ width: 400, margin: '0 auto', marginTop: 100 }}>
                    <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20 }}>
                        <h4 style={{ textAlign: 'center', marginBottom: 20  ,fontWeight:"bold"}}>Choose the Biller Type</h4>
                        <div style={{ marginBottom: 20, padding: 30 }}>
                            <Select
                                fullWidth
                                style={{ minWidth: 250 }}
                                labelId="Contact Select"
                                variant='outlined'
                                value={billerType}
                                label="Select Contact"
                                onChange={(e) => {
                                    setBillerType(e.target.value);
                                    setBillerTypeText(e.target.value)
                                }}
                            >
                                {billerList.map((data) => (
                                    <MenuItem key={data} value={data}>{data}</MenuItem>
                                ))}
                            </Select>
                            {billerType === "Other" && (
                                <TextField
                                    label="Plese Enter the TranCode"
                                    value={billerTypeText}
                                    onChange={e => setBillerTypeText(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />

                            )}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 'auto' }}>
                            <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleSubmit}>
                                Submit
                            </Button>

                        </div>
                    </CardBody>
                </Card>
            </Modal> */}

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>

        </>
    );
}
