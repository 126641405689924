import * as React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Collapse from '@material-ui/core/Collapse'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PropagateLoader from 'react-spinners/PropagateLoader'
import MUIDataTable from 'mui-datatables'
import { InputBase } from '@material-ui/core'
import moment from 'moment'
import { Paper } from '@material-ui/core'
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import SimpleButton from '@mui/lab/LoadingButton';
import download from "js-file-download";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import SearchIcon from '@material-ui/icons/Search';
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import { creatCsvFile, downloadFile } from "download-csv";
import MuiAlert from "@material-ui/lab/Alert";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from '@material-ui/icons/Replay';
import config from "config.js"
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SweetAlert from "react-bootstrap-sweetalert";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DownloadIcon from '@mui/icons-material/Download';
import { createTheme } from '@mui/material/styles';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import SendIcon from '@mui/icons-material/Search';
import BounceLoader from 'react-spinners/BounceLoader'



const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;
const theme = createTheme({

    primary: {
        light: '#757ce8',
        main: '#3f50b5',
        dark: '#002884',
        contrastText: '#fff',
    },

})
const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    companyStatusLogModal12: {
        margin: "auto",
        minWidth: "800px",
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
export default function MultiCurrencyReport() {
    const classes = useStyles();
    const [showLoader, setShowLoader] = useState(false);
    const [showStatementTable, setShowStatementTable] = useState(true)
    const [fromDate, setFromDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
    const [toDate, setToDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
    const [openSnack, setOpenSnack] = useState(false)
    const [snackBarMsg, setSnackBarMsg] = useState("")
    const [snackBarType, setSnackBarType] = useState("error")
    const [csvData, setCsvData] = React.useState(false);
    const [respAlert, setRespAlert] = React.useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [statementDetails, setStatementDetails] = useState([])
    const [CR, setCR] = useState("")
    const [searchKey, setSearchKey] = useState("")
    const [listDetails, setListDetails] = useState([])
    const [customersList, setCustomerData] = React.useState([]);

    const columns = [
        {
            name: "deviceID",
            label: "Device ID"
        },
        {
            name: "manufacturer",
            label: "Manufacturer Name"
        },
        {
            name: "modelName",
            label: "Model Name"
        },
        {
            name: "initialBinding",
            label: "First Binding Date"
        },
        {
            name: "linkedCpr",
            label: "Linked CPR"
        },
        {
            name: "walletType",
            label: "WalletType"
        },
        {
            name: "latestBinding",
            label: "Latest Binding Date"
        }
    ];
    const options = {
        selectableRows: false,
        elevation: 0,
        print: false,
        onDownload: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = " Statement Request Details.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        }
    };
    React.useEffect(() => {
        listRewardsDetails()
    }, [])


    const downloadReport = (path, name) => {
        try {
            axios({
                url: path,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                const filename = `${name}.pdf`;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            });
        }
        catch (error) {
            openSnackBar("Unable to download the report")
        }
    }

    const validateSearch = () => {
        //function validate search params
        let snackBarMsg = ""
        let flag = true
        if (fromDate.length == 0) {
            flag = false
            snackBarMsg = "Please select from date."
        }
        if (toDate.length == 0) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select To date." : snackBarMsg);
        }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }
    const listRewardsDetails = () => {
        setShowLoader(true)
        axios.post(`${config.url().baseURL}/device-binding-report`, { "startDate": fromDate, "endDate": toDate }).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                setShowLoader(false)
                let updatedDetails = []
                let statementType;
                console.log(searchKey)
                respData.data.map((details) => {
                    console.log(details.activeDeviceDetails.manufacturer, "Log");
                    let obj = {
                        deviceID: details.activeDeviceDetails.deviceID,
                        manufacturer: details.activeDeviceDetails.manufacturer,
                        modelName: details.activeDeviceDetails.model,
                        initialBinding: moment(details.initialBinding).format("MM-DD-YYYY,h:mm:ss "),
                        linkedCpr: details.cpr,
                        walletType: details.walletType,
                        latestBinding: moment(details.latestBinding).format("MM-DD-YYYY,h:mm:ss ")
                    };
                    updatedDetails.push(obj)
                })
                setListDetails(updatedDetails)

                console.log(listDetails, 'listDetails')
                var downloadedData = respData.data.map((details) => {
                    let temp = {};
                    temp.Device_ID = details.activeDeviceDetails.deviceID ? details.activeDeviceDetails.deviceID : "";
                    temp.Manufacturer_Name = details.activeDeviceDetails.manufacturer ? details.activeDeviceDetails.manufacturer : "";
                    temp.Model_Name = details.activeDeviceDetails.model ? details.activeDeviceDetails.model : "";
                    temp.FirstBinding_Date = moment(details.createdAt).format("MM-DD-YYYY,h:mm:ss ")
                    temp.LinkedCpr = details.cpr ? details.cpr : "";
                    temp.LatestBinding_Date = moment(details.latestBinding).format("MM-DD-YYYY,h:mm:ss ")
                    return temp;
                })
                setCsvData(downloadedData);

                // resetSearch()
            }
            else {
                setShowLoader(false)
                setListDetails([]);
                openSnackBar(respData.msg);
            }

        })
            .catch((err) => {
                setShowLoader(false)
                setListDetails([]);
                openSnackBar("Unable to process");

            });


    }
    const searchStatementsDetails = () => {
        try {
            if (validateSearch()) {

                setShowLoader(true);
                setSubmitting(true);
                setShowStatementTable(false);
                setStatementDetails([])
                let data = {
                    "startDate": moment(fromDate).format("MM-DD-YYYY"),
                    "endDate": moment(toDate).format("MM-DD-YYYY")
                }
                axios.post(`${config.url().baseURL}/device-binding-report`, data)
                    .then((resp) => {
                        setShowLoader(false);
                        setSubmitting(false);
                        setShowStatementTable(true)
                        let respData = resp.data
                        if (!respData.err) {
                            setShowLoader(false)
                            let updatedDetails = []
                            respData.data.map((details) => {
                                details.deviceID = details.activeDeviceDetails.deviceID
                                details.manufacturer = details.activeDeviceDetails.brand
                                details.modelName = details.activeDeviceDetails.model
                                details.initialBinding = moment(details.initialBinding).format("MM-DD-YYYY, h:mm:ss a")
                                details.linkedCpr = details.cpr
                                details.walletType = details.walletType
                                details.latestBinding = moment(details.latestBinding).format("MM-DD-YYYY, h:mm:ss a")
                                updatedDetails.push(details)
                            })
                            setListDetails(updatedDetails)

                        }
                        var downloadedData = respData.data.map((details) => {
                            let temp = {};
                            temp.Device_ID = details.activeDeviceDetails.deviceID ? details.activeDeviceDetails.deviceID : "";
                            temp.Manufacturer_Name = details.activeDeviceDetails.brand ? details.activeDeviceDetails.brand : ""
                            temp.Model_Name = details.activeDeviceDetails.model ? details.activeDeviceDetails.model : ""
                            temp.First_Binding_Date = details.initialBinding ? details.initialBinding : "";
                            temp.Linked_CPR = details.cpr ? details.cpr : "";
                            temp.WalletType = details.walletType ? details.walletType : ""
                            temp.Latest_Binding_Date = details.latestBinding ? details.latestBinding : ""


                            return temp;
                        })
                        setCsvData(downloadedData);

                    })
                    .catch((err) => {
                        openSnackBar("Unable to process")
                    })
            }
        }
        catch (error) {
            openSnackBar("Unable to fetch the details")

        }
    }
    function openSnackBar(msg, success) {
        setOpenSnack(true)
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const searchCustomer = () => {
        if (searchKey.length !== 0) {
            let data = {
                searchKey
            }
            axios.post(`${config.url().baseURL}/device-binding-report`, data).then((resp) => {
                let respData = resp.data;
                console.log(resp.data,"device")
                if (!respData.err) {
                    let data = respData.data[0]
                    let updatedDetails = []
                    data.devices.map((device) => {
                        device.deviceID = device.deviceID
                        device.manufacturer = device.brand
                        device.modelName = device.model
                        device.initialBinding = device.isActive ? moment(data.initialBinding).format("DD-MM-YYYY, h:mm:ss a") : moment(device.createdAt).format("DD-MM-YYYY, h:mm:ss a")
                        device.linkedCpr = data.cpr
                        device.walletType = data.walletType
                        device.latestBinding = device.isActive ? moment(data.latestBinding).format("DD-MM-YYYY, h:mm:ss a") : moment(device.createdAt).format("DD-MM-YYYY, h:mm:ss a")
                        updatedDetails.push(device)
                    })
                    setListDetails(updatedDetails)
                } else {
                    setListDetails([]);
                    openSnackBar(respData.msg);
                }
            }).catch((err) => {
                setListDetails([]);
                searchStatementsDetails()
                openSnackBar("Unable to process");

            });
        }
    }


    return (
        <div>{respAlert}
            <Collapse in={showStatementTable} style={{ transformOrigin: "0 0 0", textAlign: "center" }} {...(true ? { timeout: 500 } : {})}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={6} autoComplete="off">
                        <Paper elevation={1} className={classes.addDeptContainer}>
                            <InputBase
                                type='search'
                                fullWidth
                                className={classes.input}
                                placeholder="Search"
                                inputProps={{
                                    "aria-label": "Search",
                                    autoComplete: "off",
                                    autoFocus: "off",
                                    onChange: (event) => { setSearchKey(event.target.value) },
                                    onBlur: (event) => { setSearchKey(event.target.value.trim()) },
                                    value: searchKey,
                                    onKeyDown: (e) => {
                                        if (e.key == "Enter") {
                                            searchCustomer()
                                        }
                                    }
                                }}
                            />
                            {!submitting ? (
                                <Button className={classes.addDeptButton}
                                    onClick={() => searchCustomer()}
                                    size="lg" color="primary">
                                    <SearchIcon fontSize="medium" />
                                </Button>
                            ) : (
                                <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
                            )}
                        </Paper>
                        {/* <Tooltip placement="bottom" title="Reset">
                            <IconButton className={classes.addDeptButton}
                                onClick={() => resetSearch()}
                                size="lg" color="primary">
                                <RotateLeftIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip> */}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            {/* <CardBody>
                                {s}
                            </CardBody> */}
                        </Card>
                    </GridItem>
                </GridContainer>
            </Collapse>

            <GridContainer justify='center'>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={true}
                        style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                        {...true ? { timeout: 500 } : {}}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>From Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setFromDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setFromDate("");
                                                    openSnackBar("Please enter a valid Date")
                                                } else {
                                                    setFromDate(event.target.value)
                                                }
                                            },
                                            value: fromDate,
                                        }}>
                                    </InputBase>
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>To Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setToDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setToDate("")
                                                    openSnackBar("Please enter valid date.")
                                                } else {
                                                    setToDate(event.target.value)
                                                }
                                            }, value: toDate,
                                        }}
                                    />
                                    {!submitting ? (
                                        <Button className={classes.addDeptButton}
                                            onClick={() => searchStatementsDetails()}
                                            size="lg" color="primary">
                                            <SearchIcon fontSize="medium" />
                                        </Button>
                                    ) : (
                                        <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
                                    )}
                                </Paper>
                            </GridItem>
                        </GridContainer>
                    </Collapse>

                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{
                            transformOrigin: "0 0 0", textAlign: 'center'
                        }}
                        {...true ? { timeout: 500 } : {}}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showStatementTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...true ? { timeout: 500 } : {}}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Device Binding Report</h4>
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={listDetails} columns={columns} options={options} />
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    )
} 