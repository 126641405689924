import React from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import moment from "moment";
import config from "config.js"
import { useSelector } from 'react-redux';
import PropagateLoader from "react-spinners/PropagateLoader";
import SweetAlert from "react-bootstrap-sweetalert";
import { css } from "@emotion/core";
import { creatCsvFile, downloadFile } from "download-csv";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Paper from "@material-ui/core/Paper";
import SearchIcon from '@material-ui/icons/Search';
import InputBase from "@material-ui/core/InputBase";
import BounceLoader from "react-spinners/BounceLoader";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Table from "components/Table/Table.js";
import MaterialTable from "material-table";
import Details from "./Components/Details.js"
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";

const override = css`
display: block;
margin: 0px;
border-color: red;
`;

const overrideNew = css`
display: block;
margin-top: 12px;
margin-left: "150px";
border-color: red;
`;

const accountBalanceCSS = css`
display: block;
margin: 0px 25px;
`;

const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    float: "left",
    display: "inlineBlock",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  addButton: {
    float: "right !important",
    display: "inlineBlock",
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  addDeptContainer: {
    height: "49px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginBottom: "5px !important",
  },
  addDeptButton: {
    marginRight: "-5px !important",
  },
  input: {
    flex: 1,
    height: "45px",
    marginLeft: "15px",
  },
  searchHead: {
    marginTop: "-5px",
    marginBottom: "1px",
    color: "#144b85",
    fontWeight: "400"
  },
  dialogContainer: {
    display: "flex",
  },
  inputPassword: {
    flex: 1,
    marginTop: "10px",
    height: "45px",

    paddingLeft: "10px",
  },
  nextButton: {
    marginLeft: "150px"
  },
  passwordHead: {
    fontWeightL: "500",
    marginBottom: "0px",
    color: "#ffffff"
  },
  indicator: {
    backgroundColor: 'white',
  },
  ...SweetAlertStyle,
  ...modalStyle(theme),
}));




function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function MCurrencyAddMoney() {
  const classes = useStyles();
  const theme = useTheme();
  const user = useSelector(state => state.user);
  const [respAlert, setRespAlert] = React.useState(null);
  const [transactions, setTransactions] = React.useState([]);
  const [successTransactions, setSuccessTransactions] = React.useState([]);
  const [failedTransactions, setFailedTransactions] = React.useState([]);
  const [adminDepositTransactions, setAdminDepositTransactions] = React.useState([]);
  const [showTransactionTable, setShowTransactionTable] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(true);
  const [csvData, setCsvData] = React.useState([]);

  const [submitting, setSubmitting] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [showDetails, setShowDetails] = React.useState(false);

  const [transactionDetails, setTransactionDetails] = React.useState(null);

  const [searchKey, setSearchKey] = React.useState("Benefit PG");
  const [searchedKey, setSearchedKey] = React.useState("Benefit PG");
  const [fromDate, setFromDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
  const [toDate, setToDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));


  const [accountBalance, setAccountBalance] = React.useState("");
  const [walletType, setWalletType] = React.useState("");
  const [walletNo, setWalletNo] = React.useState("");
  const [employerName, setEmployerName] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [userDetails, setUserDetails] = React.useState([]);
  const [detailsModal, setDetailsModal] = React.useState(false);

  const [openPassword, setOpenPassword] = React.useState(false);
  const [submittingDeposit, setSubmittingDeposit] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [pageValue, setPageValue] = React.useState(1);
  const [transactionID, setTransactionID] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [cpr, setCpr] = React.useState("");
  const [amount, setAmount] = React.useState("")

  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    searchTransactions();
  }, []);
  let service = user.services.find(service => service.name == "Add Money Transactions")
  function openSnackBar(msg) {
    //function to tost snackbar message
    setOpenSnack(true);
    setSnackBarMsg(msg);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  }

  const validateSearch = () => {
    //function validate search params
    let snackBarMsg = ""
    let flag = true
    if (fromDate.length == 0) {
      flag = false
      snackBarMsg = "Please select from date."
    }
    if (toDate.length == 0) {
      flag = false
      snackBarMsg = (snackBarMsg == "" ? "Please select To date." : snackBarMsg);
    }
    if (searchKey.length == 0) {
      flag = false
      snackBarMsg = (snackBarMsg == "" ? "Please select type." : snackBarMsg);
    }
    if (!flag) {
      openSnackBar(snackBarMsg)
    }
    return flag
  }
  // const nextTransaction = () => {
  //   setPageValue(pageValue => pageValue + 1);
  // }

  // const previousTransaction = () => {
  //   setPageValue(pageValue => pageValue - 1);
  // }

  const searchTransactions = () => {
    //function to search customer
    if (validateSearch()) {
      let data = {
        "type": searchKey,
        "from": moment(fromDate).format("MM-DD-YYYY"),
        "to": moment(toDate).format("MM-DD-YYYY")
      }

      setSearchedKey(searchKey)
      setSubmitting(true)
      axios.post(`${config.url().baseURL}/get-multicurrency-transactions-report`, data).then((resp) => {
        setSubmitting(false)
        let resData = resp.data;
        if (!resData.err) {
          let failedData = []
          let successData = []
          let adminDepositData = []
          let respData = resp.data.data;
          var updatedData = respData.map((tran) => {

            let date = tran.time
            let status = tran.m2pstatus

            tran.date = `${moment(date).format("DD/MM/YYYY")} ${moment(date).format("h:mm:ss A")}`;
            if (tran.status) {
              tran.convertedStatus = <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="Success" />;
            } else {
              tran.convertedStatus = <Chip size="small" style={{ backgroundColor: "red", color: "white" }} label="Failure" />;
            }
            tran.dateOfCreation = moment(tran.creation_Date).format("DD/MM/YYYY");

            if (tran.m2pstatus) {
              tran.m2pstatus1 = <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="Success" />;
            } else {
              tran.m2pstatus1 = <Chip size="small" style={{ backgroundColor: "red", color: "white" }} label="Failure" />;
            }
            tran.UserID = tran.userID
            if (tran.status && status) {
              successData.push(tran)
            } else if (tran.status && !status) {
              adminDepositData.push(tran)
            }
            else {
              failedData.push(tran)
            }
            return tran;

          });

          setAdminDepositTransactions(adminDepositData)
          setSuccessTransactions(successData)
          setFailedTransactions(failedData)
          setTransactions(updatedData);
          setShowLoader(false);
          setShowTransactionTable(true);

          var updatedCsvData = respData.map((tran) => {
            let temp = {};
            temp.Date = tran.date;
            temp.CPR = "'" + tran.entityId + "'";
            temp.Transaction_ID = tran.transactionID;
            temp.Wallet_Type = tran.walletType ? tran.walletType : "";
            // temp.Corporate_Name = tran.corporateName ? tran.corporateName.replaceAll(/,/g, '') : ""
            temp.M2P_Status = tran.m2pstatus ? "Success" : "Failure"
            if (tran.status) {
              temp.status = "Success";
            } else {
              temp.status = "Failure";
            }

            temp.Payment_Mode = tran.paymentMode;
            temp.Total_Amount = tran.totalAmount;
            temp.Total_Payment_Fees = tran.totalPaymentFees;
            temp.Payment_Fees = tran.paymentFees;
            temp.Payment_Tax = tran.paymentTax;
            temp.Actual_Payment_Fees = tran.actualPaymentFees;
            temp.Actual_Payment_Tax = tran.actualPaymentTax;
            temp.Actual_Total_PaymentFees = tran.actualTotalPaymentFees;
            return temp;
          });
          setCsvData(updatedCsvData);
        } else {
          setTransactions([]);
          setShowLoader(false);
          setShowTransactionTable(true);
          openSnackBar(resData.msg);
        }
      })
        .catch((err) => {
          setSubmitting(false)
          setTransactions([]);
          setShowLoader(false);
          setShowTransactionTable(true);
          openSnackBar("Unable to process");

        });
    }
  }


  const resetSearch = () => {
    //function to reset search
    setSearchKey("Benefit PG")
    setFromDate(moment(new Date).format("YYYY-MM-DD"))
    setToDate(moment(new Date).format("YYYY-MM-DD"))
    setTimeout(function () {
      searchTransactions()
    }, 2000);

  }


  const columns = [
    {
      field: "date",
      title: "Date",
    },
    {
      field: "entityId",
      title: "CPR",
    },
    {
      field: "walletType",
      title: "Wallet Type",
    },
    // {
    //   field: "corporateName",
    //   title: "Corporate Name",
    // },
    {
      field: "transactionID",
      title: "Transaction ID",
    },
    {
      field: "paymentMode",
      title: "Payment Mode",
    },
    {
      field: "m2pstatus1",
      title: "M2P Status",
    },
    {
      field: "convertedStatus",
      title: "PG Status",
    },
    {
      field: "totalAmount",
      title: "Total Amount",
    },
    {
      field: "paymentFees",
      title: "Payment Fees"
    },
    {
      field: "paymentTax",
      title: "Payment Tax"
    },
    {
      field: "totalPaymentFees",
      title: "Total Payment Fees"
    },
    {
      field: "actualPaymentFees",
      title: "Actual Payment Fees"
    },
    {
      field: "actualPaymentTax",
      title: "Actual Payment Tax"
    },
    {
      field: "actualTotalPaymentFees",
      title: "Actual Total Payment Fees"
    },

  ];
  const options = {
    showTitle: false,
    actionsColumnIndex: -1,
    // paging: false,
    exportButton: { csv: true },
    exportCsv: () => {
      let csvFile = creatCsvFile(csvData);
      let fileName = `${searchedKey}_${moment(fromDate).format("MM-DD-YYYY")}_to_${moment(toDate).format("MM-DD-YYYY")}.csv`;
      downloadFile(csvFile, fileName);
      return false;
    },
  };


  const Actions = [
    {
      icon: "list_lt_icon",
      tooltip: 'Transaction Details',
      onClick: (event, rowData) => loadTransactionDetails(rowData.transactionID),
    },
    {
      icon: "account_circle_icon",
      tooltip: 'User Details',
      onClick: (event, rowData) => fetchUserDetails(rowData.userID),
    },
    (rowData) => {
      return (rowData.status == true && rowData.m2pstatus == false)
        ? { icon: 'replay_icon', onClick: (event, rowData) => { handleAdminDepositClick(rowData.transactionID, rowData.entityId, rowData.totalAmount, rowData.userID) } }
        : null
    }
  ]

  const handleAdminDepositClick = (tranID, entity, amount1, user) => {
    //function to open password modal and set transaction id
    setTransactionID(tranID)
    setCpr(entity);
    setAmount(amount1)
    setUserId(user)
    // adminDeposit()
    setOpenPassword(true)
  }

  const adminDeposit = () => {
    //function to do admin deposit
    if (transactionID && password) {
      try {
        let data = {
          entityID: cpr,
          amount: amount,
          userID: userId,
          transactionID: transactionID,
        }
        if (!submittingDeposit) {
          setSubmittingDeposit(true)
          let userData = { email: user.email, password: password };
          axios.post(`${config.url().baseURL}/sign-in-system-user`, userData).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
              if (respData.token !== undefined) {
                sessionStorage.setItem("cx_id", respData.token.AuthToken);
                axios.post(`${config.url().baseURL}/mc-admin-deposit`, data, {
                  headers: {
                    auth: sessionStorage.getItem("cx_id"),
                  },
                }
                ).then((resp) => {
                  let respData = resp.data;
                  if (!respData.err) {
                    setRespAlert(
                      <SweetAlert
                        success
                        style={{ display: "block" }}
                        title={respData.msg}
                        onConfirm={() => {
                          searchTransactions()
                          setRespAlert(null);
                          setTransactionID("")
                          setPassword("")
                          setSubmittingDeposit(false);
                          setOpenPassword(false)

                        }
                        }
                        confirmBtnCssClass={classes.button + " " + classes.success}
                      />
                    );
                  } else {
                    openSnackBar(respData.msg);
                    setOpenPassword(false)
                    setPassword("")
                    setSubmittingDeposit(false);
                  }
                }).catch((err) => {
                  openSnackBar("Unable to process.");
                  setOpenPassword(false)
                  setPassword("")
                  setSubmittingDeposit(false);

                });
              } else {
                openSnackBar(respData.msg);
                setSubmittingDeposit(false);
                setPassword("")
              }
            } else {
              openSnackBar(respData.msg);
              setPassword("")
              setSubmittingDeposit(false);
            }
          }).catch((err) => {
            openSnackBar("Unable to validate user.");
            setSubmittingDeposit(false);
            setPassword("")

          });
        }

      } catch (error) {
        openSnackBar("Unable to process.")
        setPassword("")
      }
    } else openSnackBar("Please enter password.")
  }

  const loadTransactionDetails = (id) => {
    //function to load transaction details
    setTransactionDetails(<Details transactionID={id} type="netSys" />)
    setShowDetails(true)
  }

  const fetchUserDetails = (id) => {
    //function to fetch details of a customer
    setDetailsModal(true);
    axios.get(`${config.url().baseURL}/get-user/${id}`).then((resp) => {
      let respData = resp.data;
      if (!respData.err && respData.data !== null) {
        let customer = respData.data;
        if (customer) {
          if (customer.statusNetsys) {
            customer.NetSys_StatusRow = customer.statusNetsys
            loadAccountBalance(customer.cpr)
            customer.statusNetsys = (
              <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />
            );
          } else {
            setAccountBalance("")
            setWalletNo("")
            setWalletType("")
            customer.statusNetsys = (
              <Chip size="small" style={{ backgroundColor: "red", color: "white" }} label="False" />
            );
          }

          if (customer.statusIGA) {
            customer.statusIGA = <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />;
          } else {
            customer.statusIGA = <Chip size="small" style={{ backgroundColor: "red", color: "white" }} label="False" />;
          }
          if (customer.statusUser) {
            customer.statusUser = <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />;
          } else {
            customer.statusUser = <Chip size="small" style={{ backgroundColor: "red", color: "white" }} label="False" />;
          }
          if (customer.additionalDataStatus) {
            customer.additionalDataStatus = (
              <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />
            );
          } else {
            customer.additionalDataStatus = (
              <Chip size="small" style={{ backgroundColor: "red", color: "white" }} label="False" />
            );
          }
        }
        setUserDetails(customer);
      } else {
        setDetailsModal(false);
        openSnackBar("Unable to load user details .")
      }
    });
  };

  const loadAccountBalance = (cpr) => {
    //function to load account balance of a customer 
    axios.get(`${config.url().baseURL}/get-wallet-balance?id=${cpr}`)
      .then((resp) => {
        if (!resp.data.err) {
          setAccountBalance(resp.data.data[0].walletBalance)
          setWalletType(resp.data.data[0].walletType)
          setWalletNo(resp.data.data[0].walletAccNumber)
          setEmployerName(resp.data.data[0].employerName)
          setUserName(resp.data.data[0].name)
        } else {
          openSnackBar("Unable to load account balance.")
        }
      }).catch((e) => {
        console.log(e)
      })
  }

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }


  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}>
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);

  };

  return (
    <div>
      {respAlert}
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showLoader}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
          </Collapse>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showTransactionTable}
            style={{ transformOrigin: "0 0 0", textAlign: "left" }}
            {...(true ? { timeout: 500 } : {})}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={3}>
                <p className={classes.searchHead}>From Date</p>
                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                  <InputBase
                    autoFocus={true}
                    fullWidth
                    className={classes.input}
                    inputProps={{
                      type: "date",
                      max: moment(new Date()).format('YYYY-MM-DD'),
                      onChange: (event) => { setFromDate(event.target.value) },
                      onBlur: (event) => {
                        if (moment(event.target.value).isAfter(moment(new Date()))) {
                          setFromDate("")
                          openSnackBar("Please enter valid date.")
                        } else {
                          setFromDate(event.target.value)
                        }
                      },
                      value: fromDate,
                    }}
                  />
                </Paper>

              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <p className={classes.searchHead}>To Date</p>
                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                  <InputBase
                    autoFocus={true}
                    fullWidth
                    className={classes.input}
                    inputProps={{
                      type: "date",
                      max: moment(new Date()).format('YYYY-MM-DD'),
                      onChange: (event) => { setToDate(event.target.value) },
                      onBlur: (event) => {
                        if (moment(event.target.value).isAfter(moment(new Date()))) {
                          setToDate("")
                          openSnackBar("Please enter valid date.")
                        } else {
                          setToDate(event.target.value)
                        }
                      },
                      value: toDate,
                    }}
                  />
                </Paper>
                <div style={{ textAlign: "center" }}>
                  <Tooltip placement="bottom" title="Reset">
                    <IconButton className={classes.addDeptButton}
                      onClick={() => resetSearch()}
                      size="lg" color="primary">
                      <RotateLeftIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <p className={classes.searchHead}>Type</p>
                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                  <FormControl className={classes.groupSelect} fullWidth>
                    <Select
                      id="group-select"
                      value={searchKey}
                      disableUnderline
                      onChange={(e) => {
                        setSearchKey(e.target.value);
                      }}>
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Benefit PG">Benefit PG </MenuItem>
                      <MenuItem value="Benefit App">Benefit App </MenuItem>
                      <MenuItem value="BFCPay Prepaid Wallet">BFCPay Prepaid Wallet</MenuItem>
                      <MenuItem value="Initiated">Initiated</MenuItem>
                      <MenuItem value="Credit/ Debit">New PG - Credit/ Debit</MenuItem>
                    </Select>
                  </FormControl>
                  {!submitting ? (
                    <Button className={classes.addDeptButton}
                      onClick={() => searchTransactions()}
                      size="lg" color="primary">
                      <SearchIcon fontSize="medium" />
                    </Button>
                  ) : (
                    <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
                  )}
                </Paper>

              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="primary">
                    <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none', color: "white" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        // indicatorColor="secondary"
                        textColor="white"
                        variant="fullWidth"
                        classes={{
                          indicator: classes.indicator
                        }}>
                        <Tab label={`All(${transactions.length})`} {...a11yProps(0)} />
                        <Tab label={`Success(${successTransactions.length})`} {...a11yProps(0)} />
                        <Tab label={`Failed(${failedTransactions.length})`} {...a11yProps(0)} />
                        <Tab label={`Retry Transactions(${adminDepositTransactions.length})`} {...a11yProps(0)} />
                      </Tabs>
                    </AppBar>
                  </CardHeader>
                  <CardBody>
                    <SwipeableViews
                      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                      index={value}
                      className={classes.tabContent}
                      onChangeIndex={handleChangeIndex}>
                      <TabPanel value={value} index={0} dir={theme.direction}
                        style={{ textAlign: "center" }}>
                        <MaterialTable
                          actions={Actions}
                          components={{
                            Container: (props) => <Paper {...props} elevation={0} />,
                          }}
                          data={transactions} columns={columns} options={options} />
                        {/* <Button 
                          disabled={pageValue <= 1 ? true : false}
                          onClick={() => previousTransaction()}
                          simple color="primary"
                          size="sm">
                          < ArrowBackIcon fontSize="medium" />
                        </Button>
                        <Button 
                          // disabled={remainingDocs == 0 ? true : false}
                          onClick={() => nextTransaction()}
                          color="primary"
                          simple
                          size="sm">
                          <ArrowForwardIcon fontSize="medium" />
                        </Button> */}
                      </TabPanel>
                      <TabPanel value={value} index={1} dir={theme.direction} style={{ textAlign: "center" }} >
                        <MaterialTable
                          actions={Actions}
                          components={{
                            Container: (props) => <Paper {...props} elevation={0} />,
                          }}
                          data={successTransactions} columns={columns} options={options} />
                        {/* <Button
                          disabled={pageValue <= 1 ? true : false}
                          onClick={() => previousTransaction()}
                          color="primary"
                          size="sm">
                          Previous
                        </Button>
                        <Button
                          // disabled={remainingDocs == 0 ? true : false}
                          onClick={() => nextTransaction()}
                          color="primary"
                          size="sm">
                          Next
                        </Button> */}
                      </TabPanel>
                      <TabPanel value={value} index={2} dir={theme.direction} style={{ textAlign: "center" }}>
                        <MaterialTable
                          actions={Actions}
                          components={{
                            Container: (props) => <Paper {...props} elevation={0} />,
                          }}
                          data={failedTransactions} columns={columns} options={options} />
                        {/* <Button
                          disabled={pageValue <= 1 ? true : false}
                          onClick={() => previousTransaction()}
                          color="primary"
                          size="sm">
                          Previous
                        </Button>
                        <Button
                          // disabled={remainingDocs == 0 ? true : false}
                          onClick={() => nextTransaction()}
                          color="primary"
                          size="sm">
                          Next
                        </Button> */}
                      </TabPanel>
                      <TabPanel value={value} index={3} dir={theme.direction} style={{ textAlign: "center" }}>
                        <MaterialTable
                          actions={Actions}
                          components={{
                            Container: (props) => <Paper {...props} elevation={0} />,
                          }}
                          data={adminDepositTransactions} columns={columns} options={options} />
                        {/* <Button
                          disabled={pageValue <= 1 ? true : false}
                          onClick={() => previousTransaction()}
                          color="primary"
                          size="sm">
                          Previous
                        </Button>
                        <Button
                          // disabled={remainingDocs == 0 ? true : false}
                          onClick={() => nextTransaction()}
                          color="primary"
                          size="sm">
                          Next
                        </Button> */}
                      </TabPanel>
                    </SwipeableViews>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Collapse>
        </GridItem>
      </GridContainer>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.companyLogModal,
        }}
        open={showDetails}
        onClose={() => {
          setShowDetails(false)
          setTransactionDetails(null)
        }}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          {transactionDetails}
        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
          <Button
            onClick={() => { setShowDetails(false); setTransactionDetails(null) }}
            color="danger"
            size="sm">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.left + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={detailsModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setDetailsModal(false);
          setUserDetails([]);
          setAccountBalance("")
          setWalletNo("")
          setWalletType("")
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
          <h4 className={classes.modalTitle}>Customer Details</h4>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <Collapse
            in={userDetails.userID == undefined}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
          </Collapse>
          <Collapse
            in={userDetails.userID}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            {userDetails.NetSys_StatusRow ? (
              <Table
                tableData={[
                  ["User ID", ":", userDetails.userID],
                  ["Name", ":", userName],
                  ["User Type", ":", userDetails.userType],
                  ["Corporate Name", ":", employerName],
                  ["CPR", ":", userDetails.cpr],
                  ["Device", ":", userDetails.deviceID],
                  ["Mobile", ":", userDetails.mobile],
                  ["Language Preferred", ":", userDetails.languagePref],
                  ["Shufti Status", ":", userDetails.statusShufti],
                  ["Shufti Reference", ":", userDetails.refShufti],
                  ["Additional Data Status", ":", userDetails.additionalDataStatus],
                  ["Created On ", ":", moment(userDetails.created_at).format("DD/MM/YYYY hh:mm A")],
                  ["Account Balance", ":", accountBalance != "" ? accountBalance : < BounceLoader color="#004fa4" loading={true} css={accountBalanceCSS} size={18} />],
                  ["Wallet Type", ":", walletType != "" ? walletType : < BounceLoader color="#004fa4" loading={true} css={accountBalanceCSS} size={18} />],
                  ["Wallet Account No", ":", walletNo != "" ? walletNo : < BounceLoader color="#004fa4" loading={true} css={accountBalanceCSS} size={18} />],
                ]}
              />
            ) : (
              <Table
                tableData={[
                  ["User ID", ":", userDetails.userID],
                  ["CPR", ":", userDetails.cpr],
                  ["Device", ":", userDetails.deviceID],
                  ["Mobile", ":", userDetails.mobile],
                  ["Language Preferred", ":", userDetails.languagePref],
                  ["Shufti Status", ":", userDetails.statusShufti],
                  ["Shufti Reference", ":", userDetails.refShufti],
                  ["Additional Data Status", ":", userDetails.additionalDataStatus],
                  ["Created On ", ":", moment(userDetails.created_at).format("DD/MM/YYYY hh:mm A")],

                ]}
              />
            )
            }
          </Collapse >
        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
          <Button
            onClick={() => {
              setDetailsModal(false);
              setUserDetails([]);
              setAccountBalance("");
              setWalletType("");
              setWalletNo("");
              setEmployerName("");
              setUserName("");
            }}
            color="danger"
            size="sm">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={() => {
        setOpenPassword(false)
        setTransactionID("")
        setPassword("")
      }} aria-labelledby="simple-dialog-title" PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }} open={openPassword}>
        <h4 className={classes.passwordHead}>Please enter password to continue.</h4>
        <Paper className={classes.dialogContainer}>
          <InputBase
            autoFocus={true}
            fullWidth
            className={classes.inputPassword}
            placeholder="Password"
            inputProps={{
              type: "password",
              onChange: (event) => { setPassword(event.target.value) },
              value: password,
            }}
          />

          <Button className={classes.nextButton}
            onClick={() => adminDeposit()}
            simple
            size="lg" color="primary">
            <ArrowForwardIcon fontSize="medium" />
          </Button>

        </Paper>

      </Dialog>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
            setTransactionDetails(null)
          }}
          severity="error">
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
