import * as React from 'react';
import axios from "axios";
import MUIDataTable from "mui-datatables";
import Collapse from "@material-ui/core/Collapse";
import config from "config.js"
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import Tooltip from "@material-ui/core/Tooltip";
// import PreviewIcon from '@mui/icons-material/Preview';
import IconButton from "@material-ui/core/IconButton";
import moment from 'moment';
import { useSelector } from 'react-redux';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { creatCsvFile, downloadFile } from "download-csv";
import CustomInput from "components/CustomInput/CustomInput.js";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import LinearProgress from '@mui/material/LinearProgress';

import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Chip from "@material-ui/core/Chip";
import BounceLoader from "react-spinners/BounceLoader";
import SearchIcon from '@material-ui/icons/Search';
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import TextField from '@mui/material/TextField';
import { CardContent } from '@mui/material';
import { Stack } from '@mui/system';

import ReplayIcon from '@mui/icons-material/Replay';
import { ButtonGroup, Grid } from '@material-ui/core';
import "react-loader-spinner";
import { ThreeDots } from 'react-loader-spinner';
import { Create } from '@material-ui/icons';
const override = css`
display: block;
margin: 32px auto;
border-color: red;
`;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },
    loaderWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Adjust the height as per your requirement
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
export default function CardsActivationsReport() {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [showLmraTable, setShowLmraTable] = React.useState(false)
    const [showLoader, setShowLoader] = React.useState(false)
    const [snackBarType, setSnackBarType] = React.useState("error");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [respAlert, setRespAlert] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [fromDate, setFromDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
    const [toDate, setToDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
    const [referralDetails, setReferralDetails] = React.useState([]);
    const [transactionDetails, setTransactionDetails] = React.useState(null);
    const [showDetails, setShowDetails] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [csvData, setCsvData] = React.useState(false);
    const [userID, setUserID] = React.useState("");
    const [status, setStatus] = React.useState("");
    const [comment, setComments] = React.useState("")
    const [loadAmount, setLoadAmount] = React.useState("")
    const [expanded, setExpanded] = React.useState();

    // const user = useSelectr(state > state.user);


    const columns = [
        {
            name: "date",
            label: "Date",
            options: {
                filter: true,
                sort: true,
            },
        },
        // {
        //     name: "name",
        //     label: "Name",
        //     options: {
        //         filter: true,
        //         sort: true
        //     }
        // },
        // {
        //     name: "productType",
        //     label: "Product Type",
        //     options: {
        //         filter: true,
        //         sort: true,
        //     },
        // },
        {
            name: "userID",
            label: "User ID",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
                name: "refferee",
                label: "Referee CPR",
                options: {
                    filter: true,
                    sort: true,
                },
            },
        {
            name: "referralId",
            label: "Referral ID",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "refferer",
            label: "Referred By",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "applyStatus",
            label: "Apply Status ",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "loadStatus",
            label: "Load Status",
            options: {
                filter: true,
                sort: true,
            },
        }, {
            name: "loadAmount",
            label: "Load Amount",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "campaignName",
            label: "Campaign Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "cashbackGivenToReferee",
            label: "Cashback given to Referee",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "cashbackGivenToReferrer",
            label: "Cashback given to Referrer",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "statusOfCashbackToReferee",
            label: "Status of Cashback to Referee",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "StatusOfCashbackToReferrer",
            label: "Status of Cashback to Referrer",
            options: {
                filter: true,
                sort: true,
            },
        },
        // {
        //     name: "reasonOfFailure",
        //     label: "Reason of Failure",
        //     options: {
        //         filter: true,
        //         sort: true,
        //     },
        // },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
            },
        }
    ];


    const options = {
        selectableRows: false,
        elevation: 0,
        print: false,
        onDownload: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = "Referral Report.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        }
    };

    React.useEffect(() => {
        referralReportDetails()
    }, []);
    const validateSearch = () => {
        //function validate search params
        let snackBarMsg = ""
        let flag = true
        if (fromDate.length == 0) {
            flag = false
            snackBarMsg = "Please select from date."
        }
        if (toDate.length == 0) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select To date." : snackBarMsg);
        }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }

    function openSnackBar(msg, success) {
        setOpenSnack(true);
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const handleClickOpen = (UserID,LoadAmount) => {
        // console.log(loadAmount);
        setUserID(UserID)
        setLoadAmount(LoadAmount)
        updateReferral()
        setOpen(true);


    };

    const handleClickClose = () => {
        setOpen(false);
    };



    const updateReferral = () => {
        let data = {
            userID: userID,
            status: (service.type === 'maker') ? "CREATED" : "APPROVED",
            comments: comment,
            loadAmount:loadAmount
        }
        axios
            .post(`${config.url().baseURL}/mc-referral-cashback-retry-create`, data)
            .then((resp) => {

                let respData = resp.data;
                if (!respData.err) {
                    setRespAlert(
                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title={respData.msg}
                            onConfirm={() => {
                                setRespAlert("");
                                // setUserID("")
                                // setStatus("")
                                // setComments("")
                                // setLoadAmount("")
                                referralReportDetails();
                            }}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                    );
                    setOpen(false);
                    setComments("");
                } else {

                }

            })
            .catch((err) => {
                openSnackBar(err.msg);
                setRespAlert("");
                setOpen(false);
                setComments("");
            });

    };


    const referralReportDetails = () => {

        try {

            if (validateSearch()) {
                let data = {
                    "fromDate": moment(fromDate).format("MM/DD/YYYY"),
                    "toDate": moment(toDate).format("MM/DD/YYYY"),

                }
                setSubmitting(true);
                setShowLmraTable(false);
                setReferralDetails([]);
                axios.post(`${config.url().baseURL}/get-mc-referral-report`, data)
                    .then((resp) => {
                        setSubmitting(false);
                        setShowLoader(false);
                        setShowLmraTable(true)
                        let resData = resp.data;
                        if (resData.err) {
                            openSnackBar(resp.data.msg)
                        }
                        else {
                            let updatedreferralReport
                            let details

                            updatedreferralReport = resp.data.data.map((activations) => {
                                let details = {}
                                details.date = moment(activations.date).format("DD/MM/YYYY  hh:mm A")
                                // details.name = activations.name ? activations.name : ""
                                // details.productType = activations.productType ? activations.productType : ""
                                details.userID = activations.userID ? activations.userID : ""
                                details.refferee = activations.refferee ? activations.refferee : ""
                                details.referralId = activations.referralId ? activations.referralId : ""
                                details.refferer = activations.refferer ? activations.refferer : ""
                                details.applyStatus = activations.applyStatus ? activations.applyStatus : ""
                                if (details.applyStatus) {
                                    details.applyStatus = (
                                        <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />
                                    );
                                } else {
                                    details.applyStatus = (
                                        <Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />
                                    );
                                }
                                details.loadStatus = activations.loadStatus ? activations.loadStatus : ""
                                if (details.loadStatus) {
                                    details.loadStatus = (
                                        <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />
                                    );
                                } else {
                                    details.loadStatus = (
                                        <Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />
                                    );
                                }
                                details.loadAmount = activations.loadAmount ? activations.loadAmount : ""
                                details.campaignName = activations.campaignName ? activations.campaignName : ""
                                details.cashbackGivenToReferee = activations.cashbackGivenToReferee ? activations.cashbackGivenToReferee : ""
                                details.cashbackGivenToReferrer = activations.cashbackGivenToReferrer ? activations.cashbackGivenToReferrer : ""
                                details.statusOfCashbackToReferee = activations.statusOfCashbackToReferee ? activations.statusOfCashbackToReferee : ""
                                if (details.statusOfCashbackToReferee) {
                                    details.statusOfCashbackToReferee = (
                                        <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />
                                    );
                                } else {
                                    details.statusOfCashbackToReferee = (
                                        <Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />
                                    );
                                }
                                details.StatusOfCashbackToReferrer = activations.StatusOfCashbackToReferrer ? activations.StatusOfCashbackToReferrer : ""
                                if (details.StatusOfCashbackToReferrer) {
                                    details.StatusOfCashbackToReferrer = (
                                        <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />
                                    );
                                } else {
                                    details.StatusOfCashbackToReferrer = (
                                        <Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />
                                    );
                                }
                                // details.reasonOfFailure = activations.reasonOfFailure ? activations.reasonOfFailure : ""
                                details.actions =
                                    <>
                                        {(activations.applyStatus === true && activations.loadStatus === true) &&
                                            ((activations.statusOfCashbackToReferee === false || activations.StatusOfCashbackToReferrer === false)||(activations.statusOfCashbackToReferee === false && !activations.refferer)) && ((service.type === 'maker' && activations.retryStatus === "")|| (service.type === 'checker' && activations.retryStatus === 'CREATED' )) ? (
                                            <Tooltip placement='top' title="Retry Referral">
                                                <IconButton
                                                    onClick={() => handleClickOpen(activations.userID,activations.loadAmount)}
                                                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                    aria-label="view"
                                                >
                                                    <ReplayIcon />
                                                </IconButton>
                                            </Tooltip>
                                        ) : null}
                                    </>





                                return details
                            })
                            setReferralDetails(updatedreferralReport);

                            var downloadedData = resp.data.data.map((activations) => {
                                let temp = {};
                                temp.Date = moment(activations.date).format("DD/MM/YYYY  hh:mm A")
                                // temp.Name = activations.name;
                                // temp.Product_Type = activations.productType;
                                temp.User_ID = activations?.userID;
                                temp.Referee_CPR = activations?.refferee;
                                temp.Referral_ID = activations?.referralId;
                                temp.Referred_By = activations.refferer;
                                temp.Apply_Status = activations?.applyStatus;
                                temp.Load_Amount = activations.loadAmount;
                                temp.Campaign_Name = activations.campaignName;
                                temp.Cashback_given_to_Referee = activations?.cashbackGivenToReferee;
                                temp.Cashback_given_to_Referrer = activations.cashbackGivenToReferrer;
                                temp.Status_of_Cashback_to_Referee = activations?.statusOfCashbackToReferee;
                                temp.Status_of_Cashback_to_Referrer = activations.StatusOfCashbackToReferrer;
                                // temp.Reason_of_Failure = activations.reasonOfFailure;
                                return temp;
                            })
                            setCsvData(downloadedData);
                        }
                    }).catch((err) => {
                        console.log(err)
                        openSnackBar('Unable To Process !!')
                    })
            }
        } catch (error) {

            console.log(error)
            openSnackBar("Unable to fetch Activation Report.")
        }
    }

    const handleChangeExpanded = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    let service = user.services.find(service => service.name == "Referral Report")

    return (
        <>

            {respAlert}

            <GridContainer justify='center'>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={true}
                        style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                        {...true ? { timeout: 500 } : {}}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>From Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setFromDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setFromDate("");
                                                    openSnackBar("Please enter a valid Date")
                                                } else {
                                                    setFromDate(event.target.value)
                                                }
                                            },
                                            value: fromDate,
                                        }}>
                                    </InputBase>
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>To Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setToDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setToDate("")
                                                    openSnackBar("Please enter valid date.")
                                                } else {
                                                    setToDate(event.target.value)
                                                }
                                            }, value: toDate,
                                        }}
                                    />
                                    {!submitting ? (
                                        <Button className={classes.addDeptButton}
                                            onClick={() => referralReportDetails()}
                                            size="lg" color="primary">
                                            <SearchIcon fontSize="medium" />
                                        </Button>
                                    ) : (
                                        <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
                                    )}
                                </Paper>
                            </GridItem>
                        </GridContainer>
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLmraTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Referral Report</h4>
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={referralDetails} columns={columns} options={options} />
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>

            <Dialog open={open} onClose={handleClickClose}>
                <div style={{ width: '33rem' }}>
                    <CardHeader>
                        <h4 style={{ marginBottom: "-30px" }}>Please enter Remark</h4>
                    </CardHeader>
                    <CardContent>
                        <CustomInput
                            // labelText={
                            //     <span>

                            //     </span>
                            // }
                            inputProps={{
                                autoComplete: "off",
                                value: comment,

                                onChange: (e) => {
                                    setComments(e.target.value)
                                }
                            }}
                            value={comment}
                            id="name"
                            type="text"
                            formControlProps={{
                                fullWidth: true,
                            }}


                        />
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>

                            <Stack direction='row' style={{ justifyContent: "center" }} >
                                <div style={{ paddingTop: "3px", padding: "10px" }}>
                                    <Button style={{ backgroundColor: "red", height: "40px" }} onClick={handleClickClose} size="sm" >Cancel</Button>
                                    {service.type === 'maker' ? (
                                        <Button style={{ backgroundColor: "Darkblue", height: "40px" }} onClick={() => { updateReferral("Created") }} size="sm">
                                            Create
                                        </Button>

                                    ) : (
                                        <Button style={{ backgroundColor: "Darkblue", height: "40px" }} onClick={() => { updateReferral("Approved") }} size="sm">
                                            Approve
                                        </Button>
                                    )}


                                </div>
                            </Stack>
                            {/* } */}
                        </div>
                    </CardContent>
                </div>
            </Dialog>

            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.companyLogModal,
                }}
                open={showDetails}
                onClose={() => {
                    setShowDetails(false)
                    setTransactionDetails([])
                }}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>Referral Report</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    {transactionDetails}
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setShowDetails(false);
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </>
    );

}

