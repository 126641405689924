import React from "react";
import config from "config.js"
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import MaterialTable from "material-table";
import { creatCsvFile, downloadFile } from "download-csv";
import axios from "axios";
import moment from "moment";
import Dialog from '@material-ui/core/Dialog';
import InputBase from "@material-ui/core/InputBase";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from "components/CustomButtons/Button.js";
import BounceLoader from "react-spinners/BounceLoader";
import Paper from "@material-ui/core/Paper";
import SweetAlert from "react-bootstrap-sweetalert";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import { useSelector } from 'react-redux';
import { TablePagination, Tooltip } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from "components/Table/Table.js";
import Slide from "@material-ui/core/Slide";

import CardHeader from "components/Card/CardHeader.js";
import { CardContent } from '@mui/material';
import CustomInput from "components/CustomInput/CustomInput.js";
import { setEmail } from "redux/actions/user";
import { setID } from "redux/actions/user";
import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TimelineIcon from '@material-ui/icons/Timeline';
import { set } from "date-fns";
import { render } from "jsx-runtime";




const override = css`
display: block;
margin: 32px auto;b
border-color: white;
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    companyStatusLogModal12: {
        margin: "auto",
        minWidth: "800px",
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...SweetAlertStyle
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function FullWidthTabs(props) {
    const classes = useStyles();
    const theme = useTheme();
    const user = useSelector(state => state.user);
    let service = user.services.find(service => service.name == "Customers")

    const [value, setValue] = React.useState(0);
    const [respAlert, setRespAlert] = React.useState(null);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");

    const [pg, setPg] = React.useState([]);
    const [wallet, setWallet] = React.useState([]);
    const [remittance, setRemittance] = React.useState([]);
    const [billPayment, setBillPayment] = React.useState([]);
    const [multiCurrency, setmultiCurrency] = React.useState([])
    const [rewardData, setRewardData] = React.useState([])
    const [creditReward, setCreditReward] = React.useState([])
    const [showField, setShowField] = React.useState(creditReward[0]?.cxstatusMsg)

    const [debitData, setDebitData] = React.useState([])

    const [noPg, setNoPg] = React.useState(false);
    const [noWallet, setNoWallet] = React.useState(false);
    const [noRemittance, setNoRemittance] = React.useState(false);
    const [noBillPayment, setNoBillPayment] = React.useState(false);
    const [noMultiCurrency, setNoMultiCurrency] = React.useState(false);
    const [norewardData, setNoRewardData] = React.useState(false);
    const [nocreditReward, setNoCreditReward] = React.useState(false);
    const [nodebitData, setNoDebitData] = React.useState(false);

    const [openPassword, setOpenPassword] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [password, setPassword] = React.useState("");
    const [transactionID, setTransactionID] = React.useState("");
    const [onViewModel, setOnViewModel] = React.useState([]);
    const [onBoardingViewModal, setOnBoardingViewModal] = React.useState(false)


    const [transactionsCsvData, setTransactionsCsvData] = React.useState([]);
    const [walletCSV, setWalletCSV] = React.useState([]);
    const [remittanceCSV, setRemittanceCSV] = React.useState([]);
    const [billPaymentCSV, setBillPaymentCSV] = React.useState([]);
    const [multiCurrencyCSV, setmultiCurrencyCSV] = React.useState([])
    const [rewardCSV, setRewardCSV] = React.useState([])
    const [creditRewardCSV, setCreditRewardCSV] = React.useState([])
    const [remainingDocs, setRemainingDocs] = React.useState(0);
    const [mremainingDocs, setMRemainingDocs] = React.useState(false);
    const [pageValue, setPageValue] = React.useState(1);
    const [mpageValue, setMPageValue] = React.useState(0);
    const [rewardRemainingDocs, setRewardRemainingDocs] = React.useState(0);
    const [creditRewardDocs, setCreditRewardsDocs] = React.useState(0);
    const [debitRemainingDocs, setDebitRemaingDocs] = React.useState(0);
    const [rpageValue, setRpageValue] = React.useState(0)
    const [debitValue, setDebitValue] = React.useState(0)


    const [open, setOpen] = React.useState(false);
    const [openApprove, setOpenApprove] = React.useState(false)
    const [openResend, setOpenResend] = React.useState(false)

    const [refNo, setRefNo] = React.useState("");
    const [userID, setUserID] = React.useState('');
    const [productId, setProductId] = React.useState("");
    const [updateMailId, setUpdateMailID] = React.useState("");
    const [mailId, setMailID] = React.useState("");
    const [id, setId] = React.useState('');
    const [comments, setComments] = React.useState('');
    const [onBoardingLogs, setOnBoardingLogs] = React.useState([]);
    const [onBoardingLogModal, setOnBoardingLogModal] = React.useState(false);

    const [values, setValues] = React.useState({
        remarksErr: null,
        aggrementErr: null
    });


    const logColumns = [
        { id: 'date', label: 'Date', width: 70 },
        // { id: 'date', label: 'Time', width: 70 },
        { id: 'user', label: 'User' },
        { id: 'status', label: 'Status', width: 70 },
        { id: 'status_message', label: 'Status Info', minWidth: 170 },
        { id: 'remarks', label: 'Remarks', minWidth: 170 },
    ];



    const verifyLength = (value, length) => {
        // function that verifies if a string has a given length or not

        if (value.length >= length) {
            return true;
        }
        return false;
    };

    const validateForm = () => {
        //function to validate form
        var flag = true, remarksErr = false;
        let snackBarMsg = ""
        if (!verifyLength(comments, 3) && comments.trim() == "") {
            flag = false;
            remarksErr = true;
            snackBarMsg = (snackBarMsg == "" ? "Please enter the remarks" : "")
        }
        // if (props.userType == "maker") {
        //     if (!verifyLength(agreementName, 2)) {
        //         setAgreementBtnClr("danger")
        //         flag = false
        //         snackBarMsg = (snackBarMsg == "" ? "Please Select Agreement" : snackBarMsg);

        //     }
        // }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }
    const verify = (value, type, errVariable, length) => {
        switch (type) {
            case "length":
                if (verifyLength(value, length)) {
                    setValues({ ...values, [errVariable]: false });
                } else {
                    setValues({ ...values, [errVariable]: true });
                }
                break;
            default:
                break;
        }
    };

    function openSnackBar(msg) {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="down" ref={ref} {...props} />;
    });

    const fetchRewardLog = (_id) => {
        setOnBoardingLogModal(true);
        axios.get(`${config.url().baseURL}/get-mc-reward-inventory-redeemed-logs/${_id}`).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                if (respData.data[0] !== undefined) {
                    setOnBoardingLogs(respData.data);
                } else {
                    setOnBoardingLogModal(false);
                    openSnackBar("No log found.");
                }

            } else {
                setOnBoardingLogModal(false);
                openSnackBar(respData.msg);
            }
        });
    };



    const tranOptions = {
        exportButton: { csv: true },
        showTitle: false,
        exportCsv: () => {
            let csvFile = creatCsvFile(transactionsCsvData);
            let fileName = "user-transactions.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        getTransactions(newValue, props.userID)
        setPageValue(1);
        setMPageValue(0)
        setRpageValue(0)
        setDebitValue(0)

    };

    const handleChangeIndex = (index) => {
        setValue(index);
        setPageValue(1);
        setMPageValue(0)
        setRpageValue(0)
        setDebitValue(0)

    };
    React.useEffect(() => {
        getTransactions(4, props.userID)

    }, [rpageValue])

    React.useEffect(() => {
        getTransactions(5, props.userID)

    }, [debitValue])

    React.useEffect(() => {
        getTransactions(6, props.userID)
    }, [])

    React.useEffect(() => {
        if (props.userID !== null) {
            getTransactions(0, props.userID)

            // fetchWalletTransactions(props.userID, pageValue);
            setValue(0)
            setPg([]);
            setWallet([]);
            setRemittance([]);
            setBillPayment([]);
            setmultiCurrency([])
            setNoPg(false);
            setNoWallet(false);
            setNoRemittance(false);
            setNoBillPayment(false);
            setNoMultiCurrency(false);
            setNoRewardData(false)
            setNoCreditReward(false)
            setNoDebitData(false)
        } else {
            setPg([]);
            setWallet([]);
            setRemittance([]);
            setBillPayment([]);
            setmultiCurrency([])
            setNoPg(false);
            setNoWallet(false);
            setNoRemittance(false);
            setNoMultiCurrency(false);
            setNoBillPayment(false);
            setNoRewardData(false);
            setNoCreditReward(false);
            setNoDebitData(false);
            setPageValue(1);
            setMPageValue(0)
            setRpageValue(0)
            setDebitValue(0)
        }
    }, [props.userID, pageValue]);

    React.useEffect(() => {
        getTransactions(3, props.userID)

    }, [mpageValue])
    const getTransactions = (id, userID) => {
        //function to get logs
        switch (id) {
            case 0:
                fetchWalletTransactions(userID, pageValue)
                break;
            case 1:
                fetchBillorRemittanceTransactions(userID, "remittance")
                break;
            case 2:
                fetchBillorRemittanceTransactions(userID, 'bill')
                break;
            case 3:
                fetchMultiCurrencyTransactions(userID, mpageValue)
                break;
            case 4: fetchFLYYTransactions(userID, rpageValue, 'credits')
                break;
            case 5: fetchFLYTransactions(userID, debitValue, 'debits')
                break;
            case 6: fetchRedeemRewardTransaction()
                break;
            default:
                break;
        }
    }

    const fetchBillorRemittanceTransactions = (id, type) => {
        //fetch all transaction done by a user
        axios.get(`${config.url().baseURL}/get-${type}-logs/${id}`)
            .then((resp) => {
                if (!resp['data']['err']) {
                    let updatedCsvData = resp['data']['data']['transactions'].map((tran) => {
                        let temp = {};
                        temp.Date = tran['time']
                        temp.Type = tran.type;
                        temp.ReferenceNo = tran.referenceID;
                        temp.Party = tran.party;
                        temp.Amount = tran.settlementAmount;
                        temp.ReceiverAmount = tran.recieverGet;
                        return temp;
                    });

                    switch (type) {
                        case "wallet":
                            setWallet(resp['data']['data']['transactions'])
                            setWalletCSV(updatedCsvData)
                            break;
                        case "remittance":
                            setRemittance(resp['data']['data']['transactions'])
                            setRemittanceCSV(updatedCsvData)
                            break;
                        case "bill":
                            setBillPayment(resp['data']['data']['transactions'])
                            setBillPaymentCSV(updatedCsvData)
                            break;
                        default:
                            break;
                    }
                } else {
                    switch (type) {
                        case "wallet":
                            setWallet([])
                            setWalletCSV([])
                            setNoWallet(true)
                            break;
                        case "remittance":
                            setRemittance([])
                            setRemittanceCSV([])
                            setNoRemittance(true)
                            break;
                        case "bill":
                            setBillPayment([])
                            setBillPaymentCSV([])
                            setNoBillPayment(true)
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch((err) => {
                switch (type) {
                    case "wallet":
                        setWallet([])
                        setWalletCSV([])
                        setNoWallet(true)
                        break;
                    case "remittance":
                        setRemittance([])
                        setRemittanceCSV([])
                        setNoRemittance(true)
                        break;
                    case "bill":
                        setBillPayment([])
                        setWallet([])
                        setBillPaymentCSV(true)
                        break;
                    case "multiCurrency":
                        setmultiCurrency([])
                        setmultiCurrencyCSV([])
                        setNoMultiCurrency(true)
                        break;
                    default:
                        break;
                }
            });
    };


    const fetchWalletTransactions = (id, value) => {
        //fetch all transaction done by a user
        axios.get(`${config.url().baseURL}/get-wallet-logs/${id}/${value}`)
            .then((resp) => {
                if (!resp['data']['err']) {
                    let updatedCsvData = resp['data']['data']['transactions'].map((tran) => {
                        let temp = {};
                        temp.Date = tran['time']
                        temp.Type = tran.type;
                        temp.ReferenceNo = tran.refID;
                        temp.Amount = tran.amount;
                        temp[`Dr/Cr`] = tran.amountIn == "False" ? "Dr" : "Cr";
                        return temp;
                    });

                    setWallet(resp['data']['data']['transactions'])
                    setRemainingDocs(resp.data.data.remainingDocs);
                    if (resp['data']['data']['transactions'].length == 0) setNoWallet(true)
                    setWalletCSV(updatedCsvData)
                } else {
                    setWallet([])
                    setWalletCSV([])
                    setNoWallet(true)
                }
            })
            .catch((err) => {
                setWallet([])
                setWalletCSV([])
                setNoWallet(true)
            });
    };
    const fetchMultiCurrencyTransactions = (userId, value) => {

        let mData = {
            userID: userId,
            pageNo: value,
            cpr: props.cpr
        }
        setMRemainingDocs(false)
        axios.post(`${config.url().baseURL}/get-multicurrency-transactions`, mData)
            .then((resp) => {
                if (!resp['data']['err']) {
                    let updatedCsvData = resp['data']['data']['transactions'].map((tran) => {
                        let temp = {};
                        temp.Date = tran['time']
                        temp.Type = tran.title;
                        temp.ReferenceNo = tran.transactionID;
                        temp.Amount = tran.amount;
                        temp[`Dr/Cr`] = tran.type == "CREDIT" ? "Cr" : "Dr";
                        return temp;
                    });

                    setmultiCurrency(resp['data']['data']['transactions'])
                    // setRemainingDocs(resp.data.data.remainingDocs);
                    if (resp['data']['data']['transactions'].length == 0) setNoMultiCurrency(true)
                    setmultiCurrencyCSV(updatedCsvData)
                } else {
                    setmultiCurrency([])
                    setmultiCurrencyCSV([])
                    setMRemainingDocs(true)
                    // setNoMultiCurrency(true)
                }
            })
            .catch((err) => {
                setMRemainingDocs(true)
                setmultiCurrency([])
                setmultiCurrencyCSV([])
                setNoMultiCurrency(true)
            });
    };

    const fetchFLYYTransactions = (userId, value) => {
        // console.log(userId,value,cpr)
        setRewardRemainingDocs(false)
        axios.get(`${config.url().baseURL}/get-flyy-transactions/${userId}/credits/${value}`)
            .then((resp) => {
                if (!resp['data']['err']) {
                    let updatedCsvData = resp['data']['data']['transactions'].map((tran) => {
                        let temp = {};
                        temp.Date = tran.time_reported;
                        temp.User_Id = tran.userID;
                        temp.Campign_ID = tran.campaignID;
                        temp.ReferenceNo = tran.refnum;
                        temp.Reward_Type = tran.reward_type;
                        temp.Reward_Value = tran.reward_value;
                        temp.Event = tran.event_name;
                        return temp;
                    });

                    setRewardData(resp['data']['data']['transactions'])
                    setRewardRemainingDocs(resp.data.data.remainingDocs);
                    if (resp['data']['data']['transactions'].length == 0) setNoRewardData(true)
                    setRewardCSV(updatedCsvData)
                } else {
                    setRewardData([])
                    setRewardCSV([])
                    setRewardRemainingDocs(true)
                    // setNoMultiCurrency(true)
                }
            })
            .catch((err) => {
                setRewardRemainingDocs(true)
                setRewardData([])
                setRewardCSV([])
                setNoRewardData(true)
            });
    };
    const fetchRedeemRewardTransaction = () => {

        let mData = {
            cpr: props.cpr,
        };

        setCreditRewardsDocs(false);

        axios
            .post(`${config.url().baseURL}/mcRewardInventoryList`, mData)
            .then((resp) => {
                if (!resp.data.err) {
                    let updatedCsvData = resp.data.data.map((tran) => {
                        // console.log(!tran.oldMails?)
                        let temp = {};
                        temp.Date = moment(tran.mailData.time).format("DD/MM/YYYY");
                        temp.RewardType = tran.rewardType;
                        temp.MailID = tran.mailData.mailID
                        return temp;
                    });

                    setCreditReward(resp.data.data);

                    if (resp.data.data.length === 0) setNoCreditReward(true);

                    setCreditRewardCSV(updatedCsvData);
                } else {
                    setCreditReward([]);
                    setCreditRewardCSV([]);
                    setCreditRewardsDocs(true);

                }
            })
            .catch((err) => {
                setNoCreditReward(true);
                setCreditReward([]);
                setCreditRewardCSV([]);
                setCreditRewardsDocs(true);
            });
    };
    const fetchFLYTransactions = (userId, value) => {
        setDebitRemaingDocs(false)
        axios.get(`${config.url().baseURL}/get-flyy-transactions/${userId}/debits/${value}`)
            .then((resp) => {
                if (!resp['data']['err']) {
                    let updatedCsvData = resp['data']['data']['transactions'].map((tran) => {
                        let temp = {};
                        temp.Date = tran.time_reported;
                        temp.User_Id = tran.userID;
                        temp.Campign_ID = tran.campaignID;
                        temp.ReferenceNo = tran.refnum;
                        temp.Reward_Type = tran.reward_type;
                        temp.Reward_Value = tran.reward_value;
                        temp.Event = tran.event_name;
                        return temp;
                    });

                    setDebitData(resp['data']['data']['transactions'])
                    setDebitRemaingDocs(resp.data.data.remainingDocs);
                    if (resp['data']['data']['transactions'].length == 0) setNoDebitData(true)
                    setRewardCSV(updatedCsvData)
                } else {
                    setDebitData([])
                    setRewardCSV([])
                    setDebitRemaingDocs(true)
                    // setNoMultiCurrency(true)
                }
            })
            .catch((err) => {
                setDebitRemaingDocs(true)
                setDebitData([])
                setRewardCSV([])
                setNoDebitData(true)
            });
    };


    const handleVoidClick = (tranID) => {
        setTransactionID(tranID)
        setOpenPassword(true)
    }
    const nextTransaction = () => {
        setPageValue(pageValue => pageValue + 1);
    }
    const mNextTransaction = () => {
        setMPageValue(mpageValue => mpageValue + 1)
    }

    const rewardPrevious = () => {
        setRpageValue(rpageValue => rpageValue - 1)

    }
    const rewardNext = () => {
        setRpageValue(rpageValue => rpageValue + 1)
    }
    const previousTransaction = () => {
        setPageValue(pageValue => pageValue - 1);
    }
    const MPreviousTransaction = () => {
        setMPageValue(mpageValue => mpageValue - 1)
    }
    const debitNext = () => {
        setRpageValue(debitValue => debitValue + 1);
    }
    const debitprevious = () => {
        setRpageValue(debitValue => debitValue - 1);
    }
    const fetchTransactions = (id) => {
        axios.get(`${config.url().transactionMSURL}/microServices/CXPortal/v1/getCustomerTransactions/${id}`,).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                var updatedData = respData.data.map((tran) => {
                    tran.totalAmount = tran.amountDetail.Total_amount;
                    tran.fee = tran.amountDetail.fee;
                    tran.tax = tran.amountDetail.tax;
                    tran.date = moment(tran.timeStamp_updated).format("DD/MM/YYYY");
                    tran.time = moment(tran.timeStamp_updated).format("HH:MM:SS");
                    if (tran.status) {
                        tran.convertedStatus = "Success"
                    } else {
                        tran.convertedStatus = "Failure"
                    }
                    return tran;
                });
                setPg(updatedData);
                let updatedTransactionsCsvData = respData.data.map((tran) => {
                    let temp = {};
                    temp.Created_Date = moment(tran.timeStamp_created).format("DD/MM/YYYY");
                    temp.Created_Time = moment(tran.timeStamp_created).format("HH:MM:SS");
                    temp.Updated_Date = moment(tran.timeStamp_updated).format("DD/MM/YYYY");
                    temp.Updated_Time = moment(tran.timeStamp_updated).format("HH:MM:SS");
                    temp.Transaction_ID = tran.transactionID;
                    if (tran.status) {
                        temp.status = "Success";
                    } else {
                        temp.status = "Failure";
                    }
                    temp.User_ID = tran.userID;
                    temp.Payment_Type = tran.paymentType;
                    temp.Payment_Mode = tran.paymentMode;
                    temp.Amount = tran.amountDetail.amount;
                    temp.Fee = tran.amountDetail.fee;
                    temp.Tax = tran.amountDetail.tax;
                    temp.Total_Amount = tran.amountDetail.Total_amount;
                    return temp;
                });
                setTransactionsCsvData(updatedTransactionsCsvData);
            } else {
                setPg([]);
                setNoPg(true);
            }
        })
            .catch((err) => {
                setPg([]);
                setNoPg(true);
            });
    };

    const walletColumns = [
        {
            field: "time",
            title: "Date",

            cellStyle: {
                width: 300,
                maxWidth: 300
            },
            render: rowData => moment(rowData.time).format("DD/MM/YYYY") != "Invalid date" ? <span> {moment(rowData.time).format("DD/MM/YYYY") + " " + moment(rowData.time).format("hh:mm:ss A")}</span> : rowData.time,
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "refID",
            title: "Reference No",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "type",
            title: "Type",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "otherCustomer",
            title: "Other Customer",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "amountIn",
            title: "Dr/Cr",
            cellStyle: {
                width: 300,
                maxWidth: 300
            },
            render: rowData => rowData.amountIn == "False" ? <span> Dr</span> : <span> Cr</span>,
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "amount",
            title: "Amount",
            options: {
                filter: false,
                sort: false,
            },
        },

    ];
    const MultiColumns = [
        {
            field: "time",
            title: "Date",

            cellStyle: {
                width: 300,
                maxWidth: 300
            },
            render: rowData => moment(rowData.time).format("DD/MM/YYYY") != "Invalid date" ? <span> {moment(rowData.time).format("DD/MM/YYYY") + " " + moment(rowData.time).format("hh:mm:ss A")}</span> : rowData.time,
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "transactionID",
            title: "Reference No",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "title",
            title: "Type",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "type",
            title: "Dr/Cr",
            cellStyle: {
                width: 300,
                maxWidth: 300
            },
            render: rowData => rowData.type == "CREDIT" ? <span> Cr</span> : <span> Dr</span>,
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "amount",
            title: "Amount",
            options: {
                filter: false,
                sort: false,
            },
        },

    ];

    const p2pColumns = [
        {
            field: "time",
            title: "Date",

            cellStyle: {
                width: 300,
                maxWidth: 300
            },
            render: rowData => moment(rowData.time).format("DD/MM/YYYY") != "Invalid date" ? <span> {moment(rowData.time).format("DD/MM/YYYY") + " " + moment(rowData.time).format("hh:mm:ss A")}</span> : rowData.time,
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "type",
            title: "Type",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "referenceID",
            title: "Reference No.",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "party",
            title: "Party",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "settlementAmount",
            title: "Amount",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            field: "recieverGet",
            title: "Rcr Amount",
            options: {
                filter: false,
                sort: false,
            },
        },

    ];
    const rewardColumns = [
        {
            field: "time_reported",
            title: "Date",
            cellStyle: {
                width: 300,
                maxWidth: 300
            },
            render: rowData => moment(rowData.time_reported).format("DD/MM/YYYY") != "Invalid date" ? <span> {moment(rowData.time_reported).format("DD/MM/YYYY") + " " + moment(rowData.time_reported).format("hh:mm:ss A")}</span> : rowData.time_reported,
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "userID",
            title: "User ID",
            cellStyle: {
                width: 300,
                maxWidth: 300
            },
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "refnum",
            title: "Reference No.",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "reward_type",
            title: "Reward Type",
            options: {
                filter: true,
                sort: true,
            },
        },

        {
            field: "reward_value",
            title: "Reward Value",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            field: "event_name",
            title: "Event",
            options: {
                filter: false,
                sort: false,
            },
        },

    ]
    const creditRewardColumn = [
        {
            field: "mailData?.time",
            title: "Date",
            cellStyle: {
                width: 300,
                maxWidth: 300
            },
            render: rowData => moment(rowData.mailData.time).format("DD/MM/YYYY") != "Invalid date" ? <span> {moment(rowData.mailData.time).format("DD/MM/YYYY")}</span> : rowData.mailData.time,
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "rewardType",
            title: "Reward Type",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "mailData?.oldMails",
            title: "Mail ID",
            render: (rowData) => {
                const lastMail = rowData?.mailData?.oldMails?.length > 0
                    ? rowData?.mailData?.oldMails[rowData?.mailData?.oldMails?.length - 1]?.mails
                    : rowData?.mailData.mailID;

                return lastMail;
            },
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "mailData?.mailID",
            title: "Update Email",
            cellStyle: {
                width: 300,
                maxWidth: 300
            },
            render: (rowData) => {
                return rowData.mailData.mailID;
            },
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "actions",
            title: "Action",
            options: {
                filter: true,
                sort: true,
            },
            render: (rowData) => {

                return (
                    <>
                        {
                            ((service.type === 'maker' && (!rowData.cxstatusMsg || rowData.cxstatusMsg === 'REJECTED')) ||
                                !service.type === 'checker') ? (
                                <Tooltip placement="top" title="Resend Email">
                                    <span
                                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                        onClick={() => handleClickOpen(rowData)}
                                    >
                                        <SendIcon />
                                    </span>
                                </Tooltip>
                            ) : null
                        }

                        {
                            (service?.type === "checker" && rowData.cxstatusMsg === "EMAIL_CHANGED") && (
                                <Tooltip placement="top" title="Approve Email">
                                    <span
                                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                        onClick={() => handleApproveEmail(rowData)}
                                    >
                                        <EmailIcon />
                                    </span>
                                </Tooltip>
                            )
                        }

                        {/* <Tooltip placement="top" title="Resend Email">
                            <span
                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                onClick={() => handleClickResend(rowData)}
                            >
                                <SendIcon />
                            </span>
                        </Tooltip> */}
                        {service.type === 'checker' && (<Tooltip placement="top" title="View Email">
                            <span
                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                onClick={() => viewEmail(rowData)}
                            >
                                <VisibilityIcon />
                            </span>
                        </Tooltip>)}
                        <Tooltip placement="top" title="Email Log">
                            <span
                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                onClick={() => fetchRewardLog(rowData._id)}
                            >
                                <TimelineIcon />
                            </span>
                        </Tooltip>
                    </>
                );
            },
        },
    ]




    const billPaymentColumns = [
        {
            field: "time",
            title: "Date",

            cellStyle: {
                width: 300,
                maxWidth: 300
            },
            render: rowData => <span> {rowData.time}</span>,
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "type",
            title: "Type",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "referenceID",
            title: "Reference No.",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "party",
            title: "Party",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "settlementAmount",
            title: "Amount",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            field: "recieverGet",
            title: "Rcr Amount",
            options: {
                filter: false,
                sort: false,
            },
        },

    ];
    const walletOptions = {
        actionsColumnIndex: -1,
        exportButton: { csv: true },
        showTitle: false,
        pageSizeOptions: [],
        paging: false,
        rowStyle: rowData => {
            if (rowData.amountIn == "False") {
                return { backgroundColor: '#f0fcf0' };
            } else {
                return { backgroundColor: '#fcf0f0' };
            }
        },
        exportCsv: () => {
            let csvFile = creatCsvFile(walletCSV);
            let fileName = "wallet-transactions.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
    };


    const walletActions = [
        (rowData) => {
            if (moment().diff(moment(rowData.time), 'hours') > 24) {
                return { icon: 'replay_icon', onClick: (event, rowData) => { handleVoidClick(rowData.referenceID) } }
            } else {
                return null
            }

        }
    ]

    const handleClickOpen = (data) => {
        setComments(data.comments);
        setRefNo(data.refNo);
        setId(data._id);
        setProductId(data.productId);
        setUserID(data.userId)
        console.log(data.userId, "==");

        setOpen(true);


    }
    const handleApproveEmail = (data) => {
        setComments(data.comments);
        setMailID(data?.mailData?.mailID)
        setRefNo(data.refNo);
        setId(data._id);
        setProductId(data.productId);
        setUserID(data.userId)

        setOpenApprove(true);
    };

    const handleClickClose = () => {
        setOpen(false);
    };
    // const handleClickCloseLog = () => {
    //     setOnBoardingLogModal(false)
    //     setOnBoardingLogs([])
    //     // setOpen(false)
    // };
    const handleApproveClose = () => {
        setOpenApprove(false);
    };

    const handleResendClose = () => {
        setOpenResend(false);
    }





    const updateEmail = () => {
        if (validateForm()) {

            let data = {
                refNo: refNo,
                userID: userID,
                productId: productId,
                id: id,
                mailId: updateMailId,
                comments: comments,

            }
            axios.post(`${config.url().baseURL}/mcRewardInvetory-update-email`, data)
                .then((resp) => {

                    let respData = resp.data;
                    if (!respData.err) {
                        setRespAlert(
                            <SweetAlert
                                success
                                style={{ display: "block" }}
                                title={respData.msg}
                                onConfirm={() => {
                                    setRespAlert("");
                                    fetchRedeemRewardTransaction()
                                }}
                                confirmBtnCssClass={classes.button + " " + classes.success}
                            />
                        );
                        setOpen(false);

                    } else {

                    }
                })
                .catch((err) => {
                    openSnackBar(err.msg);
                    setRespAlert("");
                    setOpen(false);
                    setComments("")

                });
        }

    };

    const approveEmail = () => {
        if (validateForm()) {

            let data = {
                refNo: refNo,
                userID: userID,
                productId: productId,
                id: id,
                mailId: mailId,
                comments: comments,

            }
            axios.post(`${config.url().baseURL}/approve-resendMail`, data)
                .then((resp) => {

                    let respData = resp.data;
                    if (!respData.err) {
                        setRespAlert(
                            <SweetAlert
                                success
                                style={{ display: "block" }}
                                title={respData.msg}
                                onConfirm={() => {
                                    setRespAlert("");
                                    fetchRedeemRewardTransaction()
                                }}
                                confirmBtnCssClass={classes.button + " " + classes.success}
                            />
                        );
                        setOpenApprove(false);
                        setComments("")
                        setId("")

                    } else {

                    }
                })
                .catch((err) => {
                    openSnackBar(err.msg);
                    setRespAlert("");
                    setOpenApprove(false);
                    setComments("")

                });
        }

    };
    const rejectEmail = () => {
        let data = {
            id: id,
            comments: comments,
        }
        axios.post(`${config.url().baseURL}/reward/reject`, data)
            .then((resp) => {

                let respData = resp.data;
                if (!respData.err) {
                    setRespAlert(
                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title={respData.msg}
                            onConfirm={() => {
                                setRespAlert("");
                                fetchRedeemRewardTransaction()
                            }}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                    );
                    setOpenApprove(false);
                    setComments("")
                    setId("")

                } else {

                }
            })
            .catch((err) => {
                openSnackBar(err.msg);
                setRespAlert("");
                // setOpenApprove(false);
                setComments("")

            });

    };

    const viewEmail = (row) => {
        let data = {
            refNo: row.refNo,
            userID: row.userId,
            productId: row.productId,
            id: row._id,
            mailId: row.mailData.mailID,
            comments: "Viewed Email"
        }
        setOnBoardingViewModal(true);
        axios.post(`${config.url().baseURL}/view-email-template`, data).then((resp) => {
            let respData = resp.data
            if (!respData.err) {
                console.log(respData)
                if (respData !== undefined) {
                    setOnViewModel(respData);
                } else {
                    setOnBoardingViewModal(false);
                    openSnackBar("No log found.");
                }

            } else {
                setOnBoardingViewModal(false);
                openSnackBar(respData.msg);
            }
        });
    }

    const htmlContent = `${onViewModel}`;

    const doVoid = () => {
        //function to do void 
        if (transactionID && password) {
            try {
                if (!submitting) {
                    setSubmitting(true)
                    let userData = { email: user.email, password: password };
                    axios.post(`${config.url().baseURL}/sign-in-system-user`, userData).then((resp) => {
                        let respData = resp.data;
                        if (!respData.err) {
                            if (respData.token !== undefined) {
                                sessionStorage.setItem("cx_id", respData.token);
                                axios.post(`${config.url().appURL}/CXPortal/v1/cancelTransaction`, { transactionID: transactionID, userID: props.userID, cpr: props.cpr }, {
                                    headers: { auth: sessionStorage.getItem("cx_id"), },
                                }).then((resp) => {
                                    let respData = resp.data;
                                    if (!respData.err) {
                                        setSubmitting(false);
                                        setPassword("")
                                        setOpenPassword(false)
                                        setRespAlert(
                                            <SweetAlert
                                                success
                                                style={{ display: "block", zIndex: "214748364 !important" }}
                                                title="Void Success"
                                                onConfirm={() => {
                                                    setRespAlert(null);
                                                    setTransactionID("")
                                                    setPassword("")
                                                    setOpenPassword(false)
                                                }
                                                }
                                                confirmBtnCssClass={classes.button + " " + classes.success}
                                            />
                                        );
                                    } else {
                                        openSnackBar(respData.msg);
                                        setSubmitting(false);
                                        setPassword("")
                                    }
                                }).catch((err) => {
                                    openSnackBar("Unable to process.");
                                    setSubmitting(false);
                                    setPassword("")

                                });
                            } else {
                                openSnackBar(respData.msg);
                                setSubmitting(false);
                                setPassword("")
                            }
                        } else {
                            openSnackBar(respData.msg);
                            setPassword("")
                            setSubmitting(false);
                        }
                    }).catch((err) => {
                        openSnackBar("Unable to validate user.");
                        setSubmitting(false);

                    });
                }

            } catch (error) {
                openSnackBar("Unable to process.")
            }
        } else openSnackBar("Please enter password.")
    }

    const billOptions = {
        exportButton: { csv: true },
        showTitle: false,
        exportCsv: () => {
            let csvFile = creatCsvFile(billPaymentCSV);
            let fileName = "billPayment-transactions.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
    };

    const remittanceOptions = {
        exportButton: { csv: true },
        showTitle: false,
        exportCsv: () => {
            let csvFile = creatCsvFile(remittanceCSV);
            let fileName = "remittance-transactions.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
    };
    const MultiCurrencyOptions = {
        exportButton: { csv: true },
        showTitle: false,
        paging: false,
        rowStyle: rowData => {
            if (rowData.type !== "CREDIT") {
                return { backgroundColor: '#f0fcf0' };
            } else {
                return { backgroundColor: '#fcf0f0' };
            }
        },
        exportCsv: () => {
            let csvFile = creatCsvFile(multiCurrencyCSV);
            let fileName = "MultiCurrency-transactions.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
    };
    const rewardOptions = {
        exportButton: { csv: true },
        showTitle: false,
        paging: false,
        rowStyle: rowData => {
            if (rowData.type !== "CREDIT") {
                return { backgroundColor: '#f0fcf0' };
            } else {
                return { backgroundColor: '#fcf0f0' };
            }
        },
        exportCsv: () => {
            let csvFile = creatCsvFile(rewardCSV);
            let fileName = "Reward-transactions.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
    }
    const creditRewardOptions = {
        exportButton: { csv: true },
        showTitle: false,
        paging: false,
        rowStyle: rowData => {
            if (rowData.type !== "CREDIT") {
                return { backgroundColor: '#f0fcf0' };
            } else {
                return { backgroundColor: '#fcf0f0' };
            }
        },
        exportCsv: () => {
            let csvFile = creatCsvFile(creditRewardCSV);
            let fileName = "Redeem Reward.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
    }
    return (
        <div className={classes.root}>
            {respAlert}

            {/* Reward Log Modal */}
            <Dialog
                classes={{
                    root: classes.left + " " + classes.modalRoot,
                    paper: classes.companyStatusLogModal,
                }}
                open={onBoardingLogModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setOnBoardingLogModal(false);
                    // setBfcpModalTitle(false)
                    setOnBoardingLogs([]);
                }}
                maxWidth="xl"
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h3 style={{ textAlign: 'center' }}>Reward user status logs</h3>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <TableContainer className={classes.container}>
                        <MuiTable stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {logColumns.map((column) => (
                                        <TableCell
                                            key={column._id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {onBoardingLogs.map((row, key) => {
                                    let status = row.status;
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                                            <TableCell>
                                                {moment(row.date).format("DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell>
                                                {moment(row.date).format("HH:mm:ss")}
                                            </TableCell>
                                            <TableCell>
                                                {row.user ? row.user.firstName + " " + row.user.lastName : null}
                                            </TableCell>
                                            <TableCell>
                                                {status}
                                            </TableCell>
                                            <TableCell>
                                                {row.status_message}
                                            </TableCell>
                                            <TableCell>
                                                {row.remarks}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>

                        </MuiTable>
                    </TableContainer>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                        onClick={() => {
                            setOnBoardingLogModal(false);
                            setOnBoardingLogs([]);
                        }}
                        color="danger"
                        size="sm"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    {/* <Tab label="PG" {...a11yProps(0)} /> */}
                    <Tab label="Wallet" {...a11yProps(0)} />
                    <Tab label="Remittance" {...a11yProps(0)} />
                    <Tab label="Bill Payment" {...a11yProps(0)} />
                    <Tab label="MultiCurrency" {...a11yProps(0)} />
                    <Tab label="FLYY CREDIT" {...a11yProps(0)} />
                    <Tab label="FLYY DEBIT" {...a11yProps(0)} />
                    <Tab label="Redeem Reward" {...a11yProps(0)} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                className={classes.tabContent}
                onChangeIndex={handleChangeIndex}>
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <Collapse
                        in={noWallet}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Transactions Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!noWallet}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={wallet.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                        </Collapse>
                        <Collapse
                            in={wallet.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <MaterialTable
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={wallet}
                                columns={walletColumns}
                                options={walletOptions}

                            />
                            <Button
                                disabled={pageValue <= 1 ? true : false}
                                onClick={() => previousTransaction()}
                                color="primary"
                                size="sm">
                                Previous
                            </Button>
                            <Button
                                disabled={remainingDocs == 0 ? true : false}
                                onClick={() => nextTransaction()}
                                color="primary"
                                size="sm">
                                Next
                            </Button>
                        </Collapse>
                    </Collapse>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <Collapse
                        in={noRemittance}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Transactions Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!noRemittance}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={remittance.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                        </Collapse>
                        <Collapse
                            in={remittance.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <MaterialTable
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={remittance}
                                columns={p2pColumns}
                                options={remittanceOptions}
                            />

                        </Collapse>
                    </Collapse>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <Collapse
                        in={noBillPayment}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Transactions Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!noBillPayment}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={billPayment.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                        </Collapse>
                        <Collapse
                            in={billPayment.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <MaterialTable
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={billPayment}
                                columns={billPaymentColumns}
                                options={billOptions}
                            />
                        </Collapse>
                    </Collapse>
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <Collapse
                        in={noMultiCurrency}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Transactions Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!noMultiCurrency}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={multiCurrency.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <h4>No Transactions Found.</h4>
                        </Collapse>
                        <Collapse
                            in={multiCurrency.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <MaterialTable
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={multiCurrency}
                                columns={MultiColumns}
                                options={MultiCurrencyOptions}
                            /></Collapse>
                        <Button
                            disabled={mpageValue <= 0 ? true : false}
                            onClick={() => MPreviousTransaction()}
                            color="primary"
                            size="sm">
                            Previous
                        </Button>
                        <Button
                            disabled={mremainingDocs}
                            onClick={() => mNextTransaction()}
                            color="primary"
                            size="sm">
                            Next
                        </Button>

                    </Collapse>
                </TabPanel>
                <TabPanel value={value} index={4} dir={theme.direction}>
                    <Collapse
                        in={norewardData}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Transactions Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!norewardData}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={rewardData.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <h4>No Transactions Found.</h4>
                        </Collapse>
                        <Collapse
                            in={rewardData.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <MaterialTable
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={rewardData}
                                columns={rewardColumns}
                                options={rewardOptions}
                            />

                            <Button
                                disabled={rpageValue <= 0 ? true : false}
                                onClick={() => rewardPrevious()}
                                color="primary"
                                size="sm">
                                Previous
                            </Button>
                            <Button
                                disabled={rewardRemainingDocs <= 0 ? true : false}
                                onClick={() => rewardNext()}
                                color="primary"
                                size="sm">
                                Next
                            </Button>
                        </Collapse>
                    </Collapse>
                </TabPanel>
                <TabPanel value={value} index={5} dir={theme.direction}>
                    <Collapse
                        in={nodebitData}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Transactions Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!nodebitData}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={debitData.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <h4>No Transactions Found.</h4>
                        </Collapse>
                        <Collapse
                            in={debitData.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <MaterialTable
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={debitData}
                                columns={rewardColumns}
                                options={rewardOptions}
                            />

                            <Button
                                disabled={debitValue <= 0 ? true : false}
                                onClick={() => debitprevious()}
                                color="primary"
                                size="sm">
                                Previous
                            </Button>
                            <Button
                                disabled={debitRemainingDocs <= 0 ? true : false}
                                onClick={() => debitNext()}
                                color="primary"
                                size="sm">
                                Next
                            </Button>
                        </Collapse>
                    </Collapse>
                </TabPanel>
                <TabPanel value={value} index={6} dir={theme.direction}>
                    <Collapse
                        in={nocreditReward}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Transactions Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!nocreditReward}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={creditReward.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <h4>No Transactions Found.</h4>
                        </Collapse>
                        <Collapse
                            in={creditReward.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <MaterialTable
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={creditReward}
                                columns={creditRewardColumn}
                                options={creditRewardOptions}
                            />

                        </Collapse>


                    </Collapse>
                </TabPanel>
            </SwipeableViews>



            <Dialog onClose={() => {
                setOpenPassword(false);
                setPageValue(1);
            }} aria-labelledby="simple-dialog-title" PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }} open={openPassword}>
                <h4 className={classes.passwordHead}>Please enter password to continue.</h4>
                <Paper className={classes.dialogContainer}>
                    <InputBase
                        autoFocus={true}
                        fullWidth
                        className={classes.input}
                        placeholder="Password"
                        inputProps={{
                            type: "password",
                            onChange: (event) => { setPassword(event.target.value) },
                            value: password,
                        }}
                    />

                    <Button className={classes.nextButton}
                        onClick={() => doVoid()}
                        simple
                        size="lg" color="primary">
                        <ArrowForwardIcon fontSize="medium" />
                    </Button>

                </Paper>

            </Dialog>
            <Dialog open={open} onClose={handleClickClose}>
                <div style={{ width: '33rem' }}>
                    <CardContent>

                        <CustomInput
                            labelText={
                                <span>
                                    Update Email
                                </span>
                            }
                            inputProps={{
                                autoComplete: "off",
                                value: updateMailId,
                                onChange: (e) => {
                                    setUpdateMailID(e.target.value);
                                },

                            }}
                            value={updateMailId}
                            id="name"
                            type="text"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />

                        <CustomInput
                            error={values.remarksErr}

                            labelText={
                                <span>
                                    Comments

                                </span>
                            }
                            inputProps={{
                                autoComplete: "off",
                                value: comments,
                                onChange: (e) => {
                                    setComments(e.target.value);
                                    verify(e.target.value, "length", "remarksErr", 3)

                                },
                                onBlur: (e) => {
                                    setComments(e.target.value.trim());
                                }
                            }}
                            value={comments}
                            id="name"
                            type="text"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />

                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <div style={{ paddingTop: "3px", padding: "10px" }}>
                                <>
                                    {service.type === 'checker' && (
                                        <>updateEmailupdateEmailupdateEmail
                                            <Button style={{ backgroundColor: 'red', height: '40px' }} onClick={rejectEmail} size="sm">
                                                Recheck
                                            </Button>
                                            <Button style={{ backgroundColor: 'Darkblue', height: '40px' }} onClick={updateEmail} size="sm">
                                                Approve
                                            </Button>
                                        </>
                                    )}

                                    {service.type === 'maker' && (
                                        <>
                                            <Button style={{ backgroundColor: 'red', height: '40px' }} onClick={handleClickClose} size="sm">
                                                Cancel
                                            </Button>
                                            <Button style={{ backgroundColor: 'Darkblue', height: '40px' }} onClick={updateEmail} size="sm">
                                                Submit
                                            </Button>
                                        </>
                                    )}
                                </>


                            </div>
                        </div>
                    </CardContent>

                </div>
            </Dialog>
            <Dialog open={openApprove} onClose={handleApproveClose}>
                <div style={{ width: '33rem' }}>
                    <CardHeader>
                        <h4 style={{ marginBottom: "-30px" }}>Approve Email</h4>
                    </CardHeader>
                    <CardContent>
                        <CustomInput
                            error={values.remarksErr}

                            labelText={
                                <span>
                                    Comments
                                </span>
                            }
                            inputProps={{
                                autoComplete: "off",
                                value: comments,

                                onChange: (e) => {
                                    setComments(e.target.value);
                                    verify(e.target.value, "length", "remarksErr", 3)

                                },
                                onBlur: (e) => {
                                    setComments(e.target.value.trim());
                                }
                            }}
                            value={comments}
                            id="name"
                            type="text"
                            formControlProps={{
                                fullWidth: true,
                            }}


                        />
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>


                            <div style={{ paddingTop: "3px", padding: "10px" }}>
                                <>
                                    {service.type === 'checker' && (
                                        <>
                                            <Button style={{ backgroundColor: 'red', height: '40px' }} onClick={rejectEmail} size="sm">
                                                Recheck
                                            </Button>
                                            <Button style={{ backgroundColor: 'Darkblue', height: '40px' }} onClick={approveEmail} size="sm">
                                                Approve
                                            </Button>
                                        </>
                                    )}

                                    {service.type === 'maker' && (
                                        <>
                                            <Button style={{ backgroundColor: 'red', height: '40px' }} onClick={handleApproveClose} size="sm">
                                                Cancel
                                            </Button>
                                            <Button style={{ backgroundColor: 'Darkblue', height: '40px' }} onClick={approveEmail} size="sm">
                                                Submit
                                            </Button>
                                        </>
                                    )}
                                </>
                            </div>

                        </div>
                    </CardContent>
                </div>
            </Dialog>
            <Dialog
                open={onBoardingViewModal}
                onClose={() => {
                    setOnBoardingViewModal(false);
                    setOnViewModel([]);
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
                maxWidth="md"
                fullWidth={true}
            >
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />

                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setOnBoardingViewModal(false);
                            setOnViewModel([]);
                        }}
                        color="danger"
                        size="sm"
                        style={{
                            display: 'block',
                            margin: 'auto',
                            textAlign: 'center',
                        }}
                    >
                        Close
                    </Button>

                </DialogActions>
            </Dialog>

            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        // alert("Hey")
                        setOpenSnack(false);
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div >
    );
}
