import React, { useState, useEffect, useCallback } from 'react';
import {
    CardHeader,
    TableContainer,
    Table as MuiTable,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Tooltip,
    Tabs,
    Tab,
    TablePagination,
    Box,
    Typography
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import { makeStyles } from '@material-ui/core/styles';
import PropagateLoader from 'react-spinners/PropagateLoader';
import axios from 'axios';
import { useSelector } from 'react-redux';
import CustomerDetailsModal from './components/customerDetailsModal';
import config from '../../config';
import { showAlert, customerDetailsColumns, rejectCustomerDetailsColumns } from './complanceCustFns';

const TabbedTables = () => {
    const [value, setValue] = useState(0);
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    const [details, setDetails] = useState(null);
    const [customerDetails, setCustomerDetails] = useState([]);
    const [rejectCustomerDetails, setRejectCustomerDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [totalDoc, setTotalDoc] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [referenceNo, setReferenceNo] = useState('');
    const [isReload, setIsReload] = useState(false);

    const user = useSelector((state) => state.user);
    const service = user.services.find((service) => service.name === 'eKYC Compliance Queue');

    const handleReload = useCallback((data) => setIsReload(data), []);
    const handleChange = (_, newValue) => setValue(newValue);
    const handleChangePageSize = (event) => {
        setPageSize(+event.target.value);
        setPage(0);
    };
    const handleChangePage = (_, newPage) => setPage(newPage);

    const fetchData = useCallback(async (url, params = {}) => {
        try {
            const response = await axios.post(url, params);
            const data = response.data;

            if (data.err) {
                showAlert('error', 'Error!', `Error fetching ${params.type ? 'Rejected ' : ''}CustomerDetails`);
                throw new Error('Error fetching data.');
            }

            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to load data.');
            throw error;
        }
    }, []);

    useEffect(() => {
        const fetchDataForTab = async () => {
            setLoading(true);
            setError(null);

            const params = { page: page + 1, limit: pageSize };
            const url = `${config.url().appURL}/CXPortal/v20/shufti/compliance`;

            try {
                const data = value === 0
                    ? await fetchData(url, params)
                    : await fetchData(url, { ...params, type: 'REJECTED' });

                const dataSetter = value === 0 ? setCustomerDetails : setRejectCustomerDetails;
                dataSetter(Array.isArray(data.data) ? data.data : []);
                setTotalDoc(data.totalDocuments || 0);
            } finally {
                setLoading(false);
            }
        };

        fetchDataForTab();
    }, [value, pageSize, page, isReload, fetchData]);

    const handleViewDetails = async (row) => {
        setLoading(true);
        setDetails(null);

        try {
            const response = await axios.post(`${config.url().appURL}/CXPortal/v20/shufti/compliance/detialView`, {
                refShufti: row.referenceNumber,
            });

            const data = response.data;

            if (data.err) {
                showAlert('error', 'Error!', 'Error fetching details');
                throw new Error('Error fetching details.');
            }

            setReferenceNo(row.referenceNumber);
            setDetails(data.data);
            setDetailsModalOpen(true);
        } catch (error) {
            console.error('Error fetching details:', error);
            setError('Failed to load details.');
        } finally {
            setLoading(false);
        }
    };

    const renderTable = (data, columns, isTable2) => (
        <Card>
            <CardHeader color="primary">
                <h4>Compliance Customers</h4>
            </CardHeader>
            <CardBody>
                <TableContainer>
                    <MuiTable stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length} align="center">
                                        No details available
                                    </TableCell>
                                </TableRow>
                            ) : (
                                data.map((row, key) => (
                                    <TableRow
                                        key={key}
                                        hover
                                        style={{ backgroundColor: row.action === 'REJECTED' ? '#f8d7da' : '' }}
                                    >
                                        <TableCell>{moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss')}</TableCell>
                                        <TableCell>{row.cpr}</TableCell>
                                        <TableCell>{row.referenceNumber}</TableCell>
                                        <TableCell>{row.accountCategory}</TableCell>
                                        {isTable2 ? (
                                            <TableCell>{row.status.remarks || 'N/A'}</TableCell>
                                        ) : (
                                            <TableCell>
                                                <Tooltip title="View">
                                                    <IconButton
                                                        onClick={() => handleViewDetails(row)}
                                                        style={{ marginTop: '-10px', marginBottom: '-10px' }}
                                                        aria-label="View details"
                                                    >
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </MuiTable>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 30, 100]}
                    component="div"
                    count={totalDoc}
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangePageSize}
                />
            </CardBody>
        </Card>
    );

    return (
        <div>
            <Tabs value={value} onChange={handleChange} aria-label="tabs">
                <Tab label="Customer Profiles" />
                <Tab label="Rejected Profiles" />
            </Tabs>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="flex-start" height="100vh" paddingTop={2}>
                    <PropagateLoader color="#004fa4" loading={true} size={15} />
                </Box>
            ) : error ? (
                <Box display="flex" justifyContent="center" alignItems="flex-start" height="100vh" paddingTop={2}>
                    <Typography>{error}</Typography>
                </Box>
            ) : value === 0 ? (
                renderTable(customerDetails, customerDetailsColumns, false)
            ) : (
                renderTable(rejectCustomerDetails, rejectCustomerDetailsColumns, true)
            )}

            {/* Details Modal */}
            <CustomerDetailsModal
                open={detailsModalOpen}
                onClose={() => setDetailsModalOpen(false)}
                details={details}
                referenceNo={referenceNo}
                serviceType={service?.type}
                name={user.firstName}
                isReload={handleReload}
            />
        </div>
    );
};

export default TabbedTables;
