import React, { useContext, createContext, useState } from "react";
import axios from "axios";
import moment from "moment"; // Make sure to import the moment libraryn
import config from "../../../config";

// Create a context
const W2wContext = createContext();

// Create the context provider component
const W2wContextProvider = ({ children }) => {
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
    const [html, setHtml] = useState('');
    const [showLoader, setShowLoader] = useState(false); // Assuming you have this state as well

    const getW2wReport = async () => {
        try {
            setShowLoader(true)
            let resp = await axios.post(`${config.url().appURL}/bill/generate-w2w-report`, {
                "date": date
            });
            setHtml(resp.data.data.htmlString);
            setShowLoader(false)
        } catch (error) {
            console.log(error);
            setShowLoader(false)

        }
    };

    const getWBReport = async () => {
        try {
            setShowLoader(true)
            let resp = await axios.post(`${config.url().appURL}/bill/generate-wB-report`, {
                "date": date
            });
            setHtml(resp.data.data.htmlString);
            setShowLoader(false)
        } catch (error) {
            console.log(error);
            setShowLoader(false)

        }
    };


    const handleShareReport = async (type) => {
        try {
            let endPoint = {
                "W2wReport": "/bill/share-w2w-report",
                "WBReport": "/bill/share-wb-report",
                "MisReport": "/bill/share-mis-report"


            }
            let paylod = {
                date,
                type: type
            }

            let resp = await axios.post(`${config.url().appURL}${endPoint[type]}`, paylod)
            if (resp.data.status.err) throw new Error(resp.data.status.message)
            else return resp.data.status.message
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    const getMisReport = async () => {
        try {
            setShowLoader(true)
            let resp = await axios.post(`${config.url().appURL}/bill/mis`, {
                "date": date
            });
            setHtml(resp.data.data.htmlString);
            setShowLoader(false)

        } catch (error) {
            console.log(error)
            throw error
        }
    }

    // Define the context value object
    const contextValue = {
        date,
        setDate,
        html,
        getW2wReport,
        getMisReport,
        showLoader,
        handleShareReport,
        getWBReport
    };

    return (
        <W2wContext.Provider value={contextValue}>
            {children}
        </W2wContext.Provider>
    );
};

// Custom hook for consuming the context
const useW2wContext = () => {
    return useContext(W2wContext);
};

export { W2wContextProvider, useW2wContext };
