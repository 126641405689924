import * as React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import MuiAlert from "@material-ui/lab/Alert";
import { MenuItem, Select, Snackbar } from "@material-ui/core";
import { Grid, TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import CardFooter from "components/Card/CardFooter";
import axios from "axios";
import config from "config";
import { PropagateLoader } from "react-spinners";
import Dropzone from "react-dropzone";
import ImageUpload from "./imageUploader";
import libs from "controllers/libs";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import moment from "moment";
import { setMilliseconds } from "date-fns";

import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const override = css`
display: block;
margin: 32px auto;
border-color: red;
`;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "center",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400",
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px",
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff",
    },
    indicator: {
        backgroundColor: "white",
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddReward(props) {
    const classes = useStyles();
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [submitting, setSubmitting] = React.useState(false);
    const [contactIDBtnColor, setContactIDBtnColor] = React.useState("secondary");

    const formData = new FormData();
    const [inSubmit, setInSubmit] = React.useState(false)
    const [snackBarType, setSnackBarType] = React.useState('')

    const [firstName, setFirstName] = React.useState('');
    const [middleName,setMiddleName] = React.useState('')
    const [lastName, setLastName] = React.useState('');
    const [gender, setGender] = React.useState('');
    const [mobileNumber, setMobileNumber] = React.useState('');
    const [cpr, setCPR] = React.useState('');
    const [cprExpiry, setCPRExpiry] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [placeOfBirth, setPlaceOfBirth] = React.useState('');
    const [dateOfBirth, setDateOfBirth] = React.useState('');
    const [nationality, setNationality] = React.useState('');
    const [passportNumber, setPassportNumber] = React.useState('');
    const [passportExpiry, setPassportExpiry] = React.useState('');
    const [addressType, setAddressType] = React.useState('');
    const [flatNumber, setFlatNumber] = React.useState('');
    const [buildingNumber, setBuildingNumber] = React.useState('');
    const [roadNumber, setRoadNumber] = React.useState('');
    const [blockNumber, setBlockNumber] = React.useState('');
    const [preferredLanguage, setPreferredLanguage] = React.useState('');
    const [occupation, setOccupation] = React.useState('');
    const [validProofOfIdentification, setValidProofOfIdentification] = React.useState('CPR')

    const [disableCPR,setDisableCPR] = React.useState(false);
    const [disablefirstName,setDisablefirstName] = React.useState(false);
    const [disablelastName,setDisablelastName] = React.useState(false);
    const [disablemiddleName,setDisablemiddleName] = React.useState(false);
    // const [disabledateOfBirth,setDisabledateOfBirth] = React.useState(false);
    const [disablenationality,setDisbalenationality] = React.useState(false);
    const [diablevalidProofOfIdentification,setDisablevalidProofOfIdentification] = React.useState(false);
    const [disableplaceOfBirth,setDisableplaceOfBirth] = React.useState(false);


    const [cprFrontPage, setCprFrontPage] = React.useState('')
    const [cprBackPage, setCprBackPage] = React.useState('')
    const [personPhotoImage, setPersonPhotoImage] = React.useState('')
    const [consentDocument, setConsentDocument] = React.useState('')
    const [passportFrontPage, setPassportFrontPage] = React.useState('')
    const [passportBackPage, setPassportBackPage] = React.useState('')
    const [otherDoc, setOtherDOc] = React.useState('')
    const [cprFrontPageName, setCprFrontPageNAme] = React.useState('')
    const [cprBackPageName, setCprbackPageName] = React.useState('')
    const [passportFrontPageName, setPassportFrontPageName] = React.useState('')
    const [passportBackPageName, setPassportBackPageNAme] = React.useState('')
    const [consentDocName, setConsentDocName] = React.useState('')
    const [personPhotoImageName, setPersonPhotoImageName] = React.useState('')
    const [cprFrontPageParent, setCPRFrontPageParent] = React.useState('')
    const [cprBackPageParent, setCPRBackPageParent] = React.useState('')
    const [cprFrontPageParentName, setCPRFrontPageParentName] = React.useState('')
    const [cprBackPageParentName, setCPRBackPageParentName] = React.useState('')
    const [uploadImg, setUploadImg] = React.useState();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    

    const [values, setValues] = React.useState({

        firstNameErr: null,
        lastNameErr: null,
        genderErr: null,
        emailErr: null,
        mobileNumberErr: null,
        cprErr: null,
        cprExpiryErr: null,
        nationalityErr: null,
        placeOfBirthErr: null,
        dateOfBirthErr: null,
        passportNumberErr: null,
        passportExpiryErr: null,
        addressTypeErr: null,
        flatNumberErr: null,
        buildingNumberErr: null,
        blockNumberErr: null,
        roadNumberErr: null,
        preferredLanguageErr: null,
        occupationErr: null,
        cprFrontPageErr: null,
        cprbackPageErr: null,
        passportFrontPageErr: null,
        passportBackPageErr: null,
        consentDocErr: null,
        personPhotoImageErr: null,
    });



    const handleFirstNameChange = (event) => {
        const inputValue = event.target.value;
        if (/^[A-Za-z\s]*$/.test(inputValue) || inputValue === '') {
            setFirstName(inputValue);
        }
    };
    const handleMiddleNameChange = (event) => {
        const inputValue = event.target.value;
        if (/^[A-Za-z\s]*$/.test(inputValue) || inputValue === '') {
            setMiddleName(inputValue);
        }
    };

    const handleLastNameChange = (event) => {
        const inputValue = event.target.value;
        if (/^[A-Za-z\s]*$/.test(inputValue) || inputValue === '') {
            setLastName(inputValue);
        }
    };
    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };
    const handleMobileNumberChange = (event) => {
        let inputValue = event.target.value;
        const maxLength = event.target.maxLength;
        inputValue = inputValue.replace(/\D/g, '');
        if (inputValue.length > 0 && !['3', '6'].includes(inputValue.charAt(0))) {
            inputValue = inputValue.slice(1);
        }
        if (inputValue.length > maxLength) {
            inputValue = inputValue.slice(0, maxLength);
        }

        setMobileNumber(inputValue);
    }
    const handleCPRChange = (event) => {
        const enteredValue = event.target.value;
        if (enteredValue.includes('.')) {
            const newValue = enteredValue.replace('.', '');
            setCPR(newValue);
            verify(newValue, "length", "cprNumberErr", 3);
        } else {
            if (enteredValue.length <= 9 && !isNaN(enteredValue)) {
                setCPR(enteredValue);
                verify(enteredValue, "length", "cprNumberErr", 3);
            }
        }
    };
    const handleCPRExpiryChange = (event) => {
        const enteredValue = event.target.value;
        const currentDate = new Date().toISOString().split('T')[0];
        if (enteredValue >= currentDate) {
            setCPRExpiry(enteredValue);
        }
    };

    const handleEmailChange = (event) => {
        const enteredValue = event.target.value;
        if (/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(enteredValue) || enteredValue === '') {
            setEmail(enteredValue);
        }
    };
    const handlePlaceOfBirthChange = (event) => {
        const enteredValue = event.target.value;
        if (/^[A-Za-z0-9 ]*$/.test(enteredValue) || enteredValue === '') {
            setPlaceOfBirth(enteredValue);
        }
    };
    const handleDateOfBirthChange = (event) => {
        const enteredValue = event.target.value;
        const currentDate = new Date().toISOString().split('T')[0];
        if (enteredValue <= currentDate) {
            setDateOfBirth(enteredValue);
        }
    };
    const handleNationalityChange = (event) => {
        setNationality(event.target.value);
    };

    const handlePassportNumberChange = (event) => {
        const enteredValue = event.target.value;
        if (/^[A-Za-z0-9]*$/.test(enteredValue) || enteredValue === '') {
            if (enteredValue.length <= 15) {
                setPassportNumber(enteredValue);
            }
        }
    };
    const handlePassportExpiryChange = (event) => {
        setPassportExpiry(event.target.value);
    };
    const handleAddressTypeChange = (event) => {
        setAddressType(event.target.value);
    };

    const handleFlatNumberChange = (event) => {
        const enteredValue = event.target.value;
        if (/^[A-Za-z0-9]*$/.test(enteredValue) || enteredValue === '') {
            if (enteredValue.length <= 10) {
                setFlatNumber(enteredValue);
            }
        }
    };
    const handleBuildingNumberChange = (event) => {
        const enteredValue = event.target.value;
        if (/^[A-Za-z0-9]*$/.test(enteredValue) || enteredValue === '') {
            if (enteredValue.length <= 20) {
                setBuildingNumber(enteredValue);
            }
        }
    };
    const handleRoadNumberChange = (event) => {
        const enteredValue = event.target.value;
        if (/^[A-Za-z0-9]*$/.test(enteredValue) || enteredValue === '') {
            if (enteredValue.length <= 20) {
                setRoadNumber(enteredValue);
            }
        }
    };
    const handleBlockNumberChange = (event) => {
        const enteredValue = event.target.value;
        if (/^[A-Za-z0-9]*$/.test(enteredValue) || enteredValue === '') {
            if (enteredValue.length <= 10) {
                setBlockNumber(enteredValue);
            }
        }
    };
    const handlePreferredLanguageChange = (event) => {
        setPreferredLanguage(event.target.value);
    };
    const handleOccupationChange = (event) => {
        const enteredValue = event.target.value;
        if (/^[A-Za-z\s]*$/.test(enteredValue) || enteredValue === '') {
            if (enteredValue.length <= 30) {
                setOccupation(enteredValue);
            }
        }
    };
    const validProofOfIdentifications = (event) => {
        const inputValue = event.target.value;
        if (/^[A-Za-z\s]*$/.test(inputValue) || inputValue === '') {
            setValidProofOfIdentification(inputValue);
        }
    };

    React.useEffect(() => {
        setValues({
            ...values,
            firstNameErr: false,
            lastNameErr: false,
            genderErr: false,
            emailErr: false,
            mobileNumberErr: false,
            cprErr: false,
            cprExpiryErr: false,
            nationalityErr: false,
            dateOfBirthErr: false,
            placeOfBirthErr: false,
            passportNumberErr: false,
            passportExpiryErr: false,
            addressTypeErr: false,
            flatNumberErr: false,
            buildingNumberErr: false,
            blockNumberErr: false,
            roadNumberErr: false,
            preferredLanguageErr: false,
            occupationErr: false,
        })

    }, [])
    const validateGPWForm = () => {
        let flag = true;
        let firstNameErr = false;
        let lastNameErr = false;
        let genderErr = false;
        let emailErr = false;
        let mobileNumberErr = false;
        let cprErr = false;
        let cprExpiryErr = false;
        let nationalityErr = false;
        let dateOfBirthErr = false;
        let placeOfBirthErr = false;
        // let passportNumberErr = false;
        // let passportExpiryErr = false;
        let addressTypeErr = false;
        let flatNumberErr = false;
        let buildingNumberErr = false;
        let blockNumberErr = false;
        let roadNumberErr = false;
        let preferredLanguageErr = false;
        let occupationErr = false;
        let snackBarMsg = "";

        if (firstName.trim() === "") {
            firstNameErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please enter FirstName" : snackBarMsg);
            setOpenSnack(true)
            flag = false
        }
        if (lastName.trim() === "") {
            lastNameErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please enter LastName" : snackBarMsg);
            flag = false
        }
        if (gender.trim() === "") {
            genderErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please enter Gender" : snackBarMsg);
            flag = false
        }
        if (email.trim() === "") {
            emailErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please enter email" : snackBarMsg);
            flag = false
        }
        if (mobileNumber.trim() === "") {
            mobileNumberErr = true;
            snackBarMsg = snackBarMsg === "" ? "Please enter Mobile Number" : snackBarMsg;
            flag = false;
        } else if (mobileNumber.trim().length !== 8) {
            mobileNumberErr = true;
            snackBarMsg = "Mobile Number must be 8 digits ";
            flag = false;
        }

        if (cpr.trim() === "") {
            cprErr = true;
            snackBarMsg = "Please enter CPR Number";
            flag = false;
        } else if (cpr.length < 9) {
            cprErr = true;
            snackBarMsg = "CPR must be 9 digits";
            flag = false;
        }
        if (cprExpiry === "Invalid date" || cprExpiry === "") {
            snackBarMsg = (snackBarMsg === "" ? "Please select CprExpiry Date" : snackBarMsg);
            flag = false
            cprExpiryErr = true
        }
        if (nationality.trim() === "") {
            nationalityErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please enter Nationality" : snackBarMsg);
            flag = false
        }
        if (dateOfBirth === "Invalid date" || dateOfBirth === "") {
            dateOfBirthErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please enter Date Of Birth" : snackBarMsg);
            flag = false;
        }

        if (placeOfBirth.trim() === "") {
            placeOfBirthErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please enter Place of birth" : snackBarMsg)
            flag = false
        }
        // if (passportNumber.trim() === "") {
        // passportNumberErr = true;
        // snackBarMsg = (snackBarMsg === "" ? "Please enter Passport Number" : snackBarMsg)
        // flag = false
        // }
        // if (passportExpiry === "Invalid date" || passportExpiry === "") {
        // passportExpiryErr = true;
        // snackBarMsg = (snackBarMsg === "" ? "Please enter Passport Expiry" : snackBarMsg);
        // flag = false;
        // }

        if (addressType.trim() === "") {
            addressTypeErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please enter Address Type" : snackBarMsg);
            flag = false
        }
        if (flatNumber.trim() === "") {
            flatNumberErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please enter Flat Number" : snackBarMsg);
            flag = false
        }
        if (blockNumber.trim() === "") {
            blockNumberErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please enter Block Number" : snackBarMsg);
            flag = false
        }
        if (buildingNumber.trim() === "") {
            buildingNumberErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please enter Building Number" : snackBarMsg);
            flag = false
        }
        if (roadNumber.trim() === "") {
            roadNumberErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please enter Road Number" : snackBarMsg);
            flag = false
        }
        if (preferredLanguage.trim() === "") {
            preferredLanguageErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please select langauage" : snackBarMsg);
            flag = false
        }
        if (occupation.trim() === "") {
            occupationErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please enter occupation" : snackBarMsg);
            flag = false
        }
        setValues({
            ...values,
            firstNameErr: false,
            lastNameErr: false,
            genderErr: false,
            emailErr: false,
            mobileNumberErr: false,
            cprErr: false,
            cprExpiryErr: false,
            nationalityErr: false,
            dateOfBirthErr: false,
            placeOfBirthErr: false,
            // passportNumberErr: false,
            // passportExpiryErr: false,
            addressTypeErr: false,
            flatNumberErr: false,
            buildingNumberErr: false,
            blockNumberErr: false,
            roadNumberErr: false,
            preferredLanguageErr: false,
            occupationErr: false,

        })
        if (values.firstNameErr == true || values.lastNameErr == true || values.genderErr == true || values.emailErr == true || values.mobileNumberErr == true || values.cprErr == true || values.nationalityErr == true || values.passportExpiryErr == true || values.dateOfBirthErr == true || values.placeOfBirthErr == true || values.addressTypeErr == true || values.flatNumberErr == true || values.buildingNumberErr == true || values.blockNumberErr == true || values.roadNumberErr == true || values.preferredLanguageErr == true || values.occupationErr == true) {
            flag = false;
        }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag;

    }

    const verify = (value, type, errVariable) => {

        switch (type) {

            case "email":

                if (libs.validate(value, "email", 30)) {

                    setValues({ ...values, [errVariable]: false });

                } else {

                    setValues({ ...values, [errVariable]: true });

                }

                break;

            default:

                break;

        }

    };



    const handleDoc = (type, event) => {

        let dataUpload = new FormData();
        let file = event[0];
        console.log(file);

        switch (type) {
            case "CPR_FRONT_PAGE":
                setUploadImg(type)
                setCprFrontPage(file)
                setCprFrontPageNAme(file.name)
                dataUpload.append("cprFrontPage", file);
                break
            case "CPR_BACK_PAGE":
                setUploadImg(type)
                setCprBackPage(file)
                setCprbackPageName(file.name)
                dataUpload.append("cprBackPage", file)
                break
            case "PERSON_PHOTO_IMAGE":
                setUploadImg(type)
                setPersonPhotoImage(file)
                setPersonPhotoImageName(file.name)
                dataUpload.append("personPhotoImage", file)
                break
            case "CONSENT_DOC":
                setUploadImg(type)
                setConsentDocument(file)
                setConsentDocName(file.name)
                dataUpload.append("consentDocument", file)
                break
            case "PASSPORT_FRONT_PAGE":
                setUploadImg(type)
                setPassportFrontPage(file)
                setPassportFrontPageName(file.name)
                dataUpload.append("passportFrontPage", file)
                break
            case "PASSPORT_BACK_PAGE":
                setUploadImg(type)
                setPassportBackPage(file)
                setPassportBackPageNAme(file.name)
                dataUpload.append("passportBackPage", file)
                break
            case "PARENT/GUARDIAN_CPR_FRONT_PAGE":
                setUploadImg(type)
                setCPRFrontPageParent(file)
                setCPRFrontPageParentName(file.name)
                dataUpload.append("cprFrontPageParent", file)
                break
            case "PARENT/GUARDIAN_CPR_BACK_PAGE":
                setUploadImg(type)
                setCPRBackPageParent(file)
                setCPRBackPageParentName(file.name)
                dataUpload.append("cprBackPageParent", file)
                break


            case "OTHER_DOC":
                setOtherDOc(file)
                break
        }
        try {
            dataUpload.append("cpr", cpr);
            //dataUpload.append("cprFrontPage", cprFrontPage);
            console.log(cprFrontPage);
            axios.post(`${config.url().baseURL}/upload-gpw-minor-doc`, dataUpload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then((resp) => {
                    let respData = resp.data;
                    if (!respData.error) {
                    } else {
                        openSnackBar(respData.msg);
                    }
                });

        } catch (err) {
            snackBarMsg("Something Unexpected Happend")
        }
    }

    const handleBack = () => {
        props.back()
        setFirstName("")
        setMiddleName("")
        setLastName("")
        setMobileNumber("")
        setCPR("")
        setCPRExpiry("")
        setEmail("")
        setPlaceOfBirth("")
        setDateOfBirth("")
        setNationality("")
        setPassportNumber("")
        setPassportExpiry("")
        setAddressType("")
        setFlatNumber("")
        setBuildingNumber("")
        setRoadNumber("")
        setBlockNumber("")
        setPreferredLanguage("")
        setOccupation("")
        setValidProofOfIdentification("")
        setValues({
            ...values,
            firstNameErr: false,
            lastNameErr: false,
            genderErr: false,
            emailErr: false,
            mobileNumberErr: false,
            cprErr: false,
            cprExpiryErr: false,
            nationalityErr: false,
            dateOfBirthErr: false,
            placeOfBirthErr: false,
            passportNumberErr: false,
            passportExpiryErr: false,
            addressTypeErr: false,
            flatNumberErr: false,
            buildingNumberErr: false,
            blockNumberErr: false,
            roadNumberErr: false,
            preferredLanguageErr: false,
            occupationErr: false,

        })
    }

    const handleSubmit = async () => {
        const formattedDateOfBirth = moment(dateOfBirth).format('DD-MMM-YYYY');

        if (validateGPWForm()) {

            setInSubmit(true)
            formData.append("firstName", firstName);
            formData.append("middleName",middleName);
            formData.append("lastName", lastName);
            formData.append("gender", gender);
            formData.append("cpr", cpr);
            formData.append("cprExpiry", cprExpiry);
            formData.append("mobile", mobileNumber);
            formData.append("email", email);
            formData.append("placeOfBirth", placeOfBirth);
            formData.append("dateOfBirth", formattedDateOfBirth);
            formData.append("nationality", nationality);
            formData.append("passportNumber", passportNumber);
            formData.append("passportExpiry", passportExpiry);
            formData.append("addressType", addressType);
            formData.append("flatNumber", flatNumber);
            formData.append("buildingNumber", buildingNumber);
            formData.append("blockNumber", blockNumber);
            formData.append("roadNumber", roadNumber);
            formData.append("preferredCommunicationLanguage", preferredLanguage);
            formData.append("occupation", occupation);
            formData.append("validProofOfIdentification", validProofOfIdentification);

            try {
                const resp = await axios
                    .put(`${config.url().baseURL}/edit-user-details`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((resp) => {

                        if (resp.data.err) {
                            openSnackBar(resp.data.msg, false);
                        } else {
                            setFirstName("");
                            setMiddleName("");
                            setLastName("");
                            setMobileNumber("");
                            setCPR("");
                            setCPRExpiry("");
                            setEmail("");
                            setPlaceOfBirth("");
                            setDateOfBirth("");
                            setNationality("");
                            setPassportNumber("");
                            setPassportExpiry("");
                            setAddressType("");
                            setFlatNumber("");
                            setBuildingNumber("");
                            setRoadNumber("");
                            setBlockNumber("");
                            setPreferredLanguage("");
                            setOccupation("");
                            setValidProofOfIdentification("")
                            props.back();
                        }
                    });


                setSubmitting(false);
            } catch (err) {
                console.log(err);
                setSnackBarMsg("Something Unexpected Happend")
            }

        }

    }

    function openSnackBar(msg, success) {
        setOpenSnack(true);
        if (success) {
            setSnackBarType("success");
        } else {
            setSnackBarType("error");
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, "$1"));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    React.useEffect(() => {
        handleFetchRewardData()

    }, [])

    const handleFetchRewardData = async () => {
        let isKycCreated;
        try {
            const resp = await axios.get(`${config.url().baseURL}/get-gpw-user-details/${props.cpr.cpr}`)

            const data = resp?.data?.data
            setFirstName(data?.firstName)
            setMiddleName(data?.middleName)
            setLastName(data?.lastName)
            setMobileNumber(data?.mobile)
            setGender(data?.gender)
            setCPR(data?.cpr)
            setCPRExpiry(data?.cprExpiry)
            setEmail(data?.email)
            setNationality(data?.nationality)
            setPlaceOfBirth(data?.placeOfBirth)
            setDateOfBirth(moment(data?.dateOfBirth).format("YYYY-MM-DD"))
            setPassportNumber(data?.passportNumber)
            setPassportExpiry(data?.passportExpiry)
            setAddressType(data?.addressType)
            setFlatNumber(data?.flatNumber)
            setBuildingNumber(data?.buildingNumber)
            setRoadNumber(data?.roadNumber)
            setBlockNumber(data?.blockNumber)
            setPreferredLanguage(data?.preferredCommunicationLanguage)
            setOccupation(data?.occupation)
            setValidProofOfIdentification(data?.validProofOfIdentification)
            setCprFrontPage(data?.cprFrontPage)
            setCprBackPage(data?.cprBackPage)
            setPersonPhotoImage(data?.personPhotoImage)
            setPassportFrontPage(data?.passportBackPage)
            setPassportBackPage(data?.passportBackPage)
            setConsentDocument(data?.consentDocument)
            setCPRBackPageParent(data?.cprBackPageParent)
            setCPRFrontPageParent(data?.cprFrontPageParent)

            isKycCreated = data.isKycCreated;

            if(data.cpr && data.cpr){
                setDisableCPR(true)
            }     
            if (data.firstName && isKycCreated === true) {
                setDisablefirstName(true);
            } else if (data.firstName && isKycCreated === false) {
                setDisablefirstName(false);
            }        
            if (data.lastName && isKycCreated === true) {
                setDisablelastName(true);
            } else if (data.lastName && isKycCreated === false) {
                setDisablelastName(false);
            }        
            if (data.middleName && isKycCreated === true) {
                setDisablemiddleName(true);
            } else if (data.middleName && isKycCreated === false) {
                setDisablemiddleName(false);
            }        
            if (data.placeOfBirth && isKycCreated === true) {
                setDisableplaceOfBirth(true);
            } else if (data.placeOfBirth && isKycCreated === false) {
                setDisableplaceOfBirth(false);
            }        
            if (data.nationality && isKycCreated === true) {
                setDisbalenationality(true);
            } else if (data.nationality && isKycCreated === false) {
                setDisbalenationality(false);
            }        
            if (data.validProofOfIdentification && isKycCreated === true) {
                setDisablevalidProofOfIdentification(true);
            } else if (data.validProofOfIdentification && isKycCreated === false) {
                setDisablevalidProofOfIdentification(false);
            }        
           

        } catch (err) {
            console.log(err);
            props.snack(err)
        }

    }

    function handleImageDelete(type) {
        switch (type) {
            case "CPR_FRONT_PAGE":
                setCprFrontPage(null)
                setCprFrontPageNAme('')
                break
            case "CPR_BACK_PAGE":
                setCprBackPage(null)
                setCprbackPageName('')
                break
            case "PERSON_PHOTO_IMAGE":
                setPersonPhotoImage(null)
                setPersonPhotoImageName('')
                break
            case "CONSENT_DOC":
                setConsentDocument(null)
                setConsentDocName('')
                break
            case "PASSPORT_FRONT_PAGE":
                setPassportFrontPage(null)
                setPassportFrontPageName('')
                break
            case "PASSPORT_BACK_PAGE":
                setPassportBackPage(null)
                setPassportBackPageNAme('')
                break
            case "PARENT/GUARDIAN_CPR_FRONT_PAGE":
                setCPRFrontPageParent(null)
                setCPRFrontPageParentName('')
                break
            case "PARENT/GUARDIAN_CPR_BACK_PAGE":
                setCPRBackPageParent(null)
                setCPRBackPageParentName('')
        }

    }

    const countries = [
        { code: 'AD', label: 'Andorra', phone: '376' },
        {
            code: 'AE',
            label: 'United Arab Emirates',
            phone: '971',
        },
        { code: 'AF', label: 'Afghanistan', phone: '93' },
        {
            code: 'AG',
            label: 'Antigua and Barbuda',
            phone: '1-268',
        },
        { code: 'AI', label: 'Anguilla', phone: '1-264' },
        { code: 'AL', label: 'Albania', phone: '355' },
        { code: 'AM', label: 'Armenia', phone: '374' },
        { code: 'AO', label: 'Angola', phone: '244' },
        { code: 'AQ', label: 'Antarctica', phone: '672' },
        { code: 'AR', label: 'Argentina', phone: '54' },
        { code: 'AS', label: 'American Samoa', phone: '1-684' },
        { code: 'AT', label: 'Austria', phone: '43' },
        {
            code: 'AU',
            label: 'Australia',
            phone: '61',
            suggested: true,
        },
        { code: 'AW', label: 'Aruba', phone: '297' },
        { code: 'AX', label: 'Alland Islands', phone: '358' },
        { code: 'AZ', label: 'Azerbaijan', phone: '994' },
        {
            code: 'BA',
            label: 'Bosnia and Herzegovina',
            phone: '387',
        },
        { code: 'BB', label: 'Barbados', phone: '1-246' },
        { code: 'BD', label: 'Bangladesh', phone: '880' },
        { code: 'BE', label: 'Belgium', phone: '32' },
        { code: 'BF', label: 'Burkina Faso', phone: '226' },
        { code: 'BG', label: 'Bulgaria', phone: '359' },
        { code: 'BH', label: 'Bahrain', phone: '973' },
        { code: 'BI', label: 'Burundi', phone: '257' },
        { code: 'BJ', label: 'Benin', phone: '229' },
        { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
        { code: 'BM', label: 'Bermuda', phone: '1-441' },
        { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
        { code: 'BO', label: 'Bolivia', phone: '591' },
        { code: 'BR', label: 'Brazil', phone: '55' },
        { code: 'BS', label: 'Bahamas', phone: '1-242' },
        { code: 'BT', label: 'Bhutan', phone: '975' },
        { code: 'BV', label: 'Bouvet Island', phone: '47' },
        { code: 'BW', label: 'Botswana', phone: '267' },
        { code: 'BY', label: 'Belarus', phone: '375' },
        { code: 'BZ', label: 'Belize', phone: '501' },
        {
            code: 'CA',
            label: 'Canada',
            phone: '1',
            suggested: true,
        },
        {
            code: 'CC',
            label: 'Cocos (Keeling) Islands',
            phone: '61',
        },
        {
            code: 'CD',
            label: 'Congo, Democratic Republic of the',
            phone: '243',
        },
        {
            code: 'CF',
            label: 'Central African Republic',
            phone: '236',
        },
        {
            code: 'CG',
            label: 'Congo, Republic of the',
            phone: '242',
        },
        { code: 'CH', label: 'Switzerland', phone: '41' },
        { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
        { code: 'CK', label: 'Cook Islands', phone: '682' },
        { code: 'CL', label: 'Chile', phone: '56' },
        { code: 'CM', label: 'Cameroon', phone: '237' },
        { code: 'CN', label: 'China', phone: '86' },
        { code: 'CO', label: 'Colombia', phone: '57' },
        { code: 'CR', label: 'Costa Rica', phone: '506' },
        { code: 'CU', label: 'Cuba', phone: '53' },
        { code: 'CV', label: 'Cape Verde', phone: '238' },
        { code: 'CW', label: 'Curacao', phone: '599' },
        { code: 'CX', label: 'Christmas Island', phone: '61' },
        { code: 'CY', label: 'Cyprus', phone: '357' },
        { code: 'CZ', label: 'Czech Republic', phone: '420' },
        {
            code: 'DE',
            label: 'Germany',
            phone: '49',
            suggested: true,
        },
        { code: 'DJ', label: 'Djibouti', phone: '253' },
        { code: 'DK', label: 'Denmark', phone: '45' },
        { code: 'DM', label: 'Dominica', phone: '1-767' },
        {
            code: 'DO',
            label: 'Dominican Republic',
            phone: '1-809',
        },
        { code: 'DZ', label: 'Algeria', phone: '213' },
        { code: 'EC', label: 'Ecuador', phone: '593' },
        { code: 'EE', label: 'Estonia', phone: '372' },
        { code: 'EG', label: 'Egypt', phone: '20' },
        { code: 'EH', label: 'Western Sahara', phone: '212' },
        { code: 'ER', label: 'Eritrea', phone: '291' },
        { code: 'ES', label: 'Spain', phone: '34' },
        { code: 'ET', label: 'Ethiopia', phone: '251' },
        { code: 'FI', label: 'Finland', phone: '358' },
        { code: 'FJ', label: 'Fiji', phone: '679' },
        {
            code: 'FK',
            label: 'Falkland Islands (Malvinas)',
            phone: '500',
        },
        {
            code: 'FM',
            label: 'Micronesia, Federated States of',
            phone: '691',
        },
        { code: 'FO', label: 'Faroe Islands', phone: '298' },
        {
            code: 'FR',
            label: 'France',
            phone: '33',
            suggested: true,
        },
        { code: 'GA', label: 'Gabon', phone: '241' },
        { code: 'GB', label: 'United Kingdom', phone: '44' },
        { code: 'GD', label: 'Grenada', phone: '1-473' },
        { code: 'GE', label: 'Georgia', phone: '995' },
        { code: 'GF', label: 'French Guiana', phone: '594' },
        { code: 'GG', label: 'Guernsey', phone: '44' },
        { code: 'GH', label: 'Ghana', phone: '233' },
        { code: 'GI', label: 'Gibraltar', phone: '350' },
        { code: 'GL', label: 'Greenland', phone: '299' },
        { code: 'GM', label: 'Gambia', phone: '220' },
        { code: 'GN', label: 'Guinea', phone: '224' },
        { code: 'GP', label: 'Guadeloupe', phone: '590' },
        { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
        { code: 'GR', label: 'Greece', phone: '30' },
        {
            code: 'GS',
            label: 'South Georgia and the South Sandwich Islands',
            phone: '500',
        },
        { code: 'GT', label: 'Guatemala', phone: '502' },
        { code: 'GU', label: 'Guam', phone: '1-671' },
        { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
        { code: 'GY', label: 'Guyana', phone: '592' },
        { code: 'HK', label: 'Hong Kong', phone: '852' },
        {
            code: 'HM',
            label: 'Heard Island and McDonald Islands',
            phone: '672',
        },
        { code: 'HN', label: 'Honduras', phone: '504' },
        { code: 'HR', label: 'Croatia', phone: '385' },
        { code: 'HT', label: 'Haiti', phone: '509' },
        { code: 'HU', label: 'Hungary', phone: '36' },
        { code: 'ID', label: 'Indonesia', phone: '62' },
        { code: 'IE', label: 'Ireland', phone: '353' },
        { code: 'IL', label: 'Israel', phone: '972' },
        { code: 'IM', label: 'Isle of Man', phone: '44' },
        { code: 'IN', label: 'India', phone: '91' },
        {
            code: 'IO',
            label: 'British Indian Ocean Territory',
            phone: '246',
        },
        { code: 'IQ', label: 'Iraq', phone: '964' },
        {
            code: 'IR',
            label: 'Iran, Islamic Republic of',
            phone: '98',
        },
        { code: 'IS', label: 'Iceland', phone: '354' },
        { code: 'IT', label: 'Italy', phone: '39' },
        { code: 'JE', label: 'Jersey', phone: '44' },
        { code: 'JM', label: 'Jamaica', phone: '1-876' },
        { code: 'JO', label: 'Jordan', phone: '962' },
        {
            code: 'JP',
            label: 'Japan',
            phone: '81',
            suggested: true,
        },
        { code: 'KE', label: 'Kenya', phone: '254' },
        { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
        { code: 'KH', label: 'Cambodia', phone: '855' },
        { code: 'KI', label: 'Kiribati', phone: '686' },
        { code: 'KM', label: 'Comoros', phone: '269' },
        {
            code: 'KN',
            label: 'Saint Kitts and Nevis',
            phone: '1-869',
        },
        {
            code: 'KP',
            label: "Korea, Democratic People's Republic of",
            phone: '850',
        },
        { code: 'KR', label: 'Korea, Republic of', phone: '82' },
        { code: 'KW', label: 'Kuwait', phone: '965' },
        { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
        { code: 'KZ', label: 'Kazakhstan', phone: '7' },
        {
            code: 'LA',
            label: "Lao People's Democratic Republic",
            phone: '856',
        },
        { code: 'LB', label: 'Lebanon', phone: '961' },
        { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
        { code: 'LI', label: 'Liechtenstein', phone: '423' },
        { code: 'LK', label: 'Sri Lanka', phone: '94' },
        { code: 'LR', label: 'Liberia', phone: '231' },
        { code: 'LS', label: 'Lesotho', phone: '266' },
        { code: 'LT', label: 'Lithuania', phone: '370' },
        { code: 'LU', label: 'Luxembourg', phone: '352' },
        { code: 'LV', label: 'Latvia', phone: '371' },
        { code: 'LY', label: 'Libya', phone: '218' },
        { code: 'MA', label: 'Morocco', phone: '212' },
        { code: 'MC', label: 'Monaco', phone: '377' },
        {
            code: 'MD',
            label: 'Moldova, Republic of',
            phone: '373',
        },
        { code: 'ME', label: 'Montenegro', phone: '382' },
        {
            code: 'MF',
            label: 'Saint Martin (French part)',
            phone: '590',
        },
        { code: 'MG', label: 'Madagascar', phone: '261' },
        { code: 'MH', label: 'Marshall Islands', phone: '692' },
        {
            code: 'MK',
            label: 'Macedonia, the Former Yugoslav Republic of',
            phone: '389',
        },
        { code: 'ML', label: 'Mali', phone: '223' },
        { code: 'MM', label: 'Myanmar', phone: '95' },
        { code: 'MN', label: 'Mongolia', phone: '976' },
        { code: 'MO', label: 'Macao', phone: '853' },
        {
            code: 'MP',
            label: 'Northern Mariana Islands',
            phone: '1-670',
        },
        { code: 'MQ', label: 'Martinique', phone: '596' },
        { code: 'MR', label: 'Mauritania', phone: '222' },
        { code: 'MS', label: 'Montserrat', phone: '1-664' },
        { code: 'MT', label: 'Malta', phone: '356' },
        { code: 'MU', label: 'Mauritius', phone: '230' },
        { code: 'MV', label: 'Maldives', phone: '960' },
        { code: 'MW', label: 'Malawi', phone: '265' },
        { code: 'MX', label: 'Mexico', phone: '52' },
        { code: 'MY', label: 'Malaysia', phone: '60' },
        { code: 'MZ', label: 'Mozambique', phone: '258' },
        { code: 'NA', label: 'Namibia', phone: '264' },
        { code: 'NC', label: 'New Caledonia', phone: '687' },
        { code: 'NE', label: 'Niger', phone: '227' },
        { code: 'NF', label: 'Norfolk Island', phone: '672' },
        { code: 'NG', label: 'Nigeria', phone: '234' },
        { code: 'NI', label: 'Nicaragua', phone: '505' },
        { code: 'NL', label: 'Netherlands', phone: '31' },
        { code: 'NO', label: 'Norway', phone: '47' },
        { code: 'NP', label: 'Nepal', phone: '977' },
        { code: 'NR', label: 'Nauru', phone: '674' },
        { code: 'NU', label: 'Niue', phone: '683' },
        { code: 'NZ', label: 'New Zealand', phone: '64' },
        { code: 'OM', label: 'Oman', phone: '968' },
        { code: 'PA', label: 'Panama', phone: '507' },
        { code: 'PE', label: 'Peru', phone: '51' },
        { code: 'PF', label: 'French Polynesia', phone: '689' },
        { code: 'PG', label: 'Papua New Guinea', phone: '675' },
        { code: 'PH', label: 'Philippines', phone: '63' },
        { code: 'PK', label: 'Pakistan', phone: '92' },
        { code: 'PL', label: 'Poland', phone: '48' },
        {
            code: 'PM',
            label: 'Saint Pierre and Miquelon',
            phone: '508',
        },
        { code: 'PN', label: 'Pitcairn', phone: '870' },
        { code: 'PR', label: 'Puerto Rico', phone: '1' },
        {
            code: 'PS',
            label: 'Palestine, State of',
            phone: '970',
        },
        { code: 'PT', label: 'Portugal', phone: '351' },
        { code: 'PW', label: 'Palau', phone: '680' },
        { code: 'PY', label: 'Paraguay', phone: '595' },
        { code: 'QA', label: 'Qatar', phone: '974' },
        { code: 'RE', label: 'Reunion', phone: '262' },
        { code: 'RO', label: 'Romania', phone: '40' },
        { code: 'RS', label: 'Serbia', phone: '381' },
        { code: 'RU', label: 'Russian Federation', phone: '7' },
        { code: 'RW', label: 'Rwanda', phone: '250' },
        { code: 'SA', label: 'Saudi Arabia', phone: '966' },
        { code: 'SB', label: 'Solomon Islands', phone: '677' },
        { code: 'SC', label: 'Seychelles', phone: '248' },
        { code: 'SD', label: 'Sudan', phone: '249' },
        { code: 'SE', label: 'Sweden', phone: '46' },
        { code: 'SG', label: 'Singapore', phone: '65' },
        { code: 'SH', label: 'Saint Helena', phone: '290' },
        { code: 'SI', label: 'Slovenia', phone: '386' },
        {
            code: 'SJ',
            label: 'Svalbard and Jan Mayen',
            phone: '47',
        },
        { code: 'SK', label: 'Slovakia', phone: '421' },
        { code: 'SL', label: 'Sierra Leone', phone: '232' },
        { code: 'SM', label: 'San Marino', phone: '378' },
        { code: 'SN', label: 'Senegal', phone: '221' },
        { code: 'SO', label: 'Somalia', phone: '252' },
        { code: 'SR', label: 'Suriname', phone: '597' },
        { code: 'SS', label: 'South Sudan', phone: '211' },
        {
            code: 'ST',
            label: 'Sao Tome and Principe',
            phone: '239',
        },
        { code: 'SV', label: 'El Salvador', phone: '503' },
        {
            code: 'SX',
            label: 'Sint Maarten (Dutch part)',
            phone: '1-721',
        },
        {
            code: 'SY',
            label: 'Syrian Arab Republic',
            phone: '963',
        },
        { code: 'SZ', label: 'Swaziland', phone: '268' },
        {
            code: 'TC',
            label: 'Turks and Caicos Islands',
            phone: '1-649',
        },
        { code: 'TD', label: 'Chad', phone: '235' },
        {
            code: 'TF',
            label: 'French Southern Territories',
            phone: '262',
        },
        { code: 'TG', label: 'Togo', phone: '228' },
        { code: 'TH', label: 'Thailand', phone: '66' },
        { code: 'TJ', label: 'Tajikistan', phone: '992' },
        { code: 'TK', label: 'Tokelau', phone: '690' },
        { code: 'TL', label: 'Timor-Leste', phone: '670' },
        { code: 'TM', label: 'Turkmenistan', phone: '993' },
        { code: 'TN', label: 'Tunisia', phone: '216' },
        { code: 'TO', label: 'Tonga', phone: '676' },
        { code: 'TR', label: 'Turkey', phone: '90' },
        {
            code: 'TT',
            label: 'Trinidad and Tobago',
            phone: '1-868',
        },
        { code: 'TV', label: 'Tuvalu', phone: '688' },
        {
            code: 'TW',
            label: 'Taiwan, Republic of China',
            phone: '886',
        },
        {
            code: 'TZ',
            label: 'United Republic of Tanzania',
            phone: '255',
        },
        { code: 'UA', label: 'Ukraine', phone: '380' },
        { code: 'UG', label: 'Uganda', phone: '256' },
        {
            code: 'US',
            label: 'United States',
            phone: '1',
            suggested: true,
        },
        { code: 'UY', label: 'Uruguay', phone: '598' },
        { code: 'UZ', label: 'Uzbekistan', phone: '998' },
        {
            code: 'VA',
            label: 'Holy See (Vatican City State)',
            phone: '379',
        },
        {
            code: 'VC',
            label: 'Saint Vincent and the Grenadines',
            phone: '1-784',
        },
        { code: 'VE', label: 'Venezuela', phone: '58' },
        {
            code: 'VG',
            label: 'British Virgin Islands',
            phone: '1-284',
        },
        {
            code: 'VI',
            label: 'US Virgin Islands',
            phone: '1-340',
        },
        { code: 'VN', label: 'Vietnam', phone: '84' },
        { code: 'VU', label: 'Vanuatu', phone: '678' },
        { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
        { code: 'WS', label: 'Samoa', phone: '685' },
        { code: 'XK', label: 'Kosovo', phone: '383' },
        { code: 'YE', label: 'Yemen', phone: '967' },
        { code: 'YT', label: 'Mayotte', phone: '262' },
        { code: 'ZA', label: 'South Africa', phone: '27' },
        { code: 'ZM', label: 'Zambia', phone: '260' },
        { code: 'ZW', label: 'Zimbabwe', phone: '263' },
    ];


    return (
        <Card>
            <CardHeader color="primary" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                GPW USER
            </CardHeader>
            <CardBody>
                <Grid container spacing={20} xs={12}>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            id="first-name"
                            label="First Name *"
                            variant="standard"
                            error={values.firstNameErr}
                            disabled={disablefirstName}
                            value={firstName}
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    firstNameErr: false,
                                });

                                handleFirstNameChange(event);
                            }}
                            inputProps={{
                                maxLength: 30,
                            }}
                            style={{ width: 300, margin: 10 }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            id="middle-name"
                            label="Middle Name "
                            variant="standard"
                            // error={values.firstNameErr}
                            disabled={disablemiddleName}
                            value={middleName}
                            onChange={(event) => {
                                // setValues({
                                //     ...values,
                                //     firstNameErr: false,
                                // });

                                handleMiddleNameChange(event);
                            }}
                            inputProps={{
                                maxLength: 30,
                            }}
                            style={{ width: 300, margin: 10 }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            id="last-name"
                            label="Last Name *"
                            variant="standard"
                            error={values.lastNameErr}
                            disabled={disablelastName}
                            value={lastName}
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    lastNameErr: false,
                                });
                                handleLastNameChange(event);
                            }}
                            inputProps={{
                                maxLength: 30,
                            }}
                            style={{ width: 300, margin: 10 }}
                        />
                    </Grid>

                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            id="gender"
                            select
                            label="Gender *"
                            error={values.genderErr}
                            onChange={(event) => {

                                setValues({
                                    ...values,
                                    genderErr: false,
                                });

                                handleGenderChange(event);
                            }}
                            value={gender}
                            style={{ width: 300, margin: 10 }}
                        >
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            id="mobile-number"
                            label="Mobile Number *"
                            variant="standard"
                            error={values.mobileNumberErr}
                            value={mobileNumber}
                            onChange={(event) => {

                                setValues({
                                    ...values,
                                    mobileNumberErr: false,
                                });

                                handleMobileNumberChange(event);
                            }} inputProps={{
                                maxLength: 8,
                            }}
                            style={{ width: 300, margin: 10 }}
                        />
                    </Grid>

                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            id="denomination"
                            label="CPR Number *"
                            variant="standard"
                            error={values.cprErr}
                            disabled={disableCPR}
                            value={cpr}
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    cprErr: false,
                                });

                                handleCPRChange(event);
                            }}
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                            }}
                            style={{ width: 300, margin: 10 }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            type="date"
                            style={{ width: 300 }}
                            label="CPR Expiry *"
                            error={values.cprExpiryErr}
                            InputLabelProps={{ shrink: true }}
                            value={cprExpiry}
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    cprExpiryErr: false,
                                });
                                handleCPRExpiryChange(event);
                            }}
                            inputProps={{
                                min: new Date().toISOString().split('T')[0],
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            id="email"
                            label="Email *"
                            variant="standard"
                            error={values.emailErr}
                            value={email}
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    emailErr: false,
                                });
                                handleEmailChange(event);
                            }}
                            inputProps={{
                                autoComplete: "off",
                                value: email,
                                onChange: (e) => {
                                    setEmail(e.target.value);
                                    verify(e.target.value, "email", "emailErr", 3);
                                },
                                onBlur: (e) => {
                                    setEmail(e.target.value.trim());
                                }
                            }}
                            style={{ width: 300, margin: 10 }}
                        />
                    </Grid>

                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            id="place-of-birth"
                            label="Place of Birth *"
                            variant="standard"
                            error={values.placeOfBirthErr}
                            disabled={disableplaceOfBirth}
                            value={placeOfBirth}
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    placeOfBirthErr: false,
                                });
                                handlePlaceOfBirthChange(event);
                            }} inputProps={{
                                maxLength: 50,
                            }}
                            style={{ width: 300, margin: 10 }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            type="date"
                            style={{ width: 300 }}
                            label="Date of Birth *"
                            error={values.dateOfBirthErr}
                            // disabled={disabledateOfBirth}
                            InputLabelProps={{ shrink: true }}
                            value={dateOfBirth}
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    dateOfBirthErr: false,
                                });
                                handleDateOfBirthChange(event);
                            }}
                            inputProps={{
                                max: new Date().toISOString().split('T')[0],
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                            <FormControl variant="outlined" sx={{ width: 300, marginLeft: 5 }}>
                                {/* <InputLabel id="nationality-label">Nationality</InputLabel> */}
                                <span>
                                    Nationality
                                </span>
                                <Select
                                    labelId="nationality-label"
                                    id="nationality"
                                    value={nationality}
                                    label="Nationality"
                                    onChange={(e)=>{
                                        setValues({
                                            ...values,
                                            nationalityErr: false,
                                        });
                                        setNationality(e.target.value)}
                                    }
                                    
                                >
                                    {countries.map((country) => (
                                        <MenuItem key={country.code} value={country.label}>
                                            {country.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>


                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            id="passport-number"
                            label="Passport Number"
                            variant="standard"
                            error={values.passportNumberErr}
                            value={passportNumber}
                            onChange={handlePassportNumberChange}
                            inputProps={{
                                maxLength: 15,
                            }}
                            style={{ width: 300, margin: 10 }}
                        />
                    </Grid>

                    {passportNumber && (
                        <Grid item xs={6} sm={6} md={3}>
                            <TextField
                                type="date"
                                style={{ width: 300 }}
                                label="Passport Expiry"
                                error={values.passportExpiryErr}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={passportNumber ? passportExpiry : ''}                                
                                onChange={(event) => {
                                    setValues({
                                        ...values,
                                        passportExpiryErr: false,
                                    });
                                    handlePassportExpiryChange(event);
                                }}
                            />
                        </Grid>
                    )}

                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            id="address-type"
                            select
                            label="Address *"
                            error={values.addressTypeErr}
                            value={addressType}
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    addressTypeErr: false,
                                });
                                handleAddressTypeChange(event);
                            }}
                            style={{ width: 300, margin: 10 }}
                        >
                            <MenuItem key="Home" value="Home">
                                Home
                            </MenuItem>
                            <MenuItem key="Office" value="Office">
                                Office
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            id="flat-number"
                            label="Flat Number *"
                            error={values.flatNumberErr}
                            variant="standard"
                            value={flatNumber}
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    flatNumberErr: false,
                                });
                                handleFlatNumberChange(event);
                            }}
                            inputProps={{
                                maxLength: 10,
                            }}
                            style={{ width: 300, margin: 10 }}
                        />
                    </Grid>

                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            id="building-number"
                            label="Building Number *"
                            error={values.buildingNumberErr}
                            variant="standard"
                            value={buildingNumber}
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    buildingNumberErr: false,
                                });
                                handleBuildingNumberChange(event);
                            }}
                            inputProps={{
                                maxLength: 20,
                            }}
                            style={{ width: 300, margin: 10 }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            id="road-number"
                            label="Road Number *"
                            error={values.roadNumberErr}
                            variant="standard"
                            value={roadNumber}
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    roadNumberErr: false,
                                });
                                handleRoadNumberChange(event);
                            }}
                            inputProps={{
                                maxLength: 20,
                            }}
                            style={{ width: 300, margin: 10 }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            id="block-number"
                            label="Block Number *"
                            error={values.blockNumberErr}
                            variant="standard"
                            value={blockNumber}
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    blockNumberErr: false,
                                });
                                handleBlockNumberChange(event);
                            }}
                            inputProps={{
                                maxLength: 10,
                            }}
                            style={{ width: 300, margin: 10 }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            id="occupation "
                            label="Occupation *"
                            error={values.occupationErr}
                            variant="standard"
                            value={occupation}
                            onChange={(event) => {

                                setValues({
                                    ...values,
                                    occupationErr: false,
                                });
                                handleOccupationChange(event);
                            }}
                            inputProps={{
                                maxLength: 30,
                            }}
                            style={{ width: 300, margin: 10 }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            id="validProofOfIdentification"
                            label="Valid Proof of Identification *"
                            variant="standard"
                            error={values.lastNameErr}
                            disabled={diablevalidProofOfIdentification}
                            value={validProofOfIdentification}
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    lastNameErr: false,
                                });
                                validProofOfIdentifications(event);
                            }}
                            inputProps={{
                                maxLength: 30,
                            }}
                            style={{ width: 300, margin: 10 }}
                        />
                    </Grid>

                    <Grid item xs={6} sm={6} md={3}>
                        <TextField
                            id="preferred-language"
                            select
                            label="Preferred Communication Language"
                            error={values.preferredLanguageErr}
                            value={preferredLanguage}
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    preferredLanguageErr: false,
                                });
                                handlePreferredLanguageChange(event);
                            }}
                            style={{ width: 320, margin: 10 }}
                        >
                            <MenuItem key="English" value="English">
                                English
                            </MenuItem>
                            <MenuItem key="Arabic" value="Arabic">
                                Arabic
                            </MenuItem>
                        </TextField>
                    </Grid>

                    <Grid container spacing={1} style={{ marginTop: '100px' }}>
                        <Grid item xs={12} sm={6} md={4}>
                            <div>
                                {!cprFrontPageName && !cprFrontPage && (
                                    <Dropzone onDrop={(e) => handleDoc('CPR_FRONT_PAGE', e)}>
                                        {/* console.log(e); */}
                                        {({ getRootProps, getInputProps }) => (
                                            
                                            <div {...getRootProps()}>
                                                <input
                                                    {...getInputProps({
                                                        accept: 'image/jpeg, image/png',
                                                        onChange: (event) => {
                                                            const file = event.target.files[0];
                                                            if (file && file.size > 100 * 1024) {
                                                                setSnackbarOpen(true);
                                                                event.target.value = null;
                                                            } else {

                                                            }
                                                        },
                                                    })}
                                                />
                                                <Button
                                                    size="small"
                                                    style={{ background: '#f0f0f0', width: 300, margin: 10 }}
                                                >
                                                    CPR Front Page
                                                </Button>
                                            </div>
                                        )}
                                    </Dropzone>
                                )}

                                {cprFrontPage && (
                                    <ImageUpload
                                        uploadedImage={cprFrontPage}
                                        onDelete={(e) => handleImageDelete('CPR_FRONT_PAGE')}
                                        label="CPR Front Page"
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div>
                                {!cprBackPageName && !cprBackPage && (
                                    <Dropzone onDrop={(e) => handleDoc('CPR_BACK_PAGE', e)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                                <input
                                                    {...getInputProps({
                                                        accept: 'image/jpeg, image/png',
                                                        onChange: (event) => {
                                                            const file = event.target.files[0];
                                                            if (file && file.size > 100 * 1024) {
                                                                setSnackbarOpen(true);
                                                                event.target.value = null;
                                                            } else {

                                                            }
                                                        },
                                                    })}
                                                />
                                                <Button
                                                    size="small"
                                                    style={{ background: '#f0f0f0', width: 300, margin: 10 }}
                                                >
                                                    CPR Back Page
                                                </Button>
                                            </div>
                                        )}
                                    </Dropzone>
                                )}

                                {cprBackPage && (
                                    <ImageUpload
                                        uploadedImage={cprBackPage}
                                        onDelete={(e) => handleImageDelete('CPR_BACK_PAGE')}
                                        label="CPR Back Page"
                                    />
                                )}
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div>
                                {!consentDocName && !consentDocument && (
                                    <Dropzone onDrop={(e) => handleDoc('CONSENT_DOC', e)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                                <input
                                                    {...getInputProps({
                                                        accept: 'image/jpeg, image/png',
                                                        onChange: (event) => {
                                                            const file = event.target.files[0];
                                                            if (file && file.size > 100 * 1024) {
                                                                setSnackbarOpen(true);
                                                                event.target.value = null;
                                                            } else {

                                                            }
                                                        },
                                                    })}
                                                />
                                                <Button
                                                    size="small"
                                                    style={{ background: '#f0f0f0', width: 300, margin: 10 }}
                                                >
                                                    Consent Document
                                                </Button>
                                            </div>
                                        )}
                                    </Dropzone>
                                )}

                                {consentDocument && (
                                    <ImageUpload
                                        uploadedImage={consentDocument}
                                        onDelete={(e) => handleImageDelete('CONSENT_DOC')}
                                        label="CONSENT DOCUMENT"
                                    />
                                )}
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div>
                                {!personPhotoImageName && !personPhotoImage && (
                                    <Dropzone onDrop={(e) => handleDoc('PERSON_PHOTO_IMAGE', e)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                                <input
                                                    {...getInputProps({
                                                        accept: 'image/jpeg, image/png',
                                                        onChange: (event) => {
                                                            const file = event.target.files[0];
                                                            if (file && file.size > 100 * 1024) {
                                                                setSnackbarOpen(true);
                                                                event.target.value = null;
                                                            } else {

                                                            }
                                                        },
                                                    })}
                                                />
                                                <Button
                                                    size="small"
                                                    style={{ background: '#f0f0f0', width: 300, margin: 10 }}
                                                >
                                                    Photo Taken By  Maker/Sales
                                                </Button>
                                            </div>
                                        )}
                                    </Dropzone>
                                )}

                                {personPhotoImage && (
                                    <ImageUpload
                                        uploadedImage={personPhotoImage}
                                        onDelete={(e) => handleImageDelete('PERSON_PHOTO_IMAGE')}
                                        label="PHOTO TAKEN BY MAKER & SALES"
                                    />
                                )}
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div>
                                {!cprFrontPageParentName && !cprFrontPageParent && (
                                    <Dropzone onDrop={(e) => handleDoc('PARENT/GUARDIAN_CPR_FRONT_PAGE', e)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                                <input
                                                    {...getInputProps({
                                                        accept: 'image/jpeg, image/png',
                                                        onChange: (event) => {
                                                            const file = event.target.files[0];
                                                            if (file && file.size > 100 * 1024) {
                                                                setSnackbarOpen(true);
                                                                event.target.value = null;
                                                            } else {

                                                            }
                                                        },
                                                    })}
                                                />
                                                <Button
                                                    size="small"
                                                    style={{ background: '#f0f0f0', width: 300, margin: 10 }}
                                                >
                                                    Parent/Guardian Cpr front Page
                                                </Button>
                                            </div>
                                        )}
                                    </Dropzone>
                                )}

                                {cprFrontPageParent && (
                                    <ImageUpload
                                        uploadedImage={cprFrontPageParent}
                                        onDelete={(e) => handleImageDelete('PARENT/GUARDIAN_CPR_FRONT_PAGE')}
                                        label="Parent/Guardian Cpr Front Page"
                                    />
                                )}
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div>
                                {!cprBackPageParentName && !cprBackPageParent && (
                                    <Dropzone onDrop={(e) => handleDoc('PARENT/GUARDIAN_CPR_BACK_PAGE', e)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                                <input
                                                    {...getInputProps({
                                                        accept: 'image/jpeg, image/png',
                                                        onChange: (event) => {
                                                            const file = event.target.files[0];
                                                            if (file && file.size > 100 * 1024) {
                                                                setSnackbarOpen(true);
                                                                event.target.value = null;
                                                            } else {

                                                            }
                                                        },
                                                    })}
                                                />
                                                <Button
                                                    size="small"
                                                    style={{ background: '#f0f0f0', width: 300, margin: 10 }}
                                                >
                                                    Parent/Guardian Cpr Back Page
                                                </Button>
                                            </div>
                                        )}
                                    </Dropzone>
                                )}

                                {cprBackPageParent && (
                                    <ImageUpload
                                        uploadedImage={cprBackPageParent}
                                        onDelete={(e) => handleImageDelete('PARENT/GUARDIAN_CPR_BACK_PAGE')}
                                        label="Parent/Guardian Cpr Back Page"
                                    />
                                )}
                            </div>

                        </Grid>
                        {passportNumber && (
                            <Grid item xs={12} sm={6} md={4}>
                                <div>
                                    {!passportBackPageName && !passportBackPage && (
                                        <Dropzone onDrop={(e) => handleDoc('PASSPORT_BACK_PAGE', e)}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <Button
                                                        size="small"
                                                        style={{ background: '#f0f0f0', width: 300, margin: 10 }}
                                                    >
                                                        Passport Back Page
                                                    </Button>
                                                </div>
                                            )}
                                        </Dropzone>
                                    )}

                                    {passportBackPage && (
                                        <ImageUpload
                                            uploadedImage={passportBackPage}
                                            onDelete={(e) => handleImageDelete('PASSPORT_BACK_PAGE')}
                                            label="CPR Back Page"
                                        />
                                    )}
                                </div>
                            </Grid>
                        )}

                        {passportNumber && (
                            <Grid item xs={12} sm={6} md={4}>
                                <div>
                                    {!passportFrontPageName && !passportFrontPage && (
                                        <Dropzone onDrop={(e) => handleDoc('PASSPORT_FRONT_PAGE', e)}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <Button
                                                        size="small"
                                                        style={{ background: '#f0f0f0', width: 300, margin: 10 }}
                                                    >
                                                        Passport Front Page
                                                    </Button>
                                                </div>
                                            )}
                                        </Dropzone>
                                    )}

                                    {passportFrontPage && (
                                        <ImageUpload
                                            uploadedImage={passportFrontPage}
                                            onDelete={(e) => handleImageDelete('PASSPORT_FRONT_PAGE')}
                                            label="CPR Back Page"
                                        />
                                    )}
                                </div>
                            </Grid>
                        )}

                    </Grid>

                </Grid>
            </CardBody>

            <CardFooter className={classes.cardFooter}>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', padding: '10px', marginLeft: "43%" }}>
                    {!submitting ? (
                        <div style={{ marginTop: '30px' }}>
                            <Button onClick={handleBack} size="md" simple color="primary"> <ArrowBackIcon />Back </Button>
                            <Button
                                tranCode="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submitButton}
                                onClick={handleSubmit}
                            >
                                Update
                            </Button>

                        </div>
                    ) : (
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                    )}
                </div>
            </CardFooter>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    severity="error"
                    onClose={() => setSnackbarOpen(false)}
                >
                    Files must be a maximum of 100 KB.
                </MuiAlert>
            </Snackbar>
        </Card>
    );
}

