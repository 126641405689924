import React from 'react';
import config from "config.js"
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from "@material-ui/core/Paper";
import Button from "components/CustomButtons/Button.js";
import MaterialTable from "material-table";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Chip from '@mui/material/Chip';
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        textAlign: "center"
    },
    ...modalStyle(theme)
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function NameScreen(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [respAlert, setRespAlert] = React.useState(null);
    const [secondViewValue, setSecondViewValue] = React.useState(0);
    const [companyData, setCompanyData] = React.useState([]);
    const [ownerData, setOwnerData] = React.useState([]);
    const [contactData, setContactData] = React.useState([]);
    const [lorData, setLorData] = React.useState([]);
    const [directorsData, setDirectorsData] = React.useState([]);
    const [declarationData, setDeclarationData] = React.useState([]);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [openSnack, setOpenSnack] = React.useState(false);

    const [watchListData, setWatchListData] = React.useState([]);
    const [aliasData, setAliasData] = React.useState([]);

    const [showKeywords, setShowKeyWords] = React.useState(false);
    const [keywords, setKeyWords] = React.useState([]);


    React.useEffect(() => {
        if (props.corpID !== null) {
            getNameScreenData(props.corpID)
        }
    }, [props.corpID]);

    const handleChange = (event, newValue) => {
        setSecondViewValue(0)
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const goBack = (step) => {
        //function to go back
        switch (step) {
            case 0:
                setAliasData([])
                setWatchListData([])
                setSecondViewValue(0)
                break;
            case 1:
                setAliasData([])
                setSecondViewValue(1)
                break;
            default:
                break;
        }
    }

    function openSnackBar(msg) {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const setWatchList = (data) => {
        //function to set watch list data
        setSecondViewValue(1)
        let temData = []
        // alert(JSON.stringify(data[0].Person))
        if (data !== undefined) {
            data.map((per) => {
                //alert(JSON.stringify(per.Person.Names.Aliases.Alias))
                let data = {
                    firstName: per.Person.Names.firstName,
                    lastName: per.Person.Names.lastName,
                    dob: per.Person.AgeData.Dob,
                    fullNameScore: per.Person.Names.matchDetails ? per.Person.Names.matchDetails.fullNameScore : "",
                    partsScore: per.Person.Names.matchDetails ? per.Person.Names.matchDetails.partsScore : "",
                    score: per.Person.Names.matchDetails ? per.Person.Names.matchDetails.score : "",
                    Uid: per.Uid,
                    keywords: per.Details.Keywords.Keyword.length <= 1 ? per.Details.Keywords.Keyword[0] : <p style={{ minWidth: "200px" }}>{per.Details.Keywords.Keyword[0]} <a style={{ color: "#004fa4", fontSize: "12px" }} href="#" onClick={() => openKeyWordsModal(per.Details.Keywords.Keyword)}>View More</a> </p>,
                    country: per.Details.Countries.Country[0],
                    alias: per.Person.Names.Aliases.Alias
                }
                temData.push(data)
            })
        }
        setWatchListData(temData)
    }


    const setAlias = (data) => {
        //function to set watch list data
        //  alert(JSON.stringify(data))
        let tempData = []
        if (data !== undefined) {
            data.map((ali) => {
                let data = {
                    Name: ali.Name,
                    fullNameScore: ali.matchDetails ? ali.matchDetails.fullNameScore : "",
                    partsScore: ali.matchDetails ? ali.matchDetails.partsScore : "",
                    score: ali.matchDetails ? ali.matchDetails.score : "",
                }
                tempData.push(data)
            })
        }
        setAliasData(tempData)
        setSecondViewValue(2)
    }

    const getNameScreenData = (id) => {
        //function to fetch name screening data for a customer
        setOwnerData([]);
        axios.get(`${config.url().baseURL}/get-compliance-details/${id}`)
            .then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    setCompanyData([respData.data.NameOfCompany])
                    setOwnerData(respData.data.Owners)
                    setContactData(respData.data.Contacts)
                    setLorData(respData.data.LetterOfRepresentation)
                    setDirectorsData(respData.data.DirectorsAndAuthorisedSignatories)
                    let temDec = []
                    temDec.push(respData.data.ClientDeclaration)
                    setDeclarationData(temDec)
                    // setCon
                } else {
                    openSnackBar("No Screening data found.")
                    setOwnerData([]);
                }
            })
            .catch((err) => {
                setOwnerData([]);
            });
    }

    const screeningOptions = {
        exportButton: false,
        showTitle: false,
        search: false,
        actionsColumnIndex: -1,

    };

    const screenColumns = [
        {
            field: "watchListName",
            title: "Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "watchListStatus",
            title: "Status",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "watchListCount",
            title: "Count",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    const screenActions = [
        {
            icon: "list_lt_icon",
            tooltip: 'View watch list',
            onClick: (event, rowData) => setWatchList(rowData.WatchList),

        }
    ]

    const watchListColumns = [
        {
            field: "firstName",
            title: "firstName",
            options: {
                width: "auto",
                filter: true,
                sort: true,
            },
        },
        {
            field: "lastName",
            title: "Last Name",
            options: {
                width: "auto",
                filter: true,
                sort: true,
            },
        },
        {
            field: "dob",
            title: "D O B",
            options: {
                width: "auto",
                filter: true,
                sort: true,
            },
        },
        {
            field: "fullNameScore",
            title: "Name Score",
            options: {
                width: "auto",
                filter: true,
                sort: true,
            },
        },
        {
            field: "partsScore",
            title: "Parts Score",
            options: {
                filter: true,
                sort: true,

            },
        },
        {
            field: "score",
            title: "Score",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "Uid",
            title: "Uid",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            field: "country",
            title: "Country",
            options: {
                filter: false,
                sort: false,
            },
        },

        {
            field: "keywords",
            title: "Keywords",
            options: {
                filter: false,
                sort: false,
                width: "auto",
            },
        },
    ];

    const watchListActions = [
        {
            icon: "list_lt_icon",
            tooltip: 'View Alias list',
            onClick: (event, rowData) => setAlias(rowData.alias),

        },
    ]

    const aliasColumns = [
        {
            field: "Name",
            title: "Name",
            options: {
                filter: true,
                sort: true,
            },
        },


        {
            field: "fullNameScore",
            title: "Name Score",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "partsScore",
            title: "Parts Score",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "score",
            title: "Score",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    const openKeyWordsModal = (keywords) => {
        //function to open keyword modal
        setShowKeyWords(true)
        setKeyWords(keywords)
    }

    const closeKeyWordsModal = () => {
        //function close keywords modal
        setShowKeyWords(false)
        setKeyWords([])
    }

    return (
        <div className={classes.root}>
            {respAlert}
            <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="Company" {...a11yProps(0)} />
                    <Tab label="Owners" {...a11yProps(1)} />
                    <Tab label="Contacts" {...a11yProps(2)} />
                    <Tab label="L O R" {...a11yProps(3)} />
                    <Tab label="Directors" {...a11yProps(4)} />
                    <Tab label="Declaration" {...a11yProps(5)} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={secondViewValue}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={secondViewValue} index={0} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={companyData}
                                columns={screenColumns}
                                options={screeningOptions}
                                actions={screenActions}

                            />

                        </TabPanel>
                        <TabPanel value={secondViewValue} index={1} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={watchListData}
                                columns={watchListColumns}
                                options={screeningOptions}
                                actions={watchListActions}

                            />
                            <Button color="primary" size="md" onClick={() => { goBack(0) }}>Back</Button>
                        </TabPanel>
                        <TabPanel value={secondViewValue} index={2} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={aliasData}
                                columns={aliasColumns}
                                options={screeningOptions}
                            />
                            <Button color="primary" size="md" onClick={() => { goBack(1) }}>Back</Button>
                        </TabPanel>
                    </SwipeableViews>
                </TabPanel>

                <TabPanel value={value} index={1} dir={theme.direction}>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={secondViewValue}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={secondViewValue} index={0} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={ownerData}
                                columns={screenColumns}
                                options={screeningOptions}
                                actions={screenActions}

                            />

                        </TabPanel>
                        <TabPanel value={secondViewValue} index={1} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={watchListData}
                                columns={watchListColumns}
                                options={screeningOptions}
                                actions={watchListActions}

                            />
                            <Button color="primary" size="md" onClick={() => { goBack(0) }}>Back</Button>
                        </TabPanel>
                        <TabPanel value={secondViewValue} index={2} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={aliasData}
                                columns={aliasColumns}
                                options={screeningOptions}
                            />
                            <Button color="primary" size="md" onClick={() => { goBack(1) }}>Back</Button>
                        </TabPanel>
                    </SwipeableViews>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={secondViewValue}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={secondViewValue} index={0} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={contactData}
                                columns={screenColumns}
                                options={screeningOptions}
                                actions={screenActions}

                            />

                        </TabPanel>
                        <TabPanel value={secondViewValue} index={1} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={watchListData}
                                columns={watchListColumns}
                                options={screeningOptions}
                                actions={watchListActions}

                            />
                            <Button color="primary" size="md" onClick={() => { goBack(0) }}>Back</Button>
                        </TabPanel>
                        <TabPanel value={secondViewValue} index={2} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={aliasData}
                                columns={aliasColumns}
                                options={screeningOptions}
                            />
                            <Button color="primary" size="md" onClick={() => { goBack(1) }}>Back</Button>
                        </TabPanel>
                    </SwipeableViews>
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={secondViewValue}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={secondViewValue} index={0} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={lorData}
                                columns={screenColumns}
                                options={screeningOptions}
                                actions={screenActions}

                            />

                        </TabPanel>
                        <TabPanel value={secondViewValue} index={1} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={watchListData}
                                columns={watchListColumns}
                                options={screeningOptions}
                                actions={watchListActions}

                            />
                            <Button color="primary" size="md" onClick={() => { goBack(0) }}>Back</Button>
                        </TabPanel>
                        <TabPanel value={secondViewValue} index={2} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={aliasData}
                                columns={aliasColumns}
                                options={screeningOptions}
                            />
                            <Button color="primary" size="md" onClick={() => { goBack(1) }}>Back</Button>
                        </TabPanel>
                    </SwipeableViews>
                </TabPanel>
                <TabPanel value={value} index={4} dir={theme.direction}>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={secondViewValue}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={secondViewValue} index={0} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={directorsData}
                                columns={screenColumns}
                                options={screeningOptions}
                                actions={screenActions}

                            />

                        </TabPanel>
                        <TabPanel value={secondViewValue} index={1} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={watchListData}
                                columns={watchListColumns}
                                options={screeningOptions}
                                actions={watchListActions}

                            />
                            <Button color="primary" size="md" onClick={() => { goBack(0) }}>Back</Button>
                        </TabPanel>
                        <TabPanel value={secondViewValue} index={2} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={aliasData}
                                columns={aliasColumns}
                                options={screeningOptions}
                            />
                            <Button color="primary" size="md" onClick={() => { goBack(1) }}>Back</Button>
                        </TabPanel>
                    </SwipeableViews>
                </TabPanel>
                <TabPanel value={value} index={5} dir={theme.direction}>

                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={secondViewValue}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={secondViewValue} index={0} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={declarationData}
                                columns={screenColumns}
                                options={screeningOptions}
                                actions={screenActions}

                            />


                        </TabPanel>
                        <TabPanel value={secondViewValue} index={1} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={watchListData}
                                columns={watchListColumns}
                                options={screeningOptions}
                                actions={watchListActions}

                            />
                            <Button color="primary" size="md" onClick={() => { goBack(0) }}>Back</Button>
                        </TabPanel>
                        <TabPanel value={secondViewValue} index={2} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={aliasData}
                                columns={aliasColumns}
                                options={screeningOptions}
                            />
                            <Button color="primary" size="md" onClick={() => { goBack(1) }}>Back</Button>
                        </TabPanel>
                    </SwipeableViews>
                </TabPanel>
            </SwipeableViews>
            <Dialog
                classes={{
                    root: classes.left + " " + classes.modalRoot,
                    paper: classes.modal,
                }}
                open={showKeywords}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => { closeKeyWordsModal() }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    {
                        keywords.map((keyword) => { return (<Chip label={keyword} style={{ margin: "5px" }} variant="outlined" />) })
                    }
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button onClick={() => { closeKeyWordsModal() }} color="danger" size="sm">Close</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    );
}
