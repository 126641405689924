import React, { useState } from "react";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import config from "config.js"
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { creatCsvFile, downloadFile } from "download-csv";
import { useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import FeeEditForm from "./Components/FeeEditForm.js"
import FeeView from "./Components/FeeView.js"
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiTable from '@material-ui/core/Table';
import moment from "moment";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "components/CustomButtons/Button.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { setCompanyInfo } from "redux/actions/corporateOnboarding.js";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import EditCardFee from "./Components/EditCardFee"
import EditTabiibFee from "./Components/EditTabiibFee"
import ViewCardFee from "./Components/ViewCardFee"
import PreviewIcon from '@mui/icons-material/Preview';


const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    statusHead: {
        marginBottom: "0px !important",
    },
    statusText: {
        color: "#000 !important",
    },
    ...modalStyle(theme),
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function Departments() {
    const user = useSelector(state => state.user);
    const classes = useStyles();
    const columns = [
        {
            name: "no",
            label: "#",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "companyName",
            label: "Company Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "status",
            label: " Fee Status",
            options: {
                filter: true,
                sort: true,
            },
        },
        // {
        //     name: "status",
        //     label: " Card/Tabiib Fee Status",
        //     options: {
        //         filter: true,
        //         sort: true,
        //     },
        // },
        {
            name: "kycStatus",
            label: "KYC Status",
            options: {
                filter: true,
                sort: true,
            },
        },

        {
            name: "kycAppID",
            label: "KYC Application ID",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "card_fee",
            label: "Card Fee ",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "card_fee_status",
            label: "Card Fee Staus",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "tabiib_card_fee",
            label: "TabiibFee Staus",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "tabiib_card_fee_status",
            label: "Tabiib Fee Status",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
            },
        }
    ];
    const options = {
        selectableRows: false,
        elevation: 0,
        onDownload: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = "departments.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        }
    };

    const logColumns = [
        { id: 'date', label: 'Date', width: 70 },
        { id: 'time', label: 'Time', width: 70 },
        { id: 'user', label: 'User' },
        { id: 'status', label: 'Status', width: 70 },
        { id: 'status_message', label: 'Status Info', minWidth: 170 },
        { id: 'remarks', label: 'Remarks', minWidth: 170 },
    ];

    const [respAlert, setRespAlert] = React.useState(null);
    const [showEditForm, setShowEditForm] = React.useState(false);
    const [showViewForm, setShowViewForm] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [status, setStatus] = React.useState("");
    const [showLoader, setShowLoader] = React.useState(true);

    const [corporateData, setCorporateData] = React.useState([]);
    const [showCorporateTable, setShowCorporateTable] = React.useState(false);
    const [corpID, setCorpID] = React.useState(null);
    const [csvData, setCsvData] = React.useState([]);

    const [feeEditLog, setFeeEditLog] = React.useState(false);
    const [feeEditLogs, setFeeEditLogs] = React.useState([]);
    const [newFee, setNewFee] = React.useState("")
    const [newTabibFee, setNewTabibFee] = React.useState("");
    const [openFeeEdit, setOpenFeeEdit] = React.useState(false)
    const [openTabiibFeeEdit, setOpenFeeTabiibFeeEdit] = React.useState(false);
    const [corporateList, setCorporateList] = React.useState([]);
    const [showViewCardForm, setShowViewCardForm] = React.useState(false)
    const [cardStatus, setCardStatus] = useState("")
    const [tabiibStatus, setTabiibStatus] = useState("")
    const [remarks, setRemarks] = useState("")
    // const [referenceId, setReferenceId] = useState("")
    const [statusMsg, setStatusMsg] = useState("")
    const [type, setType] = useState("")
    const reference = React.useRef("")


    const openSnackBar = (msg) => {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    };

    const hideAlert = () => {
        setRespAlert(null);
    };

    const closeModal = () => {
        //function to delete edit modal
        setShowEditForm(false)
        setCorpID(null)
        setNewFee("")
        setNewTabibFee("")
        setOpenFeeTabiibFeeEdit(false)
        setOpenFeeEdit(false);
        fetchCorporateData()
    }

    const openFeeEditForm = (id) => {
        //function to open edit fee modal
        setShowEditForm(true)
        setCorpID(id)
    }

    const openViewFee = (id, status) => {
        //function to open view fee modal
        setShowViewForm(true)
        setCorpID(id)
        setStatus(status)
    }
    const handleEditButtonClick = (id, fee, type1, status) => {
        try {
            if (status != "notcreated") {
                fetchFeeDetails(id, type1)
            }
            setCorpID(id)
            setNewFee(fee)
            setType(type1)
            setOpenFeeEdit(true)

        } catch (error) {
            console.log(error);
        }
        //function to edit card fee for corporate
    }

    const openViewCardFee = (id, status1, status2,type1) => {
        setCorpID(id)
        setCardStatus(status1)
        setTabiibStatus(status2);
        setShowViewCardForm(true);
        // fetchFeeDetails(id, type1)
        setType(type1)

    }

    const handleEditTabibButtonCLick = (id, tabibFee, type1,status) => {
        if (status != "notcreated") {
            fetchFeeDetails(id, type1)
        }
        setCorpID(id)
        setType(type1)
        setNewTabibFee(tabibFee)
        setOpenFeeTabiibFeeEdit(true);
    }
    const closeViewForm = () => {
        //function to view fee modal
        setShowViewForm(false)
        setCorpID(null)
        fetchCorporateData()
        setStatus("")
        setShowViewCardForm(false)
    }

    React.useEffect(() => {
        fetchCorporateData()
    }, []);

    const fetchFeeDetails = (id, type1) => {
        //function to fetch company details
        axios.post(`${config.url().baseURL}/get-card-fee-details/${id}`, { "type": type1 }).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                let updatedData = []
                respData.data.map((corp) => {
                    corp.card_fee_status = corp.cardLogs.length!=0 ? corp.cardLogs[0].log.status : "notcreated";
                    corp.cardFeeStatusMsg = corp.cardLogs.length!=0 ? corp.cardLogs[0].log.status_message : "notcreated"
                    updatedData.push(corp)
                })
                setNewFee((updatedData.length != 0) ? 8 : "")
                setCardStatus((updatedData.length != 0) ? updatedData[0].card_fee_status : "")
                reference.current = (updatedData.length != 0) ? updatedData[0].feeReference : ""
                setRemarks((updatedData.length != 0) ? (updatedData[0].cardFeeRemarks ? updatedData[0].cardFeeRemarks : "") : "")
                setStatusMsg((updatedData.length != 0) ? updatedData[0].cardFeeStatusMsg : "");

            } else {
                openSnackBar(respData.msg);
                closeModal()
            }
        }).catch((err) => {
            closeModal()
            openSnackBar("Unable to process.");
        });

    };
    const fetchCorporateData = () => {
        setShowLoader(true);
        setShowCorporateTable(false);
        axios
            .get(`${config.url().baseURL}/get-edit-fee-companies`, {
                headers: {
                    auth: sessionStorage.getItem("cx_id"),
                },
            }).then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    setShowLoader(false);
                    setShowCorporateTable(true);
                    let service = user.services.find(service => service.name == "Corporate Fee Edit")
                    var updatedData = []
                    let no = 1
                    respData.data.map((corp, key) => {
                        try {
                            if (corp.KYC.status == "APPROVED" && corp.Ledger.customerId) {
                                corp.no = no;
                                corp.companyID = corp.incorp_details.comm_reg_id
                                corp.status = corp.logData.length !== 0 ? corp.logData[0].log[corp.logData[0].log.length - 1].status : "";
                                corp.kycStatus = corp.KYC.status;
                                corp.kycAppID = corp.KYC.ApplicationID;
                                corp.card_fee = corp.card_fee
                                corp.card_fee_status = corp.cardFeeLogs ? corp.cardFeeLogs[corp.cardFeeLogs.length - 1].status : "notcreated";
                                corp.tabiib_card_fee_status = corp.tabiibFeeLogs ? corp.tabiibFeeLogs[corp.tabiibFeeLogs.length - 1].status : "notcreated"
                                corp.tabiib_card_fee = corp.tabiib_card_fee == "" ? 0 : corp.tabiib_card_fee;
                                if (service.type == "maker") {
                                    if (corp.logData[0].log[corp.logData[0].log.length - 1].status == "Created" || corp.logData[0].log[corp.logData[0].log.length - 1].status == "Recheck" || corp.logData[0].log[corp.logData[0].log.length - 1].status == "Approved" || corp.logData[0].log[corp.logData[0].log.length - 1].status == "Updated") {
                                        corp.actions = (
                                            <>
                                                <Tooltip placement="top" title="Edit Fee">
                                                    <IconButton
                                                        onClick={() => openFeeEditForm(corp._id)}
                                                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                        aria-label="delete">
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip placement="top" title="View Fee">
                                                    <IconButton
                                                        onClick={() => openViewFee(corp._id, corp.logData[0].log[corp.logData[0].log.length - 1].status)}
                                                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                        aria-label="delete">
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip placement="top" title="View Log">
                                                    <IconButton
                                                        onClick={() => fetchLog(corp._id)}
                                                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                    >
                                                        <AssignmentIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip placement="top" title="Edit card fee">
                                                    <IconButton
                                                        onClick={() => handleEditButtonClick(corp._id, corp.card_fee, "cardFee", corp.card_fee_status)}
                                                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                    >
                                                        <DriveFileRenameOutlineIcon />
                                                    </IconButton>
                                                </Tooltip> <Tooltip placement="top" title="Edit Tabib Card fee">
                                                    <IconButton
                                                        onClick={() => handleEditTabibButtonCLick(corp._id, corp.tabiib_card_fee, "tabiib",corp.tabiib_card_fee_status)}
                                                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                    >
                                                        <CreditScoreIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        );
                                    } else {
                                        corp.actions = (
                                            <>
                                                <Tooltip placement="top" title="View Fee">
                                                    <IconButton
                                                        onClick={() => openViewFee(corp._id, corp.logData[0].log[corp.logData[0].log.length - 1].status)}
                                                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                        aria-label="delete">
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip placement="top" title="View Log">
                                                    <IconButton
                                                        onClick={() => fetchLog(corp._id)}
                                                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                    >
                                                        <AssignmentIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip placement="top" title="Edit card fee">
                                                    <IconButton
                                                        onClick={() => handleEditButtonClick(corp._id, corp.card_fee, "cardFee", corp.card_fee_status)}
                                                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                    >
                                                        <DriveFileRenameOutlineIcon />
                                                    </IconButton>
                                                </Tooltip> <Tooltip placement="top" title="Edit Tabib Card fee">
                                                    <IconButton
                                                        onClick={() => handleEditTabibButtonCLick(corp._id,corp.tabiib_card_fee,"tabiib",corp.tabiib_card_fee_status)}
                                                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                    >
                                                        <CreditScoreIcon />
                                                    </IconButton>
                                                </Tooltip></>)
                                    }

                                } else {
                                    setShowLoader(false);
                                    setShowCorporateTable(true);

                                    corp.actions = (
                                        <>
                                            <Tooltip placement="top" title="View Fee">
                                                <IconButton
                                                    onClick={() => openViewFee(corp._id, corp.logData[0].log[corp.logData[0].log.length - 1].status)}
                                                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                    aria-label="delete">
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip placement="top" title="View Log">
                                                <IconButton
                                                    onClick={() => fetchLog(corp._id)}
                                                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                >
                                                    <AssignmentIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip placement="top" title="View Fee">
                                                <IconButton
                                                    onClick={() => openViewCardFee(corp._id, corp.card_fee_status, corp.tabiib_card_fee_status,type)}
                                                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                    aria-label="delete">
                                                    < PreviewIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    );
                                }

                                updatedData.push(corp)
                                no++

                                var updatedCsvData = []
                                respData.data.map((corp) => {
                                    if (corp.KYC.status == "APPROVED" && corp.Ledger.customerId) {
                                        let temp = {};
                                        temp.Company_ID = corp._id;
                                        temp.Company_Name = corp.companyName;
                                        // temp.Contact_Person = corp.contact_details.fullName;
                                        // temp.Contact_Person_Title = corp.contact_details.title;
                                        // temp.Contact_Person_Mobile = corp.contact_details.mobile;
                                        // temp.Contact_Person_Email = corp.contact_details.email;

                                        updatedCsvData.push(temp)
                                    }
                                });

                                setCsvData(updatedCsvData);
                            }
                        } catch (error) {
                            setShowLoader(false);
                            console.log(error)
                                                    }

                    });
                    setCorporateData(updatedData);
                    setShowLoader(false);
                    setShowCorporateTable(true);

                } else {
                    setCorporateData([]);
                    setShowLoader(false);
                    setShowCorporateTable(true);
                    openSnackBar(respData.msg);
                }
            })
            .catch((err) => {
                            openSnackBar("Unable to fetch Corporate data.");
            });
    };


    const fetchLog = (id) => {
        //function to fetch  logs
        setFeeEditLog(true);
        axios.get(`${config.url().baseURL}/fee-log/${id}`).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                //console.log(JSON.stringify(respData.data[0].log))
                if (respData.data[0] !== undefined) {
                    if (respData.data[0].log !== undefined) {
                        setFeeEditLogs(respData.data[0].log);
                    } else {
                        setFeeEditLog(false);
                        openSnackBar("No log found.");
                    }
                } else {
                    setFeeEditLog(false);
                    openSnackBar("No log found.");
                }

            } else {
                setFeeEditLog(false);
                openSnackBar(respData.msg);
            }
        });
    };

    return (
        <div>
            {respAlert}
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showCorporateTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Corporate Fee Edit</h4>
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={corporateData} columns={columns} options={options} />
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>
            <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.modal, }}
                open={showEditForm}
                TransitionComponent={Transition}
                keepMounted
                // onClose={() => {
                //     closeModal()
                // }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
                    <FeeEditForm close={closeModal} corpID={corpID} />
                    {/* <PropagateLoader color="#004fa4" loading={true} css={override} size={15} /> */}
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                </DialogActions>
            </Dialog>
            <Dialog onClose={() => setOpenFeeEdit(false)} aria-labelledby="simple-dialog-title" PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }} open={openFeeEdit}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">

                <EditCardFee close={closeModal} corpID={corpID} cardFee={newFee} cardStatus={cardStatus} remark={remarks} statusMsg={statusMsg} referenceId={reference?reference:{}} />

            </Dialog>
            <Dialog onClose={() => setOpenFeeTabiibFeeEdit(false)} aria-labelledby="simple-dialog-title" PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }} open={openTabiibFeeEdit}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
                    <EditTabiibFee close={closeModal} corpID={corpID} tabiibFee={newTabibFee} cardStatus={cardStatus} remark={remarks} statusMsg={statusMsg} referenceId={reference} />
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                </DialogActions>
            </Dialog>

            <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.modal, }}
                open={showViewForm}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    closeViewForm()
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
                    <FeeView close={closeViewForm} corpID={corpID} status={status} />
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                </DialogActions>
            </Dialog>
            <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.modal, }}
                open={showViewCardForm}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    closeViewForm()
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
                    <ViewCardFee close={closeViewForm} corpID={corpID} cardStatus={cardStatus} tabibStatus={tabiibStatus} type={type} />
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                </DialogActions>
            </Dialog>

            <Dialog
                classes={{
                    root: classes.left + " " + classes.modalRoot,
                    paper: classes.companyStatusLogModal,
                }}
                open={feeEditLog}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setFeeEditLog(false);
                    setFeeEditLogs([]);
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>Employee Status Log</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <TableContainer className={classes.container}>
                        <MuiTable stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {logColumns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {feeEditLogs.map((row, key) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                                            <TableCell>
                                                {moment(row.date).format("DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell>
                                                {moment(row.date).format("HH:MM:SS")}
                                            </TableCell>
                                            <TableCell>
                                                {row.user ? (row.user.firstName + " " + row.user.lastName) : ""}
                                            </TableCell>
                                            <TableCell>
                                                {row.status}
                                            </TableCell>
                                            <TableCell>
                                                {row.status_message}
                                            </TableCell>
                                            <TableCell>
                                                {row.remarks}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </MuiTable>
                    </TableContainer>
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setFeeEditLog(false);
                            setFeeEditLogs([]);
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    );
}
