import React from "react";
import axios from "axios";
import config from "config.js"
import libs from "controllers/libs";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import countries from "variables/countries.json";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Document, Page } from 'react-pdf';
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";

const styles = (theme) => ({
  ...SweetAlertStyle,
  subHead: {
    fontWeight: "500",
    marginBottom: "-2px !important",
    float: "left",
    display: "inlineBlock",
  },
  addContainer: {
    marginTop: "20px",
    marginBottom: "12px",
    height: "49px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  addOwnerActions: {
    float: "right",
    display: "inlineBlock",
  },
  question: {
    fontSize: "18px",
    fontWeight: "400",
  },
  radioGroup: {
    marginTop: "-20px !important",
  },
  center: {
    textAlign: "center",
  },
  dropButton: {
    marginTop: "45px !important",
  },
  autocomplete: {
    marginTop: "27px"
  },
  backButton: {
    float: "left !important",
    display: "inlineBlock",
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  required: {
    color: "red",
  },
  ...modalStyle(theme),
});

const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const override = css`
  display: block;
  margin: 0px;
  border-color: red;
`;
export default function OwnersAndControllers(props) {
  const companyID = useSelector((state) => state.corporateOnboarding.companyID);
  const ownerData = useSelector(state => state.corporateOnboarding.ownersAndCtrls);
  const ownerCheckData = { ...ownerData }
  const user = useSelector(state => state.user);
  var tempOwner = null
  var tempBackOwner = null
  var tempBackOwnerTable = null
  var tempDir = null
  var tempDirActual = null
  const [ownersTableData, setOwnersTableData] = React.useState([]);
  const [ownersData, setOwnersData] = React.useState([]);
  const [ownersActualData, setOwnersAcutalData] = React.useState([]);


  const [ownerName, setOwnerName] = React.useState("");
  const [ownerNationality, setOwnerNationality] = React.useState({ "ISO": "BH", "country": "Bahrain" });
  const [ownerCountryResidence, setOwnerCountryResidence] = React.useState({ "ISO": "BH", "country": "Bahrain" });
  const [ownerID, setOwnerID] = React.useState("");
  const [ownerIDFileName, setOwnerIDFileName] = React.useState("");
  const [ownerCPR, setOwnerCPR] = React.useState("");
  const [ownerSharePercentage, setOwnerSharePercentage] = React.useState("");

  const [powerName, setPowerName] = React.useState("");
  const [powerNationality, setPowerNationality] = React.useState({ "ISO": "BH", "country": "Bahrain" });
  const [powerCountryResidence, setPowerCountryResidence] = React.useState({ "ISO": "BH", "country": "Bahrain" });
  const [powerID, setPowerID] = React.useState("");
  const [powerIDLabel, setPowerIDLabel] = React.useState("ID Proof");
  const [powerCPR, setPowerCPR] = React.useState("");

  const [directorsTableData, setDirectorsTableData] = React.useState([]);
  const [directorsData, setDirectorsData] = React.useState([]);
  const [directorsActualData, setDirectorsActualData] = React.useState([]);

  const [dirName, setDirName] = React.useState("");
  const [dirNationality, setDirNationality] = React.useState({ "ISO": "BH", "country": "Bahrain" });
  const [dirCountryResidence, setDirCountryResidence] = React.useState({ "ISO": "BH", "country": "Bahrain" });
  const [dirIDProof, setDirIDProof] = React.useState(null);
  const [dirIDProofFileName, setDirIDProofFileName] = React.useState("");
  const [dirCPR, setDirCPR] = React.useState("");
  let dirTempData = []

  const [pepsTableData, setPEPsTableData] = React.useState([]);
  const [pepsData, setPepsData] = React.useState([]);

  const [PEPS, setPEPS] = React.useState(false);
  const [pepLegalName, setPepsLegalName] = React.useState("");
  const [pepPublicEntity, setPepsPublicEntity] = React.useState("");
  const [pepPosition, setPepsPosition] = React.useState("");
  const [pepBusinessNature, setPepsBusinessNature] = React.useState("");
  const [powFlag, setPowFlag] = React.useState(false);
  const [deletePepRow, setDeletePepRow] = React.useState([]);

  const [update, setUpdate] = React.useState(1);

  const [values, setValues] = React.useState({
    ownerNameErr: null,
    ownerNationalityErr: null,
    ownerCountryResidenceErr: null,
    ownerCPRErr: null,
    ownerSharePercentageErr: null,

    powerNameErr: null,
    powerNationalityErr: null,
    powerCountryResidenceErr: null,
    powerCPRErr: null,


    dirNameErr: null,
    dirNationalityErr: null,
    dirCountryResidenceErr: null,
    dirCPRErr: null,

    pepsErr: null,
    pepLegalNameErr: null,
    pepPublicEntityErr: null,
    pepPositionErr: null,
    pepBusinessNatureErr: null,
  });

  const [showAddPEP, setShowAddPEP] = React.useState(true);
  const [showEditPEP, setShowEditPEP] = React.useState(false);
  const [showAddDirector, setShowAddDirector] = React.useState(true);
  const [showAddOwner, setShowAddOwner] = React.useState(true);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [submitLabel, setSubmitLabel] = React.useState("Saving Data");
  const [dirUpButtonColor, setDirUpButtonColor] = React.useState("secondary");
  const [powerUpButtonColor, setPowerUpButtonColor] = React.useState("secondary");
  const [ownerUpButtonColor, setOwnerUpButtonColor] = React.useState("secondary");

  const [documentViewerData, setDocumentViewerData] = React.useState("");
  const [openImageViewer, setOpenImageViewer] = React.useState(false);
  const [openPDFeViewer, setOpenPDFViewer] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  const [updated, setUpdated] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [respAlert, setRespAlert] = React.useState(null);

  const [deletedDirectors, setDeletedDirectors] = React.useState([]);
  const [deletedOwners, setDeletedOwners] = React.useState([]);

  const [showEditOwner, setShowEditOwner] = React.useState(false);
  const [ownerEditID, setOwnerEditID] = React.useState("");
  const [pepEditID, setPEPEditID] = React.useState("");

  const [dirEditID, setDirEditID] = React.useState("");
  const [showEditDirector, setShowEditDirector] = React.useState(false);
  const [editFlag, setEditFlag] = React.useState({ status: false, id: null, type: null });


  const [editData, setEditData] = React.useState({
    "Owners": [],
    "PowerOfAttorney": {
      "Name": null,
      "Nationality": null,
      "CountryOfResidence": null,
      "CPRNumber": null
    },
    "DirectorsAndAuthorisedSignatories": [],
    "AreAnyExecutivespeps": null,
    "PoliticallyExposedPersons": []
  });

  const forceUpdate = () => {
    setUpdated(update + 1)
  }

  React.useEffect(() => {
    if (props.edit) {
      setOwnerData()
    }
  }, [props.edit]);
  // React.useEffect(()=>{
  //   if(props.editBack){
  //     setOwnerData()
  //   }
  // },[props.editBack])

  const setOwnerData = () => {
    //function to ser owners data on edit click
    let tempData = { ...ownerData }
    setEditData(tempData)
    let tempJson = ownersData;
    let tempActualJson = ownersActualData;
    ownerData.Owners.map((owner, key) => {
      tempJson.push({
        name: owner.Name,
        nationality: owner.Nationality,
        residing_country: owner.CountryOfResidence,
        id_proof: owner.DocumentID,
        cpr: owner.CPRNumber,
        share_percentage: owner.PercentageOfShare
      });
      if (owner.DocumentID) {
        tempActualJson.push({
          name: owner.Name,
          nationality: owner.Nationality,
          residing_country: owner.CountryOfResidence,
          DocumentID: owner.DocumentID,
          cpr: owner.CPRNumber,
          share_percentage: owner.PercentageOfShare
        });
      } else {
        tempActualJson.push({
          name: owner.Name,
          nationality: owner.Nationality,
          residing_country: owner.CountryOfResidence,
          id_proof: "",
          cpr: owner.CPRNumber,
          share_percentage: owner.PercentageOfShare
        });
      }
    })

    setOwnersAcutalData(tempActualJson)
    setOwnersData(tempJson);
    ownersToArray(tempJson);
    setShowAddOwner(false);

    tempJson = directorsData;
    tempActualJson = directorsActualData;
    Promise.all(ownerData.DirectorsAndAuthorisedSignatories.map((dir, key) => {
      tempJson.push({
        name: dir.Name,
        nationality: dir.Nationality,
        residing_country: dir.CountryOfResidence,
        id_proof: dir.DocumentID,
        cpr: dir.CPRNumber,
      });
      if (dir.DocumentID) {
        tempActualJson.push({
          name: dir.Name,
          nationality: dir.Nationality,
          residing_country: dir.CountryOfResidence,
          DocumentID: dir.DocumentID,
          cpr: dir.CPRNumber,
        });
      } else {
        tempActualJson.push({
          name: dir.Name,
          nationality: dir.Nationality,
          residing_country: dir.CountryOfResidence,
          id_proof: "",
          cpr: dir.CPRNumber,
        });
      }

    }))
    setDirectorsActualData(tempActualJson)
    setDirectorsData(tempJson);
    directorsToArray(tempJson);
    cancelAddingDirector();
    if (ownerData.PowerOfAttorney !== undefined && Object.keys(ownerData.PowerOfAttorney).length !== 0) {
      setPowerCPR(ownerData.PowerOfAttorney.CPRNumber)
      setPowerName(ownerData.PowerOfAttorney.Name)
      setPowerIDLabel(ownerData.PowerOfAttorney.DocumentID)
      let poaNationality = countries.find(country => country.ISO == ownerData.PowerOfAttorney.Nationality)
      setPowerNationality(poaNationality)
      let poaCountryResidence = countries.find(country => country.ISO == ownerData.PowerOfAttorney.CountryOfResidence)
      setPowerCountryResidence(poaCountryResidence)
      setPowFlag(true);
    }
    else {
      setPowerCPR("")
      setPowerName("")
      setPowerID(null)
      setPowerIDLabel("ID Proof")
      setPowerNationality({ "ISO": "BH", "country": "Bahrain" })
      setPowerCountryResidence({ "ISO": "BH", "country": "Bahrain" })
      setPowFlag(false);
    }

    if (ownerData.AreAnyExecutivespeps == "YES") {
      setPEPS("true")
      tempJson = pepsData
      Promise.all(ownerData.PoliticallyExposedPersons.map((peps, key) => {


        tempJson.push({
          legal_name: peps.LegalName,
          public_entity: peps.NameOfPublicEntity,
          title_or_position: peps.PositionHeld,
          nature_of_business: peps.NatureOfBusiness,
        })
      }))
      setPepsData(tempJson);
      pepsToArray(tempJson);
      cancelAddingPEP();
      // 
    } else {
      ownerData.PoliticallyExposedPersons = []
      setPepsData([])
      setPEPS("false")
      setEditData({ ...ownerData })

    }
  }

  const openSnackBar = (msg) => {
    //function to tost snackbar message
    setOpenSnack(true);
    setSnackBarMsg(msg);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const verifyEmail = (value) => {
    // function that returns true if value is email, false otherwise
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  const verifyLength = (value, length) => {
    // function that verifies if a string has a given length or not
    if (value !== undefined && value.length >= length) {
      return true;
    }
    return false;
  };

  const verify = (value, type, errVariable, length) => {
    //function verifies the entering data is valid or nor
    setUpdated(true)
    switch (type) {
      case "email":
        if (verifyEmail(value)) {
          setValues({ ...values, [errVariable]: false });
        } else {
          setValues({ ...values, [errVariable]: true });
        }
        break;
      case "length":
        if (verifyLength(value, length)) {
          setValues({ ...values, [errVariable]: false });
        } else {
          setValues({ ...values, [errVariable]: true });
        }
        break;
      default:
        break;
    }
  };

  const addOwner = () => {
    //function to add owner
    if (validateOwner()) {
      setUpdated(true)
      setSaved(false)
      let tempJson = ownersData;
      let tempActualJson = ownersActualData;
      tempJson.push({
        name: ownerName,
        nationality: ownerNationality.country,
        residing_country: ownerCountryResidence.country,
        id_proof: ownerIDFileName,
        cpr: ownerCPR,
        share_percentage: ownerSharePercentage,
        new: true
      });
      tempActualJson.push({
        name: ownerName,
        nationality: ownerNationality,
        residing_country: ownerCountryResidence,
        id_proof: ownerID,
        cpr: ownerCPR,
        share_percentage: ownerSharePercentage,
        new: true
      });
      setOwnersAcutalData(tempActualJson)
      setOwnersData(tempJson);
      ownersToArray(tempJson);
      setShowAddOwner(false);
      setOwnerName("");
      setOwnerNationality({ "ISO": "BH", "country": "Bahrain" });
      setOwnerCountryResidence({ "ISO": "BH", "country": "Bahrain" });
      setOwnerIDFileName("");
      setOwnerCPR("")
      setOwnerSharePercentage("")

      if (props.edit) {
        let tempEditData = editData
        tempEditData.Owners.push({
          "Name": ownerName,
          "Nationality": ownerNationality.ISO,
          "CountryOfResidence": ownerCountryResidence.ISO,
          "CPRNumber": ownerCPR,
          "CPRCopy": ownerID,
          "PercentageOfShare": parseFloat(ownerSharePercentage)
        })
        setEditData(tempEditData)

      }
    }
  };

  const cancelAddingOwner = () => {
    setShowAddOwner(false);
    setOwnerName("");
    setOwnerNationality({ "ISO": "BH", "country": "Bahrain" });
    setOwnerCountryResidence({ "ISO": "BH", "country": "Bahrain" });
    setOwnerIDFileName("");
    setOwnerIDFileName("");
    setOwnerCPR("")
    setOwnerSharePercentage("")
    // }
    if (ownersActualData.length === 0) {
      setShowAddOwner(true);
    }
  };

  const deleteOwner = (id) => {
    //function to delete a owner from array

    let tempOwner = ownersData;
    let tempActualOwner = ownersActualData;
    if ((props.back) || (props.cacheEdit)) {
      if (ownersData.length == 0) {
        tempOwner = tempBackOwnerTable
      }
      if (ownersActualData.length == 0) {
        tempActualOwner = tempBackOwner
      }
    }
    tempOwner.splice(id, 1);
    let deleted = []
    deleted = tempActualOwner.splice(id, 1);
    if (tempOwner.length === 0) {
      setShowAddOwner(true);
    }
    if (props.edit) {
      let updatedDelete = {
        "Name": deleted[0].name,
        "Nationality": deleted[0].nationality,
        "CountryOfResidence": deleted[0].residing_country,
        "CPRNumber": deleted[0].cpr,
        "CPRCopy": deleted[0].id_proof,
        "PercentageOfShare": parseFloat(deleted[0].share_percentage),
        "Delete": true
      }
      let tempDeletedOwners = deletedOwners
      tempDeletedOwners.push(updatedDelete)
      setDeletedOwners(tempDeletedOwners)
      let tempEditData = editData
      // 
      let tempOwnerEdit = []
      Promise.all(tempActualOwner.map((owner, key) => {
        if (owner.DocumentID) {
          tempOwnerEdit.push({
            "Name": owner.name,
            "Nationality": owner.nationality,
            "CountryOfResidence": owner.residing_country,
            "CPRNumber": owner.cpr,
            "DocumentID": owner.DocumentID,
            "PercentageOfShare": parseFloat(owner.share_percentage)
          })
        } else {
          tempOwnerEdit.push({
            "Name": owner.name,
            "Nationality": owner.nationality,
            "CountryOfResidence": owner.residing_country,
            "CPRNumber": owner.cpr,
            "CPRCopy": owner.id_proof,
            "PercentageOfShare": parseFloat(owner.share_percentage)
          })
        }
      }));
      tempEditData.Owners = tempOwnerEdit
      setEditData(tempEditData)

    }
    setOwnersAcutalData(tempActualOwner)
    setOwnersData(tempOwner);
    ownersToArray(tempOwner);
    if ((props.back) || (props.cacheEdit)) {
      if (tempActualOwner.length === 0) {
        setShowAddOwner(true);
      }
    } else {
      cancelAddingOwner()
    }


  };

  const ownersToArray = (data) => {
    //function to convert owner json to array
    let temp = [];
    //alert(JSON.stringify(ownerData))
    //alert(ownerData)

    data.map((owner, key) => {
      if (owner.name) {
        temp.push([
          key + 1,
          owner.name,
          owner.nationality,
          owner.residing_country,
          owner.share_percentage ? parseFloat(owner.share_percentage).toFixed(2) : null,
          owner.cpr,
          owner.id_proof,
          <>
            {owner.new == undefined && !props.back ? (
              <Tooltip placement="bottom" title="Edit">
                <IconButton
                  onClick={() => editOwner(key)}
                  style={{ marginTop: "-10px", marginBottom: "-10px", color: "#014FA4" }}
                  aria-label="delete">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            ) : (              
                <Tooltip placement="right" title="Delete">
                  <IconButton
                    onClick={() => deleteOwner(key)}
                    style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                    aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                  )}
            <Tooltip placement="bottom" title="View Document">
              <IconButton
                onClick={() => idViewer(key, "owner")}
                style={{ marginTop: "-10px", marginBottom: "-10px", color: "#014FA4" }}
                aria-label="delete">
                <VisibilityIcon />
              </IconButton>
            </Tooltip>

          </>,
        ]);
      }
      return 0;
    });
    setOwnersTableData(temp);
    forceUpdate()

  };

  const validateOwner = () => {
    //function to validate owner
    let flag = true, snackBarMsg = "";
    let ownerNameErr = false,
      ownerNationalityErr = false,
      ownerCpr = false,
      ownerSharePercentageErr = false,
      ownerCountryResidenceErr = false;
    if (!verifyLength(ownerName, 3)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter owner name." : snackBarMsg);
      ownerNameErr = true;
      flag = false;
    }
    if (!verifyLength(ownerNationality.country, 1)) {
      ownerNationalityErr = true;
      flag = false;
    }
    if (!verifyLength(ownerCountryResidence.country, 1)) {
      ownerCountryResidenceErr = true;
      flag = false;
    }
    if (!verifyLength(ownerCPR, 2)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter ID No Number." : snackBarMsg);
      ownerCpr = true;
      flag = false;
    }
    if (!verifyLength(ownerIDFileName, 1)) {
      snackBarMsg = (snackBarMsg == "" ? "Please upload id proof." : snackBarMsg);
      setOwnerUpButtonColor("danger")
      flag = false;
    }

    // if (!verifyLength(ownerSharePercentage, 1)) {
    //   snackBarMsg = (snackBarMsg == "" ? "Please enter owner's share percentage." : snackBarMsg);
    //   flag = false;
    //   ownerSharePercentageErr = true
    // }

    if (values.ownerCPRErr) {
      ownerCpr = true;
      flag = false
    }
    if (!flag) {
      openSnackBar(snackBarMsg)
    }

    setValues({
      ...values,
      ownerNameErr: ownerNameErr,
      ownerCPRErr: ownerCpr,
      ownerNationalityErr: ownerNationalityErr,
      ownerCountryResidenceErr: ownerCountryResidenceErr,
      ownerSharePercentageErr: ownerSharePercentageErr
    });
    return flag;
  };

  const directorsToArray = async (data) => {
    //function to convert director json to array
    let temp = [];
    Promise.all(data.map((dir, key) => {
      if (dir.name) {
        temp.push([
          key + 1,
          dir.name,
          dir.nationality,
          dir.residing_country,
          dir.cpr,
          dir.id_proof,
          <>
            {dir.new == undefined && !props.back ? (
              <Tooltip placement="bottom" title="Edit">
                <IconButton
                  onClick={() => editDirector(key)}
                  style={{ marginTop: "-10px", marginBottom: "-10px", color: "#014FA4" }}
                  aria-label="delete">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip placement="right" title="Delete">
                <IconButton
                  onClick={() => deleteDirector(key)}
                  style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                  aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip placement="bottom" title="View Document">
              <IconButton
                onClick={() => idViewer(key, "director")}
                style={{ marginTop: "-10px", marginBottom: "-10px", color: "#014FA4" }}
                aria-label="delete">
                <VisibilityIcon />
              </IconButton>
            </Tooltip>

          </>,
        ]);
      }

      return 0;
    })
    )
    setDirectorsTableData(temp);
  };

  const deleteDirector = (id) => {
    //function to delete a director from array
    let tempDirectors = directorsData;
    let tempActual = directorsActualData;
    if (directorsData.length !== 0) {
      tempDirectors.splice(id, 1);
      if (tempDirectors.length === 0) {
        setShowAddDirector(true);
      }
      let deleted = ""
      deleted = tempActual.splice(id, 1);
      if (props.edit) {
        //alert(JSON.stringify(deleted))
        let updatedDelete = {
          "Name": deleted.name,
          "Nationality": deleted.nationality,
          "CountryOfResidence": deleted.residing_country,
          "CPRNumber": deleted.cpr,
          "CPRCopy": deleted.id_proof,
          "Delete": true
        }
        // alert(JSON.stringify(updatedDelete))
        let tempDeleted = deletedDirectors
        tempDeleted.push(updatedDelete)
        setDeletedDirectors(tempDeleted)
        let tempEditData = editData
        let tempDir = []
        Promise.all(tempActual.map((dir, key) => {
          if (dir.DocumentID) {
            tempDir.push({
              "Name": dir.name,
              "Nationality": dir.nationality,
              "CountryOfResidence": dir.residing_country,
              "CPRNumber": dir.cpr,
              "DocumentID": dir.DocumentID,
            })
          } else {
            tempDir.push({
              "Name": dir.name,
              "Nationality": dir.nationality,
              "CountryOfResidence": dir.residing_country,
              "CPRNumber": dir.cpr,
              "CPRCopy": dir.id_proof,
            })
          }

        }));
        tempEditData.DirectorsAndAuthorisedSignatories = tempDir
        setEditData(tempEditData)

      }
      cancelAddingDirector()
      setDirectorsActualData(tempActual);
      setDirectorsData(tempDirectors);
      directorsToArray(tempDirectors);
    } else {
      tempDirActual.splice(id, 1);
      tempDir.splice(id, 1);
      if (tempDir.length === 0) {
        setShowAddDirector(true);
      }
      if (props.edit) {
        let tempEditData = editData
        tempEditData.DirectorsAndAuthorisedSignatories = tempDirectors
        setEditData(tempEditData)

      }

      setDirectorsActualData(tempDirActual);
      setDirectorsData(tempDir);
      directorsToArray(tempDir);
    }
  };

  const validateDirector = () => {
    //function to validate director
    let flag = true, snackBarMsg = "";
    let dirNameErr = false,
      dirNationalityErr = false,
      dirCPRErr = false,
      dirCountryResidenceErr = false;
    if (!verifyLength(dirName, 3)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter director name." : snackBarMsg);
      dirNameErr = true;
      flag = false;
    }
    if (!verifyLength(dirNationality.country, 3)) {
      dirNationalityErr = true;
      flag = false;
    }
    if (!verifyLength(dirCountryResidence.country, 3)) {
      dirCountryResidenceErr = true;
      flag = false;
    }
    if (!verifyLength(dirCPR, 2)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter ID No number." : snackBarMsg);
      dirCPRErr = true;
      flag = false;
    }

    if (!verifyLength(dirIDProofFileName, 1)) {
      snackBarMsg = (snackBarMsg == "" ? "Please upload id proof." : snackBarMsg);
      setDirUpButtonColor("danger");
      flag = false;
    }
    if (values.dirCPRErr) {
      dirCPRErr = true;
      flag = false
    }
    if (!flag) {
      openSnackBar(snackBarMsg)
    }

    setValues({
      ...values,
      dirNameErr: dirNameErr,
      dirCPRErr: dirCPRErr,
      dirNationalityErr: dirNationalityErr,
      dirCountryResidenceErr: dirCountryResidenceErr,
    });
    return flag;
  };

  const addDirector = () => {
    //function to add director
    if (validateDirector()) {

      let tempJson = directorsData;
      let tempActual = directorsActualData;
      tempActual.push({
        name: dirName,
        nationality: dirNationality,
        residing_country: dirCountryResidence,
        id_proof: dirIDProof,
        cpr: dirCPR,
        new: true
      });
      tempJson.push({
        name: dirName,
        nationality: dirNationality.country,
        residing_country: dirCountryResidence.country,
        id_proof: dirIDProofFileName,
        cpr: dirCPR,
        new: true
      });
      if (props.edit) {
        let tempEditData = editData
        tempEditData.DirectorsAndAuthorisedSignatories.push({
          "Name": dirName,
          "Nationality": dirNationality.ISO,
          "CountryOfResidence": dirCountryResidence.ISO,
          "CPRNumber": dirCPR,
          "CPRCopy": dirIDProof,
        })
        setEditData(tempEditData)

      }
      setDirectorsActualData(tempActual);
      setDirectorsData(tempJson);
      directorsToArray(tempJson);
      cancelAddingDirector();
    }

  };

  const cancelAddingDirector = () => {
    //function to cancel adding director
    setShowAddDirector(false);
    setDirName("");
    setDirNationality({ "ISO": "BH", "country": "Bahrain" });
    setDirCountryResidence({ "ISO": "BH", "country": "Bahrain" });
    setDirIDProofFileName("");
    setDirCPR("")
    setDirUpButtonColor("secondary");
    if (directorsActualData.length === 0) {
      setShowAddDirector(true);
    }
  };

  const dirIDDrop = async (images) => {
    if (editFlag.status) {
      let dirData = { ...ownerData.DirectorsAndAuthorisedSignatories }
      if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
        if (images[0].size < 2097152) {
          getBase64(images[0], (result) => {
            setDirIDProof(result);
            delete dirData[editFlag.id].DocumentID
            dirData[editFlag.id].CPRCopy = result
          });
          setDirUpButtonColor("secondary");
          setDirIDProofFileName(images[0].name);
        } else {
          openSnackBar("File size is too large, Maximum file size is 2 Mb.");
        }
      } else {
        openSnackBar("Please upload jpg,jpeg,png and pdf files");
      }
    } else {
      if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
        if (images[0].size < 2097152) {
          getBase64(images[0], (result) => {
            setDirIDProof(result);
          });
          setDirUpButtonColor("secondary");
          setDirIDProofFileName(images[0].name);
        } else {
          openSnackBar("File size is too large, Maximum file size is 2 Mb.");
        }
      } else {
        openSnackBar("Please upload jpg,jpeg,png and pdf files");
      }
    }
  };

  const ownerIDDrop = async (images) => {
    if (editFlag.status) {
      let ownersData = { ...ownerData.Owners }
      if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
        if (images[0].size < 2097152) {
          getBase64(images[0], (result) => {
            setOwnerID(result);
            let newEditData = editData
            delete ownersData[editFlag.id].DocumentID
            ownersData[editFlag.id].CPRCopy = result
          });
          setOwnerUpButtonColor("secondary");
          setOwnerIDFileName(images[0].name);
        } else {
          openSnackBar("File size is too large, Maximum file size is 2 Mb.");
        }
      } else {
        openSnackBar("Please upload jpg,jpeg,png and pdf files");
      }
    } else {
      if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
        if (images[0].size < 2097152) {
          getBase64(images[0], (result) => {
            setOwnerID(result);
          });
          setOwnerUpButtonColor("secondary");
          setOwnerIDFileName(images[0].name);
        } else {
          openSnackBar("File size is too large, Maximum file size is 2 Mb.");
        }
      } else {
        openSnackBar("Please upload jpg,jpeg,png and pdf files");
      }
    }

  };

  const powerIDDrop = async (images) => {
    if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
      if (images[0].size < 2097152) {
        getBase64(images[0], (result) => {
          setPowerID(result);
          if (props.edit) {
            let tempData = editData
            if (tempData.PowerOfAttorney.DocumentID) {
              delete tempData.PowerOfAttorney.DocumentID
            }
            tempData.PowerOfAttorney.CPRCopy = result
          }
        });
        setPowerUpButtonColor("secondary");
        setPowerIDLabel(images[0].name);
      } else {
        openSnackBar("File size is too large, Maximum file size is 2 Mb.");
      }
    } else {
      openSnackBar("Please upload jpg,jpeg,png and pdf files");
    }
  };
  const validateForm = () => {
    //function to validate PowerOfAttorney
    let flag = true;
    let pepsErr = false;
    let snackBarMsg = ""
    if (!verifyLength(PEPS, 1)) {
      pepsErr = true;
      flag = false;
      snackBarMsg = "Please confirm, is there any PEPs."
    }
    if (PEPS === "true") {
      if (pepsData.length === 0) {
        flag = false;
        snackBarMsg = (snackBarMsg == "" ? "Please add minimum one PEP data." : snackBarMsg);
      }
    }
    if (!props.edit) {
      if (!verifyLength(powerName, 1)) {
        setPowerCPR("")
        setPowerCountryResidence("")
        setPowerID("")
      } else {
        if (!verifyLength(powerCPR, 1)) {
          snackBarMsg = (snackBarMsg == "" ? "Please enter CPR of Power of Attorney." : snackBarMsg);
          flag = false
        } if (!verifyLength(powerCPR, 9)) {
          snackBarMsg = (snackBarMsg == "" ? "Please enter 9 digit cpr number." : snackBarMsg);
          flag = false;
        }
        if (!verifyLength(powerCountryResidence.ISO, 1)) {
          snackBarMsg = (snackBarMsg == "" ? "Please enter Residing country of Power of Attorney." : snackBarMsg);
          flag = false
        }
        if (!verifyLength(powerID, 1)) {
          snackBarMsg = (snackBarMsg == "" ? "Please select ID Proof of Power of Attorney." : snackBarMsg);
          flag = false
        }

      }
    }
    if (!flag) {
      openSnackBar(snackBarMsg)
    }
    setValues({
      ...values,
      pepsErr: pepsErr,
    });
    return flag;
  };

  const deletePEP = async (id) => {
    //function to delete a Publicly exposed person from array
    let tempPEPS = [...pepsData];
    // const found = tempPEPS.filter(element => element.legal_name == tempPEPS[id].legal_name)
    //    setDeletePepRow(found)
    tempPEPS.splice(id, 1);
    if (tempPEPS.length === 0) {
      setShowAddPEP(true);
    }
    setPepsData(tempPEPS);
    pepsToArray(tempPEPS);
    let tempEditData = { ...ownerData }
    tempEditData.PoliticallyExposedPersons = await Promise.all(tempPEPS.map(item => {
      return {
        LegalName: item.legal_name,
        NameOfPublicEntity: item.public_entity,
        NatureOfBusiness: item.nature_of_business,
        PositionHeld: item.title_or_position
      }
    }))
    setEditData(tempEditData)
  };

  const pepsToArray = (data) => {
    //function to convert Publicly exposed person json to array
    let temp = [];
    data.map((pep, key) => {
      temp.push([
        key + 1,
        pep.legal_name,
        pep.public_entity,
        pep.title_or_position,
        pep.nature_of_business,
        <>
          {!props.edit ? (
            <Tooltip placement="right" title="Delete">
              <IconButton
                onClick={() => deletePEP(key)}
                style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          {!props.cacheEdit ? (
            <Tooltip placement="bottom" title="Edit">
              <IconButton
                onClick={() =>editPEPS(key)}
                style={{ marginTop: "-10px", marginBottom: "-10px", color: "#014FA4" }}
                aria-label="delete">
                <EditIcon />
              </IconButton>
            </Tooltip>) : null}
        </>,
      ]);

      return 0;
    });
    setPEPsTableData(temp);

  };

  const validatePEP = () => {
    //function to validate Publicly exposed person
    let flag = true, snackBarMsg = "";
    let pepLegalNameErr = false,
      pepPublicEntityErr = false,
      pepPositionErr = false,
      pepBusinessNatureErr = false;

    if (!verifyLength(pepLegalName, 3)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter PEPs legal name." : snackBarMsg);
      pepLegalNameErr = true;
      flag = false;
    }
    if (!verifyLength(pepPublicEntity, 3)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter PEPS public entity name." : snackBarMsg);
      pepPublicEntityErr = true;
      flag = false;
    }
    if (!verifyLength(pepPosition, 3)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter PEPs Title/position Held." : snackBarMsg);
      pepPositionErr = true;
      flag = false;
    }
    if (!verifyLength(pepBusinessNature, 3)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter PEPs business nature." : snackBarMsg);
      pepBusinessNatureErr = true;
      flag = false;
    } if (!flag) {
      openSnackBar(snackBarMsg)
    }

    setValues({
      ...values,
      pepLegalNameErr: pepLegalNameErr,
      pepPublicEntityErr: pepPublicEntityErr,
      pepPositionErr: pepPositionErr,
      pepBusinessNatureErr: pepBusinessNatureErr,
    });
    return flag;
  };

  const cancelAddingPEP = () => {
    //function to cancel adding Publicly exposed person
    setShowAddPEP(false);
    setShowEditPEP(false);
    setPepsLegalName("");
    setPepsPublicEntity("");
    setPepsPosition("");
    setPepsBusinessNature("");
  };

  const addPEP = () => {
    //function to add Publicly exposed person
    if (validatePEP()) {
      let tempJson = pepsData;
      tempJson.push({
        legal_name: pepLegalName,
        public_entity: pepPublicEntity,
        title_or_position: pepPosition,
        nature_of_business: pepBusinessNature,
      });
      if (props.edit) {
        let tempEditData = editData
        if (tempEditData.PoliticallyExposedPersons == undefined || tempEditData.PoliticallyExposedPersons.length == 0) {
          tempEditData.PoliticallyExposedPersons = []
        }
        tempEditData.PoliticallyExposedPersons.push({
          "LegalName": pepLegalName,
          "NameOfPublicEntity": pepPublicEntity,
          "PositionHeld": pepPosition,
          "NatureOfBusiness": pepBusinessNature,
        })
        setEditData(tempEditData)

      }
      setPepsData(tempJson);
      pepsToArray(tempJson);
      cancelAddingPEP();
    }
  };

  const submitData = () => {
    //  props.nextStep();
    //function to submit data
    setSubmitting(true);
    if (ownersData.length !== 0 && directorsData.length !== 0) {
      if (validateForm()) {
        setSaved(true)
        if ((props.back) || (props.cacheEdit)) {
          let data = {
            status: props.cacheEdit ? "update" : "step",
            user: user._id,
            memberDetails: ownersActualData,
            pow_of_attorney: {
              name: powerName,
              nationality: powerNationality,
              residing_country: powerCountryResidence,
              id_proof: powerID,
              cpr: powerCPR
            },
            directors__authorised_signatories: directorsActualData,
            political_exposed_personalities: pepsData,
          };

          axios.put(`${config.url().baseURL}/update-step-two-details/${props.id}`, data, {
            headers: {
              auth: sessionStorage.getItem("cx_id"),
            },
          }).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
              props.nextStep(true, props.id);
            } else {
              openSnackBar(respData.msg);
              setSubmitting(false);
            }
          }).catch((err) => {
            setSubmitting(false);
            openSnackBar("Unable to process.");
          });

        } else {
          if (props.edit) {
            // console.log(JSON.stringify(editData))

            let data = editData
            if (data.edit = props.approvedCorp !== undefined && props.approvedCorp) {
              data.edit = true
            } else {
              data.edit = false
            }
            //data.edit = props.approvedCorp !== undefined && props.approvedCorp == true ? true : false
            data.user = user._id
            let ownersData = data.Owners
            // let updatedOwners = [...ownersData, ...deletedOwners]
            let updatedOwners = [...ownersData]
            data.Owners = updatedOwners
            axios.post(`${config.url().baseURL}/kyc-update-customer-two/${companyID}`, data, {
              headers: {
                auth: sessionStorage.getItem("cx_id"),
              },
            })
              .then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                  props.nextStep();
                } else {
                  openSnackBar(respData.msg);
                  setSubmitting(false);
                }
              })
              .catch((err) => {
                setSubmitting(false);
                openSnackBar("Unable to process.");
              });
          } else {
            if (companyID !== undefined) {
              // if (true) {
              let data = {
                user: user._id,
                memberDetails: ownersActualData,
                pow_of_attorney: {
                  name: powerName,
                  nationality: powerNationality,
                  residing_country: powerCountryResidence,
                  id_proof: powerID,
                  cpr: powerCPR
                },
                directors__authorised_signatories: directorsActualData,
                // peps_status: PEPS,
                political_exposed_personalities: pepsData,
              };
              axios.post(`${config.url().baseURL}/create-owners/${companyID}`, data, {
                headers: {
                  auth: sessionStorage.getItem("cx_id"),
                },
              })
                .then((resp) => {
                  let respData = resp.data;
                  if (!respData.err) {
                    props.nextStep(true, companyID);
                    setSubmitting(false);
                  } else {
                    openSnackBar(respData.msg);
                    setSubmitting(false);
                  }
                })
                .catch((err) => {
                  setSubmitting(false);
                  openSnackBar("Unable to process.");
                });
            } else {
              setSubmitting(false);
              openSnackBar("Company ID is not available.");
            }
          }
        }
      } else {
        setSubmitting(false);
      }
    } else {
      setSubmitting(false);
      openSnackBar("Please add minimum one data of each owners and directors .");
    }
  };

  const copyOwnerToPowerOfAttorney = () => {
    //copy owner data to power of attorney
    setPowerName(ownersActualData[0].name)
    setPowerCountryResidence(ownersActualData[0].residing_country)
    setPowerNationality(ownersActualData[0].nationality)
    setPowerCPR(ownersActualData[0].cpr)
    setPowerID(ownersActualData[0].id_proof)
    setPowerIDLabel(ownersData[0].id_proof)
  }

  const copyOwnerDirectors = async () => {
    //copy owner data to directors data
    let tempDirectors = [...ownersData]
    let tempActualDirectors = [...ownersActualData]
    dirTempData = [...ownersActualData]
    delete tempDirectors.share_percentage
    delete tempActualDirectors.share_percentage
    tempDir = tempDirectors
    tempDirActual = tempActualDirectors
    setDirectorsData(tempDirectors)
    setDirectorsActualData(tempActualDirectors)
    await directorsToArray(tempDirectors)
    if (tempDirectors.length != 0) {
      setShowAddDirector(false)
    }
  }

  const findExistingCPR = (cpr, type) => {
    //function to find cpr is already added

    switch (type) {
      case "owner":
        if (ownersActualData.find(owner => owner.cpr == cpr) !== undefined) {
          openSnackBar("Owner with same cpr exist")
          setValues({ ...values, ownerCPRErr: true });
        }
        break;
      case "director":
        if (directorsActualData.find(dir => dir.cpr == cpr) !== undefined) {
          openSnackBar("Director with same cpr exist")
          setValues({ ...values, dirCPRErr: true });
        }
        break;
      default:
        break;
    }

  }

  const idViewer = (id, type) => {
    //function to set id proof viewer
    setPageNumber(1)
    switch (type) {
      case "owner":
        if ((props.back) || (props.cacheEdit)) {
          if (ownersActualData.length == 0) {
            fileViewer(tempOwner[id].id_proof)
          } else {
            fileViewer(ownersActualData[id].id_proof)
          }
        } else {
          if (props.edit) {
            if (ownersActualData[id].id_proof) {
              fileViewer(ownersActualData[id].id_proof)
            } else {
              fetchDoc(id, type)
            }
          } else {
            fileViewer(ownersActualData[id].id_proof)
          }
        }
        break;
      case "power":
        if (props.edit) {
          if (powerID) {
            fileViewer(powerID)
          } else {
            fetchDoc(id, type)
          }
        } else {
          fileViewer(powerID)
        }
        break;
      case "director":
        if (props.edit) {
          if (directorsActualData[id].id_proof) {
            fileViewer(directorsActualData[id].id_proof)
          } else {
            fetchDoc(id, type)
          }
        } else {
          if (directorsActualData[id] == undefined) {
            fileViewer(dirTempData[id].id_proof)
          } else {
            fileViewer(directorsActualData[id].id_proof)
          }

        }
        break;
      default:
        break;
    }

    function fileViewer(file) {
      setPageNumber(1)
      if (file) {
        let extension = file.substring(file.indexOf("/") + 1, file.indexOf(";base64"));
        if (extension == "pdf") {
          setDocumentViewerData(file)
          setOpenPDFViewer(true)
        } else {
          setDocumentViewerData(file)
          setOpenImageViewer(true)
        }
      }
    }


  }

  const editOwner = async (id) => {
    setEditFlag({ status: true, id: id, type: "owner" })
    setOwnerEditID(id)
    let ownerDataTemp = ownersActualData
    setOwnerName(ownerDataTemp[id].name)
    let ownerNationalityTemp = await countries.find(country => country.ISO == ownerDataTemp[id].nationality)
    setOwnerNationality(ownerNationalityTemp)
    let ownerCountryResidenceTemp = await countries.find(country => country.ISO == ownerDataTemp[id].residing_country)
    setOwnerCountryResidence(ownerCountryResidenceTemp)
    setOwnerCPR(ownerDataTemp[id].cpr)
    setOwnerSharePercentage(parseFloat(ownerDataTemp[id].share_percentage).toFixed(2))
    if (props.edit) {
      setOwnerIDFileName(ownersData[id].id_proof)
    } else {
      setOwnerIDFileName(ownerDataTemp[id].id_proof)
    }

    setShowEditOwner(true)

  }
  const cancelEditOwner = () => {
    setShowEditOwner(false)
    cancelAddingOwner()
  }

  const saveEditOwner = (id) => {
    //function to save edit owner 
    if (validateOwner()) {
      let tempEdiOwner = [...ownersActualData]
      let temEditOwnerTable = [...ownersData]
      tempEdiOwner[id].name = ownerName
      tempEdiOwner[id].nationality = ownerNationality.ISO
      tempEdiOwner[id].residing_country = ownerCountryResidence.ISO
      tempEdiOwner[id].cpr = ownerCPR
      tempEdiOwner[id].share_percentage = ownerSharePercentage
      tempEdiOwner[id].id_proof = ownerID

      temEditOwnerTable[id].name = ownerName
      temEditOwnerTable[id].nationality = ownerNationality.ISO
      temEditOwnerTable[id].residing_country = ownerCountryResidence.ISO
      temEditOwnerTable[id].cpr = ownerCPR
      temEditOwnerTable[id].share_percentage = ownerSharePercentage
      temEditOwnerTable[id].id_proof = ownerIDFileName
      if (props.edit) {
        let ownersData = [...ownerData.Owners]
        ownersData[id].Name = ownerName
        ownersData[id].Nationality = ownerNationality.ISO
        ownersData[id].CountryOfResidence = ownerCountryResidence.ISO
        ownersData[id].CPRNumber = ownerCPR
        if (ownersData[id].DocumentID) {
          ownersData[id].DocumentID = ownersData[id].DocumentID
        }
        ownersData[id].PercentageOfShare = parseFloat(ownerSharePercentage)
        let newEditData = editData
        newEditData.Owners = ownersData
        setEditData(newEditData)

      }
      setOwnersAcutalData(tempEdiOwner)
      setOwnersData(temEditOwnerTable)
      ownersToArray(temEditOwnerTable)
      setShowEditOwner(false)
      cancelAddingOwner()
    }
  }

  const editDirector = (id) => {
    setEditFlag({ status: true, id: id, type: "director" })
    setDirEditID(id)
    let dataTemp = directorsActualData
    setDirName(dataTemp[id].name)
    let dirNationalityTemp = countries.find(country => country.ISO == dataTemp[id].nationality)
    setDirNationality(dirNationalityTemp)
    let dirCountryResidenceTemp = countries.find(country => country.ISO == dataTemp[id].residing_country)
    setDirCountryResidence(dirCountryResidenceTemp)
    setDirCPR(dataTemp[id].cpr)
    if (props.edit) {
      setDirIDProofFileName(directorsData[id].id_proof)
    } else {
      setDirIDProofFileName(dataTemp[id].id_proof)
    }
    setShowEditDirector(true)
  }

  const deleteID = () => {
    //function to delete id
    switch (editFlag.type) {
      case "owner":
        setOwnerID("")
        setOwnerIDFileName("")
        break;
      case "director":
        setDirIDProof("")
        setDirIDProofFileName("")
        break;
      default:
        openSnackBar("Can't find document.")
        break;
    }

  }
  const saveEditDirector = (id) => {
    //function  to save edit owner
    if (validateDirector()) {
      let tempDirData = [...directorsActualData]
      let tempDirTable = [...directorsData]
      tempDirData[id].name = dirName
      tempDirData[id].nationality = dirNationality.ISO
      tempDirData[id].residing_country = dirCountryResidence.ISO
      tempDirData[id].cpr = dirCPR
      tempDirData[id].id_proof = dirIDProof

      tempDirTable[id].name = dirName
      tempDirTable[id].nationality = dirNationality.ISO
      tempDirTable[id].residing_country = dirCountryResidence.ISO
      tempDirTable[id].cpr = dirCPR
      tempDirTable[id].id_proof = dirIDProofFileName

      if (props.edit) {
        let dirData = [...ownerData.DirectorsAndAuthorisedSignatories]
        dirData[id].Name = dirName
        dirData[id].Nationality = dirNationality.ISO
        dirData[id].CountryOfResidence = dirCountryResidence.ISO
        dirData[id].CPRNumber = dirCPR
        if (dirData[id].DocumentID) {
          dirData[id].DocumentID = dirData[id].DocumentID
        }
        let newEditData = editData
        newEditData.DirectorsAndAuthorisedSignatories = dirData
        setEditData(newEditData)

      }
      setDirectorsActualData(tempDirData);
      setDirectorsData(tempDirTable);
      directorsToArray(tempDirTable);
      setShowEditDirector(false)
      cancelAddingDirector();
    }
  }


  const editPEPS = (id) => {
    let dataTemp = pepsData
    setPEPEditID(id)
    setPepsLegalName(dataTemp[id].legal_name)
    setPepsPublicEntity(dataTemp[id].public_entity)
    setPepsPosition(dataTemp[id].title_or_position)
    setPepsBusinessNature(dataTemp[id].nature_of_business)
    // let newArray = dataTemp.map((item, index) => {
    //   if ((item) == id) {
    //     item.legal_name = pepLegalName
    //     item.public_entity = pepPublicEntity
    //     item.title_or_position = pepPosition
    //     item.nature_of_business = pepBusinessNature
        
    //   }
    //   return item
      
    // })
    
    // let pepDetails = { ...ownerData } 
    
    // pepDetails.PoliticallyExposedPersons = newArray
    // pepsToArray(newArray);
    // setEditData(pepDetails)
    setShowEditPEP(true)
    // setShowAddPEP(true)
    // deletePEP(id)

    // console.log(id, pepDetails)
    // let tmData = [...pepsData]
    // let emp = tmData.filter((item) => {
    //   return (item.legal_name != dataTemp[id].legal_name)
    // })
    // setPepsData(emp)
    //
    // setTemId(dataTemp[id].legal_name)

  }

  const saveEditPEP = async(id)=>{
    let dataTemp = pepsData
    setPepsLegalName(dataTemp[id].legal_name)
    setPepsPublicEntity(dataTemp[id].public_entity)
    setPepsPosition(dataTemp[id].title_or_position)
    setPepsBusinessNature(dataTemp[id].nature_of_business)
    let newArray = dataTemp.map((item, index) => {
      if ((index) == id) {
        item.legal_name = pepLegalName
        item.public_entity = pepPublicEntity
        item.title_or_position = pepPosition
        item.nature_of_business = pepBusinessNature
        
      }
      return item
      
    })
    
    let pepDetails = { ...ownerData } 
    // let tempEditData = { ...ownerData }
    pepDetails.PoliticallyExposedPersons = await Promise.all(newArray.map(item => {
      return {
        LegalName: item.legal_name,
        NameOfPublicEntity: item.public_entity,
        NatureOfBusiness: item.nature_of_business,
        PositionHeld: item.title_or_position
      }
    }))
    // pepDetails.PoliticallyExposedPersons = newArray
    pepsToArray(newArray);
    setEditData(pepDetails)
    cancelAddingPEP();

  }

  const handleEditData = (keyOne, keyTwo, keyThree, value) => {
    //function to handle edit data
    let data = editData
    if (!data[`${keyOne}`]) {
      data[`${keyOne}`] = {}
    }
    if (props.edit) {
      if (keyThree !== null) {
        data[`${keyOne}`][`${keyTwo}`][`${keyThree}`] = value
      } else if (keyTwo !== null) {
        if (!data[`${keyOne}`][`${keyTwo}`]) {
          data[`${keyOne}`][`${keyTwo}`] = {}
        }
        data[`${keyOne}`][`${keyTwo}`] = value
      } else {
        data[`${keyOne}`] = value
      }
      setEditData(data)

    }
  }

  const fetchDoc = (id, type) => {
    //function to fetch docs
    let docID = ""
    if (id !== null) {
      switch (type) {
        case "owner":
          docID = ownersData[id].id_proof
          break;
        case "power":
          docID = powerIDLabel
          break;
        case "director":
          docID = directorsData[id].id_proof
          break;
        default:
          break;
      }

      setDocumentViewerData(null)
      try {
        axios.post(`${config.url().baseURL}/get-company-docs-id/${docID}`).then((resp) => {
          let respData = resp.data;
          if (!respData.err) {
            let extension = respData.data.Content.substring(respData.data.Content.indexOf("/") + 1, respData.data.Content.indexOf(";base64"));
            setDocumentViewerData(respData.data.Content, respData.data.DocumentType)
            if (extension == "pdf") {
              setOpenPDFViewer(true)
              setOpenImageViewer(false)
            } else {
              setOpenImageViewer(true)
              setOpenPDFViewer(false)
            }
          } else {
            setDocumentViewerData(null)
            openSnackBar(respData.msg);
          }
        });
      } catch (error) {
        openSnackBar("Unable to fetch documents.");
      }

    } else {
      openSnackBar("Invalid document id.");
    }
  };

  const deletePowerDoc = () => {
    setPowerID("")
    setPowerIDLabel("ID Proof")
  }

  React.useEffect(() => {

    if ((props.back) || (props.cacheEdit)) {
      if (props.id !== undefined) {
        setBackData(props.id)
      }
    }
  }, [props.back, props.cacheEdit]);
  const [temId, setTemId] = React.useState('')
  // React.useEffect(() => {
  //   if (temId != null) {
  //     let tempPeps = [...pepsData]
  //     let tempEditData = JSON.parse(JSON.stringify(editData));
  //     tempPeps = tempPeps.map((item) => {
  //       return ({
  //         LegalName: item.legal_name,
  //         NameOfPublicEntity: item.public_entity,
  //         NatureOfBusiness: item.nature_of_business,
  //         PositionHeld: item.title_or_position
  //       })
  //     })
  //  console.log("8888888888888",tempEditData)
  // tempEditData.PoliticallyExposedPersons = tempPeps
  // tempEditData.PowerOfAttorney.Name = powerName
  // tempEditData.PowerOfAttorney.CPRNumber = powerCPR
  // tempEditData.PowerOfAttorney.CountryOfResidence = powerCountryResidence
  // tempEditData.PowerOfAttorney.Nationality = powerNationality    // console.log(tempEditData)
  //     setEditData(tempEditData)
  //     

  //   }

  // }, [temId]);
  const setBackData = (id) => {
    //function to set back button data
    setSubmitLabel("Fetching Data..")
    setSubmitting(true)
    axios.post(`${config.url().baseURL}/get-step-two-details`, { company: id }).then((resp) => {
      let respData = resp.data;
      if (!respData.err) {
        setSubmitting(false)
        setTimeout(function () {
          setSubmitLabel("Saving Data..")
        }, 1000);

        let data = respData.data
        let tempOwnerJson = [];
        let tempOwnerActualJson = [];
        let tempJson = [];
        let tempActualJson = [];

        Promise.all(data.memberDetails.map((owner, key) => {
          tempOwnerJson.push({
            name: owner.name,
            nationality: owner.nationality.country,
            residing_country: owner.residing_country.country,
            id_proof: `${owner.name}_ID_Proof`,
            cpr: owner.cpr,
            share_percentage: owner.share_percentage
          });
          tempOwnerActualJson.push({
            name: owner.name,
            nationality: owner.nationality,
            residing_country: owner.residing_country,
            id_proof: owner.id_proof,
            cpr: owner.cpr,
            share_percentage: owner.share_percentage
          });
        }))
        cancelAddingOwner()
        setShowAddOwner(false)
        tempOwner = tempOwnerActualJson

        setOwnersAcutalData(tempOwnerActualJson)
        setOwnersData(tempOwnerJson);
        ownersToArray(tempOwnerJson);
        tempBackOwner = tempOwnerActualJson
        tempBackOwnerTable = tempOwnerJson
        tempJson = directorsData;
        tempActualJson = directorsActualData;
        Promise.all(data.directors__authorised_signatories.map((dir, key) => {
          tempJson.push({
            name: dir.name,
            nationality: dir.nationality.country,
            residing_country: dir.residing_country.country,
            id_proof: `${dir.name}_ID_Proof`,
            cpr: dir.cpr,
          });
          tempActualJson.push({
            name: dir.name,
            nationality: dir.nationality,
            residing_country: dir.residing_country,
            id_proof: dir.id_proof,
            cpr: dir.cpr,
          });
        }))
        setDirectorsActualData(tempActualJson)
        setDirectorsData(tempJson);
        directorsToArray(tempJson);
        cancelAddingDirector();
        if (data.pow_of_attorney.name !== undefined && data.pow_of_attorney.name !== null && data.pow_of_attorney.name.length !== 0) {
          setPowerCPR(data.pow_of_attorney.cpr)
          setPowerName(data.pow_of_attorney.name)
          setPowerIDLabel(`${data.pow_of_attorney.name}_ID_Proof`)
          setPowerID(data.pow_of_attorney.id_proof)
          setPowerNationality(data.pow_of_attorney.nationality)
          setPowerCountryResidence(data.pow_of_attorney.residing_country)
        } else {
          setPowerCPR("")
          setPowerName("")
          setPowerIDLabel("")
          setPowerID(null)
          setPowerIDLabel("ID Proof")
          setPowerNationality({ "ISO": "BH", "country": "Bahrain" })
          setPowerCountryResidence({ "ISO": "BH", "country": "Bahrain" })
        }
        if (data.political_exposed_personalities.length !== 0) {
          setPEPS("true")
          tempJson = pepsData
          Promise.all(data.political_exposed_personalities.map((peps, key) => {
            tempJson.push({
              legal_name: peps.legal_name,
              public_entity: peps.public_entity,
              title_or_position: peps.title_or_position,
              nature_of_business: peps.nature_of_business,
            });
          }))
          setPepsData(tempJson);
          pepsToArray(tempJson);
          cancelAddingPEP();
        } else {
          setPEPS("false")
        }
      } else {
        setSubmitting(false)
        setSubmitLabel("Saving Data..")
      }
    });
  }

  const goBack = () => {
    //function to go back on corporate onboarding
    if (updated == true && saved == false) {
      setRespAlert(
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => {
            props.previousStep(true, companyID)
          }}
          onCancel={() => {
            setRespAlert(null)
          }}
          showCancel
          confirmBtnText="Yes"
          cancelBtnText="No"
          cancelBtnCssClass={classes.button}
          confirmBtnCssClass={classes.button + " " + classes.danger}
        >
          Do you really want to go back without saving?
        </SweetAlert>)
    } else {
      props.previousStep(true, companyID)
    }
  }

  const classes = useStyles();
  return (
    <div>
      {respAlert}
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <h6 className={classes.subHead}>Owners holding 20% or more of company share</h6>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <Divider style={{ background: "black" }} fullWidth />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Collapse
                in={ownersTableData.length !== 0}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <Table
                  tableHeaderColor="primary"
                  tableHead={["#", "Name", "Nationality", "Country of Residence", "Share Percentage", "ID No", "ID Proof", ""]}
                  tableData={ownersTableData}
                />
                {!showAddOwner ? (
                  <>
                    <Button color="primary" size="lg" simple onClick={() => { setShowAddOwner(true); setOwnerIDFileName("") }}>
                      Add Owner
                    </Button>
                  </>
                ) : null}
              </Collapse>
            </GridItem>

            <Collapse
              in={showAddOwner}
              style={{ transformOrigin: "0 0 0", textAlign: "center", width: "100%", padding: "0px 15px" }}
              {...(true ? { timeout: 500 } : {})}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    error={values.ownerNameErr}
                    labelText={
                      <span>
                        Owner Name <small className={classes.required}>*</small>
                      </span>
                    }
                    id="owner-name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: ownerName,
                      onChange: (e) => {
                        setOwnerName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : ownerName);
                        verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : ownerName, "length", "ownerNameErr", 3);
                      },
                      onBlur: (e) => {
                        setOwnerName(e.target.value.trim());
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Autocomplete
                    className={classes.autocomplete}
                    options={countries}
                    value={ownerNationality}
                    getOptionLabel={(option) => option.country}
                    fullWidth
                    onChange={(value, item) => {
                      if (item !== null) {
                        setOwnerNationality(item);
                        verify(item.ISO, "length", "ownerNationalityErr", 1);
                      }

                    }}
                    renderInput={(params) => <TextField fullWidth {...params} error={values.ownerNationalityErr}
                      label={
                        <span>
                          Nationality <small className={classes.required}>*</small>
                        </span>
                      }

                    />}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Autocomplete
                    className={classes.autocomplete}
                    options={countries}
                    value={ownerCountryResidence}
                    getOptionLabel={(option) => option.country}
                    fullWidth
                    onChange={(value, item) => {
                      if (item !== null) {
                        setOwnerCountryResidence(item);
                        verify(item.country, "length", "ownerCountryResidenceErr", 3);
                      }

                    }}
                    renderInput={(params) => <TextField fullWidth {...params} error={values.ownerCountryResidenceErr}
                      label={
                        <span>
                          Country of Residence <small className={classes.required}>*</small>
                        </span>
                      }
                    />}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    error={values.ownerSharePercentageErr}
                    labelText="Percentage of Share"
                    id="owner-percentage"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: ownerSharePercentage,
                      onChange: (e) => {
                        if (e.target.value <= 100) {
                          if (e.target.value === "" || !isNaN(e.target.value)) {
                            setOwnerSharePercentage(e.target.value);
                            setValues({ ...values, ownerSharePercentageErr: false });
                          }
                        }
                      },
                      onBlur: (e) => {
                        const re = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
                        if (e.target.value < 20 && e.target.value !== "") {
                          setOwnerSharePercentage("");
                          openSnackBar("Owner should have minimum 20% of share.")
                        } else {
                          if (re.test(e.target.value)) {
                            setOwnerSharePercentage(parseFloat(e.target.value.trim()).toFixed(2));
                          } else {
                            setOwnerSharePercentage("");
                            openSnackBar("Owner should have minimum 20% of share.")
                          }

                        }
                      },
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    error={values.ownerCPRErr}
                    labelText={
                      <span>
                        ID No <small className={classes.required}>*</small>
                      </span>
                    }
                    id="ownerCPR"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: ownerCPR,
                      onChange: (e) => {
                        if (e.target.value.length <= 20) {
                          const re = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
                          if (e.target.value === "" || re.test(e.target.value)) {
                            if (verifyLength(e.target.value, 2)) {
                              setOwnerCPR(e.target.value);
                              setValues({ ...values, ownerCPRErr: false });
                            } else {
                              setOwnerCPR(e.target.value);
                              setValues({ ...values, ownerCPRErr: true });
                            }
                          }
                        }
                      },
                      onBlur: (e) => {
                        setOwnerCPR(e.target.value.trim());
                        findExistingCPR(e.target.value, "owner")
                      },
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  {ownerIDFileName !== "" ? (
                    <CustomInput
                      labelText="ID Proof"
                      id="dir-id-proof"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        autoComplete: "off",
                        readOnly: true,
                        value: ownerIDFileName,
                      }}
                    />
                  ) : (
                    <>
                      <br /> <br />
                      <Dropzone onDrop={ownerIDDrop}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button size="sm" fullWidth color={ownerUpButtonColor}>
                              ID Proof
                            </Button>
                          </div>
                        )}
                      </Dropzone>
                    </>
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  {ownersTableData.length !== 0 ? (
                    <>
                      <Button
                        color="primary"
                        className={classes.addOwnerActions}
                        size="sm"
                        simple
                        onClick={cancelAddingOwner}>
                        Cancel
                      </Button>
                    </>
                  ) : null}

                  <Button color="primary" className={classes.addOwnerActions} size="sm" onClick={addOwner}>
                    Add Owner
                  </Button>
                </GridItem>
              </GridContainer>
            </Collapse>
            <GridItem xs={12} sm={12}>
              <h6 className={classes.subHead}>
                Power of Attorney<small>(if applicable)</small>{" "}
              </h6>
            </GridItem>

            <GridItem xs={12} sm={12}>
              <Divider style={{ background: "black" }} fullWidth />
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={12}>
              <Button size="sm" color="primary" style={{ height: "25px", float: "right" }} onClick={copyOwnerToPowerOfAttorney}>Copy Owners Data</Button>
            </GridItem> */}
            <GridItem xs={12} sm={12} md={9}>
              <CustomInput
                error={values.powerNameErr}
                labelText="Name"
                id="power-name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: powerName,
                  // disabled: props.edit,
                  onChange: (e) => {
                    setPowerName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : powerName);
                    verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : powerName, "length", "ownerNameErr", 3);
                  },
                  onBlur: (e) => {
                    setPowerName(e.target.value.trim());
                    handleEditData("PowerOfAttorney", "Name", null, e.target.value)
                  }
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                error={values.powerCPRErr}
                labelText="CPR"
                id="porCPR"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: powerCPR,
                  disabled: powFlag,
                  onChange: (e) => {
                    if (e.target.value.length <= 9) {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value == "" || re.test(e.target.value)) {
                        if (e.target.value.length !== 0) {
                          if (verifyLength(e.target.value, 9)) {
                            setPowerCPR(e.target.value);
                            setValues({ ...values, powerCPRErr: false });
                          } else {
                            setPowerCPR(e.target.value);
                            setValues({ ...values, powerCPRErr: true });
                          }
                        } else {
                          setPowerCPR(e.target.value);
                          setValues({ ...values, powerCPRErr: false })
                        }
                      } else {
                        setValues({ ...values, powerCPRErr: false });
                      }
                    }
                  },
                  onBlur: (e) => {
                    setPowerCPR(e.target.value.trim());
                    handleEditData("PowerOfAttorney", "CPRNumber", null, e.target.value)
                  }
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Autocomplete
                className={classes.autocomplete}
                options={countries}
                value={powerNationality}
                getOptionLabel={(option) => option.country}
                fullWidth
                onChange={(value, item) => {
                  if (item !== null) {
                    setPowerNationality(item);
                    verify(item.country, "length", "powerNationalityErr", 3);
                    if (props.edit) {
                      handleEditData("PowerOfAttorney", "Nationality", null, item.ISO)
                    }
                  }

                }}
                renderInput={(params) => <TextField fullWidth {...params} error={values.setPowerCountryResidence} label="Nationality" />}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Autocomplete
                className={classes.autocomplete}
                options={countries}
                value={powerCountryResidence}
                getOptionLabel={(option) => option.country}
                fullWidth
                onChange={(value, item) => {
                  if (item !== null) {
                    setPowerCountryResidence(item);
                    verify(item.country, "length", "powerCountryResidenceErr", 3);
                    if (props.edit) {
                      handleEditData("PowerOfAttorney", "CountryOfResidence", null, item.ISO)
                    }
                  }

                }}
                renderInput={(params) => <TextField fullWidth {...params} error={values.setPowerCountryResidence} label="Country of Residence" />}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={3}>

              {powerIDLabel == "ID Proof" ? (
                <>
                  <br /> <br />
                  <Dropzone onDrop={powerIDDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button size="sm" fullWidth color={powerUpButtonColor}>
                          {powerIDLabel}
                        </Button>
                      </div>
                    )}
                  </Dropzone>
                </>
              ) : (
                <CustomInput
                  error={values.powerNameErr}
                  labelText="ID Proof"
                  id="power-idProof-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    readOnly: false,
                    value: powerIDLabel
                  }}
                />
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <br /> <br />
              {powerIDLabel !== "ID Proof" ? (
                <>
                  <Tooltip placement="right" title="Delete Document">
                    <IconButton
                      onClick={() => deletePowerDoc()}
                      style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                      aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip placement="bottom" title="View Document">
                    <IconButton
                      onClick={() => idViewer(1, "power")}
                      style={{ marginTop: "-10px", marginBottom: "-10px", color: "#014FA4" }}
                      aria-label="delete">
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>

                </>
              ) : null}
            </GridItem>
            <GridItem xs={12} sm={12}>
              <h6 className={classes.subHead}>
                Directors and Authorized Signatories <small>(as mentioned in the Commercial Registration)</small>
              </h6>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <Divider style={{ background: "black" }} fullWidth />
            </GridItem>
            {
              ((!props.edit) && (!props.cacheEdit)) ? (
                <GridItem xs={12} sm={12} md={12}>
                  <Button size="sm" color="primary" style={{ height: "25px", marginBottom: "-10px", float: "right" }}
                    onClick={() => {
                      copyOwnerDirectors()
                    }}>Copy Owners Data</Button>
                </GridItem>
              ) : null
            }

            <GridItem xs={12} sm={12} md={12}>
              <Collapse
                in={directorsTableData.length !== 0}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <Table
                  tableHeaderColor="primary"
                  tableHead={["#", "Name", "Nationality", "Country of Residence", "ID No", "ID Proof", ""]}
                  tableData={directorsTableData}
                />
                {!showAddDirector ? (
                  <>
                    <Button color="primary" size="lg" simple onClick={() => { setShowAddDirector(true); setDirIDProofFileName("") }}>
                      Add Director
                    </Button>
                  </>
                ) : null}
              </Collapse>
            </GridItem>
            <Collapse
              in={showAddDirector}
              style={{ transformOrigin: "0 0 0", textAlign: "center", width: "100%", padding: "0px 15px" }}
              {...(true ? { timeout: 500 } : {})}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    error={values.dirNameErr}
                    labelText={
                      <span>
                        Name <small className={classes.required}>*</small>
                      </span>
                    }
                    id="dir-name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: dirName,
                      onChange: (e) => {
                        setDirName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : dirName);
                        verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : dirName, "length", "dirNameErr", 3);
                      },
                      onBlur: (e) => {
                        setDirName(e.target.value.trim());
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Autocomplete
                    className={classes.autocomplete}
                    options={countries}
                    value={dirNationality}
                    getOptionLabel={(option) => option.country}
                    fullWidth
                    onChange={(value, item) => {
                      if (item !== null) {
                        setDirNationality(item);
                        verify(item.country, "length", "dirNationalityErr", 3);
                      }
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} error={values.dirNationalityErr}
                      label={
                        <span>
                          Nationality<small className={classes.required}>*</small>
                        </span>
                      }
                    />}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Autocomplete
                    className={classes.autocomplete}
                    options={countries}
                    value={dirCountryResidence}
                    getOptionLabel={(option) => option.country}
                    fullWidth
                    onChange={(value, item) => {
                      if (item !== null) {
                        setDirCountryResidence(item);
                        verify(item.country, "length", "dirCountryResidenceErr", 3);
                      }
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} error={values.dirNationalityErr}
                      label={
                        <span>
                          Country of Residence<small className={classes.required}>*</small>
                        </span>
                      }
                    />}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    error={values.dirCPRErr}
                    labelText={
                      <span>
                        ID No. <small className={classes.required}>*</small>
                      </span>
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: dirCPR,
                      onChange: (e) => {
                        if (e.target.value.length <= 20) {
                          const re = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
                          if (e.target.value === "" || re.test(e.target.value)) {
                            if (verifyLength(e.target.value, 2)) {
                              setDirCPR(e.target.value);
                              setValues({ ...values, dirCPRErr: false });
                            } else {
                              setDirCPR(e.target.value);
                              setValues({ ...values, dirCPRErr: true });
                            }
                          }
                        }
                      },
                      onBlur: (e) => {
                        findExistingCPR(e.target.value, "director")

                      },
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  {dirIDProofFileName !== "" ? (
                    <CustomInput
                      labelText="ID Proof"
                      id="dir-id-proof"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        autoComplete: "off",
                        readOnly: true,
                        value: dirIDProofFileName,
                      }}
                    />
                  ) : (
                    <>
                      <br /> <br />
                      <Dropzone onDrop={dirIDDrop}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button size="sm" fullWidth color={dirUpButtonColor}>
                              ID Proof
                            </Button>
                          </div>
                        )}
                      </Dropzone>
                    </>
                  )}
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  {directorsTableData.length !== 0 ? (
                    <>
                      <Button
                        color="primary"
                        className={classes.addOwnerActions}
                        size="sm"
                        simple
                        onClick={cancelAddingDirector}>
                        Cancel
                      </Button>
                    </>
                  ) : null}
                  <Button color="primary" className={classes.addOwnerActions} size="sm" onClick={addDirector}>
                    Add Director
                  </Button>
                </GridItem>
              </GridContainer>
            </Collapse>
            <GridItem xs={12} sm={12}>
              <p className={classes.question}>
                Are any beneficial owners, company directors or senior executives are Politically Exposed Persons (PEPs) ? <small className={classes.required}>*</small>
              </p>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <FormControl
                className={classes.radioGroup}
                error={values.pepsErr}
                style={{ textAlign: "left !important" }}
                fullWidth
                component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  value={PEPS}
                  onChange={(e) => {
                    if (e.target.value == "false" && pepsData.length !== 0) {
                      openSnackBar("Please remove all Politically Exposed Persons")
                    } else {
                      setPEPS(e.target.value);
                      verify(e.target.value, "length", "pepsErr", 3);
                      if (props.edit) {
                        if (e.target.value == "true") {
                          handleEditData("AreAnyExecutivespeps", null, null, "YES")
                        } else {
                          handleEditData("AreAnyExecutivespeps", null, null, "NO")
                        }
                      }
                    }
                  }}
                  name="position">
                  <FormControlLabel
                    className={classes.statusText}
                    value="true"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />

                  <FormControlLabel
                    className={classes.statusText}
                    value="false"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </GridItem>
            <Collapse
              in={PEPS === "true"}
              style={{ transformOrigin: "0 0 0", textAlign: "center", width: "100%", padding: "0px 15px" }}
              {...(true ? { timeout: 500 } : {})}>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <h6 className={classes.subHead}>
                    Politically Exposed Persons<small> (PEPs)</small>
                  </h6>
                </GridItem>
                <GridItem xs={12} sm={12}>
                  <Divider style={{ background: "black" }} fullWidth />
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  <Collapse
                    in={pepsTableData.length !== 0}
                    style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                    {...(true ? { timeout: 500 } : {})}>
                    <Table
                      tableHeaderColor="primary"
                      tableHead={[
                        "#",
                        "Legal Name",
                        "Name of Public Entity",
                        "Title/Position Held",
                        "Nature of Business",
                        "",
                      ]}
                      tableData={pepsTableData}
                    />
                    {!showAddPEP ? (
                      <>
                        <Button color="primary" size="lg" simple onClick={() => setShowAddPEP(true)}>
                          Add PEP
                        </Button>
                      </>
                    ) : null}
                  </Collapse>
                </GridItem>
                <Collapse
                  in={showAddPEP}
                  style={{ transformOrigin: "0 0 0", textAlign: "center", width: "100%", padding: "0px 15px" }}
                  {...(true ? { timeout: 500 } : {})}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        error={values.pepLegalNameErr}
                        labelText="Legal Name"
                        id="pep-legal-name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          autoComplete: "off",
                          value: pepLegalName,
                          onChange: (e) => {
                            setPepsLegalName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : pepLegalName);
                            verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : pepLegalName, "length", "pepLegalNameErr", 3);
                          },
                          onBlur: (e) => {
                            setPepsLegalName(e.target.value.trim());
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        error={values.pepPublicEntityErr}
                        labelText="Name of Public Entity"
                        id="pep-public-entity"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          autoComplete: "off",
                          value: pepPublicEntity,
                          onChange: (e) => {
                            setPepsPublicEntity(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : pepPublicEntity);
                            verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : pepPublicEntity, "length", "pepPublicEntityErr", 3);
                          },
                          onBlur: (e) => {
                            setPepsPublicEntity(e.target.value.trim());
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        error={values.pepPositionErr}
                        labelText="Title / Position Held"
                        id="pep-position"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          autoComplete: "off",
                          value: pepPosition,
                          onChange: (e) => {
                            setPepsPosition(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : pepPosition);
                            verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : pepPosition, "length", "pepPositionErr", 3);
                          },
                          onBlur: (e) => {
                            setPepsPosition(e.target.value.trim());
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        error={values.pepBusinessNatureErr}
                        labelText="Nature of Business"
                        id="pep-position"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          autoComplete: "off",
                          value: pepBusinessNature,
                          onChange: (e) => {
                            setPepsBusinessNature(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : pepBusinessNature);
                            verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : pepBusinessNature, "length", "pepBusinessNatureErr", 3);
                          },
                          onBlur: (e) => {
                            setPepsBusinessNature(e.target.value.trim());
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      {pepsTableData.length !== 0 ? (
                        <>
                          <Button
                            color="primary"
                            className={classes.addOwnerActions}
                            size="sm"
                            simple
                            onClick={cancelAddingPEP}>
                            Cancel
                          </Button>
                        </>
                      ) : null}

                      <Button color="primary" className={classes.addOwnerActions} size="sm" onClick={addPEP}>
                        Add PEP
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Collapse>
                <Collapse
                  in={showEditPEP}
                  style={{ transformOrigin: "0 0 0", textAlign: "center", width: "100%", padding: "0px 15px" }}
                  {...(true ? { timeout: 500 } : {})}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        error={values.pepLegalNameErr}
                        labelText="Legal Name"
                        id="pep-legal-name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          autoComplete: "off",
                          value: pepLegalName,
                          onChange: (e) => {
                            setPepsLegalName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : pepLegalName);
                            verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : pepLegalName, "length", "pepLegalNameErr", 3);
                          },
                          onBlur: (e) => {
                            setPepsLegalName(e.target.value.trim());
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        error={values.pepPublicEntityErr}
                        labelText="Name of Public Entity"
                        id="pep-public-entity"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          autoComplete: "off",
                          value: pepPublicEntity,
                          onChange: (e) => {
                            setPepsPublicEntity(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : pepPublicEntity);
                            verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : pepPublicEntity, "length", "pepPublicEntityErr", 3);
                          },
                          onBlur: (e) => {
                            setPepsPublicEntity(e.target.value.trim());
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        error={values.pepPositionErr}
                        labelText="Title / Position Held"
                        id="pep-position"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          autoComplete: "off",
                          value: pepPosition,
                          onChange: (e) => {
                            setPepsPosition(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : pepPosition);
                            verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : pepPosition, "length", "pepPositionErr", 3);
                          },
                          onBlur: (e) => {
                            setPepsPosition(e.target.value.trim());
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        error={values.pepBusinessNatureErr}
                        labelText="Nature of Business"
                        id="pep-position"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          autoComplete: "off",
                          value: pepBusinessNature,
                          onChange: (e) => {
                            setPepsBusinessNature(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : pepBusinessNature);
                            verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : pepBusinessNature, "length", "pepBusinessNatureErr", 3);
                          },
                          onBlur: (e) => {
                            setPepsBusinessNature(e.target.value.trim());
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      {pepsTableData.length !== 0 ? (
                        <>
                          <Button
                            color="primary"
                            className={classes.addOwnerActions}
                            size="sm"
                            simple
                            onClick={cancelAddingPEP}>


                            Cancel
                          </Button>
                        </>
                      ) : null}

                      <Button color="primary" className={classes.addOwnerActions} size="sm" onClick ={()=>saveEditPEP(pepEditID)}>
                       Save PEP
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Collapse>
              </GridContainer>
            </Collapse>
            <GridItem xs={12} sm={12}>
              <Divider style={{ marginTop: "25px", marginBottom: "10px" }} fullWidth />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <Button color="primary" className={classes.backButton} onClick={() => {
                if (props.edit) {
                  props.previousStep()
                } else if (props.cacheEdit) {
                  props.previousStep(true, companyID)
                }
                else {
                  goBack()
                }
              }
              }>
                Back
              </Button>

              <Button color="primary" className={classes.addOwnerActions} onClick={submitData}>
                Save & Continue
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.processModal,
        }}
        open={submitting}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
          <h4 className={classes.modelTag}> {submitLabel}</h4>
        </DialogContent>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          // paper: classes.processModal,
        }}
        open={openPDFeViewer}
        TransitionComponent={Transition}
        onClose={() => {
          setOpenPDFViewer(false);
          setDocumentViewerData("");
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <Document
            file={documentViewerData}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <p>Page {pageNumber} of {numPages}</p>
          <Button size="sm" color="primary" onClick={() => {
            if (pageNumber < 2) {
              setPageNumber(1)
            } else {
              let newPageNumber = pageNumber - 1
              setPageNumber(newPageNumber)
            }
          }} >Pervious</Button>
          <Button size="sm" color="primary"
            onClick={() => {
              if (numPages > pageNumber) {
                let newPageNumber = pageNumber + 1
                setPageNumber(newPageNumber)
              } else {
                setPageNumber(numPages)
              }
            }}
          >Next</Button>
        </DialogContent>
      </Dialog>

      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.companyLogModal,
        }}
        open={showEditOwner}
        TransitionComponent={Transition}
        onClose={() => {
          setShowEditOwner(false);
          cancelAddingOwner()
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={9}>
              <CustomInput
                error={values.ownerNameErr}
                labelText={
                  <span>
                    Owner Name <small className={classes.required}>*</small>
                  </span>
                }
                id="owner-name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: ownerName,
                  onChange: (e) => {
                    setOwnerName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : ownerName);
                    verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : ownerName, "length", "ownerNameErr", 3);
                  },
                  onBlur: (e) => {
                    setOwnerName(e.target.value.trim());
                  }
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Autocomplete
                className={classes.autocomplete}
                options={countries}
                value={ownerNationality}
                getOptionLabel={(option) => option.country}
                fullWidth
                onChange={(value, item) => {
                  if (item !== null) {
                    setOwnerNationality(item);
                    verify(item.ISO, "length", "ownerNationalityErr", 1);
                  }

                }}
                renderInput={(params) => <TextField fullWidth {...params} error={values.ownerNationalityErr}
                  label={
                    <span>
                      Nationality <small className={classes.required}>*</small>
                    </span>
                  }

                />}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Autocomplete
                className={classes.autocomplete}
                options={countries}
                value={ownerCountryResidence}
                getOptionLabel={(option) => option.country}
                fullWidth
                onChange={(value, item) => {
                  if (item !== null) {
                    setOwnerCountryResidence(item);
                    verify(item.country, "length", "ownerCountryResidenceErr", 3);
                  }

                }}
                renderInput={(params) => <TextField fullWidth {...params} error={values.ownerCountryResidenceErr}
                  label={
                    <span>
                      Country of Residence <small className={classes.required}>*</small>
                    </span>
                  }
                />}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <CustomInput
                error={values.ownerSharePercentageErr}
                labelText="Percentage of Share"
                id="owner-percentage"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: isNaN(ownerSharePercentage) ? "" : ownerSharePercentage,
                  onChange: (e) => {
                    if (e.target.value <= 100) {
                      if (e.target.value === "" || !isNaN(e.target.value)) {
                        setOwnerSharePercentage(e.target.value);
                        setValues({ ...values, ownerSharePercentageErr: false });
                      }
                    }
                  },
                  onBlur: (e) => {
                    const re = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
                    if (e.target.value < 20 && e.target.value !== "") {
                      setOwnerSharePercentage("");
                      openSnackBar("Owner should have minimum 20% of share.")
                    } else {
                      if (re.test(e.target.value)) {
                        setOwnerSharePercentage(parseFloat(e.target.value.trim()).toFixed(2));
                      } else {
                        setOwnerSharePercentage("");
                        openSnackBar("Owner should have minimum 20% of share.")
                      }

                    }
                  },
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <CustomInput
                error={values.ownerCPRErr}
                labelText={
                  <span>
                    CPR <small className={classes.required}>*</small>
                  </span>
                }
                id="ownerCPR"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: ownerCPR,
                  disabled: props.edit,
                  onChange: (e) => {
                    if (e.target.value.length <= 9) {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        if (verifyLength(e.target.value, 9)) {
                          setOwnerCPR(e.target.value);
                          setValues({ ...values, ownerCPRErr: false });
                        } else {
                          setOwnerCPR(e.target.value);
                          setValues({ ...values, ownerCPRErr: true });
                        }
                      }
                    }
                  },
                  onBlur: (e) => {
                    setOwnerCPR(e.target.value.trim());
                    findExistingCPR(e.target.value, "owner")
                  },
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              {ownerIDFileName !== "" ? (
                <CustomInput
                  labelText="ID Proof"
                  id="dir-id-proof"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    readOnly: true,
                    value: ownerIDFileName,
                  }}
                />
              ) : (
                <>
                  <br /> <br />
                  <Dropzone onDrop={ownerIDDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button size="sm" fullWidth color={ownerUpButtonColor}>
                          ID Proof
                        </Button>
                      </div>
                    )}
                  </Dropzone>
                </>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
              <br />  <br />
              <Tooltip placement="right" title="Delete ID">
                <IconButton
                  onClick={() => deleteID()}
                  style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                  aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                color="primary"
                className={classes.addOwnerActions}
                size="sm"
                simple
                onClick={cancelEditOwner}>
                Cancel
              </Button>
              <Button color="primary" className={classes.addOwnerActions} size="sm" onClick={() => { saveEditOwner(ownerEditID) }}>
                Save
              </Button>
            </GridItem>
          </GridContainer>

        </DialogContent>
      </Dialog>


      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.companyLogModal,
        }}
        open={showEditDirector}
        TransitionComponent={Transition}
        onClose={() => {
          setShowEditDirector(false);
          cancelAddingDirector()
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={9}>
              <CustomInput
                error={values.dirNameErr}
                labelText={
                  <span>
                    Name <small className={classes.required}>*</small>
                  </span>
                }
                id="dir-name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: dirName,
                  onChange: (e) => {
                    setDirName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : dirName);
                    verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : dirName, "length", "dirNameErr", 3);
                  },
                  onBlur: (e) => {
                    setDirName(e.target.value.trim());
                  }
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Autocomplete
                className={classes.autocomplete}
                options={countries}
                value={dirNationality}
                getOptionLabel={(option) => option.country}
                fullWidth
                onChange={(value, item) => {
                  if (item !== null) {
                    setDirNationality(item);
                    verify(item.country, "length", "dirNationalityErr", 3);
                  }
                }}
                renderInput={(params) => <TextField fullWidth {...params} error={values.dirNationalityErr}
                  label={
                    <span>
                      Nationality<small className={classes.required}>*</small>
                    </span>
                  }
                />}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Autocomplete
                className={classes.autocomplete}
                options={countries}
                value={dirCountryResidence}
                getOptionLabel={(option) => option.country}
                fullWidth
                onChange={(value, item) => {
                  if (item !== null) {
                    setDirCountryResidence(item);
                    verify(item.country, "length", "dirCountryResidenceErr", 3);
                  }
                }}
                renderInput={(params) => <TextField fullWidth {...params} error={values.dirNationalityErr}
                  label={
                    <span>
                      Country of Residence<small className={classes.required}>*</small>
                    </span>
                  }
                />}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                error={values.dirCPRErr}
                labelText={
                  <span>
                    CPR <small className={classes.required}>*</small>
                  </span>
                }
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: dirCPR,
                  disabled: props.edit,
                  onChange: (e) => {
                    if (e.target.value.length <= 9) {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        if (verifyLength(e.target.value, 9)) {
                          setDirCPR(e.target.value);
                          setValues({ ...values, dirCPRErr: false });
                        } else {
                          setDirCPR(e.target.value);
                          setValues({ ...values, dirCPRErr: true });
                        }
                      }
                    }
                  },
                  onBlur: (e) => {
                    findExistingCPR(e.target.value, "director")

                  },
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              {dirIDProofFileName !== "" ? (
                <CustomInput
                  labelText="ID Proof"
                  id="dir-id-proof"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    readOnly: true,
                    value: dirIDProofFileName,
                  }}
                />
              ) : (
                <>
                  <br /> <br />
                  <Dropzone onDrop={dirIDDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button size="sm" fullWidth color={dirUpButtonColor}>
                          ID Proof
                        </Button>
                      </div>
                    )}
                  </Dropzone>
                </>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
              <br />  <br />
              <Tooltip placement="right" title="Delete ID">
                <IconButton
                  onClick={() => deleteID()}
                  style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                  aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>

              <Button
                color="primary"
                className={classes.addOwnerActions}
                size="sm"
                simple
                onClick={() => {
                  setShowEditDirector(false)
                  cancelAddingDirector()
                }}>
                Cancel
              </Button>

              <Button color="primary" className={classes.addOwnerActions} size="sm" onClick={() => saveEditDirector(dirEditID)}>
                save
              </Button>
            </GridItem>
          </GridContainer>

        </DialogContent>
      </Dialog>

      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
          }}
          severity="error">
          {snackBarMsg}
        </Alert>
      </Snackbar>
      {
        openImageViewer && (
          <Lightbox
            mainSrc={documentViewerData}
            onCloseRequest={() => setOpenImageViewer(false)}
          />
        )
      }
    </div >
  );
}
