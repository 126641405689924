import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";
import axios from "axios";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch } from "react-redux";
import { reset } from "redux/actions/user.js";
import config from "config.js"
import libs from "controllers/libs";
import fetch from 'node-fetch';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Snackbar from "@material-ui/core/Snackbar";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
// import Notifications from "@material-ui/icons/Notifications";
// import Dashboard from "@material-ui/icons/Dashboard";
// import Search from "@material-ui/icons/Search";
import ControlledSwitches from "./Components/SwitchLogin";
import Close from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import PasswordInput from "components/PasswordInput/PasswordInput.js";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import RefreshIcon from '@mui/icons-material/Refresh';

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function AdminNavbarLinks() {
  const user = useSelector(state => state.user);
  const email = useSelector((state) => state.user.email);
  let holdTimer = false
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [openNotification, setOpenNotification] = React.useState(null);
  const [respAlert, setRespAlert] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const [classicModal, setClassicModal] = React.useState(false);
  const [OTP, setOTP] = React.useState("");
  const [otpERR, setOtpErr] = React.useState(false);
  const [otpValidated, setOptValidated] = React.useState(false);
  const [enableSave, setEnableSave] = React.useState(false);
  const [passStrength, setPassStrength] = React.useState(1);
  const [newPassword, setNewPassword] = React.useState("");
  const [newConfPassword, setNewConfPassword] = React.useState("");
  const [otpTag, setOtpTag] = React.useState("Sending One Time Password.");
  const [otpMail, setOtpMail] = React.useState("");
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);

  const [timer, setTimer] = React.useState('00:00:00');

  const [values, setValues] = React.useState({
    passwordErr: false,
    cPasswordErr: false,
  });

  const hideAlert = () => {
    setRespAlert(null);
  };
  function openSnackBar(msg) {
    //function to tost snackbar message
    setOpenSnack(true);
    setSnackBarMsg(msg);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  }

  // const handleClickNotification = (event) => {
  // if (openNotification && openNotification.contains(event.target)) {
  // setOpenNotification(null);
  // } else {
  // setOpenNotification(event.currentTarget);
  // }
  // };
  // const handleCloseNotification = () => {
  // setOpenNotification(null);
  // };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }

  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const handleLogout = () => {
    //function to handle logout process
    dispatch(reset(""));
    handleCloseProfile();
    sessionStorage.removeItem("cx_id");
    if (user.admin) {
      history.push("/login-admin");
    } else {
      history.push("/");
    }
  };
  const handleOTP = (otp) => {
    //function to handle otp changes
    const re = /^[0-9\b]+$/;
    if (otp === "" || re.test(otp)) {
      setOTP(otp);
    }

    if (otp.length === 6) {
      validateOTP(otp);
    }
  };

  function passWordValidation(pwd) {
    if (pwd.length === 0) {
      setPassStrength(1);
      return false;
    }
    var prog = [/[$@$!%*#?&]/, /[A-Z]/, /[0-9]/, /[a-z]/].reduce((memo, test) => memo + test.test(pwd), 0);
    if (prog > 2 && pwd.length > 5) {
      prog++;
    }
    switch (prog) {
      case 0:
        setPassStrength(10);
        break;
      case 1:
        setPassStrength(15);
        break;
      case 2:
        setPassStrength(25);
        break;
      case 3:
        setPassStrength(35);
        break;
      case 4:
        setPassStrength(45);
        break;
      case 5:
        setPassStrength(60);
        break;
      default:
        setPassStrength(0);
        break;
    }
  }

  const handlePassword = (password) => {
    //function to validate password
    passWordValidation(password);
    if (newConfPassword === password) {
      if (password !== "") {
        setEnableSave(true);
      } else {
        setEnableSave(false);
      }
      setNewPassword(password);
      setValues({ ...values, passwordErr: false, cPasswordErr: false });
    } else {
      setEnableSave(false);
      setValues({ ...values, passwordErr: false, cPasswordErr: true });
      setNewPassword(password);
    }
  };

  const handleCPassword = (cPassword) => {
    //function to check confirm password is same
    if (newPassword === cPassword) {
      setValues({ ...values, cPasswordErr: false });
      setNewConfPassword(cPassword);
      if (newPassword !== "") {
        setEnableSave(true);
      } else {
        setEnableSave(false);
      }
    } else {
      setEnableSave(false);
      setNewConfPassword({ ...values, cPasswordErr: true });
    }
  };

  const closePasswordModal = () => {
    //function to close change password modal
    setClassicModal(false);
    setOptValidated(false);
    setNewConfPassword("");
    setNewPassword("");
    setEnableSave(false);
    setOTP("");
  };

  const handleChangePassword = () => {
    setClassicModal(true);
    handleCloseProfile();
    let url = ""
    if (user.admin) {
      url = config.url().baseURL + "/password-change"
    } else {
      url = config.url().baseURL + "/system-user-password-change"
    }
    axios
      .post(
        url,
        {},
        {
          headers: {
            auth: sessionStorage.getItem("cx_id"),
          },
        }
      )
      .then((resp) => {
        let respData = resp.data;
        console.log(respData);
        if (!respData.err) {
          setOtpTag("Otp has been sent to");
          setOtpMail(email);
        } else {
          openSnackBar(respData.msg);
          setClassicModal(false);
          setOTP("");
        }
      })
      .catch((err) => {
        openSnackBar("Unable to process.");
      });
  };

  const validateOTP = (otp) => {
    //function to validate otp
    let url = ""
    if (user.admin) {
      url = config.url().baseURL + "/otp-verify"
    } else {
      url = config.url().baseURL + "/system-user-otp-verify"
    }
    axios
      .post(
        url,
        { otp: otp },
        {
          headers: {
            auth: sessionStorage.getItem("cx_id"),
          },
        }
      )
      .then((resp) => {
        let respData = resp.data;
        console.log(respData);
        if (!respData.err) {
          setOptValidated(true);
        }
      })
      .catch((err) => { });
  };

  const successChange = () => {
    hideAlert();
    setTimeout(function () {
      sessionStorage.removeItem("cx_id")
      if (user.admin) {
        history.push("/login-admin");
      } else {
        history.push("/");
      }
    }, 700);
  };

  const savePassword = () => {
    //function to save password
    let url = ""
    if (user.admin) {
      url = "/new-password"
    } else {
      url = config.url().baseURL + "/system-user-new-password"
    }
    if (OTP.length == 6) {
      if (passStrength === 60) {
        axios
          .post(
            url,
            { password: newPassword, otp: OTP },
            {
              headers: {
                auth: sessionStorage.getItem("cx_id"),
              },
            }
          )
          .then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
              setClassicModal(false);
              setRespAlert(
                <SweetAlert
                  success
                  style={{ display: "block" }}
                  title="Password change successful."
                  onConfirm={() => successChange()}
                  confirmBtnCssClass={classes.button + " " + classes.success}
                />
              );
            } else {
              setClassicModal(false);
              openSnackBar(respData.msg);
            }
          })
          .catch((err) => { });
      } else {
        openSnackBar("Password should contain one digit one special character one small letter one capital letter and 6 characters.")
      }
    } else {
      setOtpErr(true)
      openSnackBar("Otp is not valid")
    }
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(new Date(e)) - Date.parse(new Date());
    if (total == 0) {
      clearSession()
      history.push("/");
    } else {
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / 1000 * 60 * 60) % 24);
      return {
        total, hours, minutes, seconds
      };
    }
  }

  const startTimer = (e) => {
    try {
      let { total, hours, minutes, seconds } = getTimeRemaining(e);
      if (total >= 0) {
        let time =

          (hours > 9 ? hours : '0' + hours) + ':' +
          (minutes > 9 ? minutes : '0' + minutes) + ':'
          + (seconds > 9 ? seconds : '0' + seconds)
        if (minutes < 2) {
          if (minutes < 1) {
            setTimer(<span style={{ color: "red" }}> Your session will be expired in {time} </span>)
          } else setTimer(<span style={{ color: "red" }}> Your session will be expired in {time}</span>)
        }
        else setTimer(`Your session will be expired in ${time}`)

      }
    } catch (error) {

      clearSession()
      history.push("/");

    }
  }


  const invokeTimer = () => {
    setInterval(() => {
      if (!holdTimer) startTimer(sessionStorage.getItem("cx_id_time"));
    }, 1000)
  }

  React.useEffect(async () => {
    invokeTimer()
  }, []);

  const refreshToken = async () => {
    try {
      holdTimer = true
      // let fetchResp = await fetch(`${config.url().baseURL}/refreshToken`, {
      //   method: 'get',
      //   headers: { 'refresh_token': sessionStorage.getItem('cx_refresh_id') }
      // })
      let fetchResp = await fetch(`${config.url().baseURL}/refreshToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ refresh_token: sessionStorage.getItem('cx_refresh_id') })
      });
      const respData = await fetchResp.json();
      await clearSession()
      if (respData.err) {
        await clearSession()
        history.push("/");
      }
      await setSession(respData.data.AuthToken, respData.data.RefreshToken, respData.data.Time)
      holdTimer = false
    } catch (error) {

      await clearSession()
      history.push("/");

    }
  }

  const clearSession = async () => {
    //function to clear session
    sessionStorage.removeItem("cx_id");
    sessionStorage.removeItem("cx_refresh_id");
    sessionStorage.removeItem("cx_id_time");
  }

  const setSession = async (token, refreshToken, time) => {
    sessionStorage.setItem("cx_id", token);
    sessionStorage.setItem("cx_refresh_id", refreshToken);
    sessionStorage.setItem("cx_id_time", time);
  }

  return (
    <div>
      {respAlert}
      <span>
        {timer}
      </span>
      <Button
        onClick={refreshToken}
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Dashboard"
        className={classes.buttonLink}>
        <RefreshIcon className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Dashboard</p>
        </Hidden>
      </Button>
      {!user.admin ?
        (<div className={classes.manager}>
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={openProfile ? "profile-menu-list-grow" : null}
            aria-haspopup="true"
            onClick={handleClickProfile}
            className={classes.buttonLink}>
            <Person className={classes.icons} />
            <Hidden mdUp implementation="css">
              <p className={classes.linkText}>Profile</p>
            </Hidden>
          </Button>
          <Poppers
            open={Boolean(openProfile)}
            anchorEl={openProfile}
            transition
            disablePortal
            className={classNames({ [classes.popperClose]: !openProfile }) + " " + classes.popperNav}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list-grow"
                style={{
                  transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseProfile}>
                    <MenuList role="menu">
                      <MenuItem onClick={() => handleChangePassword()} className={classes.dropdownItem}>
                        Change Password
                      </MenuItem>
                      <Divider light />
                      <MenuItem onClick={() => handleLogout()} className={classes.dropdownItem}>
                        Logout
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
          <span>
            {user?.firstName}
          </span>
        </div>) : (
          <div className={classes.manager}>
            <Button
              color={window.innerWidth > 959 ? "transparent" : "white"}
              justIcon={window.innerWidth > 959}
              simple={!(window.innerWidth > 959)}
              aria-owns={openProfile ? "profile-menu-list-grow" : null}
              aria-haspopup="true"
              onClick={handleClickProfile}
              className={classes.buttonLink}>
              <Person className={classes.icons} />
              <Hidden mdUp implementation="css">
                <p className={classes.linkText}>Profile</p>
              </Hidden>
            </Button>
            <Poppers
              open={Boolean(openProfile)}
              anchorEl={openProfile}
              transition
              disablePortal
              className={classNames({ [classes.popperClose]: !openProfile }) + " " + classes.popperNav}>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="profile-menu-list-grow"
                  style={{
                    transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                  }}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseProfile}>
                      <MenuList role="menu">
                        <MenuItem id="password" onClick={() => handleChangePassword()} className={classes.dropdownItem}>
                          Change Password
                        </MenuItem>
                        <Divider light />
                        <MenuItem id="otp" className={classes.dropdownItem}> Login with OTP {<ControlledSwitches />}</MenuItem>
                        <Divider light />
                        <MenuItem id="logout" onClick={() => handleLogout()} className={classes.dropdownItem}>
                          Logout
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Poppers>
            <span>
              {user?.firstName}
            </span>
          </div>)}
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
            setSnackBarMsg("");
          }}
          severity="error">
          {snackBarMsg}
        </Alert>
      </Snackbar>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={classicModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => closePasswordModal()}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setClassicModal(false)}>
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Change Password</h4>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <Collapse className={classes.passContainer} in={true}>
            <CustomInput
              labelText={<span>OTP</span>}
              id="tr-road"
              formControlProps={{
                fullWidth: true,
              }}
              error={otpERR}
              inputProps={{
                type: "password",
                autoComplete: "off",
                value: OTP,
                onChange: (e) => {
                  setOTP(libs.validate(e.target.value, "number", 6) ? e.target.value : OTP);
                  setOtpErr(false)
                },
                onBlur: (e) => {
                  setOTP(e.target.value.trim());

                }
              }}
            />
            <PasswordInput
              className={classes.input}
              labelText="Password"
              id="pass"
              passStrength={passStrength}
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: (e) => handlePassword(e.target.value),
                type: "password",
                autoComplete: "off",
              }}
            />
            <CustomInput
              labelText="Confirm Password"
              id="cPass"
              className={classes.input}
              error={values.cPasswordErr}
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: (e) => handleCPassword(e.target.value),
                type: "password",
                autoComplete: "off",
              }}
            />
          </Collapse>
          {/* {otpValidated ? null : (
<>
<p className={classes.otpTag}>
{otpTag} <span className={classes.otpMail}>{otpMail}</span>
</p>
<div className={classes.otpContainer}>
<OtpInput
value={OTP}
onChange={handleOTP}
focusStyle={classes.focusOtp}
containerStyle={classes.containerOtp}
numInputs={6}
inputStyle={classes.otpClass}
isInputNum={true}
separator={<span>-</span>}
/>
</div>
<p className={classes.otpResend} onClick={() => handleChangePassword()} >Resend OTP</p>
</>
)} */}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Collapse in={enableSave}>
            <Button onClick={() => savePassword()} size="sm" color="primary">
              Save
            </Button>
          </Collapse>

          <Button onClick={() => closePasswordModal()} color="danger" size="sm">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

