import * as React from 'react'
import axios from 'axios'
import { useState } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Collapse from '@material-ui/core/Collapse'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PropagateLoader from 'react-spinners/PropagateLoader'
import MUIDataTable from 'mui-datatables'
import { InputBase } from '@material-ui/core'
import moment from 'moment'
import { Paper } from '@material-ui/core'
import Snackbar from "@material-ui/core/Snackbar";
import BounceLoader from 'react-spinners/BounceLoader'
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import Divider from '@mui/material/Divider';
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import SearchIcon from '@material-ui/icons/Search';
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import { creatCsvFile, downloadFile } from "download-csv";
import MuiAlert from "@material-ui/lab/Alert";
import config from "config.js"
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from '@material-ui/icons/Replay';
import SweetAlert from "react-bootstrap-sweetalert";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import JSONPretty from 'react-json-pretty'

const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    companyStatusLogModal12: {
        margin: "auto",
        minWidth: "800px",
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


export default function CardReplaceAndRetry() {
    const classes = useStyles();
    const [showLoader, setShowLoader] = useState(false);
    const [showCardReplacedTable, setShowCardReplacedTable] = useState(true)
    const [fromDate, setFromDate] = useState(moment(new Date).format("YYYY-MM-DD"))
    const [toDate, setToDate] = useState(moment(new Date).format("YYYY-MM-DD"))
    const [openSnack, setOpenSnack] = useState(false)
    const [snackBarMsg, setSnackBarMsg] = useState("")
    const [snackBarType, setSnackBarType] = useState("error")
    const [csvData, setCsvData] = React.useState(false);
    const [respAlert, setRespAlert] = React.useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [cardReplaceDetails, setCardReplaceDetails] = useState([])
    const [pageValue, setPageValue] = React.useState(0);
    const [remainingDocs, setRemainingDOcs] = useState(0);
    const [cardLog, setCardLog] = useState(false)
    const [m2pBlockRequest, setM2PBlockRequest] = useState("");
    const [m2pBlockResponse, setM2PBlockResponse] = useState("");
    const [m2pReplaceRequest, setM2PReplaceRequest] = useState("")
    const [m2pReplaceRespons, setM2PReplaceReasons] = useState("");

    const logColumns = [
        { id: 'date', label: 'Date', width: 70 },
        { id: 'time', label: 'Time', width: 70 },
        { id: 'user', label: 'User' },
        { id: 'status', label: 'Status', width: 70 },
        { id: 'status_message', label: 'Status Info', minWidth: 170 },
        { id: 'remarks', label: 'Remarks', minWidth: 170 },
    ];
    const columns = [
        {
            name: "date",
            label: "Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "cpr",
            label: "CPR",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "oldKitNumber",
            label: "Old Kit No",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "replaced_card_reason",
            label: "Replaced Card Reason",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "newKitNumber",
            label: "New Kit No",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "replaced_status",
            label: "Replaced Card Status",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false
            }
        }

    ]
    const options = {
        selectableRows: false,
        elevation: 0,
        print: false,
        pagination: false,
        onDownload: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = " Multi Currency Replaced Card Report.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        }
    };
    React.useEffect(() => {
        searchCardReplacedDetails();
    }, [pageValue])

    const nextTransaction = () => {
        setPageValue(pageValue => pageValue + 1);
    }

    const previousTransaction = () => {
        setPageValue(pageValue => pageValue - 1);
    }
    const validateSearch = () => {
        //function validate search params
        let snackBarMsg = ""
        let flag = true
        if (fromDate.length == 0) {
            flag = false
            snackBarMsg = "Please select from date."
        }
        if (toDate.length == 0) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select To date." : snackBarMsg);
        }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }
    const searchCardReplacedDetails = () => {
        try {
            if (validateSearch()) {
                let data = {
                    "from": moment(fromDate).format("MM/DD/YYYY"),
                    "to": moment(toDate).format("MM/DD/YYYY"),
                }
                setShowLoader(true);
                setSubmitting(true);
                setShowCardReplacedTable(false);
                setCardReplaceDetails([])
                axios.post(`${config.url().baseURL}/get-replace-card-requests/${pageValue}`, data)
                    .then((resp) => {
                        setShowLoader(false);
                        setShowCardReplacedTable(true)
                        let resData = resp.data
                        if (resData.err) {
                            setSubmitting(false);
                            openSnackBar(resData.msg)
                        }
                        else {
                            setSubmitting(false);
                            let updatedDetails = []
                            if (resData && resData.data && resData.data.replaceCardList.length != 0) {
                                resData.data.replaceCardList.map((activations) => {
                                    if (activations.status != "ALLOCATED_NEW_CARD") {
                                        activations.actions =
                                            <>  <Tooltip placement="top" title="Retry Register Card">
                                                <IconButton
                                                    onClick={() => confirmRetryRegistration(activations.cpr)}
                                                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                    aria-label="log">
                                                    <ReplayIcon />
                                                </IconButton>
                                            </Tooltip>
                                                <Tooltip placement="top" title="View Log">
                                                    <IconButton
                                                        onClick={() => {
                                                            setCardLog(true);
                                                            setM2PBlockRequest(activations.m2pBlockRequest ? activations.m2pBlockRequest : "No Log Found")
                                                            setM2PBlockResponse(activations.m2pBlockResponse ? activations.m2pBlockResponse : "No Log Found")
                                                            setM2PReplaceRequest(activations.m2pReplaceRequest ? activations.m2pReplaceRequest : "No Log Found")
                                                            setM2PReplaceReasons(activations.m2pReplaceResponse ? activations.m2pReplaceResponse : "No Log Found")
                                                        }}
                                                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                    >
                                                        <AssignmentIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                    } else {
                                        activations.actions =
                                            <Tooltip placement="top" title="View Log">
                                                <IconButton
                                                    onClick={() => {
                                                        setCardLog(true);
                                                        setM2PBlockRequest(activations.m2pBlockRequest ? activations.m2pBlockRequest : "No Log Found")
                                                        setM2PBlockResponse(activations.m2pBlockResponse ? activations.m2pBlockResponse : "No Log Found")
                                                        setM2PReplaceRequest(activations.m2pReplaceRequest ? activations.m2pReplaceRequest : "No Log Found")
                                                        setM2PReplaceReasons(activations.m2pReplaceResponse ? activations.m2pReplaceResponse : "No Log Found")
                                                    }}
                                                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                >
                                                    <AssignmentIcon />
                                                </IconButton>
                                            </Tooltip>
                                    }
                                    activations.date = activations.createdAt ? moment(activations.createdAt).format("DD/MM/YYYY  hh:mm A") : ""
                                    activations.cpr = activations.entityId ? activations.entityId : ""
                                    activations.replaced_card_reason = activations.replaceReason ? activations.replaceReason : ""
                                    if (activations.status == "INITIATED") {
                                        activations.replaced_status = "REPLACEMENT INITIATED, NO REQUEST SENT"
                                    } else if (activations.status == "BLOCK_OLD_CARD_FAILED") {
                                        activations.replaced_status = "FAILED TO BLOCK CARD"
                                    } else if (activations.status == "BLOCK_OLD_CARD_SUCCESS") {
                                        activations.replaced_status = "FAILED TO REPLACE CARD"
                                    } else if (activations.status == "ALLOCATED_NEW_CARD") {
                                        activations.replaced_status = "SUCCESSFULLY REPLACED"
                                    }
                                    updatedDetails.push(activations)
                                })
                            }
                            setRemainingDOcs(resData.data.remainingRecords ? resData.data.remainingRecords : "")
                            setCardReplaceDetails(updatedDetails);
                            var downloadedData = resp.data.data.replaceCardList.map((activations) => {
                                let temp = {};
                                temp.Date = activations.date;
                                temp.CPR = activations.cpr;
                                temp.Old_Kit_No = activations.oldKitNumber;
                                temp.New_kit_No = activations.newKitNumber;
                                temp.Replaced_Card_reason = activations.replaced_card_reason;
                                temp.Replaced_Status = activations.replaced_status;
                                return temp;
                            })
                            setCsvData(downloadedData);
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        openSnackBar("Unable to process")
                    })
            }
        }
        catch (error) {
            openSnackBar("Unable to fetch the details")

        }
    }
    const confirmRetryRegistration = (id) => {
        setRespAlert(
            <SweetAlert
                warning
                style={{ display: "block" }}
                title="Are you sure?"
                onConfirm={() => {
                    setRespAlert(null);
                    retryRegistration(id)
                }}
                onCancel={() => {
                    setRespAlert(null)
                }}
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Do you really want to retry the registration ?
            </SweetAlert>)
    }
    const retryRegistration = (id) => {
        axios.post(`${config.url().baseURL}/retry-mc-card-replace`, { cpr: id })
            .then((resp) => {
                if (!resp.data.err) {
                    setRespAlert(
                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title="Retry initiated."
                            onConfirm={() => setRespAlert(null)}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                    );
                    searchCardReplacedDetails()
                }
                else {
                    openSnackBar(resp.data.msg)
                }
            }).catch((e) => {
                openSnackBar("Failed to retry the registration, Please Try again later")
            })
    }
    function openSnackBar(msg, success) {
        setOpenSnack(true)
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    return (
        <div>{respAlert}
            <GridContainer justify='center'>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={true}
                        style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                        {...true ? { timeout: 500 } : {}}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>From Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setFromDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setFromDate("");
                                                    openSnackBar("Please enter a valid Date")
                                                } else {
                                                    setFromDate(event.target.value)
                                                }
                                            },
                                            value: fromDate,
                                        }}>
                                    </InputBase>
                                </Paper>


                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>To Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setToDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setToDate("")
                                                    openSnackBar("Please enter valid date.")
                                                } else {
                                                    setToDate(event.target.value)
                                                }
                                            }, value: toDate,
                                        }}
                                    />
                                    {!submitting ? (
                                        <Button className={classes.addDeptButton}
                                            onClick={() => searchCardReplacedDetails()}
                                            size="lg" color="primary">
                                            <SearchIcon fontSize="medium" />
                                        </Button>
                                    ) : (
                                        <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
                                    )}
                                </Paper>
                            </GridItem>
                        </GridContainer>
                    </Collapse>

                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{
                            transformOrigin: "0 0 0", textAlign: 'center'
                        }}
                        {...true ? { timeout: 500 } : {}}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showCardReplacedTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...true ? { timeout: 500 } : {}}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}> Card Replace and Retry Status Report</h4>
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={cardReplaceDetails} columns={columns} options={options} />
                            </CardBody>
                        </Card>
                        <Button
                            disabled={pageValue <= 0 ? true : false}
                            onClick={() => previousTransaction()}
                            simple color="primary"
                            size="sm">
                            Previous
                        </Button>
                        <Button
                            disabled={remainingDocs <= 0 ? true : false}
                            onClick={() => nextTransaction()}
                            color="primary"
                            simple
                            size="sm">
                            Next
                        </Button>
                    </Collapse>
                </GridItem>
            </GridContainer>
            <Dialog
                classes={{
                    root: classes.left + " " + classes.modalRoot,
                    paper: classes.companyStatusLogModal12,
                }}
                open={cardLog}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setCardLog(false);
                    setM2PBlockRequest("");
                    setM2PBlockResponse("")
                    setM2PReplaceReasons("")
                    setM2PReplaceRequest("")
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>Replaced Card Log</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <h5>M2P Block Request</h5>
                    <JSONPretty data={m2pBlockRequest} />
                    <Divider/>
                    <h5>M2P Block Response</h5>
                    <JSONPretty data={m2pBlockResponse} />
                    <Divider/>
                    <h5>M2P Replace Request</h5>
                    <JSONPretty data={m2pReplaceRequest} />
                    <Divider/>
                    <h5>M2P Replace Response</h5>
                    <JSONPretty data={m2pReplaceRespons} />
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setCardLog(false);
                            setM2PBlockRequest("");
                            setM2PBlockResponse("")
                            setM2PReplaceReasons("")
                            setM2PReplaceRequest("")
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    )
} 