import React, { useState } from "react";

import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import { css } from "@emotion/core";
import Slide from "@material-ui/core/Slide";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import config from "config.js"
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiTable from '@material-ui/core/Table';
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";

const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        marginLeft: "50%",
        marginRight: "50%"
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    statusHead: {
        marginBottom: "0px !important",
    },
    statusText: {
        color: "#000 !important",
    },
    ...modalStyle(theme),
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function CampaignLogs(props) {
    const [log, setLog] = useState("")
    const [logView, setLogView] = useState(false);
    const classes = useStyles();


    React.useEffect(() => {
        fetchLog(props.ComapanyID)
    }, [props.ComapanyID])

    const logColumns = [
        { id: 'date', label: 'Date', width: 70 },
        { id: 'time', label: 'Time', width: 70 },
        { id: 'user', label: 'User' },
        { id: 'status', label: 'Status', width: 70 },
        { id: 'status_message', label: 'Status Message', width: 70 },
        { id: 'remarks', label: 'Remarks', minWidth: 170 },
    ];
    const fetchLog = (id) => {
        //function to fetch  logs
        setLogView(true);
        axios.post(`${config.url().baseURL}/get-logsView`, { "campaignid": id }).then((resp) => {
            let respData = resp.data;
            console.log(respData);
            if (!respData.err) {
                if (respData.data[0] !== undefined) {
                    setLog(respData.data);
                } else {
                    setLogView(false);
                    props.snack("No log found.");
                }

            } else {
                setLogView(false);
                props.snack(respData.msg);
            }
        });
    };
    return (
        <div>
            <Dialog
                classes={{
                    root: classes.left + " " + classes.modalRoot,
                    paper: classes.companyStatusLogModal,
                }}
                open={logView}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setLogView(false);
                    setLog([]);
                    props.back()

                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>Campaign Status Log</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <TableContainer className={classes.container}>
                        <MuiTable stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {logColumns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {log.length != 0 ? log.map((row, key) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                                            <TableCell>
                                                {moment(row.date).format("DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell>
                                                {moment(row.date).format("HH:mm:ss")}
                                            </TableCell>
                                            <TableCell>
                                                {row.user ? (row.user.firstName + " " + row.user.lastName) : ""}
                                            </TableCell>
                                            <TableCell>
                                                {row.status}
                                            </TableCell>
                                            <TableCell>
                                                {row.status_message}
                                            </TableCell>
                                            <TableCell>
                                                {row.remarks}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }) : ""}
                            </TableBody>
                        </MuiTable>
                    </TableContainer>
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setLogView(false);
                            setLog([]);
                            props.back()
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}