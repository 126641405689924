import * as React from 'react'
import axios from 'axios'
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import config from "config.js"
import Collapse from "@material-ui/core/Collapse";
import "react-awesome-lightbox/build/style.css";
import Table from "components/Table/Table.js";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import "./styles.css";
import Skeleton from '@material-ui/lab/Skeleton';
import Magnifier from "react-magnifier";
import { primaryColor } from "assets/jss/material-dashboard-react.js";
// import { Magnifier } from 'react-image-magnifiers';
import moment from 'moment';
import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux'; 
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.js";
import SweetAlert from "react-bootstrap-sweetalert";
import CardHeader from "components/Card/CardHeader.js";
import { Grid, TextField } from "@material-ui/core";
import Paper from '@mui/material/Paper';
import ImagesStyles from "assets/jss/material-dashboard-react/imagesStyles";
import DownloadIcon from '@mui/icons-material/Download';
import download from "js-file-download";
import Lightbox from "react-awesome-lightbox";




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const override = css`
display: block;
margin: 32px auto;
border-color: red;
`;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    ...ImagesStyles,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "center",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    corpTopHead: {
        fontSize: "15px",
        color: primaryColor[0],
        fontWeight: "400",
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    topBackButton: {
        float: "right"
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    detailsContainer: {
        padding: "0px",
        paddingTop: "-20px",
        marinTop: "-10px",
        maxHeight: '75vh',
        overflow: 'auto',
        '&::-webkit-scrollbar-track': {
            width: "8px",
            backgroundColor: "#F5F5F5"
        },
        '&::-webkit-scrollbar': {
            width: "8px",
            backgroundColor: "#F5F5F5"
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: " 6px",
            backgroundColor: primaryColor[0]
        }
    },
    corpDetailsTableHead: {
        marginLeft: "10px",
        marginTop: "20px",
        marginBottom: "-30px",
        fontSize: "13px",
        fontWeight: "500",
        textTransform: "none"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),
}));


export default function CampaignDetailsView(props) {
    const user = useSelector(state => state.user);
    const classes = useStyles();
    const [corpDetails, setCorpDetails] = React.useState([]);
    const [corpDocs, setCorpDocs] = React.useState([]);
    const [companyName, setCompanyName] = React.useState([]);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [showImage, setShowImage] = React.useState(false);
    const [numPages, setNumPages] = React.useState(null);
    const [respAlert, setRespAlert] = React.useState(null);
    const [showActionBtn, setShowActionBtn] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(true);
    const [startDate, setStartDate] = React.useState(new Date())
    const [endDate, setEndDate] = React.useState(new Date())
    const [approveBtn, setApproveBtn] = React.useState(null);
    const [rejectBtn, setRejectBtn] = React.useState(null);
    const [recheckBtn, setRecheckBtn] = React.useState(null);
    const [submitting, setSubmitting] = React.useState(false);
    const [status, setStatus] = React.useState("")
    const [remarks, setRemarks] = React.useState("")
    const [showRemarks, setShowRemarks] = React.useState(false);
    const [active, setActive] = React.useState(false)
    const [userDetails, setUserDetails] = React.useState({})
    const [firstName, setFirstName] = React.useState('');
    const [middleName, setMiddleName] = React.useState('')
    const [lastName, setLastName] = React.useState('');
    const [gender, setGender] = React.useState('');
    const [mobileNumber, setMobileNumber] = React.useState('');
    const [cpr, setCPR] = React.useState('');
    const [cprExpiry, setCPRExpiry] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [placeOfBirth, setPlaceOfBirth] = React.useState('');
    const [dateOfBirth, setDateOfBirth] = React.useState('');
    const [nationality, setNationality] = React.useState('');
    const [passportNumber, setPassportNumber] = React.useState('');
    const [passportExpiry, setPassportExpiry] = React.useState('');
    const [addressType, setAddressType] = React.useState('');
    const [flatNumber, setFlatNumber] = React.useState('');
    const [buildingNumber, setBuildingNumber] = React.useState('');
    const [roadNumber, setRoadNumber] = React.useState('');
    const [blockNumber, setBlockNumber] = React.useState('');
    const [preferredLanguage, setPreferredLanguage] = React.useState('');
    const [occupation, setOccupation] = React.useState('');
    const [validProofOfIdentification, setValidProofOfIdentification] = React.useState('CPR')



    const [shuftiData, setShuftiData] = React.useState([]);
    const [documentViewerData, setDocumentViewerData] = React.useState("");
    const [openImageViewer, setOpenImageViewer] = React.useState(false);
    const [loader, setLoader] = React.useState(true);

    const date = new Date(userDetails.createdAt);
    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')
        }/${date.getFullYear()}`;


    React.useEffect(() => {
        {
            fetchCompanyDetails(props.cpr)
        }
        checkUserType()
    }, [props.companyId])
    
    const CampaignDate = (value) => {
        setStartDate(moment(value[0]).format("MM-DD-YYYY"))
        setEndDate(moment(value[1]).format("MM-DD-YYYY"))
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const closeModal = () => {
        props.back()
        setShowRemarks(false);
    }


    const selectDocument = (doc) => {
        //function to set doc data to image viewer
        // setLoader(false);
        setOpenImageViewer(true)
        setDocumentViewerData(doc)
    }

    const closeDetails = () => {
        //function to go back
        setShuftiData([])
        props.back()
    }

    const downloadDoc = async (doc, name) => {
        let respData = await axios.get(doc, { responseType: "blob", })
        console.log(respData)
        // setLoader(false);
        download(respData.data, name)
    }


    const fetchCompanyDetails = (cpr) => {

        setShowLoader(true);

        axios.get(`${config.url().baseURL}/get-gpw-user-details/${cpr}`)
            .then((resp) => {
                console.log(resp);
                let respData = resp.data;
                if (!respData.err) {
                    setUserDetails(respData.data)
                    // userDetails=respData.data
                    if (respData.data.status === "CREATED" || respData.data.status === "UPDATED") {
                        setApproveBtn(<Button style={{ marginRight: "5px", marginLeft: "5px" }} color="primary" onClick={() => { setShowRemarks(true); setStatus("CHECKED") }}>Checked</Button>)
                        setRecheckBtn(<Button style={{ marginRight: "5px", marginLeft: "5px" }} color="danger" onClick={() => { setShowRemarks(true); setStatus("RECHECK") }}>Recheck</Button>)
                    }
                    setShowLoader(false);
                    checkUserType()

                }

            })
            .catch((err) => {
                console.log(err);
                setShowLoader(false);
                props.snack("Unable to process.");

            });

    };
    function openSnackBar(msg) {
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }
    const handleBack = () => {
        props.back()
        setFirstName("")
        setMiddleName("")
        setLastName("")
        setMobileNumber("")
        setCPR("")
        setCPRExpiry("")
        setEmail("")
        setPlaceOfBirth("")
        setDateOfBirth("")
        setNationality("")
        setPassportNumber("")
        setPassportExpiry("")
        setAddressType("")
        setFlatNumber("")
        setBuildingNumber("")
        setRoadNumber("")
        setBlockNumber("")
        setPreferredLanguage("")
        setOccupation("")
        setValidProofOfIdentification("")
    }

    const enableApproveStatus = () => {
        let data = {
            cpr: props.cpr,
            status,
            remarks: remarks
        }

        axios.post(`${config.url().baseURL}/change-onboarding-status`, data)
            .then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    setRespAlert(
                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title={respData.msg}
                            onConfirm={() => {
                                setRespAlert("");
                                setRemarks("")
                                closeModal()

                            }}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />

                    );

                } else {
                    openSnackBar(respData.msg);
                }
            });
    };
    const checkUserType = () => {

        if (user.services !== undefined && user.services !== null && user.services.length !== 0) {

            //change it when u add services
            let service = user.services.find(service => service.name == "Customers")
            if (service) {
                switch (service.type) {
                    case "maker":
                        return (
                            <div>
                                <Button onClick={handleBack} size="md" simple color="primary">
                                    <ArrowBackIcon />
                                    Back
                                </Button>
                            </div>
                        );
                        setShowActionBtn(false);
                        break;
                    case "checker":
                        setShowActionBtn(true);



                        break;
                    case "checkerTwo":

                        setShowActionBtn(false);
                        
                        break;
                    case "approver":

                        if (props.campaignStatus == "CHECKED") {
                            setShowActionBtn(true);
                            setApproveBtn(<Button style={{ marginRight: "5px", marginLeft: "5px" }} color="primary" onClick={() => { setShowRemarks(true); setStatus("APPROVED") }}>Approved</Button>)
                            setRejectBtn(<Button style={{ marginRight: "5px", marginLeft: "5px" }} color="danger" onClick>Reject</Button>)
                            setRecheckBtn(<Button style={{ marginRight: "5px", marginLeft: "5px" }} color="danger" onClick={() => { setShowRemarks(true); setStatus("RECHECK") }}>Recheck</Button>)
                        } else { setShowActionBtn(false) }
                        break;
                    case "viewer":
                        setShowActionBtn(false);

                        break;

                    default:
                        setShowActionBtn(false);
                        break;
                }
            }
        }

    }

    const backToHome = () => {
        setSubmitting(false);
        props.back()
    }

    const renderImage = (url) => {
        if (url) {
            return (
                <>
                    <Paper style={{ textAlign: "center", padding: "5px" }}>
                        <img
                            src={url}
                            alt="..."
                            className={`${classes.imgRounded} ${classes.imgFluid} ${classes.customLink}`}
                        />
                    </Paper>
                    <br />
                </>
            );
        }
        return null;
    };

    return (
        <>
            {respAlert}
            <Card>
                <CardHeader color="primary" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    GPW USER
                </CardHeader>
                <CardBody>
                    <Dialog
                        classes={{
                            root: classes.left + " " + classes.modalRoot,
                            paper: classes.modalMedium,
                        }}
                        open={showRemarks}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => {
                            setShowRemarks(false);
                            setRemarks("");

                        }}
                        aria-labelledby="classic-modal-slide-title"
                        aria-describedby="classic-modal-slide-description">
                        <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                            <h4 className={classes.modalTitle}>Please enter remarks</h4>
                        </DialogTitle>
                        <DialogContent id="classic-modal-slide-description" className={classes.modalBodyRemarks}>
                            <CustomInput
                                labelText="Remarks"
                                id="remarks"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    value: remarks,
                                    onChange: (e) => {
                                        setRemarks(e.target.value);
                                        // setRemarksErr(false);
                                    },
                                }}
                            />

                        </DialogContent>
                        <DialogActions className={classes.modalFooterCenter}>
                            <Button
                                onClick={() => {
                                    setShowRemarks(false);
                                    setRemarks("");
                                }}
                                simple
                                color="danger"
                                size="md">
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    enableApproveStatus()
                                }}
                                color="primary"
                                size="md">
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <div style={{ margin: "10px", textAlign: "center" }}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <Collapse in={true} style={{ transformOrigin: "0 0 0", textAlign: "center" }}  {...(true ? { timeout: 500 } : {})}>
                                    <GridContainer>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            {userDetails ? (
                                                <>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <Collapse
                                                            style={{ transformOrigin: "0 0 0", textAlign: "center", }}
                                                            {...(true ? { timeout: 500 } : {})}
                                                        >
                                                            <PropagateLoader color="#004fa4" loading={true}
                                                                css={override}
                                                                size={15} />
                                                        </Collapse>
                                                    </GridItem>
                                                    {userDetails.cprFrontPage !== undefined ? (
                                                        <GridItem xs={12} sm={4} md={12}>
                                                            <img
                                                                onClick={() => {
                                                                    selectDocument(userDetails.cprFrontPage)
                                                                }}
                                                                src={userDetails.cprFrontPage}
                                                                alt="..."
                                                                className={classes.imgRaised + " " + classes.imgRounded + " " + classes.imgFluid}
                                                                loading="lazy"
                                                            />
                                                            <br />

                                                            <div style={{ textAlign: "center" }}>
                                                            </div>
                                                        </GridItem>
                                                    ) : null}
                                                    {userDetails.cprBackPage !== undefined ? (
                                                        <GridItem xs={12} sm={4} md={12}>
                                                            <img
                                                                src={userDetails.cprBackPage}
                                                                alt="..."
                                                                onClick={() => { selectDocument(userDetails.cprBackPage) }}
                                                                loading="lazy"
                                                                className={`${classes.imgRaised} ${classes.imgRounded} ${classes.imgFluid}`}
                                                            />
                                                            <br />
                                                            <div style={{ textAlign: "center" }}>
                                                            </div>
                                                        </GridItem>

                                                    ) : null}
                                                    {!["", undefined, null].includes(userDetails.consentDocument) ? (<>

                                                        <GridItem xs={12} sm={4} md={12}>
                                                            <img
                                                                onClick={() => { selectDocument(userDetails.consentDocument) }}
                                                                loading="lazy"
                                                                src={userDetails.consentDocument}
                                                                alt="..."
                                                                className={classes.imgRaised + " " + classes.imgRounded + " " + classes.imgFluid}
                                                            />
                                                            <br />
                                                            <div style={{ textAlign: "center" }}>
                                                            </div>
                                                        </GridItem></>
                                                    ) : null}

                                                    {!["", undefined, null].includes(userDetails.personPhotoImage) ? (<>

                                                        <GridItem xs={12} sm={4} md={12}>
                                                            <img
                                                                onClick={() => { selectDocument(userDetails.personPhotoImage) }}
                                                                loading="lazy"
                                                                src={userDetails.personPhotoImage}
                                                                alt="..."
                                                                className={classes.imgRaised + " " + classes.imgRounded + " " + classes.imgFluid}
                                                            />
                                                            <br />
                                                            <div style={{ textAlign: "center" }}>
                                                            </div>
                                                        </GridItem></>
                                                    ) : null}

                                                    {!["", undefined, null].includes(userDetails.cprFrontPageParent) ? (<>

                                                        <GridItem xs={12} sm={4} md={12}>
                                                            <img
                                                                onClick={() => { selectDocument(userDetails.cprFrontPageParent) }}
                                                                loading="lazy"
                                                                src={userDetails.cprFrontPageParent}
                                                                alt="..."
                                                                className={classes.imgRaised + " " + classes.imgRounded + " " + classes.imgFluid}
                                                            />
                                                            <br />
                                                            <div style={{ textAlign: "center" }}>
                                                            </div>
                                                        </GridItem></>
                                                    ) : null}

                                                    {!["", undefined, null].includes(userDetails.cprBackPageParent) ? (<>

                                                        <GridItem xs={12} sm={4} md={12}>
                                                            <img
                                                                onClick={() => { selectDocument(userDetails.cprBackPageParent) }}
                                                                loading="lazy"
                                                                src={userDetails.cprBackPageParent}
                                                                alt="..."
                                                                className={classes.imgRaised + " " + classes.imgRounded + " " + classes.imgFluid}
                                                            />
                                                            <br />
                                                            <div style={{ textAlign: "center" }}>
                                                            </div>
                                                        </GridItem></>
                                                    ) : null}
                                                    {!["", undefined, null].includes(userDetails.passportFrontPage) ? (<>

                                                        <GridItem xs={12} sm={4} md={12}>
                                                            <img
                                                                onClick={() => { selectDocument(userDetails.passportFrontPage) }}
                                                                loading="lazy"
                                                                src={userDetails.passportFrontPage}
                                                                alt="..."
                                                                className={classes.imgRaised + " " + classes.imgRounded + " " + classes.imgFluid}
                                                            />
                                                            <br />
                                                            <div style={{ textAlign: "center" }}>
                                                            </div>
                                                        </GridItem></>
                                                    ) : null}
                                                    {!["", undefined, null].includes(userDetails.passportBackPage) ? (<>

                                                        <GridItem xs={12} sm={4} md={12}>
                                                            <img
                                                                onClick={() => { selectDocument(userDetails.passportBackPage) }}
                                                                loading="lazy"
                                                                src={userDetails.passportBackPage}
                                                                alt="..."
                                                                className={classes.imgRaised + " " + classes.imgRounded + " " + classes.imgFluid}
                                                            />
                                                            <br />
                                                            <div style={{ textAlign: "center" }}>
                                                            </div>
                                                        </GridItem></>
                                                    ) : null}

                                                </>
                                            ) : (
                                                <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                                            )}
                                        </div>
                                    </GridContainer>
                                    {
                                        openImageViewer && (
                                            <Lightbox
                                                //    reactModalStyle={customStyles}
                                                image={documentViewerData}
                                                onClose={() => setOpenImageViewer(false)}
                                            />
                                        )
                                    }
                                </Collapse>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={9}>
                                <Table
                                    tableData={
                                        [
                                            ["First Name", ":", <TextField
                                                type="text"
                                                fullWidth
                                                inputProps={{
                                                    autoComplete: "off",
                                                    type: "text",
                                                    value: userDetails?.firstName,
                                                }}
                                            />, "Middle Name", ":", <TextField
                                                    type="text"
                                                    fullWidth
                                                    inputProps={{
                                                        autoComplete: "off",
                                                        type: "text",
                                                        value: userDetails?.middleName,
                                                    }}
                                                />
                                            ],
                                            ["Last Name", ":", <TextField
                                                type="text"
                                                fullWidth
                                                inputProps={{
                                                    autoComplete: "off",
                                                    type: "text",
                                                    value: userDetails?.lastName,
                                                }}
                                            />,
                                            "Gender", ":", <TextField
                                                type="text"
                                                fullWidth
                                                inputProps={{
                                                    autoComplete: "off",
                                                    type: "text",
                                                    value: userDetails.gender,
                                                }}
                                            />,
                                            ],
                                            [
                                                "Email", ":", <TextField
                                                    type="text"
                                                    fullWidth
                                                    inputProps={{
                                                        autoComplete: "off",
                                                        type: "text",
                                                        value: userDetails?.email,
                                                    }}
                                                />,
                                                "Mobile Number", ":", <TextField
                                                type="text"
                                                fullWidth
                                                inputProps={{
                                                    autoComplete: "off",
                                                    type: "text",
                                                    value: userDetails.mobile,
                                                }}
                                            />
                                            ],
                                            ["CPR Number", ":", <TextField
                                                type="text"
                                                fullWidth
                                                inputProps={{
                                                    autoComplete: "off",
                                                    type: "text",
                                                    value: userDetails?.cpr,
                                                }}
                                            />,
                                                "CPR Expiry", ":", <TextField
                                                    type="text"
                                                    fullWidth
                                                    inputProps={{
                                                        autoComplete: "off",
                                                        type: "text",
                                                        value: userDetails.cprExpiry,
                                                    }}
                                                />
                                            ],
                                            [
                                                "Place Of Birth", ":", <TextField
                                                    type="text"
                                                    fullWidth
                                                    inputProps={{
                                                        autoComplete: "off",
                                                        type: "text",
                                                        value: userDetails.placeOfBirth,
                                                    }}
                                                />,
                                                "DOB ", ":",
                                                <TextField
                                                    type="text"
                                                    fullWidth
                                                    inputProps={{
                                                        autoComplete: "off",
                                                        type: 'text',
                                                        value: userDetails.dateOfBirth,
                                                    }}
                                                />
                                            ],
                                            ["Nationality", ":", <TextField
                                                type="text"
                                                fullWidth
                                                inputProps={{
                                                    autoComplete: "off",
                                                    type: "text",
                                                    value: userDetails.nationality,
                                                }}
                                            />,
                                                "Address Type", ":", <TextField
                                                    type="text"
                                                    fullWidth
                                                    inputProps={{
                                                        autoComplete: "off",
                                                        type: "text",
                                                        value: userDetails.addressType,
                                                    }}
                                                />
                                            ],
                                            ["Passport Number", ":", <TextField
                                                type="text"
                                                fullWidth
                                                inputProps={{
                                                    autoComplete: "off",
                                                    type: "text",
                                                    value: userDetails.passportNumber,
                                                }}
                                            />, "Passport Expiry", ":", <TextField
                                                    type="text"
                                                    fullWidth
                                                    inputProps={{
                                                        autoComplete: "off",
                                                        type: "text",
                                                        value: userDetails.passportExpiry,
                                                    }}
                                                />
                                            ],
                                            ["Flat Number", ":", <TextField
                                                type="text"
                                                fullWidth
                                                inputProps={{
                                                    autoComplete: "off",
                                                    type: "text",
                                                    value: userDetails.flatNumber,
                                                }}
                                            />,
                                                "Building Number", ":", <TextField
                                                    type="text"
                                                    fullWidth
                                                    inputProps={{
                                                        autoComplete: "off",
                                                        type: "text",
                                                        value: userDetails.buildingNumber,
                                                    }}
                                                />
                                            ],
                                            ["Block Number", ":", <TextField
                                                type="text"
                                                fullWidth
                                                inputProps={{
                                                    autoComplete: "off",
                                                    type: "text",
                                                    value: userDetails.blockNumber,
                                                }}
                                            />,
                                                "Road Number", ":", <TextField
                                                    type="text"
                                                    fullWidth
                                                    inputProps={{
                                                        autoComplete: "off",
                                                        type: "text",
                                                        value: userDetails.roadNumber,
                                                    }}
                                                />
                                            ],
                                            ["Preferred Communication Language", ":",
                                                <TextField
                                                    type="text"
                                                    fullWidth
                                                    inputProps={{
                                                        autoComplete: 'off',
                                                        type: 'text',
                                                        value: userDetails.preferredCommunicationLanguage,
                                                    }}
                                                />,
                                                "Occupation ", ":",
                                                <TextField
                                                    type="text"
                                                    fullWidth
                                                    inputProps={{
                                                        autoComplete: "off",
                                                        type: 'text',
                                                        value: userDetails.occupation,
                                                    }}
                                                />
                                            ],
                                            ["Created At", ":",
                                                <TextField
                                                    type="text"
                                                    fullWidth
                                                    inputProps={{
                                                        autoComplete: 'off',
                                                        type: 'text',
                                                        value: formattedDate,
                                                    }}
                                                />,
                                                "Valid Proof of Identification ", ":",
                                                <TextField
                                                    type='text'
                                                    fullWidth
                                                    inputProps={{
                                                        autoComplete: 'off',
                                                        type: 'text',
                                                          value: userDetails.validProofOfIdentification,
                                                    }}
                                                    />
                                               
                                          
                                    ]

                                        ]
                                    }
                                />
                                <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', margin: "50px" }}>
                                    {showActionBtn ? approveBtn : ""}

                                    {showActionBtn ? recheckBtn : ""}
                                    <Button onClick={handleBack} size="md" simple color="primary"> <ArrowBackIcon />Back </Button>

                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </CardBody>
            </Card>

        </>
    )
}
