// @material-ui/icons
import PeopleIcon from "@material-ui/icons/People";
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SettingsIcon from '@material-ui/icons/Settings';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import BusinessIcon from '@material-ui/icons/Business';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import SmsIcon from '@mui/icons-material/Sms';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import UserGroupsPage from "views/UserGroups/UserGroups.js";
import SystemGroupPage from "views/SystemUsers/SystemUsers.js";
import AppSettingsPage from "views/AppSettings/AppSettings.js"
import ServicesPage from "views/Services/Services.js"
import MigrationPage from "views/Migration/Migration.js"
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Fees from "views/Fees/Fees";
import SpecialRates from "views/SpecialRates/SpecialRates";
import Test from "views/UserProfile/UserProfile.js";
import TransactionsAdmin from "views/Transactions/Transactions"
import BFCBillers from "views/BillersEdit/HomeScreenBillers"
import SmsSwitching from "views/SmsSwitching/SmsSwitching";
import GPWArchivedUsers from "views/GPWArchivedUsers/GPWArchivedUsers"
import ArchiveIcon from '@mui/icons-material/Archive';



const dashboardRoutes = [
    {
        path: "/user-groups",
        name: "User Groups",
        icon: GroupWorkIcon,
        component: UserGroupsPage,
        layout: "/dashboard",
    },
    {
        path: "/system-users",
        name: "System Users",
        icon: AccountCircleIcon,
        component: SystemGroupPage,
        layout: "/dashboard",
    },
    // {
    //     path: "/app-settings",
    //     name: "App Settings",
    //     icon: SettingsIcon,
    //     component: AppSettingsPage,
    //     layout: "/dashboard",
    // },
    {
        path: "/services",
        name: "Services",
        icon: AssignmentIcon,
        component: ServicesPage,
        layout: "/dashboard",
    },
    {
        path: "/migration",
        name: "Migration",
        icon: UnarchiveIcon,
        component: MigrationPage,
        layout: "/dashboard",
    },
    {
        path: "/fees",
        name: "Fees",
        icon: AccountBalanceWalletIcon,
        component: Fees,
        layout: "/dashboard",
    },
    {
        path: "/special-rates",
        name: "Special Rates",
        icon: AccountBalanceWalletIcon,
        component: SpecialRates,
        layout: "/dashboard",
    },
    {
        path: "/bfc-billers",
        name: "BFC Billers",
        icon: AccountBalanceWalletIcon,
        component: BFCBillers,
        layout: "/dashboard",
    },
    {
        path: "/sms-switching",
        name: "SMS Switching",
        icon: SmsIcon,
        component: SmsSwitching,
        layout: "/dashboard"

    },
    {
        path: "/GPW-Archived-Users",
        name: "GPW Archived Users",
        icon: ArchiveIcon,
        component: GPWArchivedUsers,
        layout: "/dashboard",
    },


];

export default dashboardRoutes;
