import React from "react";
import axios from "axios";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import jwt from 'jsonwebtoken';
import { useDispatch } from "react-redux";
import { setAdmin, setID, setEmployeeID, setDepartmentID, setStatus, setFirstName, setLastName, setPhone, setEmail, setGroups, setServices, setRiskServices, setUsername } from "redux/actions/user.js";
import PerfectScrollbar from "perfect-scrollbar";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import allRoutes from "routes/routes.js";
import superAdminRoutes from "routes/superUserRoutes.js";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import image from "assets/img/sidebar-1.jpg";
import { createBrowserHistory } from "history";
import logo from "assets/img/apple-icon.png";
// import logo from "assets/img/BLANK_ICON.png";
import config from "../config.js"
import build from "../../package.json"
import animateScrollTo from 'animated-scroll-to';
import libs from "controllers/libs";

let ps;

const hist = createBrowserHistory();
const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  const color = "purple";

  // const token = useSelector(state => state.user.token);
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  const history = useHistory();
  const dispatch = useDispatch();
  // states and functions

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [routes, setRoutes] = React.useState([]);
  const [defaultRoute, setDefaultRoute] = React.useState("/dashboard/customers");
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/dashboard/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  React.useEffect(() => {
    if (sessionStorage.getItem("cx_id") !== null) {
      try {
        axios.get(`${config.url().baseURL}/verify-token `, {
          headers: {
            auth: sessionStorage.getItem("cx_id"),
          },
        })
          .then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
              // jwt.verify(sessionStorage.getItem("cx_id"), 'HeLlO-BaHrAiN', async (err, userData) => {
               if(sessionStorage.getItem("cx_data")){
                // if (err) { sessionStorage.removeItem("cx_id"); history.push("/"); }
                // else {
                  // console.log(sessionStorage.getItem("cx_id"))
                  let user =JSON.parse(sessionStorage.getItem("cx_data"))
                        if (user.admin) {
                    setDefaultRoute("/dashboard" + superAdminRoutes[0].path)
                    setRoutes(superAdminRoutes)
                    dispatch(setAdmin(user.admin));
                    dispatch(setUsername(user.admin));
                  } else {
                    if (user.passwordStats != "0") {
                      let userRoutes = []
                       Promise.all(user.services.map(async (service) => {
                        await Promise.all(allRoutes.map((route) => {
                          if (service.name == route.name) {
                            userRoutes.push(route)
                          }
                        }))
                      }))
                      Promise.all(user.riskServices.map(async (service) => {
                        await Promise.all(allRoutes.map((route) => {
                          let tempName = userRoutes.find(route => route.name == service.name)
                          if (tempName == null) {
                            if (service.name == route.name) {
                              userRoutes.push(route)
                            }
                          }
                        }))
                      }))

                       dispatch(setAdmin(user.admin));
                       dispatch(setID(user._id));
                       dispatch(setEmployeeID(user.employeeID));
                       dispatch(setDepartmentID(user.departmentID));
                       dispatch(setStatus(user.status));
                     dispatch(setFirstName(user.firstName));
                       dispatch(setLastName(user.lastName));
                       dispatch(setPhone(user.phone));
                       dispatch(setEmail(user.email));
                       dispatch(setGroups(user.groups));
                      dispatch(setServices(user.services));
                      dispatch(setRiskServices(user.riskServices));

                      userRoutes.sort(function (a, b) {
                        return a.sort - b.sort;
                      });

                      //libs.speak(`Hi, ${user.firstName} ${user.lastName}`)

                      setRoutes(userRoutes)
                      setDefaultRoute("/dashboard" + userRoutes[0].path)
                    } else {
                      history.push("/set-password");
                    }
                  }
                }
              // }
              // );
            } else {
              sessionStorage.removeItem("cx_id")
              history.push("/");
            }
          })
          .catch((err) => {
            alert(err)
            sessionStorage.removeItem("cx_id")
            history.push("/");
          });
      } catch (error) {
        sessionStorage.removeItem("cx_id")
        history.push("/");
      }
    } else {
      history.push("/");
    }
  }, []);




  const switchRoutes = (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === "/dashboard") {
          return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
        }
        return null;
      })}
      <Redirect from="/dashboard" to={defaultRoute} />
    </Switch>
  );

  const throttle = (cb, delay = 10000) => {
    let shouldWait = false;
    let waitingArgs;

    const timeOutFun = () => {
      if (waitingArgs == null) {

        shouldWait = false;
      }
      else {
        cb(...waitingArgs)
        waitingArgs = null
        setTimeout(timeOutFun, delay)

      }

    }
    return (...args) => {
      if (shouldWait) {
        waitingArgs = args
        return
      }
      shouldWait = true
      cb(...args)
      setTimeout(timeOutFun, delay)
    }
  }

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    window.addEventListener("mouseover", throttle(refresMove));

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
      window.removeEventListener("mouseover", refresMove)
    };
  }, [mainPanel]);
  const getTimeRemaining = (e) => {
    const total = Date.parse(new Date(e)) - Date.parse(new Date());
    if (total == 0) {
      clearSession()
      history.push("/");
    } else {
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / 1000 * 60 * 60) % 24);
      return {
        total, hours, minutes, seconds
      };
    }
  }

  const refresMove = async () => {
    let { total, hours, minutes, seconds } = getTimeRemaining((sessionStorage.getItem("cx_id_time")));
    try {
      if (minutes <= 10) {
        // let resp = await axios.get(`${config.url().baseURL}/refreshToken`, { headers: { refresh_token: sessionStorage.getItem("cx_refresh_id") } })
        let resp = await axios.post(`${config.url().baseURL}/refreshToken`, { refresh_token: sessionStorage.getItem("cx_refresh_id")  })

        if (resp.data.err) { clearSession(); hist.push("/login") }
        else {
          await clearSession()
          await setSession(resp.data.data.AuthToken, resp.data.data.RefreshToken, resp.data.data.Time)
        }
      }
    } catch (error) {
      // return Promise.reject(error);
      clearSession(); 
      hist.push("/login") 
    }

  }
  const setSession = async (token, refreshToken, time) => {
    sessionStorage.setItem("cx_id", token);
    sessionStorage.setItem("cx_refresh_id", refreshToken);
    sessionStorage.setItem("cx_id_time", time);
  }


  const clearSession = async () => {
    //function to clear session
    sessionStorage.removeItem("cx_id");
    sessionStorage.removeItem("cx_refresh_id");
    sessionStorage.removeItem("cx_id_time");

  }
  const scrollToTop = () => {
    animateScrollTo(10).then(hasScrolledToPosition => {
      if (hasScrolledToPosition) {
        alert("done")
      } else {
        alert("no done")
      }
    });
  }
  return (
    <div className={classes.wrapper}>
      <Sidebar
        version={build.version}
        routes={routes}
        logoText={"BFC CX-Portal"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar routes={routes} handleDrawerToggle={handleDrawerToggle} {...rest} />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}
            </div>

          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}

          </div>

        )}
        {/* {getRoute() ? <Footer /> : null} */}
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
      </div>
    </div>
  );
}
