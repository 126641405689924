import * as React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SimpleButton from "@mui/lab/LoadingButton";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CircularProgress from "@mui/material/CircularProgress";
import swal from 'sweetalert';
import moment from 'moment';
import './style/reward.css'; // Import the CSS file


import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import XlxUploadAndCheck from "./XlxUploadAndCheck";
import { styled } from "@mui/system";
import MuiAlert from "@material-ui/lab/Alert";
import { IconButton, TextareaAutosize, Tooltip } from "@material-ui/core";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { useTheme } from '@material-ui/core/styles';



//import TextField from "@material-ui/core/TextField";
import { MenuItem, Select, Snackbar } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import Box from "@mui/material/Box";
import { Grid, TextField } from "@material-ui/core";
import * as XLSX from 'xlsx';

import { Stack, responsiveFontSizes } from "@mui/material";
import { Button } from "@material-ui/core";
import CardFooter from "components/Card/CardFooter";
import axios from "axios";
import config from "config";
import { PropagateLoader } from "react-spinners";

import { useDropzone } from 'react-dropzone';


const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;

const useStyles = makeStyles((theme) => ({
  ...SweetAlertStyle,
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    float: "center",
    display: "inlineBlock",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  addButton: {
    float: "right !important",
    display: "inlineBlock",
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  addDeptContainer: {
    height: "49px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginBottom: "5px !important",
  },
  addDeptButton: {
    marginRight: "-5px !important",
  },
  input: {
    flex: 1,
    height: "45px",
    marginLeft: "15px",
  },
  searchHead: {
    marginTop: "-5px",
    marginBottom: "1px",
    color: "#144b85",
    fontWeight: "400",
  },
  dialogContainer: {
    display: "flex",
  },
  inputPassword: {
    flex: 1,
    marginTop: "10px",
    height: "45px",

    paddingLeft: "10px",
  },
  nextButton: {
    marginLeft: "150px",
  },
  passwordHead: {
    fontWeightL: "500",
    marginBottom: "0px",
    color: "#ffffff",
  },
  indicator: {
    backgroundColor: "white",
  },

  ...modalStyle(theme),
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="down" ref={ref} {...props} />;
// });

export default function AddReward({ back }) {
  const classes = useStyles();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");

  const [rewardType, setRewardType] = React.useState("");
  const [couponCodeType, setCouponCodeType] = React.useState("COMMON");
  const [productId, setProductId] = React.useState("");
  const [merchantName, setMerchantName] = React.useState("");
  const [denomination, setDenomination] = React.useState("");
  //    percentage
  const [currency, setCurrency] = React.useState("");
  const [redemptionType, setRedumptionType] = React.useState("");
  const [redumptionAccepted, setRedumptionAccepted] = React.useState("QRCODE");
  const [redemptionAcceptedFormat, setRedemptionAcceptedFormat] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [termsAndConditions, setTermsAndConditions] = React.useState("");
  const [validFrom, setValidFrom] = React.useState("");
  const [validTo, setValidTo] = React.useState("");
  const [count, setCount] = React.useState("");
  const [serialNo, setSerialNo] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [sheet, setSheet] = React.useState("");
  const [snackBarType, setSnackBarType] = React.useState("error");
  const [data, setData] = React.useState([]);
  const formData = new FormData();
  const [inSubmit, setInSubmit] = React.useState(false)
  const [barcodeTypes, setBarcodeTypes] = React.useState([])
  const [status, setStatus] = React.useState("")
  const [branchData, setBranchData] = React.useState("")
  const [logo, setLogo] = React.useState("")


  const theme = useTheme();


  const handleCheckXlx = () => {
    setInSubmit(false)
    if (data.length == 0) {
      swal("Oops!", "Please upload an Excel file with at least 1 record.", "error");
    } else {
      const headerRow = Object.keys(data[0]);

      if (headerRow[0] !== 'serialNo') {
        swal("Oops!", "The Excel file must contain a column with the header 'serialNo'.", "error");
      } else if (Number(count) !== data.length) {
        swal("Oops!", `The count and the number of records in the Excel file do not match.`, "error");
      } else {
        !inSubmit && swal({
          title: "Success",
          text: "The Excel file is in the correct format.",
          icon: "success",
          button: "OK",

        })
        return true
      }
    }
  };

  const fetchBarcodeTypes = async () => {
    try {
      // const map = new Map()
      const resp = await axios.get(`${config.url().app_URL}/CXPortal/v13/getBarcodeTypes`)
      const barcodeTypesData = resp.data.data;

      const types = Object.keys(barcodeTypesData);
      console.log(types);
      const barcodeTypesArray = types.map(type => {
        // map.set(type, barcodeTypesData[type])
        return ({
          label: type,
          value: barcodeTypesData[type]
        })
      });
      setBarcodeTypes(barcodeTypesArray)
      console.log(types);


    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    if (redumptionAccepted === 'BARCODE') {
      // Call the API and fetch the barcode types
      fetchBarcodeTypes()
    }
  }, [redumptionAccepted]);



  const handleDownload = () => {
    // Define the data that will be written to the file
    const header = [
      ["serialNo"]
    ];

    // Create a new workbook and worksheet using the xlsx library
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(header);

    // Add the worksheet to the workbook and save it as a file
    XLSX.utils.book_append_sheet(wb, ws, "Rewards");
    XLSX.writeFile(wb, "rewards.xlsx");
  };


  const handleDownloadBranchData = () => {
    // Define the data that will be written to the file
    const header = [
      ["branchCode", "pin"]
    ];

    // Create a new workbook and worksheet using the xlsx library
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(header);

    // Add the worksheet to the workbook and save it as a file
    XLSX.utils.book_append_sheet(wb, ws, "BranchDetails");
    XLSX.writeFile(wb, "BranchDetails.xlsx");
  };
  const handleBack = () => {
    back()
    setCount("")
    setSerialNo("")
    setCouponCodeType("")
    setRewardType("")
    setCouponCodeType("COMMON");
    setProductId("");
    setMerchantName("");
    setDenomination("");
    //    percentage
    setCurrency("");
    setRedumptionType("");
    setRedumptionAccepted("");

    setRedemptionAcceptedFormat("");
    setDescription("");
    setTermsAndConditions("");
    // setValidFrom("");
    // setValidTo("");
    setCount("");
    setSerialNo("");
    // const [submitting, setSubmitting] = React.useState(false);
    setSheet("");
  }

  const handleCheck = async () => {
    try {
      if (!rewardType || !couponCodeType || !productId || !merchantName || !denomination || !currency || !redemptionType || !description || !termsAndConditions || !count) {
        console.log(rewardType, couponCodeType, productId, merchantName, denomination, currency, redemptionType, description, termsAndConditions, count);
        setOpenSnack(true);
        setSnackBarMsg("Please enter all field");

      } else if (sheet && handleCheckXlx()) {

        handleSubmit()

      }
      else if (!sheet) {
        handleSubmit()
      }
      // if(resp.error)
      // console.log(resp.error);
    } catch (err) {
      console.log(err);
      setSubmitting(false);
      openSnackBar(err);
    }


  }


  const onDropBranch = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      console.log('Uploaded file:', acceptedFiles[0]);
      setBranchData(acceptedFiles[0]); // Update the sheet1 state with the uploaded file
    }
  };

  const onDropLogo = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      console.log('Uploaded file:', acceptedFiles[0]);
      setLogo(acceptedFiles[0]); // Update the sheet2 state with the uploaded file
    }
  };

  const { getRootProps: getRootPropsBranch, getInputProps: getInputPropsBranch } = useDropzone({
    onDrop: onDropBranch,
    accept: '.xlsx, .xls',
    multiple: false,
  });

  const { getRootProps: getRootPropsLogo, getInputProps: getInputPropsLogo } = useDropzone({
    onDrop: onDropLogo,
    accept: 'image/*', // Only accept image files
    multiple: false,
  });

  const handleSubmit = async () => {
    setInSubmit(true)
    formData.append("file", sheet);
    formData.append("rewardType", rewardType);
    formData.append("couponCodeType", couponCodeType);
    formData.append("productId", productId);
    formData.append("merchantName", merchantName);
    formData.append("denomination", denomination);
    formData.append("currency", currency);
    formData.append("redemptionType", redemptionType);
    formData.append("redemptionAccepted", redumptionAccepted);
    formData.append("redemptionAcceptedFormat", redemptionAcceptedFormat);
    formData.append("description", description);
    formData.append("termsAndConditions", termsAndConditions);
    formData.append("validFrom", validFrom);
    formData.append("validTo", validTo);
    formData.append("count", count);
    formData.append("serialNo", serialNo);
    formData.append("branchData", branchData);
    formData.append("logo", logo);



    if (!rewardType || !couponCodeType || !productId || !merchantName || !denomination || !currency || !redemptionType || !validFrom || !validTo) {
      openSnackBar("Please Enter All Required Fields")
      return
    } else if (validFrom > validTo) {
      openSnackBar("From Date Must be less than To Date")
      return
    }
    //   let check = await Object.keys(formData.data).some((key) => {
    //     if (["billerName", "tranCode", "category"].includes(key)) {
    //         const value = data.data[key];
    //         return value === null || value === undefined || value === "";
    //     } else {
    //         return false;
    //     }
    // });





    setSubmitting(true);
    try {
      formData.append("status","CREATED")
      const resp = await axios.post(
        `${config.url().baseURL}/mc-reward-inventory-create`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (!resp.data.err)
        swal({
          title: "Success",
          text: "Reward has been created!",
          icon: "success",
          button: "OK",

        }).then(() => {


          // redirect to another page
          back()
          setCount("")
          setSerialNo("")
          setCouponCodeType("")
          setRewardType("")
          setCouponCodeType("COMMON");
          setProductId("");
          setMerchantName("");
          setDenomination("");
          //    percentage
          setCurrency("");
          setRedumptionType("");
          setRedumptionAccepted("");

          setRedemptionAcceptedFormat("");
          setDescription("");
          setTermsAndConditions("");
          // setValidFrom("");
          // setValidTo("");
          setCount("");
          setSerialNo("");
          // const [submitting, setSubmitting] = React.useState(false);
          setSheet("");


        });





      console.log(resp.data);

      if (resp.data.err) {
        openSnackBar(resp.data.msg, false);
      }
      setSubmitting(false);
    } catch (err) {
      console.log(err);
      snackBarMsg("Something Unexpected Happend")
    }
  }


  function openSnackBar(msg, success) {
    setOpenSnack(true);
    if (success) {
      setSnackBarType("success");
    } else {
      setSnackBarType("error");
    }
    setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, "$1"));
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  }





  return (
    <Card>
      <CardHeader color="primary" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Rewards
        <Tooltip title="Download Sample Exel">
          <IconButton onClick={handleDownload} style={{ color: "white" }}>
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>

      </CardHeader>
      <CardBody>
        <Grid container spacing={20} xs={12}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              className="{{}}"
              required
              id="type"
              select
              label="Reward"
              onChange={(e) => {
                setRewardType(e.target.value);
              }}
              value={rewardType}
              // helperText="Please select Reward Type *"
              style={{ width: 300, margin: 10 }}
            >
              <MenuItem key={"VOUCHER"} value={"VOUCHER"}>
                Voucher
              </MenuItem>

              <MenuItem key={"GIFTCARD"} value={"GIFTCARD"}>
                Gift Card
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="merchantName"
              label="Merchant Name *"
              variant="standard"
              value={merchantName}
              onChange={(e) => setMerchantName(e.target.value)}
              inputProps={{
                maxLength: 20,
              }}
              style={{ width: 300, margin: 10 }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="count"
              label="Product ID *"
              variant="standard"
              value={productId}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 20);
              }}
              inputProps={{
                maxLength: 20,
              }}
              onChange={(e) => setProductId(e.target.value)}
              style={{ width: 300, margin: 10 }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="count"
              label="Count *"
              variant="standard"
              value={count}
              onChange={(e) => {
                const enteredValue = e.target.value;
                if (!isNaN(enteredValue)) {
                  setCount(enteredValue);
                }
              }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 20);
              }}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                maxLength: 20,
              }}
              style={{ width: 300, margin: 10 }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="type"
              select
              label="Type *"
              onChange={(e) => {
                setCouponCodeType(e.target.value);
                setSheet("")
              }}
              value={couponCodeType}
              // helperText="Please select Type"
              style={{ width: 300, margin: 10 }}
            >
              <MenuItem key={"unique"} value={"UNIQUE"}>
                Unique
              </MenuItem>

              <MenuItem key={"common"} value={"COMMON"}>
                Common
              </MenuItem>
            </TextField>
          </Grid>


          <Grid item xs={12} sm={6} md={4}>
            {couponCodeType === "UNIQUE" ? (
              <div style={{ marginTop: "37px" }}>
                <XlxUploadAndCheck setSheet={setSheet} sheet={sheet} count={count} data={data} setData={setData} handleCheckXlx={handleCheckXlx} />
                {sheet.name && sheet.name}
              </div>
            ) : (
              <TextField
                id="serialNo"
                label="Serial No *"
                variant="standard"
                value={serialNo}
                style={{ width: 300, margin: 10 }}
                onChange={(e) => setSerialNo(e.target.value)}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={4}>

            <TextField
              id="denomination"
              label="Denomination *"
              variant="standard"
              value={denomination}
              onChange={(e) => {
                const enteredValue = e.target.value;
                if (!isNaN(enteredValue)) {
                  setDenomination(enteredValue);
                }
              }}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                maxLength: 20,
              }}
              style={{ width: 300, margin: 10 }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="DenominationType"
              select
              label="Denomination Type *"
              onChange={(e) => {
                setCurrency(e.target.value);
              }}
              value={currency}
              // helperText="Please select Denomination Type"
              style={{ width: 300, margin: 10 }}
            >
              <MenuItem key={"BHD"} value={"BHD"}>
                BHD
              </MenuItem>

              <MenuItem key={"percentage"} value={"PERCENT"}>
                Percentage
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="redumption"
              select
              label="Redumption Type *"
              onChange={(e) => {
                setRedumptionType(e.target.value);
              }}
              value={redemptionType}
              // helperText="Please select Redumption Type"
              style={{ width: 300, margin: 10 }}
            >
              <MenuItem key={"ONLINE"} value={"ONLINE"}>
                Online
              </MenuItem>

              <MenuItem key={"OFFLINE"} value={"OFFLINE"}>
                Offline
              </MenuItem>

              <MenuItem key={"BOTH"} value={"BOTH"}>
                Both
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField


              id="acceptedRedumption"
              select
              label="Accepted Redumption *"
              onChange={(e) => {
                setRedemptionAcceptedFormat("")
                setRedumptionAccepted(e.target.value);
                console.log(
                  e.target.value === "QRCODE" || e.target.value === "BARCODE"
                );
              }}
              value={redumptionAccepted}
              // helperText="Please select Redumption Type"
              style={{ width: 300, margin: 10 }}
            >
              <MenuItem key={"QR"} value={"QRCODE"}>
                QR Code
              </MenuItem>

              <MenuItem key={"Barcode"} value={"BARCODE"}>
                Bar Code
              </MenuItem>

              <MenuItem key={"Code"} value={"SERIALNO"}>
                Serial Number
              </MenuItem>

              <MenuItem key={"Merchant"} value={"MERCHANTLINK"}>
                Merchant Link
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {console.log(barcodeTypes)}
            {
              // redumptionAccepted === "BARCODE" &&
              <>

                <TextField
                  disabled={redumptionAccepted !== "BARCODE"}
                  // {redumptionAccepted === "SERIALNO" || redumptionAccepted == 'MERCHANTLINK'}
                  id="redemptionAcceptedFormat"
                  select
                  label="Redemption Accepted Format"
                  onChange={(e) => {
                    setRedemptionAcceptedFormat(e.target.value);
                  }}
                  value={redemptionAcceptedFormat}
                  // helperText="Please Select Barcode Type"
                  style={{ width: 300, margin: 10 }}
                >
                  {
                    redumptionAccepted === "BARCODE" &&
                    barcodeTypes.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))
                  }
                </TextField>

              </>

              // barcodeTypes.map(type => (
              //   <MenuItem key={type} value={type}>
              //     {type}
              //   </MenuItem>
              // ))
              // :
              // <TextField
              //   disabled={redumptionAccepted === "SERIALNO" || redumptionAccepted == 'MERCHANTLINK'}
              //   id="redemptionAcceptedFormat"
              //   label="Redemption Accepted Format *"
              //   onChange={(e) => {
              //     const enteredValue = e.target.value;
              //     if (enteredValue === '' || (!isNaN(enteredValue) && enteredValue >= 1 && enteredValue <= 40)) {
              //       setRedemptionAcceptedFormat(enteredValue);
              //     }
              //   }}
              //   value={redemptionAcceptedFormat}
              //   helperText="Please enter a number between 1-40"
              //   type="number"
              //   inputProps={{
              //     min: 1,
              //     max: 40,
              //     step: 1,
              //   }}
              //   style={{ width: 300, margin: 10 }}
              // />

            }
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              type="date"
              style={{ width: 300 }}
              label=" From *"
              // error={values.dobErr}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              //focused={updated && updated.dob}
              // helperText={updated && updated.dob ? "Updated" : ""}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                autoComplete: "off",
                type: "date",
                min: moment().format("YYYY-MM-DD"), // Set the minimum selectable date as today

                // readOnly: !saveBtn,
                // max: moment(new Date()).format("YYYY-MM-DD"),
                // value: moment(DOB).format("YYYY-MM-DD"),
                onChange: (e) => {
                  // setDOB(e.target.value);
                  setValidFrom(e.target.value)
                },
                onBlur: (e) => {
                  // if (moment(e.target.value).isAfter(moment(new Date())) && (new Date(e.target.value)).isValid()) {
                  //   setDOB("");
                  //   openSnackBar("Invalid DOB.")
                  // } else {
                  //   validateOldData()
                  // }
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              onChange={e => setValidTo(e.target.value)}
              type="date"
              style={{ width: 300 }}
              label="To *"
              InputLabelProps={{ shrink: true }}
            // ... rest of your TextField props
            />
          </Grid>
          {/* {redumptionAccepted == 'MERCHANTLINK'?()} */}
          {redumptionAccepted === 'MERCHANTLINK' && (
            <>
              <Grid item xs={12} sm={12} md={4} >
                <Tooltip title="Download Sample Excel">
                  <IconButton onClick={handleDownloadBranchData} style={{ color: "Primary", fontSize: 32 }}>
                    <CloudDownloadIcon style={{ fontSize: 40 }} />
                  </IconButton>
                </Tooltip>
                <p>Download Sample Branch Data Excel</p>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <div {...getRootPropsLogo()} >
                  <input {...getInputPropsLogo()} />
                  <Button
                    variant="contained"
                    color="primary"
                    component="label"
                  // onChange={handleFileUpload}
                  >
                    Upload Logo
                  </Button>

                  <p>Drag and drop the logo file here, or click to select a file</p>
                </div>
                {logo && logo.name && <p>Selected logo file: {logo.name}</p>}
              </Grid>

              {/* <Grid item xs={12} sm={12} md={4}>
                <div {...getRootPropsBranch()} className="dropzone">
                  <input {...getInputPropsBranch()} /> */}
              {/* <CloudUploadIcon fontSize="large" style={{ color: theme.palette.primary.main }} /> */}
              {/* <Button
                    variant="contained"
                    color="primary"
                    component="label"
                  // onChange={handleFileUpload}
                  >
                    Upload Branch Data
                  </Button>
                  <p>Drag and drop the branch data file here, or click to select a file</p>
                </div>
                {branchData && branchData.name && <p>Selected branch data file: {branchData.name}</p>}
              </Grid> */}
              <Grid item xs={12} sm={12} md={4}>
                <XlxUploadAndCheck setSheet={setBranchData} sheet={branchData} count={count} data={data} setData={setData} />
                <p>select a branch data file here</p>

                {branchData && branchData.name}
              </Grid>
            </>
          )}


          <Grid item xs={12} sm={12} md={4}>
            {/* <InputLabel shrink style={{ fontSize: "0.875rem", marginBottom: "18px" }}>
              Description
            </InputLabel> */}

            <InputLabel shrink>Description *</InputLabel>

            <TextareaAutosize
              style={{ width: "70%" }}

              minRows={8}
              label="Description"
              value={description}
              onChange={e => setDescription(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <InputLabel shrink>T&C *</InputLabel>
            <TextareaAutosize style={{ width: "70%" }} minRows={8}
              value={termsAndConditions}
              onChange={e => setTermsAndConditions(e.target.value)}
            />
          </Grid>

        </Grid>

      </CardBody>

      <CardFooter className={classes.cardFooter}>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', padding: '10px', marginLeft: "43%" }}>
          {!submitting ? (
            <>
              <Button
                tranCode="submit"
                variant="contained"
                color="primary"
                className={classes.submitButton}
                onClick={handleSubmit}
              >
                Submit
              </Button>
              <Button
                tranCode="submit"
                variant="contained"
                color="primary"
                className={classes.submitButton}
                onClick={handleBack}
              >
                Back
              </Button>
            </>
          ) : (
            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
          )}
        </div>
      </CardFooter>




      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => {
            setOpenSnack(false);
            setSnackBarMsg("");
          }}
          severity={snackBarType}
        >
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </Card>
  );
}
