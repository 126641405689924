import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Card, CardContent, CardMedia, Divider, Box, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Swal from 'sweetalert2';
import axios from 'axios';
import config from "../../../config";

const useStyles = makeStyles((theme) => ({
    media: {
        height: 573,
        width: '100%',
        objectFit: 'cover',
        marginBottom: theme.spacing(2),
    },
    cardContent: {
        padding: theme.spacing(2),
    },
    detailRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    detailKey: {
        fontWeight: 'bold',
    },
    button: {
        margin: theme.spacing(1),
    },
    actions: {
        justifyContent: 'space-between',
    },
    remarksDialog: {
        padding: theme.spacing(2),
    },
}));

const CustomerDetailsModal = ({ open, onClose, details, referenceNo, serviceType, name, isReload }) => {
    const classes = useStyles();
    const [openRemarks, setOpenRemarks] = useState(false);
    const [remarks, setRemarks] = useState('');

    const callApi = async (status, additionalData = {}) => {
        try {
            const { data } = await axios.post(`${config.url().appURL}/CXPortal/v20/shufti/compliance/changeStatus`, {
                name,
                status,
                referenceNo,
                serviceType,
                ...additionalData,
            });

            if (!data.err) {
                isReload(true);
                Swal.fire('Success!', `The request has been ${status}.`, 'success');
            } else {
                Swal.fire('Error', data.message || 'Something went wrong!', 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to perform the action.', 'error');
        }
    };

    const handleApprove = async () => {
        onClose();
        const { isConfirmed } = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to approve this request?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
        });

        if (isConfirmed) {
            await callApi('ACCEPTED');
        }
    };

    const handleDecline = () => {
        onClose();
        setOpenRemarks(true);
    };

    const handleRemarksSubmit = async () => {
        if (!remarks) {
            Swal.fire('Error', 'Please provide remarks before declining.', 'error');
            return;
        }

        await callApi('REJECTED', { remarks });
        setOpenRemarks(false);
        setRemarks('');
    };

    const handleRemarksClose = () => {
        setOpenRemarks(false);
        setRemarks('');
    };

    if (!details) return null;

    const renderDetailRow = (key, value) => (
        <>
            <Box className={classes.detailRow}>
                <Typography variant="body1" className={classes.detailKey}>{key}:</Typography>
                <Typography variant="body1">{value}</Typography>
            </Box>
            <Divider />
        </>
    );

    return (
        <>
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
                <DialogTitle>Customer Details</DialogTitle>
                <DialogContent>
                    <Card>
                        <CardContent className={classes.cardContent}>
                            {renderDetailRow('Expiry Date', moment(details.cprExp).format('DD/MM/YYYY'))}
                            {renderDetailRow('Date of Birth', moment(details.dob).format('DD/MM/YYYY'))}
                            {renderDetailRow('Nationality', details.nationality)}
                            <Typography variant="h6" className={classes.title}>Shufti Data:</Typography>
                            <Box display="flex" flexWrap="wrap" justifyContent="center">
                                {details.proofsImgFront && (
                                    <CardMedia
                                        className={classes.media}
                                        image={details.proofsImgFront}
                                        title="Proof Front Image"
                                    />
                                )}
                                {details.proofsImgBack && (
                                    <CardMedia
                                        className={classes.media}
                                        image={details.proofsImgBack}
                                        title="Proof Back Image"
                                    />
                                )}
                                {details.proofsImgFace && (
                                    <CardMedia
                                        className={classes.media}
                                        image={details.proofsImgFace}
                                        title="Face Image"
                                    />
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button
                        onClick={onClose}
                        className={classes.button}
                        sx={{
                            backgroundColor: '#0082dc',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#0292f5',
                            }
                        }}
                    >
                        Close
                    </Button>
                    {details.status === "PENDING" && (
                        <>
                            <Button
                                onClick={handleApprove}
                                disabled={details.status === "ACCEPTED"}
                                // color="primary"
                                sx={{
                                    backgroundColor: '#139e00',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#17ba00',
                                    }
                                }}
                                className={classes.button}
                            >
                                {details.status === "ACCEPTED" ? "Approved" : "Approve"}
                            </Button>
                            <Button
                                onClick={handleDecline}
                                disabled={details.status === "REJECTED"}
                                // color="secondary"
                                sx={{
                                    backgroundColor: '#f93500',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#f90000',
                                    }
                                }}
                                className={classes.button}
                            >
                                {details.status === "REJECTED" ? "Declined" : "Decline"}
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>

            <Dialog open={openRemarks} onClose={handleRemarksClose} fullWidth maxWidth="sm">
                <DialogTitle>Decline Request</DialogTitle>
                <DialogContent className={classes.remarksDialog}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="remarks"
                        label="Remarks"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleRemarksClose} c
                        // olor="primary"
                        sx={{
                            backgroundColor: '#f93500',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#f90000',
                            }
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleRemarksSubmit}
                        // color="secondary"
                        sx={{
                            backgroundColor: '#139e00',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#17ba00',
                            }
                        }}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CustomerDetailsModal;
