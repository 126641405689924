import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as XLSX from 'xlsx';
import swal from 'sweetalert';
import { Visibility } from '@material-ui/icons';


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const ExcelPopup = ({ setSheet, count ,data,setData,handleCheckXlx}) => {
  let exelData
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [data, setData] = useState([]);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName,setFileName] = useState("")


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFileUpload = (event) => {
    setPage(0);
    const file = event.target.files[0];
    setFileName(file.name)
    setSheet(file)
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Data = e.target.result.split(',')[1]; // get the base64 data from the result
      const binaryData = atob(base64Data); // decode the base64 data into binary data
      const workbook = XLSX.read(binaryData, { type: 'binary' }); // read the binary data as an Excel workbook
      const sheetName = workbook.SheetNames[0];
      // setSheet(workbook.Sheets[sheetName]);
      const sheet = (workbook.Sheets[sheetName]);
      const options = { includeEmpty: true };
      exelData = XLSX.utils.sheet_to_json(sheet, options);
      setData(exelData)
      console.log(data);
      setFileData(exelData.slice(0, 100)); // set only the first 3 rows of data
      console.log(fileData);
      setFileUploaded(true)
      
    };
    reader.readAsDataURL(file); // read the file as a data URL instead of binary data
  };

  const checkXlx = () => {
    // Find empty columns
    // for (let i = 0; i < headerRow.length; i++) {
    // const columnData = data.map(row => row[headerRow[i]]);
    //   console.log(data.length !== 143, headerRow[0]);
    //   const isEmptyColumn = columnData.every(cellValue => cellValue === '' || cellValue === undefined);
    //   if (isEmptyColumn) {
    //     emptyColumns.push(headerRow[i]);
    //   }
    // }

    // const emptyColumns = [];

    // Find duplicate values in the Serial Number column
    // const serialNumberColumnData = data.map(row => row['serialNo']);
    // const duplicateSerialNumbers = serialNumberColumnData.filter((value, index, self) => {
    //   return self.indexOf(value) !== index;
    // }).reduce((acc, curr) => {
    //   if (!acc[curr]) {
    //     acc[curr] = serialNumberColumnData.indexOf(curr);
    //   }
    //   return acc;
    // }, {});
    // console.log(Object.entries(duplicateSerialNumbers).length );

    // console.log( "duplicate", serialNumberColumnData);
    if (data.length == 0) {
      swal("Oops!", "Please Enter Atleast 1 serialNo", "error");
    } else {
      const headerRow = Object.keys(data[0]);

      if (headerRow[0] !== 'serialNo') {
        swal("Oops!", "Download above sample Exel file Or add key as serialNo", "error");
      } else if (Number(count) !== data.length) {
        swal("Oops!", "count and Exel column Miss Match", "error");
      } else {
        swal({
          title: "Success",
          text: "Success",
          icon: "success",
          button: "OK",

        })
      }
    }
    // else if(Object.entries(duplicateSerialNumbers).length !== 0){
    //   swal("Oops!", `Duplicate Serial Numbers Found: ${Object.entries(duplicateSerialNumbers).map(([key, value]) => `${key} (at row ${value + 1})`).join(", ")}`, "error");


    // }




  }


  return (
    <div>

      {/* <input type="file"  onChange={handleFileUpload} /> */}
      <Button
        variant="contained"
        color="primary"
        component="label"
        onChange={handleFileUpload}
      >
        Upload File
        <input
          type="file"
          accept=".xls,.xlsx"
          hidden
        />
      </Button>
      <span style={{ margin: "0 10px" }}></span>
      <Button
        variant="contained"
        component="label"
        onClick={handleCheckXlx}
        disabled={!fileUploaded||!handleCheckXlx}

      >
        Check File

      </Button>
      <span style={{ margin: "0 10px" }}></span>


      <Tooltip title="Show Exel File">
      <IconButton disabled={!fileUploaded} onClick={()=>setOpen(true)}>
        <Visibility />
      </IconButton>
      </Tooltip>



      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Excel Sheet</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {fileData.length > 0 &&
                    Object.keys(fileData[0]).map((key) => (
                      <TableCell key={key} align="center">
                        {key}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {fileData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow key={index}>
                    {Object.values(row).map((cell, index) => (
                      <TableCell key={index} align="center">
                        {cell}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={fileData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExcelPopup;


