
import * as React from 'react';
import axios from "axios";
import config from "config.js"
import Collapse from "@material-ui/core/Collapse";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Dropzone from "react-dropzone";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import moment from 'moment';
import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";
import download from "js-file-download";


const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
}));


export default function StudentList(props) {
    const classes = useStyles();
    const [listView, setListView] = React.useState(0); //0:-loader , 1:-student table
    const [respAlert, setRespAlert] = React.useState(null);
    const [students, setStudents] = React.useState([])

    const columns = [
        {
            field: "updatedAt",
            title: "Date",
            render: rowData => moment(rowData.createdAt).format("DD/MM/YYYY") != "Invalid date" ? <span> {moment(rowData.createdAt).format("DD/MM/YYYY") + " " + moment(rowData.createdAt).format("hh:mm:ss A")}</span> : rowData.time,
        },
        {
            field: "register_number",
            title: "Register No",
        },
        {
            field: "name",
            title: "Name",
            width: 500
        },
        {
            field: "cpr",
            title: "CPR",
        },
        {
            field: "class",
            title: "Class",
        },
        {
            field: "contact_no",
            title: "Contact",
            render: rowData => rowData.contact ? rowData.contact.toString() : "",
        },
        {
            field: "parent_name",
            title: "Parent Name",
            width: 500
        },
        {
            field: "parent_cpr",
            title: "Parent CPR",
        },
        {
            field: "branch_name",
            title: "Branch",
        },
        {
            field: "amount",
            title: "Amount",

        },
        {
            field: "status",
            title: "Status",
            render: rowData => rowData.paymentStatus ? "Paid" : "Not Paid",

        },
    ];


    const options = {
        showTitle: false,
        actionsColumnIndex: -1,
        exportButton: false,
    };

    const fetchStudents = async (id) => {
        //function to fetch students 
        try {
            let resp = await axios.post(`${config.url().baseURL}/fetch-students`, { school: id })
            if (resp.data.err) {
                props.snack(resp.data.msg)
                setListView(1)
            } else {
                setListView(1)
                setStudents(resp.data.data)
            }
        } catch (error) {
            props.back()
            props.snack("Unable to fetch student list.")
        }
    }

    React.useEffect(() => { if (props.id) fetchStudents(props.id) }, [props.id])

    const uploadNewStudentList = async (doc) => {
        //function to upload excel file
        if (/\.(xlsx|xls|csv)$/i.test(doc[0].name) === true) {
            if (doc[0].size < 2097152) {
                setRespAlert(
                    <SweetAlert
                        warning
                        input
                        style={{ display: "block" }}
                        title="Are you sure?"
                        onConfirm={() => {
                            submitDoc(doc[0])
                        }}
                        onCancel={() => {
                            setRespAlert(null)
                        }} Data
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnText="No"
                        cancelBtnCssClass={classes.button}
                        confirmBtnCssClass={classes.button + " " + classes.danger}
                    >
                        New file will erase all existing data for this school, Do you really want to continue?
                    </SweetAlert>)

            } else {
                props.snack("File size is too large, Maximum file size is 2 Mb.");
            }
        } else {
            props.snack("Please upload xlx files.");
        }
    }

    const submitDoc = async (doc) => {
        //function to upload doc to the server 
        setRespAlert(null)
        let requestBody = new FormData();
        requestBody.set('file', doc);
        requestBody.set('school', props.id);
        try {
            setListView(0)
            let resp = await axios.post(`${config.url().baseURL}/update-student-details`, requestBody)
            if (resp.data.err) {
                setListView(1)
                props.snack(resp.data.msg)
                setListView(1)
            } else {
                fetchStudents(props.id)
                props.snack(resp.data.msg, true)
            }
        } catch (error) {
            console.log(error)
            props.snack("Unable to upload.")
        }
    }

    const downloadSampleStudentList = async () => {
        try {
            let resp = await axios.get(`${config.url().baseURL}/download-sample-student-list`, { responseType: "blob" })
            download(resp.data, "SampleStudentList.xls")
        } catch (error) {
            props.snack("Unable to download sample student list.")
        }
    }
    return (
        <>
            {respAlert}
            <Collapse
                in={listView == 0}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
            </Collapse>
            <Collapse
                in={listView == 1}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Students</h4>
                        <div className={classes.addButton}>
                            <Dropzone onDrop={uploadNewStudentList}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Button size="sm" fullWidth color="secondary">
                                            New Student List
                                        </Button>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <MaterialTable components={{ Container: (props) => <Paper {...props} elevation={0} /> }} data={students} columns={columns} options={options} />
                    </CardBody>
                </Card>
                <Button color="primary" simple onClick={downloadSampleStudentList} size="sm">
                    Download Sample FIle
                </Button>
                <Button color="primary" onClick={props.back} size="sm">
                    Back
                </Button>
            </Collapse>
        </>
    )
}