import React from "react";
import axios from "axios";
import config from "config.js"
import MUIDataTable from "mui-datatables";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { creatCsvFile, downloadFile } from "download-csv";
import { useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import LorEditForm from "./Components/LorEditForm.js"
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import TimelineIcon from '@mui/icons-material/Timeline';
import moment from 'moment'
import DialogTitle from "@material-ui/core/DialogTitle";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "components/CustomButtons/Button.js";
import MuiTable from '@material-ui/core/Table';

const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    statusHead: {
        marginBottom: "0px !important",
    },
    statusText: {
        color: "#000 !important",
    },
    ...modalStyle(theme),
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function Departments() {
    const user = useSelector(state => state.user);
    const classes = useStyles();

    const logColumns = [
        { id: 'date', label: 'Date', width: 70 },
        { id: 'updatedAt', label: 'Time', width: 70 },
        { id: 'user', label: 'User' },
        { id: 'status', label: 'Status', width: 70 },
        { id: 'status_message', label: 'Status Info', minWidth: 170 },
        // { id: 'remarks', label: 'Remarks', minWidth: 170 },
    ];

    const columns = [
        {
            name: "no",
            label: "#",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "companyName",
            label: "Company Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        // {
        //     name: "status",
        //     label: "Status",
        //     options: {
        //         filter: true,
        //         sort: true,
        //     },
        // },
        {
            name: "kycStatus",
            label: "KYC Status",
            options: {
                filter: true,
                sort: true,
            },
        },

        {
            name: "kycAppID",
            label: "KYC Application ID",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
            },
        }
    ];
    const options = {
        selectableRows: false,
        elevation: 0,
        print: false,
        onDownload: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = "departments.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
    };

    const [respAlert, setRespAlert] = React.useState(null);
    const [showEditForm, setShowEditForm] = React.useState(false);
    const [showDeptTable, setShowDeptTable] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(true);

    const [corporateData, setCorporateData] = React.useState([]);
    const [showCorporateTable, setShowCorporateTable] = React.useState(false);
    const [corpID, setCorpID] = React.useState(null);
    const [status, setStatus] = React.useState("");
    const [editDepartmentStatus, setEditDepartmentStatus] = React.useState("");
    const [csvData, setCsvData] = React.useState([]);

    const [onBoardingLogs, setOnBoardingLogs] = React.useState([]);
    const [onBoardingLogModal, setOnBoardingLogModal] = React.useState(false);

    //state for child



    const openSnackBar = (msg) => {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    };

    const hideAlert = () => {
        setRespAlert(null);
    };

    const closeModal = () => {
        //function to delete edit modal
        setShowEditForm(false)
        setCorpID(null)
    }

    const openFeeEditForm = (id, status) => {
        //function to open edit fee modal
        setShowEditForm(true)
        setCorpID(id)
        setStatus(status)
    }
    React.useEffect(() => {
        fetchCorporateData()
    }, []);

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="down" ref={ref} {...props} />;
    });


    const fetchCorporateData = () => {
        axios
            .get(`${config.url().baseURL}/get-edit-lor-companies`, {
                headers: {
                    auth: sessionStorage.getItem("cx_id"),
                },
            }).then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    let service = user.services.find(service => service.name == "Corporate LOR Edit")
                    var updatedData = []
                    let no = 1
                    respData.data.map((corp, key) => {
                        try {
                            if (corp.KYC.status == "APPROVED" && corp.Ledger.customerId) {
                                corp.no = no;
                                corp.companyID = corp.incorp_details.comm_reg_id
                                // corp.status = corp.logData[0].log[corp.logData[0].log.length - 1].status;
                                corp.kycStatus = corp.KYC.status;
                                corp.kycAppID = corp.KYC.ApplicationID;

                                corp.actions = (
                                    <>
                                        <Tooltip placement="top" title="View LOR">
                                            <IconButton
                                                onClick={() => openFeeEditForm(corp._id, corp.logData[0].log[corp.logData[0].log.length - 1].status)}
                                                //  onClick={()=>alert("clicked")}
                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                aria-label="delete">
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip placement="top" title="Added LOR">
                                            <IconButton
                                                onClick={() => fetchAddLor(corp._id)}
                                                style={{ marginTop: "10px", marginBottom: "10px" }}
                                                aria-label="delete">
                                                <TimelineIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                );
                                updatedData.push(corp)
                                no++

                                var updatedCsvData = []
                                respData.data.map((corp) => {
                                    if (corp.logData[0].log[corp.logData[0].log.length - 1].status == "Approved" && corp.KYC.status == "APPROVED" && corp.Ledger.customerId) {
                                        let temp = {};
                                        temp.Company_ID = corp._id;
                                        temp.Company_Name = corp.companyName;
                                        // temp.Contact_Person = corp.contact_details.fullName;
                                        // temp.Contact_Person_Title = corp.contact_details.title;
                                        // temp.Contact_Person_Mobile = corp.contact_details.mobile;
                                        // temp.Contact_Person_Email = corp.contact_details.email;

                                        updatedCsvData.push(temp)
                                    }
                                });

                                setCsvData(updatedCsvData);
                            }
                        } catch (error) {
                            console.log(error)
                            console.log(corp.companyName)
                        }

                    });
                    setCorporateData(updatedData);
                    setShowLoader(false);
                    setShowCorporateTable(true);

                } else {
                    setCorporateData([]);
                    setShowLoader(false);
                    setShowCorporateTable(true);
                    openSnackBar(respData.msg);
                }
            }).catch((err) => {
                console.log(err)
                openSnackBar("Unable to fetch Corporate data.");
            });
    };

    const fetchAddLor = (_id) => {
        try {
            axios.get(`${config.url().baseURL}/get-corporate-lor-edit-logs/${_id}`).then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    if (respData?.log) {
                        setOnBoardingLogs(respData.log);
                        setOnBoardingLogModal(true)

                    } else {
                        setOnBoardingLogModal(false);
                        openSnackBar("No Log found.")
                    }
                } else {
                    setOnBoardingLogModal(false);
                    openSnackBar(respData.msg);
                }

            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            {respAlert}
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showCorporateTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Corporate LOR Edit</h4>
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={corporateData} columns={columns} options={options} />
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>
            <Dialog
                classes={{
                    root: classes.left + " " + classes.modalRoot,
                    paper: classes.companyStatusLogModal,
                }}
                open={onBoardingLogModal}
                // TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setOnBoardingLogModal(false);
                    setOnBoardingLogs([]);
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>GPW user status log</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <TableContainer className={classes.container}>
                        <MuiTable stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {logColumns.map((column) => (
                                        <TableCell
                                            key={column._id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {onBoardingLogs.map((row, key) => {
                                    let status = row.status
                                    // if (row.status == "Step One Completed") {
                                    //     status = "First Level Form Completed"
                                    // } else if (row.status == "Step Two completed") {
                                    //     status = "Second Level Form Completed"
                                    // } else {
                                    //     status = row.status
                                    // }
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                                            <TableCell>
                                                {moment(row.date).format("DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell>
                                                {moment(row.date).format("HH:mm:ss")}
                                            </TableCell>
                                            <TableCell>
                                                {row.user ? row.user.firstName + " " + row.user.lastName : null}
                                            </TableCell>
                                            <TableCell>
                                                {status}
                                            </TableCell>
                                            <TableCell>
                                                {row.status_message}
                                            </TableCell>
                                            {/* <TableCell>
                                                {row.remarks}
                                            </TableCell> */}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </MuiTable>
                    </TableContainer>
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setOnBoardingLogModal(false);
                            setOnBoardingLogs([]);
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.transactionsModal, }}
                open={showEditForm}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    closeModal()
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
                    <LorEditForm close={closeModal} status={status} corpID={corpID} setShowEditForm={setShowEditForm} />
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                </DialogActions>
            </Dialog> */}

            <Dialog
                open={showEditForm}
                onClose={closeModal}
                classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.transactionsModal, }}>

                  <DialogContent >
                  <LorEditForm close={closeModal} status={status} corpID={corpID} setShowEditForm={setShowEditForm} />
                    </DialogContent>  
                  

            </Dialog>
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    );
}
