import { Collapse } from "@mui/material";
import React, { useState } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import axios from "axios"
import config from "config.js";
import libs from "controllers/libs";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiAlert from "@material-ui/lab/Alert";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from "components/CustomButtons/Button.js";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import InputBase from "@material-ui/core/InputBase";
const override = css`
display: block;
margin: 0px;
border-color: red;
`;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    dialogContainer: {
        display: "flex",
    },
    input: {
        flex: 1,
        marginTop: "10px",
        height: "45px",
        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    remarksHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
}));
export default function CorporateCardFee(props) {
    const classes = useStyles();
    const [view, setView] = useState(false); //0=loader 

    const [snackType, setSnackType] = useState("error");
    const [snackMsg, setSnackMsg] = useState("")
    const [openSnack, setOpenSnack] = React.useState(false);

    const [corporateID, setCorporateID] = useState("")

    const [newTabibFee, setNewTabibFee] = useState("");
    const [submitting, setSubmitting] = useState(false)
    const [respAlert, setRespAlert] = React.useState(null);
    const [tabibSubmitting, setTabibSubmitting] = useState(false);
    const [cardStatus, setCardStatus] = useState("")
    const [remarks, setRemarks] = useState()
    const [referenceId, setReferenceId] = useState("")
    const [statusMsg, setStatusMsg] = useState()
    const reference = React.useRef("")
    const [editFlag, setEditFlag] = useState(false)

    React.useEffect(() => {
        if (props.corpID !== null) {
            setCorporateID(props.corpID)
            // setNewTabibFee(props.tabiibFee)
            // setCardStatus(props.cardStatus)
            // setRemarks(props.remark)
            // setStatusMsg(props.statusMsg)
            // setReferenceId(props.referenceId)
            fetchFeeDetails(props.corpID)
        }
    }, [props.corpID]);
    React.useEffect(() => {
        if(editFlag == true){
        setNewTabibFee(props.tabiibFee)
        setCardStatus(props.cardStatus)
        setRemarks(props.remark)
        setStatusMsg(props.statusMsg)
        setReferenceId(props.referenceId)
    }
    }, [editFlag])

    const openSnackBar = (msg, success) => {
        setOpenSnack(true);
        if (success) {
            setSnackType("success")
        }
        else {
            setSnackType("error")
        }
        setSnackMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false)
            setSnackMsg("");
        }, 5000);
    }
    const fetchFeeDetails = (id) => {
        //function to fetch company details
        axios.post(`${config.url().baseURL}/get-card-fee-details/${id}`, { "type": "tabiib" }).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                if (respData.data && Object.keys(respData.data).length === 0) {
                    setNewTabibFee(props.tabiibFee)
                    setCardStatus(props.cardStatus)
                    setRemarks(props.remark)
                    setStatusMsg(props.statusMsg)
                    setReferenceId(props.referenceId)
                }else{
                let updatedData = []
                respData.data.map((corp) => {
                    corp.card_fee_status = corp.cardLogs.length != 0 ? corp.cardLogs[0].log.status : "notcreated";
                    corp.cardFeeStatusMsg = corp.cardLogs.length != 0 ? corp.cardLogs[0].log.status_message : "notcreated"
                    updatedData.push(corp)
                })
                // (updatedData && updatedData[0]) ? updatedData[0].feeReference : ""
                setNewTabibFee((updatedData && updatedData[0]) ? updatedData[0].feeAmount : "")
                setCardStatus((updatedData && updatedData[0]) ? updatedData[0].card_fee_status : "")
                reference.current = (updatedData && updatedData[0]) ? updatedData[0].feeReference : ""
                setReferenceId(reference);
                setRemarks(updatedData && updatedData[0] ? updatedData[0].cardFeeRemarks : "")
                setStatusMsg((updatedData && updatedData[0]) ? updatedData[0].cardFeeStatusMsg : "");

            } }else {
                openSnackBar(respData.msg);
                closeModal()
            }
        }).catch((err) => {
            // closeModal()
            setEditFlag(true)
            // openSnackBar("Unable to process.");
        });

    };
    const submitTabiibFee = async () => {
        //Function to submit Tabib Card fee
        if (newTabibFee.length == 0) openSnackBar("Please enter new mdc card fee")
        else {
            try {
                setTabibSubmitting(true);
                let resp = await axios.post(`${config.url().baseURL}/create-card-fees/${corporateID}`, {
                    "type": "tabiib",
                    "amount": newTabibFee,
                    "status": cardStatus,
                    // "status_message": statusMsg,
                    // "remarks": remarks,
                    "feeReference": referenceId.current

                })
                setTabibSubmitting(false);
                if (resp.data.err) openSnackBar(resp.data.msg)
                else {
                    setRespAlert(
                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title="MDC Card updated successfully"
                            onConfirm={() => {
                                setSubmitting(false)
                                setRespAlert(null)
                                closeModal()
                            }}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                    );
                }
            } catch (error) {
                setTabibSubmitting(false)
                openSnackBar("unable to update the fee");
                closeModal();
            }
        }

    }
    const closeModal = () => {
        //function to close modal
        setNewTabibFee("")
        setSubmitting(false)
        // setOpenFeeTabiibFeeEdit(false);
        props.close()
    }
    return (
        <div className={classes.root}>
            {respAlert}
            <Collapse
                in={view}
                style={{ transformOrigin: "0 0 0", textAlign: "center", height: "100px" }}
                {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
            </Collapse>
            <h4 className={classes.remarksHead}>Please enter new MDC Card fee.</h4>
            <Paper className={classes.dialogContainer}>
                <InputBase
                    autoFocus={true}
                    fullWidth
                    className={classes.input}
                    placeholder="MDC Card Fee"
                    defaultValue={newTabibFee}
                    inputProps={{
                        onBlur: (e) => setNewTabibFee(e.target.value.trim()),
                        onChange: (e) => { setNewTabibFee(libs.validate(e.target.value, "float", 9) ? e.target.value : newTabibFee) },
                        value: newTabibFee,
                    }}
                />
                <Button className={classes.nextButton}
                    onClick={() => submitTabiibFee()}
                    simple
                    disabled={tabibSubmitting}
                    size="lg" color="primary">
                    <ArrowForwardIcon fontSize="medium" />
                </Button>
            </Paper>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackMsg("");
                    }}
                    severity={snackType}>
                    {snackMsg}
                </Alert>
            </Snackbar>
        </div>)
}