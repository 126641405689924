import React from "react";
import config from "config.js"
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/core";
// import PropagateLoader from "react-spinners/PropagateLoader";
import Divider from "@material-ui/core/Divider";
import Paper from '@material-ui/core/Paper';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import moment from "moment";
import JSONPretty from 'react-json-pretty';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Button } from "@material-ui/core";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { IconButton } from "@material-ui/core";
import { width } from "@mui/system";


const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    overflowX: "scroll"
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,

    };
}
const useStyles = makeStyles((theme) => ({

    root: {
        backgroundColor: theme.palette.background.paper,
        width: "1500px",
    },
    tabContent: {
        maxHeight: "60vh",
        overflowX: "scroll"

    },
    logPaper: {
        padding: "15px",

    },




}));

export default function FullWidthTabs(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [logData, setLogData] = React.useState([]);
    const [noLogData, setNoLogData] = React.useState(false);
    const [netSysPageNo, setNetSysPageNo] = React.useState(0);
    const [sadadPageNo, setSadadPageNo] = React.useState(0);
    const [showLoadMore, setShowLoadMore] = React.useState(true);

    const [userId, setUserId] = React.useState('');
    //   const [pageno, setPageno] = React.useState('');
    const [logId, setLogId] = React.useState('')
    const logRef = React.useRef(null);


    const handleChange = (event, newValue) => {
        console.log(newValue);
        setValue(newValue);
        console.log(newValue)
        getLogs(newValue, props.userId, props.logId)
    };

    const handleChangeIndex = (index) => {
        setValue(index);

    };
    const copyToClipboard = (e) => {

        navigator.clipboard.writeText(JSON.stringify({

            url: e.url_called,

            request: e.request,

            response: e.response

        }));

    };

    function openSnackBar(msg) {
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }
    function parseNestedJSONString(jsonString) {

        try {

            const parseNested = (str) => {

                let result;

                try {

                    result = JSON.parse(str);

                } catch (error) {

                    if (typeof str === 'string' && str.startsWith('"') && str.endsWith('"')) {

                        return parseNested(str.slice(1, -1));

                    } else {

                        return str;

                    }

                }



                if (typeof result === 'string') {

                    return parseNested(result);

                }



                if (typeof result === 'object') {

                    for (let key in result) {

                        result[key] = parseNested(result[key]);

                    }

                }



                return result;

            };



            return parseNested(jsonString);

        } catch (error) {

            console.error('Error parsing JSON string:', error);

            return null;

        }

    }
    const copyToClipboardNet = (e) => {
        navigator.clipboard.writeText(JSON.stringify({
            url: e.url,
            request: e.request,
            response: e.response
        }));
    };


    const copyToClipboardNew = (e) => {

        navigator.clipboard.writeText(JSON.stringify({

            url: e.url_called ? e.url_called : e.url ? e.url : "",

            request: parseNestedJSONString(e.request),

            response: parseNestedJSONString(e.response)

        }, null, 4));

    };


    React.useEffect(() => {
        if (props.userId !== null) {
            getLogs(props.userId, props.logId)
            setValue(0)
            setLogData([]);


        } else {
            setLogData([]);

        }

    }, [props.userID]);

    const fetchLog = (userId, logId) => {
        let data = {
            userId: userId,
            logId: logId,
        }
        try {
            axios.post(`${config.url().baseURL}/get-audit-log-details`, data).then((resp) => {
                let respData = resp.data;
                if (!respData?.err) {
                    if (respData.data !== undefined) {
                        setLogData(respData.data);
                        setUserId();
                        setLogId()
                    } else {
                        setLogData(true)
                        // setAuditLogModel(false);
                        openSnackBar("No AUditLog");

                    }

                } else {
                    //   setShowAuditLogModel(false);
                    setShowLoadMore(false)
                    openSnackBar(respData.msg);
                }
            });
        } catch (error) {
        }

    };



    const getLogs = (userId, logId) => {
        //function to get logs
        setNetSysPageNo(0)
        setShowLoadMore(true)
        fetchLog(userId, logId)


    }


    return (
        <div className={classes.companyLogModal}>
            <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none', display: 'block' }}>
                <div style={{ overflowX: 'auto' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        aria-label="full width tabs example"
                    >
                        <Tab label="Details" {...a11yProps(0)} />

                        {
                            props.userType == "Shufti Initiated Signup" || props.userType == "Initiate" ? (
                                <Tab label="Sign up " {...a11yProps(1)} />
                            ) : null
                        }
                    </Tabs>
                </div>

            </AppBar>
            <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                className={classes.tabContent}
                overflowX="scroll"
                onChangeIndex={handleChangeIndex}>
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <Collapse
                        in={noLogData}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Log Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!noLogData}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={logData !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            {/* <PropagateLoader color="#004fa4" loading={true} css={override} size={10} /> */}
                        </Collapse>
                        <Collapse
                            in={logData !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                            {...(true ? { timeout: 500 } : {})}>

                            <div>
                                <paper>
                                    {logData?.method ? (
                                        <>
                                            Method : {logData.method}
                                            <br />
                                        </>
                                    ) : null}
                                    {logData?.url ? (
                                        <>
                                            URL : {logData.url}
                                            <br />
                                        </>
                                    ) : null}
                                    {logData?.auditLogMsg ? (
                                        <>
                                            auditLogMsg : {logData.auditLogMsg}
                                            <br />
                                        </>
                                    ) : null}
                                    {logData.req ? (
                                        <Accordion  >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Typography>Request</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails  >
                                                <div style={{ width: "750px !important" }}>
                                                    <JSONPretty data={logData.req} />
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    ) : null}
                                    {logData.response ? (
                                        <Accordion  >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Typography>Response</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails  >
                                                <div style={{ width: "750px !important" }}>
                                                    <JSONPretty data={JSON.parse(logData.response)} />
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    ) : null}
                                </paper>
                            </div>

                        </Collapse>
                    </Collapse>
                </TabPanel>
            </SwipeableViews>
        </div >
    );
}
