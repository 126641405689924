import React from 'react';
import axios from "axios";
import config from "config.js"
import moment from "moment";
import PropagateLoader from "react-spinners/PropagateLoader";
import BounceLoader from "react-spinners/BounceLoader";
import SweetAlert from "react-bootstrap-sweetalert";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InputBase from "@material-ui/core/InputBase";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import MuiAlert from "@material-ui/lab/Alert";
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MuiTable from '@material-ui/core/Table';
import MUIDataTable from "mui-datatables";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PreviewIcon from '@mui/icons-material/Preview';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TimelineIcon from '@material-ui/icons/Timeline';
import CustomerDetails from "views/Customers/Components/CustomerDetails.js"
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import styles from "assets/jss/material-dashboard-react/views/customersStyle.js";
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import libs from 'controllers/libs';
import Swal from "sweetalert2";

const useStyles = makeStyles(styles);

const override = css`
display: block;
margin: 0px;
border-color: red;
`;

const columns = [
    {
        name: "date",
        label: "Date",
    },
    {
        name: "cpr",
        label: "CPR",
    },
    {
        name: "mobile",
        label: "Mobile",
    },
    {
        name: "userType",
        label: "User Type",
    },
    {
        name: "status",
        label: "Status",
    },
    {
        name: "actions",
        label: "Actions",
    },
];


const options = {
    selectableRows: false,
    elevation: 0,
    print: false,
    download: false,
    textLabels: {
        body: {
            noMatch: "No matching records found",
        },
    }
};

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const logColumns = [
    { id: 'date', label: 'Date', width: 70 },
    { id: 'time', label: 'Time', width: 70 },
    { id: 'user', label: 'User' },
    { id: 'status', label: 'Status', width: 70 },
    { id: 'description', label: 'Status_Message', width: 100 },
    { id: 'remarks', label: 'Remarks', minWidth: 170 },
];


export default function CustomerBlock() {
    const classes = useStyles();
    const [searchKey, setSearchKey] = React.useState("");
    const [submitting, setSubmitting] = React.useState(false);
    const [respAlert, setRespAlert] = React.useState(null);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [openSnack, setOpenSnack] = React.useState(false);

    const [showLoader, setShowLoader] = React.useState(true);
    const [showCustomersTable, setShowCustomersTable] = React.useState(false);
    const [customersTable, setCustomersTable] = React.useState(null);
    const [showDetails, setShowDetails] = React.useState(false);
    const [detailsView, setDetailsView] = React.useState(null);
    const [remarks, setRemarks] = React.useState("")
    const [openRemarks, setOpenRemarks] = React.useState(false)
    const [result, setResult] = React.useState()
    const [status, setStatus] = React.useState(null)
    const [customerCPR, setCustomerCPR] = React.useState(null)

    const [editLogModal, setEditLogModal] = React.useState(false);
    const [editLogs, setEditLogs] = React.useState([]);
    const [submittingStatusChange, setSubmittingStatusChange] = React.useState(false);

    function openSnackBar(msg) {
        //function to tost snackbar message 
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    React.useEffect(async () => {
        fetchCustomers();
        setSearchKey("")
    }, []);

    const searchCustomer = () => {
        //function to search customer
        if (searchKey.length !== 0) {
            axios.get(`${config.url().baseURL}/search-block-customer/${searchKey}`).then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    setCustomerList(respData.data)
                } else {
                    openSnackBar(respData.msg);
                }
            }).catch((err) => {
                fetchCustomers()
                openSnackBar("Unable to process");

            });
        }
    }

    const resetSearch = () => {
        //function to reset search
        setSearchKey("")
        fetchCustomers()
    }

    const fetchCustomers = () => {
        //function to fetch customers list
        setRespAlert(null)
        setDetailsView(null)
        setCustomerCPR(null)
        setOpenRemarks(false)
        setRemarks("")
        setSubmittingStatusChange(false)
        axios.get(`${config.url().baseURL}/get-block-customers`).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                setCustomerList(respData.data)
                setShowLoader(false);
                setShowCustomersTable(true);
            } else {
                setShowLoader(false);
                setShowCustomersTable(true);
                openSnackBar(respData.msg);
            }
        }).catch((err) => {
            setShowLoader(false);
            setShowCustomersTable(true);
            console.log(err)
            openSnackBar("Unable to process");
        });
    }

    const setCustomerList = async (data) => {
        //function to ser customer list
        let updatedCustomers = []
        let user = await libs.checkAccessRight("Block Customer")
        Promise.all(data.map((customer) => {
            updatedCustomers.push({
                date: moment(customer.updatedAt).format("DD/MM/YYYY"),
                mobile: customer.mobile,
                cpr: customer.cpr,
                userType: customer.userType,
                userID: customer.userID,
                status: customer.blocked,
                id: customer._id,
                actions: <>
                    <Tooltip placement="top" title="Log">
                        <IconButton
                            onClick={() => fetchLog(customer._id)
                            }
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="Shufti log">
                            <TimelineIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip placement="top" title="View ">
                        <IconButton
                            onClick={() => fetchUserDetails(customer.userID)}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="view">
                            <PreviewIcon />
                        </IconButton>
                    </Tooltip>
                    {
                        user.type == "maker" ?
                            customer.blocked == "Blocked" ?
                                <Tooltip placement="top" title="Unblock">
                                    <IconButton
                                        onClick={() => handleStatusChangeClick("Unblock", customer.cpr)}
                                        style={{ marginTop: "-10px", marginBottom: "-10px", color: "green" }}
                                        aria-label="view">
                                        <LockOpenIcon />

                                    </IconButton>
                                </Tooltip>
                                :
                                customer.blocked == "Unblocked" ?
                                    <Tooltip placement="top" title="Block">
                                        <IconButton
                                            disabled={submittingStatusChange}
                                            onClick={() => handleStatusChangeClick("Block", customer.cpr)}
                                            style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                                            aria-label="view">
                                            <LockIcon />
                                        </IconButton>
                                    </Tooltip>
                                    : null
                            : user.type == "checker" ?
                                <>
                                    <Tooltip placement="top" title="Approve">
                                        <IconButton
                                            onClick={() => handleStatusChangeClick("Approve", customer.cpr)}
                                            style={{ marginTop: "-10px", marginBottom: "-10px", color: "green" }}
                                            aria-label="view">
                                            <CheckCircleIcon />

                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip placement="top" title="Reject">
                                        <IconButton
                                            disabled={submittingStatusChange}
                                            onClick={() => handleStatusChangeClick("Reject", customer.cpr)}
                                            style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                                            aria-label="view">
                                            <BlockIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                                : null
                    }
                </>
            })
        }))
        setCustomersTable(<MUIDataTable data={updatedCustomers} columns={columns} options={options} />)
    }

    const fetchLog = (id) => {
        //function to fetch company logs
        axios.get(`${config.url().baseURL}/get-block-log/${id}`).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                setEditLogModal(true);
                if (respData.data.length !== 0) {
                    setEditLogs(respData.data);
                } else {
                    setEditLogModal(false);
                    openSnackBar("No log found.");
                }

            } else {
                setEditLogModal(false);
                openSnackBar(respData.msg);
            }
        });
    };

    const fetchUserDetails = (id) => {
        //function to fetch details of a customer
        setShowDetails(true);
        setShowCustomersTable(false)
        setDetailsView(<CustomerDetails id={id} snack={openSnackBar} back={fetchCustomers} disableLock={true} />)
    };

    const handleStatusChangeClick = (status1, cpr) => {
        //function to open remarks for freeze or unfreeze
        setStatus(status1)
        setCustomerCPR(cpr)
        if (status1 === "Block") {
            const { value: reasonForBlocking } = Swal.fire({
                title: 'Select the Reason',
                input: 'select',
                inputOptions: {
                    SUSPECIOUS_TRANSACTION_OR_FRAUDLENT_ACTIVITY: 'SUSPECIOUS TRANSACTION/FRAUDLENT ACTIVITY',
                    DISPUTE_RECEIVED: 'DISPUTE RECEIVED',
                    DISPUTE_RAISED_ON_ANOTHER_CPR: 'DISPUTE RAISED ON ANOTHER CPR',
                    CUSTOMER_REQUEST: 'CUSTOMER REQUEST',
                    ON_HOLD_BY_OPS: 'ON HOLD BY OPS',
                    CORPORATE_REQUEST: 'CORPORATE REQUEST'

                },
                inputValidator: (value) => {
                    return new Promise((resolve, reject) => {
                        resolve();

                    })
                },
                inputPlaceholder: 'Select the reason',
                showCancelButton: true,
                showConfirmButton: true,

            })
                .then((result) => {
                    if (result.isConfirmed) {
                        setOpenRemarks(true);
                        setResult(result.value)
                        // changeUserStatus(cpr, status1, result.value)
                    }
                })
        }
        else if (status1 === "Unblock") {
            const { value: reasonForBlocking } = Swal.fire({
                title: 'Select the Reason',
                input: 'select',
                inputOptions: {
                    DISPUTE_RESOLVED: 'DISPUTE RESOLVED',
                    CUSTOMER_REQUESTED: 'CUSTOMER REQUESTED',
                    ACCOUNT_UNFREEZE_BY_OPS: "ACCOUNT UNBLOCK BY OPS",
                    DEBT_RECOVERED_FROM_CUSTOMER: 'DEBT RECOVERED FROM CUSTOMER',
                    REQUEST_FROM_CORPORATE_TO_UNFREEZE: 'REQUEST FROM CORPORATE TO UNBLOCK'
                },
                inputValidator: (value) => {
                    return new Promise((resolve, reject) => {
                        resolve();

                    })
                },
                inputPlaceholder: 'Select the reason',
                showCancelButton: true,
                showConfirmButton: true,

            })
                .then((result) => {
                    if (result.isConfirmed) {
                        // changeUserStatus(cpr, status1, result.value)
                        setOpenRemarks(true);
                        setResult(result.value)

                    }
                })
        }
        else { setOpenRemarks(true) }
    }

    const changeUserStatus = async () => {
        //function to change user status of user (block or unblock)
        try {
            if (remarks.trim() !== "" && remarks.trim() !== undefined) {
                if (!submittingStatusChange) {
                    let resp
                    switch (status) {
                        case "Block":
                            resp = await axios.post(`${config.url().baseURL}/block-customer/${customerCPR}`, { remarks: remarks, description: result })
                            setSubmittingStatusChange(true)
                            break;
                        case "Unblock":
                            resp = await axios.post(`${config.url().baseURL}/unblock-customer/${customerCPR}`, { remarks: remarks, description: result })
                            setSubmittingStatusChange(true)
                            break;
                        default:
                            break;
                    }
                    if (resp.data.err) {
                        setRemarks("")
                        setOpenRemarks(false)
                        setCustomerCPR(null)
                        openSnackBar(resp.data.msg)
                        setSubmittingStatusChange(false)
                    }
                    else {
                        setRespAlert(
                            <SweetAlert
                                success
                                style={{ display: "block" }}
                                title={resp.data.msg}
                                onConfirm={() => fetchCustomers()}
                                confirmBtnCssClass={classes.button + " " + classes.success}
                            />
                        );
                    }
                }
            } else {
                openSnackBar("Remarks Field should not be empty")
            }
        } catch (error) {
            console.log(error)
            openSnackBar("Unable to change status of user.");
        }
    }


    const approveRejectBlock = async () => {
        //function to approve block or unblock user
        if (remarks.trim() !== "" && remarks.trim() !== undefined) {
            if (status === "Approve") {
                if (!submittingStatusChange) {
                    await axios.post(`${config.url().baseURL}/approve-block-customer/${customerCPR}`, { remarks: remarks })
                        .then((res) => {
                            if (!res.data.err) {
                                setSubmittingStatusChange(true)
                                setRespAlert(
                                    <SweetAlert
                                        success
                                        style={{ display: "block" }}
                                        title={res.data.msg}
                                        onConfirm={() => fetchCustomers()}
                                        confirmBtnCssClass={classes.button + " " + classes.success}
                                    />
                                );
                            }
                            else {
                                setRemarks("")
                                setOpenRemarks(false)
                                setCustomerCPR(null)
                                openSnackBar(res.data.msg)
                                setSubmittingStatusChange(false)
                            }
                        }).catch((err) => {
                            openSnackBar("unable to Proceed")
                            setRemarks("")
                            setOpenRemarks(false)
                            setCustomerCPR(null)
                            setSubmittingStatusChange(false)
                            console.log(err)
                        })

                }

            }
            else {
                if (!submittingStatusChange) {
                    await axios.post(`${config.url().baseURL}/reject-block-customer/${customerCPR}`, { remarks: remarks })
                        .then((res) => {
                            if (!res.data.err) {
                                setSubmittingStatusChange(true)
                                setRespAlert(
                                    <SweetAlert
                                        success
                                        style={{ display: "block" }}
                                        title={res.data.msg}
                                        onConfirm={() => fetchCustomers()}
                                        confirmBtnCssClass={classes.button + " " + classes.success}
                                    />
                                );
                            }
                            else {
                                setRemarks("")
                                setOpenRemarks(false)
                                setCustomerCPR(null)
                                openSnackBar(res.data.msg)
                                setSubmittingStatusChange(false)
                            }
                        }).catch((err) => {
                            openSnackBar("unable to Proceed")
                            setRemarks("")
                            setOpenRemarks(false)
                            setCustomerCPR(null)
                            setSubmittingStatusChange(false)
                            console.log(err)
                        })

                }

            }
        }
        else {
            openSnackBar("Please enter remarks")
            setSubmittingStatusChange(false)
        }
    }


    return (
        <>
            {respAlert}
            <Collapse in={showLoader} style={{ transformOrigin: "0 0 0", textAlign: "center" }} {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
            </Collapse>
            <Collapse in={showCustomersTable} style={{ transformOrigin: "0 0 0", textAlign: "center" }} {...(true ? { timeout: 500 } : {})}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={6}>
                        <Paper elevation={1} className={classes.addDeptContainer}>
                            <InputBase
                                autoFocus={false}
                                fullWidth
                                type='search'
                                    className={classes.input}
                                placeholder="Search"
                                inputProps={{
                                    autoComplete:"off",
                                    autoFocus: "off",
                                    "aria-label": "Search",
                                    onChange: (event) => { setSearchKey(event.target.value) },
                                    onBlur: (event) => { setSearchKey(event.target.value.trim()) },
                                    value: searchKey,
                                    onKeyDown: (e) => {
                                        if (e.key == "Enter") {
                                            searchCustomer()
                                        }
                                    }
                                }}
                            />
                            {!submitting ? (
                                <Button className={classes.addDeptButton}
                                    onClick={() => searchCustomer()}
                                    size="lg" color="primary">
                                    <SearchIcon fontSize="medium" />
                                </Button>
                            ) : (
                                <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
                            )}
                        </Paper>
                        <Tooltip placement="bottom" title="Reset">
                            <IconButton className={classes.addDeptButton}
                                onClick={() => resetSearch()}
                                size="lg" color="primary">
                                <RotateLeftIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardBody>
                                {customersTable}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </Collapse>
            <Collapse in={showDetails} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 500 } : {})}>
                {detailsView}
            </Collapse>
            <Dialog
                classes={{
                    root: classes.left + " " + classes.modalRoot,
                    paper: classes.companyStatusLogModal,
                }}
                open={editLogModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setEditLogModal(false);
                    setEditLogs([]);
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>Block Log</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <TableContainer className={classes.container}>
                        <MuiTable stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {logColumns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {editLogs.map((row, key) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                                            <TableCell>
                                                {moment(row.date).format("DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell>
                                                {moment(row.date).format("hh:mm:ss A")}
                                            </TableCell>
                                            <TableCell>
                                                {row.user ? row.user : null}
                                            </TableCell>
                                            <TableCell>
                                                {row.status}
                                            </TableCell>
                                            <TableCell>
                                                {row.description}
                                            </TableCell>
                                            <TableCell>
                                                {row.remarks}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </MuiTable>
                    </TableContainer>
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setEditLogModal(false);
                            setEditLogs([]);
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog onClose={() => {
                setOpenRemarks(false)
                setRemarks("")
            }} aria-labelledby="simple-dialog-title" PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }} open={openRemarks}>
                <h4 className={classes.passwordHead}>Please enter remarks.</h4>
                <Paper className={classes.dialogContainer}>
                    <InputBase
                        autoFocus={true}
                        fullWidth
                        multiline={true}
                        minRows="3"
                        className={classes.inputPassword}
                        placeholder="Remarks"
                        inputProps={{
                            type: "text",
                            onChange: (event) => { setRemarks(event.target.value) },
                            value: remarks,
                            onBlur: (e) => {
                                setRemarks(e.target.value)
                            }
                        }}
                    />

                    <Button className={classes.nextButton}
                        onClick={() => {
                            if (status === "Approve" || status === "Reject") {
                                approveRejectBlock()
                            }
                            else {

                                changeUserStatus()
                            }
                        }
                        }
                        simple
                        size="lg" color="primary">
                        <ArrowForwardIcon fontSize="medium" />
                    </Button>

                </Paper>

            </Dialog>
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </>
    );
}