import React from 'react'; // Make sure to import React

import MainCard from './components/Main/MainCard';
import { W2wContextProvider, useW2wContext } from './Provider/W2wContext'

function TravelBuddyReport(props) {
    return (
        <W2wContextProvider>
          <MainCard/>
        </W2wContextProvider>
    );
}

export default TravelBuddyReport;
