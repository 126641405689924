import * as React from 'react';
import axios from "axios";
import MUIDataTable from "mui-datatables";
import Collapse from "@material-ui/core/Collapse";
import config from "config.js"
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import Tooltip from "@material-ui/core/Tooltip";
// import PreviewIcon from '@mui/icons-material/Preview';
import IconButton from "@material-ui/core/IconButton";
import moment from 'moment';
import { useSelector } from 'react-redux';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { creatCsvFile, downloadFile } from "download-csv";
import CustomInput from "components/CustomInput/CustomInput.js";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import LinearProgress from '@mui/material/LinearProgress';

import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Chip from "@material-ui/core/Chip";
import BounceLoader from "react-spinners/BounceLoader";
import SearchIcon from '@material-ui/icons/Search';
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import TextField from '@mui/material/TextField';
import { CardContent } from '@mui/material';
import { Stack } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import ReceiptIcon from '@mui/icons-material/Receipt';
import EditIcon from '@mui/icons-material/Edit';
import { Grid } from '@material-ui/core';
import "react-loader-spinner";
import { ThreeDots } from 'react-loader-spinner';
const override = css`
display: block;
margin: 32px auto;
border-color: red;
`;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },
    loaderWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Adjust the height as per your requirement
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
export default function CardsActivationsReport() {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [showLmraTable, setShowLmraTable] = React.useState(false)
    const [showLoader, setShowLoader] = React.useState(false)
    const [snackBarType, setSnackBarType] = React.useState("error");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [respAlert, setRespAlert] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const [fromDate, setFromDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
    const [toDate, setToDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
    const [lmraDetails, setLmraDetails] = React.useState([]);

    // const [showDetails, setShowDetails] = React.useState(false);
    const [transactionDetails, setTransactionDetails] = React.useState(null);

    const [showDetails, setShowDetails] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [csvData, setCsvData] = React.useState(false);
    const [familyDetails, setFamilyDetails] = React.useState([]);
    const [searchKey, setSearchKey] = React.useState("INDIVIDUAL");
    const [lmravalue, setLmraValue] = React.useState("");
    const [vendorRef, serVendorRef] = React.useState("")
    const [isLoading, setLoading] = React.useState(false);

    const [expanded, setExpanded] = React.useState();
    const columns = [
        {
            name: "appliedCpr",
            label: "CPR",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "createdAt",
            label: "Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "LMRACpr",
            label: "LMRA CPR",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "paymentStatus",
            label: "Payment Status",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "LMRAStatus",
            label: "LMRA Status",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "avenuesSettlementStatus",
            label: "Fossil Status",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "lmraTransactionID",
            label: "LMRA Reference ID",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "pgRef",
            label: "Payment Ref",
            options: {
                filter: true,
                sort: true,
            },
        },{
            name: "netsysRef",
            label: "Netsys Ref",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "Total_Amount",
            label: "Amount",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
            },
        }
    ];

    const options = {
        selectableRows: false,
        elevation: 0,
        print: false,
        onDownload: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = "LMRA Report.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        }
    };
    React.useEffect(() => {
        lmrsReportDetails()
        // setTransactionDetails([])
        // setMailID()
    }, []);
    const validateSearch = () => {
        //function validate search params
        let snackBarMsg = ""
        let flag = true
        if (fromDate.length == 0) {
            flag = false
            snackBarMsg = "Please select from date."
        }
        if (toDate.length == 0) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select To date." : snackBarMsg);
        }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }
    function openSnackBar(msg, success) {
        //function to tost snackbar message
        setOpenSnack(true);
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const msgSendLMRA = (id) => {
        // alert(id)
        setRespAlert(
            <SweetAlert
                warning
                style={{ display: "block" }}
                title="Do you want void Transaction ?"
                onConfirm={() => {
                    sendLMRAMessage(id)
                }}
                onCancel={() => {
                    setRespAlert(null)
                }}
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                cancelBtnCssClass={classes.button}
                confirmBtnCssClass={classes.button + " " + classes.danger} api
            >

            </SweetAlert>
        )
    }
    const sendLMRAMessage = (id) => {
        let data = {
            id: id,
        }
        axios.post(`${config.url().baseURL}/void-lmra-transaction`, data)
            .then((resp) => {
                let respData = resp.data
                if (!respData.err) {
                    setRespAlert(
                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title={respData.msg}
                            onConfirm={() => {
                                setRespAlert("");
                                lmrsReportDetails();
                            }}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                    );
                } else {
                    openSnackBar(respData.msg)
                    setRespAlert("")
                }
            })
    }
    const handleClickOpen = (vendorID) => {
        serVendorRef(vendorID)
        setOpen(true);


    };

    const handleClickClose = () => {
        setOpen(false);
    };



    const updateLMRAMessage = () => {

        if (lmravalue.trim() === "") {

            openSnackBar("Please enter LMRA reference ID");
            return;
        }

        setLoading(true);
        let data = {
            transactionID: vendorRef,
            lmraTransaction: lmravalue,
            // mobileNumber: mobileNum,
            // name: name,
        };

        axios
            .post(`${config.url().baseURL}/register-transaction`, data)
            .then((resp) => {

                let respData = resp.data;
                if (!respData.err) {
                    setRespAlert(
                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title={respData.msg}
                            onConfirm={() => {
                                setRespAlert("");
                                lmrsReportDetails();
                            }}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                    );
                    setOpen(false);
                    setLmraValue("");
                } else {

                }
                setLoading(false);
            })
            .catch((err) => {
                openSnackBar(err.msg);
                setRespAlert("");
                setOpen(false);
                setLmraValue("");
                setLoading(false);
            });

    };


    const lmrsReportDetails = () => {
        //function to fetch transactions 
        try {
            //checkUserType();
            if (validateSearch()) {
                let data = {
                    "fromDate": moment(fromDate).format("MM/DD/YYYY"),
                    "toDate": moment(toDate).format("MM/DD/YYYY"),
                }
                // setShowLoader(true);
                setSubmitting(true);
                setShowLmraTable(false);
                setLmraDetails([]);
                axios.post(`${config.url().baseURL}/get-lmra-report`, data)
                    .then((resp) => {
                        setSubmitting(false);
                        setShowLoader(false);
                        setShowLmraTable(true)
                        let resData = resp.data;
                        if (resData.err) {
                            openSnackBar(resp.data.msg)
                        }
                        else {
                            let updatedlmraDetails
                            let details

                            updatedlmraDetails = resp.data.data.map((activations) => {
                                let details = {}
                                details.appliedCpr = activations.appliedCpr ? activations.appliedCpr : ""
                                details.createdAt = moment(activations.createdAt).format("DD/MM/YYYY  hh:mm A")
                                details.LMRACpr = activations.LMRAData?.cpr ? activations.LMRAData.cpr : ""
                                details.paymentStatus = activations.payment?.status ? activations.payment.status : ""
                                if (details.paymentStatus) {
                                    details.paymentStatus = (
                                        <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />
                                    );
                                } else {
                                    details.paymentStatus = (
                                        <Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />
                                    );
                                }
                                details.LMRAStatus = activations?.LMRA?.status ? activations?.LMRA?.status : ""
                                if (details.LMRAStatus) {
                                    details.LMRAStatus = (
                                        <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />
                                    );
                                } else {
                                    details.LMRAStatus = (
                                        <Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />
                                    );
                                }
                                details.avenuesSettlementStatus = activations?.avenuesSettlement?.status ? activations?.avenuesSettlement?.status : ""
                                if (details.avenuesSettlementStatus) {
                                    details.avenuesSettlementStatus = (
                                        <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />
                                    );
                                } else {
                                    details.avenuesSettlementStatus = (
                                        <Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />
                                    );
                                    }
                                details.Total_Amount = activations.Total_Amount ? activations.Total_Amount : ""
                                details.pgRef = activations.paymentRef.pgRef ? activations.paymentRef.pgRef : ""
                                details.netsysRef = activations.paymentRef.netsysRef ? activations.paymentRef.netsysRef : ""
                                details.lmraTransactionID = activations.lmraTransactionID ? activations.lmraTransactionID : ""
                              
                                details.actions =

                                    <>
                                        {
                                            (activations?.payment?.status === true && (activations?.LMRA?.status === false && activations.avenuesSettlement.status === false)) ?
                                                <Tooltip placement='top' title="Void Transaction">
                                                    <IconButton
                                                        onClick={() => msgSendLMRA(activations._id)}
                                                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                        aria-label="view">
                                                        <ReceiptIcon />
                                                    </IconButton>
                                                </Tooltip> : ""
                                        }

                                        {
                                            (activations?.payment?.status === true && (activations?.LMRA?.status === false && activations.avenuesSettlement.status === false)) ?
                                                <Tooltip placement='top' title="Update LMRA">
                                                    <IconButton
                                                        onClick={() => handleClickOpen(activations.paymentRef.vendorRef)}
                                                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                        aria-label="view">
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip> : ""
                                        }
                                    </>
                                return details
                            })
                            setLmraDetails(updatedlmraDetails);
                            var downloadedData = resp.data.data.map((activations) => {
                                let temp = {};
                                temp.CPR = activations.appliedCpr;
                                temp.Date = moment(activations.createdAt).format("DD/MM/YYYY  hh:mm A")
                                temp.LMRACPR = activations.LMRAData.cpr;
                                temp.Payment_Status = activations?.payment?.status;
                                temp.LMRA_Status = activations?.LMRA?.status;
                                temp.LMRA_ReferenceID = activations.lmraTransactionID;
                                temp.Fossil_Status = activations?.avenuesSettlement?.status;
                                temp.Netsys_Ref = activations.paymentRef?.netsysRef;
                                temp.Amount = activations.Total_Amount;
                                return temp;
                            })
                            setCsvData(downloadedData);
                        }
                    }).catch((err) => {
                        console.log(err)
                        openSnackBar('Unable To Process !!')
                    })
            }
        } catch (error) {

            console.log(error)
            openSnackBar("Unable to fetch Activation Report.")
        }
    }
    // const openFamilyDetails = (familyMemberDetails) => {
    //     const fmData = familyMemberDetails.map((details) => {
    //         return details.name;
    //     })
    //     setShowDetails(true);
    //     setFamilyDetails(familyMemberDetails);
    // }
    const handleChangeExpanded = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <>

            {respAlert}
            <GridContainer justify='center'>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={true}
                        style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                        {...true ? { timeout: 500 } : {}}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>From Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setFromDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setFromDate("");
                                                    openSnackBar("Please enter a valid Date")
                                                } else {
                                                    setFromDate(event.target.value)
                                                }
                                            },
                                            value: fromDate,
                                        }}>
                                    </InputBase>
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>To Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setToDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setToDate("")
                                                    openSnackBar("Please enter valid date.")
                                                } else {
                                                    setToDate(event.target.value)
                                                }
                                            }, value: toDate,
                                        }}
                                    />
                                    {!submitting ? (
                                        <Button className={classes.addDeptButton}
                                            onClick={() => lmrsReportDetails()}
                                            size="lg" color="primary">
                                            <SearchIcon fontSize="medium" />
                                        </Button>
                                    ) : (
                                        <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
                                    )}
                                </Paper>
                            </GridItem>
                        </GridContainer>
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLmraTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>LMRA Report</h4>
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={lmraDetails} columns={columns} options={options} />
                                {/* <MaterialTable data={activationDetails} columns={columns} options={options} /> */}
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>

            <Dialog open={open} onClose={handleClickClose}>
                <div style={{ width: '33rem' }}>
                    <CardHeader>
                        <h4 style={{ marginBottom: "-30px" }}>Please Enter LMRA Reference ID</h4>
                    </CardHeader>
                    <CardContent>
                        <CustomInput
                            labelText={
                                <span>
                                    Reference ID
                                </span>
                            }
                            inputProps={{
                                autoComplete: "off",
                                value: lmravalue,

                                onChange: (e) => {
                                    setLmraValue(e.target.value)
                                }
                            }}
                            value={lmravalue}
                            id="name"
                            type="text"
                            formControlProps={{
                                fullWidth: true,
                            }}


                        />
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>

                            {isLoading ? <ThreeDots
                                height="90"
                                width="100"
                                radius="9"
                                color="#004fa4"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            /> :
                                <Stack direction='row' style={{ justifyContent: "center" }} >
                                    <div style={{ paddingTop: "3px", padding: "10px" }}>
                                        <Button style={{ backgroundColor: "red", height: "40px" }} onClick={handleClickClose} size="sm" >Cancel</Button>
                                        <Button style={{ backgroundColor: "Darkblue", height: "40px" }} onClick={updateLMRAMessage} size="sm">Submit</Button>
                                    </div>
                                </Stack>
                            }
                        </div>
                    </CardContent>
                </div>
            </Dialog>

            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.companyLogModal,
                }}
                open={showDetails}
                onClose={() => {
                    setShowDetails(false)
                    setTransactionDetails([])
                }}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>LMRA Card Details</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    {transactionDetails}
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setShowDetails(false);
                            //setTransactionDetails(null) 
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </>
    );

}

