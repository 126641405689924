const setCompanyID = (payload) => {
  return {
    type: "companyID",
    payload,
  };
};

const setCompanyInfo = (payload) => {
  return {
    type: "companyInfo",
    payload,
  };
};

const setOwnersAndCtrls = (payload) => {
  return {
    type: "ownersAndCtrls",
    payload,
  };
};

const setBusinessProfile = (payload) => {
  return {
    type: "businessProfile",
    payload,
  };
};

const setEditData = (payload) => {
  return {
    type: "editData",
    payload,
  };
};

export { setCompanyID, setCompanyInfo, setOwnersAndCtrls, setBusinessProfile, setEditData };
