
import * as React from 'react';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import SweetAlert from "react-bootstrap-sweetalert";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/customersStyle.js";
import config from "config.js"



export default function ControlledSwitches() {
    const [checked, setChecked] = React.useState(false);
    const [respAlert, setRespAlert] = React.useState();
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const useStyles = makeStyles(styles);
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const classes = useStyles();

    const hideAlert = () => {
        setRespAlert(null);
    };
    React.useEffect(() => {
        settingSwitchStatus()
    }, [])
    function openSnackBar(msg) {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }
    const settingSwitchStatus = () => {
        axios.get(`${config.url().baseURL}/GetOtpLoginStatus`).then((res) => {
            if (!res.data.err) {
                setChecked(res.data.data)
            }
            else {
                openSnackBar(res.data.err)
            }
        }).catch((err) => {
            openSnackBar("Unable to Process")
        })
    }
    const handleChange = (event) => {
        setChecked(event.target.checked);
        let flag = event.target.checked;
        console.log("OTP is disabled", checked)
        axios.post(`${config.url().baseURL}/SetOtpLoginStatus`, { flag: flag }).
            then((res) => {
                if (!res.data.err) {
                    setRespAlert(
                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title="Login with OTP Status Changed"
                            onConfirm={() => hideAlert()}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                    )
                }
                else {

                    openSnackBar(res.data.err)

                }
            })
            .catch((err) => {
                openSnackBar("Unble to disable Login with OTP for system User")

            })
    };

    return (
        <>
            <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </>
    );
}