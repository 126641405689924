
import * as React from 'react'
import axios from 'axios'
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import config from "config.js"
import Collapse from "@material-ui/core/Collapse";
import "react-awesome-lightbox/build/style.css";
import Table from "components/Table/Table.js";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "./styles.css";
import Skeleton from '@material-ui/lab/Skeleton';
import Magnifier from "react-magnifier";
import { primaryColor } from "assets/jss/material-dashboard-react.js";
import DatePicker from "./DatePicker"
import moment from 'moment';
import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux'; import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.js";
import SweetAlert from "react-bootstrap-sweetalert";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const override = css`
display: block;
margin: 32px auto;
border-color: red;
`;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "center",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    corpTopHead: {
        fontSize: "15px",
        color: primaryColor[0],
        fontWeight: "400",
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    topBackButton: {
        float: "right"
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    detailsContainer: {
        padding: "0px",
        paddingTop: "-20px",
        marinTop: "-10px",
        maxHeight: '75vh',
        overflow: 'auto',
        '&::-webkit-scrollbar-track': {
            width: "8px",
            backgroundColor: "#F5F5F5"
        },
        '&::-webkit-scrollbar': {
            width: "8px",
            backgroundColor: "#F5F5F5"
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: " 6px",
            backgroundColor: primaryColor[0]
        }
    },
    corpDetailsTableHead: {
        marginLeft: "10px",
        marginTop: "20px",
        marginBottom: "-30px",
        fontSize: "13px",
        fontWeight: "500",
        textTransform: "none"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),
}));

export default function CampaignDetailsView(props) {
    const user = useSelector(state => state.user);
    const classes = useStyles();
    const [corpDetails, setCorpDetails] = React.useState([]);
    const [corpDocs, setCorpDocs] = React.useState([]);
    const [companyName, setCompanyName] = React.useState([]);

    const [showImage, setShowImage] = React.useState(false);
    const [numPages, setNumPages] = React.useState(null);
    const [magnifierImage, setMagnifierImage] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [respAlert, setRespAlert] = React.useState(null);
    const [showActionBtn, setShowActionBtn] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(true);
    const [startDate, setStartDate] = React.useState(new Date())
    // let service = user.services.find(service => service.name == "Special Rates")
    const [endDate, setEndDate] = React.useState(new Date())
    const [approveBtn, setApproveBtn] = React.useState(null);
    const [rejectBtn, setRejectBtn] = React.useState(null);
    const [recheckBtn, setRecheckBtn] = React.useState(null);
    const [submitting, setSubmitting] = React.useState(false);
    const [status, setStatus] = React.useState("")
    const [remarks, setRemarks] = React.useState("")
    const [showRemarks, setShowRemarks] = React.useState(false);
    const [active, setActive] = React.useState(false)
    const [viewOnceMore, setViewOnceMore] = React.useState(false)

    

    React.useEffect(() => {
        if (props.companyId != "") {
            fetchCompanyDetails(props.companyId)
        }
    }, [props.companyId])
    const CampaignDate = (value) => {
        setStartDate(moment(value[0]).format("MM-DD-YYYY"))
        setEndDate(moment(value[1]).format("MM-DD-YYYY"))
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const closeModal = () => {
        props.back()
        setShowRemarks(false);
    }
    const fetchCompanyDetails = (id) => {
        //function to fetch campaign details
        setShowLoader(true);

        axios.get(`${config.url().baseURL}/get-campaign-details/${id}`)
            .then((resp) => {
                console.log(resp);
                let respData = resp.data;
                if (!respData.err) {
                    setActive(respData && respData.data[0] && respData.data[0].activeStatus ? respData.data[0].activeStatus : false)
                    setCorpDocs(respData && respData.data && respData.data[0].doc ? respData.data[0].doc : [])
                    setMagnifierImage(respData && respData.data && respData.data[0].doc ? respData.data[0].doc : null)
                    if (respData.data[0].doc) {
                        const getFileExtension = (filename) => filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
                        let extension = getFileExtension(respData.data[0].doc)
                        // respData.data[0].doc.substring(respData.data[0].doc.indexOf("/") + 1, respData.data[0].doc.indexOf(";base64"));
                        
                        if (extension == "pdf"||extension=="octet-stream") {
                            setShowImage(false)
                        } else {
                            setShowImage(true)
                        }
                    }
                    setCorpDetails(respData.data[0]);
                    let upDatedDate = []
                    let dateEdit = false
                    if (respData && respData.data.length != 0 && respData.data[0].startDate && respData.data[0].endDate) {
                        upDatedDate.push(respData.data[0].startDate, respData.data[0].endDate)

                        if (props.approvalEdit == true) { dateEdit = false }
                        else {
                            dateEdit = true
                        }
                        CampaignDate(upDatedDate)
                    }
                    let comInfo = respData?.data[0]?.companyInfo
                    setCompanyName([
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >{comInfo[0]?.companyName?'Name Of Company':"CPR"}</b>, ":", comInfo[0]?.companyName?comInfo[0]?.companyName:respData.data[0].cpr],
                        // [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >ERP NO </b>, ":", comInfo[0].ERP_NO ? comInfo[0].ERP_NO : "Not entered"],
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Campaign Created Date</b>, ":", (respData.data.length && respData.data[0].createdAt ? moment(respData.data[0].createdAt).format("DD/MM/YYYY") : "")],
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Special Fee</b>, ":", (respData.data[0].quoteType ? respData.data[0].quoteType : "")],
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Campaign Date</b>, ":", <DatePicker dateSetter={CampaignDate} FromTo={upDatedDate} flag={dateEdit} />],
                        respData.data[0].conditionToavail&&[<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >{"Conditional"}</b>, ":", respData.data[0].conditionToavail]


                    ])
                    setShowLoader(false);
                    checkUserType()

                }

            })
            .catch((err) => {
                console.log(err);
                setShowLoader(false);
                props.snack("Unable to process.");

            });

    };
    const rejectConfirmation = (rejectStatus) => {
        setStatus(rejectStatus)
        if (rejectStatus == "REJECTED") {
            setRespAlert(
                <SweetAlert
                    warning
                    style={{ display: "block" }}
                    title="Are you sure?"
                    onConfirm={() => {
                        setRespAlert(null);
                        setShowRemarks(true)
                    }}
                    onCancel={() => {
                        setRespAlert(null)
                    }}
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    cancelBtnCssClass={classes.button}
                    confirmBtnCssClass={classes.button + " " + classes.danger}
                >
                    Do you really want to reject this Campaign?
                </SweetAlert>)
        }
        else if (rejectStatus == "DISABLE") {
            setRespAlert(
                <SweetAlert
                    warning
                    style={{ display: "block" }}
                    title="Are you sure?"
                    onConfirm={() => {
                        setRespAlert(null);
                        setShowRemarks(true)
                    }}
                    onCancel={() => {
                        setRespAlert(null)
                    }}
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    cancelBtnCssClass={classes.button}
                    confirmBtnCssClass={classes.button + " " + classes.danger}
                >
                    Do you really want to disable this Campaign?
                </SweetAlert>)
        }
        else {
            setRespAlert(
                <SweetAlert
                    warning
                    style={{ display: "block" }}
                    title="Are you sure?"
                    onConfirm={() => {
                        setRespAlert(null);
                        setShowRemarks(true)
                    }}
                    onCancel={() => {
                        setRespAlert(null)
                    }}
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    cancelBtnCssClass={classes.button}
                    confirmBtnCssClass={classes.button + " " + classes.danger}
                >
                    Do you really want to enable this Campaign?
                </SweetAlert>)
        }

    }
    const checkUserType = () => {
        if (user.services !== undefined && user.services !== null && user.services.length !== 0) {
            let service = user.services.find(service => service.name == "Special Rates")
            if (service) {
                switch (service.type) {
                    case "maker":
                        setShowActionBtn(false);
                        break;
                    case "checker":
                        if (props.campaignStatus == "Created" || props.campaignStatus == "UPDATED" || props.campaignStatus == "CREATED" || props.campaignStatus == "Updated") {
                            setShowActionBtn(true);
                            setApproveBtn(<Button style={{ marginRight: "5px", marginLeft: "5px" }} color="primary" onClick={() => { setShowRemarks(true); setStatus("CHECKED") }}>Checked</Button>)
                            setRecheckBtn(<Button style={{ marginRight: "5px", marginLeft: "5px" }} color="danger" onClick={() => { setShowRemarks(true); setStatus("RECHECK") }}>Recheck</Button>)
                        }
                        else { setShowActionBtn(false) }
                        break;
                    case "checkerTwo":
                        setShowActionBtn(false);
                        break;
                    case "approver":
                        if (props.campaignStatus == "CHECKED") {
                            setShowActionBtn(true);
                            setApproveBtn(<Button style={{ marginRight: "5px", marginLeft: "5px" }} color="primary" onClick={() => { setShowRemarks(true); setStatus("APPROVED") }}>Approve</Button>)
                            setRejectBtn(<Button style={{ marginRight: "5px", marginLeft: "5px" }} color="danger" onClick={() => { rejectConfirmation("REJECTED") }}>Reject</Button>)
                            setRecheckBtn(<Button style={{ marginRight: "5px", marginLeft: "5px" }} color="danger" onClick={() => { setShowRemarks(true); setStatus("RECHECK") }}>Recheck</Button>)
                        } else { setShowActionBtn(false) }
                        break;
                    case "viewer":
                        setShowActionBtn(false);

                        break;

                    default:
                        setShowActionBtn(false);
                        break;
                }
            }
        }

    }

    const changeStatusRates = () => {

        if (remarks.trim() !== undefined && remarks.trim() != "") {
            if (props.superUser == true) {

                if (!submitting) {
                    setSubmitting(true)
                    axios.post(`${config.url().baseURL}/change-campaign-status`, { "campaignID": props.companyId, "remarks": remarks })
                        .then((res) => {
                            if (!res.data.err) {
                                if (props.campaignStatus == "APPROVED") {
                                    setRespAlert(
                                        <SweetAlert
                                            success
                                            style={{ display: "block" }}
                                            title="Disabled the Campaign"
                                            onConfirm={() => {
                                                setSubmitting(false)
                                                setRespAlert(null)
                                                setRemarks("")
                                                closeModal()
                                            }}
                                            confirmBtnCssClass={classes.button + " " + classes.success}
                                        />
                                    );
                                }
                                else {
                                    setRespAlert(
                                        <SweetAlert
                                            success
                                            style={{ display: "block" }}
                                            title="Enabled the Campaign"
                                            onConfirm={() => {
                                                setSubmitting(false)
                                                setRespAlert(null)
                                                setRemarks("")
                                                closeModal()
                                            }}
                                            confirmBtnCssClass={classes.button + " " + classes.success}
                                        />
                                    );
                                }
                            }
                            else {
                                props.snack(res.data.msg)
                                setSubmitting(false)
                                setRemarks("")
                            }
                        })
                        .catch((err) => {
                            setRespAlert(null)
                            setRemarks("")
                            setSubmitting(false)
                        })
                }
            }
            else {
                let sData = {
                    "campaignID": props.companyId,
                    "status": status,
                    "status_message": "approved",
                    "remarks": remarks,
                    "startDate": startDate,
                    "endDate": endDate
                }
                if (!submitting) {
                    setSubmitting(true)
                    axios.post(`${config.url().baseURL}/approve-campaign`, sData)
                        .then((res) => {
                            let resData = res.data
                            if (!resData.err) {
                                if (status == "RECHECK") {
                                    setRespAlert(
                                        <SweetAlert
                                            success
                                            style={{ display: "block" }}
                                            title="Rechecked"
                                            onConfirm={() => {
                                                setSubmitting(false)
                                                setRespAlert(null)
                                                setRemarks("")
                                                closeModal()
                                            }}
                                            confirmBtnCssClass={classes.button + " " + classes.success}
                                        />
                                    );
                                } else if (status == "APPROVED") {
                                    setRespAlert(
                                        <SweetAlert
                                            success
                                            style={{ display: "block" }}
                                            title="Approved by approver"
                                            onConfirm={() => {
                                                setSubmitting(false)
                                                setRespAlert(null)
                                                setRemarks("")
                                                closeModal()
                                            }}
                                            confirmBtnCssClass={classes.button + " " + classes.success}
                                        />
                                    );
                                } else if (status == "REJECTED") {
                                    setRespAlert(
                                        <SweetAlert
                                            success
                                            style={{ display: "block" }}
                                            title="Campaign is Rejected by approver"
                                            onConfirm={() => {
                                                setSubmitting(false)
                                                setRespAlert(null)
                                                setRemarks("")
                                                closeModal()
                                            }}
                                            confirmBtnCssClass={classes.button + " " + classes.success}
                                        />
                                    );
                                }
                                else {
                                    setRespAlert(
                                        <SweetAlert
                                            success
                                            style={{ display: "block" }}
                                            title="Approved by Checker"
                                            onConfirm={() => {
                                                setSubmitting(false)
                                                setRespAlert(null)
                                                setRemarks("")
                                                closeModal()
                                            }}
                                            confirmBtnCssClass={classes.button + " " + classes.success}
                                        />
                                    );
                                }
                                setRemarks("")

                            }
                            else {
                                if (resData.msg == "Cast to date failed for value \"NaN\" (type number) at path \"endDate\"") {
                                    props.snack("Please Enter Correct starting and Ending Dates of Campaign")
                                }
                                else {
                                    props.snack(resData.msg)
                                }
                                setRemarks("")
                                setShowRemarks(false)
                                setSubmitting(false)
                                setRespAlert(null)
                            }
                        })
                        .catch((err) => {
                            setRespAlert(null)
                            setRemarks("")
                            setShowRemarks(false)
                            setSubmitting(false)
                        })
                }
            }

        } else {
            props.snack("Enter the Remarks")
        }
    }
    const backToHome = () => {
        setSubmitting(false);
        // setCorpDocs([])
        // setCorpDetails([])
        // setStartDate("")
        // setEndDate("")
        // setCompanyName([])
        props.back()
    }
    return (
        <>{respAlert}
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{
                            transformOrigin: "0 0 0", textAlign: 'center'
                        }}
                        {...true ? { timeout: 500 } : {}}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <h6 className={classes.corpTopHead}>Campaign ID: {corpDetails.campaignID} </h6>
                        </GridItem>

                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <h6 className={classes.corpTopHead}>Campaign Name : {corpDetails.campaignName} </h6>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <Button
                                onClick={() => {
                                    backToHome()
                                }}
                                size="lg"
                                className={classes.topBackButton}
                                simple
                                color="primary">
                                <ArrowBackIcon />
                                Back
                            </Button>
                        </GridItem></GridContainer></GridItem>
                <GridItem xs={12} sm={12} md={8}>

                    <Card plain  >
                        <CardBody className={classes.detailsContainer}>
                            <Table tableData={companyName} />
                            {/* {showActionBtn ? (
                                <div style={{ textAlign: "center" }}>
                                    {recheckBtn}
                                    {approveBtn}
                                    {rejectBtn}
                                </div>
                            ) : null} */}
                            {props.superUser && props.campaignStatus != "REJECTED" ?
                                <div style={{ textAlign: "center" }}>
                                    {active ? <Button style={{ marginRight: "5px", marginLeft: "5px" }} color="danger" onClick={() => { rejectConfirmation("DISABLE") }}>Disable</Button> :
                                        <Button style={{ marginRight: "5px", marginLeft: "5px" }} color="primary" onClick={() => { rejectConfirmation("ENABLE") }}>Enable</Button>}
                                </div>
                                : ""}
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    {magnifierImage !== null ? (
                        <>
                            <h6 className={classes.imageName} >PROOF</h6>
                            {showImage ? (
                                <>
                                    <Magnifier src={magnifierImage} width="100%" />
                                </>
                            ) : (<>
                                <div style={{ textAlign: "center" }} >
                                    <Document
                                        file={magnifierImage}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page pageNumber={pageNumber} />
                                    </Document>
                                    <p>Page {pageNumber} of {numPages}</p>
                                    <Button size="sm" color="primary" onClick={() => {
                                        if (pageNumber < 2) {
                                            setPageNumber(1)
                                        } else {
                                            let newPageNumber = pageNumber - 1
                                            setPageNumber(newPageNumber)
                                        }
                                    }} >Pervious</Button>
                                    <Button size="sm" color="primary"
                                        onClick={() => {
                                            if (numPages > pageNumber) {
                                                let newPageNumber = pageNumber + 1
                                                setPageNumber(newPageNumber)
                                            } else {
                                                setPageNumber(numPages)
                                            }
                                        }}
                                    >Next</Button>
                                </div>
                            </>)}
                        </>
                    ) : (<>
                        <h6 className={classes.imageName} >No PROOF IS UPLOADED</h6>
                        <Skeleton variant="text" width={500} />
                        <Skeleton variant="react" height={500} />
                        <Skeleton variant="react" height={60} />

                    </>)}

                </GridItem>
            </GridContainer>
            <Dialog
                classes={{
                    root: classes.left + " " + classes.modalRoot,
                    paper: classes.modalMedium,
                }}
                open={showRemarks}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setShowRemarks(false);
                    setRemarks("");

                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>Please enter remarks</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBodyRemarks}>
                    <CustomInput
                        labelText="Remarks"
                        // error={remarksErr}
                        id="remarks"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            value: remarks,
                            onChange: (e) => {
                                setRemarks(e.target.value);
                                // setRemarksErr(false);
                            },
                        }}
                    />

                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setShowRemarks(false);
                            setRemarks("");
                        }}
                        simple
                        color="danger"
                        size="md">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            changeStatusRates()
                        }}
                        color="primary"
                        size="md">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}