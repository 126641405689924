import React, { useState } from 'react';
import { Button, Modal } from '@material-ui/core';
import PDFViewerComponent from './PDFViewerComponent';

const DocumentListComponent = ({ docs }) => {
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleViewDoc = (doc) => {
    setSelectedDoc(doc);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedDoc(null);
    setOpenModal(false);
  };

  return (
    <div className="document-list-container">
      <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
        {Object.entries(docs).map(([key, value]) => (
          <Button
            key={key}
            variant="contained"
            color="primary"
            onClick={() => handleViewDoc(value)}
          >
            View {key}
          </Button>
        ))}

      </div>
      <Modal open={openModal} onClose={handleCloseModal}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            outline: 'none',
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          {selectedDoc && (
            <PDFViewerComponent selectedFile={selectedDoc} fileSource="url" handleCloseModal={handleCloseModal} />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default DocumentListComponent;
