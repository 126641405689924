import { container, defaultFont, cardTitle, roseColor, grayColor } from "assets/jss/material-dashboard-react.js";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";

const productStyle = (theme) => ({
  ...SweetAlertStyle,
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "20vh",
    color: "#FFFFFF",
  },
  title: {
    ...defaultFont,
    color: "white",
    marginTop: "5vh",
    marginBottom: "30px",
    textAlign: "center",
  },
  description: {
    fontSize: "18px",
    color: "white",
    textAlign: "center",
  },
  cardTitleWhite: {
    ...cardTitle,
    color: "white !important",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  cardCategoryWhite: {
    color: "white",
    marginTop: "10px",
  },
  icon: {
    color: "white",
    margin: "10px auto 0",
    width: "100px",
    height: "100px",
    border: "1px solid " + grayColor[1],
    borderRadius: "50%",
    lineHeight: "140px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconWhite: {
    color: "white",
  },
  iconRose: {
    color: roseColor[0],
  },
  marginTop30: {
    marginTop: "30px",
  },
  root: {
    marginBottom: "5px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    height: "45px",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)",
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""',
    },
    "& footer li a,& footer li a:hover,& footer li a:active": {
      color: "#FFFFFF",
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
    },
  },
  backButton: {
    textTransform: "capitalize !important",
  },
  createLink: {
    fontWeight: "400",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default productStyle;
