import React from "react";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import BounceLoader from "react-spinners/BounceLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { creatCsvFile, downloadFile } from "download-csv";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";

const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const styles = {
  ...SweetAlertStyle,
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    float: "left",
    display: "inlineBlock",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  addButton: {
    float: "right !important",
    display: "inlineBlock",
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  addDeptContainer: {
    height: "49px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  addDeptButton: {
    marginRight: "-5px !important",
  },
  input: {
    flex: 1,
    height: "45px",
    marginLeft: "15px",
  },
  statusHead: {
    marginBottom: "0px !important",
  },
  statusText: {
    color: "#000 !important",
  },
};

const useStyles = makeStyles(styles);
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function Departments() {
  const classes = useStyles();
  const columns = [
    {
      name: "deptName",
      label: "Department Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "_id",
      label: "Department ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "created_date",
      label: "Date of creation",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "actions",
      label: " Actions",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];
  const options = {
    selectableRows: false,
    elevation: 0,
    onDownload: () => {
      let csvFile = creatCsvFile(csvData);
      let fileName = "departments.csv";
      downloadFile(csvFile, fileName);
      return false;
    },
  };

  const [respAlert, setRespAlert] = React.useState(null);
  const [showAddDepartment, setShowAddDepartment] = React.useState(false);
  const [showDeptTable, setShowDeptTable] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(true);
  const [heading, setHeading] = React.useState("Enter Department Name");
  const [buttonLabel, setButtonLabel] = React.useState("ADD");

  const [departmentName, setDepartmentName] = React.useState("");
  const [departments, setDepartments] = React.useState([]);
  const [editDepartmentID, setEditDepartmentID] = React.useState("");
  const [editDepartmentStatus, setEditDepartmentStatus] = React.useState("");
  const [csvData, setCsvData] = React.useState([]);

  const openSnackBar = (msg) => {
    //function to tost snackbar message
    setOpenSnack(true);
    setSnackBarMsg(msg);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  };

  const hideAlert = () => {
    setRespAlert(null);
  };

  const handleAddClick = () => {
    //function to handle add button click
    setShowAddDepartment(true);
    setShowDeptTable(false);
    setHeading("Enter Department Name");
    setButtonLabel("ADD");
  };

  const handleBackClick = () => {
    //function to handle back button click
    setShowDeptTable(true);
    setShowAddDepartment(false);
  };

  const handleEditClick = (id, name, status) => {
    //function to handle add button click
    setEditDepartmentID(id);
    setEditDepartmentStatus(status);
    setDepartmentName(name);
    setHeading("Edit Department");
    setButtonLabel("SAVE");
    setShowDeptTable(false);
    setShowAddDepartment(true);
  };
  const successAdd = () => {
    hideAlert();
    setShowAddDepartment(false);
    setShowDeptTable(true);
    setSubmitting(false);
    setDepartmentName("");
    fetchDepartments();
  };

  const submitDepartment = () => {
    //function to submit department
    if (departmentName.length >= 5) {
      if (buttonLabel === "ADD") {
        axios
          .post(
            "/api/create-department",
            {
              deptName: departmentName,
            },
            {
              headers: {
                auth: sessionStorage.getItem("cx_id"),
              },
            }
          )
          .then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
              setRespAlert(
                <SweetAlert
                  success
                  style={{ display: "block" }}
                  title="Department added successfully."
                  onConfirm={() => successAdd()}
                  confirmBtnCssClass={classes.button + " " + classes.success}
                />
              );
            } else {
              openSnackBar(respData.msg);
              setSubmitting(false);
            }
          })
          .catch((err) => {
            openSnackBar("Unable to process.");
          });
      } else {
        let url = "/api/edit-department/" + editDepartmentID;
        axios
          .put(
            url,
            {
              deptName: departmentName,
              status: editDepartmentStatus,
            },
            {
              headers: {
                auth: sessionStorage.getItem("cx_id"),
              },
            }
          )
          .then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
              setRespAlert(
                <SweetAlert
                  success
                  style={{ display: "block" }}
                  title="Department updated."
                  onConfirm={() => successAdd()}
                  confirmBtnCssClass={classes.button + " " + classes.success}
                />
              );
            } else {
              openSnackBar(respData.msg);
              setSubmitting(false);
            }
          })
          .catch((err) => {
            setSubmitting(false);
            openSnackBar("Unable to process.");
          });
      }
    } else {
      setSubmitting(false);
      openSnackBar("Department name must be 5 letters long.");
    }
  };

  React.useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = () => {
    //function to fetch departments data
    axios
      .get("/api/get-departments", {
        headers: {
          auth: sessionStorage.getItem("cx_id"),
        },
      })
      .then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          var updatedData = respData.data.map((dept) => {
            dept.actions = (
              <>
                <Tooltip placement="top" title="Edit">
                  <IconButton
                    onClick={() => handleEditClick(dept._id, dept.deptName, dept.status)}
                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                    aria-label="delete">
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </>
            );
            dept.created_date = moment(dept.created_date).format("DD/MM/YYYY");
            return dept;
          });
          setDepartments(updatedData);
          setShowLoader(false);
          setShowDeptTable(true);
          var updatedCsvData = respData.data.map((dept) => {
            let temp = {};
            temp.Department_Name = dept.deptName;
            temp.Department_ID = dept._id;
            temp.Created_On = dept.created_date
            temp.Status = dept.status;
            return temp;
          });
          setCsvData(updatedCsvData);
        } else {
          setDepartments([]);
          setShowLoader(false);
          setShowDeptTable(true);
          openSnackBar(respData.msg);
        }
      })
      .catch((err) => {
        setDepartments([]);
        setShowLoader(false);
        setShowDeptTable(true);
        openSnackBar("Unable to fetch user departments.");
      });
  };

  return (
    <div>
      {respAlert}
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showLoader}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
          </Collapse>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showDeptTable}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Departments</h4>
                <Button onClick={() => handleAddClick()} color="secondary" size="sm" className={classes.addButton}>
                  Add Department
                </Button>
              </CardHeader>
              <CardBody>
                <MUIDataTable data={departments} columns={columns} options={options} />
              </CardBody>
            </Card>
          </Collapse>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Collapse
            in={showAddDepartment}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <h3>{heading}</h3>
            <br />
            {buttonLabel === "SAVE" ? (
              <FormControl component="fieldset">
                <FormLabel className={classes.statusHead} component="legend">
                  Status
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="position"
                  onChange={(e) => setEditDepartmentStatus(e.target.value)}
                  value={editDepartmentStatus}
                  name="position"
                  defaultValue="top">
                  <FormControlLabel
                    className={classes.statusText}
                    value="Active"
                    control={<Radio color="primary" />}
                    label="Active"
                  />

                  <FormControlLabel
                    className={classes.statusText}
                    value="Inactive"
                    control={<Radio color="primary" />}
                    label="Inactive"
                  />
                </RadioGroup>
              </FormControl>
            ) : null}
            <Paper component="form" className={classes.addDeptContainer}>
              <InputBase
                autoFocus={true}
                fullWidth
                className={classes.input}
                placeholder="Department Name"
                inputProps={{
                  "aria-label": "department name",
                  onChange: (event) => {
                    if (event.target.value !== "") {
                      if (event.target.value.match(/^[a-zA-Z ]+$/)) {
                        setDepartmentName(event.target.value);
                      }
                    } else {
                      setDepartmentName(event.target.value);
                    }
                  },
                  value: departmentName,
                }}
              />
              {!submitting ? (
                <Button className={classes.addDeptButton} onClick={() => submitDepartment()} size="lg" color="primary">
                  {buttonLabel}
                </Button>
              ) : (
                <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
              )}
            </Paper>
            <Button
              onClick={() => {
                handleBackClick();
              }}
              size="lg"
              className={classes.backButton}
              simple
              color="primary">
              <ArrowBackIcon />
              Back
            </Button>
          </Collapse>
        </GridItem>
      </GridContainer>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
            setSnackBarMsg("");
          }}
          severity="error">
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
