import React from "react";
import config from "config.js"
import libs from "controllers/libs.js"
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import Button from "components/CustomButtons/Button.js";
import MaterialTable from "material-table";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import axios from "axios";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SweetAlert from "react-bootstrap-sweetalert";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Chip from '@mui/material/Chip';
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";



const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
  
`;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        textAlign: "center"
    },
    ...modalStyle(theme)
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


export default function FullWidthTabs(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [nameScreenData, setNameScreenData] = React.useState([]);
    const [wlScore, setWlScore] = React.useState("");
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [fullName, setFullName] = React.useState("");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [aliasData, setAliasData] = React.useState([]);
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [respAlert, setRespAlert] = React.useState(null);
    const [showKeywords, setShowKeyWords] = React.useState(false);
    const [keywords, setKeyWords] = React.useState([]);
    const [nationality, setNationality] = React.useState(null);
    const[showLoader,setShowLoader] = React.useState(false)


    var remarks = null

    const screeningOptions = {
        exportButton: false,
        showTitle: false,
        actions: true,
        actionsColumnIndex: -1,
        selection: props.whiteList ? true : false,
        rowStyle: rowData => {
            if (rowData.score >= 90) {
                return { backgroundColor: '#ffbaba' };
            }
            return {};
        }
    };

    const aliasOptions = {
        exportButton: false,
        showTitle: false,
        actionsColumnIndex: -1,
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const watchListColumns = [
        {
            field: "firstName",
            title: "firstName",
            options: {
                width: "auto",
                filter: true,
                sort: true,
            },
        },
        {
            field: "lastName",
            title: "Last Name",
            options: {
                width: "auto",
                filter: true,
                sort: true,
            },
        },
        {
            field: "dob",
            title: "D O B",
            options: {
                width: "auto",
                filter: true,
                sort: true,
            },
        },


        {
            field: "Uid",
            title: "Uid",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            field: "country",
            title: "Country of Residence",
            options: {
                filter: false,
                sort: false,
            },
        },

        {
            field: "keywords",
            title: "Keywords",
            options: {
                filter: false,
                sort: false,
                width: "auto",
            },
        },
        {
            field: "fullNameScore",
            title: "Name Score(%)",
            options: {
                width: "auto",
                filter: true,
                sort: true,
            },
        },
        {
            field: "partsScore",
            title: "Parts Score(%)",
            options: {
                filter: true,
                sort: true,

            },
        },
        {
            field: "score",
            title: "Score(%)",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    const aliasColumns = [
        {
            field: "Name",
            title: "Name",
            options: {
                filter: true,
                sort: true,
            },
        },


        {
            field: "fullNameScore",
            title: "Name Score(%)",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "partsScore",
            title: "Parts Score(%)",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "score",
            title: "Score(%)",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];
    const watchListActions = [
        {
            icon: "list_lt_icon",
            tooltip: 'View Alias list',
            onClick: (event, rowData) => setAlias(rowData.alias),
            position: "row"
        }
        ,
        // (rowData) => {
        //     return props.whiteList
        //         ? { icon: 'check_circle_icon', tooltip: 'White List', onClick: (event, rowData) => { confirmWhiteList(rowData.firstName, rowData.lastName, rowData.Uid) }, position: "row" }
        //         : null
        // }

    ]
    React.useEffect(() => {
        if (props.cpr !== null) {
            getNameScreenData(props.cpr)
        }
    }, [props.cpr]);

    const setAlias = (data) => {
        //function to set watch list data
        //  alert(JSON.stringify(data))
        let tempData = []
        if (data !== undefined) {
            data.map((ali) => {
                let data = {
                    Name: ali.Name,
                    fullNameScore: ali.matchDetails ? ali.matchDetails.fullNameScore : "",
                    partsScore: ali.matchDetails ? ali.matchDetails.partsScore : "",
                    score: ali.matchDetails ? (ali.matchDetails.score * 100).toFixed(2) : "",
                }
                tempData.push(data)
            })
        }
        setAliasData(tempData)
        setValue(1)
    }

    const getNameScreenData = (cpr) => {
        //function to fetch name screening data for a customer
        setNameScreenData([]);
        setSelectedUsers([])
        setWlScore('')
        setShowLoader(true)
        axios.get(`${config.url().baseURL}/get-wl-score/${cpr}`)
            .then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    setShowLoader(false)
                    let tempData = []
                    setFullName(respData.data.Name ? respData.data.Name : "")
                    setNationality(respData.data.Nationality ? libs.convertCountry(respData.data.Nationality, "name") : null)
                    if (respData.data.NameScreen !== undefined && respData.data.NameScreen.length !== 0) {
                        respData.data.NameScreen.map((per) => {
                            let data = {
                                firstName: per.Person.Names.firstName,
                                lastName: per.Person.Names.lastName,
                                dob: per.Person.AgeData.Dob,
                                fullNameScore: per.Person.Names.matchDetails ? (per.Person.Names.matchDetails.fullNameScore * 100).toFixed(2) : "",
                                partsScore: per.Person.Names.matchDetails ? (per.Person.Names.matchDetails.partsScore * 100).toFixed(2) : "",
                                score: per.Person.Names.matchDetails ? (per.Person.Names.matchDetails.score * 100).toFixed(2) : "",
                                Uid: per.Uid,
                                keywords: per.Details.Keywords.Keyword.length <= 1 ? per.Details.Keywords.Keyword[0] : <p style={{ minWidth: "200px" }}>{per.Details.Keywords.Keyword[0]} <a style={{ color: "#004fa4", fontSize: "12px" }} href="#" onClick={() => openKeyWordsModal(per.Details.Keywords.Keyword)}>View More</a> </p>,
                                country: per.Details.Countries.Country[0],
                                alias: per.Person.Names.Aliases.Alias
                            }
                            tempData.push(data)
                        })
                    }
                    setNameScreenData(tempData)
                    //alert(respData.data.WLScore)
                    setWlScore(`WL Score : ${respData.data.WLScore}`)
                } else {
                    setShowLoader(false)
                    openSnackBar("No Screening data found.")
                    // console.log("test")
                    // setNameScreenData([]);
                    // props.close()
                    closeModal()
                }
            })
            .catch((err) => {
                setShowLoader(false)
                    // openSnackBar("No Screening data found.")
                setNameScreenData([]);
                // props.close()
            });
    }

    const closeModal = () => {
        //function to close modal
        setWlScore("")
        setNameScreenData([])
        setAliasData([])
        props.close()
    }

    function openSnackBar(msg) {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }
    const handleRemarks = (remark) => {
        //function to set remarks 
        remarks = remark
    }
    const confirmWhiteList = (fname, lname, id, multiple) => {
        //function to confirm white listing
        if (!multiple) {
            let name = (fname ? (fname + " ") : "") + (lname ? lname : "");
            setRespAlert(
                <SweetAlert
                    warning
                    input
                    style={{ display: "block" }}
                    title="Are you sure?"
                    onConfirm={() => {
                        whiteListUsers(name, id, false)
                    }}
                    onCancel={() => {
                        setRespAlert(null)
                    }}
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    cancelBtnCssClass={classes.button}
                    confirmBtnCssClass={classes.button + " " + classes.danger}
                >
                    Do you really want to white list this user?
                    <CustomInput
                        labelText="Remarks"
                        id="remarks"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            default: "",
                            onChange: (e) => {
                                handleRemarks(e.target.value)
                            },
                        }}
                    />

                </SweetAlert>)
        } else {
            setRespAlert(
                <SweetAlert
                    warning
                    input
                    style={{ display: "block" }}
                    title="Are you sure?"
                    onConfirm={() => {
                        whiteListUsers(null, null, true)
                    }}
                    onCancel={() => {
                        setRespAlert(null)
                    }}
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    cancelBtnCssClass={classes.button}
                    confirmBtnCssClass={classes.button + " " + classes.danger}
                >
                    Do you really want to white list these {selectedUsers.length} users?
                    <CustomInput
                        labelText="Remarks"
                        id="remarks"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            default: "",
                            onChange: (e) => {
                                handleRemarks(e.target.value)
                            },
                        }}
                    />

                </SweetAlert>)
        }
    }

    const whiteListUsers = (name, id, multiple) => {
        //function to move user white listing
        if (remarks && (remarks.trim()).length !== 0) {
            setRespAlert(null);
            let data = { users: multiple ? selectedUsers : [{ name, uid: id }], type: props.type, typeID: props.id, cpr: props.cpr, remarks: remarks.trim() }
            axios.post(`${config.url().baseURL}/name-screen-white-list`, data).then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    setRespAlert(
                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title="White listed."
                            onConfirm={() => { setRespAlert(null); getNameScreenData(props.cpr); remarks = null }}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        >
                        </SweetAlert>
                    );
                } else {
                    setRespAlert(
                        <SweetAlert
                            danger
                            style={{ display: "block" }}
                            title={respData.msg}
                            onConfirm={() => { setRespAlert(null); remarks = null }}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                    );
                }
            }).catch((err) => {
                remarks = null
                openSnackBar("Unable to process.");
            });
        } else {
            openSnackBar("Please enter remarks.");
        }

    }

    const selectUsers = (rows) => {
        //function to handle selection of rows in name screening table
        let tempUsers = []
        Promise.all(rows.map((row) => {
            tempUsers.push({
                name: (row.firstName ? (row.firstName + " ") : "") + (row.lastName ? row.lastName : ""),
                uid: row.Uid
            })
        }))
        setSelectedUsers(tempUsers)
    }

    const openKeyWordsModal = (keywords) => {
        //function to open keyword modal
        setShowKeyWords(true)
        setKeyWords(keywords)
    }

    const closeKeyWordsModal = () => {
        //function close keywords modal
        setShowKeyWords(false)
        setKeyWords([])
    }
    return (
        <div className={classes.root}>
            {respAlert}
            <Collapse
                // in={wlScore == ""}
                in={showLoader}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
            </Collapse>
            <Collapse
                in={wlScore}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <p style={{ margin: "-200 !important" }}>
                    <b style={{ fontSize: "18px", color: "#004fa4" }}> Name : {fullName}&nbsp;&nbsp;|&nbsp;&nbsp;CPR : {props.cpr}&nbsp;&nbsp;| &nbsp;&nbsp;Date Of Birth : {moment(libs.convertDate(props.dob)).format("DD/MM/YYYY")} {nationality ? "| Nationality : " + nationality : null}</b>
                </p>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <MaterialTable
                            components={{
                                Container: (props) => <Paper {...props} elevation={0} />,
                            }}
                            data={nameScreenData}
                            columns={watchListColumns}
                            options={screeningOptions}
                            actions={watchListActions}
                            onSelectionChange={(rows) => selectUsers(rows)}
                        />
                        <br />
                        <Button color="danger" size="sm" onClick={() => props.close()}>close</Button>
                        {selectedUsers.length !== 0 ? <Button color="primary" onClick={() => confirmWhiteList(null, null, null, true)} size="sm">White List</Button> : null}
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <MaterialTable
                            components={{
                                Container: (props) => <Paper {...props} elevation={0} />,
                            }}
                            data={aliasData}
                            columns={aliasColumns}
                            options={aliasOptions}
                        />
                        <br />
                        <Button color="danger" size="sm" onClick={() => props.close()}>close</Button> &nbsp;
                        <Button color="primary" size="sm" onClick={() => { setValue(0) }}>Back</Button>
                    </TabPanel>

                </SwipeableViews>
            </Collapse>
            <Dialog
                classes={{
                    root: classes.left + " " + classes.modalRoot,
                    paper: classes.modal,
                }}
                open={showKeywords}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => { closeKeyWordsModal() }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    {
                        keywords.map((keyword) => { return (<Chip label={keyword} style={{ margin: "5px" }} variant="outlined" />) })
                    }
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button onClick={() => { closeKeyWordsModal() }} color="danger" size="sm">Close</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div >
    );
}
