import React, { useEffect, useState } from 'react';
import config from "config.js"
import Button from '@mui/material/Button';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "components/CustomInput/CustomInput.js";
import axios from 'axios'
import { CardMedia } from '@material-ui/core';

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import libs from 'controllers/libs';
//css for card header
const useStyles = makeStyles((them) => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "1px",
    textDecoration: "none",
    float: "left",
    display: "inlineBlock",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    }
  }
}))



export default function CategoryForm(props) {
  const classes = useStyles()
  const [name, setName] = useState(props.name)
  const [icon, setIcon] = useState(props.img)


  useEffect(() => {
    if(!props.name || !props.img){
      props.snack("")

    }

  })


  const validateForm = () => {
    let flag = true;
    let msg = ""
    let nameErr = false, iconErr = false
   
    if (!icon) {
      iconErr = true
      msg = 'Please enter Icon link'
      flag = false
    }
   
  
    if (!name) {
      nameErr = true
      msg = 'please enter name.'
      flag = false
    }
    if (nameErr || iconErr ) {
      flag = false

    }
    if (!flag) {
      props.snack(msg)
    }
    else {
      return flag
    }

  }


  const submitForm = async () => {
    try {
      if(validateForm()){
      let categoryDetails = { name: name, url: icon, _id: props._id }
      let resp = await axios.post(`${config.url().appURL}/CXPortal/v3/update-sadad-category`, categoryDetails)
      if (resp.data.err === false) {
        props.Alert('success', 'Success', "Updated")
      }
        else{
          props.snack(resp.data.msg)
        }
      }
    } catch (error) {
      props.snack("Unable to process.")
    }
  
  }


  return (
    <Card>
      <CardHeader color="primary">
        <GridItem xs={1} >
          <CardMedia
            style={{ marginTop: "20px" }}
            component="img"
            image={`${props.img}.png`}
          />

        </GridItem>
      </CardHeader>
      <CardBody>
        <GridContainer >

          <GridItem xs={12} sm={12} md={8}>
            <CustomInput
              labelText="Name"
              id="biller-name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                autoComplete: "off",
                value: name,
                  
                // onChange: (e) => {
                //   setName(libs.validate(e.target.value, "alphabet", 50) ? e.target.value : name)
                // },
                // onBlur: (e) => {
                //   setName(e.target.value.trim());
                // }
              }}
            />
          </GridItem>
          <GridItem xs={9} >
            <CustomInput
              labelText="Icon"
              id="icon-link"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                autoComplete: "off",
                value: icon,
                onChange: (e) => {
                  setIcon(libs.validate(e.target.value, "all", 100) ? e.target.value : icon)
                },
                onBlur: (e) => {
                  setIcon(e.target.value.trim());
                }
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <div style={{ textAlign: "center" }} >
              <Button onClick={props.onBack} variant="simple" color="primary" >
                Back
              </Button>
              <Button variant="contained" onClick={submitForm} >
                Save
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>


  );
}
