import * as React from "react";


import { css } from "@emotion/core";
import { styled } from '@mui/material/styles';
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
// import XlxUploadAndCheck from "./XlxUploadAndCheck";
import avatar from "assets/img/school.jpg";

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import MuiAlert from "@material-ui/lab/Alert";

import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import { Card, Collapse, FormHelperText, Switch } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import { PropagateLoader } from "react-spinners";
import CardBody from "components/Card/CardBody";
import { Button } from "@material-ui/core";
import axios from "axios";
import config from "config";
import libs from "controllers/libs.js"
import CardAvatar from "components/Card/CardAvatar";
import Dropzone from "react-dropzone";

const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;


// const uploadLogo = async (img) => {
//     //function to upload image 
//     if (/\.(jpe?g|png)$/i.test(img[0].name) === true) {
//         if (img[0].size < 2097152) {
//             libs.getBase64(img[0], (result) => { setLogo(result); });
//         } else {
//             props.snack("File size is too large, Maximum file size is 2 Mb.");
//         }
//     } else {
//         props.snack("Please upload image files.");
//     }
// }

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "center",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400",
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px",
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff",
    },
    indicator: {
        backgroundColor: "white",
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const OnboardBiller = (props) => {
    const [billerName, setbillerName] = React.useState("")
    const [category, setcategory] = React.useState("")
    const [group, setgroup] = React.useState("")
    const [crNumber, setCrNumber] = React.useState("")

    const [productName, setproductName] = React.useState("")
    const [issuerId, setissuerId] = React.useState("")
    const [issuerName, setissuerName] = React.useState("")
    const [logo, setLogo] = React.useState("")
    const [productCode, setproductCode] = React.useState("")
    const [values, setValues] = React.useState({ nameErr: null, });
    const [formView, setFormView] = React.useState(1); //0:-loader , 1:-school form
    const [isProducts, setIsProducts] = React.useState(false)
    const [flat, setFlat] = React.useState("")
    const [road, setRoad] = React.useState("")
    const [submitting, setSubmitting] = React.useState(false)
    const [block, setBlock] = React.useState("")
    const [building, setBuilding] = React.useState("")

    const [licence, setLicence] = React.useState("")
    const [moi, setMoi] = React.useState("")
    const [cr, setCr] = React.useState("")
    const [aggrement, setAggrement] = React.useState("")
    const [fileName, setFileName] = React.useState("")







    const validateFrom = () => {
        //function to validate form
        let nameErr = false, flag = true, msg = ""
        if (billerName.length == 0) {
            flag = false
            nameErr = true
            msg = "Please enter school name."
        }
        if (!flag) {
            props.snack(msg)
        }
        setValues({ ...values, nameErr })
        return flag
    }

    const handleDocumentChange = ()=>{

    }
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const submitData = async () => {
        //function to submit data
        setSubmitting(true)
        if (!validateFrom()) setSubmitting(false)
        else {
            try {
                let data = {
                    data: {
                        billerName,
                        category,
                        issuerId,
                        issuerName,
                        isProducts,
                        type: props.billerType
                    },
                    address: {
                        flat,
                        road,
                        block
                    },
                    documents: {
                        licence,
                        aggrement,
                        moi,
                        cr
                    }
    
                }
    
    
                const resp = await axios.post(`${config.url().app_URL}/bill/create-category`, data)
                console.log(resp);
                if (resp.data.status.err) {
                    props.snack(props.data.status.message)
                    setSubmitting(false)
                }
                else {
                    setSubmitting(false)
                    props.alert("success", "Biller data saved", resp.data.msg)
                }
            } catch (error) {
                console.log(error)
                setSubmitting(false)
                props.snack("Unable to save.")
            }
        }
    }

    return (
        <div>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    
                
                        <Card profile>
                        <CardAvatar profile>
                                
                                    
                                        <img src={logo ? logo : avatar} alt="..." /> :
                                        {/* <Dropzone onDrop={uploadLogo}>
                                           {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                                        <img src={logo ? logo : avatar} alt="..." />
                                                   </a>
                                              </div>
                                           )}
                                         </Dropzone> */}
                               

                            </CardAvatar>
                            
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            labelText="Biller Name"
                                            id="biller Name`"
                                            // error={values.nameErr}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                value: billerName,
                                                onChange: (e) => {
                                                    setbillerName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : billerName);
                                                    setValues({ ...values, nameErr: false })
                                                },
                                                onBlur: (e) => {
                                                    setbillerName(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            labelText="category                                            "
                                            id="category"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                value: category,
                                                onChange: (e) => {
                                                    setcategory(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : category);
                                                },
                                                onBlur: (e) => {
                                                    setcategory(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            labelText="Cr Number"
                                            id="crNumber"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                value: crNumber,
                                                onChange: (e) => {
                                                    setCrNumber(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : crNumber);
                                                },
                                                onBlur: (e) => {
                                                    setCrNumber(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            labelText="productName"
                                            id="productName"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                multiline: true,
                                                maxRows: 5,
                                                value: productName,
                                                onChange: (e) => {
                                                    setproductName(libs.validate(e.target.value, "alphanumeric", 500) ? e.target.value : productName);
                                                },
                                                onBlur: (e) => {
                                                    setproductName(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                    <CustomInput
                                            labelText="Issuer Id"
                                            id="productName"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                multiline: true,
                                                maxRows: 5,
                                                value: issuerId,
                                                onChange: (e) => {
                                                    setissuerId(e.target.value);
                                                },
                                                onBlur: (e) => {
                                                    // setproductName(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            labelText="issuerName"
                                            id="issuerName"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                value: issuerName,
                                                onChange: (e) => {
                                                    setissuerName(libs.validate(e.target.value, "all", 150) ? e.target.value : issuerName);
                                                },
                                                onBlur: (e) => {
                                                    setissuerName(libs.validate(e.target.value.trim(), "issuerName", 150) ? e.target.value : "")
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            labelText="productCode"
                                            id="productCode"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                multiline: true,
                                                maxRows: 3,
                                                value: productCode,
                                                onChange: (e) => {
                                                    setproductCode(libs.validate(e.target.value, "all", 150) ? e.target.value : productCode);
                                                },
                                                onBlur: (e) => {
                                                    setproductCode(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            labelText="Flat"
                                            id="flat"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                multiline: true,
                                                maxRows: 3,
                                                value: flat,
                                                onChange: (e) => {
                                                    setFlat(libs.validate(e.target.value, "all", 150) ? e.target.value : productCode);
                                                },
                                                onBlur: (e) => {
                                                    setFlat(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            labelText="Building"
                                            id="flat"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                multiline: true,
                                                maxRows: 3,
                                                value: building,
                                                onChange: (e) => {
                                                    setBuilding(libs.validate(e.target.value, "all", 150) ? e.target.value : productCode);
                                                },
                                                onBlur: (e) => {
                                                    setBuilding(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            labelText="Road"
                                            id="Road"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                multiline: true,
                                                maxRows: 3,
                                                value: road,
                                                onChange: (e) => {
                                                    setRoad(libs.validate(e.target.value, "all", 150) ? e.target.value : road);
                                                },
                                                // onBlur: (e) => {
                                                //     setproductCode(e.target.value.trim());
                                                // }
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <p>Is Products</p>

                                        <Switch {...label} 
                                        defaultChecked={isProducts}
                                        checked={isProducts}
                                        onChange={e=>setIsProducts(e.target.checked)}
                                        

                                        
                                        />
                                        
                                        <FormHelperText id="username-helper"
                                        // error={selectBool}
                                        // style={{ paddingLeft: "41px" }}
                                        >
                                            {isProducts ? "Biller Is Now Have Sub Products" : "Biller Is Now Didn't have Sub Products"}
                                            {/* {helperText} */}
                                        </FormHelperText>
                                    </GridItem>

                                   

                                    
                                </GridContainer>
                                <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                <label htmlFor="licence-upload" style={{ display: 'block', cursor: 'pointer' }}>
                                    Licence
                                    <input
                                        type="file"
                                        id="licence-upload"
                                        // style={{ display: 'none' }}
                                        onChange={(e) => {
                                            setFileName('Licence');
                                            handleDocumentChange(e);
                                        }}
                                    />
                                </label>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                <label htmlFor="licence-upload" style={{ display: 'block', cursor: 'pointer' }}>
                                    Moi
                                    <input
                                        type="file"
                                        id="licence-upload"
                                        // style={{ display: 'none' }}
                                        onChange={(e) => {
                                            setFileName('Licence');
                                            handleDocumentChange(e);
                                        }}
                                    />
                                </label>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            
                                    {/* <div style={{ textAlign: "center", marginBottom: "20px" }}>
                                        <Button simple onClick={() => props.back()} color="primary">Back</Button>
                                    </div>  */}
                                    <div style={{ textAlign: "center", marginBottom: "20px" }}>
                                        <Button simple onClick={() => props.back()} color="primary">Back</Button>
                                          <Button disabled={submitting} onClick={submitData} color="primary">Save</Button>
                                    </div>
                          
                        </Card>
                    
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default OnboardBiller