import React from "react";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import BounceLoader from "react-spinners/BounceLoader";
import { creatCsvFile, downloadFile } from "download-csv";
import MaterialTable from "material-table";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
// @material-ui/core components
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import ListAltIcon from '@material-ui/icons/ListAlt';
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";

import languages from "variables/languages.json";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";

const styles = {
  ...SweetAlertStyle,
  checkboxContainer: {
    width: "500px !important",
  },
  heading: {
    margin: "0px",
  },
  subHeading: {
    fontWeight: "300",
    fontSize: "25px",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    float: "left",
    display: "inlineBlock",
  },
  addContainer: {
    marginTop: "20px",
    marginBottom: "12px",
    height: "49px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  codeInput: {
    marginLeft: "20px",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    float: "left",
    display: "inlineBlock",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;
export default function PaymentSettings() {
  const classes = useStyles();
  const [submitting, setSubmitting] = React.useState(false);
  const [respAlert, setRespAlert] = React.useState(null);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(true);
  const [showVersionsTable, setShowVersionsTable] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);
  const [showAddLanguage, setShowAddLanguage] = React.useState(false);
  const [showAddReason, setShowAddReason] = React.useState(false);
  const [selected, setSelected] = React.useState("");
  const [appVersions, setAppVersions] = React.useState([]);
  const [versionCSVData, setVersionCSVData] = React.useState([]);
  const [versionData, setVersionData] = React.useState([]);


  const [appVersion, setAppVersion] = React.useState("1.0.0");
  const [platform, setPlatform] = React.useState("ios");
  const [language, setLanguage] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [reasonID, setReasonID] = React.useState("");
  const [reasonsTableData, setReasonsTableData] = React.useState([]);
  const [reasonsData, setReasonsData] = React.useState([]);
  const [url, setUrl] = React.useState("");
  const [code, setCode] = React.useState("");

  const [minAmount, setMinAmount] = React.useState("1");
  const [maxAmount, setMaxAmount] = React.useState("500");
  const [readOnlyAmount, setReadOnlyAmount] = React.useState(true);

  const [state, setState] = React.useState({
    walletBenefitPG: false,
    walletBenefitApp: false,
    walletCreditCardPG: false,
    remittanceBenefitPG: false,
    remittanceBenefitApp: false,
    remittanceCreditCardPG: false,
    BillPaymentBenefitPG: false,
    BillPaymentBenefitApp: false,
    BillPaymentCreditCardPG: false,
  });

  const [languagesTableData, setLanguagesTableData] = React.useState([]);
  const [languagesData, setLanguagesData] = React.useState([]);

  const versionColumns = [
    {
      field: "_id",
      title: "ID",
    },

    {
      field: "App_version",
      title: "Version",
    },

    {
      field: "platform",
      title: "Platform",
    },

  ];
  const versionOptions = {
    actionsColumnIndex: -1,
    exportButton: { csv: true },
    showTitle: false,
    exportCsv: () => {
      let csvFile = creatCsvFile(versionCSVData);
      let fileName = "app-versions.csv";
      downloadFile(csvFile, fileName);
      return false;
    },
    textLabels: {
      body: {
        noMatch: "No matching records found",
      },
    }
  };

  const submitAmount = (type) => {
    //function to submit min and max amount of app
    if (type == "edit") {
      setReadOnlyAmount(false)
    } else {
      setReadOnlyAmount(true)
    }
  }

  const versionActions = [
    {
      icon: "list_lt_icon",
      tooltip: 'View and Edit Settings',
      onClick: (event, rowData) => fetchVersionData(rowData._id),

    }

  ]



  React.useEffect(() => {
    fetchVersions()
  }, []);

  function openSnackBar(msg) {
    //function to tost snackbar message
    setOpenSnack(true);
    setSnackBarMsg(msg);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  }

  const changePaymentMode = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const cancelAddLanguage = () => {
    //function to handle add language button click
    setShowAddLanguage(false);
    setLanguage("");
    setCode("");
    setUrl("");
    setSelected("");
  };

  const cancelAddReason = () => {
    //function to handle add language button click
    setShowAddReason(false);
    setReason("");
  };

  const languageToArray = (data) => {
    //function to convert language json  to array
    let temp = [];
    data.map((lan, key) => {
      temp.push([
        key + 1,
        lan.fullname,
        lan.id,
        <img style={{ width: "32px", height: "24px" }} alt={lan.id} src={lan.img} />,
        <Tooltip placement="right" title="Delete">
          <IconButton
            onClick={() => deleteLanguage(key)}
            style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
            aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>,
      ]);
      return 0;
    });
    setLanguagesTableData(temp);
  };

  const deleteLanguage = (id) => {
    //function to delete a language from array
    // alert(id)
    let tempLan = languagesData;
    tempLan.splice(id, 1);
    setLanguagesData(tempLan);
    languageToArray(tempLan);
  };

  const deleteReason = (id) => {
    //function to delete a reason from array
    // alert(id)
    // alert(reasonsData)
    let tempReason = reasonsData;
    tempReason.splice(id, 1);
    setReasonsData(tempReason);
    reasonsToArray(tempReason);
  };

  const fetchVersions = () => {
    //function to fetch version list
    setShowVersionsTable(false);
    setShowLoader(true);
    axios
      .get("/api/microservices/CXPortal/v1/version/fetch-versions")
      .then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          setAppVersions(respData.data);
          setShowLoader(false);
          setShowVersionsTable(true);

          let updatedCsvData = respData.data.map((version) => {
            let temp = {};
            temp.ID = version._id;
            temp.Version = version.App_version;
            temp.Platform = version.platform;
            return temp;
          });
          setVersionCSVData(updatedCsvData);
        } else {
          openSnackBar(respData.msg);
          setAppVersions([])
          setShowLoader(false);
          setShowVersionsTable(true);
        }
      })
      .catch((err) => {
        openSnackBar("Unable to process.");
        setAppVersions([])
        setShowLoader(false);
        setShowVersionsTable(true);
      });
  };

  const fetchVersionData = (id) => {
    //function to fetch version data
    setShowVersionsTable(false)
    setShowLoader(true)
    let url = "/api/microservices/CXPortal/v1/version/fetch-data/" + id
    axios.get(url)
      .then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          setVersionData(respData.data)
          setLanguagesData(respData.data.languages)
          languageToArray(respData.data.languages);
          setReasonsData(respData.data.reasonsForOpeningAccount)
          reasonsToArray(respData.data.reasonsForOpeningAccount);
          let paymentModes = respData.data.paymentModes
          setState({
            walletBenefitPG: paymentModes.walletOptions[0].status,
            walletBenefitApp: paymentModes.walletOptions[1].status,

            remittanceBenefitPG: paymentModes.remittanceOptions[0].status,
            remittanceBenefitApp: paymentModes.remittanceOptions[1].status,

            BillPaymentBenefitPG: paymentModes.billPaymentsOptions[0].status,
            BillPaymentBenefitApp: paymentModes.billPaymentsOptions[1].status,

          })
          setShowLoader(false);
          setShowSettings(true);
        } else {
          openSnackBar(respData.msg);
          setVersionData([])
          setShowLoader(false);
          setShowVersionsTable(true);
        }
      })
      .catch((err) => {
        openSnackBar("Unable to process.");
        setVersionData([])
        setShowLoader(false);
        setShowVersionsTable(true);
      });
  }

  const reasonsToArray = (data) => {
    //function to convert reasons json to array
    let temp = [];
    data.map((reason, key) => {
      temp.push([
        key + 1,
        reason.field,
        <Tooltip placement="right" title="Delete">
          <IconButton
            onClick={() => deleteReason(key)}
            style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
            aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>,
      ]);
      setReasonID(reason.id);
      return 0;
    });
    setReasonsTableData(temp);
  };

  const addLanguage = () => {
    //function to add language
    let tempJson = languagesData;
    tempJson.push({
      id: code,
      fullname: language,
      img: url,
    });
    setLanguagesData(tempJson);
    languageToArray(tempJson);
    setShowAddLanguage(false);
    setLanguage("");
    setCode("");
    setUrl("");
    setSelected("");
  };

  const addReason = () => {
    //function to add reason

    let tempJson = reasonsData;
    tempJson.push({
      id: parseInt(reasonID) + 1,
      field: reason,
    });
    setReasonsData(tempJson);
    reasonsToArray(tempJson);
    setShowAddReason(false);
    setReason("");
    setReasonID(parseInt(reasonID) + 1);
  };

  const selectLanguage = (lan) => {
    //function to select language
    if (lan !== null) {
      setSelected(lan);
      setLanguage(lan.English);
      setCode(lan.alpha2);
    }
  };

  const saveData = () => {
    //function to save all data
    setSubmitting(true)
    let data = versionData
    data.languages = languagesData
    data.reasonsForOpeningAccount = reasonsData
    data.paymentModes.walletOptions[0].status = state.walletBenefitPG
    data.paymentModes.walletOptions[1].status = state.walletBenefitApp
    data.paymentModes.remittanceOptions[0].status = state.remittanceBenefitPG
    data.paymentModes.remittanceOptions[1].status = state.remittanceBenefitApp
    data.paymentModes.billPaymentsOptions[0].status = state.BillPaymentBenefitPG
    data.paymentModes.billPaymentsOptions[1].status = state.BillPaymentBenefitApp

    let url = "/api/microservices/CXPortal/v1/version/updateDate/" + data._id
    axios.put(url, data)
      .then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          setRespAlert(
            <SweetAlert
              success
              style={{ display: "block" }}
              title="Version data saved."
              onConfirm={() => {
                setRespAlert(null);
                setShowSettings(false);
                setShowVersionsTable(true)
                setSubmitting(false)
              }
              }
              confirmBtnCssClass={classes.button + " " + classes.success}
            />
          );
        } else {
          setSubmitting(false)
          openSnackBar(respData.msg);
        }
      })
      .catch((err) => {
        setSubmitting(false)
        openSnackBar("Unable to process.");
      });
  };

  const handleBackClick = () => {
    //function to handle back button click
    setVersionData([])
    setLanguagesData([])
    setReasonsData([])
    setShowSettings(false)
    setShowVersionsTable(true)
  }

  const scrollToTop = () => {
    alert(window.innerHeight)
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  return (
    <div>
      {respAlert}
      <Collapse
        in={showLoader}
        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
        {...(true ? { timeout: 500 } : {})}>
        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
      </Collapse>

      <Collapse
        in={showVersionsTable}
        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
        {...(true ? { timeout: 500 } : {})}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>App Versions</h4>
          </CardHeader>
          <CardBody>
            <MaterialTable
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              data={appVersions}
              columns={versionColumns}
              options={versionOptions}
              actions={versionActions}
            />
          </CardBody>
        </Card>
      </Collapse>
      <Collapse
        in={showSettings}
        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
        {...(true ? { timeout: 500 } : {})}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardBody>


                <h3 className={classes.subHeading}>Payment Settings</h3>
                <Table
                  tableData={[
                    [
                      <h4 className={classes.heading}>Add Money</h4>,
                      <h4 className={classes.heading}>:</h4>,
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={state.walletBenefitPG}
                              onChange={changePaymentMode}
                              name="walletBenefitPG"
                            />
                          }
                          label="BenefitPG"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={state.walletBenefitApp}
                              onChange={changePaymentMode}
                              name="walletBenefitApp"
                            />
                          }
                          label="BenefitApp"
                        />
                        {/* <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={state.walletCreditCardPG}
                          onChange={changePaymentMode}
                          name="walletCreditCardPG"
                        />
                      }
                      label="CreditCardPG"
                    /> */}
                      </FormGroup>,
                    ],
                    [
                      <h4 className={classes.heading}>Bill Payment</h4>,
                      <h4 className={classes.heading}>:</h4>,
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={state.BillPaymentBenefitPG}
                              onChange={changePaymentMode}
                              name="BillPaymentBenefitPG"
                            />
                          }
                          label="BenefitPG"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={state.BillPaymentBenefitApp}
                              onChange={changePaymentMode}
                              name="BillPaymentBenefitApp"
                            />
                          }
                          label="BenefitApp"
                        />
                        {/* <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={state.BillPaymentCreditCardPG}
                          onChange={changePaymentMode}
                          name="BillPaymentCreditCardPG"
                        />
                      }
                      label="CreditCardPG"
                    /> */}
                      </FormGroup>,
                    ],
                    [
                      <h4 className={classes.heading}>Remittance</h4>,
                      <h4 className={classes.heading}>:</h4>,
                      <FormGroup className={classes.checkboxContainer} row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={state.remittanceBenefitPG}
                              onChange={changePaymentMode}
                              name="remittanceBenefitPG"
                            />
                          }
                          label="BenefitPG"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={state.remittanceBenefitApp}
                              onChange={changePaymentMode}
                              name="remittanceBenefitApp"
                            />
                          }
                          label="BenefitApp"
                        />
                        {/* <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={state.remittanceCreditCardPG}
                          onChange={changePaymentMode}
                          name="remittanceCreditCardPG"
                        />
                      }
                      label="CreditCardPG"
                    /> */}
                      </FormGroup>,
                    ],
                  ]}
                />
                <br />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>

            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h3 className={classes.subHeading}>Transaction Limit</h3>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} >
                    <TextField
                      className={classes.codeInput}
                      fullWidth

                      inputProps={{ readOnly: readOnlyAmount, value: minAmount, onChange: (e) => setMinAmount(e.target.value) }}
                      label="Minimum Amount"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} >
                    <TextField
                      className={classes.codeInput}
                      fullWidth
                      inputProps={{ readOnly: readOnlyAmount, value: maxAmount, onChange: (e) => setMaxAmount(e.target.value) }}
                      label="Maximum Amount"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} >

                    <Button color="primary" style={{ marginTop: "20px" }} size="sm">Edit</Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>

            <Card>
              <CardBody>
                <h3 className={classes.subHeading}>Language Settings</h3>
                <Table
                  tableHeaderColor="primary"
                  tableHead={["#", "Language", "Code", "Icon", ""]}
                  tableData={languagesTableData}
                />
                <Collapse
                  in={showAddLanguage}
                  style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                  {...(true ? { timeout: 500 } : {})}>
                  <div className={classes.addContainer}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={languages}
                      value={selected}
                      getOptionLabel={(option) => option.English}
                      style={{ width: 300 }}
                      onChange={(value, item) => {
                        selectLanguage(item);
                      }}
                      renderInput={(params) => <TextField fullWidth {...params} label="Language" />}
                    />
                    <TextField className={classes.codeInput} inputProps={{ readOnly: true, value: code }} label="Code" />
                    <TextField
                      className={classes.codeInput}
                      fullWidth
                      inputProps={{ value: url, onChange: (e) => setUrl(e.target.value) }}
                      label="Icon url"
                    />
                  </div>
                  <Button onClick={() => cancelAddLanguage()} size="sm" simple color="primary">
                    cancel
                  </Button>
                  <Button onClick={addLanguage} size="sm" color="primary">
                    Add
                  </Button>
                </Collapse>
                <Collapse
                  in={!showAddLanguage}
                  style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                  {...(true ? { timeout: 500 } : {})}>
                  <br />
                  <Button onClick={() => setShowAddLanguage(true)} size="md" simple color="primary">
                    Add Language
                  </Button>
                </Collapse>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardBody>
                <h3 className={classes.subHeading}>Reason for Opening Account</h3>
                <Table tableHeaderColor="primary" tableHead={["#", "Reason", ""]} tableData={reasonsTableData} />
                <Collapse
                  in={showAddReason}
                  style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                  {...(true ? { timeout: 500 } : {})}>
                  <div className={classes.addContainer}>
                    <TextField
                      fullWidth
                      inputProps={{ value: reason, onChange: (e) => setReason(e.target.value) }}
                      label="Reason"
                    />
                  </div>
                  <Button onClick={() => cancelAddReason()} size="sm" simple color="primary">
                    cancel
                  </Button>
                  <Button onClick={addReason} size="sm" color="primary">
                    Add
                  </Button>
                </Collapse>
                <Collapse
                  in={!showAddReason}
                  style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                  {...(true ? { timeout: 500 } : {})}>
                  <br />
                  <Button onClick={() => setShowAddReason(true)} simple size="md" color="primary">
                    ADD REASON
                  </Button>
                </Collapse>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <button onClick={scrollToTop}>test</button>
            <br />
            <br />
            <Button size="lg" onClick={handleBackClick} simple color="primary">
              <ArrowBackIcon />
              Back
            </Button>
            {!submitting ? (
              <Button onClick={saveData} size="md" color="primary">
                Save
              </Button>
            ) : (
              <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
            )}

          </GridItem>
        </GridContainer>
      </Collapse>

      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
            setSnackBarMsg("");
          }}
          severity="error">
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
