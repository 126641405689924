import React, { useEffect, useState } from 'react';
import config from "config.js"
import Button from '@mui/material/Button';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "components/CustomInput/CustomInput.js";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import { Select } from '@material-ui/core';
import MenuItem from "@material-ui/core/MenuItem";
import JSONPretty from 'react-json-pretty';
import axios from 'axios'
//  

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import libs from 'controllers/libs';
import { height } from '@mui/system';
import { Paper } from '@material-ui/core';
import { isTemplateExpression } from 'typescript';
//css for card header
const useStyles = makeStyles((them) => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "1px",
    textDecoration: "none",
    float: "left",
    display: "inlineBlock",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    }
  }
}))



export default function BasicTextFields(props) {
  const classes = useStyles()
  const [name, setName] = useState()
  const [category, setCategory] = useState()
  const [categoryList, setCategoryList] = useState([])
  const [icon, setIcon] = useState()
  const [status, setStatus] = useState()
  const [formData, setFormData] = useState([])





  //set the initial biller values
  const setBillDetails = async () => {
    setName(props.category.name)
    setCategory(props.category.category)
    setIcon(props.category.icon)
    setStatus(props.category.active)
    setFormData(props.category.products)
    setCategoryList(props.categoryList)
  }


  useEffect(() => {
    setBillDetails()
  }, [])

  const submitForm = async () => {
    //function to call the  update api
    try {


      if (validateForm()) {

        let categoryDetails = { name: name, category: category, icon: icon, active: status, _id: props.category._id, products: formData }
        let resp = await axios.post(`${config.url().appURL}/CXPortal/v3/update-sadad-Billers`, categoryDetails)
        if (resp.data.err === false) {
          props.Alert('success', 'Success', "Updated.")
        }
        else {
          props.snack("resp.data.msg")
        }

      }

    } catch (error) {
      console.log(error)
      props.snack("Unable to process.")
    }
  }


  //validating all from fields 
  const validateForm = () => {
    let flag = true;
    let msg = ""
    let nameErr = false, categoryErr = false, iconErr = false, statusErr = false, productsErr = false
    if (formData === undefined) {
      productsErr = true
      msg = 'please enter products.'
      flag = false
    }
    if (!icon) {
      iconErr = true
      msg = 'Please enter Icon link'
      flag = false
    }
    if (!status) {
      statusErr = true
      msg = 'Please enter status.'
      flag = false
    }
    if (!category) {
      categoryErr = true
      msg = 'Please enter category.'
      flag = false
    }
    if (!name) {
      nameErr = true
      msg = 'please enter name.'
      flag = false
    }
    if (nameErr || iconErr || categoryErr || statusErr || productsErr) {
      flag = false

    }
    if (!flag) {
      props.snack(msg)
    }
    else {
      return flag
    }

  }





  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Biller Details</h4>
      </CardHeader>
      <CardBody>
        <GridContainer >
          <GridItem xs={12} sm={12} md={8}>
            <CustomInput
              labelText="Name"
              id="biller-name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                autoComplete: "off",
                value: name,
                onChange: (e) => {
                  setName(libs.validate(e.target.value, "alphabet", 50) ? e.target.value : name)
                },
                onBlur: (e) => {
                  setName(e.target.value.trim());
                }
              }}
            />
          </GridItem>
          <GridItem xs={4} >

            <FormControl style={{ marginTop: "43px" }} fullWidth>
              <Select
                id="category"
                value={`${category}`}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}>

                {
                  categoryList.map((item) => {
                    return (

                      <MenuItem
                        value={`${item.img_name}`}>
                        {`${item.img_name}`}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>

          </GridItem>
          <GridItem xs={3} >

            <FormControl style={{ marginTop: "43px" }} fullWidth>
              <Select
                id="category"
                value={`${status}`}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}>
                <MenuItem
                  value={`true`}>
                   {`true`}
                </MenuItem>
                <MenuItem
                  value={`false`}>
                    {`false`}
                 
                </MenuItem>
              </Select>
            </FormControl>
            {/* <CustomInput labelText="status" id="biller-status" formControlProps={{ fullWidth: true, }}
              inputProps={{
                autoComplete: "off",
                value: status,
                onChange: (e) => {
                  setStatus(libs.validate(e.target.value, "boolean", 10) ? e.target.value : status)
                },
                onBlur: (e) => {
                  setStatus(e.target.value.trim());
                }
              }}
            /> */}
          </GridItem>
          <GridItem xs={9} >
            <CustomInput
              labelText="Icon"
              id="icon-link"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                autoComplete: "off",
                value: icon,
                onChange: (e) => {
                  setIcon(libs.validate(e.target.value, "all", 100) ? e.target.value : icon)
                },
                onBlur: (e) => {
                  setIcon(e.target.value.trim());
                }
              }}
            />
          </GridItem>
          <br />
          <GridItem xs={12} sm={12} md={12}>
            <Paper elevation={3} >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Products</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <JSONInput
                      placeholder={props.category.products}
                      id="json-editor"
                      confirmGood={false}
                      onChange={(res) => {
                        if (res.jsObject === undefined) {
                          setFormData()
                        } else {
                          setFormData(res.jsObject)
                        }
                      }}
                      theme="light_mitsuketa_tribute"
                      locale={locale}
                      height="100%"
                      width="100%"
                    />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Paper>
            <br />
          </GridItem>
          <GridItem xs={12}>
            <div style={{ textAlign: "center" }} >
              <Button onClick={props.back} variant="simple" color="primary" >
                Back
              </Button>
              <Button variant="contained" onClick={submitForm} >
                Save
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}
