
import * as React from 'react';
import NoDataFound from './NodataFound/NoDataFound';
import LoadingComponent from './Loder/Loader';
import { useW2wContext } from '../Provider/W2wContext';



function WBReport() {
    const { date, html, getWBReport, showLoader } = useW2wContext()
    //const [showLoader, setShowLoader] = React.useState(false);


   const handleGanerateReport = async () => {
        try {
            
            await getWBReport()
            
        } catch (error) {
            console.log(error)
          
        }
    }

    React.useEffect(() => {
        handleGanerateReport();
    }, [date]);

    return (
        (!showLoader) ? (html === "") ? <NoDataFound /> :
            <div style={{
                maxWidth: "100%",
                overflow: "auto",
                wordWrap: "break-word",
                wordBreak: "break-all"
            }} className="html-content" dangerouslySetInnerHTML={{ __html: html }}></div>
            : <LoadingComponent />


    );
}

export default WBReport;
