import React from "react";
import ProductForm from "./BfcBillerForm";
import VendorForm from "./VendorBIllerForm";
import EditBillerForm from "./EditBillerForm";
export default function MainForm({ type, back, snack, alertPop,billRef }) {
    let componentToRender;

    switch (type) {
        case "BFC_Pay":
            componentToRender = <ProductForm back={back} alertPop={alertPop} snack={snack} type={type}/>;
            break;
        
        case "Edit":
            componentToRender = <EditBillerForm back={back} alertPop={alertPop} snack={snack}  billRef={billRef} type="Edit"/>;
            break;

        default:
            componentToRender = <EditBillerForm   type="view" back={back} alertPop={alertPop} snack={snack}  billRef={billRef}/>;
            break;
    }

    return (
        <div>
            {componentToRender}
        </div>
    );
}
