
import * as React from 'react';
import config from "config.js"
import Table from "components/Table/Table.js";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import Dialog from '@material-ui/core/Dialog';
import InputBase from "@material-ui/core/InputBase";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from "components/CustomButtons/Button.js";
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import Paper from "@material-ui/core/Paper";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { textAlign } from '@mui/system';

const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
    },
    tabContent: {
        maxHeight: "60vh"
    },
    dialogContainer: {
        display: "flex",
    },
    input: {
        flex: 1,
        marginTop: "10px",
        height: "45px",
        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },

}));

export default function EditForm(props) {
    const classes = useStyles();
    const [mobile, setMobile] = React.useState("");
    const [confirmMobile, setConfirmMobile] = React.useState("");
    const [DOB, setDOB] = React.useState("");

    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [snackBarType, setSnackBarType] = React.useState("error");
    const [openSnack, setOpenSnack] = React.useState(false);

    const [openPassword, setOpenPassword] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [password, setPassword] = React.useState("");
    const [declaration, setDeclaration] = React.useState(false);

    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [values, setValues] = React.useState({ dobErr: null });

    function openSnackBar(msg, success) {
        //function to tost snackbar message
        setOpenSnack(true);
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    React.useEffect(() => {
        if (props.emp.cpr !== null) {
            getCustomerDetails(props.emp.cpr)
        }
    }, [props.emp.cpr]);


    const validateFrom = () => {
        //function to validate form
        var flag = true
        var dobErr = false, addressTypeErr = false, flatNoErr = false, buildingNoErr = false, roadErr = false, blockErr = false, nationalityErr = false, placeOfBirthErr = false, occupationErr = false, empAddressTypeErr = false, empFlatNoErr = false, empBuildingNoErr = false, empRoadErr = false, empBlockErr = false;
        var msg = ""
        if (mobile.length !== 8) {
            msg = "Please enter valid mobile number"
            flag = false
        }
        if (mobile !== confirmMobile) {
            msg = (msg == "" ? "Mobile numbers doesn't match." : msg);
            flag = false
        }
        if (DOB.length == 0 || moment(DOB).isAfter(moment(new Date()))) {
            msg = (msg == "" ? "Please enter valid date of birth." : msg);
            dobErr = true
            flag = false
        }

        if (!declaration) {
            msg = (msg == "" ? "Please confirm you have asked the questions." : msg);
            flag = false
        }

        if (!flag) {
            openSnackBar(msg)
        }
        setValues({ ...values, dobErr: dobErr })
        return flag
    }

    const submitData = () => {
        //function to submit data
        if (validateFrom()) {
            setOpenPassword(true)
        }
    }

    const getCustomerDetails = (cpr) => {
        //function to get customer details 
        axios.get(`${config.url().baseURL}/get-kyc-details/${cpr}`).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                setMobile(respData.data[0].Contact.PhoneNumber ? respData.data[0].Contact.PhoneNumber : "")
                setConfirmMobile(respData.data[0].Contact.PhoneNumber ? respData.data[0].Contact.PhoneNumber : "")
                if (moment(respData.data[0].DateOfBirth, 'DD/MM/YYYY', true).isValid()) {
                    let convertedDate = respData.data[0].DateOfBirth.split('/')
                    setDOB(new Date(`${convertedDate[1]}/${convertedDate[0]}/${convertedDate[2]}`))
                } else {
                    setDOB(new Date(respData.data[0].DateOfBirth))
                }
                setDataLoaded(true)
            } else {
                openSnackBar(respData.msg);
            }
        }).catch((err) => {
            console.log(err)
            openSnackBar("Unable to process.");
            setSubmitting(false);
            setPassword("")

        });
    }

    const updateCustomer = () => {
        //function save changes 
        if (!submitting) {
            setSubmitting(true);
            let apiData = {
                phone: mobile,
                password: password,
            }
            axios.post(`${config.url().baseURL}/update-customer/${props.emp.cpr}`, apiData).then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    setSubmitting(false);
                    setPassword("")
                    setOpenPassword(false)
                    openSnackBar(respData.msg, true);
                    setTimeout(function () {
                        props.close()
                    }, 5000);
                } else {
                    openSnackBar(respData.msg);
                    setOpenPassword(false)
                    setSubmitting(false);
                    setPassword("")
                }
            }).catch((err) => {
                openSnackBar("Unable to process.");
                setSubmitting(false);
                setPassword("")

            });
        }
    }
    return (
        <div style={{ margin: "10px", textAlign: "center" }}>
            <h5 style={{ margin: "0px" }} >Edit Mobile Number</h5>
            <Collapse
                in={!dataLoaded}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={8} />
            </Collapse>
            <Collapse
                in={dataLoaded}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <Table
                    tableData={[
                        ["Mobile", ":", <TextField
                            type="text"
                            fullWidth
                            error={mobile.length != 8}
                            inputProps={{
                                autoComplete: "off",
                                type: "text",
                                value: mobile,
                                onPaste: (e) => {
                                    e.preventDefault()
                                    return false;
                                },
                                onCopy: (e) => {
                                    e.preventDefault()
                                    return false;
                                },
                                onChange: (e) => {
                                    const re = /^[0-9\b]+$/;
                                    var res = e.target.value.substring(0, 1)
                                    if (res == 3 || res == 6 || e.target.value == "") {
                                        if (e.target.value === "" || re.test(e.target.value)) {
                                            if (e.target.value.length <= 8) {
                                                setMobile(e.target.value);
                                            }
                                        }
                                    }
                                },

                            }}
                        />,
                        ],
                        [
                            "Re-enter Mobile", ":", <TextField
                                type="text"
                                fullWidth
                                error={mobile !== confirmMobile}
                                inputProps={{
                                    autoComplete: "off",
                                    type: "text",
                                    value: confirmMobile,
                                    onPaste: (e) => {
                                        e.preventDefault()
                                        return false;
                                    },
                                    onCopy: (e) => {
                                        e.preventDefault()
                                        return false;
                                    },
                                    onChange: (e) => {
                                        const re = /^[0-9\b]+$/;
                                        var res = e.target.value.substring(0, 1)
                                        if (res == 3 || res == 6 || e.target.value == "") {
                                            if (e.target.value === "" || re.test(e.target.value)) {
                                                if (e.target.value.length <= 8) {
                                                    setConfirmMobile(e.target.value);
                                                }
                                            }
                                        }
                                    },

                                }}
                            />
                        ],
                        [
                            "Date of Birth", ":", <TextField
                                type="date"
                                fullWidth
                                error={values.dobErr}
                                disabled={true}
                                inputProps={{
                                    autoComplete: "off",
                                    type: "date",
                                    max: moment(new Date()).format("YYYY-MM-DD"),
                                    value: moment(DOB).format("YYYY-MM-DD"),
                                    onChange: (e) => {
                                        setDOB(e.target.value);
                                    },
                                    onBlur: (e) => {
                                        if (moment(e.target.value).isAfter(moment(new Date()))) {
                                            setDOB("");
                                            openSnackBar("Invalid DOB.")
                                        }
                                    },
                                }}
                            />
                        ],



                    ]}
                />
                <FormGroup style={{ textAlign: "left" }}>
                    <br />
                    <FormControlLabel style={{ color: "black", paddingLeft: "15px", paddingRight: "15px" }} control={<Checkbox checked={declaration} onClick={() => setDeclaration(!declaration)} />} label="I hereby confirm that I have asked 3 kyc related questionnaire to the customer and customer has answered those correctly." />
                </FormGroup>
            </Collapse>
            <br />

            <Button color="danger" onClick={() => props.close()} size="sm">Cancel</Button>
            {dataLoaded ? (<Button color="primary" onClick={() => submitData()} size="sm">Save</Button>) : null}

            <Dialog onClose={() => setOpenPassword(false)} aria-labelledby="simple-dialog-title" PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }} open={openPassword}>
                <h4 className={classes.passwordHead}>Please enter password to continue.</h4>
                <Paper className={classes.dialogContainer}>
                    <InputBase
                        autoFocus={true}
                        fullWidth
                        className={classes.input}
                        placeholder="Password"
                        inputProps={{
                            type: "password",
                            onChange: (event) => { setPassword(event.target.value) },
                            value: password,
                        }}
                    />

                    <Button className={classes.nextButton}
                        onClick={() => updateCustomer()}
                        simple
                        size="lg" color="primary">
                        <ArrowForwardIcon fontSize="medium" />
                    </Button>

                </Paper>

            </Dialog>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>

        </div >
    );
}