import React from 'react';
import axios from "axios";
import config from "config.js"
import moment from "moment";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styles from "assets/jss/material-dashboard-react/views/customersStyle.js"
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const logColumns = [
    { id: 'date', label: 'Date', width: 70 },
    { id: 'time', label: 'Time', width: 70 },
    { id: 'user', label: 'User', width: 70 },
    { id: 'status', label: 'Status', width: 70 },
    { id: 'status_message', label: 'Status_Message', width: 90 },
    { id: 'remarks', label: 'Remarks', minWidth: 170 },
];
export default function UpgradeLog(props) {
    const classes = useStyles();
    const [upgradeLogModal, setupgradeLogModal] = React.useState(false);
    const [upgradeLogs, setUpgradeLogs] = React.useState([]);

    React.useEffect(() => {
        fetchLog()
    }, [props.id])

    const fetchLog = () => {
        //function to fetch company logs
        axios.get(`${config.url().baseURL}/get-mc-upgrade-logs/${props.id}`).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                setupgradeLogModal(true);
                console.log(JSON.stringify(respData.data[0]))
                if (respData.data.length !== 0) {
                    setUpgradeLogs(respData.data);
                } else {
                    setupgradeLogModal(false);
                    props.openSnackBar("No log found.");
                }

            } else {
                setupgradeLogModal(false);
                props.openSnackBar(respData.msg);
            }
        }).catch((err) => {
            props.openSnackBar("Unable to process")
        });
    };
    return (
        <>  {console.log(upgradeLogs)}
            <Dialog
                classes={{
                    root: classes.left + " " + classes.modalRoot,
                    paper: classes.companyStatusLogModal,
                }}
                open={upgradeLogModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setupgradeLogModal(false);
                    setUpgradeLogs([]);
                    props.close()
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>Travel Buddy Upgrade Log</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <TableContainer className={classes.container}>
                        <MuiTable stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {logColumns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {upgradeLogs.map((row, key) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                                            <TableCell>
                                                {moment(row.date).format("DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell>
                                                {moment(row.date).format("hh:mm:ss A")}
                                            </TableCell>
                                            <TableCell>
                                                {row.user ? (row.user.firstName + " " + row.user.lastName) : ""}
                                            </TableCell>
                                            <TableCell>
                                                {row.status ? row.status : null}
                                            </TableCell>
                                            <TableCell>
                                                {row.status_message ? row.status_message : ""}
                                            </TableCell>
                                            <TableCell>
                                                {row.remarks ? row.remarks : ""}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </MuiTable>
                    </TableContainer>
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setupgradeLogModal(false);
                            setUpgradeLogs([]);
                            props.close()
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog></>
    )
}