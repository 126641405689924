import React, { useEffect, useRef, useState } from 'react';
import { TextField, Checkbox, FormControlLabel, FormGroup, Select, MenuItem } from '@material-ui/core';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { css } from "@emotion/core";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Divider } from '@mui/material';
import config from 'config';
import axios from 'axios';
import Dropzone from "react-dropzone";
import DocumentListComponent from 'views/BfcBiller/Components/ViewDoc';



import Button from "components/CustomButtons/Button.js";
import { makeStyles } from '@material-ui/core/styles';
import Grid from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem';

const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    formContainer: {

        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        padding: theme.spacing(2),

    },
    sectionContainer: {
        marginBottom: theme.spacing(2),
    },
    fileInput: {
        display: 'none',
    },
    uploadButton: {
        marginRight: theme.spacing(2),
    },
    submitButton: {
        marginTop: theme.spacing(2),
    },

}));
const EditBillerForm = ({ back, snack, alertPop, type, billRef }) => {
    const classes = useStyles();

    const [billerName, setBillerName] = useState('');
    const [category, setCategory] = useState('')
    const [tranCode, settranCode] = useState(type);
    const [isProducts, setisProducts] = useState(false);
    const [productCode, setProductCode] = useState('');
    const [producttranCode, setProducttranCode] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [inHome, setInHome] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [respAlert, setRespAlert] = React.useState(null);
    const [docs, setDocs] = useState()
    const [address, setAddress] = useState()
    //const [reason, setReason] = useState()
    const [status, setStatus] = useState(null)
    const reason = useRef()
    const [issuerId, setissuerId] = useState('')
    const [group, setGroup] = useState('')

    const [issuerName, setissuerName] = useState('')
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [uploadedFile, setUploadedFile] = useState()

    useEffect(() => {
        handleView()
    }, [])

    const handleView = async () => {
        try {

            const resp = await axios.post(`${config.url().app_URL}/bill/get-category`, { billRef })
            if (resp?.data?.status.err) {
                snack(resp?.data?.status.message)
            }
            console.log(resp.data.data);
            setBillerName(resp.data?.data?.billerName)
            setCategory(resp.data?.data?.category)
            setissuerName(resp.data?.data?.issuerName)
            settranCode(resp.data?.data?.tranCode)
            setissuerId(resp.data?.data?.issuerId)
            setDocs(resp.data?.data?.docs)
            setAddress(resp.data?.data?.address)
            setStatus(resp.data?.data?.status)
            // setGroup(resp.data?.data?.group)
            // setProductName(resp.data?.data?.productName)
            setProductCode(resp.data?.data?.productCode)
            setInHome(resp.data?.data?.inHome)
            setIsActive(resp.data?.data?.isActive)
            setGroup(resp.data?.data?.group)

        } catch (err) {
            console.log(err);
            snack("Something Went Wrong");

        }
    }

    let handleApprove = async () => {
        try {
            let data = {
                billRef,
                value: {
                    isActive: true,
                    inHome: true,
                    status: "Approved"
                }
            }
            const resp = await axios.post(`${config.url().app_URL}/bill/update-category`, data)
            if (resp.data.status.err) {
                snack(resp.data.message)
            }
            else {
                snack("Approved Sucessfully", true)
                handleView()
                setRespAlert("")
            }
        } catch (error) {
            console.log(error)
            snack("Something went Wrong")

        }
    }








    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setRespAlert(
                <SweetAlert
                    warning
                    input
                    style={{ display: "block" }}
                    title="Are you sure?"
                    onConfirm={() => {
                        handleApprove()
                    }}
                    onCancel={() => {
                        setRespAlert(null)
                    }} Data
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    cancelBtnCssClass={classes.button}
                    confirmBtnCssClass={classes.button + " " + classes.danger}
                >
                    This action will enable this biller in the app, Do you want to do it?
                </SweetAlert>)

        } catch (error) {
            console.log(error)
            snack(error.message)
        }

    };


    const handleReasonChange = (e) => {
        reason.current = e.target.value
    };

    const handleReject = async (e) => {
        try {
            e.preventDefault();

            setRespAlert(
                <SweetAlert
                    warning
                    input
                    style={{ display: "block" }}
                    title="Are you sure?"
                    onConfirm={handleRejection} // Pass the reason directly without arrow function
                    onCancel={() => {
                        setRespAlert(null);
                    }}
                    showCancel
                    confirmBtnText="Recheck"
                    cancelBtnText="Cancel"
                    cancelBtnCssClass={classes.button}
                    confirmBtnCssClass={classes.button + " " + classes.danger}
                >
                    <TextField
                        label="Recheck Reason"
                        variant="outlined"
                        fullWidth
                        value={reason.current}
                        onChange={handleReasonChange} // Update the onChange event handler to call handleReasonChange
                        required
                    />
                </SweetAlert>
            );
        } catch (error) {
            console.log(error);
            snack(error.message);
        }
    };


    const handleRejection = async () => {
        try {


            let data = {
                billRef,
                value: {
                    status: `Recheck`,
                    reason :reason.current,
                },
            };
            console.log(reason.current)

              const resp = await axios.post(`${config.url().app_URL}/bill/update-category`, data);
              if (resp.data.status.err) {
                snack(resp.data.message);
              } else {
                snack("Moved to Recheck", true);
                handleView();
                setRespAlert("");
              }
        } catch (error) {
            console.log(error);
            snack("Something went wrong");
        }
    };


    return (
        <>
            {respAlert}
            <Card>

                <CardHeader color="primary" >
                    <h4 className={classes.cardTitleWhite}>Edit Biller</h4>

                </CardHeader>
                <CardBody style={{ padding: 50 }}>
                    <Grid xs={12} >
                        <GridItem xs={4}>
                            <TextField
                                label="Biller Name"
                                value={billerName}
                                onChange={(e) => setBillerName(e.target.value)}
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    readOnly: true
                                }}
                            />

                        </GridItem>

                        <GridItem xs={4}>
                            <TextField
                                label="Group"
                                value={group}
                                onChange={(e) => setGroup(e.target.value)}
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    readOnly: true
                                }}
                            />

                        </GridItem>
                        {/* <GridItem xs={4}>
                        <TextField
                            label="Tarn Code"
                            value={tranCode}
                            onChange={(e) => settranCode(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </GridItem> */}
                        {/* <GridItem xs={2} >
                        <FormGroup style={{ marginTop: 25 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isActive}
                                        onChange={(e) => setIsActive(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Is Active Biller Or Not"
                            />
                        </FormGroup>
                    </GridItem>
                    <GridItem xs={2}>
                        <FormGroup style={{ marginTop: 25 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={inHome}
                                        onChange={(e) => setInHome(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Is Show In Home"
                            />
                        </FormGroup>
                    </GridItem> */}




                        {/* <GridItem xs={4}>
                        <TextField
                            label="category"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </GridItem> */}
                        {!isProducts && (<><GridItem xs={4}>
                            <TextField
                                label="issuerId"
                                value={issuerId}
                                onChange={(e) => setissuerId(e.target.value)}
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                        </GridItem>
                            <GridItem xs={4}>
                                <TextField
                                    label="issuerName"
                                    value={issuerName}
                                    onChange={(e) => setissuerName(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </GridItem></>)}
                        <GridItem xs={4}>
                            <TextField
                                label="category"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                        </GridItem>

                    </Grid>
                    {
                        (address) ?
                            <div className={classes.sectionContainer}>
                                <h4 style={{ fontWeight: "bold" }}>Basic Address Details</h4>
                                <Divider
                                    sx={{ margin: "10px 0", backgroundColor: "black", height: 1 }}
                                />
                                <Grid spacing={2} xs={12}>


                                    {
                                        Object.entries(address).map(([key, value]) => (
                                            <GridItem xs={4}>
                                                <TextField
                                                    key={key}
                                                    label={key}
                                                    value={value}
                                                    // onChange={(e) => handleAddressChange(key, e.target.value)}
                                                    fullWidth
                                                    margin="normal"
                                                    inputProps={{
                                                        readOnly: type === "Edit" ? false : true
                                                    }}
                                                />
                                            </GridItem>
                                        ))
                                    }
                                </Grid>
                            </div>
                            :
                            (
                                (tranCode === "BFC_Pay") ? <div className={classes.sectionContainer}>
                                    <h4 style={{ fontWeight: "bold" }}>Basic Address Details</h4>
                                    <Divider
                                        sx={{ margin: "10px 0", backgroundColor: "black", height: 1 }}
                                    />
                                    <Grid spacing={2} xs={12}>

                                        <GridItem xs={12}>
                                            <div> Address details not updated
                                            </div>
                                        </GridItem>

                                    </Grid>
                                </div> : ""
                            )
                    }
                    {
                        (docs) ?
                            <div>

                                <div className={classes.sectionContainer}>
                                    <h4 style={{ fontWeight: "bold" }}>  Documents</h4>
                                    <Divider />
                                    <p>Document Size Is Max of 2MB</p>
                                    <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                                        <DocumentListComponent docs={docs} />
                                    </div>
                                </div>

                            </div> : (
                                (tranCode === "BFC_Pay") ?
                                    <div>

                                        <div className={classes.sectionContainer}>
                                            <h4 style={{ fontWeight: "bold" }}>  Documents</h4>
                                            <Divider />

                                            <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                                                Documents not updated
                                            </div>
                                        </div>

                                    </div> : ""
                            )

                    }

                    <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
                        {!isActive && !inHome && !["Approved", "Rejected","Recheck","Rechecked"].includes(status) && (
                            <>
                                <Button
                                   // tranCode="Approve"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submitButton}
                                    style={{ width: 100, marginRight: 10, padding: "8px", fontSize: "12px" }}
                                    onClick={handleSubmit}
                                >
                                    Approve
                                </Button>
                                <Button
                                    //tranCode="Recheck"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submitButton}
                                    style={{ width: 100, marginRight: 10, padding: "8px", fontSize: "12px" }}
                                    onClick={handleReject}
                                >
                                    Recheck
                                </Button>
                            </>
                        )}

                        <Button
                          //  tranCode="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submitButton}
                            style={{ width: 100, marginRight: 10, padding: "8px", fontSize: "12px" }}
                            onClick={back}
                        >
                            Back
                        </Button>
                    </div>


                </CardBody>
            </Card >
        </>
    );
};

export default EditBillerForm;
