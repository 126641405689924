import React, { useEffect, useState } from 'react';
import { Button, Modal, Tooltip, IconButton } from '@material-ui/core';
import { Document, Page } from 'react-pdf';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CancelIcon from '@material-ui/icons/Cancel';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PDFViewerComponent from './PDFViewerComponent';

const UploadPDFComponent = ({ name, uploadNewStudentList }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleCancelUpload = () => {
    setSelectedFile(null);
  };

  const handleViewDoc = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handelSubmit = () => {
    uploadNewStudentList(selectedFile, name);
  };


  useEffect(()=>{},[selectedFile])

  const isFileSelected = selectedFile !== null;

  return (
    <div style={{ padding: '10px' }}>
      <input
        accept="application/pdf"
        id={`upload-pdf-${name}`}
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileUpload}
        disabled={isFileSelected} // Disable file selection if a file is already selected
      />
      <label htmlFor={`upload-pdf-${name}`}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<CloudUploadIcon />}
          disabled={isFileSelected}
        >
          Select {name}*
        </Button>
      </label>
      {isFileSelected && (
        <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
          <IconButton onClick={handleCancelUpload}>
            <Tooltip title="Reset File">
              <CancelIcon />
            </Tooltip>
          </IconButton>
          <IconButton onClick={handelSubmit}>
            <Tooltip title="Upload File">
              <CloudUploadIcon />
            </Tooltip>
          </IconButton>
          <IconButton onClick={handleViewDoc}>
            <Tooltip title="View File">
              <VisibilityIcon />
            </Tooltip>
          </IconButton>
        </div>
      )}
      <Modal open={openModal} onClose={handleCloseModal}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            outline: 'none',
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          {selectedFile && (
            <PDFViewerComponent selectedFile={selectedFile} fileSource="local" handleCloseModal={handleCloseModal} />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default UploadPDFComponent;
