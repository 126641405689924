
const reset = (payload) => {
  return {
    type: "reset",
    payload,
  };
};

const setAdmin = (payload) => {
  return {
    type: "admin",
    payload,
  };
};

const setID = (payload) => {
  return {
    type: "_id",
    payload,
  };
};
const setEmployeeID = (payload) => {
  return {
    type: "employeeID",
    payload,
  };
};
const setDepartmentID = (payload) => {
  return {
    type: "departmentID",
    payload,
  };
};
const setStatus = (payload) => {
  return {
    type: "status",
    payload,
  };
};
const setFirstName = (payload) => {
  return {
    type: "firstName",
    payload,
  };
};
const setLastName = (payload) => {
  return {
    type: "lastName",
    payload,
  };
};
const setPhone = (payload) => {
  return {
    type: "phone",
    payload,
  };
};
const setEmail = (payload) => {
  return {
    type: "email",
    payload,
  };
};
const setGroups = (payload) => {
  return {
    type: "groups",
    payload,
  };
};
const setServices = (payload) => {
  return {
    type: "services",
    payload,
  };
};

const setRiskServices = (payload) => {
  return {
    type: "riskServices",
    payload,
  };
};

const setUsername = (payload) => {
  return {
    type: "username",
    payload,
  };
};
export { reset, setAdmin, setID, setEmployeeID, setDepartmentID, setStatus, setFirstName, setLastName, setPhone, setEmail, setGroups, setServices, setUsername, setRiskServices  };
