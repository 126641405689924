import React from "react";
import config from "config.js"
import MUIDataTable from "mui-datatables";
import libs from "controllers/libs";
import SweetAlert from "react-bootstrap-sweetalert";
import PulseLoader from "react-spinners/PulseLoader";
import BounceLoader from "react-spinners/BounceLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import axios from "axios";
import { creatCsvFile, downloadFile } from "download-csv";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import EditIcon from "@material-ui/icons/Edit";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete"
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import CustomSelectStyle from "assets/jss/material-dashboard-react/customSelectStyle";
const override = css`
  padding: 0;
  margin: 0;
`;

const styles = {
  ...CustomSelectStyle,
  ...SweetAlertStyle,
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    float: "left",
    display: "inlineBlock",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },

  },
  addButton: {
    float: "right !important",
    display: "inlineBlock",
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  selectLabel: {
    color: "#AAAAAA !important",
    fontSize: "14px",
    fontWeight: "400",
  },
  formControl: {
    margin: "27px 0 0 0",
    textAlign: "left",
  },
  cardFooter: {
    textAlign: "center !important",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "20px",
  },
  addGroupContainer: {
    height: "49px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  addGroupButton: {
    marginRight: "-5px !important",
  },
  groupSelect: {
    textAlign: "left !important",
    paddingLeft: "10px",
  },
  required: {
    color: "red",
  },
};

const useStyles = makeStyles(styles);
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SystemUsers() {
  const classes = useStyles();
  const [users, setUsers] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const [userGroups, setUserGroups] = React.useState([]);
   const [csvData, setCsvData] = React.useState([]);
  const [showGroups, setShowGroups] = React.useState(false);
  const [showUserTable, setShowUserTable] = React.useState(false);
  const [showAddUser, setShowAddUser] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [respAlert, setRespAlert] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(true);
  const [heading, setHeading] = React.useState("Add User");
  const [buttonLabel, setButtonLabel] = React.useState("ADD");
  const [editUserID, setEditUserID] = React.useState("");
  const [values, setValues] = React.useState({
    fnameErr: false,
    lnameErr: false,
    depErr: false,
    empIDErr: false,
    phoneErr: false,
    emailErr: false,
    grpErr: false,
    serviceErr: false,
    stsErr: false,
  });

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [department, setDepartment] = React.useState("");
  const [employeeID, setEmployeeID] = React.useState("");
  const [phoneNo, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [groupID, setsGroupID] = React.useState("");
  const [services, setServices] = React.useState("");
  const [status, setStatus] = React.useState("true");

  const [selectedGroup, setSelectedGroup] = React.useState("");

  const columns = [
    {
      name: "phone",
      label: "Mobile",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "cpr",
    //   label: "CPR",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    // {
    //   name: "passportNo",
    //   label: "Passport No",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    // {
    //   name: "associatedCorporate",
    //   label: "Associated Corporate",
    //   options: {
    //     filter: false,
    //     sort: false,
    //   },
    // },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "updatedStatus",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "actions",
      label: " Actions",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];
  const options = {
    selectableRows: false,
    elevation: 0,
    onDownload: () => {
      let csvFile = creatCsvFile(csvData);
      let fileName = "system_users.csv";
      downloadFile(csvFile, fileName);
      return false;
    },
    textLabels: {
      body: {
        noMatch: "No matching records found",
      },
    }
  };

  const hideAlert = () => {
    setRespAlert(null);
  };

  const handleAddClick = () => {
    //function to  handle user add button click
    setShowUserTable(false);
    setShowAddUser(true);
    setButtonLabel("ADD");
    setHeading("Add User");
  };


  const handleEditClick = (user) => {
    setShowUserTable(false);
    setShowAddUser(true);
    setButtonLabel("SAVE");
    setHeading("Edit user");
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setPhone(user.phone);
    setEmail(user.email);
    setEmployeeID(user.employeeID);
    setEditUserID(user._id);
    setDepartment(user.departmentID);
    setStatus(user.status)
    //function to handle edit click
  };

  const deleteGroup = (groupID, userID) => {
    //function to delete group from user
    axios.post(`${config.url().baseURL}/delete-user-group`, { groupID: groupID, userID: userID },
      {
        headers: {
          auth: sessionStorage.getItem("cx_id"),
        },
      }
    ).then((resp) => {
      let respData = resp.data;
      if (!respData.err) {
        setRespAlert(
          <SweetAlert
            success
            style={{ display: "block" }}
            title="Group removed successfully."
            onConfirm={() => {
              fetchUserGroups(userID)
              setRespAlert(null)
            }}
            confirmBtnCssClass={classes.button + " " + classes.success}
          />
        );
      } else {
        openSnackBar(respData.msg);
        setSubmitting(false);
      }
    })
      .catch((err) => {
        openSnackBar("Unable to process.");
      });
  }

  const handleGroupsClick = (id) => {
    //function to handle group button click
    setShowUserTable(false);
    setShowGroups(true);
    setEditUserID(id);
    fetchUserGroups(id);
  };

  const fetchUserGroups = (id) => {
    //function to fetch user groups
    axios.get(`${config.url().baseURL}/get-user-groups/${id}`, {
      headers: {
        auth: sessionStorage.getItem("cx_id"),
      },
    })
      .then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          let temp = [];
          console.log(respData.data);
          respData.data.map((group, key) => {
            temp.push([key + 1, group.groupName,
            <Tooltip placement="right" title="Delete">
              <IconButton
                onClick={() => deleteGroup(group._id, id)}
                style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            ]);
            return 0;
          });
          setUserGroups(temp);
        } else {
          setUserGroups([]);
          openSnackBar(respData.msg);
        }
      })
      .catch((err) => {
        openSnackBar("Unable to fetch user groups.");
      });
  };

  const handleBackClick = () => {
    //function to handle back button click
    setUserGroups([]);
    setShowUserTable(true);
    setShowGroups(false);
    setShowAddUser(false);
    setSubmitting(false);
    setSelectedGroup("");
    setFirstName("");
    setLastName("");
    setDepartment("");
    setEmployeeID("");
    setPhone("");
    setEmail("");
    setsGroupID("");
    setServices("");
    setStatus("");
    setValues({
      ...values,
      fnameErr: false,
      lnameErr: false,
      depErr: false,
      empIDErr: false,
      phoneErr: false,
      emailErr: false,
      grpErr: false,
      serviceErr: false,
      stsErr: false,
    });
  };

  const openSnackBar = (msg) => {
    //function to tost snackbar message
    setOpenSnack(true);
    setSnackBarMsg(msg);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  };

  const successAdd = () => {
    hideAlert();
    fetchUsers();
    setShowUserTable(true);
    setShowGroups(false);
    setShowAddUser(false);
    setSubmitting(false);
    setSelectedGroup("");
    setFirstName("");
    setLastName("");
    setDepartment("");
    setEmployeeID("");
    setPhone("");
    setEmail("");
    setsGroupID("");
    setServices("");
    setStatus("");
  };

  const validateForm = () => {
    //function to validate user creation form
    const reEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let flag = true,snackBarMsg="";
    let fnameErr = false,
      lnameErr = false,
      depErr = false,
      empIDErr = false,
      phoneErr = false,
      emailErr = false,
      grpErr = false,
      serviceErr = false,
      stsErr = false;

    if (firstName.length < 1) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter first name." : snackBarMsg);
      fnameErr = true;
      flag = false;
    }
    if (lastName.length < 1) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter last name." : snackBarMsg);
      lnameErr = true;
      flag = false;
    }
    if (employeeID.length < 1) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter employee id." : snackBarMsg);
      empIDErr = true;
      flag = false;
    }
    if (phoneNo.length < 1) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter phone number." : snackBarMsg);
      phoneErr = true;
      flag = false;
    }
    if (!reEmail.test(email)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter email." : snackBarMsg);
      emailErr = true;
      flag = false;
    }
    if (!flag) {
      openSnackBar(snackBarMsg)
    }
    // if (groupID.length < 1) {
    //   grpErr = true;
    //   flag = false;
    // }
    // if (services.length < 1) {
    //   serviceErr = true;
    //   flag = false;
    // }
    // if (status.length < 1) {
    //   stsErr = true;
    //   flag = false;
    // }
    setValues({
      ...values,
      fnameErr: fnameErr,
      lnameErr: lnameErr,
      depErr: depErr,
      empIDErr: empIDErr,
      phoneErr: phoneErr,
      emailErr: emailErr,
      grpErr: grpErr,
      serviceErr: serviceErr,
      stsErr: stsErr,
    });
    return flag;
  };

  const successGroupAdd = () => {
    //function to call after successful addition on group to user
    hideAlert();
    setSubmitting(false);
    fetchUserGroups(editUserID);
  };

  const submitUser = () => {
    //function to submit user for adding and updating
    let valid = validateForm();
    if (valid) {
      if (!submitting) {
        setSubmitting(true);
        if (buttonLabel === "ADD") {
          axios
            .post(
              `${config.url().baseURL}/create-system-user`,
              {
                email: email,
                phone: phoneNo,
                firstName: firstName,
                lastName: lastName,
                employeeID: employeeID,
                departmentID: "60af903742d4b6834cbbce45",
                status: status
              },
              {
                headers: {
                  auth: sessionStorage.getItem("cx_id"),
                },
              }
            )
            .then((resp) => {
              let respData = resp.data;
              if (!respData.err) {
                setRespAlert(
                  <SweetAlert
                    success
                    style={{ display: "block" }}
                    title="User added successfully."
                    onConfirm={() => successAdd()}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                  />
                );
              } else {
                openSnackBar(respData.msg);
                setSubmitting(false);
              }
            })
            .catch((err) => {
              openSnackBar("Unable to process.");
            });
        } else {
          axios.put(`${config.url().baseURL}/edit-system-user/${editUserID}`,
            {
              departmentID: "60af903742d4b6834cbbce45",
              employeeID: employeeID,
              email: email,
              phone: phoneNo,
              firstName: firstName,
              lastName: lastName,
              status: status
            },
            {
              headers: {
                auth: sessionStorage.getItem("cx_id"),
              },
            }
          )
            .then((resp) => {
              let respData = resp.data;
              if (!respData.err) {
                setRespAlert(
                  <SweetAlert
                    success
                    style={{ display: "block" }}
                    title="User updated."
                    onConfirm={() => successAdd()}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                  />
                );
              } else {
                openSnackBar(respData.msg);
                setSubmitting(false);
              }
            })
            .catch((err) => {
              openSnackBar("Unable to process.");
            });
        }
      }
    }/*  else {
      openSnackBar("Empty or Incorrect values.");
    } */
  };

  React.useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    //function to fetch users
    axios
      .get(`${config.url().baseURL}/get-system-users`, {
        headers: {
          auth: sessionStorage.getItem("cx_id"),
        },
      })
      .then((resp) => {
        let respData = resp.data;
        fetchGroups();
        if (!respData.err) {
          var updatedData = respData.data.map((user) => {
            user.actions = (
              <>
                <Tooltip placement="top" title="Groups">
                  <IconButton
                    onClick={() => handleGroupsClick(user._id)}
                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                    aria-label="delete">
                    <GroupWorkIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip placement="top" title="Edit">
                  <IconButton
                    onClick={() => handleEditClick(user)}
                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                    aria-label="delete">
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </>
            );

            if (user.status == "true") {
              user.updatedStatus = (
                <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="Active" />
              );
            } else {
              user.updatedStatus = (
                <Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="Inactive" />
              );
            }


            // user.updatedStatus = (
            //   <Tooltip placement="bottom" title="Change Status">
            //     <Switch
            //       checked={status}
            //       onClick={changeUserStatus(user.status, user._id)}
            //     />
            //   </Tooltip>
            // )
            user.name = user.firstName + " " + user.lastName;
            return user;
          });
          setUsers(updatedData);
          setShowUserTable(true);
          setShowLoader(false);
          var updatedCsvData = respData.data.map((user) => {
            let temp = {};
            temp.User_ID = user._id;
            temp.Employee_ID = user.employeeID;
            temp.First_Name = user.firstName;
            temp.Last_Name = user.lastName;
            temp.Phone = user.phone;
            temp.Email = user.email;
            temp.Status = user.status;
            return temp;
          });
          setCsvData(updatedCsvData);
        } else {
          setUsers([]);
          setCsvData([]);
          openSnackBar(respData.msg);
          setShowUserTable(true);
          setShowLoader(false);
        }
      })
      .catch((err) => {
        openSnackBar("Unable to fetch user groups.");
      });
  };


  const fetchGroups = () => {
    //function to fetch groups data
    axios
      .get(`${config.url().baseURL}/get-groups`, {
        headers: {
          auth: sessionStorage.getItem("cx_id"),
        },
      })
      .then((resp) => {
        // fetchDepartments();
        let respData = resp.data;
        if (!respData.err) {
          setGroups(respData.data);
        } else {
          setGroups([]);
          openSnackBar(respData.msg);
        }
      })
      .catch((err) => {
        openSnackBar("Unable to fetch user groups.");
      });
  };

  
  const submitGroup = () => {
    //function to submit user group
    setSubmitting(true);
    if (selectedGroup !== "") {
      axios.put(
        `${config.url().baseURL}/group-add-user/${selectedGroup}`,
        {
          userid: editUserID,
        },
        {
          headers: {
            auth: sessionStorage.getItem("cx_id"),
          },
        }
      )
        .then((resp) => {
          let respData = resp.data;
          if (!respData.err) {
            setRespAlert(
              <SweetAlert
                success
                style={{ display: "block" }}
                title="Group Added."
                onConfirm={() => successGroupAdd()}
                confirmBtnCssClass={classes.button + " " + classes.success}
              />
            );
          } else {
            openSnackBar(respData.msg);
            setSubmitting(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
          openSnackBar("Unable to process.");
        });
    } else {
      setSubmitting(false);
      openSnackBar("Please select the group.");
    }
  };
  return (
    <div>
      {respAlert}
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showLoader}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
          </Collapse>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showUserTable}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>System Users</h4>
                <Button onClick={() => handleAddClick()} color="secondary" size="sm" className={classes.addButton}>
                  Add User
                </Button>
              </CardHeader>
              <CardBody>
                <MUIDataTable data={users} columns={columns} options={options} />
              </CardBody>
            </Card>
          </Collapse>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Collapse
            in={showAddUser}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{heading}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomInput
                      labelText={
                        <span>
                          First Name <small className={classes.required}>*</small>
                        </span>
                      }
                      error={values.fnameErr}
                      id="first-name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: firstName,
                        onChange: (e) => {
                          setFirstName(libs.validate(e.target.value, "alphabet", 50) ? e.target.value : firstName);
                          setValues({ ...values, fnameErr: false });
                        },
                        onBlur: (e) => {
                          setFirstName(e.target.value.trim());
                        } 
                        
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomInput
                      error={values.lnameErr}
                      labelText={
                        <span>
                          Last Name <small className={classes.required}>*</small>
                        </span>
                      }
                      id="last-name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: lastName,
                        onChange: (e) => {
                          setLastName(libs.validate(e.target.value, "alphabet", 50) ? e.target.value : lastName);
                          setValues({ ...values, lnameErr: false });
                        },
                          onBlur: (e) => {
                              setLastName(e.target.value.trim());
                            } 
                      }}
                    />
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={7}>
                    <FormControl className={classes.formControl} error={values.depErr} fullWidth>
                      <InputLabel className={classes.selectLabel}>Department</InputLabel>
                      <Select
                        id="department-select"
                        value={department}
                        onChange={(e) => {
                          setDepartment(e.target.value);
                          setValues({ ...values, depErr: false });
                        }}>
                        {departments.map((dep) => {
                          return <MenuItem value={dep._id}>{dep.deptName}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </GridItem> */}
                  {/* <GridItem xs={12} sm={12} md={4}>
                    <FormControl className={classes.formControl} error={values.grpErr} fullWidth>
                      <InputLabel className={classes.selectLabel}>Group</InputLabel>
                      <Select
                        id="group-select"
                        value={groupID}
                        onChange={(e) => {
                          setsGroupID(e.target.value);
                          setValues({ ...values, grpErr: false });
                        }}>
                        {groups.map((group) => {
                          return <MenuItem value={group._id}>{group.groupName}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      error={values.empIDErr}
                      labelText={
                        <span>
                          Employee ID <small className={classes.required}>*</small>
                        </span>
                      }
                      id="empID"
                      inputProps={{
                        value: employeeID,
                        onChange: (e) => {
                         /*  const re = /^[^*|\":<>[\]{}`\\()';@&$]+$/;
                          if (e.target.value === "" || re.test(e.target.value)) {
                            if (e.target.value.length <= 10) {
                              setEmployeeID(e.target.value);
                            }
                          } else {
                            setValues({ ...values, empIDErr: true });
                          }
                          if (e.target.value.length < 10) {
                            setValues({ ...values, empIDErr: true });
                          } else {
                            setValues({ ...values, empIDErr: false });
                          }

                        }, */
                        setEmployeeID(libs.validate(e.target.value, "alphanumeric", 50) ? e.target.value : employeeID);
                        setValues({ ...values, lnameErr: false });
                      },
                        onBlur: (e) => {
                          setEmployeeID(e.target.value.trim());
                          } 

                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      error={values.phoneErr}
                      labelText={
                        <span>
                          Phone Number <small className={classes.required}>*</small>
                        </span>
                      }
                      id="phoneNo"
                      inputProps={{
                        value: phoneNo,
                        onChange: (e) => {
                         /*  const re = /^[0-9\b]+$/;
                          if (e.target.value === "" || re.test(e.target.value)) {
                            if (e.target.value.length <= 8) {
                              setPhone(e.target.value);
                              setValues({ ...values, phoneErr: false });
                            }
                          }

                          if (e.target.value.length < 8) {
                            setValues({ ...values, phoneErr: true });
                          } else {
                            setValues({ ...values, phoneErr: false });
                          }

                        }, */
                        var res = e.target.value.substring(0, 1)
                        if (res == 3 || res == 6 || e.target.value == "") {
                        setPhone(libs.validate(e.target.value, "number", 8) ? e.target.value : phoneNo);
                          setValues({ ...values, fnameErr: false });
                        }
                        },
                      
                        onBlur: (e) => {
                          setPhone(e.target.value.trim());
                        } 
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      error={values.emailErr}
                      labelText={
                        <span>
                          Email <small className={classes.required}>*</small>
                        </span>
                      }
                      id="email"
                      inputProps={{
                        type: "email",
                        value: email,
                        onChange: (e) => {
                          let reEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                          setEmail(e.target.value);
                          setValues({ ...values, emailErr: false });
                          if (!reEmail.test(e.target.value)) {
                            setValues({ ...values, emailErr: true });
                          } else {
                            setValues({ ...values, emailErr: false });
                          }
                        },
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <br />
                    <FormControl style={{ textAlign: "left" }} fullWidth>
                      <InputLabel className={classes.selectLabel}>
                        Status <small className={classes.required}>*</small>
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        id="group-select"
                        value={status}
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}>
                        <MenuItem classes={{
                          root: classes.selectMenuItem,
                        }} value="true">Active</MenuItem>
                        <MenuItem classes={{
                          root: classes.selectMenuItem,
                        }} value="false">Inactive</MenuItem>;
                      </Select>
                    </FormControl>
                  </GridItem>
                  {/* 
                  <GridItem xs={12} sm={12} md={4}>
                    <FormControl className={classes.formControl} error={values.serviceErr} fullWidth>
                      <InputLabel className={classes.selectLabel}>Services/Responsibility ID</InputLabel>
                      <Select
                        id="group-select"
                        value={services}
                        onChange={(e) => {
                          setServices(e.target.value);
                          setValues({ ...values, serviceErr: false });
                        }}>
                        <MenuItem value={10}>test</MenuItem>
                        <MenuItem value={20}>test</MenuItem>
                        <MenuItem value={30}>test</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <FormControl className={classes.formControl} error={values.stsErr} fullWidth>
                      <InputLabel className={classes.selectLabel}>Status</InputLabel>
                      <Select
                        id="group-select"
                        value={status}
                        onChange={(e) => {
                          setStatus(e.target.value);
                          setValues({ ...values, stsErr: false });
                        }}>
                        <MenuItem value={10}>test</MenuItem>
                        <MenuItem value={20}>test</MenuItem>
                        <MenuItem value={30}>test</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem> */}
                  <CardFooter className={classes.cardFooter}>
                    <Button onClick={() => handleBackClick()} size="md" simple color="primary">
                      <ArrowBackIcon />
                      Back
                    </Button>
                    <Button onClick={() => submitUser()} size="md" color="primary">
                      {!submitting ? (
                        buttonLabel
                      ) : (
                        <PulseLoader color="#fff" loading={true} css={override} size={5} margin={2} />
                      )}
                    </Button>
                  </CardFooter>
                </GridContainer>
              </CardBody>
            </Card>
          </Collapse>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Collapse
            in={showGroups}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <h3>Select Group</h3>
            <Paper component="form" className={classes.addGroupContainer}>
              <FormControl className={classes.groupSelect} error={values.depErr} fullWidth>
                <Select
                  id="group-select"
                  value={selectedGroup}
                  disableUnderline
                  onChange={(e) => {
                    setSelectedGroup(e.target.value);
                  }}>
                  {groups.map((group) => {
                    return <MenuItem value={group._id}>{group.groupName}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              {!submitting ? (
                <Button className={classes.addGroupButton} onClick={() => submitGroup()} size="lg" color="primary">
                  Add
                </Button>
              ) : (
                <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
              )}
            </Paper>
            {userGroups.length === 0 ? <h4> This User is not associated with any other group</h4> : null}
            <Collapse
              in={userGroups.length !== 0}
              style={{ transformOrigin: "0 0 0", textAlign: "center" }}
              {...(true ? { timeout: 500 } : {})}>
              <Table tableHeaderColor="primary" tableHead={["#", "Group Name", ""]} tableData={userGroups} />
            </Collapse>
            <Button onClick={() => handleBackClick()} size="lg" simple color="primary">
              <ArrowBackIcon />
              Back
            </Button>
          </Collapse>
        </GridItem>
      </GridContainer>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
            setSnackBarMsg("");
          }}
          severity="error">
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
