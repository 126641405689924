import * as React from 'react';
import config from "config.js"
import axios from "axios";
import { useSelector } from 'react-redux';
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import JSONPretty from 'react-json-pretty';
import Typography from "@material-ui/core/Typography";
import Button from '@mui/lab/LoadingButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SimpleButton from "components/CustomButtons/Button.js";
import moment from 'moment';
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import MoonLoader from "react-spinners/MoonLoader";


import { Document, Page } from 'react-pdf';
import Slide from "@material-ui/core/Slide";

import DialogContent from "@material-ui/core/DialogContent";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import Dropzone from "react-dropzone";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete"
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';


const override = css`
    display: block;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },
    button: {
        margin: "25px"
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function AdjustmentDetails(props) {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [adjustment, setAdjustment] = React.useState(null)
    const [viewLog, setViewLog] = React.useState(false)
    const [showLoader, setShowLoader] = React.useState(true)
    const [document, setDocument] = React.useState(null)

    const [remarks, setRemarks] = React.useState("")
    const [openRemarks, setOpenRemarks] = React.useState(false)
    const [status, setStatus] = React.useState(false)
    const [approvalBtn, setApprovalBtn] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);

    const [noDoc, setNoDoc] = React.useState(false)
    const [documentName, setDocumentName] = React.useState(null);
    const [openImageViewer, setOpenImageViewer] = React.useState(false);
    const [openPDFeViewer, setOpenPDFViewer] = React.useState(false);
    const [numPages, setNumPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);

    React.useEffect(() => {
        if (props.id !== null || undefined) {
            fetchAdjustmentDetails(props.id)
        }
    }, []);

    const fetchAdjustmentDetails = async (id) => {
        //function to fetch adjustment details
        try {
            let resp = await axios.get(`${config.url().baseURL}/get-adjustment/${id}`)
            if (resp.data.err) {
                props.snack(resp.data.msg)
                props.back()
            } else {
                fetchAdjustmentDocument(id)
                setShowLoader(false)
                setAdjustment(resp.data.data)
                checkUserType(resp.data.data.status)
            }
        } catch (error) {
            props.back()
            props.snack("Unable to fetch adjustment details.")
        }
    }

    const fetchAdjustmentDocument = async (id) => {
        //function to fetch adjustment document 
        try {
            let resp = await axios.get(`${config.url().baseURL}/get-adjustment-document/${id}`)
            if (resp.data.err) {
                props.snack(resp.data.msg)
                setDocumentName("No Document found.")
                setNoDoc(true)
            }
            else {
                let file = resp.data.data.Document
                setDocument(resp.data.data.Document)
                setDocumentName(`${id}.${file.substring(file.indexOf("/") + 1, file.indexOf(";base64"))}`)
            }
        } catch (error) {
            console.log(error)
            setNoDoc(true)
            setDocumentName("No Document found.")
            props.snack(`Unable to fetch adjustment document.`)
        }
    }

    const handleStatusClick = async (newStatus) => {
        //function to handle status change click
        setStatus(newStatus)
        setOpenRemarks(true)
    }

    const changeStatus = async () => {
        //function to change status of adjustment
        if (!submitting) {
            setSubmitting(true)
            if (remarks.length == 0) {
                props.snack(`Please enter remarks.`)
                setSubmitting(false)
            }
            else {
                try {
                    let resp = await axios.post(`${config.url().baseURL}/level-up-adjustment/${adjustment._id}`, { remarks, action: status })
                    setSubmitting(false)
                    if (resp.data.err) {
                        setOpenRemarks(false)
                        setRemarks("")
                        props.snack(resp.data.msg)
                    }
                    else props.openAlert("success", "Status Updated", resp.data.msg)
                } catch (error) {
                    props.snack(`Unable to ${status} adjustment.`)
                }
            }
        }
    }


    const checkUserType = (status) => {
        //function to check user  type
        let service = user.services.find(service => service.name == "Adjustments")
        let riskService = user.riskServices.find(riskService => riskService.name == "Adjustments")
        if (status == "Created") {
            if ((service && service.type == "checker") || (riskService && riskService.type == "checker")) {
                setApprovalBtn(true)
            } else {
                setApprovalBtn(false)
            }
        } else {
            setApprovalBtn(false)
        }
    }

    const idViewer = async (file) => {
        //function to view docs uploaded
        let extension = file.substring(file.indexOf("/") + 1, file.indexOf(";base64"));
        if (extension == "pdf") {
            setDocument(file)
            setOpenPDFViewer(true)
        } else {
            setDocument(file)
            setOpenImageViewer(true)
        }
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <>
            <Collapse
                in={showLoader}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
            </Collapse>
            <Collapse
                in={!showLoader}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Adjustment Details</h4>
                    </CardHeader>
                    <CardBody>
                        <Table
                            tableData={[
                                ["Date", ":", adjustment && adjustment.date ? `${moment(adjustment.date).format("DD/MM/YYYY")} ${moment(adjustment.date).format("hh:mm:ss A")} ` : null],
                                ["Reference Number", ":", adjustment && adjustment.ReferenceNumber ? adjustment.ReferenceNumber : null],
                                ["Transaction File Type", ":", adjustment && adjustment.TransactionFileType ? adjustment.TransactionFileType : null],
                                ["Customer", ":", adjustment && adjustment.Customer ? adjustment.Customer : null],
                                ["Account", ":", adjustment && adjustment.Account ? adjustment.Account : null],
                                ["Balance", ":", adjustment && adjustment.Balance !== (null || undefined) ? adjustment.Balance : null],
                                ["Fee settlement Account", ":", adjustment && adjustment.FeeSettlementAccount ? adjustment.FeeSettlementAccount : null],
                                ["Currency Code", ":", adjustment && adjustment.CurrencyCode ? adjustment.CurrencyCode : null],
                                ["Amount", ":", adjustment && adjustment.Amount ? adjustment.Amount : null],
                                ["Document", ":", documentName ?
                                    noDoc ? <span> {documentName}</span>
                                        :
                                        <span>
                                            {documentName}
                                            <Tooltip placement="bottom" title="View Document">
                                                <IconButton
                                                    onClick={() => idViewer(document)}
                                                    style={{ marginTop: "-10px", marginBottom: "-10px", color: "#014FA4" }}
                                                    aria-label="delete">
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </span>
                                    : <MoonLoader color="#004fa4" loading={true} css={override} size={18} />]
                            ]}
                        />
                        <div style={{ textAlign: "center", marginTop: "35px" }}>
                            <Button size="sm" simple color="primary" className={classes.button} onClick={() => props.back()}><ArrowBackIcon />Back</Button> <Button className={classes.button} color="primary" onClick={() => setViewLog(!viewLog)} variant="contained"> {viewLog ? "Hide Log" : "View Log"}</Button> {approvalBtn ? <><Button className={classes.button} color="warning" onClick={() => handleStatusClick("reject")} variant="contained">Reject</Button> <Button className={classes.button} color="success" onClick={() => handleStatusClick("approve")} variant="contained">Approve</Button></> : null}
                        </div>
                        <br />
                        <Collapse
                            in={viewLog}
                            style={{ transformOrigin: "0 0 0" }}
                            {...(true ? { timeout: 500 } : {})}>
                            {
                                adjustment && adjustment.log ? adjustment.log.map(log => {
                                    return (
                                        <>
                                            <Accordion  >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header">
                                                    <Typography>{log.status}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails  >
                                                    <div style={{ width: "750px !important" }}>
                                                        <JSONPretty data={log} />
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </>
                                    )
                                }) : null
                            }
                        </Collapse>
                    </CardBody>
                </Card>
            </Collapse>
            <Dialog onClose={() => {
                setOpenRemarks(false)
                setRemarks("")
            }} aria-labelledby="simple-dialog-title" PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }} open={openRemarks}>
                <h4 className={classes.passwordHead}>Please enter remarks.</h4>
                <Paper className={classes.dialogContainer}>
                    <InputBase
                        autoFocus={true}
                        fullWidth
                        className={classes.inputPassword}
                        placeholder="Remarks"
                        inputProps={{
                            type: "text",
                            onChange: (event) => { setRemarks(event.target.value) },
                            onBlur: (e) => { setRemarks(e.target.value.trim()) },
                            value: remarks,
                        }}
                    />

                    <SimpleButton className={classes.nextButton}
                        disabled={submitting}
                        onClick={() => changeStatus()}
                        simple
                        size="lg" color="primary">
                        <ArrowForwardIcon fontSize="medium" />
                    </SimpleButton>
                </Paper>
            </Dialog>
            <Dialog
                style={{ textAlign: "center" }}
                open={openPDFeViewer}
                TransitionComponent={Transition}
                onClose={() => {
                    setOpenPDFViewer(false);

                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <Document
                        file={document}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <p>Page {pageNumber} of {numPages}</p>
                    <SimpleButton size="sm" color="primary" onClick={() => {
                        if (pageNumber < 2) {
                            setPageNumber(1)
                        } else {
                            let newPageNumber = pageNumber - 1
                            setPageNumber(newPageNumber)
                        }
                    }} >Pervious</SimpleButton>
                    <SimpleButton size="sm" color="primary"
                        onClick={() => {
                            if (numPages > pageNumber) {
                                let newPageNumber = pageNumber + 1
                                setPageNumber(newPageNumber)
                            } else {
                                setPageNumber(numPages)
                            }
                        }}
                    >Next</SimpleButton>
                </DialogContent>
            </Dialog>
            {
                openImageViewer && (
                    <Lightbox
                        image={document}
                        onClose={() => setOpenImageViewer(false)}
                    />
                )
            }
        </>
    );
}