import React from "react";
import PropTypes from 'prop-types';
import moment from "moment";
import { Chart } from "react-google-charts";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import AccessTime from "@material-ui/icons/AccessTime";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Danger from "components/Typography/Danger.js";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
// import AppWidgetSummary from "./Components/AppWidgetSummary";

import axios from "axios";
import config from "config.js"

import Collapse from "@material-ui/core/Collapse";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import PropagateLoader from "react-spinners/PropagateLoader";
import Skeleton from '@mui/material/Skeleton';
import { css } from "@emotion/core";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';

import { Doughnut } from 'react-chartjs-2';



import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
require('chartist-plugin-legend');

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(styles);
const override = css`
    display: block;
    border-color: red;
  `;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
    // hAxis: {
    //   title: "Time",
    // },
    // vAxis: {
    //   title: "Popularity",
    // },
    // series: {
    //   1: { curveType: "function" },
    // },
  },
};
// export const data = [
//   ["x", "dogs", "cats"],
//   [0, 0, 0],
//   [1, 10, 5],
//   [2, 23, 15],
//   [3, 17, 9],
//   [4, 18, 10],
//   [5, 9, 5],
//   [6, 11, 3],
//   [7, 27, 19],
// ];

export default function Dashboard() {
  const classes = useStyles();
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [tranData, setTranData] = React.useState([]);
  const [tranFetched, setTranFetched] = React.useState(new Date());
  const [usersData, setUsersData] = React.useState([]);
  const [remittanceData, setRemittanceData] = React.useState("");
  const [usersFetched, setUsersFetched] = React.useState(new Date());
  const [failedRemittanceCount, setFailedRemittanceCount] = React.useState([]);
  const [failedCprUser, setFailedCprUser] = React.useState([]);
  const [remittanceMonth, setRemittanceMonth] = React.useState([]);
  const [remittancePreMonth, setRemittancePreMonth] = React.useState([]);
  const [remittanceDate, setRemittanceDate] = React.useState([]);
  const [userTypeCount, setTypeUserCount] = React.useState(
    {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 1,
        },
      ],
    }
  );
  //  const data1 = [
  //   ["Date", "Previous Remittance", "Remittance"],
  //   [remittanceMonth]
  // ]


  function openSnackBar(msg) {
    //function to tost snackbar message
    setOpenSnack(true);
    setSnackBarMsg(msg);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  }

  const getTransactionData = () => {
    //function to get transaction data
    try {
      axios.get(`${config.url().app_URL}/CXPortal/v4/dashboard-data`)
        .then(async (resp) => {
          if (!resp.data.err) {
            resp.data.data.stats.map((datas, index) => {
              if (index != 0) {
              resp.data.data.stats[index][0] = datas[0].split("-")[0];
              }
              })
            setTranData(resp.data.data.stats);
            console.log(resp.data.data.stats);
            let chartDate = moment(resp.data.data.stats[1][0]).format("MMM/DD/YYYY");
            console.log(chartDate)
            chartDate = moment(chartDate).format("DD")
            console.log(chartDate);
            // setTranData([resp.data.data.stats[0][0],resp.data.data.stats[0][3],resp.data.data.stats[1][3]]);
            setTranFetched(new Date())
            if (tranFetched) {
              setRemittanceData(resp.data.data.stats[1][3])
              setFailedRemittanceCount(resp.data.data.failedRemittanceCount)

            }
          } else openSnackBar(resp.data.msg);
          // let chartData
          // resp.data.data.stats.map(element => {
          //  chartData=[element[0],element[2],element[3]]
          // setRemittanceDate.push(moment(element[0][0]).format('d'))
          // setRemittancePreMonth.push(element[0][2])
          // setRemittanceMonth.push(element[0][3])
          //   setRemittanceMonth(chartData);
          // });

        })
        .catch((err) => {
          console.log(err);
          openSnackBar("Unable fetch transactions data.");
        });
    } catch (error) {
      openSnackBar("Unable fetch transactions data.")
    }
  }

  const getUsersData = () => {
    //function to get transaction data
    try {
      axios.get(`${config.url().app_URL}/CXPortal/v4/dashboard-data-usercount`)
        .then(async (resp) => {
          if (!resp.data.err) {
            resp.data.data.stats.map((datas, index) => {
              if (index != 0) {
              resp.data.data.stats[index][0] = datas[0].split("-")[0];
              }
              })
            setUsersData(resp.data.data.stats)
            setUsersFetched(new Date())
            setFailedCprUser(resp.data.data.userFailedAtCprPage)
          } else openSnackBar(resp.data.msg);
        })
        .catch((err) => {
          console.log(err);
          openSnackBar("Unable fetch users data.");
        });
    } catch (error) {
      openSnackBar("Unable fetch users data.")
    }
  }
  const getUserCountByType = async () => {
    //function to get user count by type
    try {
      let resp = await axios.get(`${config.url().baseURL}/get-user-count-by-type`)
      console.log(resp)
      if (resp.data.err) openSnackBar(resp.data.msg)
      else {
        setTypeUserCount({
          labels: resp.data.data.labels,
          datasets: [
            {
              data: resp.data.data.count,
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
              ],
              borderWidth: 1,
            },
          ],
        }
        )
      }
    } catch (error) {
      console.log(error)
      openSnackBar("Unable to fetch user count by type.")
    }
  }


  React.useEffect(() => {
    getTransactionData()
    getUsersData()
    getUserCountByType()
    console.log(tranData);
    const interval = setInterval(() => {
      getTransactionData()
      getUsersData()
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <AccountBalanceWalletIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Remittances</p>
              <h3 className={classes.cardTitle}>
                {remittanceData}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                {/* <Danger>
                  <Warning />
                </Danger> */} <DateRange />
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  {moment(tranFetched).format("DD-MMM-YYYY")}
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <AccountBalanceWalletIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Failed Remittance</p>
              <h3 className={classes.cardTitle}>{failedRemittanceCount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  {moment(tranFetched).format("DD/MM/YY")}
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Users failed in CPR page</p>
              <h3 className={classes.cardTitle}>{failedCprUser} </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                Total {moment(tranFetched).format("MMMM").toUpperCase()}
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      {/* <GridContainer> */}
        {/* <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <AccountBalanceWalletIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Biller 1</p>
              <h3 className={classes.cardTitle}>{ }</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  {moment(tranFetched).format("DD-MMM-YYYY")}
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}
        {/* <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <AccountBalanceWalletIcon />
              </CardIcon>
              <p className={classes.cardCategory}> Biller 2</p>
              <h3 className={classes.cardTitle}>{ }</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  {moment(tranFetched).format("DD-MMM-YYYY")}
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}
        {/* <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <AccountBalanceWalletIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Biller 3</p>
              <h3 className={classes.cardTitle}>{ }</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                <a href="pablo" onClick={(e) => e.preventDefault()}>
                  {moment(tranFetched).format("DD-MMM-YYYY")}
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}
      {/* </GridContainer> */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardBody>
              {
                tranData.length !== 0 ?
                  <Chart
                    chartType="Bar"
                    // chartType="LineChart"
                    width="100%"
                    height="400px"
                    data={tranData}
                    // data={remittanceMonth}
                    // options={options,
                    options={
                      {
                        chart: {
                          title: `Transactions - ${moment().format('MMMM')}`,
                        }
                      }

                    } />
                  :
                  <div>
                    <Skeleton
                      animation="wave"
                      height={25}
                      width="20%"
                      style={{ marginBottom: "-30px" }}
                    />
                    <Skeleton style={{ margin: "0px", padding: "0px", marginTop: "-100px", marginBottom: "-80px" }} animation="wave" height="550px" />
                  </div>
              }
            </CardBody>
            <CardFooter chart>
              {
                tranData.length !== 0 ?
                  <div className={classes.stats}>
                    <AccessTime /> updated {moment(tranFetched).fromNow()}
                  </div> :
                  <Skeleton
                    animation="wave"
                    height={25}
                    width="40%"
                    style={{ marginTop: "0px", marginBottom: "0px" }}
                  />
              }
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardBody>
              {
                usersData.length !== 0 ?
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={usersData}
                    options={{
                      chart: {
                        title: `Users- ${moment().format('MMMM')}`,
                      },
                    }}
                  />
                  :
                  <div>
                    <Skeleton
                      animation="wave"
                      height={25}
                      width="20%"
                      style={{ marginBottom: "-30px" }}
                    />
                    <Skeleton style={{ margin: "0px", padding: "0px", marginTop: "-100px", marginBottom: "-80px" }} animation="wave" height="550px" />
                  </div>
              }
            </CardBody>
            <CardFooter chart>
              {
                usersData.length !== 0 ?
                  <div className={classes.stats}>
                    <AccessTime /> updated {moment(usersFetched).fromNow()}
                  </div> :
                  <Skeleton
                    animation="wave"
                    height={25}
                    width="40%"
                    style={{ marginTop: "0px", marginBottom: "0px" }}
                  />
              }
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <Collapse in={1} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 500 } : {})}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={5}>
            <Card chart>
              <CardBody>
                <Doughnut
                  data={userTypeCount}
                />
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> updated {moment(tranFetched).fromNow()}
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </Collapse>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
            setSnackBarMsg("");
          }}
          severity="error">
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
