import * as React from 'react';
import axios from "axios";
import config from "config.js"
import DropZone from 'react-dropzone';
import Button from "components/CustomButtons/Button.js";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import bell from 'variables/bell.mp3';
import Collapse from "@material-ui/core/Collapse";


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

export default function IdUpload(props) {
    const [uploadStatus, setUploadStatus] = React.useState(0);
    const [failedDocs, setFailedDocs] = React.useState([]);

    const [progress, setProgress] = React.useState(0);
    const [filesCount, setFilesCount] = React.useState(0);
    const [uploaded, setUploaded] = React.useState(0);


    const employeeIdUpload = async (images) => {
        setFailedDocs([])
        setUploadStatus(1);
        let formSent = new FormData();
        let addedCount = 0
        let uploadedFiles = 0
        let totalFiles = 0
        let uploadingFiles = []
        if (images.length > 100) {
            props.openSnackBar(`Selected 100 documents out of  ${images.length}.`, true)
            setFilesCount(100)
            totalFiles = 100
            images.splice(100)
        }
        else {
            totalFiles = images.length
            setFilesCount(images.length)
            props.openSnackBar(`Selected ${images.length} documents.`, true)
        }

        const calculateProgress = async (count) => {
            addedCount = 0
            formSent = new FormData();
            uploadedFiles = uploadedFiles + count
            setUploaded(uploadedFiles)
            let percentage = (uploadedFiles / totalFiles) * 100
            setProgress(percentage)
            if (totalFiles == uploadedFiles) {
                setUploadStatus(2)
                new Audio(bell).play();
            }
        }

        for (let index = 0; index < images.length; index++) {
            let doc = images[index]
            if (/\.(jpe?g|png|gif|pdf)$/.test(doc.name) === true && doc.size < 2097152) {
                formSent.append('id', doc)
                uploadingFiles.push(doc.name)
                addedCount++

            }
            else {
                props.openSnackBar("Invalid files found.")
                setUploadStatus(0)

            }
            if (images.length == 1 || ((images.length % 2) == 1 && index + 1 == images.length)) {
                try {
                    if (formSent.has('id')) {
                        let resp = await axios({ method: 'post', url: `${config.url().baseURL}/employee-id-upload-new/${props.batchEmpId}`, data: formSent, maxContentLength: Infinity, maxBodyLength: Infinity })
                        if (!resp['data']['err']) {
                            setFailedDocs([])
                            await calculateProgress(1)
                        } else {
                            // props.openSnackBar(`Unable to upload  ${resp['data']['data']['failed_files']}`);
                            let tempFiles = failedDocs
                            // resp.data.data.failed_files.map((fuser, key) => {
                            //     tempFiles.push(fuser)
                            // })
                            // tempFiles.push(resp['data']['data']['failed_files'])

                            // setFailedDocs(tempFiles)
                            setFailedDocs(resp['data']['data']['failed_files'] ? resp['data']['data']['failed_files'] : "")
                            await calculateProgress(1)
                        }
                    }
                } catch (error) {
                    props.openSnackBar(`Unable to upload ${uploadingFiles.toString()}`);
                    await calculateProgress(1)
                }
            } else if (addedCount == 2) {
                try {
                    let resp = await axios({ method: 'post', url: `${config.url().baseURL}/employee-id-upload-new/${props.batchEmpId}`, data: formSent, maxContentLength: Infinity, maxBodyLength: Infinity })
                    if (!resp['data']['err']) {
                        setFailedDocs([])
                        await calculateProgress(2)
                    } else {
                        // props.openSnackBar(`Unable to upload ${resp['data']['data']['failed_files']}`);
                        let tempFiles = failedDocs
                        // resp.data.data.failed_files.map((fuser, key) => {
                        //     tempFiles.push(fuser)
                        // })
                        // tempFiles.push(uploadingFiles)

                        // tempFiles.push(resp['data']['data']['failed_files'])
                        // setFailedDocs(tempFiles)
                        setFailedDocs(resp['data']['data']['failed_files'] ? resp['data']['data']['failed_files'] : "")
                        await calculateProgress(2)

                    }
                } catch (error) {
                    props.openSnackBar(`Unable to upload ${uploadingFiles.toString()}`);
                    let tempFiles = []
                    // tempFiles.push(uploadingFiles)
                    setFailedDocs(tempFiles)
                    await calculateProgress(2)
                }
            }
        }
    }
    return (
        <>
            <Collapse
                in={uploadStatus == 0}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <h3>Upload IDs Of Employees</h3>
                <DropZone onDrop={employeeIdUpload} >
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button size="sm" color="primary">
                                <strong>Upload</strong>
                            </Button>
                        </div>
                    )}
                </DropZone>
            </Collapse>
            <Collapse
                in={uploadStatus == 1}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <h3>Uploading Documents</h3>
                <BorderLinearProgress variant="determinate" value={progress} />
                <h4>Uploaded {(uploaded / filesCount) * 100}</h4>
            </Collapse>

            <Collapse
                in={uploadStatus == 2}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                {
                    failedDocs.length != 0 ? (<> {props.openSnackBar(`Unable to upload ${failedDocs.length ? failedDocs.toString() : ""}`)}
                        <h3 style={{ color: "red", marginRight: "10px" }}> Failed to upload ID's of CPR's-{failedDocs.toString()} </h3>
                    </>)
                        : null
                    }
                    <h3> Documents are uploaded </h3>
                <Button size="md" onClick={() => { setUploadStatus(0); setProgress(0); setFailedDocs([]) }} color="success">
                    Ok
                </Button>
            </Collapse>




        </>
    );
}