import React from "react";
import config from "config.js"
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/core";
import Button from "components/CustomButtons/Button.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import axios from "axios";
import Dropzone from "react-dropzone";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Document, Page } from 'react-pdf';
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import GridContainer from "components/Grid/GridContainer";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import { Grid } from '@material-ui/core';

const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
  
`;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        textAlign: "center"
    },
    sideHead: {
        fontWeight: "500",
        fontSize: "14px",
        marginBottom: "0px !important",
        marginTop: "25px !important",
        color: "#474747",
    },

}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function Upgrade(props) {
    const classes = useStyles();
    const [respAlert, setRespAlert] = React.useState(null);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [agreement, setAgreement] = React.useState("");
    const [agreementName, setAgreementName] = React.useState("");
    const [agreementBtnClr, setAgreementBtnClr] = React.useState("secondary");

    const [documentViewerData, setDocumentViewerData] = React.useState("");
    const [openImageViewer, setOpenImageViewer] = React.useState(false);
    const [openPDFeViewer, setOpenPDFViewer] = React.useState(false);
    const [numPages, setNumPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [submitting, setSubmitting] = React.useState(false);
    const [remarks, setRemarks] = React.useState("")
    const [values, setValues] = React.useState({
        remarksErr: null,
        aggrementErr: null
    });


    const verifyLength = (value, length) => {
        // function that verifies if a string has a given length or not

        if (value.length >= length) {
            return true;
        }
        return false;
    };

    const validateForm = () => {
        //function to validate form
        var flag = true, remarksErr = false;
        let snackBarMsg = ""
        if (!verifyLength(remarks, 3) && remarks.trim() == "") {
            flag = false;
            remarksErr = true;
            snackBarMsg = (snackBarMsg == "" ? "Please enter the remarks" : "")
        }
        if (props.userType == "maker") {
            if (!verifyLength(agreementName, 2)) {
                setAgreementBtnClr("danger")
                flag = false
                snackBarMsg = (snackBarMsg == "" ? "Please Select Agreement" : snackBarMsg);

            }
        }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }
    const verify = (value, type, errVariable, length) => {
        switch (type) {
            case "length":
                if (verifyLength(value, length)) {
                    setValues({ ...values, [errVariable]: false });
                } else {
                    setValues({ ...values, [errVariable]: true });
                }
                break;
            default:
                break;
        }
    };
    const createUpgrade = () => {
        //function to save fee details

        if (validateForm()) {
            let newData = {
                cpr: props.custId,
                status: "Created",
                remarks: remarks,
                doc: agreement
            }
            if (!submitting) {
                axios.post(`${config.url().baseURL}/mc-upgrade-to-travel-buddy-plus`, newData)
                    .then((resp) => {
                        let respData = resp.data;
                        if (!respData.err) {
                            setRespAlert(
                                <SweetAlert
                                    success
                                    style={{ display: "block" }}
                                    title={respData.msg}
                                    onConfirm={() => {
                                        setSubmitting(false)
                                        deleteAgreement()
                                        setRespAlert(null)
                                        closeModal()
                                    }}
                                    confirmBtnCssClass={classes.button + " " + classes.success}
                                />
                            );
                        } else {
                            setSubmitting(false)
                            openSnackBar(respData.msg);
                        }
                    }).catch((err) => {
                        setSubmitting(false)
                        openSnackBar("Unable to process.");
                    });
            }
        } else {
            // openSnackBar("Please fill All details.")
            setSubmitting(false)
        }
    }
    const approveUpgrade = () => {
        if (validateForm()) {
            let newData = {
                cpr: props.custId,
                status: "Approved",
                remarks: remarks,

            }
            if (!submitting) {
                setSubmitting(true)
                axios.post(`${config.url().baseURL}/mc-upgrade-to-travel-buddy-plus`, newData)
                    .then((resp) => {
                        let respData = resp.data;
                        if (!respData.err) {
                            setRespAlert(
                                <SweetAlert
                                    success
                                    style={{ display: "block" }}
                                    title={respData.msg}
                                    onConfirm={() => {
                                        setSubmitting(false)
                                        deleteAgreement()
                                        setRespAlert(null)
                                        closeModal()
                                    }}
                                    confirmBtnCssClass={classes.button + " " + classes.success}
                                />
                            );
                        } else {
                            setSubmitting(false)
                            openSnackBar(respData.msg);
                        }
                    }).catch((err) => {
                        setSubmitting(false)
                        openSnackBar("Unable to process.");
                    });
            }
        } else {
            setSubmitting(false)
        }
    }

    const rejectUpgrade = () => {
        if (validateForm()) {
            let newData = {
                cpr: props.custId,
                status: "Rejected",
                remarks: remarks,

            }
            if (!submitting) {
                setSubmitting(true)
                axios.post(`${config.url().baseURL}/mc-upgrade-to-travel-buddy-plus`, newData)
                    .then((resp) => {
                        let respData = resp.data;
                        if (!respData.err) {
                            setRespAlert(
                                <SweetAlert
                                    success
                                    style={{ display: "block" }}
                                    title={respData.msg}
                                    onConfirm={() => {
                                        setSubmitting(false)
                                        deleteAgreement()
                                        setRespAlert(null)
                                        closeModal()
                                    }}
                                    confirmBtnCssClass={classes.button + " " + classes.success}
                                />
                            );
                        } else {
                            setSubmitting(false)
                            openSnackBar(respData.msg);
                        }
                    }).catch((err) => {
                        setSubmitting(false)
                        openSnackBar("Unable to process.");
                    });
            }
        } else {
            setSubmitting(false)
        }
    }

    function openSnackBar(msg) {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const closeModal = () => {
        //function to close modal
        setRemarks("")
        setAgreementBtnClr("secondary");
        setAgreementName("")
        props.close()
    }

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    const agreementDrop = async (images) => {
        if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
            if (images[0].size < 2000000) {
                getBase64(images[0], (result) => {
                    setAgreement(result);
                });
                setAgreementBtnClr("secondary");
                setAgreementName(images[0].name);
            } else {
                openSnackBar("File size is too large.");
            }
        } else {
            openSnackBar("Please upload jpg,jpeg and png files");
        }
    };

    const CheckerFileView = async(id)=>{
        try {
            let data = await axios.get(`${config.url().baseURL}/mc-upgrade-documents/Upgrade/${props.custId}`)
            if(data.data.data.doc){
                fileViewer(data.data.data.doc)
            }
        } catch (error) {
            console.log(error)
        }
    }

    function fileViewer(file) {
        let extension = file.substring(file.indexOf("/") + 1, file.indexOf(";base64"));
        if (extension == "pdf") {
                    setDocumentViewerData(file)
            setOpenPDFViewer(true)
        } else {
            setDocumentViewerData(file)
            setOpenImageViewer(true)
        }
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const deleteAgreement = () => {
        setAgreement("")
        setAgreementName("")
    }

    const customStyles = {
        content: {
            zIndex: "2147483647"
        },
        overlay: {
            zIndex: "2147483647"
        }
    };
    return (
        <div className={classes.root}>
            {respAlert}
            <Collapse
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                {...(true ? { timeout: 500 } : {})}>
                {props.userType == "maker" ?
                    <>
                        <GridItem xs={12} sm={12}>
                            <p style={{ textAlign: "left", fontWeight: "bold" }}>
                                Upgrade
                                <small className={classes.required}></small>
                            </p>
                        </GridItem>

                        <GridItem xs={12} sm={12}>
                            <CustomInput
                                error={values.remarksErr}
                                
                                labelText={
                                    <span>
                                        Remarks<small className={classes.required}>*</small>
                                    </span>
                                }
                                id="fee_reversal"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    autoComplete: "off",
                                    value: remarks,

                                    onChange: (e) => {
                                        setRemarks(e.target.value);
                                        verify(e.target.value, "length", "remarksErr", 3);
                                    },
                                    onBlur: (e) => {
                                        setRemarks(e.target.value.trim());
                                    }
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    {agreementName !== "" ? (
                                        <CustomInput
                                            labelText="Proof"
                                            id="dir-id-proof"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                autoComplete: "off",
                                                readOnly: true,
                                                value: agreementName,
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <br />
                                            <Dropzone onDrop={agreementDrop}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <Button size="sm" fullWidth color={agreementBtnClr}>
                                                            Proof
                                                        </Button>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </>
                                    )}
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} style={{ textAlign: "left" }}>
                                    {agreementName !== "" ? (
                                        <>
                                            <br /><br />
                                            <Tooltip placement="right" title="Delete Document">
                                                <IconButton
                                                    onClick={() => deleteAgreement()}
                                                    style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                                                    aria-label="delete">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip placement="bottom" title="View Document">
                                                <IconButton
                                                    onClick={() => fileViewer(agreement)}
                                                    style={{ marginTop: "-10px", marginBottom: "-10px", color: "#014FA4" }}
                                                    aria-label="delete">
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    ) : null}
                                </GridItem>
                            </GridContainer>
                        </GridItem>

                        <br />
                        <Button
                            onClick={() => {
                                closeModal();
                                deleteAgreement()
                            }}
                            color="danger"
                            size="sm">
                            Close
                        </Button>
                        <Button
                            onClick={() => {
                                createUpgrade()
                            }}
                            color="primary"
                            size="sm" >
                            Create
                        </Button>
                        
                    </> : <>
                        <GridItem xs={12} sm={12}>
                            <p style={{ textAlign: "left", fontWeight: "bold" }}>
                                Upgrade
                                <small className={classes.required}></small>
                            </p>
                        </GridItem>
                        <GridItem xs={12} sm={12}>
                            <CustomInput
                                error={values.remarksErr}
                                labelText={
                                    <div>
                                        Remarks<small className={classes.required}>*</small>
                                    </div>
                                }
                                id="fee-reversal"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    autoComplete: "off",
                                    value: remarks,

                                    onChange: (e) => {
                                        setRemarks(e.target.value);
                                        verify(e.target.value, "length", "remarksErr", 3);
                                    },
                                    onBlur: (e) => {
                                        setRemarks(e.target.value.trim());
                                    }
                                }}
                            />
                        </GridItem>
                        <Grid container alignItems="flex-start">
                            <GridItem >
                                view
                                <Tooltip  title="View Document">
                                    <IconButton
                                        onClick={() => CheckerFileView()}
                                        style={{ marginTop: "5px", marginBottom: "5px", color: "#014FA4" }}
                                        aria-label="View">
                                        <VisibilityIcon />
                                    </IconButton>
                                </Tooltip>
                            </GridItem>
                        </Grid>
                        <br/>
                        {/* <br/> */}
                        <GridItem>
                        <Button
                            onClick={() => {
                                closeModal();

                            }}
                            color="secondary"
                            size="sm">
                            Close
                        </Button>
                        <Button
                            color="danger"
                            size="sm"
                            onClick={()=>{
                                rejectUpgrade()
                            }}
                        >
                            Reject
                        </Button>
                        <Button
                            onClick={() => {
                                approveUpgrade()
                            }}
                            color="primary"
                            size="sm" >
                            Approve
                        </Button>
                        </GridItem>
                    </>}
            </Collapse>
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    // paper: classes.processModal,
                }}
                open={openPDFeViewer}
                TransitionComponent={Transition}
                onClose={() => {
                    setOpenPDFViewer(false);
                    setDocumentViewerData("");
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <Document
                        file={documentViewerData}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <p>Page {pageNumber} of {numPages}</p>
                    <Button size="sm" color="primary" onClick={() => {
                        if (pageNumber < 2) {
                            setPageNumber(1)
                        } else {
                            let newPageNumber = pageNumber - 1
                            setPageNumber(newPageNumber)
                        }
                    }} >Pervious</Button>
                    <Button size="sm" color="primary"
                        onClick={() => {
                            if (numPages > pageNumber) {
                                let newPageNumber = pageNumber + 1
                                setPageNumber(newPageNumber)
                            } else {
                                setPageNumber(numPages)
                            }
                        }}
                    >Next</Button>
                </DialogContent>
            </Dialog>
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
            {
                openImageViewer && (
                    <Lightbox
                        reactModalStyle={customStyles}
                        mainSrc={documentViewerData}
                        onCloseRequest={() => setOpenImageViewer(false)}
                    />
                )
            }
        </div >
    );
}
