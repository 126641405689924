import React from "react";
import config from "config.js"
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import Button from "components/CustomButtons/Button.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Table from "components/Table/Table.js";
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import { useRef } from "react";

const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
  
`;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        textAlign: "center"
    },
    sideHead: {
        fontWeight: "500",
        fontSize: "14px",
        marginBottom: "0px !important",
        marginTop: "25px !important",
        color: "#474747",
    },

}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function FeeEditForm(props) {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [tableData, setTableData] = React.useState([]);
    const [dialogueButtons, setDialogueButtons] = React.useState("");
    const [respAlert, setRespAlert] = React.useState(null);
    const [submitting, setSubmitting] = React.useState(false);
    let service = user.services.find(service => service.name == "Corporate Fee Edit")
    const [cardData, setCardData] = React.useState()
    const [remarks, setRemarksValue] = React.useState("")
    const reference = useRef("")
    // var remarks = ""
    React.useEffect(() => {
        if (props.corpID !== null) {
            fetchCardFeeDetails(props.corpID)
            // remarks = ""
            setRemarksValue("")
        }
    }, [props.corpID]);
    // const setRemarksValue = (value) => {
    //     remarks = value
    // }
    // console.log(props);
    const fetchCardFeeDetails = (id) => {
        // function to fetch company details      
        axios.post(`${config.url().baseURL}/get-card-fee-details/${id}`, { "type": "cardFee" }).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                setCardData(respData && respData.data ? respData.data : "")
                // console.log("Second", respData.data)
                // let service = user.services.find(service => service.name == "Corporate Fee Edit")
                if (service.type == "maker") {
                    setDialogueButtons(<Button onClick={() => { closeModal() }} color="danger" size="sm"> Close </Button>)
                }
                else if (service.type == "checker" && props.cardStatus == "Updated" || props.cardStatus == "Created") {
                    reference.current = (respData && respData.data && respData.data[0]) ? respData.data[0].feeReference : ""
                    setTableData([
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Card Fee</b>, ":", (respData && respData.data && respData.data[0]) ? respData.data[0].feeAmount : ""],
                    ])
                }
                else if (service.type == "approver" && props.cardStatus == "Checked") {
                    reference.current = (respData && respData.data && respData.data[0]) ? respData.data[0].feeReference : ""
                    setTableData([
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Card Fee</b>, ":", (respData && respData.data && respData.data[0]) ? respData.data[0].feeAmount : ""],
                    ])
                }

                else {
                    if (props.cardStatus != "notcreated") {
                        setDialogueButtons(<Button onClick={() => { closeModal() }} color="danger" size="sm"> Close </Button>)
                        setTableData([
                            [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Card Fee</b>, ":", (respData && respData.data && respData.data[0]) ? respData.data[0].feeAmount : ""],
                        ])
                    } else {
                        setDialogueButtons(<Button onClick={() => { closeModal() }} color="danger" size="sm"> Close </Button>)
                        setTableData([
                            [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Card Fee</b>, ":", ""],
                        ])

                    }
                }

            } else {
                openSnackBar(respData.msg);

                closeModal()
                setRemarksValue("")

            }
        }).catch((err) => {
            closeModal()
            setRemarksValue("")
            openSnackBar("Unable to process.");
        });

    };

    function openSnackBar(msg) {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const closeModal = () => {
        //function to close modal
        setRemarksValue("")
        setSubmitting(false);
        props.close()
    }

    const changeStatus = (status) => {
        //function to change status of fee
        if (remarks !== undefined && remarks.trim().length !== 0 && remarks != "") {
            let data1 = {
                "status": status,
                "remarks": remarks,
                "type": "cardFee",
                "feeReference": reference.current
            }
            if (!submitting) {
                setSubmitting(true)

                axios.post(`${config.url().baseURL}/approve-card-fees/${props.corpID}`, data1).then((resp) => {
                    let respData = resp.data;
                    if (!respData.err) {
                        if (status == "Recheck") {
                            setRespAlert(
                                <SweetAlert
                                    success
                                    style={{ display: "block" }}
                                    title="Rechecked"
                                    onConfirm={() => {
                                        setSubmitting(false)
                                        setRespAlert(null)
                                        setRemarksValue("")
                                        closeModal()
                                    }}
                                    confirmBtnCssClass={classes.button + " " + classes.success}
                                />
                            );
                        } else if(status=="Approved"){
                            setRespAlert(
                                <SweetAlert
                                    success
                                    style={{ display: "block" }}
                                    title="Card fee approved by Approver"
                                    onConfirm={() => {
                                        setSubmitting(false)
                                        setRespAlert(null)
                                        setRemarksValue("")
                                        closeModal()
                                    }}
                                    confirmBtnCssClass={classes.button + " " + classes.success}
                                />
                            );
                        }
                        else{
                            setRespAlert(
                                <SweetAlert
                                    success
                                    style={{ display: "block" }}
                                    title="Card fee Approved by Checker"
                                    onConfirm={() => {
                                        setSubmitting(false)
                                        setRespAlert(null)
                                        setRemarksValue("")
                                        closeModal()
                                    }}
                                    confirmBtnCssClass={classes.button + " " + classes.success}
                                />
                            );
                        }
                        setRemarksValue("")
                        // closeModal()
                    } else {
                        setRemarksValue("")
                        setSubmitting(false)
                        openSnackBar(respData.msg);
                    }
                }).catch((err) => {
                    setSubmitting(false)
                    setRemarksValue("")
                    openSnackBar("Fee profile not created, create fee profile");
                });
            }
        } else {
            openSnackBar("Please enter remarks.")
        }
    }

    return (
        <div className={classes.root}>
            {respAlert}
            <Collapse
                in={tableData.length == 0}
                style={{ transformOrigin: "0 0 0", textAlign: "center", height: "100px" }}
                {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
            </Collapse>
            <Collapse
                in={tableData.length !== 0}
                style={{ transformOrigin: "0 0 0", textAlign: "center", paddingLeft: "15px", paddingRight: "15px" }}
                {...(true ? { timeout: 500 } : {})}>


                <br />{service.type == "checker" && props.cardStatus == "Updated" || props.cardStatus == "Created" ?
                    <><Table tableData={tableData} />
                        <TextField label="Remarks" fullWidth value={remarks} onChange={(e) => setRemarksValue(e.target.value)} />
                        <Button onClick={() => { changeStatus("Recheck") }} color="danger" size="sm">Recheck</Button>
                        <Button color="primary" onClick={() => { changeStatus("Checked") }} size="sm">Approve</Button></> :
                    // <> <Table tableData={tableData} />
                    //     {dialogueButtons}</>

                    (service.type == "approver" && props.cardStatus == "Checked"?
                        <> <Table tableData={tableData} />
                            <TextField label="Remarks" fullWidth value ={remarks} onChange={(e) => setRemarksValue(e.target.value)} />
                            <Button onClick={() => { changeStatus("Recheck") }} color="danger" size="sm">Recheck</Button>
                            <Button color="primary" onClick={() => { changeStatus("Approved") }} size="sm">Approve</Button>
                            <Button onClick={() => { closeModal() }} color="danger" size="sm"> Close </Button>
                            </> :
                        <> <Table tableData={tableData} />
                            {dialogueButtons}</>)}
            </Collapse>
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div >
    );
}
