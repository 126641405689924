import React, { useEffect, useState } from 'react';
import { TextField, Checkbox, FormControlLabel, FormGroup, Select, MenuItem, InputLabel } from '@material-ui/core';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { css } from "@emotion/core";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Divider } from '@mui/material';
import config from 'config';
import axios from 'axios';
import Dropzone from "react-dropzone";
import DocumentListComponent from './ViewDoc';
import FileUploadBfc from "./FileUploadBfc";
import SweetAlert from "react-bootstrap-sweetalert";



import Button from "components/CustomButtons/Button.js";
import { makeStyles } from '@material-ui/core/styles';
import Grid from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem';
import { PropagateLoader } from 'react-spinners';

const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    formContainer: {

        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        padding: theme.spacing(2),

    },
    sectionContainer: {
        marginBottom: theme.spacing(2),
    },
    fileInput: {
        display: 'none',
    },
    uploadButton: {
        marginRight: theme.spacing(2),
    },
    submitButton: {
        marginTop: theme.spacing(2),
    },

}));
let categoryList = ['Telecom', 'Schools', 'Credit Card', 'Government Services', 'Gift Cards', 'Broadband', 'Investment', 'Calling Card', 'Pay Rent']
const addressField = {
    "Block": "",
    "Country": "",
    "CR Number": "",
    "Building no": "",
    "Road": ""


}

const EditBillerForm = ({ back, snack, alertPop, type, billRef }) => {
    const classes = useStyles();

    const [billerName, setBillerName] = useState('');
    const [loder, setLoder] = useState(true)
    const [category, setCategory] = useState('')
    const [tranCode, settranCode] = useState(type);
    const [isProducts, setisProducts] = useState(false);
    const [productCode, setProductCode] = useState('');
    const [producttranCode, setProducttranCode] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [address, setAddress] = useState(null)
    const [inHome, setInHome] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [issuerId, setissuerId] = useState('')
    const [group, setGroup] = useState('')
    const [docs, setDocs] = useState(null)
    const documentsName = ["CR", "VAT", "AGGREMENT", "ADDITIONAL"];

    const [respAlert, setRespAlert] = React.useState(null);

    const [issuerName, setissuerName] = useState('')
    const [open, setOpen] = useState("");

    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [uploadedFile, setUploadedFile] = useState()

    useEffect(() => {
        handleView()
    }, [])

    const submitDoc = async (doc, name) => {
      
        //function to upload doc to the server
        setRespAlert(null);
        let requestBody = new FormData();
        requestBody.set("file", doc);

        try {
            // setListView(0)
            let resp = await axios.post(
                `${config.url().wallet_URL}/upload-file`,
                requestBody
            );
            if (resp.data.err) {
                // setListView(1)
                snack(resp.data.msg);
                // setListView(1)
                setOpen(false);

            } else {
                console.log(name);
                let key = ["CR", "VAT", "AGGREMENT"].includes(name) ? name : "ADDITIONAL"
                if (docs) {
                    let newDocs = { ...docs, [key]: resp.data.data.filePath.Location }
                    setDocs(newDocs)
                } else {
                    let newDoc = { [key]: resp.data.data.filePath.Location }
                    setDocs(newDoc)
                    snack(resp.data.msg, true);
                    setOpen(false);
                }
                // "CR", "VAT", "AGGREMENT", "ADDITIONAL"

            }
        } catch (error) {
            console.log(error);
            snack("Unable to upload.");
            setOpen(false);

            //    fetchStudents(props.id)
        }
    };

    const handleView = async () => {
        try {

            const resp = await axios.post(`${config.url().app_URL}/bill/get-category`, { billRef })
            if (resp?.data?.status.err) {
                snack(resp?.data?.status.message)
            }
            console.log(resp.data.data);
            setBillerName(resp.data?.data?.billerName)
            setCategory(resp.data?.data?.category)
            setissuerName(resp.data?.data?.issuerName)
            setissuerId(resp.data?.data?.issuerId)
            setDocs(resp.data?.data?.docs)
            settranCode(resp.data?.data?.tranCode)
            setAddress(resp.data?.data?.address || addressField)
            // setGroup(resp.data?.data?.group)
            // setProductName(resp.data?.data?.productName)
            setProductCode(resp.data?.data?.productCode)
            setInHome(resp.data?.data?.inHome)
            setIsActive(resp.data?.data?.isActive)
            setGroup(resp.data?.data?.group)
            setLoder(false)


        } catch (err) {
            console.log(err);
            snack("Something Went Wrong");

        }
    }
    const uploadNewStudentList = async (doc, name) => {
        console.log(doc)
        if (/\.(pdf)$/i.test(doc.name) === true) {
            if (doc.size < 2097152) {
                setRespAlert(
                    <SweetAlert
                        warning
                        input
                        style={{ display: "block" }}
                        title="Are you sure?"
                        onConfirm={() => {
                            setUploadedFile(doc.name)
                            submitDoc(doc, name);
                        }}
                        onCancel={() => {

                            setRespAlert(null);
                            setUploadedFile("")

                        }}
                        Data
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnText="No"
                        cancelBtnCssClass={classes.button}
                        confirmBtnCssClass={classes.button + " " + classes.danger}
                    >
                        Are You Sure Want To Upload
                    </SweetAlert>
                );
            } else {
                snack("File size is too large, Maximum file size is 2 Mb.");
            }
        } else {
            snack("Please upload pdf files.");
        }

        //function to upload excel file
    };

    let renderedFileupload = documentsName.map((item, index) => {

        if (docs && docs[item]) {
            return (
                <div>
                    <DocumentListComponent docs={(docs) ? { [item]: docs[item] } : []} />
                </div>
            )
        } else if (type === "Edit") {
            return (
                <div className='shake'>
                    <FileUploadBfc name={item} uploadNewStudentList={uploadNewStudentList} key={index} />
                </div>

            )
        }

    }

    );

    //function handle the change address 
    const handleAddressChange = (key, value) => {
        try {

            let newAddress = { ...address, [key]: value }
            setAddress(newAddress)

        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const data = {
                billRef,
                value: {
                    name: billerName,
                    category,
                    group,
                    issuerName,
                    isActive,
                    issuerId,
                    inHome,
                    status: "Pending for Approval"
                }
            }
            if (docs) data.value.docs = docs
            if (address) data.value.address = address
            const resp = await axios.post(`${config.url().app_URL}/bill/update-category`, data)
            if (resp.data.status.err) {
                snack(resp.data.message)
                // setBillerName('');
                // settranCode('');
                // setisProducts(false);
                // setProductCode('');
                // setProducttranCode('');
                // setAddressLine1('');
                // setAddressLine2('');
                // setCity('');
                // setState('');
                // setZipCode('');
            }
            else {
                alertPop("success", "Biller data saved", resp.data.msg)
            }
            // }
            // Reset the form

        } catch (error) {
            console.log(error)
            snack(error.message)

        }

    };

    return (
        <>
            {respAlert}
            <Card>

                <CardHeader color="primary" >
                    <h4 className={classes.cardTitleWhite}>Edit Biller</h4>

                </CardHeader>
                <CardBody style={{ padding: 50 }}>
                    <Grid xs={12} >
                        <GridItem xs={4}>
                            <TextField
                                label="Biller Name"

                                value={billerName}
                                onChange={(e) => setBillerName(e.target.value)}
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    readOnly: (type === 'Edit') ? false : true
                                }}
                            />

                        </GridItem>

                        <GridItem xs={4}>
                            <TextField
                                label="Group"
                                value={group}
                                onChange={(e) => setGroup(e.target.value)}
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    readOnly: (type === "Edit") ? false : true
                                }}
                            />

                        </GridItem>

                        {!isProducts && (<><GridItem xs={4}>
                            <TextField
                                label="issuerId"
                                value={issuerId}
                                onChange={(e) => setissuerId(e.target.value)}
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    readOnly: (type === "Eidt") ? false : true
                                }}
                            />
                        </GridItem>
                            <GridItem xs={4}>
                                <TextField
                                    label="issuerName"
                                    value={issuerName}
                                    onChange={(e) => setissuerName(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{
                                        readOnly: (type === "Edit") ? false : true
                                    }}
                                />
                            </GridItem></>)}
                        <GridItem xs={4}>

                            <InputLabel id="productType" style={{ paddingTop: "10px" }}>Category *</InputLabel>
                            <Select
                                style={{ marginTop: 10 }}
                                fullWidth
                                labelId="category"
                                value={category}
                                label="Product category"
                                onChange={(e) => {
                                    setCategory(e.target.value);
                                }}
                                inputProps={{
                                    readOnly: type === "Edit" ? false : true
                                }}
                            >
                                {categoryList.map((item) => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </GridItem>
                        <GridItem item xs={4}>
                        </GridItem>
                    </Grid>
                    {/* document and adress only need to show or edit if it is an BFC_PAY biller */}
                    {
                        (address && tranCode === "BFC_Pay") ?
                            <div className={classes.sectionContainer}>
                                <h4 style={{ fontWeight: "bold" }}>Basic Address Details</h4>
                                <Divider
                                    sx={{ margin: "10px 0", backgroundColor: "black", height: 1 }}
                                />
                                <Grid spacing={2} xs={12}>


                                    {
                                        Object.entries(address).map(([key, value]) => (
                                            <GridItem xs={4}>
                                                <TextField
                                                    key={key}
                                                    label={key}
                                                    value={value}
                                                    onChange={(e) => handleAddressChange(key, e.target.value)}
                                                    fullWidth
                                                    margin="normal"
                                                    inputProps={{
                                                        readOnly: type === "Edit" ? false : true
                                                    }}
                                                />
                                            </GridItem>
                                        ))
                                    }
                                </Grid>
                            </div>
                            :
                            <div style={{ padding: 50, marginLeft: 550 }}>

                                <PropagateLoader color="#004fa4" loading={loder} css={override} size={15} />
                            </div>

                    }
                    {
                        (tranCode === "BFC_Pay") ?
                            <div>

                                <div className={classes.sectionContainer}>
                                    <h4 style={{ fontWeight: "bold" }}>  Documents</h4>
                                    <Divider />
                                    <p>Document Size Is Max of 2MB</p>
                                    <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                                        {renderedFileupload}
                                    </div>
                                </div>

                            </div> : ""

                    }


                    <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
                        {type === "Edit" && (
                            <>
                                <Button
                                    tranCode="Approve"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submitButton}
                                    style={{ width: 100, marginRight: 10, padding: "8px", fontSize: "12px" }}
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>

                            </>
                        )}

                        <Button
                            tranCode="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submitButton}
                            style={{ width: 100, marginRight: 10, padding: "8px", fontSize: "12px" }}
                            onClick={back}
                        >
                            Back
                        </Button>
                    </div>
                </CardBody>
            </Card >
        </>
    );
};

export default EditBillerForm;
