import axios from "axios";
import config from "config.js"
import moment from "moment";
import countries from "../variables/countriesWithIso3.json";
import jwt from 'jsonwebtoken';

const regex = {
    alphanumeric: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
    float: /^\d*\.?\d*$/,
    alphabet: /^[a-zA-Z ]*$/,
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    number: /^[0-9\b]+$/,
    all: /.*/,
    cpr: new RegExp("[0-9]{​​​​​​​​9}​​​​​​​​"),
    passport: new RegExp("[a-zA-Z0-9-<]+"),
    unitCode: new RegExp("[0-9]{​​​​​​​​10}​​​​​​​​"),
    cr: new RegExp("[0-9]{​​​​​​​​9}​​​​​​​​"),
    base64: /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
}

const libs = {
    getCorporate: async (id, resp) => {
        try {
            await axios.get(`${config.url().baseURL}/get-ledger-details/${id}`).then((respData) => {
                resp(respData.data)
            });
        } catch (error) {
            resp({ err: true, msg: "Unable to process." })
        }
    },
    validate: (data, type, length) => {
        //function to validate given data 
        if (data.length <= length && (data === "" || regex[type].test(data.trim()))) {
            return true
        } else return false
    },
    getBase64: (file, cb) => {
        //function to convert file into base64
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    },
    speak: (msg) => {
        //function to speak texts
        const sp = new SpeechSynthesisUtterance(msg);
        [sp.voice] = speechSynthesis.getVoices();
        speechSynthesis.speak(sp);
    },
    convertDate: (date) => {
        try {
            let newDate
            if (moment(date, 'DD/MM/YYYY', true).isValid()) {
                let convertedDate = date.split('/')
                newDate = new Date(`${convertedDate[1]}/${convertedDate[0]}/${convertedDate[2]}`)
            } else if (moment(date, 'DD-MM-YYYY', true).isValid()) {
                let convertedDate = date.split('-')
                newDate = new Date(`${convertedDate[1]}/${convertedDate[0]}/${convertedDate[2]}`)
            }
            else {
                newDate = new Date(date)
            }
            return newDate
        } catch (error) {
            console.log(error)
            let defaultDate = new Date()
            return defaultDate
        }

    },
    convertCountry: (iso3, type) => {
        //function to convert country code to desired type
        let data = countries
        let country = null
        if (!iso3 || iso3 == "NULL") return ("");
        for (let i = 0; i < data.length; i++) {
            if (data[i].isoAlpha3.toUpperCase() == iso3.toUpperCase() || data[i].isoAlpha2.toUpperCase() == iso3.toUpperCase() || (data[i].name).toUpperCase() == iso3.toUpperCase()) {
                country = data[i]
            }
        }
        let response = ""
        if (country) {
            switch (type) {
                case "name":
                    response = country.name
                    break;
                case "iso2":
                    response = country.isoAlpha2
                    break;
                case "iso3":
                    response = country.isoAlpha3
                    break;
                default:
                    break;
            }
        } else {
            response = iso3
        }
        return response
    },
    base64ToFile: (dataurl, filename) => {
        //function to convert base64 to file
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    },
    checkAccessRight: async (service) => {
        //function to check user access right
        try {
            // let user = await jwt.verify(sessionStorage.getItem("cx_id"), "HeLlO-BaHrAiN")
            // user = user.userData
            let user =JSON.parse(sessionStorage.getItem("cx_data"))
            let userType = await user.services.find(userService => userService.name == service)
            if (userType == null || userType == undefined) return ({ status: false })
            else return ({ status: true, type: userType.type })
        } catch (error) {
            return ({ status: false })
        }

    }



}

export default libs


