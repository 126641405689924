
import * as React from 'react';
import { useSelector } from 'react-redux';
import config from "config.js"
import Table from "components/Table/Table.js";
import avatar from "assets/img/no_dp.png";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputBase from "@material-ui/core/InputBase";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from "components/CustomButtons/Button.js";
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import Danger from 'components/Typography/Danger';
import libs from "controllers/libs";
import Slide from "@material-ui/core/Slide";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from '@material-ui/icons/Visibility';
import ImagesStyles from "assets/jss/material-dashboard-react/imagesStyles";
import Dropzone from "react-dropzone";
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  tabContent: {
    maxHeight: "60vh"
  },
  dialogContainer: {
    display: "flex",
  },
  input: {
    flex: 1,
    marginTop: "10px",
    height: "45px",
    paddingLeft: "10px",
  },
  nextButton: {
    marginLeft: "150px"
  },
  remarksHead: {
    fontWeightL: "500",
    marginBottom: "0px",
    color: "#ffffff"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    float: "left",
    display: "inlineBlock",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },

  },
  helperText: {
    color: "#004fa4"
  },
  ...SweetAlertStyle,
  ...ImagesStyles
}));



export default function EditForm(props) {
  const user = useSelector(state => state.user);
  const classes = useStyles();
  var tempUpdated = null
  const [mobile, setMobile] = React.useState("");
  const [confirmMobile, setConfirmMobile] = React.useState("");
  const [DOB, setDOB] = React.useState("");
  const [CPRExpiry, setCPRExpiry] = React.useState("");
  const [addressType, setAddressType] = React.useState("");
  const [flatNumber, setFlatNumber] = React.useState("");
  const [buildingNumber, setBuildingNumber] = React.useState("");
  const [roadNumber, setRoadNumber] = React.useState("");
  const [blockNumber, setBlockNumber] = React.useState("");
  const [nationality, setNationality] = React.useState("");
  const [placeOfBirth, setPlaceOfBirth] = React.useState("");
  const [occupation, setOccupation] = React.useState("");
  const [singleAddress, setSingleAddress] = React.useState("");
  const [passportNo, setPassportNo] = React.useState("");
  const [passportExpiry, setPassportExpiry] = React.useState("");

  const [oldDOB, setOldDOB] = React.useState("");
  const [oldAddressType, setOldAddressType] = React.useState("");
  const [oldCPRExpiry, setOldCPRExpiry] = React.useState("");
  const [oldPassportNo, setOldPassportNo] = React.useState("");
  const [oldPassportExpiry, setOldPassportExpiry] = React.useState("");
  const [oldFlatNumber, setOldFlatNumber] = React.useState("");
  const [oldBuildingNumber, setOldBuildingNumber] = React.useState("");
  const [oldRoadNumber, setOldRoadNumber] = React.useState("");
  const [oldBlockNumber, setOldBlockNumber] = React.useState("");
  const [oldPlaceOfBirth, setOldPlaceOfBirth] = React.useState("");

  const [docs, setDocs] = React.useState([]);

  const [employer, setEmployer] = React.useState("");
  const [empAddressType, setEmpAddressType] = React.useState("");
  const [empFlatNumber, setEmpFlatNumber] = React.useState("");
  const [empBuildingNumber, setEmpBuildingNumber] = React.useState("");
  const [empRoadNumber, setEmpRoadNumber] = React.useState("");
  const [empBlockNumber, setEmpBlockNumber] = React.useState("");

  const [accountStatus, setAccountStatus] = React.useState("");
  const [oldAccountStatus, setOldAccountStatus] = React.useState("");

  const [userData, setUserData] = React.useState(null);



  const [respAlert, setRespAlert] = React.useState(null);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [snackBarType, setSnackBarType] = React.useState("error");
  const [openSnack, setOpenSnack] = React.useState(false);

  const [status, setStatus] = React.useState("");
  const [openRemarks, setOpenRemarks] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [remarks, setRemarks] = React.useState("");

  const [disableAddressType, setDisableAddressType] = React.useState(false);
  const [disableFlatNo, setDisableFlatNo] = React.useState(false);
  const [disableBuildingNo, setDisableBuildingNo] = React.useState(false);
  const [disableRoad, setDisableRoad] = React.useState(false);
  const [disableBlock, setDisableBlock] = React.useState(false);
  const [disableOccupation, setDisableOccupation] = React.useState(false);
  const [disableNationality, setDisableNationality] = React.useState(false);
  const [disablePlaceOfBirth, setDisablePlaceOfBirth] = React.useState(false);

  const [disableEmpAddressType, setDisableEmpAddressType] = React.useState(false);
  const [disableEmpFlatNo, setDisableEmpFlatNo] = React.useState(false);
  const [disableEmpBuildingNo, setDisableEmpBuildingNo] = React.useState(false);
  const [disableEmpRoad, setDisableEmpRoad] = React.useState(false);
  const [disableEmpBlock, setDisableEmpBlock] = React.useState(false);

  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [passportReq, setPassportReq] = React.useState(false);
  const [addressProofReq, setAddressProofReq] = React.useState(false);
  const [cprCopyReq, setCprCopyReq] = React.useState(false);

  const [passportDoc, setPassportDoc] = React.useState({ uploaded: false, index: null });
  const [addressProofDoc, setAddressProofDoc] = React.useState({ uploaded: false, index: null });
  const [cprCopyDoc, setCprCopyDoc] = React.useState({ uploaded: false, index: null });
  const [newDocs, setNewDocs] = React.useState([]);

  const [passportBtn, setPassportBtn] = React.useState("primary");
  const [addressProofBtn, setAddressProofBtn] = React.useState("primary");
  const [cprCopyBtn, setCprCopyBtn] = React.useState("primary");


  const [documentViewerData, setDocumentViewerData] = React.useState(avatar);
  const [openImageViewer, setOpenImageViewer] = React.useState(false);
  const [openPDFeViewer, setOpenPDFViewer] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageNumberTwo, setPageNumberTwo] = React.useState(1);


  const [approvalBtn, setApprovalBtn] = React.useState(false);
  const [saveBtn, setSaveBtn] = React.useState(false);
  const [updated, setUpdated] = React.useState(null);




  // const [addressProofReq, setPageNumberTwo] = React.useState(1);

  const [values, setValues] = React.useState({
    addressTypeErr: null,
    flatNoErr: null,
    buildingNoErr: null,
    roadErr: null,
    blockErr: null,
    areaErr: null,
    nationalityErr: null,
    placeOfBirthErr: null,
    occupationErr: null,
    cprExpiryErr: null,
    passportNoErr: null,
    passportExpiryErr: null,


    empAddressTypeErr: null,
    empFlatNoErr: null,
    empBuildingNoErr: null,
    empRoadErr: null,
    empBlockErr: null,
    dobErr: null
  });

  function openSnackBar(msg, success) {
    //function to tost snackbar message
    setOpenSnack(true);
    if (success) {
      setSnackBarType("success")
    } else {
      setSnackBarType("error")
    }
    setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  }

  React.useEffect(() => {
    if (props.emp.cpr !== null) {
      getCustomerDetails(props.emp.cpr)
    }
  }, [props.emp.cpr]);


  const checkUserType = (status) => {
    //function to check user  type
    let service = user.services.find(service => service.name == "Customer Edit")
    let riskService = user.riskServices.find(riskService => riskService.name == "Customer Edit")
    console.log(JSON.stringify(service))
    console.log(JSON.stringify(riskService))
    if (status == "UPDATED") {
      if ((service && service.type == "checker") || (riskService && riskService.type == "checker")) {
        setApprovalBtn(true)
      } else {
        setApprovalBtn(false)
      }
    } else if (status == "APPROVED" || status == "ADDED" || status == "RECHECK") {
      if ((service && service.type == "maker") || (riskService && riskService.type == "maker")) {
        setSaveBtn(true)
      } else {
        setSaveBtn(false)
      }
    } else {
      setApprovalBtn(false)
      setSaveBtn(false)
    }
  }

  const validateFrom = () => {
    //function to validate form
    var flag = true
    var dobErr = false, addressTypeErr = false, flatNoErr = false, buildingNoErr = false, roadErr = false, blockErr = false, nationalityErr = false, placeOfBirthErr = false, occupationErr = false, empAddressTypeErr = false, empFlatNoErr = false, empBuildingNoErr = false, empRoadErr = false, empBlockErr = false, cprExpiryErr = false, passportNoErr = false, passportExpiryErr = false;
    var msg = ""
    if (mobile.length !== 8) {
      msg = "Please enter valid mobile number"
      flag = false
    }
    if (mobile !== confirmMobile) {
      msg = (msg == "" ? "Mobile numbers doesn't match." : msg);
      flag = false
    }
    if (DOB.length == 0 || moment(DOB).isAfter(moment(new Date()))) {
      msg = (msg == "" ? "Please enter valid date of birth." : msg);
      dobErr = true
      flag = false
    }
    if (passportNo.length !== 0) {
      if (passportExpiry.length == 0) {
        msg = (msg == "" ? "Please enter Passport expiry." : msg);
        passportExpiryErr = true
        flag = false
      }
    }
    if (nationality.length == 0) {
      msg = (msg == "" ? "Please enter nationality." : msg);
      nationalityErr = true
      flag = false
    }
    if (addressType.length == 0) {
      msg = (msg == "" ? "Please enter address type." : msg);
      addressTypeErr = true
      flag = false
    }
    if (flatNumber.length == 0) {
      msg = (msg == "" ? "Please enter flat no." : msg);
      flatNoErr = true
      flag = false
    }
    if (buildingNumber.length == 0) {
      msg = (msg == "" ? "Please enter building no." : msg);
      buildingNoErr = true
      flag = false
    }
    if (roadNumber.length == 0) {
      msg = (msg == "" ? "Please enter road no." : msg);
      roadErr = true
      flag = false
    }
    if (blockNumber.length == 0) {
      msg = (msg == "" ? "Please enter block no." : msg);
      blockErr = true
      flag = false
    }
    if (placeOfBirth.length == 0) {
      msg = (msg == "" ? "Please enter place of birth." : msg);
      placeOfBirthErr = true
      flag = false
    }
    if (occupation.length == 0) {
      msg = (msg == "" ? "Please enter occupation." : msg);
      occupationErr = true
      flag = false
    }

    if (empAddressType.length == 0) {
      msg = (msg == "" ? "Please enter employer address type." : msg);
      empAddressTypeErr = true
      flag = false
    }
    if (empFlatNumber.length == 0) {
      msg = (msg == "" ? "Please enter employer flat no." : msg);
      empFlatNoErr = true
      flag = false
    }
    if (empBuildingNumber.length == 0) {
      msg = (msg == "" ? "Please enter employer building no." : msg);
      empBuildingNoErr = true
      flag = false
    }
    if (empRoadNumber.length == 0) {
      msg = (msg == "" ? "Please enter employer road no." : msg);
      empRoadErr = true
      flag = false
    }
    if (empBlockNumber.length == 0) {
      msg = (msg == "" ? "Please enter employer block no." : msg);
      empBlockErr = true
      flag = false
    }
    if (CPRExpiry.length == 0) {
      if (passportNo.length == 0) {
        msg = (msg == "" ? "Please enter CPR expiry or Passport Number." : msg);
        passportNoErr = true
        flag = false
      } else if (passportExpiry.length == 0) {
        msg = (msg == "" ? "Please enter Passport expiry." : msg);
        passportExpiryErr = true
        flag = false
      }
    }

    if (passportReq && !passportDoc.uploaded) {
      msg = (msg == "" ? "Please upload passport copy." : msg);
      setPassportBtn("danger")
      flag = false
    }
    if (cprCopyReq && !cprCopyDoc.uploaded) {
      msg = (msg == "" ? "Please upload CPR copy." : msg);
      setCprCopyBtn("danger")
      flag = false
    }
    if (addressProofReq && !addressProofDoc.uploaded) {
      msg = (msg == "" ? "Please upload Address Proof copy." : msg);
      setAddressProofBtn("danger")
      flag = false
    }
    if (!flag) openSnackBar(msg)
    setValues({ ...values, dobErr: dobErr, flatNoErr: flatNoErr, buildingNoErr: buildingNoErr, roadErr: roadErr, blockErr: blockErr, addressTypeErr: addressTypeErr, nationalityErr: nationalityErr, placeOfBirthErr: placeOfBirthErr, occupationErr: occupationErr, empAddressTypeErr: empAddressTypeErr, empFlatNoErr: empFlatNoErr, empBuildingNoErr: empBuildingNoErr, empRoadErr: empRoadErr, empBlockErr: empBlockErr, passportNoErr: passportNoErr, passportExpiryErr: passportExpiryErr })
    return flag
  }

  const submitData = () => {
    //function to submit data
    if (validateFrom()) {
      updateCustomer()
    }
  }

  const deleteDoc = (id) => {
    //function to delete doc
    let tempDocs = [...docs]
    tempDocs.splice(id, 1)
    var filteredDocs = newDocs.filter(e => e !== id)
    setNewDocs(filteredDocs)
    setDocs(tempDocs)
    if (addressProofDoc.uploaded && addressProofDoc.index == id) {
      setAddressProofDoc({ uploaded: false, index: null })
    }
    if (cprCopyDoc.uploaded && cprCopyDoc.index == id) {
      setCprCopyDoc({ uploaded: false, index: null })
    }
    if (passportDoc.uploaded && passportDoc.index == id) {
      setPassportDoc({ uploaded: false, index: null })
    }
  }

  const getCustomerDetails = (cpr) => {
    //function to get customer details 
    axios.get(`${config.url().baseURL}/get-customer-edit-details/${cpr}`).then((resp) => {
      let respData = resp.data;
      try {
        if (!respData.err) {
          if (!dataLoaded) {
            setUserData(respData.data[0])
            setDataLoaded(true)
            setSingleAddress(respData.data[0].EmployeeFullAddress ? respData.data[0].EmployeeFullAddress : "")
            setMobile(respData.data[0].Contact.PhoneNumber ? respData.data[0].Contact.PhoneNumber : "")
            setConfirmMobile(respData.data[0].Contact.PhoneNumber ? respData.data[0].Contact.PhoneNumber : "")
            setAddressType(respData.data[0].Address && respData.data[0].Address.AddressType ? respData.data[0].Address.AddressType : "")
            setFlatNumber(respData.data[0].Address && respData.data[0].Address.FlatNumber ? respData.data[0].Address.FlatNumber : "")
            setBuildingNumber(respData.data[0].Address && respData.data[0].Address.BuildingNumber ? respData.data[0].Address.BuildingNumber : "")
            setRoadNumber(respData.data[0].Address && respData.data[0].Address.RoadNumber ? respData.data[0].Address.RoadNumber : "")
            setBlockNumber(respData.data[0].Address && respData.data[0].Address.BlockNumber ? respData.data[0].Address.BlockNumber : "")
            setNationality(respData.data[0].Nationality ? respData.data[0].Nationality : "")
            setPlaceOfBirth(respData.data[0].PlaceOfBirth ? respData.data[0].PlaceOfBirth : "")
            setOccupation(respData.data[0].Occupation ? respData.data[0].Occupation : "")
            setPassportNo(respData.data[0].PassPortNo ? respData.data[0].PassPortNo : "")
            setDocs(respData.data[0].AddressProofDocImg ? respData.data[0].AddressProofDocImg : "")

            setOldAddressType(respData.data[0].Address && respData.data[0].Address.AddressType ? respData.data[0].Address.AddressType : "")
            setOldFlatNumber(respData.data[0].Address && respData.data[0].Address.FlatNumber ? respData.data[0].Address.FlatNumber : "")
            setOldPassportNo(respData.data[0].PassPortNo ? respData.data[0].PassPortNo : "")
            setOldBuildingNumber(respData.data[0].Address && respData.data[0].Address.BuildingNumber ? respData.data[0].Address.BuildingNumber : "")
            setOldRoadNumber(respData.data[0].Address && respData.data[0].Address.RoadNumber ? respData.data[0].Address.RoadNumber : "")
            setOldBlockNumber(respData.data[0].Address && respData.data[0].Address.BlockNumber ? respData.data[0].Address.BlockNumber : "")
            setOldPlaceOfBirth(respData.data[0].PlaceOfBirth ? respData.data[0].PlaceOfBirth : "")

            setEmployer(respData.data[0].NameOfEmployer ? respData.data[0].NameOfEmployer : "")
            setEmpAddressType(respData.data[0].EmployerAddress && respData.data[0].EmployerAddress.AddressType ? respData.data[0].EmployerAddress.AddressType : "")
            setEmpFlatNumber(respData.data[0].EmployerAddress && respData.data[0].EmployerAddress.FlatNumber ? respData.data[0].EmployerAddress.FlatNumber : "")
            setEmpBuildingNumber(respData.data[0].EmployerAddress && respData.data[0].EmployerAddress.BuildingNumber ? respData.data[0].EmployerAddress.BuildingNumber : "")
            setEmpRoadNumber(respData.data[0].EmployerAddress && respData.data[0].EmployerAddress.RoadNumber ? respData.data[0].EmployerAddress.RoadNumber : "")
            setEmpBlockNumber(respData.data[0].EmployerAddress && respData.data[0].EmployerAddress.BlockNumber ? respData.data[0].EmployerAddress.BlockNumber : "")

            setAccountStatus(respData.data[0].AccountStatus ? respData.data[0].AccountStatus : "")
            setOldAccountStatus(respData.data[0].AccountStatus ? respData.data[0].AccountStatus : "")

            if (respData.data[0].Nationality && respData.data[0].Nationality) {
              setDisableNationality(true)
            }
            if (respData.data[0].Address && respData.data[0].Address.AddressType) {
              setDisableAddressType(true)
            }
            if (respData.data[0].Address && respData.data[0].Address.FlatNumber) {
              setDisableFlatNo(true)
            }
            if (respData.data[0].Address && respData.data[0].Address.BuildingNumber) {
              setDisableBuildingNo(true)
            }
            if (respData.data[0].Address && respData.data[0].Address.RoadNumber) {
              setDisableRoad(true)
            }
            if (respData.data[0].Address && respData.data[0].Address.BlockNumber) {
              setDisableBlock(true)
            }
            if (respData.data[0].PlaceOfBirth) {
              setDisablePlaceOfBirth(true)
            }
            if (respData.data[0].Occupation) {
              setDisableOccupation(true)
            }
            if (respData.data[0].EmployerAddress && respData.data[0].EmployerAddress.AddressType) {
              setDisableEmpAddressType(true)
            }
            if (respData.data[0].EmployerAddress && respData.data[0].EmployerAddress.FlatNumber) {
              setDisableEmpFlatNo(true)
            }
            if (respData.data[0].EmployerAddress && respData.data[0].EmployerAddress.BuildingNumber) {
              setDisableEmpBuildingNo(true)
            }
            if (respData.data[0].EmployerAddress && respData.data[0].EmployerAddress.RoadNumber) {
              setDisableEmpRoad(true)
            }
            if (respData.data[0].EmployerAddress && respData.data[0].EmployerAddress.BlockNumber) {
              setDisableEmpBlock(true)
            }
            if (respData.data[0].DateOfBirth) {
              setDOB(libs.convertDate(respData.data[0].DateOfBirth))
              setOldDOB(libs.convertDate(respData.data[0].DateOfBirth))

            }
            if (respData.data[0].EmployeeCRPExpiry) {
              setCPRExpiry(libs.convertDate(respData.data[0].EmployeeCRPExpiry))
              setOldCPRExpiry(libs.convertDate(respData.data[0].EmployeeCRPExpiry))
            }
            if (respData.data[0].PassportExpiry) {
              setPassportExpiry(libs.convertDate(respData.data[0].PassportExpiry))
              setOldPassportExpiry(libs.convertDate(respData.data[0].PassportExpiry))
            }

            if (respData.data[1]) {
              if (updated == null) {
                setUpdated(respData.data[1])
              }
            }

            checkUserType(props.emp.status)
          }

        } else {
          openSnackBar(respData.msg);
        }
      } catch (error) {
        console.log(error)
      }


    }).catch((err) => {
      console.log(err)
      openSnackBar("Unable to process.");
      setSubmitting(false);
      setRemarks("")
    });
  }




  const updateCustomer = () => {
    //function save changes 
    if (!submitting) {
      setSubmitting(true);
      let apiData = {
        dob: moment(DOB).format("MM/DD/YYYY"),
        phone: mobile,
        nationality,
        addressType,
        flatNumber,
        buildingNumber,
        roadNumber,
        blockNumber,
        placeOfBirth,
        occupation,
        empAddressType,
        empFlatNumber,
        empBuildingNumber,
        empRoadNumber,
        empBlockNumber,
        cprExpiry: moment(CPRExpiry).format("MM/DD/YYYY"),
        passportNo,
        passportExpiry,
        addressDocs: docs,
        accountStatus
      }
      axios.post(`${config.url().baseURL}/edit-customer/${props.emp.cpr}`, apiData).then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          setSubmitting(false);
          setRemarks("")
          setOpenRemarks(false)
          setRespAlert(
            <SweetAlert
              success
              style={{ display: "block" }}
              title={respData['msg']}
              onConfirm={() => { setRespAlert(null); goBack() }}
              confirmBtnCssClass={classes.button + " " + classes.success}
            />
          );

        } else {
          openSnackBar(respData.msg);
          setOpenRemarks(false)
          setSubmitting(false);
          setRemarks("")
        }
      }).catch((err) => {
        openSnackBar("Unable to process.");
        setSubmitting(false);
        setRemarks("")

      });
    }
  }

  const viewIDProof = (image) => {
    //function to open ID Proof
    let extension = image.substring(image.indexOf("/") + 1, image.indexOf(";base64"));
    setDocumentViewerData(image)
    if (extension == "pdf") {
      setPageNumber(1)
      setOpenPDFViewer(true)
      setOpenImageViewer(false)
    } else {
      setOpenImageViewer(true)
      setOpenPDFViewer(false)
    }
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const dropDoc = (images) => {
    //function to drop doc
    if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
      if (images[0].size < 4000000) {
        libs.getBase64(images[0], (result) => {
          let tempDocs = [...docs]
          let tempNewDocs = [...newDocs]
          tempNewDocs.push(tempDocs.length)
          tempDocs.push(result)
          setDocs(tempDocs)
          setNewDocs(tempNewDocs)
        });
      } else {
        openSnackBar("File size is too large.");
      }
    } else {
      openSnackBar("Please upload jpg,jpeg and png files");
    }
  }

  const dropPassport = (images) => {
    //function to drop doc
    if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
      if (images[0].size < 4000000) {
        libs.getBase64(images[0], (result) => {
          let tempDocs = [...docs]
          let tempNewDocs = [...newDocs]
          tempNewDocs.push(tempDocs.length)
          tempDocs.push(result)
          setPassportDoc({ uploaded: true, index: tempDocs.length - 1 })
          setDocs(tempDocs)
          setNewDocs(tempNewDocs)
          setPassportBtn("primary")
        });
      } else {
        openSnackBar("File size is too large.");
      }
    } else {
      openSnackBar("Please upload jpg,jpeg and png files");
    }
  }


  const dropCPRCopy = (images) => {
    //function to drop doc
    if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
      if (images[0].size < 4000000) {
        libs.getBase64(images[0], (result) => {
          let tempDocs = [...docs]
          tempDocs.push(result)
          let tempNewDocs = [...newDocs]
          tempNewDocs.push(tempDocs.length)
          setCprCopyDoc({ uploaded: true, index: tempDocs.length - 1 })
          setDocs(tempDocs)
          setNewDocs(tempNewDocs)
          setCprCopyBtn("primary")
        });
      } else {
        openSnackBar("File size is too large.");
      }
    } else {
      openSnackBar("Please upload jpg,jpeg and png files");
    }
  }


  const dropAddressProof = (images) => {
    //function to drop doc
    if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
      if (images[0].size < 4000000) {
        libs.getBase64(images[0], (result) => {
          let tempDocs = [...docs]
          tempDocs.push(result)
          let tempNewDocs = [...newDocs]
          tempNewDocs.push(tempDocs.length)
          setAddressProofDoc({ uploaded: true, index: tempDocs.length - 1 })
          setDocs(tempDocs)
          setNewDocs(tempNewDocs)
          setAddressProofBtn("primary")
        });
      } else {
        openSnackBar("File size is too large.");
      }
    } else {
      openSnackBar("Please upload jpg,jpeg and png files");
    }
  }

  const validateOldData = () => {
    let cprReq = false, addressReq = false, passportCopyReq = false
    if (moment(DOB).format("YYYY-MM-DD") !== moment(oldDOB).format("YYYY-MM-DD") && DOB !== "") {
      cprReq = true
    }
    if (moment(CPRExpiry).format("YYYY-MM-DD") !== moment(oldCPRExpiry).format("YYYY-MM-DD") && CPRExpiry !== "") {
      cprReq = true
    }
    if (passportNo !== oldPassportNo && passportNo !== "") {
      passportCopyReq = true
    }
    if (moment(passportExpiry).format("YYYY-MM-DD") !== moment(oldPassportExpiry).format("YYYY-MM-DD") && passportExpiry !== "") {
      passportCopyReq = true
    }
    if (addressType !== oldAddressType && addressType !== "") {
      addressReq = true
    }
    if (flatNumber !== oldFlatNumber && flatNumber !== "") {
      addressReq = true
    }
    if (buildingNumber !== oldBuildingNumber && buildingNumber !== "") {
      addressReq = true
    }
    if (roadNumber !== oldRoadNumber && roadNumber !== "") {
      addressReq = true
    }
    if (blockNumber !== oldBlockNumber && blockNumber !== "") {
      addressReq = true
    }
    if (placeOfBirth !== oldPlaceOfBirth && placeOfBirth !== "") {
      passportCopyReq = true
    }
    if (accountStatus == "ACTIVE") {
      if (accountStatus !== oldAccountStatus) {
        cprReq = true
      }
    }

    setAddressProofReq(addressReq)
    setPassportReq(passportCopyReq)
    setCprCopyReq(cprReq)

  }

  const changeStatus = () => {
    //function to change status of customer
    if (status !== null) {
      if (!submitting) {
        setSubmitting(true);
        let apiData = { status, remarks }
        axios.post(`${config.url().baseURL}/checker-update-customer/${props.emp.cpr}`, apiData).then((resp) => {
          let respData = resp.data;
          if (!respData.err) {
            setSubmitting(false);
            setRemarks("")
            setStatus(null)
            setOpenRemarks(false)
            setRespAlert(
              <SweetAlert
                success
                style={{ display: "block" }}
                title={respData['msg']}
                onConfirm={() => { setRespAlert(null); goBack() }}
                confirmBtnCssClass={classes.button + " " + classes.success}
              />
            );
          } else {
            openSnackBar(respData.msg);
            setOpenRemarks(false)
            setSubmitting(false);
            setRemarks("")
          }
        }).catch((err) => {
          openSnackBar("Unable to process.");
          setSubmitting(false);
          setRemarks("")
        });
      }
    } else {
      openSnackBar("No status found.");
    }

  }

  const goBack = () => {
    //function to go back to the previous page
    setUserData("")
    setSingleAddress("")
    setMobile("")
    setConfirmMobile("")
    setAddressType("")
    setFlatNumber("")
    setBuildingNumber("")
    setRoadNumber("")
    setBlockNumber("")
    setNationality("")
    setPlaceOfBirth("")
    setOccupation("")
    setPassportNo("")
    setDocs("")

    setOldAddressType("")
    setOldFlatNumber("")
    setOldPassportNo("")
    setOldBuildingNumber("")
    setOldRoadNumber("")
    setOldBlockNumber("")
    setOldPlaceOfBirth("")

    setEmployer("")
    setEmpAddressType("")
    setEmpFlatNumber("")
    setEmpBuildingNumber("")
    setEmpRoadNumber("")
    setEmpBlockNumber("")
    setDocs([])
    props.close()
  }
  const getRemarks = (status) => {
    //function to open remarks model
    setStatus(status)
    setOpenRemarks(true)
  }
  return (
    <div style={{ margin: "10px", textAlign: "center" }}>
      {respAlert}
      <Collapse
        in={!dataLoaded}
        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
        {...(true ? { timeout: 500 } : {})}>
        <PropagateLoader color="#004fa4" loading={true} css={override} size={8} />
      </Collapse>
      <Collapse
        in={dataLoaded}
        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
        {...(true ? { timeout: 500 } : {})}>
        <div style={{ textAlign: "left" }}>
          <p>
            <b>
              Name : {(userData && userData.FirstNameEnglish ? userData.FirstNameEnglish : null) + " " + (userData && userData.LastNameEnglish ? userData.LastNameEnglish : null)} &nbsp; &nbsp;
              CPR : {userData && userData.EmployeeCPR ? userData.EmployeeCPR : null} <br />
              Full Address : {singleAddress}
            </b>
          </p>
        </div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={2}>
            <Collapse in={true} style={{ transformOrigin: "0 0 0", textAlign: "center" }}  {...(true ? { timeout: 500 } : {})}>
              {
                docs ? docs.map((doc, key) => {
                  let extension = doc.substring(doc.indexOf("/") + 1, doc.indexOf(";base64"));
                  if (extension == "pdf") {
                    return (
                      <>
                        <Paper style={{ textAlign: "center", padding: "5px" }}>
                          <Document
                            style={{ width: "100px" }}
                            className={classes.customLink}
                            onClick={() => viewIDProof(doc)}
                            file={doc}
                            onLoadSuccess={onDocumentLoadSuccess}
                          >
                            <Page pageNumber={pageNumberTwo} />
                          </Document>
                          {
                            saveBtn && (newDocs.find(doc => doc == key) !== undefined || false) ?
                              <IconButton
                                onClick={() => deleteDoc(key)}
                                size="sm"
                                style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                                aria-label="delete">
                                <DeleteIcon />
                              </IconButton>
                              : null
                          }
                          {/* <IconButton
                            onClick={() => viewIDProof(doc)}
                            size="sm"
                            style={{ marginTop: "-10px", marginBottom: "-10px", color: "#014FA4" }}
                            aria-label="delete">
                            <VisibilityIcon />
                          </IconButton> */}
                        </Paper>
                        <br />
                      </>
                    )
                  } else {
                    return (
                      <>
                        <Paper style={{ textAlign: "center", padding: "5px" }}>
                          <img
                            onClick={() => viewIDProof(doc)}
                            src={doc}
                            alt="..."
                            className={
                              classes.imgRounded +
                              " " +
                              classes.imgFluid +
                              " " +
                              classes.customLink
                            } />
                          {
                            saveBtn && (newDocs.find(doc => doc == key) !== undefined || false) ?
                              <IconButton
                                onClick={() => deleteDoc(key)}
                                size="sm"
                                style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                                aria-label="delete">
                                <DeleteIcon />
                              </IconButton> : null
                          }

                          {/* <IconButton
                            onClick={() => viewIDProof(doc)}
                            size="sm"
                            style={{ marginTop: "-10px", marginBottom: "-10px", color: "#014FA4" }}
                            aria-label="delete">
                            <VisibilityIcon />
                          </IconButton> */}
                        </Paper>
                        <br />
                      </>
                    )
                  }
                })
                  : null
              }
              {
                saveBtn ?
                  <Dropzone onDrop={dropDoc}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button size="sm" fullWidth color="primary">
                          Add Document
                        </Button>
                      </div>
                    )}
                  </Dropzone>
                  : null
              }



            </Collapse>


          </GridItem>

          <GridItem xs={12} sm={12} md={10}>
            <Table
              tableData={[
                ["Mobile", ":", <TextField
                  type="text"
                  fullWidth
                  //focused={updated && updated.phone}
                  helperText={updated && updated.phone ? "Updated" : ""}
                  error={mobile.length != 8}
                  FormHelperTextProps={{
                    className: classes.helperText
                  }}
                  inputProps={{
                    autoComplete: "off",
                    type: "text",
                    value: mobile,
                    readOnly: !saveBtn,
                    onPaste: (e) => {
                      e.preventDefault()
                      return false;
                    },
                    onCopy: (e) => {
                      e.preventDefault()
                      return false;
                    },
                    onChange: (e) => {
                      const re = /^[0-9\b]+$/;
                      var res = e.target.value.substring(0, 1)
                      if (res == 3 || res == 6 || e.target.value == "") {
                        if (e.target.value === "" || re.test(e.target.value)) {
                          if (e.target.value.length <= 8) {
                            setMobile(e.target.value);
                          }
                        }
                      }
                    },

                  }}
                />,
                  "Re-enter Mobile", ":", <TextField
                    type="text"
                    fullWidth
                    error={mobile !== confirmMobile}
                    inputProps={{
                      autoComplete: "off",
                      type: "text",
                      value: confirmMobile,
                      readOnly: !saveBtn,
                      onPaste: (e) => {
                        e.preventDefault()
                        return false;
                      },
                      onCopy: (e) => {
                        e.preventDefault()
                        return false;
                      },
                      onChange: (e) => {
                        const re = /^[0-9\b]+$/;
                        var res = e.target.value.substring(0, 1)
                        if (res == 3 || res == 6 || e.target.value == "") {
                          if (e.target.value === "" || re.test(e.target.value)) {
                            if (e.target.value.length <= 8) {
                              setConfirmMobile(e.target.value);
                            }
                          }
                        }
                      },

                    }}
                  />
                ],
                [
                  "Date of Birth", ":", <TextField
                    type="date"
                    fullWidth
                    error={values.dobErr}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    //focused={updated && updated.dob}
                    helperText={updated && updated.dob ? "Updated" : ""}
                    inputProps={{
                      autoComplete: "off",
                      type: "date",
                      readOnly: !saveBtn,
                      max: moment(new Date()).format("YYYY-MM-DD"),
                      value: moment(DOB).format("YYYY-MM-DD"),
                      onChange: (e) => {
                        setDOB(e.target.value);
                      },
                      onBlur: (e) => {
                        if (moment(e.target.value).isAfter(moment(new Date())) && (new Date(e.target.value)).isValid()) {
                          setDOB("");
                          openSnackBar("Invalid DOB.")
                        } else {
                          validateOldData()
                        }
                      },
                    }}
                  />,
                  "CPR Expiry", ":",
                  <TextField
                    type="date"
                    fullWidth
                    error={values.cprExpiryErr}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    //focused={updated && updated.cprExpiry}
                    helperText={updated && updated.cprExpiry ? "Updated" : ""}
                    inputProps={{
                      autoComplete: "off",
                      type: "date",
                      readOnly: !saveBtn,
                      // min: moment(new Date().getDate() + 1).format("YYYY-MM-DD"),
                      value: moment(CPRExpiry).format("YYYY-MM-DD"),
                      onChange: (e) => {
                        setCPRExpiry(e.target.value);
                      },
                      // onBlur: (e) => {
                      //   if (moment(e.target.value).isBefore(moment(new Date()))) {
                      //     setCPRExpiry("");
                      //     openSnackBar("Invalid CPR Expiry.")
                      //   } else {
                      //     validateOldData()
                      //   }

                      // },
                    }}
                  />
                ],
                [
                  "Passport No", ":", <TextField
                    type="text"
                    fullWidth
                    error={values.passportNoErr}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    //focused={updated && updated.passportNo}
                    helperText={updated && updated.passportNo ? "Updated" : ""}
                    inputProps={{
                      autoComplete: "off",
                      readOnly: !saveBtn,
                      value: passportNo,
                      onChange: (e) => {
                        setPassportNo(libs.validate(e.target.value, "alphanumeric", 100) ? e.target.value : passportNo);
                        setValues({ ...values, passportNoErr: false })
                      },
                      onBlur: (e) => {
                        validateOldData()
                        setPassportNo(e.target.value.trim())
                        if (!passportNo) {
                          setPassportExpiry("")
                          setValues({ ...values, passportExpiryErr: false })
                        }
                      },
                    }}
                  />,
                  "Passport Expiry", ":",
                  <TextField
                    type="date"
                    fullWidth
                    error={values.passportExpiryErr}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    //focused={updated && updated.passportExpiry}
                    disabled={!passportNo && saveBtn}
                    helperText={updated && updated.passportExpiry ? "Updated" : ""}
                    inputProps={{
                      autoComplete: "off",
                      type: "date",
                      readOnly: !saveBtn,
                      min: moment(new Date().getDate() + 1).format("YYYY-MM-DD"),
                      value: moment(passportExpiry).format("YYYY-MM-DD"),
                      onChange: (e) => {
                        setPassportExpiry(e.target.value);
                        setValues({ ...values, passportExpiryErr: false })
                      },
                      onBlur: (e) => {
                        if (moment(e.target.value).isBefore(moment(new Date()))) {
                          setPassportExpiry("");
                          openSnackBar("Invalid Passport Expiry.")
                        } else {

                          validateOldData()
                        }
                      },
                    }}
                  />
                ],
                [
                  "Address Type", ":", <TextField
                    type="text"
                    fullWidth
                    error={values.addressTypeErr}
                    //focused={updated && updated.addressType}
                    helperText={updated && updated.addressType ? "Updated" : ""}
                    // disabled={disableAddressType}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    inputProps={{
                      autoComplete: "off",
                      readOnly: !saveBtn,
                      value: addressType,
                      onChange: (e) => {
                        setAddressType(libs.validate(e.target.value.trim(), "alphanumeric", 10) ? e.target.value.trim() : addressType);
                        setValues({ ...values, addressTypeErr: false })
                      },
                      onBlur: (e) => {
                        setAddressType(e.target.value.trim())
                        validateOldData()
                      },
                    }}
                  />,
                  "Flat Number", ":", <TextField
                    type="text"
                    fullWidth
                    error={values.flatNoErr}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    //focused={updated && updated.flatNumber}
                    helperText={updated && updated.flatNumber ? "Updated" : ""}
                    // disabled={disableFlatNo}
                    inputProps={{
                      autoComplete: "off",
                      value: flatNumber,
                      readOnly: !saveBtn,
                      onChange: (e) => {
                        setFlatNumber(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : flatNumber);
                        setValues({ ...values, flatNoErr: false })
                      },
                      onBlur: (e) => {
                        validateOldData()
                        setFlatNumber(e.target.value.trim())
                      },
                    }}
                  />,
                ],
                [
                  "Building Number", ":", <TextField
                    type="text"
                    fullWidth
                    error={values.buildingNoErr}
                    // disabled={disableBuildingNo}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    //focused={updated && updated.buildingNumber}
                    helperText={updated && updated.buildingNumber ? "Updated" : ""}
                    inputProps={{
                      autoComplete: "off",
                      value: buildingNumber,
                      readOnly: !saveBtn,
                      onChange: (e) => {
                        setBuildingNumber(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : buildingNumber);
                        setValues({ ...values, buildingNoErr: false })
                      },
                      onBlur: (e) => {
                        validateOldData()
                        setBuildingNumber(e.target.value.trim())
                      },
                    }}
                  />,
                  "Road Number", ":", <TextField
                    type="text"
                    fullWidth
                    error={values.roadErr}
                    // disabled={disableRoad}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    //focused={updated && updated.roadNumber}
                    helperText={updated && updated.roadNumber ? "Updated" : ""}
                    inputProps={{
                      autoComplete: "off",
                      value: roadNumber,
                      readOnly: !saveBtn,
                      onChange: (e) => {
                        setRoadNumber(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : roadNumber);
                        setValues({ ...values, roadErr: false })
                      },
                      onBlur: (e) => {
                        validateOldData()
                        setRoadNumber(e.target.value.trim())
                      },

                    }}
                  />,
                ],
                [
                  "BlockNumber", ":", <TextField
                    type="text"
                    fullWidth
                    error={values.blockErr}
                    // disabled={disableBlock}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    //focused={updated && updated.blockNumber}
                    helperText={updated && updated.blockNumber ? "Updated" : ""}
                    inputProps={{
                      autoComplete: "off",
                      value: blockNumber,
                      readOnly: !saveBtn,
                      onChange: (e) => {
                        setBlockNumber(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : blockNumber);
                        setValues({ ...values, blockErr: false })
                      },
                      onBlur: (e) => {
                        validateOldData()
                        setBlockNumber(e.target.value.trim())
                      },
                    }}
                  />,
                  "Place of Birth", ":", <TextField
                    type="text"
                    fullWidth
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    //focused={updated && updated.placeOfBirth}
                    helperText={updated && updated.placeOfBirth ? "Updated" : ""}
                    error={values.placeOfBirthErr}
                    disabled={disablePlaceOfBirth  && saveBtn}
                    inputProps={{
                      autoComplete: "off",
                      value: placeOfBirth,
                      placeholder: "ISO 2 Standard",
                      readOnly: !saveBtn,
                      onChange: (e) => {
                        setPlaceOfBirth(libs.validate(e.target.value, "alphabet", 2) ? e.target.value.toUpperCase() : placeOfBirth);
                        setValues({ ...values, placeOfBirthErr: false })
                      },
                      onBlur: (e) => {
                        setPlaceOfBirth(e.target.value.trim())
                        validateOldData()
                      },
                    }}
                  />
                ],
                [
                  "Occupation", ":", <TextField
                    type="text"
                    fullWidth
                    error={values.occupationErr}
                    disabled={disableOccupation  && saveBtn}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    //focused={updated && updated.occupation}
                    helperText={updated && updated.occupation ? "Updated" : ""}
                    inputProps={{
                      autoComplete: "off",
                      value: occupation,
                      readOnly: !saveBtn,
                      onChange: (e) => {
                        setOccupation(libs.validate(e.target.value, "alphanumeric", 50) ? e.target.value : blockNumber);
                        setValues({ ...values, occupationErr: false })
                      },
                      onBlur: (e) => {
                        setOccupation(e.target.value.trim())
                      },
                    }}
                  />,
                  "Nationality", ":",
                  <TextField
                    type="text"
                    fullWidth
                    error={values.nationalityErr}
                    disabled={disableNationality  && saveBtn}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    //focused={updated && updated.nationality}
                    helperText={updated && updated.nationality ? "Updated" : ""}
                    inputProps={{
                      autoComplete: "off",
                      value: nationality,
                      readOnly: !saveBtn,
                      placeholder: "ISO 2 Standard",
                      onChange: (e) => {
                        setNationality(libs.validate(e.target.value, "alphabet", 2) ? e.target.value.toUpperCase() : nationality);
                        setValues({ ...values, nationalityErr: false })
                      },
                      onBlur: (e) => {
                        setNationality(e.target.value.trim())
                      },
                    }}
                  />
                ],
                [
                  <b>Employer Name</b>, ":", <b>{employer}</b>,
                  "", "", ""
                ],
                [
                  " EmployerAddress Type", ":", <TextField
                    type="text"
                    fullWidth
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    //focused={updated && updated.empAddressType}
                    helperText={updated && updated.empAddressType ? "Updated" : ""}
                    error={values.empAddressTypeErr}
                    disabled={disableEmpAddressType && saveBtn}
                    inputProps={{
                      autoComplete: "off",
                      value: empAddressType,
                      readOnly: !saveBtn,
                      onChange: (e) => {
                        setEmpAddressType(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : empAddressType);
                        setValues({ ...values, empAddressTypeErr: false })
                      },
                      onBlur: (e) => {
                        setEmpAddressType(e.target.value.trim())
                      },
                    }}
                  />,
                  "Employer Flat Number", ":", <TextField
                    type="text"
                    fullWidth
                    error={values.empFlatNoErr}
                    disabled={disableEmpFlatNo  && saveBtn}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    //focused={updated && updated.empFlatNumber}
                    helperText={updated && updated.empFlatNumber ? "Updated" : ""}
                    inputProps={{
                      autoComplete: "off",
                      value: empFlatNumber,
                      readOnly: !saveBtn,
                      onChange: (e) => {
                        setEmpFlatNumber(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : empFlatNumber);
                        setValues({ ...values, empFlatNoErr: false })
                      },
                      onBlur: (e) => {
                        setEmpFlatNumber(e.target.value.trim())
                      },
                    }}
                  />,
                ],
                [
                  "Employer Building Number", ":", <TextField
                    type="text"
                    fullWidth
                    error={values.empBuildingNoErr}
                    disabled={disableEmpBuildingNo  && saveBtn}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    //focused={updated && updated.empBuildingNumber}
                    helperText={updated && updated.empBuildingNumber ? "Updated" : ""}
                    inputProps={{
                      autoComplete: "off",
                      value: empBuildingNumber,
                      readOnly: !saveBtn,
                      onChange: (e) => {
                        setEmpBuildingNumber(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : empBuildingNumber);
                        setValues({ ...values, empBuildingNoErr: false })
                      },
                      onBlur: (e) => {
                        setEmpBuildingNumber(e.target.value.trim())
                      },
                    }}
                  />,
                  "Employer Road Number", ":", <TextField
                    type="text"
                    fullWidth
                    error={values.empRoadErr}
                    disabled={disableEmpBlock  && saveBtn}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    //focused={updated && updated.empRoadNumber}
                    helperText={updated && updated.empRoadNumber ? "Updated" : ""}
                    inputProps={{
                      autoComplete: "off",
                      value: empRoadNumber,
                      readOnly: !saveBtn,
                      onChange: (e) => {
                        setEmpRoadNumber(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : empRoadNumber);
                        setValues({ ...values, empRoadErr: false })
                      },
                      onBlur: (e) => {
                        setEmpRoadNumber(e.target.value.trim())
                      },
                    }}
                  />,
                ],
                [
                  "Employer BlockNumber", ":", <TextField
                    type="text"
                    fullWidth
                    error={values.empBlockErr}
                    disabled={disableEmpBlock  && saveBtn}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    //focused={updated && updated.empBlockNumber}
                    helperText={updated && updated.empBlockNumber ? "Updated" : ""}
                    inputProps={{
                      autoComplete: "off",
                      value: empBlockNumber,
                      readOnly: !saveBtn,
                      onChange: (e) => {
                        setEmpBlockNumber(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : empBlockNumber);
                        setValues({ ...values, empBlockErr: false })
                      },
                      onBlur: (e) => {
                        setEmpBlockNumber(e.target.value.trim())
                      },
                    }}
                  />,
                  "Account Status", ":",
                  <>
                    <FormControl fullWidth>
                      <Select
                        value={accountStatus}
                        onBlur={() => {
                          validateOldData()
                        }}
                        readOnly={!saveBtn}
                        onChange={(e) => {
                          setAccountStatus(e.target.value);
                        }}>

                        <MenuItem value="ACTIVE">ACTIVE </MenuItem>
                        <MenuItem value="DORMANT">DORMANT </MenuItem>
                        <MenuItem value="DISABLED">DISABLED</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                ],
              ]}
            />
            <Collapse in={(passportReq && !passportDoc.uploaded) || (cprCopyReq && !cprCopyDoc.uploaded) || (addressProofReq && !addressProofDoc.uploaded)} style={{ transformOrigin: "0 0 0", textAlign: "center" }}  {...(true ? { timeout: 500 } : {})}>
              <Table
                tableData={[
                  [
                    <Collapse in={passportReq && !passportDoc.uploaded} style={{ transformOrigin: "0 0 0", textAlign: "center" }}  {...(true ? { timeout: 500 } : {})}>
                      <Dropzone onDrop={dropPassport}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button size="sm" fullWidth color={passportBtn}>
                              Upload Passport
                            </Button>
                          </div>
                        )}
                      </Dropzone>
                    </Collapse>
                    ,
                    <Collapse in={cprCopyReq && !cprCopyDoc.uploaded} style={{ transformOrigin: "0 0 0", textAlign: "center" }}  {...(true ? { timeout: 500 } : {})}>
                      <Dropzone onDrop={dropCPRCopy}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button size="sm" fullWidth color={cprCopyBtn}>
                              Upload CPR
                            </Button>
                          </div>
                        )}
                      </Dropzone>
                    </Collapse>
                    ,
                    <Collapse in={addressProofReq && !addressProofDoc.uploaded} style={{ transformOrigin: "0 0 0", textAlign: "center" }}  {...(true ? { timeout: 500 } : {})}>
                      <Dropzone onDrop={dropAddressProof}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button size="sm" fullWidth color={addressProofBtn}>
                              Upload Address Proof
                            </Button>
                          </div>
                        )}
                      </Dropzone>
                    </Collapse>,

                  ]
                ]} />
            </Collapse>
          </GridItem>
        </GridContainer>
      </Collapse>
      <Button color="primary" onClick={() => goBack()} simple size="md"><ArrowBackIcon />Back</Button>
      {dataLoaded ? saveBtn ? submitting ? < CircularProgress style={{ marginTop: "35px" }} size={20} /> : (<Button color="primary" onClick={() => submitData()} size="sm">Save</Button>) : null : null}
      {dataLoaded ? approvalBtn ? submitting ? < CircularProgress style={{ marginTop: "35px" }} size={20} /> : <><Button color="danger" onClick={() => getRemarks("RECHECK")} size="sm">Recheck</Button> <Button color="primary" onClick={() => getRemarks("APPROVED")} size="sm">Approve</Button></> : null : null}
      <Dialog onClose={() => setOpenRemarks(false)} aria-labelledby="simple-dialog-title" PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }} open={openRemarks}>
        <h4 className={classes.remarksHead}>Please enter remarks to continue.</h4>
        <Paper className={classes.dialogContainer}>
          <InputBase
            autoFocus={true}
            fullWidth
            className={classes.input}
            placeholder="Remarks"
            inputProps={{
              onChange: (event) => { setRemarks(event.target.value) },
              value: remarks,
            }}
          />

          <Button className={classes.nextButton}
            onClick={() => changeStatus()}
            simple
            size="lg" color="primary">
            <ArrowForwardIcon fontSize="medium" />
          </Button>

        </Paper>

      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          // paper: classes.processModal,
        }}
        open={openPDFeViewer}
        TransitionComponent={Transition}
        onClose={() => {
          setOpenPDFViewer(false);
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <Document
            file={documentViewerData}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <p>Page {pageNumber} of {numPages}</p>
          <Button size="sm" color="primary" onClick={() => {
            if (pageNumber < 2) {
              setPageNumber(1)
            } else {
              let newPageNumber = pageNumber - 1
              setPageNumber(newPageNumber)
            }
          }} >Pervious</Button>
          <Button size="sm" color="primary"
            onClick={() => {
              if (numPages > pageNumber) {
                let newPageNumber = pageNumber + 1
                setPageNumber(newPageNumber)
              } else {
                setPageNumber(numPages)
              }
            }}
          >Next</Button>
        </DialogContent>
      </Dialog>

      {
        openImageViewer && (
          <Lightbox
            mainSrc={documentViewerData}
            onCloseRequest={() => setOpenImageViewer(false)}
          />
        )
      }
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
            setSnackBarMsg("");
          }}
          severity={snackBarType}>
          {snackBarMsg}
        </Alert>
      </Snackbar>

    </div >
  );
}