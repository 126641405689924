import * as React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SimpleButton from "@mui/lab/LoadingButton";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CircularProgress from "@mui/material/CircularProgress";
import swal from 'sweetalert';
import moment from 'moment';

import Slide from "@material-ui/core/Slide";

import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import SweetAlert from "react-bootstrap-sweetalert";

import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import XlxUploadAndCheck from "./XlxUploadAndCheck";
import { styled } from "@mui/system";
import MuiAlert from "@material-ui/lab/Alert";
import { IconButton, TextareaAutosize, Tooltip } from "@material-ui/core";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Button from "components/CustomButtons/Button.js";
import { MenuItem, Select, Snackbar } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import Box from "@mui/material/Box";
import { Grid, TextField } from "@material-ui/core";
import * as XLSX from 'xlsx';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.js";

import { Stack, responsiveFontSizes } from "@mui/material";
// import { Button } from "@material-ui/core";
import CardFooter from "components/Card/CardFooter";
import axios from "axios";
import config from "config";
import { PropagateLoader } from "react-spinners";
import { useSelector } from 'react-redux';
import { Update } from "@material-ui/icons";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;

const useStyles = makeStyles((theme) => ({
  ...SweetAlertStyle,
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    float: "center",
    display: "inlineBlock",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  addButton: {
    float: "right !important",
    display: "inlineBlock",
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  addDeptContainer: {
    height: "49px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginBottom: "5px !important",
  },
  addDeptButton: {
    marginRight: "-5px !important",
  },
  input: {
    flex: 1,
    height: "45px",
    marginLeft: "15px",
  },
  searchHead: {
    marginTop: "-5px",
    marginBottom: "1px",
    color: "#144b85",
    fontWeight: "400",
  },
  dialogContainer: {
    display: "flex",
  },
  inputPassword: {
    flex: 1,
    marginTop: "10px",
    height: "45px",

    paddingLeft: "10px",
  },
  nextButton: {
    marginLeft: "150px",
  },
  passwordHead: {
    fontWeightL: "500",
    marginBottom: "0px",
    color: "#ffffff",
  },
  indicator: {
    backgroundColor: "white",
  },

  ...modalStyle(theme),
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="down" ref={ref} {...props} />;
// });

export default function ViewReward({ back, type, id, snack }) {
  const user = useSelector(state => state.user);

  const classes = useStyles();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");

  const [rewardType, setRewardType] = React.useState("");
  const [couponCodeType, setCouponCodeType] = React.useState("");
  const [productId, setProductId] = React.useState("");
  const [merchantName, setMerchantName] = React.useState("");
  const [denomination, setDenomination] = React.useState("");
  //    percentage
  const [currency, setCurrency] = React.useState("");
  const [redemptionType, setRedumptionType] = React.useState("");
  const [redumptionAccepted, setRedumptionAccepted] = React.useState("");
  const [
    redemptionAcceptedFormat,
    setRedemptionAcceptedFormat,
  ] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [termsAndConditions, setTermsAndConditions] = React.useState("");
  const [validFrom, setValidFrom] = React.useState("");
  const [validTo, setValidTo] = React.useState("");
  const [count, setCount] = React.useState("");
  const [serialNo, setSerialNo] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [sheet, setSheet] = React.useState("");
  const [snackBarType, setSnackBarType] = React.useState("error");
  const [data, setData] = React.useState([]);
  const formData = new FormData();
  const [inSubmit, setInSubmit] = React.useState(false)
  const [barcodeTypes, setBarcodeTypes] = React.useState([])
  const [respAlert, setRespAlert] = React.useState(null);

  const [showActionBtn, setShowActionBtn] = React.useState(false)
  const [approveBtn, setApproveBtn] = React.useState("");
  const [recheckBtn, setRecheckBtn] = React.useState("");
  const [showLoader, setShowLoader] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [userDetails, setUserDetails] = React.useState("");
  const [showRemarks, setShowRemarks] = React.useState("")
  const [comments, setComments] = React.useState("")
  // const [remarks,  setRemarks] = React.useState("");


  const handleCheckXlx = () => {
    setInSubmit(false)

    if (data.length == 0) {
      swal("Oops!", "Please upload an Excel file with at least 1 record.", "error");
    } else {
      const headerRow = Object.keys(data[0]);

      if (headerRow[0] !== 'serialNo') {
        swal("Oops!", "The Excel file must contain a column with the header 'serialNo'.", "error");
      } else if (Number(count) !== data.length) {
        swal("Oops!", `The count and the number of records in the Excel file do not match.`, "error");
      } else {
        !inSubmit && swal({
          title: "Success",
          text: "The Excel file is in the correct format.",
          icon: "success",
          button: "OK",

        })
        // setChecked(true)
        return true
        // handleSubmit()
      }
    }
    // else if(Object.entries(duplicateSerialNumbers).length !== 0){
    //   swal("Oops!", `Duplicate Serial Numbers Found: ${Object.entries(duplicateSerialNumbers).map(([key, value]) => `${key} (at row ${value + 1})`).join(", ")}`, "error");


    // }

  };

  // const fetchBarcodeTypes = async () => {
  //   try {
  //     // const map = new Map()
  //     const resp = await axios.get(`${config.url().app_URL}/CXPortal/v13/getBarcodeTypes`)
  //     const barcodeTypesData = resp.data.data;

  //     const types = Object.keys(barcodeTypesData);
  //     console.log(types);
  //     const barcodeTypesArray = types.map(type => {
  //       // map.set(type, barcodeTypesData[type])
  //       return ({
  //         label: type,
  //         value: barcodeTypesData[type]
  //       })
  //     });
  //     setBarcodeTypes(barcodeTypesArray)
  //     console.log(types);


  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  const handleFetchRewardData = async () => {
    try {

      const resp = await axios.post(`${config.url().app_URL}/CXPortal/v13/customRewardDetail`, { productId: id })

      const data = resp?.data?.data
      console.log(resp, "ghhhh", data);
      setRewardType(data?.rewardType)
      setMerchantName(data?.merchantName)
      setProductId(data?.productId)
      setCount(data?.totalCount)
      // setSerialNo(data.serialNo)
      setRedumptionType(data?.redemptionType)
      setRedemptionAcceptedFormat(data?.redemptionAcceptedFormat)
      setDenomination(data?.denomination)
      setCurrency(data?.currency)
      setTermsAndConditions(data?.termsAndConditions)
      setDescription(data?.description)
      setRedumptionAccepted(data?.redemptionAccepted)
      setCouponCodeType(data?.couponCodeType)

      // console.log(data.createStatus);
      if (data.createStatus === "CREATED" || data.createStatus === "UPDATED") {

        setApproveBtn(<Button
          style={{ marginRight: "5px", marginLeft: "5px" }}
          color="primary"
          onClick={() => {
            setShowRemarks(true);
            setStatus("APPROVED");
          }}
        >
          Approved
        </Button>)


        setRecheckBtn(<Button
          style={{ marginRight: "5px", marginLeft: "5px" }}
          color="danger"
          onClick={() => {
            setShowRemarks(true);
            setStatus("RECHECK");
          }}
        >
          Recheck
        </Button>)

      }
      checkUserType()

    } catch (err) {
      console.log(err);
      // snack(err)
    }


  }

  React.useEffect(() => {
    handleFetchRewardData()
    // {
    // approveStatus()
    // }
    checkUserType()

  }, [])
  // [redumptionAccepted]// );


  function openSnackBar(msg) {
    setOpenSnack(true);
    setSnackBarMsg(msg);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  }

  const closeModal = () => {
    back()
    setShowRemarks(false);
  }

  const handleUpdate = async () => {
    try {
      const data = {
        productId: id,
        description,
        termsAndConditions,
        rewardType,
        merchantName,
        denomination,
        redemptionType,
        currency,
        status: "UPDATED",


      }
      setSubmitting(true)
      const resp = await axios.post(`${config.url().baseURL}/mc-reward-inventory-create`, data)
      // console.log(resp);
      if (resp.data.err)
        snack(resp.data.msg)
      handleBack()

    } catch (err) {
      console.log(err);
      snack("Something Unexpected Happened")
    } finally {
      setSubmitting(false)
    }

  }

  const handleDownload = () => {
    // Define the data that will be written to the file
    const header = [
      ["serialNo"]
    ];

    // Create a new workbook and worksheet using the xlsx library
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(header);

    // Add the worksheet to the workbook and save it as a file
    XLSX.utils.book_append_sheet(wb, ws, "Rewards");
    XLSX.writeFile(wb, "rewards.xlsx");
  };

  const handleBack = () => {
    back()
    setCount("")
    setSerialNo("")
    setCouponCodeType("")
    setRewardType("")
    setCouponCodeType("COMMON");
    setProductId("");
    setMerchantName("");
    setDenomination("");
    //    percentage
    setCurrency("");
    setRedumptionType("");
    setRedumptionAccepted("");

    setRedemptionAcceptedFormat("");
    setDescription("");
    setTermsAndConditions("");
    // setValidFrom("");
    // setValidTo("");
    setCount("");
    setSerialNo("");
    // const [submitting, setSubmitting] = React.useState(false);
    setSheet("");
  }

  const checkUserType = () => {
    let service = user?.services?.find(service => service.name == "Reward Inventory")
    if (service) {
      switch (service.type) {
        case "maker":
          return (
            <div>
              <Button onClick={handleBack} size="md" simple color="primary">
                <ArrowBackIcon />
                Back
              </Button>
            </div>
          );
          setShowActionBtn(false);
          break;
        case "checker":
          setShowActionBtn(true)
          break;
        case "viewer":
          setShowActionBtn(false);
          break;
        default:
          setShowActionBtn(false);
          break;
      }
    }
  }


  const enableApproveStatus = () => {
    let data = {
      comments,
      status,
      productId
    }

    axios.post(`${config.url().baseURL}/mc-reward-inventory-create`, data)
      .then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          setRespAlert(
            <SweetAlert
              success
              style={{ display: "block" }}
              title={respData.msg}
              onConfirm={() => {
                setRespAlert("");
                setComments("")
                closeModal()

              }}
              confirmBtnCssClass={classes.button + " " + classes.success}
            />

          );

        } else {
          openSnackBar(respData.msg);
        }
      });
  };


  return (
    <>
      {respAlert}
      <Card>
        <CardHeader color="primary" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Rewards

        </CardHeader>
        <CardBody>
          <Dialog
            classes={{
              root: classes.left + " " + classes.modalRoot,
              paper: classes.modalMedium,
            }}
            open={showRemarks}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setShowRemarks(false);
              setComments("");

            }}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description">
            <DialogTitle id="classic-modal-slide-title" disableTypograpy className={classes.modalHeader}>
              <h4 className={classes.modalTitle}>Please enter remarks</h4>
            </DialogTitle>
            <DialogContent id="classic-modal-slide-description" className={classes.modalBodyRemarks}>
              <CustomInput
                labelText="Remarks"
                id="remarks"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: comments,
                  onChange: (e) => {
                    setComments(e.target.value);
                    // setRemarksErr(false);
                  },
                }}
              />

            </DialogContent>
            <DialogActions className={classes.modalFooterCenter}>
              <Button
                onClick={() => {
                  setShowRemarks(false);
                  setComments("");
                }}
                simple
                color="danger"
                size="md">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  enableApproveStatus()
                }}
                color="primary"
                size="md">
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          <Grid container spacing={20} xs={12}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                className="{{}}"
                required
                id="type"
                select
                label="Reward"
                onChange={(e) => {
                  setRewardType(e.target.value);
                }}
                value={rewardType}
                InputProps={{
                  readOnly: type === "View" ? true : false,
                }}
                style={{ width: 300, margin: 10 }}
              >
                <MenuItem key={"VOUCHER"} value={"VOUCHER"}>
                  Voucher
                </MenuItem>

                <MenuItem key={"GIFTCARD"} value={"GIFTCARD"}>
                  Gift Card
                </MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="merchantName"
                label="Merchant Name *"
                InputProps={{
                  readOnly: type === "View" ? true : false,
                }}
                variant="standard"
                value={merchantName}
                onChange={(e) => setMerchantName(e.target.value)}
                style={{ width: 300, margin: 10 }}
              />
            </Grid>


            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="count"
                label="Product ID *"
                InputProps={{
                  readOnly: type === "View" || true,
                }}
                variant="standard"
                value={productId}
                onChange={(e) => setProductId(e.target.value)}
                style={{ width: 300, margin: 10 }}
              />
            </Grid>


            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="type"
                select
                label="Type *"
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e) => {
                  setCouponCodeType(e.target.value);
                  setSheet("")
                }}
                value={couponCodeType}
                // helperText="Please select Type"
                style={{ width: 300, margin: 10 }}
              >
                <MenuItem key={"unique"} value={"UNIQUE"}>
                  Unique
                </MenuItem>

                <MenuItem key={"common"} value={"COMMON"}>
                  Common
                </MenuItem>
              </TextField>
            </Grid>


            {/* <Grid item xs={12} sm={6} md={4}>
            {couponCodeType === "UNIQUE" ? (
              <div style={{ marginTop: "37px" }}>
                <XlxUploadAndCheck setSheet={setSheet} sheet={sheet} count={count} data={data} setData={setData} handleCheckXlx={handleCheckXlx} />
                {sheet.name && sheet.name}
              </div>
            ) : (
              <TextField
                id="serialNo"
                label="Serial No *"
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
                value={serialNo}
                style={{ width: 300, margin: 10 }}
                onChange={(e) => setSerialNo(e.target.value)}
              />
            )}
          </Grid> */}

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="denomination"
                label="Denomination *"
                InputProps={{
                  readOnly: type === "View" ? true : false,
                }}
                variant="standard"
                value={denomination}
                onChange={(e) => setDenomination(e.target.value)}
                style={{ width: 300, margin: 10 }}
              />
            </Grid>


            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="DenominationType"
                select
                label="Denomination Type *"
                InputProps={{
                  readOnly: type === "View" ? true : false,
                }}
                onChange={(e) => {
                  setCurrency(e.target.value);
                }}
                value={currency}
                style={{ width: 300, margin: 10 }}
              >
                <MenuItem key={"BHD"} value={"BHD"}>
                  BHD
                </MenuItem>

                <MenuItem key={"percentage"} value={"PERCENT"}>
                  Percentage
                </MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="redemption"
                select
                label="Redumption Type *"
                InputProps={{
                  readOnly: type === "View" ? true : false,
                }}
                onChange={(e) => {
                  setRedumptionType(e.target.value);
                }}
                value={redemptionType}
                style={{ width: 300, margin: 10 }}
              >
                <MenuItem key={"ONLINE"} value={"ONLINE"}>
                  Online
                </MenuItem>

                <MenuItem key={"OFFLINE"} value={"OFFLINE"}>
                  Offline
                </MenuItem>

                <MenuItem key={"BOTH"} value={"BOTH"}>
                  Both
                </MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField


                id="acceptedRedumption"
                select
                label="Accepted Redumption *"
                InputProps={{
                  readOnly: type === "View" || true,
                }}
                onChange={(e) => {
                  setRedemptionAcceptedFormat("")
                  setRedumptionAccepted(e.target.value);
                  console.log(
                    e.target.value === "QRCODE" || e.target.value === "BARCODE"
                  );
                }}
                value={redumptionAccepted}
                // helperText="Please select Redumption Type"
                style={{ width: 300, margin: 10 }}
              >
                <MenuItem key={"QR"} value={"QRCODE"}>
                  QR
                </MenuItem>

                <MenuItem key={"Barcode"} value={"BARCODE"}>
                  Barcode
                </MenuItem>

                <MenuItem key={"Code"} value={"SERIALNO"}>
                  Code
                </MenuItem>

                <MenuItem key={"MERCHANTLINK"} value={"MERCHANTLINK"}>
                  Merchant Link
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {console.log(barcodeTypes)}
              {redumptionAccepted !== "SERIALNO" && redumptionAccepted !== 'MERCHANTLINK' &&
                <>

                  <TextField
                    // disabled={redumptionAccepted === "SERIALNO"}
                    id="redemptionAcceptedFormat"
                    InputProps={{
                      readOnly: type === "View" || true,
                    }}
                    label="Redemption Accepted Format *"
                    onChange={(e) => {
                      setRedemptionAcceptedFormat(e.target.value);
                    }}
                    value={redemptionAcceptedFormat}
                    // helperText="Please Enter Number Between 1-40"
                    style={{ width: 300, margin: 10 }}
                  >

                  </TextField>

                </>



              }
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4}>
            <TextField
              type="date"
              style={{ width: 300 }}
              label=" From *"
              // error={values.dobErr}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              //focused={updated && updated.dob}
              // helperText={updated && updated.dob ? "Updated" : ""}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                autoComplete: "off",
                type: "date",
                min: moment().format("YYYY-MM-DD"), // Set the minimum selectable date as today

                // readOnly: !saveBtn,
                // max: moment(new Date()).format("YYYY-MM-DD"),
                // value: moment(DOB).format("YYYY-MM-DD"),
                onChange: (e) => {
                  // setDOB(e.target.value);
                  setValidFrom(e.target.value)
                },
                onBlur: (e) => {
                  // if (moment(e.target.value).isAfter(moment(new Date())) && (new Date(e.target.value)).isValid()) {
                  //   setDOB("");
                  //   openSnackBar("Invalid DOB.")
                  // } else {
                  //   validateOldData()
                  // }
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              onChange={e => setValidTo(e.target.value)}
              type="date"
              style={{ width: 300 }}
              label="To *"
              InputLabelProps={{ shrink: true }}

              // error={values.dobErr}
              FormHelperTextProps={
                {
                  // className: classes.helperText,
                }
              }

              inputProps={{
                autoComplete: "off",
                type: "date",
                min: validFrom,
                // min: moment.max(moment().format("YYYY-MM-DD"), moment(validFrom)).format("YYYY-MM-DD"), // Set the minimum selectable date as the maximum value between today and validFrom
                onBlur: (e) => {
                },
              }}
            />
          </Grid> */}
            <Grid item xs={12} sm={12} md={4}>
              {/* <InputLabel shrink style={{ fontSize: "0.875rem", marginBottom: "18px" }}>
              Description
            </InputLabel> */}

              <InputLabel shrink>Description *</InputLabel>

              <TextareaAutosize
                style={{ width: "70%" }} primary

                minRows={8}
                label="Description"
                value={description}
                onChange={e => setDescription(e.target.value)}
                readOnly={type === "View"}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <InputLabel shrink>T&C *</InputLabel>
              <TextareaAutosize
                style={{ width: "70%" }}
                minRows={8}
                value={termsAndConditions}
                readOnly={type === "View"}
                onChange={e => setTermsAndConditions(e.target.value)}
              />
            </Grid>


          </Grid>

        </CardBody>

        <CardFooter className={classes.cardFooter}>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', padding: '10px', marginLeft: "43%" }}>
            {!submitting ? (
              <>
                {type === "Edit" && <Button
                  tranCode="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={handleUpdate}
                >
                  Update
                </Button>}

                {type === "View" && showActionBtn ? approveBtn : ""}

                {type === "View" && showActionBtn ? recheckBtn : ""}
                <Button onClick={handleBack} size="md" simple color="primary"> <ArrowBackIcon />Back </Button>
                {/* <Button color="primary">ADD</Button> */}

                {/* </div> */}
              </>
            ) : (
              <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
            )}
          </div>
        </CardFooter>
      </Card>
    </>

  );
}
