import React from 'react';
import config from "config.js"
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from "@material-ui/core/Paper";
import Button from "components/CustomButtons/Button.js";
import MaterialTable from "material-table";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import axios from "axios";
import Collapse from "@material-ui/core/Collapse";
import CustomInput from "components/CustomInput/CustomInput.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Chip from '@mui/material/Chip';
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        textAlign: "center"
    },
    ...modalStyle(theme)
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function NameScreen(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [respAlert, setRespAlert] = React.useState(null);
    const [secondViewValue, setSecondViewValue] = React.useState(0);
    const [companyData, setCompanyData] = React.useState([]);
    const [ownerData, setOwnerData] = React.useState([]);
    const [contactData, setContactData] = React.useState([]);
    const [lorData, setLorData] = React.useState([]);
    const [directorsData, setDirectorsData] = React.useState([]);
    const [declarationData, setDeclarationData] = React.useState([]);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [openSnack, setOpenSnack] = React.useState(false);

    const [watchListData, setWatchListData] = React.useState([]);
    const [aliasData, setAliasData] = React.useState([]);
    const [title, setTitle] = React.useState(null);
    var remarks = null

    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [showKeywords, setShowKeyWords] = React.useState(false);
    const [keywords, setKeyWords] = React.useState([]);

    React.useEffect(() => {
        if (props.corpID !== null) {
            getNameScreenData(props.corpID)
        }
    }, [props.corpID]);

    const handleChange = (event, newValue) => {
        setSecondViewValue(0)
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
        setSelectedUsers([])
    };

    const goBack = (step) => {
        //function to go back
        switch (step) {
            case 0:
                setAliasData([])
                setWatchListData([])
                setSecondViewValue(0)
                break;
            case 1:
                setAliasData([])
                setSecondViewValue(1)
                break;
            default:
                break;
        }
    }

    function openSnackBar(msg) {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const setWatchList = (data, title) => {
        //function to set watch list data
        setSecondViewValue(1)

        setTitle(title)
        let temData = []
        // alert(JSON.stringify(data[0].Person))
        if (data !== undefined) {
            data.map((per) => {
                //alert(JSON.stringify(per.Person.Names.Aliases.Alias))
                let data = {
                    firstName: per.Person.Names.firstName,
                    lastName: per.Person.Names.lastName,
                    dob: per.Person.AgeData.Dob,
                    fullNameScore: per.Person.Names.matchDetails ? (per.Person.Names.matchDetails.fullNameScore * 100).toFixed(2) : "",
                    partsScore: per.Person.Names.matchDetails ? (per.Person.Names.matchDetails.partsScore * 100).toFixed(2) : "",
                    score: per.Person.Names.matchDetails ? (per.Person.Names.matchDetails.score * 100).toFixed(2) : "",
                    Uid: per.Uid,
                    keywords: per.Details.Keywords.Keyword.length <= 1 ? per.Details.Keywords.Keyword[0] : <p style={{ minWidth: "200px" }}>{per.Details.Keywords.Keyword[0]} <a style={{ color: "#004fa4", fontSize: "12px" }} href="#" onClick={() => openKeyWordsModal(per.Details.Keywords.Keyword)}>View More</a> </p>,
                    country: per.Details.Countries.Country[0],
                    alias: per.Person.Names.Aliases.Alias
                }
                temData.push(data)
            })
        }
        setWatchListData(temData)
    }


    const setAlias = (data) => {
        //function to set watch list data
        //  alert(JSON.stringify(data))
        let tempData = []
        if (data !== undefined) {
            data.map((ali) => {
                let data = {
                    Name: ali.Name,
                    fullNameScore: ali.matchDetails ? (ali.matchDetails.fullNameScore * 100).toFixed(2) : "",
                    partsScore: ali.matchDetails ? (ali.matchDetails.partsScore * 100).toFixed(2) : "",
                    score: ali.matchDetails ? (ali.matchDetails.score * 100).toFixed(2) : "",
                }
                tempData.push(data)
            })
        }
        setAliasData(tempData)
        setSecondViewValue(2)
    }

    const getNameScreenData = (id) => {
        //function to fetch name screening data for a customer
        setOwnerData([]);
        setContactData([])
        setLorData([])
        setDirectorsData([])
        setSecondViewValue(0)
        setSelectedUsers([])
        let tempValue = value
        setValue(tempValue);
        axios.get(`${config.url().baseURL}/get-compliance-details/${id}`)
            .then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    setCompanyData([respData.data.NameOfCompany])
                    setOwnerData(respData.data.Owners)
                    setContactData(respData.data.Contacts)
                    setLorData(respData.data.LetterOfRepresentation)
                    setDirectorsData(respData.data.DirectorsAndAuthorisedSignatories)
                    let temDec = []
                    temDec.push(respData.data.ClientDeclaration)
                    setDeclarationData(temDec)
                } else {
                    openSnackBar("No Screening data found.")
                }
            })
            .catch((err) => {
                setOwnerData([]);
            });
    }

    const confirmWhiteList = (fname, lname, id) => {
        //function to confirm white listing
        let name = fname + " " + lname
        setRespAlert(
            <SweetAlert
                warning
                style={{ display: "block" }}
                title="Are you sure?"
                onConfirm={() => {
                    whiteListUser(name, id)
                }}
                onCancel={() => {
                    setRespAlert(null)
                }}
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                cancelBtnCssClass={classes.button}
                confirmBtnCssClass={classes.button + " " + classes.danger}
            >
                Do you really want to white list these {selectedUsers.length} users?
                <CustomInput
                    labelText="Remarks"
                    id="remarks"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        default: "",
                        onChange: (e) => {
                            handleRemarks(e.target.value)
                        },
                    }}
                />
            </SweetAlert>)
    }

    const whiteListUser = (name, id) => {
        //function to move user white listing
        if (remarks && (remarks.trim()).length !== 0) {
            setRespAlert(null);
            let data = { users: selectedUsers, type: "corporate", typeID: props.corpID, remarks: remarks.trim() }
            axios.post(`${config.url().baseURL}/name-screen-white-list`, data).then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    setRespAlert(
                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title="White listed."
                            onConfirm={() => {
                                setRespAlert(null);
                                setOwnerData([]);
                                setContactData([])
                                setLorData([])
                                setDirectorsData([])
                                getNameScreenData(props.corpID)
                                remarks = null
                            }}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                    );
                } else {
                    setRespAlert(
                        <SweetAlert
                            danger
                            style={{ display: "block" }}
                            title={respData.msg}
                            onConfirm={() => { setRespAlert(null); remarks = null }}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                    );
                }
            }).catch((err) => {
                remarks = null
                openSnackBar("Unable to process.");
            });
        } else {
            openSnackBar("Please enter remarks.");
        }

    }

    const handleRemarks = (remark) => {
        //function to set remarks 
        remarks = remark
    }

    const screeningOptions = {
        exportButton: false,
        showTitle: false,
        search: false,
        actionsColumnIndex: -1,
    };

    const watchListOptions = {
        exportButton: false,
        showTitle: false,
        actions: true,
        search: false,
        actionsColumnIndex: -1,
        selection: true
    };

    const screenColumns = [
        {
            field: "watchListName",
            title: "Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "watchListStatus",
            title: "Status",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "watchListCount",
            title: "Count",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    const screenActions = [
        {
            icon: "list_lt_icon",
            tooltip: 'View watch list',
            onClick: (event, rowData) => { setWatchList(rowData.WatchList, rowData.watchListName) },

        }
    ]

    const watchListColumns = [
        {
            field: "firstName",
            title: "FirstName",
            options: {
                width: "auto",
                filter: true,
                sort: true,
            },
        },
        {
            field: "lastName",
            title: "Last Name",
            options: {
                width: "auto",
                filter: true,
                sort: true,
            },
        },
        {
            field: "dob",
            title: "D O B",
            options: {
                width: "auto",
                filter: true,
                sort: true,
            },
        },

        {
            field: "Uid",
            title: "Uid",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            field: "country",
            title: "Country",
            options: {
                filter: false,
                sort: false,
            },
        },

        {
            field: "keywords",
            title: "Keywords",
            options: {
                filter: false,
                sort: false,
                width: "auto",
            },
        },
        {
            field: "fullNameScore",
            title: "Name Score(%)",
            options: {
                width: "auto",
                filter: true,
                sort: true,
            },
        },
        {
            field: "partsScore",
            title: "Parts Score(%)",
            options: {
                filter: true,
                sort: true,

            },
        },
        {
            field: "score",
            title: "Score(%)",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    const companyWatchListColumns = [
        {
            field: "lastName",
            title: "Company Name",
            options: {
                width: "auto",
                filter: true,
                sort: true,
            },
        },
        {
            field: "Uid",
            title: "Uid",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            field: "country",
            title: "Country",
            options: {
                filter: false,
                sort: false,
            },
        },

        {
            field: "keywords",
            title: "Keywords",
            options: {
                filter: false,
                sort: false,
                width: "auto",
            },
        },
        {
            field: "fullNameScore",
            title: "Name Score(%)",
            options: {
                width: "auto",
                filter: true,
                sort: true,
            },
        },
        {
            field: "partsScore",
            title: "Parts Score(%)",
            options: {
                filter: true,
                sort: true,

            },
        },
        {
            field: "score",
            title: "Score(%)",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    const watchListActions = [
        {
            icon: "list_lt_icon",
            tooltip: 'View Alias list',
            onClick: (event, rowData) => setAlias(rowData.alias),
            position: "row"

        },
        // {
        //     icon: "check_circle_icon",
        //     tooltip: 'White List',
        //     onClick: (event, rowData) => confirmWhiteList(rowData.firstName, rowData.lastName, rowData.Uid),
        // }
    ]

    const aliasColumns = [
        {
            field: "Name",
            title: "Name",
            options: {
                filter: true,
                sort: true,
            },
        },


        {
            field: "fullNameScore",
            title: "Name Score(%)",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "partsScore",
            title: "Parts Score(%)",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "score",
            title: "Score(%)",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];


    const selectUsers = (rows) => {
        //function to handle selection of rows in name screening table
        let tempUsers = []
        Promise.all(rows.map((row) => {
            tempUsers.push({
                name: (row.firstName ? (row.firstName + " ") : "") + (row.lastName ? row.lastName : ""),
                uid: row.Uid
            })
        }))
        setSelectedUsers(tempUsers)
    }

    const openKeyWordsModal = (keywords) => {
        //function to open keyword modal
        setShowKeyWords(true)
        setKeyWords(keywords)
    }

    const closeKeyWordsModal = () => {
        //function close keywords modal
        setShowKeyWords(false)
        setKeyWords([])
    }

    return (
        <div className={classes.root}>
            {respAlert}
            <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="Company" {...a11yProps(0)} />
                    <Tab label="Owners" {...a11yProps(1)} />
                    <Tab label="Contacts" {...a11yProps(2)} />
                    <Tab label="L O R" {...a11yProps(3)} />
                    <Tab label="Directors" {...a11yProps(4)} />
                    <Tab label="Declaration" {...a11yProps(5)} />
                </Tabs>
            </AppBar>

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={secondViewValue}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={secondViewValue} index={0} dir={theme.direction}>
                            <Collapse
                                in={companyData.length !== 0}
                                style={{ transformOrigin: "0 0 0", width: "100%" }}
                                {...(true ? { timeout: 500 } : {})}>
                                <MaterialTable key={companyData.length}
                                    style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                    components={{
                                        Container: (props) => <Paper {...props} elevation={0} />,
                                    }}
                                    data={companyData}
                                    columns={screenColumns}
                                    options={screeningOptions}
                                    actions={screenActions}

                                />
                            </Collapse>
                        </TabPanel>
                        <TabPanel value={secondViewValue} index={1} dir={theme.direction}>
                            <p style={{ marginTop: "-500 !important" }}>
                                <b style={{ fontSize: "18px", color: "#004fa4" }}> Name : {title}</b>
                            </p>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-20px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={watchListData}
                                columns={companyWatchListColumns}
                                options={watchListOptions}
                                actions={watchListActions}
                                // title={title}
                                onSelectionChange={(rows) => selectUsers(rows)}

                            />
                            <Button color="primary" size="md" simple onClick={() => { goBack(0) }}>Back</Button>
                            {selectedUsers.length !== 0 ? <Button color="primary" onClick={() => confirmWhiteList(null, null, null, true)} size="sm">White List</Button> : null}
                        </TabPanel>
                        <TabPanel value={secondViewValue} index={2} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={aliasData}
                                columns={aliasColumns}
                                options={screeningOptions}
                            />
                            <Button color="primary" size="md" onClick={() => { goBack(1) }}>Back</Button>
                        </TabPanel>
                    </SwipeableViews>
                </TabPanel>

                <TabPanel value={value} index={1} dir={theme.direction}>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={secondViewValue}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={secondViewValue} index={0} dir={theme.direction}>
                            <Collapse
                                in={ownerData.length !== 0}
                                style={{ transformOrigin: "0 0 0", width: "100%" }}
                                {...(true ? { timeout: 500 } : {})}>
                                <MaterialTable key={ownerData.length}
                                    style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                    components={{
                                        Container: (props) => <Paper {...props} elevation={0} />,
                                    }}
                                    data={ownerData}
                                    columns={screenColumns}
                                    options={screeningOptions}
                                    actions={screenActions}

                                />
                            </Collapse>
                        </TabPanel>
                        <TabPanel value={secondViewValue} index={1} dir={theme.direction}>
                            <p style={{ marginTop: "-500 !important" }}>
                                <b style={{ fontSize: "18px", color: "#004fa4" }}> Name : {title}</b>
                            </p>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-20px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={watchListData}
                                columns={watchListColumns}
                                options={watchListOptions}
                                actions={watchListActions}
                                // title={title}
                                onSelectionChange={(rows) => selectUsers(rows)}

                            />
                            <Button color="primary" size="md" simple onClick={() => { goBack(0) }}>Back</Button>
                            {selectedUsers.length !== 0 ? <Button color="primary" onClick={() => confirmWhiteList(null, null, null, true)} size="sm">White List</Button> : null}
                        </TabPanel>
                        <TabPanel value={secondViewValue} index={2} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={aliasData}
                                columns={aliasColumns}
                                options={screeningOptions}
                            />
                            <Button color="primary" size="md" onClick={() => { goBack(1) }}>Back</Button>
                        </TabPanel>
                    </SwipeableViews>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={secondViewValue}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={secondViewValue} index={0} dir={theme.direction}>
                            <Collapse
                                in={contactData.length !== 0}
                                style={{ transformOrigin: "0 0 0", width: "100%" }}
                                {...(true ? { timeout: 500 } : {})}>
                                <MaterialTable
                                    style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                    components={{
                                        Container: (props) => <Paper {...props} elevation={0} />,
                                    }}
                                    data={contactData}
                                    columns={screenColumns}
                                    options={screeningOptions}
                                    actions={screenActions}


                                />
                            </Collapse>
                        </TabPanel>
                        <TabPanel value={secondViewValue} index={1} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-20px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={watchListData}
                                columns={watchListColumns}
                                options={watchListOptions}
                                actions={watchListActions}
                                title={title}
                                onSelectionChange={(rows) => selectUsers(rows)}
                            />
                            <Button color="primary" size="md" simple onClick={() => { goBack(0) }}>Back</Button>
                            {selectedUsers.length !== 0 ? <Button color="primary" onClick={() => confirmWhiteList(null, null, null, true)} size="sm">White List</Button> : null}
                        </TabPanel>
                        <TabPanel value={secondViewValue} index={2} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={aliasData}
                                columns={aliasColumns}
                                options={screeningOptions}
                            />
                            <Button color="primary" size="md" onClick={() => { goBack(1) }}>Back</Button>
                        </TabPanel>
                    </SwipeableViews>
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={secondViewValue}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={secondViewValue} index={0} dir={theme.direction}>
                            <Collapse
                                in={lorData.length !== 0}
                                style={{ transformOrigin: "0 0 0", width: "100%" }}
                                {...(true ? { timeout: 500 } : {})}>
                                <MaterialTable
                                    style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                    components={{
                                        Container: (props) => <Paper {...props} elevation={0} />,
                                    }}
                                    data={lorData}
                                    columns={screenColumns}
                                    options={screeningOptions}
                                    actions={screenActions}
                                />
                            </Collapse>
                        </TabPanel>
                        <TabPanel value={secondViewValue} index={1} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-20px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={watchListData}
                                columns={watchListColumns}
                                options={watchListOptions}
                                actions={watchListActions}
                                title={title}
                                onSelectionChange={(rows) => selectUsers(rows)}

                            />
                            <Button color="primary" size="md" simple onClick={() => { goBack(0) }}>Back</Button>
                            {selectedUsers.length !== 0 ? <Button color="primary" onClick={() => confirmWhiteList(null, null, null, true)} size="sm">White List</Button> : null}
                        </TabPanel>
                        <TabPanel value={secondViewValue} index={2} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={aliasData}
                                columns={aliasColumns}
                                options={screeningOptions}
                            />
                            <Button color="primary" size="md" onClick={() => { goBack(1) }}>Back</Button>
                        </TabPanel>
                    </SwipeableViews>
                </TabPanel>
                <TabPanel value={value} index={4} dir={theme.direction}>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={secondViewValue}
                        onChangeIndex={handleChangeIndex}
                    >

                        <TabPanel value={secondViewValue} index={0} dir={theme.direction}>
                            <Collapse
                                in={directorsData.length !== 0}
                                style={{ transformOrigin: "0 0 0", width: "100%" }}
                                {...(true ? { timeout: 500 } : {})}>
                                <MaterialTable
                                    style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                    components={{
                                        Container: (props) => <Paper {...props} elevation={0} />,
                                    }}
                                    data={directorsData}
                                    columns={screenColumns}
                                    options={screeningOptions}
                                    actions={screenActions}
                                    onSelectionChange={(rows) => selectUsers(rows)}

                                />
                            </Collapse>
                        </TabPanel>

                        <TabPanel value={secondViewValue} index={1} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-20px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={watchListData}
                                columns={watchListColumns}
                                options={watchListOptions}
                                actions={watchListActions}
                                title={title}
                                onSelectionChange={(rows) => selectUsers(rows)}

                            />
                            <Button color="primary" size="md" simple onClick={() => { goBack(0) }}>Back</Button>
                            {selectedUsers.length !== 0 ? <Button color="primary" onClick={() => confirmWhiteList(null, null, null, true)} size="sm">White List</Button> : null}
                        </TabPanel>
                        <TabPanel value={secondViewValue} index={2} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={aliasData}
                                columns={aliasColumns}
                                options={screeningOptions}
                            />
                            <Button color="primary" size="md" onClick={() => { goBack(1) }}>Back</Button>
                        </TabPanel>
                    </SwipeableViews>
                </TabPanel>
                <TabPanel value={value} index={5} dir={theme.direction}>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={secondViewValue}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={secondViewValue} index={0} dir={theme.direction}>
                            <Collapse
                                in={declarationData.length !== 0}
                                style={{ transformOrigin: "0 0 0", width: "100%" }}
                                {...(true ? { timeout: 500 } : {})}>
                                <MaterialTable
                                    style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                    components={{
                                        Container: (props) => <Paper {...props} elevation={0} />,
                                    }}
                                    data={declarationData}
                                    columns={screenColumns}
                                    options={screeningOptions}
                                    actions={screenActions}

                                />

                            </Collapse>
                        </TabPanel>
                        <TabPanel value={secondViewValue} index={1} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-20px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={watchListData}
                                columns={watchListColumns}
                                options={watchListOptions}
                                actions={watchListActions}
                                title={title}
                                onSelectionChange={(rows) => selectUsers(rows)}

                            />
                            <Button color="primary" size="md" simple onClick={() => { goBack(0) }}>Back</Button>
                            {selectedUsers.length !== 0 ? <Button color="primary" onClick={() => confirmWhiteList(null, null, null, true)} size="sm">White List</Button> : null}
                        </TabPanel>
                        <TabPanel value={secondViewValue} index={2} dir={theme.direction}>
                            <MaterialTable
                                style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "-100px" }}
                                components={{
                                    Container: (props) => <Paper {...props} elevation={0} />,
                                }}
                                data={aliasData}
                                columns={aliasColumns}
                                options={screeningOptions}
                            />
                            <Button color="primary" size="md" onClick={() => { goBack(1) }}>Back</Button>
                        </TabPanel>
                    </SwipeableViews>
                </TabPanel>
            </SwipeableViews>

            <Dialog
                classes={{
                    root: classes.left + " " + classes.modalRoot,
                    paper: classes.modal,
                }}
                open={showKeywords}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => { closeKeyWordsModal() }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    {
                        keywords.map((keyword) => { return (<Chip label={keyword} style={{ margin: "5px" }} variant="outlined" />) })
                    }
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button onClick={() => { closeKeyWordsModal() }} color="danger" size="sm">Close</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    );
}
