import * as React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Collapse from '@material-ui/core/Collapse'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PropagateLoader from 'react-spinners/PropagateLoader'
import MUIDataTable from 'mui-datatables'
import { InputBase } from '@material-ui/core'
import moment from 'moment'
import { Paper } from '@material-ui/core'
import Snackbar from "@material-ui/core/Snackbar";
import BounceLoader from 'react-spinners/BounceLoader'
import JSONPretty from 'react-json-pretty';
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import SearchIcon from '@material-ui/icons/Search';
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import { creatCsvFile, downloadFile } from "download-csv";
import MuiAlert from "@material-ui/lab/Alert";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from '@material-ui/icons/Replay';
import config from "config.js"
import SweetAlert from "react-bootstrap-sweetalert";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from '@mui/material/Divider';
import FeeReversal from "./Componenets/FeeReversal"
import TimelineIcon from '@material-ui/icons/Timeline';
import { useSelector } from 'react-redux';
import FeeReversalLog from './Componenets/FeeReversalLog'
import Upgrade from "./Componenets/Upgrade"
import UpgradeLogs from "./Componenets/UpgradeLogs"
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';

const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    companyStatusLogModal12: {
        margin: "auto",
        minWidth: "800px",
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


export default function MultiCurrencyReport() {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [showLoader, setShowLoader] = useState(false);
    const [showMulticurrencyTable, setShowMultiCurrencyTable] = useState(true)
    const [fromDate, setFromDate] = useState(moment(new Date).format("YYYY-MM-DD"))
    const [toDate, setToDate] = useState(moment(new Date).format("YYYY-MM-DD"))
    const [openSnack, setOpenSnack] = useState(false)
    const [snackBarMsg, setSnackBarMsg] = useState("")
    const [snackBarType, setSnackBarType] = useState("error")
    const [csvData, setCsvData] = React.useState(false);
    const [respAlert, setRespAlert] = React.useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [multiCurrencyDetails, setMultiCurrencyDetails] = useState([])
    const [cardLog, setCardLog] = useState(false)
    const [replacedCardLogs, setReplacedCardLogs] = useState()
    const [m2pRequest, setM2PRequest] = useState();
    const [showFeeReversal, setShowFeeReversal] = useState(false)
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [subFee, setSubFee] = useState("")
    // const [amount,setAmount] = useState("");
    let service = user.services.find(service => service.name == "Multicurrency Card Report")
    let tbUpgradeService = user.services.find(service => service.name == "travel buddy upgrade")
    let tbFeeReversal = user.services.find(service => service.name == "TB fees reversal")

    const [txnRef,settxnRef] = React.useState("");
    const [custId,setCustId] = React.useState("")
    // console.log(custId,"custId");

    const [showLog, setShowFeeLog] = useState(false)
    const [showUpgradeLog, setShowUpgradeLog] = useState(false)
    const columns = [

        {
            name: "date",
            label: "Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "cpr",
            label: "CPR",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "walletType",
            label: "Wallet Type",
            options: {
                filter: true,
                sort: false
            }
        },

        {

            name: "addMoneyStatus",
            label: "Add Money",
            options: {
                filter: true,
                sort: true,
                helperText: "Updated",
                customBodyRender: (value, tableMeta) => {
                    console.log(tableMeta)
                    return (
                        <>
                            <label style={{ color: "black", textAlign: "right" }}>{tableMeta.rowData[4]}</label><br />
                            {tableMeta.rowData[4] == "YES" && tableMeta.rowData[5] > "0.000" && tableMeta.rowData[7] == "NO" ?
                                // (tableMeta.rowData[10] !== "CREATED" && tableMeta.rowData[10] !== "APPROVED" && tableMeta.rowData[11] == "maker" ?
                                //     <Button size="sm" color="success" onClick={() => openFeeReversal(tableMeta.rowData[9], tableMeta.rowData[5])}>
                                //         {/* Fee ReversalCREATED */}
                                //     </Button>
                                //     :
                                    (tableMeta.rowData[10] == "CREATED" && tableMeta.rowData[11] == "maker" ?
                                        <a>
                                            *Created
                                        </a>
                                        :

                                        (tableMeta.rowData[10] == "CREATED" &&
                                            tbFeeReversal?.type == 'checker' ?
                                            //  tableMeta.rowData[11] == "checker" ?
                                            <Button size="sm" color="success" onClick={() => openFeeReversal(tableMeta.rowData[9], tableMeta.rowData[5])}>
                                                Fee Reversal
                                            </Button>
                                            : ""
                                        )

                                    )
                                // )

                                : (
                                    tableMeta.rowData[4] == "YES" && tableMeta.rowData[5] > "0.000" && tableMeta.rowData[7] == "YES" && tableMeta.rowData[10] == "APPROVED" ? <a>
                                        *Approved
                                    </a> : ""

                               


)
                            }

                        </>
                    );
                }
            }
        },
        {
            name: "subFees",
            label: "Subscription fees",
            options: {
                filter: true,
                customBodyRender: (value) => <div style={{ textAlign: "center" }}>{value}</div>,
                sort: true
            }
        },
        {
            name: "productType",
            label: "Product Type",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    console.log(tableMeta.rowData);

                    return (
                        <>   <label style={{ color: "black", textAlign: "center" }}>{tableMeta.rowData[6]}</label><br />
                            {tableMeta.rowData[6] == "TravelBuddy" && tableMeta.rowData[8] == "ALLOCATED" ?

                                (tableMeta.rowData[12] != "Created" && tableMeta.rowData[12] != "Approved" && tableMeta.rowData[11] == "maker" ? <Button size="sm" color="primary" onClick={() => openUpgrade(tableMeta.rowData[1])}>
                                    Upgrade
                                </Button>
                                    :

                                    (tableMeta.rowData[12] == "Created" && tableMeta.rowData[11] == "maker" ?
                                        <a>*Created</a> :
                                        (tableMeta.rowData[12] == "Created" &&
                                            // tableMeta.rowData[11] == "checker" ?
                                            tbUpgradeService?.type == "checker" ?
                                            <Button size="sm" color="primary" onClick={() => openUpgrade(tableMeta.rowData[1])}>
                                                Upgrade
                                            </Button>
                                            : "")))

                                : null}</>
                    );
                }
            }
        },
        {
            name: "isFeeReversed",
            label: "Fee Reversal",
            options: { display: "excluded" }
        },
        {
            name: "multiCurrencyStatus",
            label: "Multicurrency Status",
            options: {
                customBodyRender: (value) => <div style={{ textAlign: "center" }}>{value}</div>,
                filter: true,
                sort: true
            }
        },
        {
            name: "userID",
            label: "User ID",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "feeReversalStatus",
            label: "Fee Reversal status",
            options: { display: "excluded" }
        },
        {
            name: "user",
            label: "userType",
            options: { display: "excluded" }
        },
        {
            name: "upgradeStatus",
            label: "Upgrade status",
            options: { display: "excluded" }
        },
        {
            name: "referredByCpr",
            label: "Referred by CPR",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "referredByCode",
            label: "Referred by Code",
            options: {
                filter: true,
                sort: true,
            }
        },

        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
            },
        }
    ]
    const options = {
        selectableRows: false,
        elevation: 0,
        print: false,
        onDownload: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = " Multi Currency Report.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        }
    };
    React.useEffect(() => {
        searchMultiCurrencyDetails();
    }, [])

    const openFeeReversal = (id, subFee) => {
        setSubFee(subFee)
        settxnRef(id)
        setShowFeeReversal(true);
    }

    const openUpgrade = (id) => {
        console.log(setCustId,"custId");
        setCustId(id)
        setShowUpgrade(true);

    }

    const validateSearch = () => {
        //function validate search params
        let snackBarMsg = ""
        let flag = true
        if (fromDate.length == 0) {
            flag = false
            snackBarMsg = "Please select from date."
        }
        if (toDate.length == 0) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select To date." : snackBarMsg);
        }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }
    const confirmRetryRegistration = (id) => {
        setRespAlert(
            <SweetAlert
                warning
                style={{ display: "block" }}
                title="Are you sure?"
                onConfirm={() => {
                    setRespAlert(null);
                    retryRegistration(id)
                }}
                onCancel={() => {
                    setRespAlert(null)
                }}
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                Do you really want to retry the registration ?
            </SweetAlert>)
    }
    const retryRegistration = (id) => {
        axios.post(`${config.url().baseURL}/retry-multicurrency-card-activation`, { cpr: id })
            .then((resp) => {
                if (!resp.data.err) {
                    setRespAlert(
                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title="Retry initiated."
                            onConfirm={() => setRespAlert(null)}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                    );
                    searchMultiCurrencyDetails()
                }
                else {
                    openSnackBar(resp.data.msg)
                }
            }).catch((e) => {
                openSnackBar("Failed to retry the registration, Please Try again later")
            })
    }

    const changeRetryStatus = (id, status) => {
        //Function to handle interested and not interested from customer choice
        axios.post(`${config.url().baseURL}/mc-update-interest`, { cpr: id, interested: !status })
            .then((resp) => {
                if (!resp.data.err) {
                    if (status == true) {
                        setRespAlert(<SweetAlert
                            success
                            style={{ display: "block" }}
                            title="Retry Option Disabled"
                            onConfirm={() => setRespAlert(null)}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                        )
                        searchMultiCurrencyDetails()
                    } else {
                        setRespAlert(<SweetAlert
                            success
                            style={{ display: "block" }}
                            title="Retry Option Enabled"
                            onConfirm={() => setRespAlert(null)}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                        )
                        searchMultiCurrencyDetails()

                    }
                }
                else {
                    openSnackBar(resp.data.msg)
                }
            }).catch((err) => {
                openSnackBar("Failed to change the status of retry option")
            })
    }
    const feeLog = (id) => {
        setShowFeeLog(true);
        settxnRef(id)
    }

    const upgradeLog = (id) => {
        setCustId(id)
        setShowUpgradeLog(true)
    }
    const searchMultiCurrencyDetails = () => {
        try {
            if (validateSearch()) {
                let data = {
                    "fromDate": moment(fromDate).format("MM/DD/YYYY"),
                    "toDate": moment(toDate).format("MM/DD/YYYY")
                }
                setShowLoader(true);
                setSubmitting(true);
                setShowMultiCurrencyTable(false);
                setMultiCurrencyDetails([])
                axios.post(`${config.url().baseURL}/GetMultiCurrencyReports`, data)
                    .then((resp) => {
                        setShowLoader(false);
                        setSubmitting(false);
                        setShowMultiCurrencyTable(true)
                        let resData = resp.data
                        if (resData.err) {
                            openSnackBar(resData.msg)
                        }
                        else {
                            let updatedDetails = []
                            console.log(updatedDetails)
                            resp.data.data.map((activations) => {

                                if (activations.multiCurrencyStatus == "NO CARD") {
                                    activations.actions =
                                        <>    <Tooltip placement="top" title="View Log">
                                            <IconButton
                                                onClick={() => {
                                                    setCardLog(true)
                                                    setReplacedCardLogs(activations.m2pResponse ? activations.m2pResponse : "No Data Found")
                                                    setM2PRequest(activations.m2pRequest ? activations.m2pRequest : "No Data Found")
                                                }}
                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                            >
                                                <AssignmentIcon />
                                            </IconButton>
                                        </Tooltip>
                                            <Tooltip placement="top" title="Fee Reversal Log">
                                                <IconButton
                                                    onClick={() => feeLog(activations.userID)
                                                    }
                                                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                    aria-label="Shufti log">
                                                    <TimelineIcon />
                                                </IconButton>
                                            </Tooltip>
                                            {service.type == "maker" ?
                                                (activations.interested === true ? <>
                                                    <Tooltip placement="top" title="Retry Register Card">
                                                        <IconButton
                                                            onClick={() => confirmRetryRegistration(activations.cpr)}
                                                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                            aria-label="log">
                                                            <ReplayIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Button size="sm" onClick={() => { changeRetryStatus(activations.cpr, activations.interested) }} color="danger"> NOT INTERESTED</Button></>
                                                    :
                                                    <Button style={{ padding: "5px", fontSize: "12px" }} onClick={() => { changeRetryStatus(activations.cpr, activations.interested) }} color="success"> INTERESTED</Button>)
                                                : ""}
                                        </>
                                } else {
                                    activations.actions = <>
                                        <Tooltip placement="top" title="View Log">
                                            <IconButton
                                                onClick={() => {
                                                    setCardLog(true)
                                                    setReplacedCardLogs(activations.m2pResponse ? activations.m2pResponse : "No Log Found")
                                                    setM2PRequest(activations.m2pRequest ? activations.m2pRequest : "No log Found")

                                                }}
                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                            >
                                                <AssignmentIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip placement="top" title="Fee Reversal Log">
                                            <IconButton
                                                onClick={() => feeLog(activations.userID)
                                                }
                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                aria-label="Shufti log">
                                                <TimelineIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip placement="top" title="Upgrade Log">
                                            <IconButton
                                                onClick={() => upgradeLog(activations.userID)
                                                }
                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                aria-label="Shufti log">
                                                <AssessmentOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                }
                                activations.upgradeStatus = activations.mcUpgradeStatus && activations.mcUpgradeStatus.status ? activations.mcUpgradeStatus.status : ""
                                activations.user = service && service.type ? service.type : ""
                                activations.feeReversalStatus = activations.feeReversalStatus && activations.feeReversalStatus.status ? activations.feeReversalStatus.status : ""
                                activations.isFeeReversed = activations.isFeeReversed ? "YES" : "NO"
                                activations.addMoneyStatus = activations.firstAddMoneyComplete ? "YES" : "NO"
                                activations.date = moment(activations.date).format("DD/MM/YYYY  hh:mm A")
                                activations.multiCurrencyStatus = activations.multiCurrencyStatus ? activations.multiCurrencyStatus : ""
                                activations.tbUpgrade = tbUpgradeService && tbUpgradeService.type ? tbUpgradeService.type : ""
                                activations.feeReversal = tbFeeReversal && tbFeeReversal.type ? tbFeeReversal.type : ""

                                updatedDetails.push(activations)
                            })
                            setMultiCurrencyDetails(updatedDetails);
                            var downloadedData = resp.data.data.map((activations) => {
                                let temp = {};
                                temp.Date = activations.date;
                                temp.CPR = "'" + activations.cpr + "'" //unable to display cprs starting with 0 in xl sheet. That's why quotes included
                                temp.Mobile = activations.mobile;
                                temp.Name = activations.name;
                                temp.User_Id = activations.userID
                                temp.Product_Type = activations.productType;
                                temp.Wallet_Type = activations.walletType;
                                temp.Kit_No = activations.kitNo;
                                temp.clevertap_ID = activations.cleverTapID
                                temp.Email_Id = activations.email;
                                temp.MultiCurrency_Status = activations.multiCurrencyStatus;
                                temp.Add_Money_Status = activations.addMoneyStatus;
                                temp.Subscription_Fees = activations.subFees;
                                temp.ReferredBY_CPR = activations.referredByCpr;
                                temp.ReferredBY_Code = activations.referredByCode
                                return temp;
                            })
                            setCsvData(downloadedData);
                        }
                    })
                    .catch((err) => {
                        openSnackBar("Unable to process")
                    })
            }
        }
        catch (error) {
            openSnackBar("Unable to fetch the details")

        }
    }
    const closeModal = () => {
        setShowFeeReversal(false);
        setShowUpgrade(false)
        searchMultiCurrencyDetails()
        setShowUpgradeLog(false)
        setShowFeeLog(false)
    }
    
    function openSnackBar(msg, success) {
        setOpenSnack(true)
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    return (
        <div>{respAlert}
            <GridContainer justify='center'>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={true}
                        style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                        {...true ? { timeout: 500 } : {}}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>From Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setFromDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setFromDate("");
                                                    openSnackBar("Please enter a valid Date")
                                                } else {
                                                    setFromDate(event.target.value)
                                                }
                                            },
                                            value: fromDate,
                                        }}>
                                    </InputBase>
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>To Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setToDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setToDate("")
                                                    openSnackBar("Please enter valid date.")
                                                } else {
                                                    setToDate(event.target.value)
                                                }
                                            }, value: toDate,
                                        }}
                                    />
                                    {!submitting ? (
                                        <Button className={classes.addDeptButton}
                                            onClick={() => searchMultiCurrencyDetails()}
                                            size="lg" color="primary">
                                            <SearchIcon fontSize="medium" />
                                        </Button>
                                    ) : (
                                        <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
                                    )}
                                </Paper>
                            </GridItem>
                        </GridContainer>
                    </Collapse>

                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{
                            transformOrigin: "0 0 0", textAlign: 'center'
                        }}
                        {...true ? { timeout: 500 } : {}}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showMulticurrencyTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...true ? { timeout: 500 } : {}}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}> MultiCurrency Status Report</h4>
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={multiCurrencyDetails} columns={columns} options={options} />
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>
            {showLog ? <FeeReversalLog id={txnRef} openSnackBar={openSnackBar} close={closeModal} /> : ""}
            <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.modal, }}
                open={showFeeReversal}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
                    <FeeReversal txnRef={txnRef} subFee={subFee} close={closeModal} userType={service.type} />
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                </DialogActions>
            </Dialog>
            {showUpgradeLog ?
                <UpgradeLogs id={custId} openSnackBar={openSnackBar} close={closeModal} /> : ""}
            <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.modal, }}
                open={showUpgrade}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
                    <Upgrade custId={custId} close={closeModal} userType={service.type} />
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                </DialogActions>
            </Dialog>
            <Dialog
                classes={{
                    root: classes.left + " " + classes.modalRoot,
                    paper: classes.companyStatusLogModal12,
                }}
                open={cardLog}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setCardLog(false);
                    setReplacedCardLogs([]);
                    setM2PRequest()
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>MultiCurrency Card Log</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    {m2pRequest || replacedCardLogs ?
                        <div style={{ width: "750px !important" }}>
                            <h5>M2P Request</h5>
                            <JSONPretty data={m2pRequest} />
                            <Divider />
                            <h5>M2P Response</h5>
                            <JSONPretty data={replacedCardLogs} />
                        </div> : <h4>No LOg Found</h4>}
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setCardLog(false);
                            setReplacedCardLogs([]);
                            setM2PRequest()
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    )
} 