import React from "react";
import axios from "axios";
import config from "config.js"
import DropZone from 'react-dropzone';
import MUIDataTable from "mui-datatables";
import PropagateLoader from "react-spinners/PropagateLoader";
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/core";
import { creatCsvFile, downloadFile } from "download-csv";
import Magnifier from "react-magnifier";
import { useSelector } from 'react-redux';
import moment from "moment";
import download from "js-file-download";
import { Document, Page } from 'react-pdf';
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Tooltip from "@material-ui/core/Tooltip";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Skeleton from '@material-ui/lab/Skeleton';
import ImagesStyles from "assets/jss/material-dashboard-react/imagesStyles.js";
import { primaryColor } from "assets/jss/material-dashboard-react.js";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import EditIcon from '@material-ui/icons/Edit';
import EditEmployeeForm from "./Components/EditEmployee.js"
import NameScreen from "../Customers/Components/NameScreen.js"
import Log from "./Components/Log.js"
import TimelineIcon from '@material-ui/icons/Timeline';
import GetAppIcon from '@material-ui/icons/GetApp';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Divider from '@mui/material/Divider';
import { FixedSizeList } from 'react-window';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import CustomInput from "components/CustomInput/CustomInput.js";


import IdUploadForm from "./Components/IdUploadForm.js"

const useStyles = makeStyles((theme) => ({
  ...ImagesStyles,
  ...SweetAlertStyle,
  root: {
    width: "100%",
    // textAlign: "center",
  },
  mainHeadArea: {
    width: "100%",
    textAlign: "center",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    float: "left",
    display: "inlineBlock",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  addButton: {
    float: "right !important",
    display: "inlineBlock",
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  backContainer: {
    width: "100%",
    textAlign: "center",
  },
  logContainer: {
    maxHeight: "500px",
    overflowY: "scroll",
  },
  corpTopHead: {
    fontSize: "15px",
    color: primaryColor[0],
    fontWeight: "400",
  },
  thumbnailContainer: {
    overflowX: "scroll",
    width: "100%",
    whiteSpace: "nowrap",
    '&::-webkit-scrollbar-track': {
      height: "8px",
      backgroundColor: "#F5F5F5"
    },
    '&::-webkit-scrollbar': {
      height: "8px",
      backgroundColor: "#F5F5F5"
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: " 6px",
      backgroundColor: primaryColor[0]
    }
  },
  thumbnail: {
    marginLeft: "4px",
    marginRight: "4px",
    width: "100px !important",
    height: "100px !important",
    display: "inlineBlock",
    whiteSpace: "normal"
  },
  detailsContainer: {
    padding: "0px",
    paddingTop: "-20px",
    marinTop: "-10px",
    maxHeight: '75vh',
    overflow: 'auto',
    '&::-webkit-scrollbar-track': {
      width: "8px",
      backgroundColor: "#F5F5F5"
    },
    '&::-webkit-scrollbar': {
      width: "8px",
      backgroundColor: "#F5F5F5"
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: " 6px",
      backgroundColor: primaryColor[0]
    }
  },
  imageName: {
    fontSize: "15px",
    color: primaryColor[0],
    fontWeight: "500",
    marginTop: "0px",
    marginBottom: "10px"
  },
  topBackButton: {
    float: "right"
  },
  ...modalStyle(theme),
}));

const override = css`
  padding: 0;
  margin: 0;
`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



export default function TableList() {
  const user = useSelector(state => state.user);
  var rejectedEmployees = []
  const [csvData, setCsvData] = React.useState([]);
  const [corporateData, setCorporateData] = React.useState([]);
  const [corporateBatchData, setCorporateBatchData] = React.useState([]);
  const [onBoardingLogs, setOnBoardingLogs] = React.useState([]);
  const [companyName, setCompanyName] = React.useState("");

  const [showLoader, setShowLoader] = React.useState(true);
  const [showCorporateTable, setShowCorporateTable] = React.useState(false);

  const [showBatchTable, setShowBatchTable] = React.useState(false);
  const [showBulkUpload, setShowBulkUpload] = React.useState(false);
  const [showAcceptedRejected, setShowAcceptedRejected] = React.useState(false);
  const [showEmployeeIdUpload, setShowEmployeeIdUpload] = React.useState(false);
  const [showBatchEmployee, setShowBatchEmployee] = React.useState(false);
  const [showBatchEmployeeKey, setShowBatchEmployeeKey] = React.useState(1);
  const [showEmployeeDetailsModal, setShowEmployeeDetailsModal] = React.useState(false);
  const [showEmployeeEdit, setShowEmployeeEdit] = React.useState(false);
  const [corpToUploadCsv, setCorpToUploadCsv] = React.useState('');
  const [acceptedList, setAcceptedList] = React.useState([]);
  const [rejectedList, setRejectedList] = React.useState([]);
  const [acceptedCount, setAcceptedCount] = React.useState(0);
  const [rejectedCount, setRejectedCount] = React.useState(0);
  const [batchEmployee, setBatchEmployee] = React.useState([]);
  const [edModal, setEdModal] = React.useState([]);
  const [empNameTableData, setEmpNameTableData] = React.useState([]);
  const [edDoc, setEdDoc] = React.useState({});
  const [approvedBatch, setApprovedBatch] = React.useState(false);
  const [enableSelect, setEnableSelect] = React.useState(false);

  const [batchEmpId, setBatchEmpId] = React.useState('');
  const [batchID, setBatchID] = React.useState('');
  const [batchStatus, setBatchStatus] = React.useState('');

  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [snackBarType, setSnackBarType] = React.useState("error");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [onBoardingLogModal, setOnBoardingLogModal] = React.useState(false);

  const [showMakerApproveBtn, setShowMakerApproveBtn] = React.useState(false);
  const [showImage, setShowImage] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [employeeData, setEmployeeData] = React.useState(null);
  const [showScreeningModel, setShowScreeningModel] = React.useState(false);
  const [screeningModel, setScreeningModel] = React.useState(null);

  const [logModal, setLogModal] = React.useState(null);
  const [showLogModal, setShowLogModal] = React.useState(false);

  const [remarks, setRemarks] = React.useState("");
  const [remarksModel, setRemarksModel] = React.useState(false);
  const [recheckRemarksModel, setRecheckRemarksModel] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [respAlert, setRespAlert] = React.useState(null);

  const [exceptionStatus, setExceptionStatus] = React.useState(false);
  const [exceptionButton, setExceptionButton] = React.useState(false);

  const [enableApprove, setEnableApprove] = React.useState(true);
  const [idUploadForm, setIdUploadForm] = React.useState(null);
  const [rejectedBatchEmp, setRejectedBatchEmp] = React.useState([]);

  React.useEffect(() => {
    checkUserType()
  }, []);


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const closeScreenModal = () => {
    //function to close name screen modal
    setShowScreeningModel(false)
    setShowBatchEmployee(true)
  }


  const openScreeningModal = (cpr, id, name, dob) => {
    //function to open Screen modal
    setShowLogModal(false)
    setShowScreeningModel(true)
    setShowBatchEmployee(false)
    if (user.riskServices !== undefined && user.riskServices !== null) {
      let riskService = user.riskServices.find(service => service.name == "Employee")
      if (riskService && riskService.type == "checkerTwo") {
        setScreeningModel(<NameScreen cpr={cpr} name={name} type="employee" whiteList={true} id={id} close={closeScreenModal} dob={dob} />)
      } else {
        setScreeningModel(<NameScreen cpr={cpr} name={name} type="employee" whiteList={false} id={id} close={closeScreenModal} dob={dob} />)
      }
    } else {
      setScreeningModel(<NameScreen cpr={cpr} name={name} type="employee" whiteList={false} id={id} close={closeScreenModal} dob={dob} />)
    }
  }
  const openLogModal = (cpr) => {
    setShowScreeningModel(false)
    setLogModal(<Log userID={cpr} />)
    setShowLogModal(true)
  }

  const checkUserType = async () => {
    //function to check what type of user is accessing the page
    if (user.admin !== undefined && user.admin == true) {
      setShowMakerApproveBtn(false)
    } else {
      if (user.services !== undefined && user.services !== null) {
        let service = user.services.find(service => service.name == "Employee")
        if (service !== undefined) {
          switch (service.type) {
            case "maker":
              setShowMakerApproveBtn(false)
              setExceptionButton(null)
              break;
            case "checker":
              setShowMakerApproveBtn(true)
              setExceptionButton(true)
              break;
            case "approver":
              setShowMakerApproveBtn(false)
              setExceptionButton(null)
              break;
            case "viewer":
              setShowMakerApproveBtn(false)
              setExceptionButton(null)
              break;

            default:
              setShowMakerApproveBtn(false)
              setExceptionButton(null)
              break;
          }
        }

      }
      if (user.riskServices !== undefined && user.riskServices !== null) {
        let riskService = user.riskServices.find(service => service.name == "Employee")
        if (riskService !== undefined) {
          switch (riskService.type) {
            case "maker":
              setShowMakerApproveBtn(false)
              setExceptionButton(null)
              break;
              // case "checker":
              // setExceptionButton(true)
              // break
            case "checkerTwo":
              setShowMakerApproveBtn(true)
              setExceptionButton(null)
              break;
            case "approver":
              setShowMakerApproveBtn(false)
              setExceptionButton(null)
              break;
            case "viewer":
              setShowMakerApproveBtn(false)
              setExceptionButton(null)
              break;
            default:
              setShowMakerApproveBtn(false)
              setExceptionButton(null)
              break;
          }
        }
      }
    }
    fetchCorporateData();
    return true
  }


  const openSnackBar = (msg, status) => {
    //function to tost snackbar message
    if (status) setSnackBarType("success")
    else setSnackBarType("error")
    setOpenSnack(true);
    setSnackBarMsg(msg);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  };

  const logColumns = [
    { id: 'date', label: 'Date', width: 70 },
    { id: 'time', label: 'Time', width: 70 },
    { id: 'user', label: 'User' },
    { id: 'status', label: 'Status', width: 70 },
    { id: 'status_message', label: 'Status Info', minWidth: 170 },
    { id: 'remarks', label: 'Remarks', minWidth: 170 },
  ];

  const columns = [

       {
      name: "companyName",
      label: "Company Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "companyID",
      label: "Company ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pending",
      label: "Pending",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "approved",
      label: "Approved",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
      },
    }
  ];

  const batchColumns = [
    // {
    //   name: 'batchNo',
    //   label: '#',
    //   option: {
    //     filter: false,
    //     sort: false,
    //   }
    // },
    {
      name: 'date',
      label: 'Created On',
      sorting: false,
      option: {
        filter: false,
      }
    },
    {
      name: 'batchId',
      label: 'Batch ID',
      option: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'NumberOfEmployees',
      label: 'Total Members',
      option: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'idUploaded',
      label: 'ID Uploaded',
      option: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'kycSuccess',
      label: 'KYC Success',
      option: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'exception',
      label: 'Exception',
      option: {
        filter: true,
        sort: true
      }
    },

    {
      name: 'Status',
      label: 'Batch Status',
      option: {
        filter: true,
        sort: true
      }
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const batchEmplColumns = [
    {
      name: 'fullName',
      label: 'Full Name',
      option: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'EmployeeCPRId',
      label: 'Employee CPR',
      option: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'MobileNumber',
      label: 'Mobile',
      option: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'fileUploaded',
      label: 'ID Uploaded',
      option: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'kycStatus',
      label: 'KYC Status',
      option: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'exception',
      label: 'Exception',
      option: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'actions',
      label: 'Actions',
      option: {
        filter: false,
        sort: false
      }
    }
  ]

  const acceptedColumns = [
    {
      name: "EmployeeCPRId",
      label: "CPR",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "EmailId",
      label: "Email",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "MobileNumber",
      label: "Mobile",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "remarks",
      label: "Remarks",
      options: {
        filter: true,
        sort: true
      }
    }
  ];

  const RejectedColumns = [
    {
      name: "EmployeeCPRId",
      label: "CPR",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "ReasonForRejection",
      label: (<> <span style={{ color: 'red' }}>Invalid Fields</span>  </>),
      options: {
        filter: true,
        sort: true
      }
    }
  ];

  let IdsToDelete = [];

  const options = {
    selectableRows: false,
    elevation: 0,
    print: false,
    sort:false,
    onDownload: () => {
      let csvFile = creatCsvFile(csvData);
      let fileName = "corporate.csv";
      downloadFile(csvFile, fileName);
      return false;
    },
    textLabels: {
      body: {
        noMatch: "No matching records found",
      },
    }
  };

  const fetchCorporateData = () => {
    axios
      .get(`${config.url().baseURL}/get-employee-companies`, {
        headers: {
          auth: sessionStorage.getItem("cx_id"),
        },
      })
      .then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          let service = user.services.find(service => service.name == "Employee")
          var updatedData = []
          respData.data.map((corp, key) => {
            corp.no = key + 1;
            if (service && service.type == "maker") {
              corp.actions = (
                <>
                  <Tooltip placement="top" title="View Batch">
                    <IconButton
                      onClick={() => handleCorpBatch(corp._id, corp.companyName)}
                      style={{ marginTop: "-10px", marginBottom: "-10px" }}
                      aria-label="delete">
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip placement="top" title="Bulk Upload">
                    <IconButton
                      onClick={() => letsBulkUpload(corp._id, corp.companyName)}
                      style={{ marginTop: "-10px", marginBottom: "-10px" }}
                      aria-label="delete">
                      <GroupAddIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip placement="top" title="Download Employee List">
                    <IconButton
                      onClick={() => downloadAllEmployees(corp._id, corp.companyName)}
                      style={{ marginTop: "-10px", marginBottom: "-10px" }}
                      aria-label="delete">
                      <GetAppIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip placement="top" title="Activation Report">
                    <IconButton
                      onClick={() => activationReport(corp._id, corp.companyName)}
                      style={{ marginTop: "-10px", marginBottom: "-10px" }}
                      aria-label="delete">
                      <SummarizeIcon />
                    </IconButton>
                  </Tooltip>
                </>
              );
            } else {
              corp.actions = (
                <>
                  <Tooltip placement="top" title="View Batch">
                    <IconButton
                      onClick={() => handleCorpBatch(corp._id, corp.companyName)}
                      style={{ marginTop: "-10px", marginBottom: "-10px" }}
                      aria-label="delete">
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip placement="top" title="Download Employee List">
                    <IconButton
                      onClick={() => downloadAllEmployees(corp._id, corp.companyName)}
                      style={{ marginTop: "-10px", marginBottom: "-10px" }}
                      aria-label="delete">
                      <GetAppIcon />
                    </IconButton>
                  </Tooltip>
                </>
              );
            }
            corp.companyID = corp.incorp_details.comm_reg_id
            updatedData.push(corp)

          });
          setCorporateData(updatedData);
          setShowLoader(false);
          setShowCorporateTable(true);
          var updatedCsvData = []
          respData.data.map((corp) => {
            let temp = {};
            temp.Company_ID = corp.companyID;
            temp.Company_Name = corp.companyName.replaceAll(/,/g, '');
            temp.Pending = corp.pending;
            temp.Approved = corp.approved;
            // temp.Contact_Person = corp.contact_details.fullName;
            // temp.Contact_Person_Title = corp.contact_details.title;
            // temp.Contact_Person_Mobile = corp.contact_details.mobile;
            // temp.Contact_Person_Email = corp.contact_details.email;

            updatedCsvData.push(temp)

          });

          setCsvData(updatedCsvData);
        } else {
          setCorporateData([]);
          setShowLoader(false);
          setShowCorporateTable(true);
          openSnackBar(respData.msg);
        }
      })
      .catch((err) => {
        // console.log(err)
        // openSnackBar("Unable to fetch Corporate data.");
      });
  };

  const fetchCorporateDataRefresh = () => {
    axios.get(`${config.url().baseURL}/get-employee-companies`, {
      headers: {
        auth: sessionStorage.getItem("cx_id"),
      },
    })
      .then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          let service = user.services.find(service => service.name == "Employee")
          var updatedData = []
          respData.data.map((corp, key) => {
            corp.no = key + 1;
                  // corp.date=moment(corp.date).format('DD/MMM/YYYY');
            if (service.type == "maker") {
              corp.actions = (
                <>
                  <Tooltip placement="top" title="View Batch">
                    <IconButton
                      onClick={() => handleCorpBatch(corp._id, corp.companyName)}
                      style={{ marginTop: "-10px", marginBottom: "-10px" }}
                      aria-label="delete">
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip placement="top" title="Bulk Upload">
                    <IconButton
                      onClick={() => letsBulkUpload(corp._id), corp.companyName}
                      style={{ marginTop: "-10px", marginBottom: "-10px" }}
                      aria-label="delete">
                      <GroupAddIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip placement="top" title="Download Employee List">
                    <IconButton
                      onClick={() => downloadAllEmployees(corp._id, corp.companyName)}
                      style={{ marginTop: "-10px", marginBottom: "-10px" }}
                      aria-label="delete">
                      <GetAppIcon />
                    </IconButton>
                  </Tooltip>
                </>
              );
            } else {
              corp.actions = (
                <>
                  <Tooltip placement="top" title="View Batch">
                    <IconButton
                      onClick={() => handleCorpBatch(corp._id, corp.companyName)}
                      style={{ marginTop: "-10px", marginBottom: "-10px" }}
                      aria-label="delete">
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </>
              );
            }
            updatedData.push(corp)
                });
          setCorporateData(updatedData);
          var updatedCsvData = []
          respData.data.map((corp) => {
            let temp = {};
            temp.Company_ID = corp._id;
            temp.Company_Name = corp.companyName.replaceAll(/,/g, '');
            // temp.Contact_Person = corp.contact_details.fullName;
            // temp.Contact_Person_Title = corp.contact_details.title;
            // temp.Contact_Person_Mobile = corp.contact_details.mobile;
            // temp.Contact_Person_Email = corp.contact_details.email;

            updatedCsvData.push(temp)

          });

          setCsvData(updatedCsvData);
        } else {
          setCorporateData([]);
          openSnackBar(respData.msg);
        }
      })
      .catch((err) => {
        // console.log(err)
        // openSnackBar("Unable to fetch Corporate data.");
      });
  };

  const fetchCorporateBatchData = (corpid) => {
    setShowLoader(true);
    axios.get(`${config.url().baseURL}/get-batches/${corpid}`, {
      headers: {
        auth: sessionStorage.getItem("cx_id"),
      }
    }).then((resp) => {
      if (!resp['data']['err']) {
        let BatchData = [];
        let service = user.services.find(service => service.name == "Employee")
        resp['data']['data'].map((batch, index) => {
          // if (batch.Status !== "APPROVED") {
          if (service && service.type == "maker" && batch.NumberOfEmployees !== 0) {
            BatchData.push({
              batchNo: BatchData.length + 1,
              batchId: batch['id'],
              date: moment(batch['date']).format("DD MMM YYYY hh:mm A"),
              idUploaded: batch['idUploaded'],
              exception: batch['exception'],
              kycSuccess: batch['kycSuccess'],
              NumberOfEmployees: batch['NumberOfEmployees'],
              actions: (
                <>
                  <Tooltip placement="top" title="Employees">
                    <IconButton
                      onClick={() => { fetchCorpBatchEmployeeData(batch['_id'], batch['Status']) }}
                      style={{ marginTop: "-10px", marginBottom: "-10px" }}
                      aria-label="delete">
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  {
                    batch['Status'] != "APPROVED" && batch['Status'] != "REJECTED" ?
                      <Tooltip placement="top" title="Upload Employee IDs">
                        <IconButton
                          onClick={() => handleAddEmployeeId(batch['_id'])}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="delete">
                          <AddAPhotoIcon />
                        </IconButton>
                      </Tooltip>
                      : null
                  }
                </>
              ),
              Status: batch['Status'] == "APPROVED" ? "APPROVED" : batch['Status']
            })
            // switch (batch['Status']) {
            //   case "CHECKED":
            //     BatchData[index].Status = (<Chip size="small" style={{ backgroundColor: "blue", color: "white", textAlign: "center", width: "90px" }} label="Checked" />)
            //     break;
            //   case "PENDING":
            //     BatchData[index].Status = (<Chip size="small" style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "90px" }} label="Pending" />)
            //     break;
            //   case "APPROVED":
            //     BatchData[index].Status = (<Chip size="small" style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "90px" }} label="Approved" />)
            //     break;
            //   case "RECHECK":
            //     BatchData[index].Status = (<Chip size="small" style={{ backgroundColor: "orange", color: "white", textAlign: "center", width: "90px" }} label="Recheck" />)
            //     break;
            //   default:
            //     break;
            // }
          } else {

            if (batch.idUploaded !== 0) {
              BatchData.push({
                batchNo: BatchData.length + 1,
                batchId: batch['id'],
                date: moment(batch['date']).format("DD/MM/YYYY"),
                idUploaded: batch['idUploaded'],
                exception: batch['exception'],
                NumberOfEmployees: batch['NumberOfEmployees'],
                kycSuccess: batch['kycSuccess'],
                actions: (
                  <>
                    <Tooltip placement="top" title="Employees">
                      <IconButton
                        onClick={() => { fetchCorpBatchEmployeeData(batch['_id'], batch['Status']) }}
                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                        aria-label="delete">
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ),
                Status: batch['Status'] == "APPROVED" ? "APPROVED" : batch['Status']
              })
            }
          }
          // }
        })
        setCorporateBatchData(BatchData);
        setShowLoader(false);
        setShowBatchTable(true);
      } else {
        setCorporateBatchData([])
        setShowBatchTable(true);
        setShowLoader(false);
      }
    }).catch((e) => {
      // console.log(e)
      openSnackBar("Unable to fetch Corporate Batch Data");
    })
  }

  const fetchCorpBatchEmployeeData = (id, status) => {
    setBatchID(id)
    setBatchStatus(status)
    let service = user.services.find(service => service.name == "Employee")
    let riskService = user.riskServices.find(service => service.name == "Employee")
    if (status == 'PENDING' && service && service.type == "checker") {
      setApprovedBatch(false);
      setEnableSelect(true)
    } else if (status == 'UPDATED' && service && service.type == "checker") {
      setApprovedBatch(false);
      setEnableSelect(true)
    } else if ((status == 'UPDATED' || status == 'PENDING') && riskService && riskService.type == "checkerTwo") {
      setApprovedBatch(false);
      setEnableSelect(true)
    }
    else {
      setApprovedBatch(true)
      setEnableSelect(false)
    }

    setBatchEmpId(id);
    setShowLoader(true);
    setShowBatchTable(false)
    axios.get(`${config.url().baseURL}/get-batch-employee/${id}`)
      .then((resp) => {
        if (!resp['data']['err']) {
          // console.log(JSON.stringify(resp['data']['data']))
          let EmpData = [];
          let service = user.services.find(service => service.name == "Employee")
          let RiskService = user.riskServices.find(service => service.name == "Employee")
          resp['data']['data'].map((i, j) => {
            if (status == "APPROVED") {
              EmpData.push({
                fullName: i.Employee.FirstName + " " + i.Employee.LastName,
                exception: i['Employee']['RiskStatus'] ?
                  (<>
                    <Chip
                      size="small"
                      style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "100px" }}
                      label="Yes"
                    />
                  </>)
                  :
                  (<>
                    <Chip
                      size="small"
                      style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "100px" }}
                      label="No"
                    />
                  </>),
                EmpNo: j + 1,
                ...i['Employee'],
                'fileUploaded': i['Employee']['fileUploaded'] ?
                  (<>
                    <Chip
                      size="small"
                      style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "100px" }}
                      label="Yes"
                    />
                  </>)
                  :
                  (<>
                    <Chip
                      size="small"
                      style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "100px" }}
                      label="No"
                    />
                  </>),
                kycStatus: i['Employee']['KYCStatus'] !== undefined && i['Employee']['KYCStatus']['ID'] !== "" ?
                  (<>
                    <Chip
                      size="small"
                      style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "100px" }}
                      label="Success"
                    />
                  </>)
                  :
                  (<>
                    <Chip
                      size="small"
                      style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "100px" }}
                      label="Failed"
                    />
                  </>),
                actions: (
                  <>
                    <Tooltip placement="top" title="Log">
                      <IconButton
                        onClick={() => { fetchOnboardingLog(i['Employee']['_id']) }}
                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                        aria-label="delete">
                        <AssignmentIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip placement="top" title="View">
                      <IconButton
                        onClick={() => { openEmployeeModal(i['Employee']) }}
                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                        aria-label="delete">
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip placement="top" title="Name Screening">
                      <IconButton
                        onClick={() => { openScreeningModal(i['Employee']['EmployeeCPRId'], i['Employee']['_id'], (i['Employee']['FirstName'] + " " + i['Employee']['MiddleName'] + " " + i['Employee']['LastName']), i['Employee']['DateOfBirth']) }}
                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                        aria-label="delete">
                        <AssignmentIndIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip placement="top" title="View Log">
                      <IconButton
                        onClick={() => { openLogModal(i['Employee']['EmployeeCPRId']) }}
                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                        aria-label="delete">
                        <TimelineIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )
              })
            } else if (service && service.type == "checker") {
              if (i['Employee']['KYCStatus'] !== undefined && i['Employee']['KYCStatus']['ID'] !== "" && i['Employee']['RiskStatus'] == false) {
                if (i['Employee']['fileUploaded']) {
                  EmpData.push({
                    fullName: i.Employee.FirstName + " " + i.Employee.LastName,
                    exception: i['Employee']['RiskStatus'] ?
                      (<>
                        <Chip
                          size="small"
                          style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "100px" }}
                          label="Yes"
                        />
                      </>)
                      :
                      (<>
                        <Chip
                          size="small"
                          style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "100px" }}
                          label="No"
                        />
                      </>),
                    EmpNo: j + 1,
                    ...i['Employee'],
                    'fileUploaded': i['Employee']['fileUploaded'] ?
                      (<>
                        <Chip
                          size="small"
                          style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "100px" }}
                          label="Yes"
                        />
                      </>)
                      :
                      (<>
                        <Chip
                          size="small"
                          style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "100px" }}
                          label="No"
                        />
                      </>),
                    kycStatus: i['Employee']['KYCStatus'] !== undefined && i['Employee']['KYCStatus']['ID'] !== "" ?
                      (<>
                        <Chip
                          size="small"
                          style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "100px" }}
                          label="Success"
                        />
                      </>)
                      :
                      (<>
                        <Chip
                          size="small"
                          style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "100px" }}
                          label="Failed"
                        />
                      </>),
                    actions: (
                      <>
                        <Tooltip placement="top" title="Log">
                          <IconButton
                            onClick={() => { fetchOnboardingLog(i['Employee']['_id']) }}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="delete">
                            <AssignmentIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title="View">
                          <IconButton
                            onClick={() => { openEmployeeModal(i['Employee']) }}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="delete">
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip placement="top" title="Name Screening">
                          <IconButton
                            onClick={() => { openScreeningModal(i['Employee']['EmployeeCPRId'], i['Employee']['_id'], (i['Employee']['FirstName'] + " " + i['Employee']['MiddleName'] + " " + i['Employee']['LastName']), i['Employee']['DateOfBirth']) }}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="delete">
                            <AssignmentIndIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title="View Log">
                          <IconButton
                            onClick={() => { openLogModal(i['Employee']['EmployeeCPRId']) }}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="delete">
                            <TimelineIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )
                  })
                }
              }
            } else if (service && service.type == "maker") {
              EmpData.push({
                fullName: i.Employee.FirstName + " " + i.Employee.LastName,
                exception: i['Employee']['RiskStatus'] ?
                  (<>
                    <Chip
                      size="small"
                      style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "100px" }}
                      label="Yes"
                    />
                  </>)
                  :
                  (<>
                    <Chip
                      size="small"
                      style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "100px" }}
                      label="No"
                    />
                  </>),
                EmpNo: j + 1,
                ...i['Employee'],
                'fileUploaded': i['Employee']['fileUploaded'] ?
                  (<>
                    <Chip
                      size="small"
                      style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "100px" }}
                      label="Yes"
                    />
                  </>)
                  :
                  (<>
                    <Chip
                      size="small"
                      style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "100px" }}
                      label="No"
                    />
                  </>),
                kycStatus: i.Employee.KYCStatus !== undefined && i.Employee.KYCStatus.ID !== "" ?
                  (<>
                    <Chip
                      size="small"
                      style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "100px" }}
                      label="Success"
                    />
                  </>)
                  :
                  (<>
                    <Chip
                      size="small"
                      style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "100px" }}
                      label="Failed"
                    />
                  </>),
                actions: (
                  <>
                    <Tooltip placement="top" title="Log">
                      <IconButton
                        onClick={() => { fetchOnboardingLog(i['Employee']['_id']) }}
                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                        aria-label="delete">
                        <AssignmentIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip placement="top" title="View">
                      <IconButton
                        onClick={() => { openEmployeeModal(i['Employee']) }}
                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                        aria-label="delete">
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    {status == "PENDING" && i['Employee']['fileUploaded'] ? (
                      <Tooltip placement="top" title="Edit">
                        <IconButton
                          onClick={() => { editEmployee(i['Employee']) }}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="delete">
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {status == "RECHECK" ? (
                      <Tooltip placement="top" title="Edit">
                        <IconButton
                          onClick={() => { editEmployee(i['Employee']) }}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="delete">
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    <Tooltip placement="top" title="View Log">
                      <IconButton
                        onClick={() => { openLogModal(i['Employee']['EmployeeCPRId']) }}
                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                        aria-label="delete">
                        <TimelineIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )
              })
            } else if (RiskService && RiskService.type == "checkerTwo") {
              if (i['Employee']['KYCStatus'] !== undefined && i['Employee']['KYCStatus']['ID'] !== "" && i['Employee']['RiskStatus'] == true) {
                if (i['Employee']['fileUploaded']) {
                  EmpData.push({
                    fullName: i.Employee.FirstName + " " + i.Employee.LastName,
                    exception: i['Employee']['RiskStatus'] ?
                      (<>
                        <Chip
                          size="small"
                          style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "100px" }}
                          label="Yes"
                        />
                      </>)
                      :
                      (<>
                        <Chip
                          size="small"
                          style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "100px" }}
                          label="No"
                        />
                      </>),
                    EmpNo: j + 1,
                    ...i['Employee'],
                    'fileUploaded': i['Employee']['fileUploaded'] ?
                      (<>
                        <Chip
                          size="small"
                          style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "100px" }}
                          label="Yes"
                        />
                      </>)
                      :
                      (<>
                        <Chip
                          size="small"
                          style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "100px" }}
                          label="No"
                        />
                      </>),
                    kycStatus: i['Employee']['KYCStatus'] !== undefined && i['Employee']['KYCStatus']['ID'] !== "" ?
                      (<>
                        <Chip
                          size="small"
                          style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "100px" }}
                          label="Success"
                        />
                      </>)
                      :
                      (<>
                        <Chip
                          size="small"
                          style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "100px" }}
                          label="Failed"
                        />
                      </>),
                    actions: (
                      <>
                        <Tooltip placement="top" title="Log">
                          <IconButton
                            onClick={() => { fetchOnboardingLog(i['Employee']['_id']) }}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="delete">
                            <AssignmentIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title="View">
                          <IconButton
                            onClick={() => { openEmployeeModal(i['Employee']) }}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="delete">
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip placement="top" title="Name Screening">
                          <IconButton
                            onClick={() => { openScreeningModal(i['Employee']['EmployeeCPRId'], i['Employee']['_id'], (i['Employee']['FirstName'] + " " + i['Employee']['MiddleName'] + " " + i['Employee']['LastName']), i['Employee']['DateOfBirth']) }}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="delete">
                            <AssignmentIndIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title="View Log">
                          <IconButton
                            onClick={() => { openLogModal(i['Employee']['EmployeeCPRId']) }}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="delete">
                            <TimelineIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )
                  })
                }
              }
            }
          });
          setBatchEmployee(EmpData);
          setShowBatchEmployee(true)
          setShowLoader(false)
        } else {
          setShowBatchEmployee(true)
          openSnackBar(resp['data']['msg']);
          setShowLoader(false)
        }
      })
  }

  const fetchOnboardingLog = (id) => {
    //function to fetch company logs
    setOnBoardingLogModal(true);
    axios.get(`${config.url().baseURL}/employee-log/${id}`).then((resp) => {
      let respData = resp.data;
      if (!respData.err) {
        //console.log(JSON.stringify(respData.data[0].log))
        if (respData.data[0] !== undefined) {
          if (respData.data[0].log !== undefined) {
            setOnBoardingLogs(respData.data[0].log);
          } else {
            setOnBoardingLogModal(false);
            openSnackBar("No log found.");
          }
        } else {
          setOnBoardingLogModal(false);
          openSnackBar("No log found.");
        }

      } else {
        setOnBoardingLogModal(false);
        openSnackBar(respData.msg);
      }
    });
  };

  const findIdsToDelete = (data) => {
    IdsToDelete = data.map((i) => batchEmployee[i['index']]['_id']);
    }

  function renderRow(props) {
    const { index, style } = props;
    return (
      <>
        <ListItem style={style} key={index} component="div" disablePadding>
          <ListItemButton>
            <ListItemText primary={rejectedEmployees[index].FirstName} secondary={rejectedEmployees[index].EmployeeCPRId} />
          </ListItemButton>
        </ListItem>
      </>
    );
  }

  const approveEmployees = () => {
    if (IdsToDelete.length > 0) {
      if (remarks.length !== 0) {
        setShowLoader(true);
        setRemarksModel(false)
        setShowBatchEmployee(false);
        if (exceptionStatus) {
          axios.post(`${config.url().baseURL}/move-employees-exception`, { IDs: IdsToDelete, remarks }).then((resp) => {
            IdsToDelete = [];
            if (!resp['data']['err']) {
              setRespAlert(
                <SweetAlert
                  success
                  style={{ display: "block" }}
                  title={resp['data']['msg']}
                  onConfirm={() => { setRespAlert(null); handleCorpBatch(corpToUploadCsv, companyName); setExceptionStatus(false) }}
                  confirmBtnCssClass={classes.button + " " + classes.success}
                />
              );
              setRemarks("")
              let temp = showBatchEmployeeKey
              setShowBatchEmployeeKey(temp + 1)
            } else {
              setRespAlert(
                <SweetAlert
                  danger
                  style={{ display: "block" }}
                  title={resp['data']['msg']}
                  onConfirm={() => { setRespAlert(null); handleCorpBatch(corpToUploadCsv, companyName); setExceptionStatus(false) }}
                  confirmBtnCssClass={classes.button + " " + classes.success}
                />
              );
              setRemarks("")
              let temp = showBatchEmployeeKey
              setShowBatchEmployeeKey(temp + 1)
            }
          })
        } else {
          axios.put(`${config.url().baseURL}/approve-employees/${corpToUploadCsv}`, {
            BatchID: `${batchEmpId}`, IDs: IdsToDelete, user: user._id, remarks
          }, {
            headers: {
              auth: sessionStorage.getItem("cx_id"),
            }
          }).then((resp) => {
            IdsToDelete = [];
            if (!resp['data']['err']) {
              rejectedEmployees = resp.data.data.rejected
              if (rejectedEmployees.length !== 0) {
                setRespAlert(
                  <SweetAlert
                    style={{ display: "block" }}
                    title={resp.data.msg}
                    onConfirm={() => { setRespAlert(null); handleCorpBatch(corpToUploadCsv, companyName) }}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                  >
                    <FixedSizeList
                      height={250}
                      itemSize={46}
                      itemCount={resp.data.data.rejected.length}
                      overscanCount={5}
                    >
                      {renderRow}
                    </FixedSizeList>
                  </SweetAlert>
                );
              } else {
                setRespAlert(
                  <SweetAlert
                    success
                    style={{ display: "block" }}
                    title={"All Approved."}
                    onConfirm={() => { setRespAlert(null); handleCorpBatch(corpToUploadCsv, companyName) }}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                  />
                );
              }
              setRemarks("")
              let temp = showBatchEmployeeKey
              setShowBatchEmployeeKey(temp + 1)
            } else {
              setShowLoader(false);
              setRemarks("")
              openSnackBar(resp['data']['err']);
              setShowCorporateTable(true)
            }
          })
        }
      } else {
        openSnackBar("Please enter remarks")
      }
    }
  }

  const checkBatch = () => {
    //function to approve 
    setShowLoader(true);
    setShowBatchEmployee(false)
    axios.post(`${config.url().baseURL}/check-batch/${batchID}`,
      {
        user: user._id,
      },
      {
        headers: {
          auth: sessionStorage.getItem("cx_id"),
        },
      }
    ).then((resp) => {
      let respData = resp.data;
      if (!respData.err) {
        setShowLoader(false);
        setShowCorporateTable(true)
      } else {
        setShowLoader(false);
        openSnackBar(resp['data']['err']);
        setShowCorporateTable(true)
      }
    })
      .catch((err) => {
        openSnackBar("Unable to process.");
      });
  }

  const checkBatchNew = (status) => {
    //function to check employees  
    if (IdsToDelete.length > 0) {
      if (remarks.length !== 0) {
        setRecheckRemarksModel(false)
        setShowLoader(true);
        setShowBatchEmployee(false);
        axios.post(`${config.url().baseURL}/check-employees/${corpToUploadCsv}`, {
          BatchID: `${batchEmpId}`, IDs: IdsToDelete, user: user._id, status: status, remarks: remarks
        }, {
          headers: {
            auth: sessionStorage.getItem("cx_id"),
          }
        }).then((resp) => {
          IdsToDelete = [];
          if (!resp['data']['err']) {
            setShowLoader(false);
            setShowCorporateTable(true)
            setRemarks("")
            let temp = showBatchEmployeeKey
            setShowBatchEmployeeKey(temp + 1)
          } else {
            setShowLoader(false);
            setRemarks("")
            openSnackBar(resp['data']['msg']);
            setShowCorporateTable(true)
          }
        })
      } else {
        openSnackBar("Please enter remarks")
      }
    }
  }

  const recheckBatch = () => {
    //function to approve 
    setShowLoader(true);
    setShowBatchEmployee(false)
    axios.post(`${config.url().baseURL}/recheck-batch/${batchID}`,
      {
        user: user._id,
      },
      {
        headers: {
          auth: sessionStorage.getItem("cx_id"),
        },
      }
    ).then((resp) => {
      let respData = resp.data;
      if (!respData.err) {
        setShowLoader(false);
        setShowCorporateTable(true)
      } else {
        setShowLoader(false);
        openSnackBar(resp['data']['err']);
        setShowCorporateTable(true)
      }
    })
      .catch((err) => {
        openSnackBar("Unable to process.");
      });
  }


  const openEmployeeModal = (id) => {
    let empid = id['_id']
    // console.log(id['Passport']['PassportExpiry']);


    let SampleOne = id['DateOfBirth'].split('/');
    let DOB = `${SampleOne[1]}/${SampleOne[0]}/${SampleOne[2]}`;

    let SampleTwo = id['Passport']['PassportExpiry'].split('/');
    let PassportExp = `${SampleTwo[1]}/${SampleTwo[0]}/${SampleTwo[2]}`;


    let SampleThree = id['CPRExpiryDate'].split('-');
    let cprExpiry = `${SampleThree[1]}/${SampleThree[0]}/${SampleThree[2]}`;


    setEmpNameTableData([
      [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >First Name </b>, ":", "", id['FirstName'], <b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Middle Name </b>, ":", id['MiddleName'], <b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Last Name </b>, ":", id['LastName']],
    ])
    setEdModal([
      // [<h6 style={{ margin: "0", fontSize: "15px", fontWeight: "500", }} >Employee Information</h6>, "", "", "", "", ""],

      ["Gender", ":", id['Gender'], "Date Of Birth", ":", moment(DOB).format("DD-MM-YYYY")],
      ["CPR", ":", id['EmployeeCPRId'], "Expiry", ":", moment(cprExpiry).format("DD-MM-YYYY") !== "Invalid date" ? moment(cprExpiry).format("DD-MM-YYYY") : ""],
      [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Address</b>, "", "", "", "", ""],
      ["Address Type", ":", id['EmployeeAddress']['AddressType'], "Flat Number", ":", id['EmployeeAddress']['FlatNumber']],
      ["Building Number", ":", id['EmployeeAddress']['BuildingNumber'], "Road Number", ":", id['EmployeeAddress']['RoadNumber']],
      ["Block Number", ":", id['EmployeeAddress']['BlockNumber'], "", "", ""],

      [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Contact</b>, "", "", "", "", ""],
      ["Mobile Number", ":", id['MobileNumber'], "Email", ":", id['EmailId']],

      [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Others</b>, "", "", "", "", ""],
      ["Place Of Birth", ":", id['PlaceOfBirth'], "Nationality", ":", id['Nationality']],
      ["Occupation", ":", id['Occupation'], "Preferred Communication Language", ":", id['PreferedCommunicationLanguage']],
      ["Passport No", ":", id['Passport']['PassportNumber'], "Expiry", ":", moment(PassportExp).format("DD-MM-YYYY") !== "Invalid date" ? moment(PassportExp).format("DD-MM-YYYY") : ""]

    ]);
    setShowEmployeeDetailsModal(true);
    setShowBatchEmployee(false)
    setEdDoc({});
    axios.get(`${config.url().baseURL}/get-employee-id/${empid}`, {}, {
      headers: {
        auth: sessionStorage.getItem("cx_id"),
      }
    })
      .then((resp) => {
        if (!resp['data']['err']) {
          let dataBody = { content: resp['data']['data']['file'] }
          setEdDoc(dataBody);
          let extension = dataBody.content.substring(dataBody.content.indexOf("/") + 1, dataBody.content.indexOf(";base64"));
          if (extension == "pdf") {
            setShowImage(false)
          } else {
            setShowImage(true)
          }
        } else {
          setEdDoc({});
        }
      }).catch((e) => {
        console.log('crashed')
      })
  }

  const letsBulkUpload = (id, company) => {
    setCompanyName(company)
    setCorpToUploadCsv(id);
    setShowCorporateTable(false);
    setShowBulkUpload(true);
  }

  const csvUpload = (payload) => {
    // console.log(payload[0]['type']);
    if (0) {
      openSnackBar('Upload a CSV')
    } else {
      setShowBulkUpload(false);
      setShowLoader(true);
      let requestBody = new FormData();
      requestBody.set('employee', payload[0]);
      axios.post(`${config.url().baseURL}/employee-bulk-upload/${corpToUploadCsv}`, requestBody, {
        headers: {
          auth: sessionStorage.getItem("cx_id"),
        }
      })
        .then((resp) => {
          if (!resp['data']['err']) {
            setShowBulkUpload(false);
            let postiveResp = [];
            resp['data']['data']['ApprovedList']['SuccessData'].map((i) => {
              postiveResp.push(i)
            });
            let negativeResp = [];
            resp['data']['data']['RejectedList']['RejectedData'].map((i) => {
              negativeResp.push({
                "EmployeeCPRId": i['EmployeeCPRId'],
                'ReasonForRejection': <Chip size="small" style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", marginRight: "4px" }} label={i['ReasonForRejection']} />
              })
            })
            if (resp['data']['data']['ApprovedList']['SuccessData'].length > 100) {
              openSnackBar("Approved List Exceed the limit, Maximum limit is 100");
              setEnableApprove(false)
            } else {
              setEnableApprove(true)
            }
            setAcceptedList(postiveResp);
            setRejectedList(negativeResp);
            let AC = resp['data']['data']['ApprovedList']['SuccessCount'];
            setAcceptedCount(AC);
            let RC = resp['data']['data']['RejectedList']['RejectedCount'];
            setRejectedCount(RC);
            setShowLoader(false);
            setShowAcceptedRejected(true);

          } else {
            openSnackBar(resp['data']['msg']);
            setShowLoader(false);
            setShowBulkUpload('true')
          }
        })
    }
  }

  const confirmEmployees = () => {
    window.scroll(0, 0);
    setShowAcceptedRejected(false);
    setShowLoader(true);
    let updatedList = acceptedList.map((elem) => {
      delete elem[`remarks`];
      return elem
    })
    axios.post(`${config.url().baseURL}/employee-confirmed/${corpToUploadCsv}`, { employeeList: [...updatedList], user: user._id }, {
      headers: {
        auth: sessionStorage.getItem("cx_id"),
      }
    }).then((resp) => {
      if (!resp.data.err) {
        setShowLoader(false);
        setShowAcceptedRejected(false);
        setShowBulkUpload(true);
      } else {
        setShowLoader(false);
        setShowBulkUpload(true);
        openSnackBar(resp['data']['msg']);
      }
    })
  };

  const employeeIdUpload = (images) => {
    setShowLoader(true);
    setShowEmployeeIdUpload(false);
    let formSent = new FormData();
    images.map((i) => {
      if (/\.(jpe?g|png|gif|pdf)$/i.test(i.name) === true && i.size < 10000000) {
        formSent.append('id', i)
      } else {
        openSnackBar("Invalid files found.")
      }
    });
    axios({
      method: 'post', url: `${config.url().baseURL}/employee-id-upload/${batchEmpId}`, data: formSent, maxContentLength: Infinity,
      maxBodyLength: Infinity
    })
      // axios.post(`${config.url().baseURL}/employee-id-upload/${batchEmpId}`, formSent, {
      //   headers: {
      //     auth: sessionStorage.getItem("cx_id"),
      //   }
      // })
      .then((resp) => {
        // console.log(resp['data'])
        if (!resp['data']['err']) {
          setShowLoader(false);
          setShowBatchTable(true)
        } else {
          setShowLoader(false);
          setShowEmployeeIdUpload(true)
          openSnackBar(resp['data']['msg']);
        }
      })
      .catch((e) => {
        // console.log(e)
      })
  }

  const handleAddEmployeeId = (id) => {
    setBatchEmpId(id);
    setShowBatchTable(false)
    setShowEmployeeIdUpload(true);
    setIdUploadForm(<IdUploadForm openSnackBar={openSnackBar} batchEmpId={id} />)
  };

  const handleCorpBatch = (corpid, company) => {
    setCompanyName(company)
    setCorpToUploadCsv(corpid);
    fetchCorporateBatchData(corpid);
    setShowCorporateTable(false);
  }

  const confirmReject = () => {
    //Function to confirm the rejection of employee.
    if (IdsToDelete.length > 0) {
      setRespAlert(
        <SweetAlert
          warning
          input style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => {
            handleRejectClick();

          }}
          onCancel={() => {
            setRespAlert(null);
          }}
          Data
          showCancel
          confirmBtnText="Yes"
          cancelBtnText="No"
          cancelBtnCssClass={classes.button}
          confirmBtnCssClass={classes.button + " " + classes.danger}
        >
          Do you really want to reject this user?
          This is REJECT, not RE-CHECK. Once rejected, KYC will not allow to revoke.
        </SweetAlert>
      )
    }
  }
  const handleRejectClick = () => {
    //function to handle rejection of selected employees
    try {
      setRespAlert(null);
      setShowLoader(true);
      setShowBatchEmployee(false);

      axios.put(`${config.url().baseURL}/reject-employees/${corpToUploadCsv}`, { BatchID: `${batchID}`, IDs: IdsToDelete })
        .then(async (resp) => {
          let responseData = resp.data;
          setShowLoader(false);
          // console.log(resp);
          if (!responseData.err) {
            setRespAlert(
              <SweetAlert
                success
                input
                style={{ display: "block" }}
                title={responseData.msg}
                onConfirm={() => {
                  setRespAlert(null)
                  fetchCorporateBatchData(corpToUploadCsv);
                }}
                onCancel={() => {
                  setRespAlert(null)
                }}
                confirmBtnText="Ok"
                confirmBtnCssClass={classes.button + " " + classes.success}
              >
              </SweetAlert>)

          } else {
            setRespAlert(null)
            setRespAlert(
              <SweetAlert
                danger
                input
                style={{ display: "block" }}
                title={responseData.msg}
                confirmBtnCssClass={classes.button + " " + classes.danger}
                onConfirm={() => {
                  setRespAlert(null)
                  fetchCorporateBatchData(corpToUploadCsv);
                }}
                confirmBtnText="Ok"
              >
              </SweetAlert>)
          }
        })
    } catch {
      openSnackBar("Unable to process.")
    }
  }

  const handleBackClick = () => {
    //function to handle back button click.
    // setShowCorporateTable(true);
    fetchCorporateDataRefresh()
    setIdUploadForm(null)
    if (showBatchTable) {
      setShowBatchTable(false);
      fetchCorporateData();
    } else if (showBulkUpload) {
      setShowBulkUpload(false);
      fetchCorporateData();
    } else if (showEmployeeIdUpload) {
      setShowEmployeeIdUpload(false);
      // fetchCorporateData();
      setShowCorporateTable(false);
      setShowBatchTable(true);
      setShowAcceptedRejected(false);
      setShowBatchEmployee(false);
      setShowEmployeeDetailsModal(false);
      fetchCorporateBatchData(corpToUploadCsv);

    } else if (showAcceptedRejected) {
      setShowAcceptedRejected(false);
      setShowBulkUpload(true);
    } else if (showBatchEmployee) {
      setShowBatchEmployee(false);
      setShowBatchTable(true);
    } else if (showEmployeeDetailsModal) {
      setShowEmployeeDetailsModal(false);
      setShowBatchEmployee(true);
    } else if (showEmployeeEdit) {
      setShowEmployeeEdit(false)
      setShowBatchEmployee(true);
    }
  };

  const successEmployeeEdit = () => {
    setShowEmployeeEdit(false)
    setShowBatchEmployee(true);
    fetchCorpBatchEmployeeData(batchID, batchStatus)
  }

  const editEmployee = (id) => {
    //function to handle employee edit button click
    setShowBatchEmployee(false)
    setShowEmployeeEdit(true);
    setEmployeeData(id)


  }

  const downloadAllEmployees = (id, name) => {
    //function to download all customer csv 
    try {
      axios.post(`${config.url().baseURL}/employee-infos/${id}`, {
        responseType: "blob",
      })
        .then(async (resp) => {
          download(resp.data, `${name}-Employees.csv`);
        })
        .catch((err) => {
          // console.log(err);
          openSnackBar("Unable tp process.");
        });
    } catch (error) {
      openSnackBar("Unable to process.")
    }
  }
  const activationReport = (id, name) => {
    //function to download all customer activation report
    try {
      axios.post(`${config.url().baseURL}/downloadMobileActivatedUserList/${id}`, {
        responseType: "blob",
      })
        .then(async (resp) => {
          download(resp.data, `${name}-Employees.csv`);
        })
        .catch((err) => {
          // console.log(err);
          openSnackBar("Unable tp process.");
        });
    } catch (error) {
      openSnackBar("Unable to process.")
    }
  }

  const moveToException = () => {
    //function to open remarks model and move to exception
    setRemarksModel(true)
    setRemarks("")
    setExceptionStatus(true)
    
  }

  const downloadSampleDoc = () => {
    //function to download sample doc file to upload
    try {
      axios.get(`${config.url().baseURL}/emp-bulk-add-csv`, {
        responseType: "blob",
      })
        .then(async (resp) => {
          download(resp.data, `Sample.xlsx`);
        })
        .catch((err) => {
          // console.log(err);
          openSnackBar("Unable download sample doc.");
        });
    } catch (error) {
      openSnackBar("Unable download sample doc.")
    }
  }


  const classes = useStyles();
  return (
    <div>
      {respAlert}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showLoader}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
          </Collapse>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showCorporateTable}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Companies</h4>
              </CardHeader>
              <CardBody>
                <MUIDataTable data={corporateData} columns={columns} options={options} />
              </CardBody>
            </Card>
          </Collapse>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showBatchTable}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}><b>{companyName}</b> - Batches</h4>
              </CardHeader>
              <CardBody>
                <MUIDataTable data={corporateBatchData} columns={batchColumns} options={options} />

              </CardBody>
            </Card>
            <Button
              onClick={() => { handleBackClick() }} size="lg" className={classes.backButton} simple color="primary">
              <ArrowBackIcon />
              Back
            </Button>
          </Collapse>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showBatchEmployee}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}><b>{companyName}</b> - Employees</h4>
              </CardHeader>
              <CardBody>
                <MUIDataTable key={showBatchEmployeeKey} data={batchEmployee} columns={batchEmplColumns} options={{
                  selectableRows: !enableSelect ? "none" : "multiple",
                  elevation: 0,
                  // rowsSelected: tempID,
                  // onDownload: () => {
                  //   let csvFile = creatCsvFile(csvData);
                  //   let fileName = "EmployeeList.csv";
                  //   downloadFile(csvFile, fileName);
                  //   return false;
                  // },
                  download: false,
                  print: false,
                  onTableChange: (data1, data2) => {
                    findIdsToDelete(data2['selectedRows']['data']);
                  }
                }} />
              </CardBody>
            </Card>
            <Button
              onClick={() => { handleBackClick() }} size="md" className={classes.backButton} simple color="primary">
              <ArrowBackIcon />
              Back
            </Button>
            {!approvedBatch && batchEmployee.length !== 0 ? (
              <>
                <Button type="reset"
                  onClick={() => {
                    if (IdsToDelete.length > 0) {
                      setRecheckRemarksModel(true)
                    }
                  }} size="md" className={classes.backButton} simple color="danger">
                  Recheck
                </Button>
                {exceptionButton && <Button type="reset"
                  onClick={() => {
                    if (IdsToDelete.length > 0) {
                      moveToException()
                    }
                  }} size="md" className={classes.backButton} simple color="warning">
                  Exception
                </Button>
                }
                <Button type="reset"
                  onClick={() => {
                    if (IdsToDelete.length > 0) {
                      setRemarksModel(true)
                    }
                  }} size="md" className={classes.backButton} simple color="primary">
                  Approve
                </Button>
                <Button type="reset"
                  onClick={() => confirmReject()} size="md" className={classes.backButton} simple color="danger">
                  Reject
                </Button>
              </>
            ) : null
            }
            {/* {showMakerApproveBtn ? (
              <>
                <Button
                  onClick={() => checkBatchNew("RECHECK")}
                  size="md" className={classes.backButton} color="danger">
                  Recheck
                </Button>
                <Button
                  onClick={() => checkBatchNew("CHECKED")}
                  size="md" className={classes.backButton} color="primary">
                  Approve
                </Button>
              </>
            ) : null} */}
          </Collapse>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showBulkUpload}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite} style={{ marginTop: "10px" }}><b>{companyName}</b> - Bulk User Upload</h4>
                <Button onClick={() => downloadSampleDoc()} color="secondary" size="sm" className={classes.addButton}>
                  Download Sample
                </Button>
              </CardHeader>
              <CardBody>
                <h4>Upload Excel</h4>
                <DropZone onDrop={csvUpload} >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button className={classes.uploadButton} size="sm" color="primary">
                        <strong>Upload</strong>
                      </Button>
                    </div>
                  )}
                </DropZone>
              </CardBody>
            </Card>
            <Button
              onClick={() => { handleBackClick() }} size="lg" className={classes.backButton} simple color="primary">
              <ArrowBackIcon />
              Back
            </Button>
          </Collapse>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showAcceptedRejected}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 1000 } : {})}>
            {showAcceptedRejected && <h4>Out of {acceptedCount + rejectedCount}, {acceptedCount} Accepted & {rejectedCount} Rejected </h4>}
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Accepted</h4>
              </CardHeader>
              <CardBody>
                <MUIDataTable data={acceptedList} columns={acceptedColumns} options={options} />
              </CardBody>
            </Card>

          </Collapse>
          <Collapse
            in={showAcceptedRejected}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>

            <Card>
              <CardHeader color="danger">
                <h4 className={classes.cardTitleWhite}>Rejected</h4>
              </CardHeader>
              <CardBody>
                <MUIDataTable data={rejectedList} columns={RejectedColumns} options={options} />
              </CardBody>
            </Card>
            <Button
              onClick={() => { handleBackClick() }} size="lg" className={classes.backButton} simple color="primary">
              <ArrowBackIcon />
              Back
            </Button>
            {enableApprove ? (
              <Button
                onClick={() => { confirmEmployees() }} size="lg" className={classes.backButton} simple color="primary">
                Confirm
              </Button>
            ) : null}
          </Collapse>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showEmployeeIdUpload}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Upload Employee ID</h4>
              </CardHeader>
              <CardBody>
                {idUploadForm}
              </CardBody>
            </Card>
            <Button
              onClick={() => { handleBackClick() }} size="lg" className={classes.backButton} simple color="primary">
              <ArrowBackIcon />
              Back
            </Button>
          </Collapse>
        </GridItem>

      </GridContainer>

      <Collapse in={showEmployeeDetailsModal} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 500 } : {})}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <h6 className={classes.corpTopHead}>Employee Details</h6>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <h6 className={classes.corpTopHead}>Company Name : {companyName}</h6>
              </GridItem>
            </GridContainer>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Button onClick={() => { handleBackClick() }} size="lg" className={classes.topBackButton} simple color="primary">
              <ArrowBackIcon /> Back
            </Button>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <CardBody className={classes.detailsContainer}>
                <Table tableData={empNameTableData} />
                <div tyle={{ marginTop: "-55px" }}>
                  <Table s tableData={edModal} />
                </div>

              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            {edDoc['content'] != undefined ? (
              <>
                <h6 className={classes.imageName} >ID File</h6>

                {showImage ? (
                  <>
                    <Magnifier src={edDoc['content']} width="100%" />
                  </>
                ) : (<>
                  <div style={{ textAlign: "center" }} >
                    <Document
                      file={edDoc['content']}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                    <p>Page {pageNumber} of {numPages}</p>
                    <Button size="sm" color="primary" onClick={() => {
                      if (pageNumber < 2) {
                        setPageNumber(1)
                      } else {
                        let newPageNumber = pageNumber - 1
                        setPageNumber(newPageNumber)
                      }
                    }} >Pervious</Button>
                    <Button size="sm" color="primary"
                      onClick={() => {
                        if (numPages > pageNumber) {
                          let newPageNumber = pageNumber + 1
                          setPageNumber(newPageNumber)
                        } else {
                          setPageNumber(numPages)
                        }
                      }}
                    >Next</Button>
                  </div>
                </>)}

                {/* <h6 className={classes.imageName} >ID File</h6>
                <Magnifier src={edDoc['content']} width="100%" />
                <div className={classes.thumbnailContainer} >
                </div> */}
              </>
            ) : (
              <>
                <Skeleton variant="text" width={500} height={500} />
              </>
            )}
          </GridItem>
        </GridContainer>
      </Collapse>
      <Collapse in={showEmployeeEdit} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 500 } : {})}>
        <EditEmployeeForm data={employeeData} back={handleBackClick} success={successEmployeeEdit} />
      </Collapse>
      <Collapse in={showScreeningModel} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 500 } : {})}>
        <Card>
          <CardBody>
            {screeningModel}
          </CardBody>
        </Card>

      </Collapse>

      <Dialog
        classes={{
          root: classes.left + " " + classes.modalRoot,
          paper: classes.companyStatusLogModal,
        }}
        open={onBoardingLogModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOnBoardingLogModal(false);
          setOnBoardingLogs([]);
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
          <h4 className={classes.modalTitle}>Employee Status Log</h4>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <TableContainer className={classes.container}>
            <MuiTable stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {logColumns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {onBoardingLogs.map((row, key) => {
                  let status = ""
                  if (row.status == "Step One Completed") {
                    status = "First Level Form Completed"
                  } else if (row.status == "Step Two completed") {
                    status = "Second Level Form Completed"
                  } else {
                    status = row.status
                  }
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                      <TableCell>
                        {moment(row.date).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>
                        {moment(row.date).format("HH:MM:SS")}
                      </TableCell>
                      <TableCell>
                        {row.user ? row.user.firstName + " " + row.user.lastName : null}
                      </TableCell>
                      <TableCell>
                        {status}
                      </TableCell>
                      <TableCell>
                        {row.status_message}
                      </TableCell>
                      <TableCell>
                        {row.remarks}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </MuiTable>
          </TableContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
          <Button
            onClick={() => {
              setOnBoardingLogModal(false);
              setOnBoardingLogs([]);
            }}
            color="danger"
            size="sm">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.transactionsModal, }}
        open={showScreeningModel}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setShowScreeningModel(false);
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
          {screeningModel}
        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
        </DialogActions>
      </Dialog> */}

      <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.transactionsModal, }}
        open={showLogModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setShowLogModal(false);
          setLogModal(null)
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
          {logModal}
        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
          <Button
            onClick={() => { setShowLogModal(false); setLogModal(null) }}
            color="danger"
            size="sm">
            Close
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        classes={{
          root: classes.left + " " + classes.modalRoot,
          paper: classes.modalMedium,
        }}
        open={recheckRemarksModel}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setRecheckRemarksModel(false);
          setRemarks("");
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
          <h4 className={classes.modalTitle}>Please enter remarks</h4>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classes.modalBodyRemarks}>
          <CustomInput
            labelText="Remarks"
            id="remarks"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: remarks,
              onChange: (e) => {
                setRemarks(e.target.value);
              },
            }}
          />

        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
          <Button
            onClick={() => {
              setRecheckRemarksModel(false);
              setRemarks("");
            }}
            simple
            color="danger"
            size="md">
            Cancel
          </Button>
          <Button
            onClick={() => {
              checkBatchNew('RECHECK')
            }}
            color="primary"
            size="md">
            {!submitting ? (
              "Save"
            ) : (
              <PulseLoader color="#fff" loading={true} css={override} size={5} margin={2} />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        classes={{
          root: classes.left + " " + classes.modalRoot,
          paper: classes.modalMedium,
        }}
        open={remarksModel}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setRemarksModel(false);
          setRemarks("");
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
          <h4 className={classes.modalTitle}>Please enter remarks</h4>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classes.modalBodyRemarks}>
          <CustomInput
            labelText="Remarks"
            id="remarks"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: remarks,
              onChange: (e) => {
                setRemarks(e.target.value);
              },
            }}
          />

        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
          <Button
            onClick={() => {
              setRemarksModel(false);
              setRemarks("");
            }}
            simple
            color="danger"
            size="md">
            Cancel
          </Button>
          <Button
            onClick={() => { approveEmployees() }}
            color="primary"
            size="md">
            {!submitting ? (
              "Save"
            ) : (
              <PulseLoader color="#fff" loading={true} css={override} size={5} margin={2} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
            setSnackBarMsg("");
          }}
          severity={snackBarType}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
