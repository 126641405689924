import * as React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Collapse from '@material-ui/core/Collapse'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PropagateLoader from 'react-spinners/PropagateLoader'
import MUIDataTable from 'mui-datatables'
import { InputBase } from '@material-ui/core'
import moment from 'moment'
import { Paper } from '@material-ui/core'
import Snackbar from "@material-ui/core/Snackbar";
import BounceLoader from 'react-spinners/BounceLoader'

import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import SearchIcon from '@material-ui/icons/Search';
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import { creatCsvFile, downloadFile } from "download-csv";
import MuiAlert from "@material-ui/lab/Alert";
import config from "config.js"
import Chip from "@material-ui/core/Chip";


const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    companyStatusLogModal12: {
        margin: "auto",
        minWidth: "800px",
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


export default function RewardReport() {
    const classes = useStyles();
    const [showLoader, setShowLoader] = useState(false);
    const [showPTPTbale, setShowPTPTable] = useState(true)
    const [from, setFrom] = useState(moment(new Date).format("YYYY-MM-DD"))
    const [to, setTo] = useState(moment(new Date).format("YYYY-MM-DD"))
    const [openSnack, setOpenSnack] = useState(false)
    const [snackBarMsg, setSnackBarMsg] = useState("")
    const [snackBarType, setSnackBarType] = useState("error")
    const [csvData, setCsvData] = React.useState(false);
    const [respAlert, setRespAlert] = React.useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [ptpDetails, setPTPDetails] = useState([])
   
    const columns = [
        {
            name: "date",
            label: "Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "senderCpr",
            label: "Sender CPR",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "receiverCpr",
            label: "Receiver CPR",
            options: {
                filter: true,
                sort: true
            }
        },
       
        {
            name: "senderDebitedAmount",
            label: "Amount",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "transactionID",
            label: "Txn Reference Number",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "walletType",
            label: "Wallet Type",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true
            }

        },    
    ]
    const options = {
        selectableRows: false,
        elevation: 0,
        print: false,
        onDownload: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = "PTP TB report.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        }
    };
    React.useEffect(() => {
        searchP2PDetails();
    }, [])

    const validateSearch = () => {
        //function validate search params
        let snackBarMsg = ""
        let flag = true
        if (from.length == 0) {
            flag = false
            snackBarMsg = "Please select from date."
        }
        if (to.length == 0) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select To date." : snackBarMsg);
        }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }

    const searchP2PDetails = () => {
        try {
            if (validateSearch()) {
                let data = {
                    "from": moment(from).format("MM/DD/YYYY"),
                    "to": moment(to).format("MM/DD/YYYY"),  
                }
                setShowLoader(true);
                setSubmitting(true);
                setShowPTPTable(false);
                setPTPDetails([])
                axios.post(`${config.url().transactionMSURL}/mc/v3/cxportal/p2pTransactionReport`, data)
                    .then((resp) => {
                        setShowLoader(false);
                        setSubmitting(false);
                        setShowPTPTable(true)
                        let resData = resp.data
                        if (resData.err) {
                            openSnackBar(resData.msg)
                        }
                        else {
                            let updatedDetails = [];
                            resData.data.map((activations) => {
                              activations.date = activations.date
                                ? moment(activations.date).format("DD/MM/YYYY hh:mm A")
                                : "";
                              activations.senderCpr = activations.senderCpr ? activations.senderCpr : "";
                              activations.receiverCpr = activations.receiverCpr ? activations.receiverCpr : "";
                              activations.senderDebitedAmount = activations.senderDebitedAmount ? activations.senderDebitedAmount : "";
                              activations.transactionID = activations.transactionID
                                ? activations.transactionID
                                : "";
                              activations.walletType = activations.walletType ? activations .walletType : "";
                              activations.status = activations.status ? "True" : "False";
                          
                              if (activations.status) {
                                activations.status = (
                                  <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />
                                );
                              } else {
                                activations.status = (
                                  <Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />
                                );
                              }
                          
                              updatedDetails.push(activations);
                            });
                            setPTPDetails(updatedDetails);
                          
                            var downloadedData = resData.data.map((activations) => {
                              let temp = {};
                              temp.Date = activations.date;
                              temp.SenderCPR = activations.senderCpr;
                              temp.ReceiverCPR = activations.receiverCpr;
                              temp.Amount = activations.senderDebitedAmount;
                              temp.TxnReference_Number = activations.transactionID;
                              temp.WalletType = activations.walletType ;
                            //   temp.Status = activations ?.status; 
                          
                              return temp;
                            });
                            setCsvData(downloadedData);
                          }
                          
                     })
                    .catch((err) => {
                        openSnackBar("Unable to process")
                    })
            }
        }
        catch (error) {
            openSnackBar("Unable to fetch the details")

        }
    }

    
    function openSnackBar(msg, success) {
        setOpenSnack(true)
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    return (
        <div>{respAlert}
            <GridContainer justify='center'>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={true}
                        style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                        {...true ? { timeout: 500 } : {}}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>From Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setFrom(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setFrom("");
                                                    openSnackBar("Please enter a valid Date")
                                                } else {
                                                    setFrom(event.target.value)
                                                }
                                            },
                                            value: from,
                                        }}>
                                    </InputBase>
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>To Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setTo(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setTo("")
                                                    openSnackBar("Please enter valid date.")
                                                } else {
                                                    setTo(event.target.value)
                                                }
                                            }, value: to,
                                        }}
                                    />
                                    {!submitting ? (
                                        <Button className={classes.addDeptButton}
                                            onClick={() => searchP2PDetails()}
                                            size="lg" color="primary">
                                            <SearchIcon fontSize="medium" />
                                        </Button>
                                    ) : (
                                        <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
                                    )}
                                </Paper>
                            </GridItem>
                        </GridContainer>
                    </Collapse>

                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{
                            transformOrigin: "0 0 0", textAlign: 'center'
                        }}
                        {...true ? { timeout: 500 } : {}}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showPTPTbale}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...true ? { timeout: 500 } : {}}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>PTP TB report</h4>
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={ptpDetails} columns={columns} options={options} />
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    )
} 