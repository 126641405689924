import React from "react";
import AddReward from "./AddReward";
import ViewRewardForm from "./ViewRewardForm"
import CouponTable from "./CouponTable";
export default function MainForm({ type, back, snack, alertPop,id }) {
    let componentToRender;

    switch (type) {
        case "Add":
            console.log("Adding");
            componentToRender = <AddReward back={back} alertPop={alertPop} snack={snack} type={type}/>;
            break;
        
        case "View":
            componentToRender = <ViewRewardForm back={back} alertPop={alertPop} snack={snack}  id={id} type="View"/>;
            break;

            case "CouponTable":
                componentToRender = <CouponTable back={back} alertPop={alertPop} snack={snack}  id={id} type="View"/>;
                break;

        default:
            componentToRender = <ViewRewardForm   type="Edit" back={back} alertPop={alertPop} snack={snack}  id={id}/>;
            break;
    }

    return (
        <div>
            {componentToRender}
        </div>
    );
}
