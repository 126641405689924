import React from 'react';
import './NoDataFound.css';
import { ErrorOutline } from '@mui/icons-material'; // Import the Material-UI icon

const NoDataFound = ({message}) => {
  return (
    <div className="no-data">
      <ErrorOutline className="no-data-icon" />
      <p>No data found.</p>
      <p>{message}</p>
    </div>
  );
};

export default NoDataFound;
