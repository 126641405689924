import * as React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Collapse from '@material-ui/core/Collapse'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PropagateLoader from 'react-spinners/PropagateLoader'
import MUIDataTable from 'mui-datatables'
import { InputBase } from '@material-ui/core'
import moment from 'moment'
import { Paper } from '@material-ui/core'
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import SimpleButton from '@mui/lab/LoadingButton';
import download from "js-file-download";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import SearchIcon from '@material-ui/icons/Search';
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import { creatCsvFile, downloadFile } from "download-csv";
import MuiAlert from "@material-ui/lab/Alert";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from '@material-ui/icons/Replay';
import config from "config.js"
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SweetAlert from "react-bootstrap-sweetalert";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DownloadIcon from '@mui/icons-material/Download';
import { createTheme } from '@mui/material/styles';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;
const theme = createTheme({

    primary: {
        light: '#757ce8',
        main: '#3f50b5',
        dark: '#002884',
        contrastText: '#fff',
    },

})
const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    companyStatusLogModal12: {
        margin: "auto",
        minWidth: "800px",
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
export default function MultiCurrencyReport() {
    const classes = useStyles();
    const [showLoader, setShowLoader] = useState(false);
    const [showStatementTable, setShowStatementTable] = useState(true)
    const [fromDate, setFromDate] = useState(moment(new Date).format("YYYY-MM-DD"))
    const [toDate, setToDate] = useState(moment(new Date).format("YYYY-MM-DD"))
    const [openSnack, setOpenSnack] = useState(false)
    const [snackBarMsg, setSnackBarMsg] = useState("")
    const [snackBarType, setSnackBarType] = useState("error")
    const [csvData, setCsvData] = React.useState(false);
    const [respAlert, setRespAlert] = React.useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [statementDetails, setStatementDetails] = useState([])
    const [CR, setCR] = useState("")
    const [searchKey, setSearchKey] = useState("CORPORATE")
    const [listDetails, setListDetails] = useState([])

    const columns = [
        {
            name: "createdAt",
            label: "Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "CR",
            label: "CR/CPR",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "corporate",
            label: "Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "fromto",
            label: "Date Range",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "requestedBy",
            label: "Requested By",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Request Status",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
            },
        }
    ]
    const options = {
        selectableRows: false,
        elevation: 0,
        print: false,
        onDownload: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = " Statement Request Details.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        }
    };
    React.useEffect(() => {
        listRewardsDetails()
    }, [])


    const downloadReport = (path, name) => {
        try {
            axios({
                url: path,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                const filename = `${name}.pdf`;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            });
        }
        catch (error) {
            openSnackBar("Unable to download the report")
        }
    }

    const validateSearch = () => {
        //function validate search params
        let snackBarMsg = ""
        let flag = true
        if (fromDate.length == 0) {
            flag = false
            snackBarMsg = "Please select from date."
        }
        let test = moment(toDate).isAfter(moment(maxDate))
        console.log(test)
        if (toDate.length == 0) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select the correct end date." : snackBarMsg);
        }
        if (moment(toDate).isAfter(moment(maxDate))) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select the correct end date." : snackBarMsg);
        }
        if (CR == "" || CR.length >= 15) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please enter the CR/CPR" : snackBarMsg)

        }
        if (searchKey.length == 0) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please Select the Type" : snackBarMsg)
        }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }
    const listRewardsDetails = () => {
        setShowLoader(true)
        axios.get(`${config.url().baseURL}/list-statements`).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                setShowLoader(false)
                let updatedDetails = []
                // let statementType ;
                // console.log (searchKey)
                respData.data.map((details) => {
                    if (details.status == "INITIATED"|| details.status == "IN_PROGRESS") {
                        details.actions = <>
                            <>    <Tooltip placement="top" title="Refresh">
                                <IconButton
                                    onClick={() => {
                                        listRewardsDetails()
                                    }}
                                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                >
                                    < PublishedWithChangesIcon />
                                </IconButton>
                            </Tooltip>
                            </>
                        </>
                    } else if (details.status == "COMPLETED" && details.statementType == "EMPLOYEE" ) {   
                        details.actions =
                            <>    <Tooltip placement="top" title="Download">
                                <IconButton
                                    onClick={() => {
                                        downloadReport(details.filePath, details.CR)
                                    }}
                                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                >
                                    < DownloadIcon color="secondary" />
                                </IconButton>
                            </Tooltip>
                        

                            </>
                     }else if (details.status == "COMPLETED" && details.statementType== "CORPORATE") {
                        
                     details.actions =
                         <>    <Tooltip placement="top" title="Download">
                             <IconButton
                                 onClick={() => {
                                     downloadReport(details.filePath, details.corporate)
                                 }}
                                 style={{ marginTop: "-10px", marginBottom: "-10px" }}
                             >
                                 < DownloadIcon color="secondary" />
                             </IconButton>
                         </Tooltip>
                         </>
                  }
                    details.requestedBy = details.requestedBy && (details.requestedBy.firstName || details.requestedBy.lastName) ? details.requestedBy.firstName + details.requestedBy.lastName : ""
                    details.corporate = details.corporate && details.corporate.companyName ? details.corporate.companyName : ""
                    details.createdAt = details.createdAt ? moment(details.createdAt).format("DD/MM/YYYY") : ""
                    details.from = details.startDate ? moment(details.startDate).format("DD/MM/YYYY") : ""
                    details.to = details.endDate ? moment(details.endDate).format("DD/MM/YYYY") : ""
                    details.fromto = details.from && details.to ? `${details.from} to ${details.to}` : ""
                    updatedDetails.push(details)
                })
                setListDetails(updatedDetails)
                var downloadedData = respData.data.map((details) => {
                    let temp = {};
                    temp.Date = details.createdAt ? details.createdAt : "";
                    temp.CR = "'" + details.CR + "'" //unable to display cprs starting with 0 in xl sheet. That's why quotes included
                    temp.Name = details.corporate ? details.corporate : ""
                    temp.Date_Range = details.fromto ? details.fromto : ""
                    temp.Status = details.status ? details.status : "";
                    temp.Requested_By = details.requestedBy ? details.requestedBy : "";
                    return temp;
                })
                setCsvData(downloadedData);

                // resetSearch()
            }
            else {
                setShowLoader(false)
                setListDetails([]);
                openSnackBar(respData.msg);
            }

        })
            .catch((err) => {
                setShowLoader(false)
                setListDetails([]);
                openSnackBar("Unable to process");

            });


    }
    const searchStatementsDetails = () => {
        try {
            if (validateSearch()) {

                setShowLoader(true);
                setSubmitting(true);
                setShowStatementTable(false);
                setStatementDetails([])
                axios.post(`${config.url().baseURL}/search-statement`, { "searchString": CR })
                    .then((resp) => {
                        setShowLoader(false);
                        setSubmitting(false);
                        setShowStatementTable(true)
                        let respData = resp.data
                        if (!respData.err) {
                            setShowLoader(false)
                            let updatedDetails = []
                            respData.data.map((details) => {
                                console.log(details.status)
                                if (details.status == "INITIATED" || details.status == "IN_PROGRESS") {
                                    details.actions = <>
                                        <>    <Tooltip placement="top" title="Refresh">
                                            <IconButton
                                                onClick={() => {
                                                    listRewardsDetails()
                                                }}
                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                            >
                                                < PublishedWithChangesIcon />
                                            </IconButton>
                                        </Tooltip>
                                        </>
                                    </>
                                } else if (details.status == "COMPLETED") {
                                    details.actions =
                                        <>    <Tooltip placement="top" title="Download">
                                            <IconButton
                                                onClick={() => {
                                                    downloadReport(details.filePath, details.corporate)
                                                }}
                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                            >
                                                < DownloadIcon color="secondary" />
                                            </IconButton>
                                        </Tooltip>
                                        </>
                                }
                                details.requestedBy = details.requestedBy && (details.requestedBy.firstName || details.requestedBy.lastName) ? details.requestedBy.firstName + details.requestedBy.lastName : ""
                                details.corporate = details.corporate && details.corporate.companyName ? details.corporate.companyName : ""
                                details.createdAt = details.createdAt ? moment(details.createdAt).format("DD/MM/YYYY") : ""
                                details.from = details.startDate ? moment(details.startDate).format("DD/MM/YYYY") : ""
                                details.to = details.endDate ? moment(details.endDate).format("DD/MM/YYYY") : ""
                                details.fromto = details.from && details.to ? `${details.from} to ${details.to}` : ""
                                updatedDetails.push(details)
                            })
                            setListDetails(updatedDetails)
                            setCR("")
                            const fromdate = getMaxDate(moment(new Date()).format('YYYY-MM-DD'), "CORPORATE")
                            setFromDate(moment(fromdate).format('YYYY-MM-DD'))
                            setToDate(moment(new Date()).format('YYYY-MM-DD'))
                            setSearchKey("CORPORATE")
                            // resetSearch()
                        }
                        var downloadedData = respData.data.map((details) => {
                            let temp = {};
                            temp.Date = details.createdAt ? details.createdAt : "";
                            temp.CPR = "'" + details.CR + "'" //unable to display cprs starting with 0 in xl sheet. That's why quotes included
                            details.corporate = details.corporate ? details.corporate : ""
                            temp.Date_Range = details.fromto ? details.fromto : ""
                            temp.Status = details.status ? details.status : "";
                            temp.Requested_By = details.requestedBy ? details.requestedBy : ""

                            return temp;
                        })
                        setCsvData(downloadedData);

                    })
                    .catch((err) => {
                        openSnackBar("Unable to process")
                        setCR("")
                        const fromdate = getMaxDate(moment(new Date()).format('YYYY-MM-DD'), "CORPORATE")
                        setFromDate(moment(fromdate).format('YYYY-MM-DD'))
                        setToDate(moment(new Date()).format('YYYY-MM-DD'))
                        setSearchKey("CORPORATE")
                    })
            }
        }
        catch (error) {
            openSnackBar("Unable to fetch the details")

        }
    }
    function openSnackBar(msg, success) {
        setOpenSnack(true)
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }
    const getMaxDate = (date, searchItem) => {
        if (!date) {
            return date
        }

        if (searchItem == "CORPORATE") {
            let sixMonth;
            if (moment(date).add(6, 'month').isAfter(moment(new Date()))) {
                sixMonth = moment(new Date()).format('YYYY-MM-DD')
            }
            else {

                sixMonth = moment(date).add(6, 'month').format('YYYY-MM-DD')
            }
            return sixMonth;
        }
        else {
            let sixMonth;
            if (moment(date).add(6, 'month').isAfter(moment(new Date()))) {
                sixMonth = moment(new Date()).format('YYYY-MM-DD')
            }
            else {

                sixMonth = moment(date).add(6, 'month').format('YYYY-MM-DD') 
            }
            return sixMonth;

        }
    }
    const maxDate = React.useMemo(() => getMaxDate(fromDate, searchKey))
    const resetSearch = () => {
        setCR("")
        const fromdate = getMaxDate(moment(new Date()).format('YYYY-MM-DD'), "CORPORATE")
        setFromDate(moment(fromdate).format('YYYY-MM-DD'))
        setToDate(moment(new Date()).format('YYYY-MM-DD'))
        setSearchKey("CORPORATE")
        listRewardsDetails()
    }

    const generateStatementReport = () => {
        try {
            if (validateSearch()) {
                setShowLoader(true)
                let dataReport = {
                    "fromDate": fromDate,
                    "toDate": toDate,
                    "accountNumber": CR,
                    "type": searchKey
                }
                axios.post(`${config.url().baseURL}/generate-statement`, dataReport).then((resp) => {
                    let respData = resp.data;
                    if (!respData.err) {
                        openSnackBar("You will be notified once the statement is ready", "success")
                        resetSearch()

                    } else {
                        setShowLoader(false)
                        openSnackBar(respData.msg);
                    }

                })
                    .catch((err) => {
                        setShowLoader(false)
                        openSnackBar("Unable to process");

                    });
            }

        } catch (error) {
            openSnackBar("Unable to generate the statement")
        }
    }
    return (
        <div>{respAlert}
            <GridContainer justify='center'>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={true}
                        style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                        {...true ? { timeout: 500 } : {}}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={4} md={3}>
                                <p className={classes.searchHead}>CR/CPR</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={false}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            value: CR,
                                            onChange: (event) => {
                                                const value = event.target.value
                                                const re = /^[0-9-]{1,15}$/;
                                                if ((value === "" || re.test(value))) {
                                                    setCR(event.target.value)
                                                }
                                            },
                                        }}>
                                    </InputBase>
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>From Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setFromDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setFromDate("")
                                                    openSnackBar("Please enter valid date ,future date is not acceptable.")
                                                } else {
                                                    setFromDate(event.target.value)
                                                }
                                            },
                                            value: fromDate,
                                        }}>
                                    </InputBase>
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3}>
                                <p className={classes.searchHead}>To Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            min: fromDate, // Minimum date is the selected "From Date"
                                            max: maxDate,
                                            onChange: (event) => { setToDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setToDate("")
                                                    openSnackBar("Please enter valid date ,future date is not acceptable.")
                                                } else {
                                                    setToDate(event.target.value)
                                                }
                                            }, value: toDate,
                                        }}
                                    />
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} sm={4} md={3}>
                                <p className={classes.searchHead}>Type</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <FormControl className={classes.groupSelect} fullWidth>
                                        <Select
                                            id="group-select"
                                            value={searchKey}
                                            disableUnderline
                                            onChange={(e) => {
                                                setSearchKey(e.target.value);
                                            }}>
                                            <MenuItem value="CORPORATE">Corporate</MenuItem>
                                            <MenuItem value="EMPLOYEE">Employee </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Paper>
                            </GridItem>
                            <GridContainer spacing={1}>
                                <GridItem >
                                    <Button className={classes.addDeptButton} size="sm" color="primary" onClick={resetSearch} variant="contained">Reset</Button>
                                </GridItem>
                                <GridItem >
                                    <Button className={classes.addDeptButton} size="sm" color="primary" onClick={generateStatementReport} variant="contained">Generate Report</Button>
                                </GridItem>
                                <GridItem> <Button size="sm" className={classes.addDeptButton} color="primary" onClick={searchStatementsDetails} loading={submitting} variant="contained">Search</Button>
                                </GridItem>
                            </GridContainer>
                        </GridContainer>
                    </Collapse>

                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{
                            transformOrigin: "0 0 0", textAlign: 'center'
                        }}
                        {...true ? { timeout: 500 } : {}}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showStatementTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...true ? { timeout: 500 } : {}}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}> Statement Request Report</h4>
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={listDetails} columns={columns} options={options} />
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    )
} 