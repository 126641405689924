import React from "react";
import { useHistory } from "react-router-dom";
import config from "config.js"
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Box, Typography } from '@material-ui/core';
import OTPInput from "otp-input-react";
// import NoOtpLogin from "./NoOtpLogin";
// import OTPInput from "./"
import styles from "assets/jss/material-dashboard-react/views/loginPage.js";

const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AdminOTPVerify(props) {
  const history = useHistory();
  const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [snackBarType, setSnackBarType] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [showTextboxOtp, setShowTextboxOtp] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(true);
  const [showLoginNoOtp, setShowLoginNoOtp] = React.useState("");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const override = css`
display: block;
margin: 32px auto;
border-color: red;
`;

  React.useEffect(() => {
    checkUserSignedIn()
    //alert(JSON.stringify(config.url()config.url().baseURL))
  }, []);
  const [counter, setCounter] = React.useState(59);
  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const checkUserSignedIn = () => {
    //function to check user is already signed in
    if (sessionStorage.getItem('cx_id') !== undefined && sessionStorage.getItem("cx_id") !== null) {
      history.push("/dashboard");
    }
  }

  const setSession = async (token, refreshToken, time, data) => {
    sessionStorage.setItem("cx_id", token);
    sessionStorage.setItem("cx_refresh_id", refreshToken);
    sessionStorage.setItem("cx_id_time", time);
    sessionStorage.setItem("cx_data", JSON.stringify(data))
    
  }
  const sendOTP = async () => {
    let validForm = validate();
    setShowTextboxOtp(false);
    if (validForm) {
      if (username !== "" || password !== "") {
        if (!submitting) {
          let userData = { username: username, password: password };
          axios.post(`${config.url().baseURL}/sign-in`, userData).then(async (resp) => {
            let respData = resp.data;
            if (!respData.err) {
              if (respData.otpLoginEnabled) {
                setShowTextboxOtp(true);
                openSnackBar(respData.msg);
                setSubmitting(false);
              } else {
                if (respData.token !== undefined) {
                  await setSession(respData.token.AuthToken, respData.token.RefreshToken, respData.token.Time, respData.data)
                  setTimeout(function () {
                    setSubmitting(false)
                    if (respData.pswdStatus == "0") {
                      history.push("/set-password");
                    } else {
                      history.push("/dashboard");
                    }
                  }, 500);
                }
              }
            }
            else {
              setShowLogin(true);
              setShowTextboxOtp(false);
              openSnackBar(respData.msg);
              setSubmitting(false);
            }
          }).catch((err) => {
            setShowTextboxOtp(false);
            openSnackBar("Internal Server Error");
            setSubmitting(false);
          })
        }
      }
    }
    else {
      openSnackBar("Invalid Credentials");
      setShowTextboxOtp(false);
      setSubmitting(false);
    }
  }
  const signOn = async () => {
    try {
          //function to get user signed in
    setSubmitting(true);
    let validForm = validate();
    if (validForm) {
      if (username !== "" || password !== "") {
        if (!submitting) {
          let userData = { username: username, password: password, otp };

          let resp = await axios.post(`${config.url().baseURL}/AdminUserSignInOtpVerify`, userData)
          let respData = resp.data;
          if (!respData.err) {
            if (respData.token !== undefined) {
              sessionStorage.setItem("cx_id", respData.token.AuthToken);
              sessionStorage.setItem("cx_refresh_id", respData.token.RefreshToken);
              sessionStorage.setItem("cx_id_time", respData.token.Time);
              sessionStorage.setItem("cx_data", JSON.stringify(respData.data))
              setSubmitting(false);
              setSubmitting(false);
              history.push("/dashboard");
            } else {
              openSnackBar(respData.msg);
              setSubmitting(false);
            }
          } else {
            openSnackBar(respData.msg);
            setSubmitting(false);
          }

        }
      } else {
        openSnackBar("Invalid credentials");
        setSubmitting(false);
      }
    }
    } catch (error) {
      openSnackBar(error.message);
      setSubmitting(false);
    }

  };


  function openSnackBar(msg) {
    //function to tost snackbar message
    setOpenSnack(true);
    if (msg == "2FA Login Activated. Proceed to OTP Page") {
      setSnackBarType("success")
    } else if (msg == "Sign In Successfull") {
      setSnackBarType("success")
    }
    else {
      setSnackBarType("error")

    }
    setSnackBarMsg(msg);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg(msg);
    }, 1300);
  }
  const validate = () => {
    //function to validate user credentials
    if (username === "") {
      openSnackBar("Username field cannot be left blank");
      setSubmitting(false);
      return false;
    } else if (password === "") {
      openSnackBar("Password field cannot be left blank");
      setSubmitting(false);
      return false;
    }
    else {
      return true;
    }
  };
  const validateOTP = () => {
    if (otp == "") {
      openSnackBar("OTP field cannot be left blank");
      setSubmitting(false);
      return false;
    }
    else if (otp.length !== 6) {
      openSnackBar("Please enter the OTP sent to your registered mail");
      setSubmitting(false);
      return false;
    }
    else {
      return true;
    }
  };

  return (
    <div>
      <div
        className={classes.pageHeader}
        style={{
          backgroundColor: "#001e42",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}>
        <div className={classes.container}>
          <GridContainer justify="center">
            {(!showTextboxOtp && showLogin) ?
              <GridItem xs={12} sm={12} md={3}>
                <Card className={classes[cardAnimation]}>
                  <form className={classes.form}>
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4 className={classes.cardHead}>Welcome to BFC C X Portal</h4>
                    </CardHeader>
                    <CardBody>
                      <>
                        <CustomInput
                          labelText="Username"
                          id="username"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) => {
                              setUsername(e.target.value);
                            },
                            value: username,
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircleIcon className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <CustomInput
                          labelText="Password"
                          id="pass"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) => {
                              setPassword(e.target.value);
                            },
                            value: password,
                            type: "password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputIconsColor}>lock_outline</Icon>
                              </InputAdornment>
                            ),
                            onKeyDown: (e) => {
                              if (e.key == "Enter") {
                                setShowTextboxOtp(true);
                                sendOTP();
                                // setSubmitting(true);
                              }
                            },
                            autoComplete: "off",
                          }}
                        />
                      </>
                    </CardBody>
                    <p className={classes.divider}>

                      Forgot password ?{" "}
                      <span onClick={() => history.push("/forgot-password")} className={classes.createLink}>
                        Reset Here.
                      </span>
                    </p>
                    <p className={classes.divider}>
                      <span onClick={() => history.push("/login")} className={classes.createLink}>
                        Switch to Normal
                      </span>
                    </p>
                    <CardFooter className={classes.cardFooter}>


                      {!submitting ? (
                        <Button color="primary" onClick={() => {
                          // setShowTextboxOtp(true);
                          sendOTP();
                          // setSubmitting(true);
                          setCounter(59);
                        }
                        } size="md">
                          Login
                        </Button>) :
                        (
                          <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                        )
                      }
                    </CardFooter>
                  </form>
                </Card>
              </GridItem> :

              <GridItem xs={12} sm={9} md={4}>
                <Card className={classes[cardAnimation]}>
                  <form className={classes.form}>
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4 className={classes.cardHead}>Welcome to BFC C X Portal</h4></CardHeader>
                    <CardBody>
                      <OTPInput value={otp} onChange={setOtp} autoFocus OTPLength={6} otpType="number" disabled={false} secure
                        inputStyles={{
                          width: "100%",
                          // height: "200%",
                          margin: ".8rem",
                          fontSize: "1rem",
                          borderRadius: 3,
                          border: "1px solid rgba(0,0,0,0.7)"

                        }}
                      />

                    </CardBody>
                    {(!counter == 0) ? <p className={classes.divider}>
                      <Box mt={3} ><Typography fontWeight={500} align="center" color='textSecondary'> Resend OTP in <span style={{ color: "green", fontWeight: "bold" }}> 00:{counter}</span> </Typography></Box></p> :
                      <p className={classes.divider}><span className={classes.createLink} onClick={() => { setSubmitting(true); setOtp(); sendOTP(); setCounter(59) }}>Resend OTP {""}</span></p>}
                    <CardFooter className={classes.cardFooter}>
                      {!submitting ? (<>
                        <Button color="primary" size="mdz" onClick={() => { setShowTextboxOtp(false); setShowLogin(true); setOtp(""); setCounter(59); }}> Back</Button>
                        <Button color="primary" onClick={() => { signOn() }} size="md">Verify OTP</Button></>
                      )
                        : (<PropagateLoader color="#004fa4" loading={true} css={override} size={10} />)}
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            }
          </GridContainer>
        </div>
      </div>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={8000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
            setSnackBarMsg("");
          }}
          severity={snackBarType}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
