import React from "react";
import config from "config.js"
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import Button from "components/CustomButtons/Button.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Table from "components/Table/Table.js";
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import { useRef } from "react";

const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
  
`;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        textAlign: "center"
    },
    sideHead: {
        fontWeight: "500",
        fontSize: "14px",
        marginBottom: "0px !important",
        marginTop: "25px !important",
        color: "#474747",
    },

}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function FeeEditForm(props) {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [tableData, setTableData] = React.useState([]);
    const [dialogueButtons, setDialogueButtons] = React.useState("");
    const [respAlert, setRespAlert] = React.useState(null);
    const [type, setType] = React.useState("cardFee")
    const reference = useRef("")
    var remarks = ""
    React.useEffect(() => {
        if (props.corpID !== null) {
            fetchCardFeeDetails(props.corpID)
            setType(props.type)
        }
    }, [props.corpID]);
    const setRemarksValue = (value) => {
        remarks = value
    }
// console.log(props);
    const fetchCardFeeDetails = (id) => {
        //function to fetch company details
        axios.post(`${config.url().baseURL}/get-card-fee-details/${id}`, { "type": type}).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                let service = user.services.find(service => service.name == "Corporate Fee Edit")
                if (service.type == "maker") {
                    setDialogueButtons(<Button onClick={() => { closeModal() }} color="danger" size="sm"> Close </Button>)
                } else if (service.type == "checker" && props.cardStatus == "Updated" || props.cardStatus == "Created" ) {
                    reference.current = (respData.data.length != 0) ? respData.data[0].feeReference : ""
                    setTableData([
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Card Fee</b>, ":", (respData.data.length != 0) ? 8: ""],
                    ])
                    setDialogueButtons(
                        <>
                            <TextField label="Remarks" fullWidth onChange={(e) => setRemarksValue(e.target.value)} />
                            <Button onClick={() => { changeStatus("Recheck") }} color="danger" size="sm">Recheck</Button>
                            <Button color="primary" onClick={() => { changeStatus("Approved") }} size="sm">Approve</Button>

                        </>
                    )

                } else if(service.type == "checker" &&  props.tabibStatus == "Created" || props.tabibStatus == "Updated"){
                    reference.current = (respData.data.length != 0) ? respData.data[0].feeReference : ""
                    setTableData([
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Tabiib Fee</b>, ":", (respData.data.length != 0) ? 8 : ""],
                    ])
                    setDialogueButtons(
                        <>
                            <TextField label="Remarks" fullWidth onChange={(e) => setRemarksValue(e.target.value)} />
                            <Button onClick={() => { changeStatus("Recheck") }} color="danger" size="sm">Recheck</Button>
                            <Button color="primary" onClick={() => { changeStatus("Approved") }} size="sm">Approve</Button>

                        </>
                    ) 
                }
                else if (service.type == "approver" && props.cardStatus == "Updated" || props.cardStatus == "Created" ) {
                    reference.current = (respData.data.length != 0) ? respData.data[0].feeReference : ""
                    setTableData([
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Card Fee</b>, ":", (respData.data.length != 0) ? 8 : ""],
                    ])
                    setDialogueButtons(
                        <div>
                            <TextField label="Remarks" fullWidth onChange={(e) => setRemarksValue(e.target.value)} />
                            <Button onClick={() => { changeStatus("Recheck") }} color="danger" size="sm">Recheck</Button>
                            <Button color="primary" onClick={() => { changeStatus("Approved") }} size="sm">Approve</Button>
                            <Button onClick={() => { closeModal() }} color="danger" size="sm"> Close </Button>
                        </div>
                    )

                } else if(service.type == "approver" &&  props.tabibStatus == "Created" || props.tabibStatus == "Update"){
                    reference.current = (respData.data.length != 0) ? respData.data[0].feeReference : ""
                    setTableData([
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Tabiib Fee</b>, ":", (respData.data.length != 0) ? 9 : ""],
                    ])
                    setDialogueButtons(
                        <>
                            <TextField label="Remarks" fullWidth onChange={(e) => setRemarksValue(e.target.value)} />
                            <Button onClick={() => { changeStatus("Recheck") }} color="danger" size="sm">Recheck</Button>
                            <Button color="primary" onClick={() => { changeStatus("Approved") }} size="sm">Approve</Button>
                            <Button onClick={() => { closeModal() }} color="danger" size="sm"> Close </Button>

                        </>
                    ) 
                }
                else {
                     setDialogueButtons(<Button onClick={() => { closeModal() }} color="danger" size="sm"> Close </Button>)
                }
                // if (type == "cardFee" && props.cardStatus!="Approved") {
                //     reference.current = (respData.data.length != 0) ? respData.data[0].feeReference : ""
                //     setTableData([
                //         [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Card Fee</b>, ":", (respData.data.length != 0) ? respData.data[0].feeAmount : ""],
                //     ])
                // } else {
                //     reference.current = (respData.data.length != 0) ? respData.data[0].feeReference : ""
                //     setTableData([
                //         [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Tabiib Fee</b>, ":", (respData.data.length != 0) ? respData.data[0].feeAmount : ""],
                //     ])
                // }

            } else {
                openSnackBar(respData.msg);
                closeModal()
            }
        }).catch((err) => {
           console.log(err)
            closeModal()
            openSnackBar("Unable to process.");
        });

    };

    function openSnackBar(msg) {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const closeModal = () => {
        //function to close modal
        props.close()
        setRemarksValue("")

    }

    const changeStatus = (status) => {
        //function to change status of fee
        if (remarks.length !== 0) {
            let data1 = {
                "status": status,
                "remarks": remarks,
                "type": type,
                "feeReference": reference.current
            }
            axios.post(`${config.url().baseURL}/approve-card-fees/${props.corpID}`, data1).then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    setRespAlert(
                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title="Fee Approved"
                            onConfirm={() => {
                                // setSubmitting(false)
                                setRespAlert(null)
                                closeModal()
                            }}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                    );
                    remarks = ""
                    closeModal()
                } else {
                    remarks = ""
                    openSnackBar(respData.msg);
                }
            }).catch((err) => {
                remarks = ""
                openSnackBar("Unable to process.");
            });
        } else {
            openSnackBar("Please enter remarks.")
        }
    }

    return (
        <div className={classes.root}>
            <Collapse
                in={tableData.length == 0}
                style={{ transformOrigin: "0 0 0", textAlign: "center", height: "100px" }}
                {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
            </Collapse>
            <Collapse
                in={tableData.length !== 0}
                style={{ transformOrigin: "0 0 0", textAlign: "center", paddingLeft: "15px", paddingRight: "15px" }}
                {...(true ? { timeout: 500 } : {})}>

                <Table tableData={tableData} />
                <br />
                {dialogueButtons}
            </Collapse>
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div >
    );
}
