import * as React from 'react';
import axios from "axios";
import MUIDataTable from "mui-datatables";
import Collapse from "@material-ui/core/Collapse";
import config from "config.js"
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import Tooltip from "@material-ui/core/Tooltip";
import PreviewIcon from '@mui/icons-material/Preview';
import IconButton from "@material-ui/core/IconButton";
import moment from 'moment';
import { useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { creatCsvFile, downloadFile } from "download-csv";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Chip from "@material-ui/core/Chip";
import BounceLoader from "react-spinners/BounceLoader";
import SearchIcon from '@material-ui/icons/Search';
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Table from "components/Table/Table.js";


const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
export default function CardsActivationsReport() {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [showActivationTable, setShowActivationTable] = React.useState(false)
    const [showLoader, setShowLoader] = React.useState(false)
    const [snackBarType, setSnackBarType] = React.useState("error");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [respAlert, setRespAlert] = React.useState(null);

    const [fromDate, setFromDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
    const [toDate, setToDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
    const [activationDetails, setActivationDetails] = React.useState([]);

    const [showDetails, setShowDetails] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [csvData, setCsvData] = React.useState(false);
    const [familyDetails, setFamilyDetails] = React.useState([]);
    const [searchKey, setSearchKey] = React.useState("B2B");
    const [expanded, setExpanded] = React.useState();

    const columns = [
        // {
        //     name: "cardID",
        //     label: "Card ID",
        //     options: {
        //         filter: true,
        //         sort: true,
        //     },
        // },
        {
            name: "cardNo",
            label: "Card Number",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "cpr",
            label: "CPR",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "cardType",
            label: "Card Type",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "cardRefNo",
            label: "Card Reference No",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "identifier",
            label: "Identifier",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "updatedAt",
            label: "Activation Time",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "walletType",
            label: "Wallet Type",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "familyCount",
            label: "No of FamilyMembers",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "parentCorporateName",
            label: "Corporate Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
            },
        }
    ];

    const options = {
        selectableRows: false,
        elevation: 0,
        print: false,
        onDownload: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = "Tabiib Card Activation Report.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        }
    };
    React.useEffect(() => {
        searchActivationsDetails();
    }, []);

    const validateSearch = () => {
        //function validate search params
        let snackBarMsg = ""
        let flag = true
        if (fromDate.length == 0) {
            flag = false
            snackBarMsg = "Please select from date."
        }
        if (toDate.length == 0) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select To date." : snackBarMsg);
        }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }
    function openSnackBar(msg, success) {
        //function to tost snackbar message
        setOpenSnack(true);
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }
    const searchActivationsDetails = () => {
        //function to fetch transactions 
        try {
            //checkUserType();
            if (validateSearch()) {
                let data = {
                    "type": searchKey,
                    "startDate": moment(fromDate).format("DD-MMM-YYYY"),
                    "endDate": moment(toDate).format("DD-MMM-YYYY")
                }
                // setShowLoader(true);
                setSubmitting(true);
                setShowActivationTable(false);
                setActivationDetails([]);
                axios.post(`${config.url().baseURL}/GetTabibCardActivationReport`, data)
                    .then((resp) => {
                        setSubmitting(false);
                        setShowLoader(false);
                        setShowActivationTable(true)
                        let resData = resp.data;
                        if (resData.err) {
                            openSnackBar(resp.data.msg)
                        }
                        else {
                            let updatedActivationDetails = []
                            resp.data.data.map((activations) => {
                                activations.updatedAt = `${moment(activations.updatedAt).format("DD-MM-YYYY")} ${moment(activations.updatedAt).format("hh:mm A")} `
                                activations.familyCount = activations.familyMembers.length;
                                activations.identifier = <Chip size="small" style={{ background: "green", color: "white" }} label="Primary" />
                                activations.familyCount = activations.familyMembers.length;
                                updatedActivationDetails.push(activations);
                                activations.actions =
                                    <Tooltip placement='top' title="Family Members Card Details">
                                        <IconButton
                                            onClick={() => openFamilyDetails(activations.familyMembers)}
                                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                            aria-label="view">
                                            <AccountCircleIcon />
                                        </IconButton>
                                    </Tooltip>
                            })
                            //setActivationDetails(resp['data']['data']); 
                            setActivationDetails(updatedActivationDetails)
                            var downloadedData = resp.data.data.map((activations) => {
                                let temp = {};
                                temp.Card_No = activations.cardNo;
                                temp.CPR = activations.cpr;
                                temp.card_Ref_No = activations.cardRefNo;
                                temp.Name = activations.name;
                                temp.Card_Type = activations.cardType;
                                temp.Account_Category = activations.walletType;
                                temp.Identifier = "Primary"
                                temp.No_of_Family_Members = activations.familyMembers.length;
                                temp.Activation_Time = activations.updatedAt;
                                temp.Corporate_Name = activations.parentCorporateName;
                                return temp;
                            })
                            setCsvData(downloadedData);
                        }
                    }).catch((err) => {
                        openSnackBar('Unable To Process !!')
                    })
            }
        } catch (error) {
            openSnackBar("Unable to fetch Activation Report.")
        }
    }
    const openFamilyDetails = (familyMemberDetails) => {
        const fmData = familyMemberDetails.map((details) => {
            return details.name;
        })
        setShowDetails(true);
        setFamilyDetails(familyMemberDetails);
    }
    const handleChangeExpanded = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <>
            {respAlert}
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={true}
                        style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>From Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setFromDate(event.target.value) }, onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setFromDate("")
                                                    openSnackBar("Please enter valid date.")
                                                } else {
                                                    setFromDate(event.target.value)
                                                }
                                            },
                                            value: fromDate,
                                        }}
                                    />
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>To Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setToDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setToDate("")
                                                    openSnackBar("Please enter valid date.")
                                                } else {
                                                    setToDate(event.target.value)
                                                }
                                            }, value: toDate,
                                        }}
                                    />
                                    {/* {!submitting ? (
                                        <Button className={classes.addDeptButton}
                                            onClick={() => searchActivationsDetails()}
                                            size="lg" color="primary">
                                            <SearchIcon fontSize="medium" />
                                        </Button>
                                    )
                                        :
                                        <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
                                    } */}
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>Type</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <FormControl className={classes.groupSelect} fullWidth>
                                        <Select
                                            id="group-select"
                                            value={searchKey}
                                            disableUnderline
                                            onChange={(e) => {
                                                setSearchKey(e.target.value);
                                            }}>
                                            <MenuItem value="B2B">B2B</MenuItem>
                                            <MenuItem value="B2C">B2C </MenuItem>
                                            <MenuItem value="ALL">ALL </MenuItem>
                                        </Select>
                                    </FormControl>
                                    {!submitting ? (
                                        <Button className={classes.addDeptButton}
                                            onClick={() => searchActivationsDetails()}
                                            size="lg" color="primary">
                                            <SearchIcon fontSize="medium" />
                                        </Button>
                                    ) : (
                                        <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
                                    )}
                                </Paper>

                            </GridItem>
                        </GridContainer>
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showActivationTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Tabiib Card Issuance Report</h4>
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={activationDetails} columns={columns} options={options} />
                                {/* <MaterialTable data={activationDetails} columns={columns} options={options} /> */}
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.companyLogModal,
                }}
                open={showDetails}
                onClose={() => {
                    setShowDetails(false)
                }}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>Family Member Card Details</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <Collapse
                        in={familyDetails.length == 0}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        {/* <PropagateLoader color="#004fa4" loading={true} css={override} size={10} /> */}
                        <h4 className={classes.modalTitle}>No Card details Available.</h4>
                    </Collapse>
                    <Collapse
                        in={familyDetails}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <div>
                            {familyDetails.map((details) => {
                                return (
                                    <>
                                        <Paper elevation={3} className={classes.logPaper}>
                                            {details._id ?
                                                <Accordion
                                                    key={details._id}
                                                    expanded={expanded === `panel_${details._id}`}
                                                    onChange={handleChangeExpanded(`panel_${details._id}`)}  >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header">
                                                        <Typography>Name :&nbsp;&nbsp; {details.name}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails  >
                                                        <div style={{ width: "750px !important" }}>
                                                            <Table align="justify"
                                                                tableData={[
                                                                    ["CPR", ":", details.cpr],
                                                                    ["Card No", ":", details.cardNo],
                                                                    ["Address", ":", details.address],
                                                                    ["Status", ":", details.status ? (<Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />) : (<Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />)],
                                                                ]}
                                                            />
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                                : null}
                                        </Paper>
                                    </>
                                )
                            })}
                        </div>

                    </Collapse>
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setShowDetails(false);
                            //setTransactionDetails(null) 
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </>
    );
}