import { Collapse } from "@mui/material";
import React, { useState } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import axios from "axios"
import config from "config.js";
import libs from "controllers/libs";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiAlert from "@material-ui/lab/Alert";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from "components/CustomButtons/Button.js";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import InputBase from "@material-ui/core/InputBase";
const override = css`
display: block;
margin: 0px;
border-color: red;
`;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    dialogContainer: {
        display: "flex",
    },
    input: {
        flex: 1,
        marginTop: "10px",
        height: "45px",
        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    remarksHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
}));
export default function CorporateCardFee(props) {
    const classes = useStyles();
    const [view, setView] = useState(false); //0=loader 
    const [corporateList, setCorporateList] = useState([]);
    const [snackType, setSnackType] = useState("error");
    const [snackMsg, setSnackMsg] = useState("")
    const [openSnack, setOpenSnack] = React.useState(false);

    const [corporateID, setCorporateID] = useState("")
    const [newFee, setNewFee] = useState("")
    const [openFeeEdit, setOpenFeeEdit] = useState(false)
    const [respAlert, setRespAlert] = React.useState(null);
    const [submitting, setSubmitting] = useState(false)
    const [cardStatus, setCardStatus] = useState("")
    const [remarks, setRemarks] = useState("")
    const [referenceId, setReferenceId] = useState("")
    const [statusMsg, setStatusMsg] = useState("")
 
    React.useEffect(() => {
        if (props.corpID !== null) {
           console.log(props.referenceId)
            setCorporateID(props.corpID)
            // fetchFeeDetails(props.corpID)
            setNewFee(props.cardFee)
            setCardStatus(props.cardStatus);
            setRemarks(props.remark)
            setStatusMsg(props.statusMsg)
            setReferenceId(props.referenceId)
            
        }
    }, [props.corpID]);
    // console.log(props)
    
    const openSnackBar = (msg, success) => {
        setOpenSnack(true);
        if (success) {
            setSnackType("success")
        }
        else {
            setSnackType("error")
        }
        setSnackMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false)
            setSnackMsg("");
        }, 5000);
    }
      const submitFee = async () => {
        //function to submit new fee
        if (newFee.length == 0) openSnackBar("Please enter fee.")
        else {

            try {
                let data = {
                    "type": "cardFee",
                    "amount": newFee,
                    "status": cardStatus,
                    // "status_message": statusMsg,
                    // "remarks": remarks,
                    "feeReference": referenceId.current
                }
                console.log(data)
                // setView(true)
                setSubmitting(true)
                let res = await axios.post(`${config.url().baseURL}/create-card-fees/${corporateID}`, data
                ).then((res) => {
                    // setSubmitting(false)
                    if (res.data.err) openSnackBar(res.data.msg)
                    else {
                        setRespAlert(
                            <SweetAlert
                                success
                                style={{ display: "block" }}
                                title="Updated Successfully"
                                onConfirm={() => {
                                    setSubmitting(false)
                                    setRespAlert(null)
                                    closeModal()
                                }}
                                confirmBtnCssClass={classes.button + " " + classes.success}
                            />
                        );
                    }
                })
            } catch (error) {
                setSubmitting(false)
                openSnackBar("Unable to update the fee.")
                closeModal();
            }
        }
    }
    const closeModal = () => {
        //function to close modal
        setNewFee("")
        setOpenFeeEdit(false)
        props.close()
    }
    return (
        <div className={classes.root}>
            {respAlert}
            <Collapse
                in={view}
                style={{ transformOrigin: "0 0 0", textAlign: "center", height: "100px" }}
                {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
            </Collapse>

            <h4 className={classes.remarksHead}>Please enter new fee.</h4>
            <Paper className={classes.dialogContainer}>
                <InputBase
                    autoFocus={true}
                    fullWidth
                    className={classes.input}
                    placeholder=" Card Fee"
                    defaultValue={newFee}
                    inputProps={{
                        onBlur: (e) => setNewFee(e.target.value.trim()),
                        onChange: (e) => { setNewFee(libs.validate(e.target.value, "float", 9) ? e.target.value : newFee) },
                        value: newFee,
                    }}
                />
                <Button className={classes.nextButton}
                    onClick={() => submitFee()}
                    simple
                    disabled={submitting}
                    size="lg" color="primary">
                    <ArrowForwardIcon fontSize="medium" />
                </Button>
            </Paper>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackMsg("");
                    }}
                    severity={snackType}>
                    {snackMsg}
                </Alert>
            </Snackbar>
        </div>)
}