import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import ImageStyle from "assets/jss/material-dashboard-react/imagesStyles.js";
const customersStyle = (theme) => ({
  ...ImageStyle,
  ...SweetAlertStyle,
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    float: "left",
    display: "inlineBlock",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  left: {
    textAlign: "left",
  },
  marginRight: {
    marginRight: "5px",
  },
  modalSectionTitle: {
    marginTop: "30px",
  },
  logContainer: {
    maxHeight: "500px",
    overflowY: "scroll"

  },
  addButton: {
    float: "right !important",
    display: "inlineBlock",
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  addDeptContainer: {
    height: "49px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginBottom: "5px !important",
  },
  addDeptButton: {
    marginRight: "-5px !important",
  },
  input: {
    flex: 1,
    height: "45px",
    marginLeft: "15px",
  },
  input: {
    flex: 1,
    height: "45px",
    marginLeft: "15px",
  },
  searchHead: {
    marginTop: "-5px",
    marginBottom: "1px",
    color: "#144b85",
    fontWeight: "400"
  },


  dialogContainer: {
    display: "flex",
  },
  inputPassword: {
    flex: 1,
    marginTop: "10px",
    height: "85px",
    width: "350px",
    overflowY: "auto",
    paddingLeft: "10px",
  },
  // nextButton: {
  //   marginLeft: "150px"
  // },
  passwordHead: {
    fontWeightL: "500",
    marginBottom: "0px",
    color: "#ffffff"
  },
  indicator: {
    backgroundColor: 'white',
  },
  // button: {
  //   margin: "25px"
  // },


  ...modalStyle(theme),
});

export default customersStyle;
