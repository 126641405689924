import React from "react";
import config from "config.js"
import { useHistory } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardRounded from "@material-ui/icons/ArrowForwardRounded";
import Collapse from "@material-ui/core/Collapse";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MuiAlert from "@material-ui/lab/Alert";
import styles from "assets/jss/material-dashboard-react/views/forgotPasswordStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function PricingPage() {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = React.useState("");
  const [otp, setOTP] = React.useState("");
  const [showOTP, setShowOTP] = React.useState(false);
  const [showEmail, setShowEmail] = React.useState(true);
  const [showPassSession, setPassSession] = React.useState(false);
  const [showButton, setShowButton] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState("");
  const [newConfPassword, setNewConfPassword] = React.useState("");
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackType, setSnackType] = React.useState("error");
  const [respAlert, setRespAlert] = React.useState(null);

  const [otpHead, setOtpHead] = React.useState("Enter Your Email.");
  React.useEffect(() => { }, []);

  const hideAlert = () => {
    setRespAlert(null);
  };

  const getEmail = (e) => {
    //function to validate email and set to state
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    setEmail(e.target.value);
    if (re.test(e.target.value)) {
      setShowButton(true);
    } else {
      setShowButton(false);
      setShowOTP(false);
      setOTP("");
    }
  };

  function validate() {
    //function to validate password and otp
    if (otp.length === 6) {
      if (newPassword.length !== 0) {
        if (newPassword === newConfPassword) {
          var prog = [/[$@$!%*#?&]/, /[A-Z]/, /[0-9]/, /[a-z]/].reduce(
            (memo, test) => memo + test.test(newPassword),
            0
          );
          if (prog > 2 && newPassword.length > 5) {
            prog++;
          }
          if (prog === 5) {
            return true;
          } else {
            openSnackBar(
              "Password should contain one digit one special character one small letter and one capital letter",
              "error"
            );
            return false;
          }
        } else {
          openSnackBar("Passwords didn't match.", "error");
          return false;
        }
      } else {
        openSnackBar("Please enter password.", "error");
        return false;
      }
    } else {
      openSnackBar("Please enter valid otp.", "error");
      return false;
    }
  }
  function openSnackBar(msg, type) {
    //function to tost snackbar message
    setSnackBarMsg(msg);
    setSnackType(type);
    setOpenSnack(true);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  }

  const successChange = () => {
    hideAlert();
    sessionStorage.removeItem("cx_id");
    setTimeout(function () {
      history.push("/login-admin");
    }, 700);
  };

  const savePassword = () => {
    let valid = validate();
    if (valid) {
      axios.post(`${config.url().baseURL}/forgot-password-reset`, { otp: otp, password: newPassword, email: email })
        .then((resp) => {
          let respData = resp.data;
          console.log(respData);
          if (!respData.err) {
            setRespAlert(
              <SweetAlert
                success
                style={{ display: "block" }}
                title="Password has been changed."
                onConfirm={() => successChange()}
                confirmBtnCssClass={classes.button + " " + classes.success}
              />
            );
          } else {
            openSnackBar(respData.msg, "error");
          }
        })
        .catch((err) => {
          openSnackBar("Unable to process.", "error");
        });
    }
  };

  const sendOtp = () => {
    //function to send otp to given mail
    axios.post(`${config.url().baseURL}/forgot-password`, { email: email })
      .then((resp) => {
        let respData = resp.data;
        console.log(respData);
        if (!respData.err) {
          setOtpHead("Otp has been sent to your email.");
          openSnackBar("Otp send", "success")
          setShowEmail(false);
          setShowOTP(true);
        } else {
          openSnackBar(respData.msg, "error");
        }
      })
      .catch((err) => {
        openSnackBar("Unable to process.", "error");
      });
  };
  return (
    <div
      className={classes.pageHeader}
      style={{
        // backgroundImage: "url(" + image + ")",
        backgroundColor: "#001e42",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}>
      {respAlert}
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <h3 className={classes.title}>{otpHead}</h3>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={3}>
            {showEmail ? (
              <Paper component="form" className={classes.root}>
                <InputBase
                  className={classes.input}
                  placeholder="Email"
                  inputProps={{
                    "aria-label": "Email",
                    onChange: (event) => getEmail(event),
                    value: email,
                  }}
                />
                {showButton ? (
                  <>
                    <Divider className={classes.divider} orientation="vertical" />
                    <IconButton className={classes.iconButton} aria-label="directions" onClick={() => sendOtp()}>
                      <ArrowForwardRounded />
                    </IconButton>
                  </>
                ) : null}
              </Paper>
            ) : null}
            <Collapse
              in={showOTP}
              style={{ transformOrigin: "0 0 0", textAlign: "center" }}
              {...(true ? { timeout: 500 } : {})}>
              <p onClick={() => sendOtp()} className={classes.createLink}>
                Resend OTP
              </p>
              <Paper component="form" className={classes.root}>
                <InputBase
                  className={classes.input}
                  placeholder="OTP"
                  inputProps={{
                    "aria-label": "Otp",
                    type: "password",
                    value: otp,
                    onChange: (event) => {
                      if (!isNaN(event.target.value)) {
                        if (event.target.value.length <= 6) {
                          setOTP(event.target.value);
                        }
                        if (event.target.value.length === 6) {
                          setShowOTP(false);
                          setPassSession(true);
                          setOtpHead("Please enter new password.");
                        }
                      }
                    },
                  }}
                />
              </Paper>
            </Collapse>
            <Collapse
              in={showPassSession}
              style={{ transformOrigin: "0 0 0", textAlign: "center" }}
              {...(true ? { timeout: 500 } : {})}>
              <Paper component="form" className={classes.root}>
                <InputBase
                  className={classes.input}
                  placeholder="Password"
                  inputProps={{
                    "aria-label": "Password",
                    type: "password",
                    value: newPassword,
                    onChange: (event) => setNewPassword(event.target.value),
                  }}
                />
              </Paper>
              <Paper component="form" className={classes.root}>
                <InputBase
                  className={classes.input}
                  placeholder="Confirm Password"
                  inputProps={{
                    "aria-label": "Confirm Password",
                    type: "password",
                    value: newConfPassword,
                    onChange: (event) => setNewConfPassword(event.target.value),
                  }}
                />
              </Paper>
              <Button size="md" onClick={() => savePassword()} className={classes.backButton} color="green">
                Submit
              </Button>
            </Collapse>

            <Button
              onClick={() => history.push("/login-admin")}
              size="lg"
              fullWidth
              className={classes.backButton}
              simple
              color="white">
              <ArrowBackIcon />
              Back to login
            </Button>
          </GridItem>
        </GridContainer>
      </div>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
            setSnackBarMsg("");
          }}
          severity={snackType}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
