import React from "react";
import config from "config.js"
import SweetAlert from "react-bootstrap-sweetalert";
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/core";
import axios from "axios";
import moment from "moment";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from '@material-ui/core/Dialog';
import IconButton from "@material-ui/core/IconButton";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import avatar from "assets/img/no_dp.png";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// core componentsimport { Document, Page } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import Slide from "@material-ui/core/Slide";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Dropzone from "react-dropzone";
import { pdfjs } from 'react-pdf';
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Paper from '@mui/material/Paper';
import DeleteIcon from "@material-ui/icons/Delete";

import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import ImagesStyles from "assets/jss/material-dashboard-react/imagesStyles";
import CustomSelectStyle from "assets/jss/material-dashboard-react/customSelectStyle";
import libs from "controllers/libs";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const override = css`
  padding: 0;
  margin: 0;
`;

const styles = {
    ...CustomSelectStyle,
    ...ImagesStyles,
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },

    },
    formControl: {
        margin: "27px 0 0 0",
        textAlign: "left",
    },
    cardFooter: {
        textAlign: "center !important",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "20px",
    },
    required: {
        color: "red",
    },
    dateLabel: {
        marginTop: "-18px",
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "12px",
        fontWeight: "400",
    },
    dateInput: {
        marginTop: "20px !important",
    },
    subHead: {
        fontWeight: "500",
        marginBottom: "-2px !important",
        float: "left",
        display: "inlineBlock",
    },
};

const useStyles = makeStyles(styles);
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
export default function SystemUsers(props) {
    const classes = useStyles();
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [respAlert, setRespAlert] = React.useState(null);
    const [docs, setDocs] = React.useState([]);
    const [submitting, setSubmitting] = React.useState(false);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [saveBtn, setSaveBtn] = React.useState(false);
    const [newDocs, setNewDocs] = React.useState([]);
    const [documentViewerData, setDocumentViewerData] = React.useState(avatar);
    const [openImageViewer, setOpenImageViewer] = React.useState(false);
    const [pageNumberTwo, setPageNumberTwo] = React.useState(1);
    const [openPDFeViewer, setOpenPDFViewer] = React.useState(false);
    const [values, setValues] = React.useState({
        fnameErr: false,
        mnameErr: false,
        lnameErr: false,
        genderErr: false,
        cprErr: false,
        cprExpiryErr: false,
        dobErr: false,
        addressTypeErr: false,
        flatNumberErr: false,
        buildingNumberErr: false,
        roadNumberErr: false,
        blockNumberErr: false,
        phoneErr: false,
        emailErr: false,
        placeOfBirthErr: false,
        nationalityErr: false,
        occupationErr: false,
        prefLanguageErr: false,
        passportNumberErr: false,
        passportExpiryErr: false,
        docsErr: false
    });

    const [firstName, setFirstName] = React.useState("");
    const [middleName, setMiddleName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [gender, setGender] = React.useState("");
    const [dateOfBirth, setDateOfBirth] = React.useState("");
    const [cpr, setCpr] = React.useState("");
    const [cprExpiry, setCprExpiry] = React.useState("");
    const [addressType, setAddressType] = React.useState("");
    const [flatNumber, setFlatNumber] = React.useState("");
    const [buildingNumber, setBuildingNumber] = React.useState("");
    const [roadNumber, setRoadNumber] = React.useState("");
    const [blockNumber, setBlockNumber] = React.useState("");
    const [phoneNo, setPhone] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [placeOfBirth, setPlaceOfBirth] = React.useState("");
    const [nationality, setNationality] = React.useState("");
    const [occupation, setOccupation] = React.useState("");
    const [prefLanguage, setPrefLanguage] = React.useState("");
    const [passportNumber, setPassportNumber] = React.useState("");
    const [passportExpiry, setPassportExpiry] = React.useState("");
    const [numPages, setNumPages] = React.useState(null);
    const [kycEdit, setKycEdit] = React.useState(false)
    React.useEffect(() => {

        if (props.data !== null) {
            setData()
            console.log(props.data)
        }
    }, [props.data]);

    const setData = () => {
        //function to set form data 
        let SampleOne = props.data.DateOfBirth.split('/');
        let DOB = `${SampleOne[1]}/${SampleOne[0]}/${SampleOne[2]}`;

        let SampleTwo = props.data.Passport.PassportExpiry.split('/');
        let PassportExp = `${SampleTwo[1]}/${SampleTwo[0]}/${SampleTwo[2]}`;

        let SampleThree = props.data.CPRExpiryDate.split('-');
        let cprExp = `${SampleThree[1]}/${SampleThree[0]}/${SampleThree[2]}`;

        setFirstName(props.data.FirstName)
        setMiddleName(props.data.MiddleName)
        setLastName(props.data.LastName)
        setGender(props.data.Gender)
        setDateOfBirth(moment(DOB).format("YYYY-MM-DD"))
        setCpr(props.data.EmployeeCPRId)
        setCprExpiry(moment(cprExp).format("YYYY-MM-DD"))
        setAddressType(props.data.EmployeeAddress.AddressType)
        setFlatNumber(props.data.EmployeeAddress.FlatNumber)
        setBuildingNumber(props.data.EmployeeAddress.BuildingNumber)
        setRoadNumber(props.data.EmployeeAddress.RoadNumber)
        setBlockNumber(props.data.EmployeeAddress.BlockNumber)
        setPhone(props.data.MobileNumber)
        setEmail(props.data.EmailId)
        setPlaceOfBirth(props.data.PlaceOfBirth)
        setNationality(props.data.Nationality)
        setOccupation(props.data.Occupation)
        setPrefLanguage(props.data.PreferedCommunicationLanguage)
        setPassportNumber(props.data.Passport.PassportNumber)
        console.log(props.kycEdit)
        if (props.data.KYCStatus.ID !== undefined && props.data.KYCStatus.ID != "") {

            setKycEdit(true)
        }
        else { setKycEdit(false) }
        // setDocs([(props && props.data && props.data.addressProofDocImg ? props.data.addressProofDocImg : [])])
        setPassportExpiry(moment(PassportExp).format("YYYY-MM-DD"))
        axios.get(`${config.url().baseURL}/get-employee-id-new/${props.data._id}`, {}, {
            headers: {
                auth: sessionStorage.getItem("cx_id"),
            }
        })
            .then((resp) => {
                if (!resp['data']['err']) {
                    setDocs([resp['data']['data']['file']])

                } else {
                    setDocs([]);
                }
                console.log(docs)
            }).catch((e) => {
                console.log('crashed')
            })
    }


    const openSnackBar = (msg) => {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    };
    const deleteDoc = (id) => {
        //function to delete doc
        let tempDocs = [...docs]
        tempDocs.splice(id, 1)
        var filteredDocs = newDocs.filter(e => e !== id)
        setNewDocs(filteredDocs)
        setDocs(tempDocs)
    }

    const verifyLength = (value, length) => {
        // function that verifies if a string has a given length or not
        if (value !== undefined && value.length >= length) {
            return true;
        }
        return false;
    };
    const verifyEmail = (value) => {
        // function that returns true if value is email, false otherwise

        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    };
    const verify = (value, type, errVariable, length) => {
        switch (type) {
            case "email":
                if (libs.validate(value, "email", 64)) {
                    setValues({ ...values, [errVariable]: false });
                } else {
                    setValues({ ...values, [errVariable]: true });
                }
                break;
            case "length":
                if (verifyLength(value, length)) {
                    setValues({ ...values, [errVariable]: false });
                } else {
                    setValues({ ...values, [errVariable]: true });
                }
                break;
            case "phone":
                if (value.length <= 8) {
                    if (libs.validate(value, "number", 8)) {
                        if (verifyLength(value, length)) {
                            setPhone(value);
                            setValues({ ...values, [errVariable]: false });
                        } else {
                            setPhone(value);
                            setValues({ ...values, [errVariable]: true });
                        }
                    }
                }
                break;
            case "cpr":
                if (value.length <= 9) {
                    const re = /^[0-9\b]+$/;
                    if (value === "" || re.test(value)) {
                        if (verifyLength(value, length)) {
                            setValues({ ...values, [errVariable]: false });
                        } else {
                            setValues({ ...values, [errVariable]: true });
                        }
                    }
                }
                break;
            default:
                break;
        }
    };


    const validateForm = () => {
        //function to validate user creation form
        const reEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let flag = true;
        let fnameErr = false,
            mnameErr = false,
            lnameErr = false,
            genderErr = false,
            cprErr = false,
            cprExpiryErr = false,
            dobErr = false,
            addressTypeErr = false,
            flatNumberErr = false,
            buildingNumberErr = false,
            roadNumberErr = false,
            blockNumberErr = false,
            phoneErr = false,
            emailErr = false,
            placeOfBirthErr = false,
            nationalityErr = false,
            occupationErr = false,
            prefLanguageErr = false,
            passportNumberErr = false,
            passportExpiryErr = false,
            docsErr = false;
        let snackBarMsg = ""
        if (!verifyLength(firstName.trim(), 2)) {
            // (firstName.length < 2) {
            fnameErr = true;
            flag = false;
            snackBarMsg = (snackBarMsg == "" ? "First Name should not be Empty." : snackBarMsg);
        }
        if (!verifyLength(lastName.trim(), 1)) {
            lnameErr = true;
            snackBarMsg = (snackBarMsg == "" ? "Last Name should not be Empty." : snackBarMsg);
            flag = false;
        }
       
        if (gender.length < 1) {
            genderErr = true;
            flag = false;
            snackBarMsg = (snackBarMsg == "" ? "Gender Field should not be Empty." : snackBarMsg);

        }
        if (dateOfBirth.length < 1) {
            dobErr = true;
            flag = false;
            snackBarMsg = (snackBarMsg == "" ? "Select correct date of birth" : snackBarMsg);

        }
        if (!verifyLength(cpr, 9)) {
            snackBarMsg = (snackBarMsg == "" ? "Please enter 9 digit cpr number." : snackBarMsg);
            cprErr = true;
            flag = false;
        }
        if (!verifyLength(cprExpiry, 1) || (cprExpiry == "Invalid date") || (cprExpiry == "")) {
            if (!verifyLength(passportNumber.trim(), 3)) {
                passportNumberErr = true
                snackBarMsg = (snackBarMsg == "" ? "Passport Number is mandatory." : snackBarMsg);
                flag = false;
            }
            if ((!verifyLength(passportExpiry, 1)) || (passportExpiry == "Invalid date") || (passportExpiry == "")) {
                passportExpiryErr = true;
                snackBarMsg = (snackBarMsg == "" ? "Please select passport exipry date" : snackBarMsg);
                flag = false;
            }
        }
        else {
            if (moment(cprExpiry).isBefore(moment(new Date()))) {
                cprExpiryErr = true;
                snackBarMsg = (snackBarMsg == "" ? "Please enter the valid expiry date." : snackBarMsg);
                flag = false;
            }
            else if (passportExpiry !="" && passportExpiry !== "Invalid date" && verifyLength(passportExpiry, 1) && (!verifyLength(passportNumber, 3))) {
                flag = false;
                passportExpiryErr = true;
                snackBarMsg = (snackBarMsg == "" ? "Please enter Passport No  " : snackBarMsg);
                flag = false;
            }
        }

        if (!verifyLength(addressType.trim(), 1)) {
            addressTypeErr = true;
            flag = false;
            snackBarMsg = (snackBarMsg == "" ? "Address type should not be Empty." : snackBarMsg);

        }
        if (!verifyLength(flatNumber.trim(), 1)) {
            flatNumberErr = true;
            snackBarMsg = (snackBarMsg == "" ? "Flat Number should not be Empty." : snackBarMsg);
            flag = false;
        }
        if (!verifyLength(buildingNumber.trim(), 1)) {
            buildingNumberErr = true;
            snackBarMsg = (snackBarMsg == "" ? "Building Number should not be Empty." : snackBarMsg);
            flag = false;
        }
        if (!verifyLength(roadNumber.trim(), 1)) {
            roadNumberErr = true;
            snackBarMsg = (snackBarMsg == "" ? "Road Number should not be Empty." : snackBarMsg);
            flag = false;
        }
        if (!verifyLength(blockNumber.trim(), 1)) {
            blockNumberErr = true;
            snackBarMsg = (snackBarMsg == "" ? "Block Number should not be Empty." : snackBarMsg);
            flag = false;
        }

        if (!verifyLength(phoneNo.trim(), 8)) {
            snackBarMsg = (snackBarMsg == "" ? "Please enter eight digit mobile number." : snackBarMsg);
            phoneErr = true;
            flag = false;
        }
        const diffTime = Math.abs(moment(dateOfBirth) - moment(new Date()));
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays < 6575) {
            dobErr = true;
            snackBarMsg = (snackBarMsg == "" ? "Employee should not be minor one" : snackBarMsg)
            flag = false;
        }
        if ((moment(dateOfBirth).isAfter(moment(new Date())))) {
            dobErr = true;
            snackBarMsg = (snackBarMsg == "" ? "Please enter the valid date of birth." : snackBarMsg);
            flag = false;
        }
        if (moment(passportExpiry).isBefore(moment(new Date()))) {
            dobErr = true;
            snackBarMsg = (snackBarMsg == "" ? "Please enter the valid Passport Expiry date." : snackBarMsg);
            flag = false;
        }

        if (!verifyLength(placeOfBirth.trim(), 1)) {
            placeOfBirthErr = true;
            snackBarMsg = (snackBarMsg == "" ? "Place of Birth should not be Empty." : snackBarMsg);
            flag = false;
        }
        if (!verifyLength(nationality.trim(), 1)) {
            nationalityErr = true;
            flag = false;
            snackBarMsg = (snackBarMsg == "" ? "Nationality should not be Empty." : snackBarMsg);

        }
        if (!verifyLength(occupation.trim(), 1)) {
            occupationErr = true;
            snackBarMsg = (snackBarMsg == "" ? "Occupation should not be Empty." : snackBarMsg);
            flag = false;
        }
        if (!verifyLength(prefLanguage.trim(), 1)) {
            prefLanguageErr = true;
            snackBarMsg = (snackBarMsg == "" ? "Preferred Language should not be Empty." : snackBarMsg);
            flag = false;
        }
        if (docs.length == 0) {
            docsErr = true;
            snackBarMsg = (snackBarMsg == "" ? "Please upload the address proof image" : snackBarMsg);
            flag = false;
        }

        setValues({
            ...values,
            fnameErr: fnameErr,
            mnameErr: mnameErr,
            lnameErr: lnameErr,
            genderErr: genderErr,
            cprErr: cprErr,
            cprExpiryErr: cprExpiryErr,
            dobErr: dobErr,
            addressTypeErr: addressTypeErr,
            flatNumberErr: flatNumberErr,
            buildingNumberErr: buildingNumberErr,
            roadNumberErr: roadNumberErr,
            blockNumberErr: blockNumberErr,
            phoneErr: phoneErr,
            emailErr: emailErr,
            placeOfBirthErr: placeOfBirthErr,
            nationalityErr: nationalityErr,
            occupationErr: occupationErr,
            prefLanguageErr: prefLanguageErr,
            passportNumberErr: passportNumberErr,
            passportExpiryErr: passportExpiryErr,
            docsErr: docsErr
        });
        if (!flag) {
            if (snackBarMsg == "") {
                openSnackBar("Please Fill the field")
            } else {
                openSnackBar(snackBarMsg)
            }
        }
        return flag;
    };

    const dropDoc = (images) => {
        //function to drop doc
        if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
            if (images[0].size < 2000000) {
                libs.getBase64(images[0], (result) => {
                    let tempDocs = [...docs]
                    let tempNewDocs = [...newDocs]
                    tempNewDocs.push(tempDocs.length)
                    tempDocs.push(result)
                    setDocs(tempDocs)
                    setNewDocs(tempNewDocs)
                });
            } else {
                openSnackBar("File size is too large.");
            }
        } else {
            openSnackBar("Please upload jpg,jpeg and png files");
        }
    }
    const viewIDProof = (image) => {
        //function to open ID Proof
        let extension = image.toString().substring(image.indexOf("/") + 1, image.indexOf(";base64"));
        setDocumentViewerData(image)
        if (extension == "pdf") {
            setOpenPDFViewer(true)
            setOpenImageViewer(false)
        } else {
            setOpenImageViewer(true)
            setOpenPDFViewer(false)
        }
    }
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const submitUser = () => {
        //function to submit user for adding and updating
        let valid = validateForm();
        if (valid && docs.length != 0) {
            if (!submitting) {
                setSubmitting(true);
                let data = {
                    "FirstName": firstName.trim(),
                    "MiddleName": middleName.trim(),
                    "LastName": lastName.trim(),
                    "Gender": gender,
                    "EmployeeCPRId": cpr,
                    "CPRExpiryDate": cprExpiry ? (moment(cprExpiry).format("DD-MM-YYYY")) : "",
                    "MobileNumber": phoneNo,
                    "EmailId": email,
                    "PlaceOfBirth": placeOfBirth,
                    "DateOfBirth": moment(dateOfBirth).format("DD/MM/YYYY"),
                    "Nationality": nationality,
                    "EmployeeAddress": {
                        "AddressType": addressType.trim(),
                        "FlatNumber": flatNumber.trim(),
                        "BuildingNumber": buildingNumber.trim(),
                        "RoadNumber": roadNumber.trim(),
                        "BlockNumber": blockNumber.trim()
                    },
                    "Passport": {
                        "PassportNumber": (passportNumber ? passportNumber : ""),
                        "PassportExpiry": (passportExpiry ? (moment(passportExpiry).format("DD/MM/YYYY")) : "")
                    },
                    "PreferedCommunicationLanguage": prefLanguage,
                    "ValidProofOfIdentification": "cpr",
                    "Occupation": occupation,
                    "addressProofDocImg": docs[0],

                }
                axios.put(`${config.url().baseURL}/update-employee-new/${props.data._id}`, data, {
                    headers: {
                        auth: sessionStorage.getItem("cx_id"),
                    },
                })
                    .then((resp) => {
                        let respData = resp.data;
                        if (!respData.err) {
                            setSubmitting(false);
                            setRespAlert(
                                <SweetAlert
                                    success
                                    style={{ display: "block" }}
                                    title="Employee update successful."
                                    onConfirm={() => {
                                        props.success();
                                        setRespAlert(null);
                                    }}
                                    confirmBtnCssClass={classes.button + " " + classes.success}
                                />
                            );
                        } else {
                            openSnackBar(respData.msg);
                            setSubmitting(false);
                        }
                    })
                    .catch((err) => {
                        setSubmitting(false);
                        openSnackBar("Unable to process.");
                    });
            }

        } else {
            setSubmitting(false);
        }
    };


    return (
        <div>
            {respAlert}
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={true}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Edit Employee</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={6} sm={12} md={3}>
                                        <Collapse in={true} style={{ transformOrigin: "0 0 0", textAlign: "center" }}  {...(true ? { timeout: 500 } : {})}>
                                            {
                                                docs ?
                                                    docs.map((doc, key) => {
                                                        let extension = doc.toString().substring(doc.indexOf("/") + 1, doc.indexOf(";base64"));
                                                        if (extension == "pdf") {
                                                            return (
                                                                <>
                                                                    <Paper style={{ textAlign: "center", padding: "5px" }}>
                                                                        <Document
                                                                            style={{ width: "100px" }}
                                                                            className={classes.customLink}
                                                                            onClick={() => viewIDProof(doc)}
                                                                            file={doc}
                                                                            onLoadSuccess={onDocumentLoadSuccess}
                                                                        >
                                                                            <Page pageNumber={pageNumberTwo} />
                                                                        </Document>
                                                                        {
                                                                            !kycEdit ?
                                                                                <IconButton
                                                                                    onClick={() => deleteDoc(key)}
                                                                                    size="sm"
                                                                                    style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                                                                                    aria-label="delete">
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                                : null
                                                                        }
                                                                    </Paper>
                                                                    <br />
                                                                </>
                                                            )
                                                        } else {
                                                            return (
                                                                <>
                                                                    <Paper style={{ textAlign: "center", padding: "5px" }}>
                                                                        <img
                                                                            onClick={() => viewIDProof(doc)}
                                                                            src={doc}
                                                                            alt="..."
                                                                            className={
                                                                                classes.imgRounded +
                                                                                " " +
                                                                                classes.imgFluid +
                                                                                " " +
                                                                                classes.customLink
                                                                            } />
                                                                        {
                                                                            !kycEdit && (docs.length != 0) ?
                                                                                <IconButton
                                                                                    onClick={() => deleteDoc(key)}
                                                                                    size="sm"
                                                                                    style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                                                                                    aria-label="delete">
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                                : null
                                                                        }
                                                                    </Paper>
                                                                    <br />
                                                                </>
                                                            )
                                                        }
                                                    })
                                                    : null
                                            }
                                            {!kycEdit ?
                                                ((docs.length == 0) ?
                                                    <Dropzone onDrop={dropDoc}>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <Button size="sm" fullWidth color="primary">
                                                                    Add Document
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                    : null) : null
                                            }
                                        </Collapse>
                                    </GridItem>
                                    <GridItem xs={6} sm={12} md={9}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={3}>
                                                <CustomInput
                                                    labelText={
                                                        <span>
                                                            First Name <small className={classes.required}>*</small>
                                                        </span>
                                                    }
                                                    error={values.fnameErr}

                                                    id="first-name"
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                        value: firstName,
                                                        readOnly: kycEdit,
                                                        onChange: (e) => {
                                                            if (e.target.value !== "") {
                                                                if (e.target.value.match(/^[a-zA-Z ]+$/)) {
                                                                    verify(e.target.value.trim(), "length", "fnameErr", 2);
                                                                    setFirstName(e.target.value);
                                                                }
                                                            } else {
                                                                setFirstName(e.target.value);
                                                            }
                                                        },
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={3}>
                                                <CustomInput
                                                    error={values.mnameErr}
                                                    labelText={
                                                        <span>
                                                            Middle Name <small className={classes.required}></small>
                                                        </span>
                                                    }
                                                    id="middle-name"
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                        value: middleName,
                                                        readOnly: kycEdit,
                                                        onChange: (e) => {
                                                            if (e.target.value !== "") {
                                                                if (e.target.value.match(/^[a-zA-Z ]+$/)) {
                                                                    verify(e.target.value.trim(), "length", "mnameErr", 2);
                                                                    setMiddleName(e.target.value);
                                                                }
                                                            } else {
                                                                setMiddleName(e.target.value);
                                                            }
                                                        },
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={3}>
                                                <CustomInput
                                                    error={values.lnameErr}
                                                    readOnly={kycEdit}
                                                    labelText={
                                                        <span>
                                                            Last Name <small className={classes.required}>*</small>
                                                        </span>
                                                    }
                                                    id="last-name"
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                        value: lastName,
                                                        readOnly: kycEdit,
                                                        onChange: (e) => {
                                                            if (e.target.value !== "") {
                                                                if (e.target.value.match(/^[a-zA-Z ]+$/)) {
                                                                    verify(e.target.value.trim(), "length", "lnameErr", 2);
                                                                    setLastName(e.target.value);
                                                                }
                                                            } else {
                                                                setLastName(e.target.value);
                                                            }
                                                        },
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={3}>
                                                <FormControl className={classes.selectFormControl} error={values.genderErr} fullWidth>
                                                    <InputLabel className={classes.selectLabel}>
                                                        <span> Gender<small className={classes.required}>*
                                                        </small></span>
                                                    </InputLabel>
                                                    <Select
                                                        id="gender"
                                                        MenuProps={{
                                                            className: classes.selectMenu,
                                                        }}
                                                        classes={{
                                                            select: classes.select,
                                                        }}
                                                        value={gender}
                                                        disabled={kycEdit}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        onChange={(e) => {
                                                            setGender(e.target.value);
                                                            verify(e.target.value, "length", "genderErr", 2);
                                                        }}>
                                                        <MenuItem
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                            }}
                                                            disabled={kycEdit}
                                                            value="MALE">
                                                            MALE
                                                        </MenuItem>
                                                        <MenuItem
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                            }}
                                                            disabled={kycEdit}
                                                            value="FEMALE">
                                                            FEMALE
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            <GridItem xs={12} sm={3}>
                                                <FormControl className={classes.selectFormControl} fullWidth>
                                                    <InputLabel className={classes.dateLabel}>
                                                        <span>
                                                            Date of Birth <small className={classes.required}>*</small>
                                                        </span>
                                                    </InputLabel>
                                                    <TextField
                                                        error={values.dobErr}
                                                        className={classes.dateInput}
                                                        type="date"
                                                        inputProps={{
                                                            autoComplete: "off",
                                                            type: "date",
                                                            readOnly: kycEdit,
                                                            max: moment(new Date()).format("YYYY-MM-DD"),
                                                            value: dateOfBirth,
                                                            onChange: (e) => {
                                                                setDateOfBirth(e.target.value);
                                                                verify(e.target.value, "length", "dobErr", 2);
                                                            },
                                                            onBlur: (e) => {
                                                                if (moment(e.target.value).isAfter(moment(new Date()))) {
                                                                    setValues({ ...values, dobErr: true })
                                                                } else {
                                                                    setValues({ ...values, dobErr: false })
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <CustomInput
                                                    error={values.cprErr}
                                                    labelText={
                                                        <span>
                                                            CPR <small className={classes.required}>*</small>
                                                        </span>
                                                    }
                                                    id="emp-cpr"
                                                    inputProps={{
                                                        value: cpr,
                                                        max: "9",
                                                        readOnly: kycEdit,
                                                        onChange: (e) => {
                                                            if (e.target.value.length <= 9) {
                                                                const re = /^[0-9\b]+$/;
                                                                if (e.target.value == "" || re.test(e.target.value)) {
                                                                    if (e.target.value.length !== 0) {
                                                                        if (verifyLength(e.target.value, 9)) {
                                                                            setCpr(e.target.value.trim());
                                                                            setValues({ ...values, cprErr: false });
                                                                        } else {
                                                                            setCpr(e.target.value.trim());
                                                                            setValues({ ...values, cprErr: true });
                                                                        }
                                                                    } else {
                                                                        setCpr(e.target.value.trim());
                                                                        setValues({ ...values, cprErr: false })
                                                                    }
                                                                } else {
                                                                    setValues({ ...values, cprErr: false });
                                                                }

                                                            }
                                                        },
                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={3}>
                                                <FormControl className={classes.selectFormControl} fullWidth>
                                                    <InputLabel className={classes.dateLabel}>
                                                        <span>
                                                            Expiry Date <small className={classes.required}>*</small>
                                                        </span>
                                                    </InputLabel>
                                                    <TextField
                                                        error={values.cprExpiryErr}
                                                        className={classes.dateInput}
                                                        type="date"
                                                        inputProps={{
                                                            autoComplete: "off",
                                                            type: "date",
                                                            max: "9999/12/30",
                                                            min: moment(new Date()).format("YYYY-MM-DD"),
                                                            value: cprExpiry,
                                                            onChange: (e) => {
                                                                verify(e.target.value, "length", "cprExpiryErr", 3);
                                                                setCprExpiry(e.target.value);
                                                            },
                                                            onBlur: (e) => {
                                                                if (moment(e.target.value).isBefore(moment(new Date()))) {
                                                                    setValues({ ...values, cprExpiryErr: true })
                                                                } else {
                                                                    setValues({ ...values, cprExpiryErr: false })
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </GridItem>
                                            <GridItem xs={12} sm={12}>
                                                <h6 className={classes.subHead}> Address</h6>
                                            </GridItem>
                                            <GridItem xs={12} sm={12}>
                                                <Divider style={{ background: "black" }} fullWidth />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <CustomInput
                                                    error={values.addressTypeErr}
                                                    labelText={
                                                        <span>
                                                            Address Type <small className={classes.required}>*</small>
                                                        </span>
                                                    }
                                                    id="addressType"
                                                    inputProps={{
                                                        value: addressType,
                                                        onChange: (e) => {
                                                            const re = /^[a-zA-Z0-9_.-]*$/;
                                                            if (e.target.value === "" || re.test(e.target.value)) {
                                                                verify(e.target.value.trim(), "length", "addressTypeErr", 2);
                                                                setAddressType(e.target.value);
                                                            }
                                                            else {
                                                                setAddressType(e.target.value)
                                                            }
                                                        },
                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <CustomInput
                                                    error={values.flatNumberErr}
                                                    labelText={
                                                        <span>
                                                            Flat Number <small className={classes.required}>*</small>
                                                        </span>
                                                    }
                                                    id="phone-no"
                                                    inputProps={{
                                                        value: flatNumber,
                                                        onChange: (e) => {
                                                            const re = /^[a-zA-Z0-9_.-]*$/;
                                                            if (e.target.value === "" || re.test(e.target.value)) {
                                                                verify(e.target.value.trim(), "length", "flatNumberErr", 2);
                                                                setFlatNumber(e.target.value);
                                                            }
                                                            else {
                                                                setFlatNumber(e.target.value)
                                                            }
                                                        },
                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <CustomInput
                                                    error={values.buildingNumberErr}
                                                    labelText={
                                                        <span>
                                                            Building Number <small className={classes.required}>*</small>
                                                        </span>
                                                    }
                                                    id="build-no"
                                                    inputProps={{
                                                        value: buildingNumber,
                                                        onChange: (e) => {
                                                            const re = /^[a-zA-Z0-9_.-]*$/;
                                                            if (e.target.value === "" || re.test(e.target.value)) {
                                                                verify(e.target.value.trim(), "length", "buildingNumberErr", 2);
                                                                setBuildingNumber(e.target.value);
                                                            }
                                                            else {
                                                                setBuildingNumber(e.target.value)
                                                            }
                                                        },
                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <CustomInput
                                                    error={values.roadNumberErr}
                                                    labelText={
                                                        <span>
                                                            Road Number <small className={classes.required}>*</small>
                                                        </span>
                                                    }
                                                    id="road-no"
                                                    inputProps={{
                                                        value: roadNumber,
                                                        onChange: (e) => {
                                                            const re = /^[a-zA-Z0-9_.-]*$/;
                                                            if (e.target.value === "" || re.test(e.target.value)) {
                                                                verify(e.target.value.trim(), "length", "roadNumberErr", 2);
                                                                setRoadNumber(e.target.value);
                                                            }
                                                            else {
                                                                setRoadNumber(e.target.value)
                                                            }
                                                        },
                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <CustomInput
                                                    error={values.blockNumberErr}
                                                    labelText={
                                                        <span>
                                                            Block Number <small className={classes.required}>*</small>
                                                        </span>
                                                    }
                                                    id="block-no"
                                                    inputProps={{
                                                        value: blockNumber,
                                                        onChange: (e) => {
                                                            const re = /^[a-zA-Z0-9_.-]*$/;
                                                            if (e.target.value === "" || re.test(e.target.value)) {
                                                                verify(e.target.value.trim(), "length", "blockNumberErr", 2);
                                                                setBlockNumber(e.target.value);
                                                            }
                                                            else {
                                                                setBlockNumber(e.target.value)
                                                            }
                                                        },
                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12}>
                                                <h6 className={classes.subHead}> Contact</h6>
                                            </GridItem>
                                            <GridItem xs={12} sm={12}>
                                                <Divider style={{ background: "black" }} fullWidth />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <CustomInput
                                                    error={values.phoneErr}
                                                    labelText={
                                                        <span>
                                                            Phone Number <small className={classes.required}>*</small>
                                                        </span>
                                                    }
                                                    id="phoneNo"
                                                    inputProps={{
                                                        autoComplete: "off",
                                                        value: phoneNo,
                                                        max: "8",
                                                        min: "8",
                                                        onChange: (e) => {
                                                            var res = e.target.value.toString().substring(0, 1)
                                                            if (e.target.value === "" || res == 3 || res == 6) {
                                                                verify(e.target.value.trim(), "phone", "phoneErr", 8);
                                                                // setPhone(e.target.value.trim());
                                                            }
                                                        },
                                                        // else{
                                                        //     setPhone(e.target.value.trim());

                                                        // }
                                                        onBlur: (e) => {
                                                            setPhone(e.target.value.trim());
                                                        }


                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <CustomInput
                                                    error={values.emailErr}
                                                    labelText={
                                                        <span>
                                                            Email
                                                        </span>
                                                    }
                                                    id="email"
                                                    inputProps={{
                                                        autoComplete: "off",
                                                        // type: "email",
                                                        value: email,
                                                        onChange: (e) => {
                                                            verify(e.target.value.trim(), "email", "emailErr", 3)
                                                            setEmail(e.target.value.trim());
                                                        },
                                                        onBlur: (e) => {
                                                            setEmail(e.target.value.trim());
                                                        }
                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12}>
                                                <h6 className={classes.subHead}> Others</h6>
                                            </GridItem>
                                            <GridItem xs={12} sm={12}>
                                                <Divider style={{ background: "black" }} fullWidth />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <CustomInput
                                                    error={values.placeOfBirthErr}
                                                    labelText={
                                                        <span>
                                                            Place Of Birth <small className={classes.required}>*</small>
                                                        </span>
                                                    }
                                                    id="place-of-birth"
                                                    inputProps={{
                                                        value: placeOfBirth,
                                                        onChange: (e) => {
                                                            const re = /^[a-zA-Z0-9_.-]*$/;
                                                            if (e.target.value === "" || re.test(e.target.value)) {
                                                                verify(e.target.value.trim(), "length", "placeOfBirthErr", 2);
                                                                setPlaceOfBirth(e.target.value.trim());
                                                            }
                                                        },
                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <CustomInput
                                                    error={values.nationalityErr}
                                                    labelText={
                                                        <span>
                                                            Nationality <small className={classes.required}>*</small>
                                                        </span>
                                                    }
                                                    id="nationality"
                                                    inputProps={{
                                                        value: nationality,
                                                        readOnly: kycEdit,
                                                        onChange: (e) => {
                                                            const re = /^[a-zA-Z0-9_.-]*$/;
                                                            if (e.target.value === "" || re.test(e.target.value)) {
                                                                verify(e.target.value.trim(), "length", "nationalityErr", 2);
                                                                setNationality(e.target.value);
                                                                setValues({ ...values, nationalityErr: false });
                                                            }
                                                        },
                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <CustomInput
                                                    error={values.occupationErr}
                                                    labelText={
                                                        <span>
                                                            Occupation <small className={classes.required}>*</small>
                                                        </span>
                                                    }
                                                    id="occupation"
                                                    inputProps={{
                                                        value: occupation,
                                                        onChange: (e) => {
                                                            const re = /^[a-zA-Z0-9_.-]*$/;
                                                            if (e.target.value === "" || re.test(e.target.value)) {
                                                                verify(e.target.value.trim(), "length", "occupationErr", 2);
                                                                setOccupation(e.target.value);
                                                            }
                                                        },
                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <CustomInput
                                                    error={values.prefLanguageErr}
                                                    labelText={
                                                        <span>
                                                            Preferred Communication Language <small className={classes.required}>*</small>
                                                        </span>
                                                    }
                                                    id="pref-language"
                                                    inputProps={{
                                                        value: prefLanguage,
                                                        onChange: (e) => {
                                                            const re = /^[a-zA-Z0-9_.-]*$/;
                                                            if (e.target.value === "" || re.test(e.target.value)) {
                                                                verify(e.target.value.trim(), "length", "prefLanguageErr", 2);
                                                                setPrefLanguage(e.target.value);
                                                            }
                                                        },
                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <CustomInput
                                                    error={values.passportNumberErr}
                                                    labelText={
                                                        <span>
                                                            Passport Number
                                                        </span>
                                                    }
                                                    id="passport-no"
                                                    inputProps={{
                                                        value: passportNumber,
                                                        onChange: (e) => {
                                                            const re = /^[a-zA-Z0-9_.-]*$/;
                                                            if (e.target.value === "" || re.test(e.target.value)) {
                                                                verify(e.target.value.trim(), "length", "passportNumberErr", 2);
                                                                setPassportNumber(e.target.value.trim());
                                                            }
                                                        },
                                                    }}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <FormControl className={classes.selectFormControl} fullWidth>
                                                    <InputLabel className={classes.dateLabel}>
                                                        <span>
                                                            Expiry Date
                                                        </span>
                                                    </InputLabel>
                                                    <TextField
                                                        error={values.passportExpiryErr}
                                                        className={classes.dateInput}
                                                        type="date"
                                                        inputProps={{
                                                            autoComplete: "off",
                                                            type: "date",
                                                            max: "9999/12/30",
                                                            min: moment(new Date()).format("YYYY-MM-DD"),
                                                            value: passportExpiry,
                                                            onChange: (e) => {
                                                                setPassportExpiry(e.target.value);
                                                                verify(e.target.value, "length", "passportExpiryErr", 2);
                                                            },
                                                            onBlur: (e) => {
                                                                if (moment(e.target.value).isBefore(moment(new Date()))) {
                                                                    setValues({ ...values, passportExpiryErr: true })
                                                                } else {
                                                                    setValues({ ...values, passportExpiryErr: false })
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </GridItem> </GridContainer>  </GridItem>
                                    <CardFooter className={classes.cardFooter}>
                                        <Button onClick={() => props.back()} size="md" simple color="primary">
                                            <ArrowBackIcon />
                                            Back
                                        </Button>
                                        <Button onClick={() => submitUser()} size="md" color="primary">
                                            {!submitting ? (
                                                "Save"
                                            ) : (
                                                <PulseLoader color="#fff" loading={true} css={override} size={5} margin={2} />
                                            )}
                                        </Button>
                                    </CardFooter>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    // paper: classes.processModal,
                }}
                open={openPDFeViewer}
                TransitionComponent={Transition}
                onClose={() => {
                    setOpenPDFViewer(false);
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <Document
                        file={documentViewerData}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <p>Page {pageNumber} of {numPages}</p>
                    <Button size="sm" color="primary" onClick={() => {
                        if (pageNumber < 2) {
                            setPageNumber(1)
                        } else {
                            let newPageNumber = pageNumber - 1
                            setPageNumber(newPageNumber)
                        }
                    }} >Pervious</Button>
                    <Button size="sm" color="primary"
                        onClick={() => {
                            if (numPages > pageNumber) {
                                let newPageNumber = pageNumber + 1
                                setPageNumber(newPageNumber)
                            } else {
                                setPageNumber(numPages)
                            }
                        }}
                    >Next</Button>
                </DialogContent>
            </Dialog>
            {
                openImageViewer && (
                    <Lightbox
                        mainSrc={documentViewerData}
                        onCloseRequest={() => setOpenImageViewer(false)}
                    />
                )
            }
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    );
}
