import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { Document, Page } from 'react-pdf';
import CancelIcon from '@material-ui/icons/Cancel';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const PDFViewerComponent = ({ selectedFile, fileSource, handleCloseModal }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const goToPrevPage = () => {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages));
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    // if (fileSource === 'url') {
    // }
    setPageNumber(1); // Reset the page number when switching files
  }, [selectedFile, fileSource]);

  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleCloseModal} startIcon={<CancelIcon />}>
        Close
      </Button>
      <br />
      <br />
        <Document file={selectedFile} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
      <div style={{ marginTop: '10px', textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          disabled={pageNumber <= 1}
          onClick={goToPrevPage}
          startIcon={<ChevronLeftIcon />}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={pageNumber >= numPages}
          onClick={goToNextPage}
          endIcon={<ChevronRightIcon />}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default PDFViewerComponent;
