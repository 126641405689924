import * as React from 'react';
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Document, Page } from 'react-pdf';
import Button from "components/CustomButtons/Button.js";
import Lightbox from "react-awesome-lightbox";
import axios from 'axios';
import config from "config.js"
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";

import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import { makeStyles } from "@material-ui/core/styles";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(modalStyle);
export default function DocumentViewer(props) {
    const classes = useStyles();
    const [documentViewerData, setDocumentViewerData] = React.useState("");
    const [openImageViewer, setOpenImageViewer] = React.useState(false);
    const [openPDFeViewer, setOpenPDFViewer] = React.useState(false);
    const [numPages, setNumPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const setDocument = async (id) => {
        //function to set document 
        try {
            let resp = await axios.post(`${config.url().baseURL}/get-company-doc/${id}`, { company: props.company })
            if (resp.data.err) props.snack(resp.data.msg)
            else {
                let file = resp.data.doc;
                let extension = file.substring(file.indexOf("/") + 1, file.indexOf(";base64"));
                if (extension == "pdf") {
                    console.log(file)
                    setDocumentViewerData(file)
                    setOpenPDFViewer(true)
                } else {
                    setDocumentViewerData(file)
                    setOpenImageViewer(true)
                }
            }

        } catch (error) {
            console.log(error)
            props.snack("Unable to load document.")
        }

    }
    React.useEffect(() => { if (props.id) setDocument(props.id) }, [props.id, props.flag])
    return (
        <>
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    // paper: classes.processModal,
                }}
                open={openPDFeViewer}
                TransitionComponent={Transition}
                onClose={() => {
                    setOpenPDFViewer(false);
                    setDocumentViewerData("");
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <Document
                        file={documentViewerData}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <p>Page {pageNumber} of {numPages}</p>
                    <Button size="sm" color="primary" onClick={() => {
                        if (pageNumber < 2) {
                            setPageNumber(1)
                        } else {
                            let newPageNumber = pageNumber - 1
                            setPageNumber(newPageNumber)
                        }
                    }} >Pervious</Button>
                    <Button size="sm" color="primary"
                        onClick={() => {
                            if (numPages > pageNumber) {
                                let newPageNumber = pageNumber + 1
                                setPageNumber(newPageNumber)
                            } else {
                                setPageNumber(numPages)
                            }
                        }}
                    >Next</Button>
                </DialogContent>
            </Dialog>
            {
                openImageViewer && (
                    <Lightbox
                        //    reactModalStyle={customStyles}
                        image={documentViewerData}
                        onClose={() => setOpenImageViewer(false)}
                    />
                )
            }
        </>
    );
}