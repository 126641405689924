import * as React from 'react'
import axios from 'axios'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import config from "config.js"
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import SimpleButton from '@mui/lab/LoadingButton';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Table from "components/Table/Table.js";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import libs from "controllers/libs";
import CorporateSelect from "./CorporateSelect"
import "./styles.css"; import Dropzone from "react-dropzone";
import DeleteIcon from "@material-ui/icons/Delete"
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from "@material-ui/core/TextField";
import DatePicker from "./DatePicker"
import moment from 'moment';
import { Document, Page } from 'react-pdf';
import { setCompanyID } from 'redux/actions/corporateOnboarding';
import { TramRounded } from '@material-ui/icons';
import CustomerSpecialEdit from './CustomerSpecialFeeEdit'
import SelectCpr from "react-select";
import ShowDuplicatesCpr from './ShowDuplicatesCpr';
import { Table as Tables } from '@material-ui/core';
import { DialogTitle, DialogActions, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import * as XLSX from 'xlsx';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const override = css`
display: block;
margin: 32px auto;
border-color: red;
`;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "center",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },


    },

    cardTitleBlack: {
        color: "#000000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "center",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },


    },

    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),
}));


export default function CustomerTable(props) {
    const [submitting, setSubmitting] = React.useState(false)
    const [specialRateType, setSpecialRateType] = React.useState("")
    const [feeType, setFeeType] = React.useState("COMM-SPCL")
    const [documentName, setDocumentName] = React.useState(null);
    const classes = useStyles();
    const [openImageViewer, setOpenImageViewer] = React.useState(false);
    const [openPDFeViewer, setOpenPDFViewer] = React.useState(false);
    const [numPages, setNumPages] = React.useState(null);
    const [document, setDocument] = React.useState("");
    const [pageNumber, setPageNumber] = React.useState(1);
    const [uploadButtonColour, setUploadButtonColour] = React.useState("primary")
    const [corporateList, setCorporateList] = React.useState([])
    // const [selectedOptions, setSelectedOptions] = React.useState([]);
    const [startDate, setStartDate] = React.useState(new Date())
    const [endDate, setEndDate] = React.useState(new Date())
    const [comments, setComments] = React.useState("")
    const [campignName, setCampaignName] = React.useState("")
    const [showLoader, setShowLoader] = React.useState(false)
    const [fromTo, setFromTo] = React.useState([])
    const [corpDataReturn, setCorpDataReturn] = React.useState([])
    const [corprateTextColor, setCorprateTextColor] = React.useState(classes.cardTitleWhite)
    const [customerTextColor, setCustomerTextColor] = React.useState(classes.cardTitleBlack)
    const [customerBgColor, setCustomerBgColor] = React.useState("white")
    const [corprateBgColor, setCorprateBgColor] = React.useState("primary")
    // const [showCustomer, setShowCustomer] = React.useState(false)
    const [uploadedFile, setUploadedFile] = React.useState()
    const [uploadedFileName, setUploadedFileName] = React.useState()
    const [cpr, setCpr] = React.useState("")
    const [duplicates, setDuplicates] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [duplicateCprs, setDuplicateCprs] = React.useState([])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [url, setUrl] = React.useState('');


    const [optionList, setOptionList] = React.useState([{
        value: true,
        label: "Upload Cpr Excel"
    }, {
        value: false,
        label: "Enter Cpr Manually"
    }])
    const [selectedOptions, setSelectedOptions] = React.useState(null)
    const [cprs, setCprs] = React.useState("")
    // React state to manage selected options
    const handleOptionChange = (selectedOption) => {

        setSelectedOptions(selectedOption);
        console.log(selectedOptions);
    };
    const formData = new FormData()


    // const [child,setChild] = React.useState(<SpecialFeeEdit corporats={corporateList} corpotareSelected={CorporateSelected} disableEdit={props.edit} clearSelect={corpDataReturn} snack={props.snack} />)





    const [corpNames, setCorpNames] = React.useState([])
    const [values, setValues] = React.useState({
        campaignNameErr: null,
        campaignCommentsErr: null,
        campaignDateErr: null,
        corporatesErr: null,
        docErr: null
    });

    React.useEffect(() => {
        // fetchCorporateList()
        if (props.edit) {
            SetBackCampaignData(props.companyId)
        }
        else {

            setCompanyID("")
            setStartDate("")
            setEndDate("")
            setFromTo([])
            setCampaignName("")
            setComments("")
            setFeeType("COMM-SPCL")
            setDocumentName("")
            setDocument("")
            setCorpNames([])
        }
    }, [props.companyId, props.edit])
    React.useEffect(() => {
        setValues({
            ...values,
            campaignNameErr: false,
            campaignCommentsErr: false,
            campaignDateErr: false,
            corporatesErr: false,
            docErr: false
        })
    }, [])
    const idViewer = async (file) => {
        //function to view docs uploaded
        let extension = file.substring(file.indexOf("/") + 1, file.indexOf(";base64"));
        if (extension == "pdf"||extension=="octet-stream") {
            setDocument(file)
            setOpenPDFViewer(true)
        } else {
            setDocument(file)
            setOpenImageViewer(true)
        }
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const campaignDocDrop = async (images) => {
        try {

            // console.log(images);
            //function to handle dropping of  documents
            if (/\.(jpe?g|png|pdf)$/i.test(images[0].name) === true) {
                if (images[0].size < 2097152) {
                    setUploadButtonColour("primary")

                    formData.append('file', images[0])
                    // console.log(images[0]);
                    const url = await axios.post(`${config.url().wallet_URL}/upload-file`,
                        formData
                    )
                    console.log(url.data.data.filePath.Location);
                    setUrl(url.data.data.filePath.Location)
                    let urls = url.data.data.filePath.Location

                    const response = await fetch(urls);
                    const blob = await response.blob();
                    const base64 = await blobToBase64(blob);
                    setDocument(base64)

                    // console.log(base64);


                    //old
                    // libs.getBase64(images[0], (result) => {
                    //     setDocument(result);
                    // });
                    setDocumentName(images[0].name);
                } else {
                    props.snack("File size is too large, Maximum file size is 2 Mb.");
                }
            } else {
                props.snack("Please upload jpg,jpeg,png and pdf files");
            }
        } catch (err) {
            console.log(err);
        }
    }
    const verifyLength = (value, length) => {
        // function that verifies if a field is empty or not
        if (value !== undefined && value.length >= length) {
            return true;
        }
        return false;
    };

    const deleteDoc = () => {
        setDocumentName("")
        setDocumentName(null)
        setDocument("")
    }

    const validateField = () => {
        let flag = true;
        let campaignNameErr = false, docErr = false, corporatesErr = false, campaignCommentsErr = false, snackbarMsg = "", campaignDateErr = false
        console.log(campignName);
        if (!verifyLength(campignName.trim(), 2)) {
            console.log(campignName);
            campaignNameErr = true
            snackbarMsg = (snackbarMsg == "" ? "Please enter the Campaign Name" : snackbarMsg);
            flag = false
        }
        if (!verifyLength(comments && comments.trim(), 3)) {
            campaignCommentsErr = true
            snackbarMsg = (snackbarMsg == "" ? "Please enter the Comments" : snackbarMsg);
            flag = false
        }
        // if (!verifyLength(corpNames, 1)) {
        //     snackbarMsg = (snackbarMsg == "" ? "Please select the corporate" : snackbarMsg);
        //     flag = false
        //     corporatesErr = true
        // }
        let startingDate = moment(fromTo[0]).format("MM/DD/YYYY")
        if ((!verifyLength(startingDate, 1) || startingDate == "Invalid date" || startingDate == "")) {
            snackbarMsg = (snackbarMsg == "" ? "Please select campaign starting Date" : snackbarMsg);
            flag = false
            campaignDateErr = true
        }
        setValues({ ...values, campaignNameErr, campaignCommentsErr, campaignDateErr, corporatesErr, docErr });
        if (!flag) {
            props.snack(snackbarMsg)
        }
        return flag

    }
    // const ShowCorprateSpecialFeeForm = () => {
    //     // setCorprateColor(classes.cardTi)
    //     setShowCustomer(false)

    //     setCustomerBgColor("white")
    //     setCorprateBgColor("")
    //     setCorprateTextColor(classes.cardTitleWhite)
    //     setCustomerTextColor(classes.cardTitleBlack)
    // }

    // const ShowCustomerSpecialFeeForm = () => {
    //     setShowCustomer(true)
    //     setCustomerBgColor("")
    //     setCorprateBgColor("white")
    //     setCorprateTextColor(classes.cardTitleBlack)
    //     setCustomerTextColor(classes.cardTitleWhite)

    // }
    // const fetchCorporateList = async () => {
    //     //function to fetch corporate list
    //     try {
    //         let corporateResp = await axios.get(`${config.url().baseURL}/get-companies-list`)
    //         if (corporateResp.data.err) { props.snack(corporateResp.data.msg) }
    //         else { setCorporateList(corporateResp.data.data) }
    //     } catch (error) {
    //         props.snack("Unable to fetch corporate list.")
    //     }
    // }
    // const CorporateSelected = (corpData) => {
    //     setCorpDataReturn(corpData);
    //     let corpId = []
    //     let corpName = []
    //     corpData.map((corp) => {
    //         corpId.push(corp._id)
    //         corpName.push(corp.companyName)
    //     })
    //     setSelectedOptions(corpId)
    //     setCorpNames(corpName)
    //     console.log(corpName)
    // }
    const ClearDropdown = () => {

    }


    function blobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }


    const handleDownload = () => {
        const header = [
            ["cpr"]
        ];

        // Create a new workbook and worksheet using the xlsx library
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(header);

        // Add the worksheet to the workbook and save it as a file
        XLSX.utils.book_append_sheet(wb, ws, "cpr_special_fee_edit");
        XLSX.writeFile(wb, "Cpr_Special_Fee_Edit.xlsx");
    }
    const CampaignDate = (value) => {

        setStartDate(moment(value[0]).format("MM-DD-YYYY"))
        setEndDate(moment(value[1]).format("MM-DD-YYYY"))
    }
    const SetBackCampaignData = (id) => {
        //Edit purpose
        setShowLoader(true)
        // for get single campaign
        axios.get(`${config.url().baseURL}/get-campaign-details/${id}`)
            .then((resp) => {
                // console.log(resp);
                let respData = resp.data;
                if (!respData.err) {
                    let names = []
                    setCampaignName(respData.data[0].campaignName)
                    setCpr(respData.data[0].cpr)
                    setFeeType(respData.data[0].quoteType)
                    let upDatedDate = []
                    if (respData && respData.data.length != 0 && respData.data[0].startDate && respData.data[0].endDate) {
                        upDatedDate.push(respData.data[0].startDate, respData.data[0].endDate)
                    }
                    names.push(respData.data[0].companyInfo[0]?.companyName)
                    setCorpNames(names?.length ? names : null)
                    setFromTo(upDatedDate)
                    CampaignDate(upDatedDate)
                    setDocument(respData.data[0] && respData.data[0].doc ? respData.data[0].doc : null)
                    setDocumentName(respData.data[0].doc ? " Proof" : "")
                    setComments(respData.data[0].comments)
                    setSpecialRateType(respData?.data[0].conditionToavail)
                    setValues({
                        ...values,
                        campaignNameErr: false,
                        campaignCommentsErr: false,
                        corporatesErr: false,
                        campaignDateErr: false,
                        docErr: false
                    })
                }
                else {
                    props.snack(respData.msg)
                    console.log(respData);
                    setStartDate("")
                    setEndDate("")
                    setCampaignName("")
                    setComments("")
                    setFromTo([])
                    setFeeType("COMM-SPCL")
                    setDocumentName("")
                    setDocument("")
                }
            })
            .catch((err) => {
                console.log(err);
                props.snack("Unable to load campaign Details")
                setStartDate("")
                setEndDate("")
                setCampaignName("")
                setComments("")
                setFromTo([])
                setFeeType("COMM-SPCL")
                setDocumentName("")
                setDocument("")

            })
    }

    const CreateCampaign = () => {
        if (validateField()) {
            setSubmitting(true)
            if (props.edit != true) {
                // let campaignData = {
                //     "companyid": selectedOptions,
                //     "startDate": startDate,
                //     "endDate": endDate,
                //     "campaignName": campignName,
                //     "quoteType": feeType,
                //     "comments": comments,
                //     "doc": document
                // }
                formData.append("companyid", selectedOptions)
                formData.append("startDate", startDate)
                formData.append("endDate", endDate)
                formData.append("campaignName", campignName)
                formData.append("quoteType", feeType)
                formData.append("comments", comments)
                formData.append("doc", url)
                formData.append("file", uploadedFile)
                formData.append("conditionToavail", specialRateType)






                //For creating new Camapign
                axios.post(`${config.url().baseURL}/create-new-campaignCustomer`, formData).then((resp) => {
                    console.log(resp);
                    if (resp.data.err) {
                        props.snack(resp.data.msg)
                        // fetchCorporateList()
                        setSubmitting(false);

                    }
                    else if (resp.data.duplicates) {
                        setDuplicates(true)
                        setDuplicateCprs(resp?.data?.duplicatedCPR)
                        // console.log(resp?.data?.duplicatedCPR);
                        setOpen(true)
                        setSubmitting(false);

                        // setDuplicateCprs[resp?.data?.duplicatedCPR]
                    }
                    else {

                        props.snack(resp.data.msg, "success")
                        BackToHome()
                        setCorporateList([])
                        setCorpDataReturn([])
                        // setSelectedOptions([])
                        setCorpNames([])
                        ClearDropdown()
                        setSubmitting(false);


                    }

                }).catch((err) => {
                    props.snack("Unable to Create the Campaign,Try again later")
                    setSubmitting(false);
                    setCompanyID("")
                    setStartDate("")
                    setEndDate("")
                    setCampaignName("")
                    setFromTo([])
                    setComments("")
                    setFeeType("COMM-SPCL")
                    props.back()
                    props.setShowCustomer(false)
                    setDocumentName("")
                    setDocument("")
                    setCorpDataReturn([])
                })
            }
            else {
                let campaignData = {
                    "campaignID": props.companyId,
                    "startDate": startDate,
                    "endDate": endDate,
                    "campaignName": campignName,
                    "quoteType": feeType,
                    "comments": comments,
                    "doc": url,
                    "conditionToavail": specialRateType


                }


                //For updating Camapign data
                axios.post(`${config.url().baseURL}/edit-campaignCustomer`, campaignData).then((resp) => {
                    if (!resp.data.err) {
                        props.snack(resp.data.msg, "success")
                        props.back()
                        props.setShowCustomer(false)
                        setFromTo([])
                        setSubmitting(false);
                    }
                    else {
                        props.snack(resp.data.msg)
                        setSubmitting(false);
                    }

                }).catch((err) => {
                    props.snack("Unable to Update the Campaign,Try again later")
                    setSubmitting(false);
                    setCompanyID("")
                    setStartDate("")
                    setEndDate("")
                    setFromTo([])
                    setCampaignName("")
                    setComments("")
                    setFeeType("COMM-SPCL")
                    props.back()
                    props.setShowCustomer(false)
                    setDocumentName("")
                    setDocument("")
                    setCorpNames([])
                    setCorpDataReturn([])
                })
            }
        }
    }

    const Back = () => {
        setOpen(false)
    }
    const BackToHome = () => {
        setOpen(false)
        setSubmitting(false);
        // fetchCorporateList()
        setCompanyID("")
        setStartDate("")
        setEndDate("")
        setCampaignName("")
        setCorpDataReturn([])
        setComments("")
        setFeeType("COMM-SPCL")
        props.back()
        props.setShowCustomer(false)
        setDocumentName("")
        setDocument("")
        setCorpNames([])
        setFromTo([])
        setValues({
            ...values,
            campaignNameErr: false,
            campaignCommentsErr: false,
            campaignDateErr: false,
            corporatesErr: false,
            docErr: false
        })

    }

    return (
        <>
            {/* <FormControl className="dropdown-container" autoComplete="off">
        <p style={{ width: "400px", margin: "-5px",display:"block",
            }}> 
                    <Dropzone 
                     onDrop={selectedFile=>{
                        console.log(selectedFile);
                        setUploadedFile(selectedFile[0])
                        // setCustomerCpr(selectedFile)
                        setUploadedFileName(selectedFile[0].name)
                    }}
                    
                    accept=".xlsx, .xls">
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Button size="sm" color={uploadButtonColour} style={{width:"100%"}}>
                                    Click to Upload
                                </Button>
                            </div>
                        )}
                    </Dropzone>
                    {uploadedFileName&&`Uploaded File:${uploadedFileName}`}
                    </p>
    </FormControl> */}
            {duplicates && (
                <ShowDuplicatesCpr open={open} duplicateCprs={duplicateCprs} BackToHome={Back} />)
            }
            <Table
                tableData={[
                    //    
                    !props.edit && ["Download Sample Excel File", ":", <Button variant="contained" startIcon={<CloudDownloadIcon />} onClick={handleDownload}>
                        Download Sample Excel File
                    </Button>],
                    // selectedOptions && selectedOptions.value ? null 
                    !props.edit ?
                        ["Upload Customer Cpr", ":",
                            <p style={{
                                width: "400px", margin: "-5px", display: "block",
                            }}>
                                <Dropzone
                                    onDrop={selectedFile => {
                                        console.log(selectedFile);
                                        setUploadedFile(selectedFile[0])
                                        // setCustomerCpr(selectedFile)
                                        setUploadedFileName(selectedFile[0].name)
                                    }}

                                    accept=".xlsx, .xls">
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <Button size="sm" color={uploadButtonColour}>
                                                Click to Upload
                                            </Button>
                                        </div>
                                    )}
                                </Dropzone>
                                {uploadedFileName && `Uploaded File:${uploadedFileName}`}
                            </p>
                        ] : [

                            "CPR", ":",
                            <TextField
                                type="text"
                                InputProps={{
                                    // readOnly: true,
                                }}
                                style={{ width: "90%" }}
                                error={values.campaignNameErr}
                                inputProps={{
                                    autoComplete: "off",
                                    value: cpr,
                                    onChange: (e) => {
                                        const value = e.target.value;
                                        const re = /^[a-zA-Z0-9!+=@#$%^&*'';() ,.?":{}/|<>]{1,500}$/;
                                        if ((value === "" || re.test(value))) {
                                            setCpr(e.target.value)
                                        }
                                        setValues({ ...values, campaignNameErr: false })
                                    },
                                    onBlur: (e) => {
                                        let data = e.target.value.trim()
                                        if (data) {
                                            setCpr(data)
                                        }
                                    }
                                }}
                            />
                        ],

                    ["Condition To Avail", ":",
                        <FormControl className="dropdown-container" >
                            <Select value={specialRateType} disableUnderline onChange={(e) => { setSpecialRateType(e.target.value) }}>
                                <MenuItem value="FIRST_TIME_REMITTANCE">First Time Remittance</MenuItem>
                                <MenuItem value="FIRST_TIME_REMITTANCE_DURING_CAMPAIGN">First Time Remittance During Campaign</MenuItem>
                            </Select>
                        </FormControl>

                    ],


                    ["SpecialFee", ":",
                        <FormControl className="dropdown-container" >
                            <Select value={feeType} disableUnderline onChange={(e) => { setFeeType(e.target.value) }}>
                                <MenuItem value="COMM-SPCL">Commission-Special</MenuItem>
                                <MenuItem value="RATE-SPCL">Rate-Special</MenuItem>
                                <MenuItem value="BOTH-SPCL">Commission & Rate-Special</MenuItem>
                            </Select>
                        </FormControl>

                    ],
                    [

                        "Proof", ":",
                        documentName ? (<p style={{ width: "400px" }} >{documentName}
                            <Tooltip placement="right" title="Delete">
                                <IconButton
                                    onClick={() => deleteDoc()}
                                    style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                                    aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip placement="bottom" title="View Document">
                                <IconButton
                                    onClick={() => idViewer(document)}
                                    style={{ marginTop: "-10px", marginBottom: "-10px", color: "#014FA4" }}
                                    aria-label="delete">
                                    <VisibilityIcon />
                                </IconButton>
                            </Tooltip>
                            Upload only JPE(G) PNG or PDF, Make sure the file size is below 2 MB
                        </p>)
                            :
                            (<p style={{ width: "400px", margin: "-5px" }}>
                                <Dropzone onDrop={campaignDocDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <Button size="sm" color={uploadButtonColour}>
                                                Click to Upload
                                            </Button>
                                        </div>
                                    )}
                                </Dropzone>
                                Upload only JPEG or PNG or PDF, Make sure the file size is below 2 MB
                            </p>
                            ),

                    ],
                    [
                        "Campaign Name", ":",
                        <TextField
                            type="text"
                            style={{ width: "90%" }}
                            error={values.campaignNameErr}
                            inputProps={{
                                autoComplete: "off",
                                value: campignName,
                                onChange: (e) => {
                                    const value = e.target.value;
                                    const re = /^[a-zA-Z0-9!+=@#$%^&*'';() ,.?":{}/|<>]{1,500}$/;
                                    if ((value === "" || re.test(value))) {
                                        setCampaignName(e.target.value)
                                    }
                                    setValues({ ...values, campaignNameErr: false })
                                },
                                onBlur: (e) => {
                                    let data = e.target.value.trim()
                                    if (data) {
                                        setCampaignName(data)
                                    }
                                }
                            }}
                        />
                    ],
                    [
                        "Comments", ":",
                        <TextField
                            type="text"
                            style={{ width: "90%" }}
                            error={values.campaignCommentsErr}
                            inputProps={{
                                autoComplete: "off",
                                value: comments,
                                onChange: (e) => {
                                    const value = e.target.value;
                                    const re = /^[a-zA-Z0-9!+=@#$%^&*'';() ,.?":{}/|<>]{1,500}$/;
                                    if ((value === "" || re.test(value))) {
                                        setComments(e.target.value)
                                    }
                                    setValues({ ...values, campaignCommentsErr: false })

                                },
                            }}
                        />
                    ],
                    [
                        "Campaign Date", ":", <DatePicker dateSetter={CampaignDate} FromTo={fromTo} />

                    ]
                ]
                }
            />
            <br />
            <SimpleButton size="sm" simple color="primary" onClick={BackToHome} ><ArrowBackIcon />Back</SimpleButton> <SimpleButton color="primary" onClick={CreateCampaign} loading={submitting} variant="contained">Submit</SimpleButton>
            <Dialog
                style={{ textAlign: "center" }}
                open={openPDFeViewer}
                TransitionComponent={Transition}
                onClose={() => {
                    setOpenPDFViewer(false);
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <Document
                        file={document}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <p>Page {pageNumber} of {numPages}</p>
                    <SimpleButton size="sm" color="primary" onClick={() => {
                        if (pageNumber < 2) {
                            setPageNumber(1)
                        } else {
                            let newPageNumber = pageNumber - 1
                            setPageNumber(newPageNumber)
                        }
                    }} >Pervious</SimpleButton>
                    <SimpleButton size="sm" color="primary"
                        onClick={() => {
                            if (numPages > pageNumber) {
                                let newPageNumber = pageNumber + 1
                                setPageNumber(newPageNumber)
                            } else {
                                setPageNumber(numPages)
                            }
                        }}
                    >Next</SimpleButton>
                </DialogContent>
            </Dialog>
            {
                openImageViewer && (
                    <Lightbox
                        image={document}
                        onClose={() => setOpenImageViewer(false)}
                    />
                )
            }

        </>
    )

}