export default (state = 0, action) => {
  switch (action.type) {
    case "reset":
      return {
        state : 0,
      };
    case "admin":
      return {
        ...state,
        admin: action.payload,
      };
    case "_id":
      return {
        ...state,
        _id: action.payload,
      };
    case "employeeID":
      return {
        ...state,
        employeeID: action.payload,
      };
    case "departmentID":
      return {
        ...state,
        departmentID: action.payload,
      };
    case "status":
      return {
        ...state,
        status: action.payload,
      };
    case "firstName":
      return {
        ...state,
        firstName: action.payload,
      };
    case "lastName":
      return {
        ...state,
        lastName: action.payload,
      };
    case "phone":
      return {
        ...state,
        phone: action.payload,
      };
    case "email":
      return {
        ...state,
        email: action.payload,
      };
    case "groups":
      return {
        ...state,
        groups: action.payload,
      };
    case "services":
      return {
        ...state,
        services: action.payload,
      };
      case "riskServices":
        return {
          ...state,
          riskServices: action.payload,
        };
    case "username":
      return {
        ...state,
        username: action.payload,
      };

    default:
      return state;
  }
};
