
import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';
import config from "config.js"
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import MUIDataTable from "mui-datatables";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core/styles";
import CardHeader from 'components/Card/CardHeader';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import SweetAlert from 'react-bootstrap-sweetalert';
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import libs from 'controllers/libs';
import Button from '@mui/material/Button';
import axios from 'axios';

//custom components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import BillersForms from './BillersForms'

const useStyles = makeStyles((them) => ({
  ...SweetAlertStyle,
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "1px",
    textDecoration: "none",
    float: "left",
    display: "inlineBlock",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    }
  }
}))


export default function SadadBillers(props) {
  const classes = useStyles()
  const [tableData, setTableData] = React.useState([])
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("400px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [flag, setFlag] = useState(true)
  const [form, setForm] = useState(null)
  const [snackBarType, setSnackBarType] = React.useState("error");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [respAlert, setRespAlert] = React.useState(null);
  const [showLoader, setShowLoader] = React.useState(true)




  const override = css`
display: block;
margin: 32px auto;
border-color: red;
`;





  const handleClick = (category) => {
    //function to render the form component
    setFlag(false)
    setForm(<BillersForms category={category} back={comeBack} snack={openSnackBar} Alert={openAlert} categoryList={props.categoryList} />)

  }
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled"  {...props} />;
  }


  function openSnackBar(msg, status) {
    //function to tost snackbar message
    setOpenSnack(true);
    if (status) {
      setSnackBarType("success")
    } else {
      setSnackBarType("error")
    }
    setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  }

  const parseData = async () => {
    //add action to the category array
    try {
      let newData = []
      let resp = await axios.post(`${config.url().appURL}/CXPortal/v3/view-sadad-Billers`, { category: props.category })
      console.log(resp.data.data)
      Promise.all(
        resp.data.data.map((category) => {
          let newCategory = {
            name: category.name,
            category: category.category,
            active: category.active,
            actions: (<>
              <Tooltip placement="top" title="View">
                <IconButton
                  onClick={() => handleClick(category)}
                  style={{ marginTop: "-10px", marginBottom: "-10px" }}
                  aria-label="view">
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </>)
          }
          newData.push(newCategory)
        }))
      setTableData(newData)
      setShowLoader(false)

    } catch (error) {
      setShowLoader(false)
      openSnackBar('Unable to fetch Billers details.')
    }

  }

  const openAlert = (type, title, text) => {
    //function to open sweet alert
    setRespAlert(
      <SweetAlert
        type={type}
        style={{ display: "block" }}
        title={title}
        onConfirm={() => {
          setRespAlert(null);
          comeBack()
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {text}
      </SweetAlert>)
  }


  React.useEffect(() => {
    if (props.category) parseData()
    else openSnackBar("Category not fund.")

  }, [])

  //billers table column
  const columns = [{
    name: "name",
    label: "Name",
  },
  {
    name: "category",
    label: "Category",
  },
  {
    name: "active",
    label: "Status",
  }, {
    name: 'actions',
    label: 'Actions'
  }
  ];


  //billers table options
  const options = {
    selectableRows: false,
    responsive,
    elevation: 0,
    tableBodyHeight,
    tableBodyMaxHeight,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    }
  };

  const comeBack = () => {
    //function to come back to billers listing page
    setFlag(true)
    parseData()
    setForm(null)
  }

  return (
    <>
      {respAlert}
      <Collapse
        in={showLoader}
        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
        {...(true ? { timeout: 500 } : {})}>
        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
      </Collapse>
      <Collapse in={flag && !showLoader}
        style={{ transformOrigin: "0 0 0", textAlign: "center" }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary" >
                <h4 className={classes.cardTitleWhite} >Billers Details</h4>
              </CardHeader>
              <CardBody>< MUIDataTable data={tableData} columns={columns} options={options} />
                <div style={{ justifyContent: "center" }} >
                  <Button onClick={props.onBack} style={{ marginTop: '10px' }} variant="simple">
                    Back
                  </Button>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Collapse>
      <Collapse in={!flag && !showLoader}
        style={{ transformOrigin: "0 0 0" }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {form}
          </GridItem>
        </GridContainer>
      </Collapse>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
            setSnackBarMsg("");
          }}
          severity={snackBarType}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </>
  );
}