import * as React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Collapse from '@material-ui/core/Collapse'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PropagateLoader from 'react-spinners/PropagateLoader'
import MUIDataTable from 'mui-datatables'
import Chip from "@material-ui/core/Chip";
import moment from 'moment'
import Snackbar from "@material-ui/core/Snackbar";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import { creatCsvFile, downloadFile } from "download-csv";
import MuiAlert from "@material-ui/lab/Alert";
import config from "config.js"
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import MainForm from 'views/GPWUserOnboarding/Components/MainForm'
import GPWUserView from 'views/GPWUserOnboarding/Components/GPWUserView';
import GPWEdit from 'views/GPWUserOnboarding/Components/GPWEdit';
import { useSelector } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import UnarchiveIcon from '@mui/icons-material/Unarchive';

const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


export default function RewardReport() {
    const classes = useStyles();
    const [showLoader, setShowLoader] = useState(false);
    const [showGPWUserTable, setshowGPWUserTable] = useState(true)
    const [fromDate, setFromDate] = useState(moment(new Date).format("YYYY-MM-DD"))
    const [toDate, setToDate] = useState(moment(new Date).format("YYYY-MM-DD"))
    const [openSnack, setOpenSnack] = useState(false)
    const [snackBarMsg, setSnackBarMsg] = useState("")
    const [snackBarType, setSnackBarType] = useState("error")
    const [csvData, setCsvData] = React.useState(false);
    const [respAlert, setRespAlert] = React.useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [gpwUser, setGPWUser] = React.useState([])
    const [child, setChild] = useState(null)
    const [openAddTable, setOpenAddTable] = useState(false)

    const user = useSelector(state => state.user);
    const columns = [
        {
            name: "createdAt",
            label: "date",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "cpr",
            label: "CPR",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "mobile",
            label: "Mobile",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "firstName",
            label: "FirstName",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "lastName",
            label: "LastName",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "riskStatus",
            label: "Name screening",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'Actions',
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (_, tableMeta) => {
                    const rowData = gpwUser[tableMeta.rowIndex];
                    let service = user?.service && user.services.find(service => service.name == "GPW Archived Users")
                    return (
                        <>
                            <Tooltip title="View GPW User">
                                <span
                                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                                    onClick={() => viewGPWUser(rowData.cpr)}
                                >
                                    <VisibilityIcon />
                                </span>
                            </Tooltip>
                            <span style={{ marginRight: '10px' }}></span>

                            { superUser === true ? (
                                <Tooltip title="Retrieve User">
                                    <span
                                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                                        onClick={() => confirmRetrieveCustomer(rowData.cpr)}
                                    >
                                        <UnarchiveIcon />
                                    </span>
                                </Tooltip>
                            ) : null}

                            <span style={{ marginRight: '10px' }}></span>
                        </>

                    );
                },
            },
        },
    ]
    const options = {
        selectableRows: false,
        elevation: 0,
        print: false,
        onDownload: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = "GPW User.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        }
    };


    React.useEffect(() => {
        searchGPWUser();
    }, [])

    const viewGPWUser = (cpr) => {
        // setGPWId(id)
        setshowGPWUserTable(false);
        setOpenAddTable(true)
        setChild(<GPWUserView type="Add" back={comeBack} snack={openSnackBar} cpr={cpr} />)

    }
    // const retrieveUserUser = (cpr) => {
    //     // setGPWId(id)
    //     setshowGPWUserTable(false);
    //     setOpenAddTable(true)
    //     //setChild(<GPWEdit type="Add" back={comeBack} snack={openSnackBar} cpr={cpr} />)



    // }

    const confirmRetrieveCustomer = (id) => {
        //function to confirm user before approve
        setRespAlert(
            <SweetAlert
                warning
                input
                style={{ display: "block" }}
                title="Are you sure?"
                onConfirm={() => {
                    retrieveUser(id)
                }}
                onCancel={() => {
                    setRespAlert(null)
                }} Data
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                cancelBtnCssClass={classes.button}
                confirmBtnCssClass={classes.button + " " + classes.danger}
            >
                Do you really want to retrive this user?
            </SweetAlert>)
    }

    const retrieveUser = async(id) => {
        //function to approve customer 
        try {
            let payload = {}
            let url = ``
            let respData
            
            url = `${config.url().baseURL}/change-onboarding-status`
            payload = {status:"RETRIEVED",cpr:id}
            respData = (await axios.post(url,payload)).data

            if (!respData.err) {
                setRespAlert(
                    <SweetAlert
                        success
                        input
                        style={{ display: "block" }}
                        title={respData.msg}
                        onConfirm={() => {
                            setRespAlert(null)
                            searchGPWUser();
                        }}
                        onCancel={() => {
                            setRespAlert(null)
                        }}
                        confirmBtnText="Ok"
                        confirmBtnCssClass={classes.button + " " + classes.success}
                    >
                    </SweetAlert>)
            } else {
                setRespAlert(null)
                setRespAlert(
                    <SweetAlert
                        danger
                        input
                        style={{ display: "block" }}
                        title={respData.msg}
                        confirmBtnCssClass={classes.button + " " + classes.danger}
                        onConfirm={() => {
                            setRespAlert(null)
                        }}
                        confirmBtnText="Ok"
                    >
                    </SweetAlert>)
            }
        } catch (error) {
            openSnackBar("Unable to process.");
        }

    }


    const validateSearch = () => {

        let snackBarMsg = ""
        let flag = true

        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }

    function comeBack() {
        setshowGPWUserTable(true);
        setOpenAddTable(false)
        searchGPWUser()
        setChild("")

    }

    const searchGPWUser = () => {
        try {
            if (validateSearch()) {
                let data = {
                    "fromDate": moment(fromDate).format("MM/DD/YYYY"),
                    "toDate": moment(toDate).format("MM/DD/YYYY"),
                }
                setShowLoader(true);
                setSubmitting(true);
                setshowGPWUserTable(false);
                setGPWUser([])
                axios.get(`${config.url().baseURL}/get-gpw-archived-userlist`, data)
                    .then((resp) => {
                        setShowLoader(false);
                        setSubmitting(false);
                        setshowGPWUserTable(true)
                        let resData = resp.data
                        if (resData.err) {
                            openSnackBar(resData.msg)
                        }
                        else {
                            let updatedDetails = [];
                            resData.data.map((activations) => {
                                activations.createdAt = activations.createdAt ? moment(activations.createdAt).format("DD/MM/YYYY hh:mm A") : "";
                                activations.cpr = activations.cpr ? activations.cpr : "";
                                activations.mobile = activations.mobile ? activations.mobile : "";
                                activations.firstName = activations.firstName ? activations.firstName : "";
                                activations.lastName = activations.lastName ? activations.lastName : "";
                                activations.riskStatus = activations.riskStatus ? activations.riskStatus : "";
                                activations.status = activations.status ? activations.status : ""
                                if (activations.riskStatus) {
                                    activations.riskStatus = (
                                        <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />
                                    );
                                } else {
                                    activations.riskStatus = (
                                        <Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />
                                    );
                                }
                                updatedDetails.push(activations);
                            });
                            setGPWUser(updatedDetails);

                            var downloadedData = resData.data.map((activations) => {
                                let temp = {};
                                temp.Date = activations.createdAt
                                temp.CPR = activations.cpr;
                                temp.Mobile = activations.mobile;
                                temp.FirstName = activations.firstName;
                                temp.LastName = activations.lastName;
                                temp.Status = activations.status
                                // temp.NameScreening = activations.riskStatus;

                                return temp;
                            });
                            setCsvData(downloadedData);
                        }

                    })
                    .catch((err) => {
                        openSnackBar("Unable to process")
                    })
            }
        }
        catch (error) {
            openSnackBar("Unable to fetch the details")

        }
    }


    function openSnackBar(msg, success) {
        setOpenSnack(true)
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }
    console.log("user......",user)
    let service = user?.service && user.services.find(service => service.name == "GPW Archived Users")
    let superUser = user && user.admin

    

    return (
        <div>{respAlert}

            <GridContainer justify='center'>

                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{
                            transformOrigin: "0 0 0", textAlign: 'center'
                        }}
                        {...true ? { timeout: 500 } : {}}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showGPWUserTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...true ? { timeout: 500 } : {}}>
                        <Card>
                            <CardHeader color="primary">

                                <h4 className={classes.cardTitleWhite}> GPW Archived Users</h4>

                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={gpwUser} columns={columns} options={options} />
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={openAddTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        {child}
                        {/* <AddReward back={comeBack} snack={openSnackBar} /> */}
                    </Collapse>
                </GridItem>
            </GridContainer>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    )
} 