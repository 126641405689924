import * as React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Collapse from '@material-ui/core/Collapse'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PropagateLoader from 'react-spinners/PropagateLoader'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import Snackbar from "@material-ui/core/Snackbar";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import { creatCsvFile, downloadFile } from "download-csv";
import { useSelector } from 'react-redux';
import MuiAlert from "@material-ui/lab/Alert";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AssignmentIcon from "@material-ui/icons/Assignment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Delete } from '@material-ui/icons'
import EditIcon from '@material-ui/icons/Edit';
import config from "config.js"
import CampaignLogs from './CampaignLogs'
import CampaignDetailsView from './CampaignDetailsView'
import { Dialog } from '@mui/material'
import { DialogActions, DialogContent, DialogTitle, InputBase, Paper } from '@material-ui/core'
import SpecialFeeEdit from './SpecialFeeEdit'
// import { ContentPasteSearchOutlined } from '@mui/icons-material'
import SearchIcon from '@material-ui/icons/Search';
import BounceLoader from "react-spinners/BounceLoader";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';


const override = css`
display: block;
margin: 32px auto;
border-color: red;
`;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function ShowCampaginDetialView(props) {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [csvData, setCsvData] = React.useState("")
    let superUser = user && user.admin
    let updatedData = []
    let updatedCSV = []
    let service = user && user.services && user.services.find(service => service.name == "Special Rates")
    const [campaignList, setCampaignList] = useState([])
    const [log, setLog] = useState(false)
    const [test, setTest] = useState(false)
    const [id, setId] = useState("")
    const [showCompanyView, setShowCompanyView] = useState(false)
    const [companyId, setCompanyId] = useState("")
    const [approvalEdit, setApprovalEdit] = useState(false)
    const [statusCampaign, setStausCampaign] = React.useState("");
    const [showCampaignTable, setShowCampaignTable] = useState(true)
    const [makerEdit, setMakerEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [searchKey, setSearchKey] = useState("");
    const [searchEnabled, setSearchEnabled] = useState(true);

    const [submitting, setSubmitting] = useState(false);

    const BackToHome =  ()=>{
        setSearchKey("")
        fetchEachCampaign(true)
        props.back()
    }

    const handleOpen = (id) => {
        setOpen(true);
        setId(id)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        removeCampaign();
        setOpen(false);
    };

    const editCampaignData = (id) => {

        // setShowCompanyView(false)
        setOpenEdit(true);
        setMakerEdit(true)
        setCompanyId(id)
        setShowCampaignTable(false)
        setShowCompanyView(false)
        setLog(false)
    }

    const resetSearch = ()=>{
        setSearchKey("")
    }

    const searchCampagin = async () => {
        // setCampaignList([])
        try {
            setSearchEnabled(true)
            fetchEachCampaign()
            // const resp = await axios.post(`${config.url().baseURL}/search-campaign`, {
            //     campaignID: props.campaignID,
            //     searchKey: searchKey
            // })
            // if (resp.data.err) {
            //     props.snack(resp.data.msg)
            // } else {
               
            // }
        } catch (err) {
            console.log(err);
            props.snack("Something Went Wrong")
        }

    }
    const removeCampaign = async () => {
        try {
            const resp = await axios.post(`${config.url().baseURL}/delete-campaign`, {
                id
            })
            // console.log(resp);
            if (resp.data.err) {
                props.snack(resp.data.msg)

            } else {
                props.snack("deleted", true)
                setCampaignList([])
                // fetchEachCampaign()
                setTest(true)

            }
        } catch (err) {
            console.log(err);
            props.snack("Something Went Wrong")

        }
    }

    const fetchLog = (id) => {
        //Displaying logs from CamapignLogs component
        // setShowCorporateTable(false)
        // setShowCompanyView(false)
        setOpenEdit(false)
        // setMakerEdit(false)
        setLog(true)
        setId(id)
        // setCompanyId(id)

    }

    const fetchCampaignDetails = (id, level) => {
        //function for passing values to CamapignDetails component
        setShowCampaignTable(false);
        if (service && service.type && service.type == "approver" && level == "CHECKED") {
            setApprovalEdit(true); //Approver can edit campaigndate.
        } else { setApprovalEdit(false) }
        setShowCompanyView(true)
        setMakerEdit(true);
        setCompanyId(id)
        // setLog(false)
        setStausCampaign(level)

    }

    const Back = () => {
        setShowCompanyView(false)
        setShowCampaignTable(true)
        setLog(false)
    }

    const fetchEachCampaign = async (back) => {
        let newRecord = []
        let resp
        // setShowCompanyView(false)
        // setShowCampaignTable(true)
        try {
           
            if (props.campaignID&&!props.stopRerender) {
                // console.log(props.campaignID);
                // setTest(false)
                props.setShowLoader(true)
                if(searchEnabled){
                    resp = await axios.post(`${config.url().baseURL}/search-campaign`, {
                   campaignID: props.campaignID,
                   searchKey: !back?searchKey:""
               })
              
               }else{
                 resp = await axios.post(`${config.url().baseURL}/get-campaignBy-campaignID`, {
                    campaignID: props.campaignID,
                    pageNumber: page || 1,
                    pageSize: 10
                })

            }
                if (resp.data.err) {
                    props.snack(resp.data.msg)
                } else {
                    // console.log(resp.data.data.data)
                    await Promise.all(resp.data.data.data.map((cList) => {
                        if (superUser == true) {
                            // if (cList && cList.logs.length && cList.logs[cList.logs.length - 1].status == "APPROVED" ||
                            //     cList && cList.logs.length && cList.logs[cList.logs.length - 1].status == "DISABLED" ||
                            //     cList && cList.logs.length && cList.logs[cList.logs.length - 1].status == "ENABLED") {
                            cList.level = cList && cList.logs.length && cList.logs[cList.logs.length - 1].status ? cList.logs[cList.logs.length - 1].status : ""
                            // cList.created_Date = moment(cList.createdAt).format("DD/MM/YYYY")
                            cList.company_Name = cList && cList?.companyInfo?.length && cList.companyInfo[0].companyName ? cList.companyInfo[0].companyName : ""
                            cList.campaignID = cList && cList.campaignID
                            cList.cpr = cList && cList.cpr
                            cList.StartDate = cList.campaigns && cList.campaigns.startDate && cList.campaigns.startDate ? moment(cList.campaigns.startDate).format("DD/MM/YYYY") : "";
                            cList.EndDate = cList.campaigns && cList.campaigns.endDate && cList.campaigns.endDate != "NA" ? moment(cList.campaigns.endDate).format("DD/MM/YYYY") : ""
                            cList.level = cList && cList?.logs?.length && cList?.logs[cList.logs.length - 1].status ? cList?.logs[cList.logs?.length - 1].status : ""
                            cList.actions = (
                                <>
                                    <Tooltip placement="right" title="View Log">
                                        <IconButton
                                            onClick={() => fetchLog(cList._id)}
                                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                            aria-label="log">
                                            <AssignmentIcon />
                                        </IconButton>
                                    </Tooltip>

                                    {/* <Tooltip placement="right" title="View GPW/WPS">
                                <IconButton
                                    onClick={() => fetchEachCampaign(cList.campaignID)}
                                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                    aria-label="log">
                                    <ViewList />
                                </IconButton>
                            </Tooltip> */}
                                    <Tooltip placement="right" title="View Details">
                                        <IconButton
                                            onClick={() => fetchEachCampaign(cList.campaignID)}
                                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                            aria-label="log">
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Tooltip>
                                    {/* {props.service.type=='checker'&& */}
                                    <Tooltip placement="right" title="Remove Cpr">
                                        <IconButton
                                            onClick={() => removeCampaign(cList._id)}
                                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                            aria-label="log">
                                            < Delete />
                                        </IconButton>
                                    </Tooltip>
                                    {/* } */}
                                </>)
                            updatedData.push(cList)
                            let temp = {}
                            // temp.Created_Date = cList.created_Date;
                            temp.Company_Name = cList.company_Name.replaceAll(/,/g, '')
                            temp.Campaign_StartDate = cList.StartDate
                            temp.Campiagn_EndDate = cList.EndDate
                            temp.Level = cList.level
                            temp.campaignID = cList.campaignID
                            // console.log("temp..........",temp);
                            updatedCSV.push(temp)

                            // }

                        } else {
                            // console.log("data:", cList?.logs[cList.logs.length - 1]?.log[cList?.logs[cList.logs.length - 1].log.length - 1].status, "length", cList.logs[cList.logs.length - 1]?.log.length);
                            // cList.created_Date = moment(cList.createdAt).format("DD/MM/YYYY")
                            cList.company_Name = cList && cList?.companyInfo?.length && cList.companyInfo[0].companyName ? cList.companyInfo[0].companyName : ""
                            cList.campaignID = cList && cList.campaignID
                            cList.StartDate = cList && cList.startDate && cList.startDate ? moment(cList.startDate).format("DD/MM/YYYY") : "";
                            cList.EndDate = cList && cList.endDate && cList.endDate != "NA" ? moment(cList.endDate).format("DD/MM/YYYY") : ""
                            cList.level = cList && cList?.logs?.length && cList?.logs[cList.logs.length - 1]?.log[cList?.logs[cList.logs.length - 1].log.length - 1].status
                            cList.cpr = cList && cList?.cpr

                            cList.actions = (
                                <>
                                    {/* <Tooltip placement="right" title="View Log">
                                <IconButton
                                    onClick={() => fetchLog(cList._id)}
                                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                    aria-label="log">
                                    <AssignmentIcon />
                                </IconButton>
                            </Tooltip> */}

                                    {/* <Tooltip placement="right" title="View GPW/WPS">
                                <IconButton
                                    onClick={() => fetchEachCampaign(cList.campaignID)}
                                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                    aria-label="log">
                                    <ViewList />
                                </IconButton>
                            </Tooltip> */}
                                    <Tooltip placement="right" title="View Log">

                                        <IconButton
                                            onClick={() => fetchLog(cList._id)}
                                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                            aria-label="log">
                                            <AssignmentIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip placement="right" title="View Details">
                                        <IconButton
                                            onClick={() => fetchCampaignDetails(cList._id, cList.level)}
                                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                            aria-label="log">
                                            <VisibilityIcon />
                                        </IconButton>

                                    </Tooltip>
                                    {/* {service.type == "maker" && (cList.level == "Created" || cList.level == "RECHECK" || cList.level == "CREATED")&&(cList.cpr) ?
                                        <Tooltip placement="right" title="Edit">
                                            <IconButton
                                                onClick={() => editCampaignData(cList._id)}
                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                aria-label="log">
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>

                                        : null} */}

                                    {service.type == "maker" || service.type == "checker" && (cList.level == "Created" || cList.level == "RECHECK" || cList.level == "CREATED") &&
                                        <Tooltip placement="right" title="Remove GPW/WPS">
                                            <IconButton
                                                onClick={() => handleOpen(cList._id)}
                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                aria-label="log">
                                                < Delete />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </>)
                            newRecord.push(cList)
                            let temp = {}
                            // temp.Created_Date = cList.created_Date;
                            temp.cpr = cList?.cpr
                            temp.company_Name = cList?.Company_Name
                            temp.Start_Date = cList.StartDate
                            temp.End_Date = cList.EndDate
                            temp.Level = cList.level
                            updatedCSV.push(temp)

                            // console.log("temp.....",temp);

                        }
                    }))
                    // console.log(newRecord)

                    setCount(resp.data.data.count)
                    setCampaignList(newRecord);
                    setCsvData(updatedCSV)

                }


                // console.log(resp);
            }

        } catch (error) {
            console.log(error);
            props.snack("Something Went Wrong")
        } finally {
            props.setShowLoader(false)
            // setSearch(false)
        }


    }

    useEffect(() => {
        fetchEachCampaign()

        return (() => {

        })
    }, [props.campaignID,props.id ])
    // props.campaignID, test, page

    // useEffect(() => {
    //     fetchEachCampaign()
    // }, [])

    const options = {
        selectableRows: false,
        elevation: 0,
        print: false,
        onDownload: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = " Special Rates.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        },


        // Pagination properties:
        // pagination: true,        // Enable pagination
        // page: page,                  // Current page number
        // rowsPerPage: 10,          // Number of rows per page
        // rowsPerPageOptions: [10], // Options for rows per page dropdown
        // count: count, // Total number of records (required for pagination to work)

        // onTableChange: (action, tableState) => {


        //     switch (action) {
        //         case "changePage":
        //             setPage(tableState.page);
        //             break;
        //         case "changeRowsPerPage":
        //             setRowsPerPage(tableState.rowsPerPage);
        //             setPage(0); // Reset to the first page when changing rows per page
        //             break;
        //         default:
        //             break;
        //     }
        // }

    };

    const columns = [
        {
            name: "company_Name",
            label: "Company Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "cpr",
            label: "CPR",
            options: {
                filter: true,
                sort: true,
                display: true,

            },
        },


        {
            name: "StartDate",
            label: "Start Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "EndDate",
            label: "End Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "level",
            label: "Level",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
            },
        }
    ]

    return (
        <>
            <Collapse
                in={showCompanyView}
                style={{ transformOrigin: "0 0 0" }}
                {...(true ? { timeout: 500 } : {})}>
                <CampaignDetailsView companyId={companyId} back={Back} snack={props.snack} approvalEdit={approvalEdit} campaignStatus={statusCampaign} superUser={superUser} />
            </Collapse>


            {/* delete popup */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={6}> */}
            
            {/* <Tooltip placement="bottom" title="Download CSV">
                        <IconButton className={classes.addDeptButton}
                          onClick={() => setShowDownload(true)}
                          size="lg" color="primary">
                          <GetAppIcon fontSize="medium" />
                        </IconButton>
                      </Tooltip> */}
            {/* <Tooltip placement="bottom" title="Failed User Log">
                        <IconButton className={classes.addDeptButton}
                          onClick={() => openFailedLog()}
                          size="lg" color="primary">
                          <PersonAddDisabledIcon fontSize="medium" />
                        </IconButton>
                      </Tooltip> */}
            {/* <Tooltip placement="bottom" title="Download Failed users List">
                        <IconButton className={classes.addDeptButton}
                          onClick={() => downloadFailedCustomers()}
                          size="lg" color="primary">
                          <SystemUpdateAltIcon fontSize="medium" />
                        </IconButton>
                      </Tooltip> */}
            {/* </GridItem>
                  </GridContainer> */}

            <Collapse
                in={showCampaignTable&&!props.showLoader}
                style={{ transformOrigin: "0 0 0" }}
                {...(true ? { timeout: 500 } : {})}>
                    <Paper elevation={1} className={classes.addDeptContainer}>
                <InputBase
                    id="cusSearch"
                    autoFocus={true}
                    fullWidth
                    type="search"
                    className={classes.input}
                    placeholder="Search"
                    inputProps={{

                        "aria-label": "Search",
                        onChange: (event) => { setSearchKey(event.target.value) },
                        onBlur: (event) => { setSearchKey(event.target.value.trim()) },
                        value: searchKey,
                        onKeyDown: (e) => {
                            if (e.key == "Enter") {
                                searchCampagin()

                            }
                        }
                    }}
                />
                {!submitting ? (
                    <Button submit className={classes.addDeptButton}
                        onClick={() => searchCampagin()}
                        size="lg" color="primary">
                        <SearchIcon fontSize="medium" />
                    </Button>
                ) : (
                    <BounceLoader color="#004fa4" loading={true} css={override} size={50} />
                )}
            </Paper>

            <Tooltip placement="bottom" title="Reset">
                <IconButton className={classes.addDeptButton}
                      onClick={() => resetSearch()}
                    size="lg" color="primary">
                    <RotateLeftIcon fontSize="medium" />
                </IconButton>
            </Tooltip>
                <MUIDataTable options={options} columns={columns} data={campaignList} />
                <Button onClick={BackToHome}>Back</Button>
            </Collapse>

            <Collapse
                in={openEdit}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <SpecialFeeEdit back={Back} snack={props.snack} companyId={companyId} editCpr={true} AllCampagin={false} />
            </Collapse>

            {
                log &&
                <CampaignLogs ComapanyID={id} back={Back} snack={props.snack} />
            }
        </>
    )

}