import * as React from 'react';
import axios from "axios";
// import {round} from mathjs;
import MUIDataTable from "mui-datatables";
import Collapse from "@material-ui/core/Collapse";
import config from "config.js"
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import Chip from "@material-ui/core/Chip";
import moment from 'moment';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { creatCsvFile, downloadFile } from "download-csv";

import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import BounceLoader from "react-spinners/BounceLoader";
import SearchIcon from '@material-ui/icons/Search';
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Table from "components/Table/Table.js";


const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
export default function TabiibReports() {
    const classes = useStyles();

    const [showActivationTable, setShowActivationTable] = React.useState(false)
    const [showLoader, setShowLoader] = React.useState(false)
    const [snackBarType, setSnackBarType] = React.useState("error");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [respAlert, setRespAlert] = React.useState(null);

    const [fromDate, setFromDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
    const [toDate, setToDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
    const [activationDetails, setActivationDetails] = React.useState([]);

    const [searchKey, setSearchKey] = React.useState("B2B");
    const [showDetails, setShowDetails] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [csvData, setCsvData] = React.useState(false);
    const [openCardTransaction, setOpenCardTransaction] = React.useState({});

    const columns = [
        {
            name: "cpr",
            label: "CPR",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "cardNo",
            label: "Tabiib Card Number",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "cardref",
            label: "Card Reference No",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "userType",
            label: "Account Category",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "cardType",
            label: "Card Type",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "convertedStatus",
            label: "Transaction Status",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "transactionID",
            label: "Transaction ID",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "amount",
            label: "Amount",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "timeStamp_created",
            label: "Payment Time",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "identifier",
            label: "Identifier",
            options: {
                filter: true,
                filterOptions: {
                    names: []
                },
                sort: true,
            },

        },
        {
            name: "corporateName",
            label: "Corporate Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        // {
        //     name: "actions",
        //     label: "Actions",
        //     options: {
        //         filter: false,
        //         sort: false,
        //     },
        // }

    ];

    const options = {
        selectableRows: false,
        elevation: 0,
        print: false,
        onDownload: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = "Tabiib Transaction Report.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        }
    };
    React.useEffect(() => {
        searchActivationsDetails();
    }, []);
    const validateSearch = () => {
        //function validate search params
        let snackBarMsg = ""
        let flag = true
        if (fromDate.length == 0) {
            flag = false
            snackBarMsg = "Please select from date."
        }
        if (toDate.length == 0) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select To date." : snackBarMsg);
        }
        if (searchKey.length == 0) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select type." : snackBarMsg);
        }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }
    function openSnackBar(msg, success) {
        //function to tost snackbar message
        setOpenSnack(true);
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }
    const searchActivationsDetails = () => {
        //function to fetch transactions 
        try {
            //checkUserType();
            if (validateSearch()) {
                let data = {
                    "type": "B2C",
                    "from": moment(fromDate).format("DD-MMM-YYYY"),
                    "to": moment(toDate).format("DD-MMM-YYYY")
                }
                setShowLoader(true);
                setSubmitting(true);
                setShowActivationTable(false);
                setActivationDetails([]);
                axios.post(`${config.url().baseURL}/get-tabib-transactions`, data)
                    .then((resp) => {
                        setSubmitting(false);
                        setShowLoader(false);
                        setShowActivationTable(true)
                        let resData = resp.data;
                        if (resData.err) {
                            openSnackBar(resp.data.msg)
                        }
                        else {
                            let updatedActivationDetails = []
                            resp.data.data.map((activations) => {
                                let paymentStatus = activations.status;
                                let ackStatus = activations.ackStatus;
                                activations.timeStamp_created = `${moment(activations.timeStamp_created).format("DD/MM/YYYY")} ${moment(activations.timeStamp_created).format("hh:mm A")} `
                                if (paymentStatus) {
                                    activations.convertedStatus = <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="Success" />;
                                } else {
                                    activations.convertedStatus = <Chip size="small" style={{ backgroundColor: "red", color: "white" }} label="Failure" />;
                                }
                                if (paymentStatus == true && ackStatus == true) {
                                    activations.cardStatus = <Chip size="small" style={{ background: "green", color: "white" }} label="Activated" />
                                }
                                else {
                                    activations.cardStatus = <Chip size="small" style={{ background: "red", color: "white" }} label="Not Activated" />
                                }
                                activations.cardNo = activations.tabibData.cardNo;
                                activations.userType = activations.tabibData.userType;
                                activations.amount = Number((activations.amountDetail.Total_amount)).toFixed(3);
                                activations.cardType = activations.tabibData.cardType;
                                activations.cardref = activations.tabibData.cardRefNo;
                                activations.identifier = activations.tabibData.isPrimary ?
                                    <Chip size="small" style={{ background: "green", color: "white" }} label="Primary" /> :
                                    <Chip size="small" style={{ background: "Blue", color: "white" }} label="Family" />
                                activations.corporateName = activations.tabibData.corporateName ? activations.tabibData.corporateName : "";
                                updatedActivationDetails.push(activations);
                                activations.actions =
                                    <Tooltip placement='top' title="View Card Details">
                                        <IconButton
                                            onClick={() => openCardDetails(resp['data']['data'].find(ele => ele.transactionID == activations.transactionID))}
                                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                            aria-label="view">
                                            <CreditCardIcon />
                                        </IconButton>
                                    </Tooltip>
                            })
                            setActivationDetails(updatedActivationDetails)
                            var downloadedData = resp.data.data.map((activations) => {
                                let temp = {};
                                temp.Card_No = activations.cardNo;
                                temp.card_Ref_No = activations.cardref;
                                temp.CPR = activations.cpr;
                                temp.Account_Category = activations.userType;
                                temp.Card_Type = activations.tabibData.cardType;
                                temp.Transaction_Status = activations.status;
                                temp.Transcation_ID = activations.transactionID;
                                temp.Amount = activations.amount
                                temp.Payment_Time = activations.timeStamp_created;
                                temp.Identifier = activations.tabibData.isPrimary ? "Primary" : "Family";
                                temp.corporate_Name = activations.corporateName;
                                return temp;
                            })
                            setCsvData(downloadedData);
                        }
                    }).catch((err) => {
                        openSnackBar('Unable To Process !!')
                    })
            }
        } catch (error) {
            openSnackBar("Unable to fetch Tabiib Card Reports.")
        }
    }
    const openCardDetails = (tranDetail) => {
        setOpenCardTransaction(tranDetail);
        setShowDetails(true);
    }
    return (
        <>
            {respAlert}
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={true}
                        style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>From Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setFromDate(event.target.value) }, onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setFromDate("")
                                                    openSnackBar("Please enter valid date.")
                                                } else {
                                                    setFromDate(event.target.value)
                                                }
                                            },
                                            value: fromDate,
                                        }}
                                    />
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>To Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setToDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setToDate("")
                                                    openSnackBar("Please enter valid date.")
                                                } else {
                                                    setToDate(event.target.value)
                                                }
                                            }, value: toDate,
                                        }}
                                    />
                                    {!submitting ? (
                                        <Button className={classes.addDeptButton}
                                            onClick={() => searchActivationsDetails()}
                                            size="lg" color="primary">
                                            <SearchIcon fontSize="medium" />
                                        </Button>
                                    ) : (
                                        <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
                                    )}
                                </Paper>
                            </GridItem>
                        </GridContainer>
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showActivationTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Tabiib Card Payment Reports</h4>
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={activationDetails} columns={columns} options={options} />
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.companyLogModal,
                }}
                open={showDetails}
                onClose={() => {
                    setShowDetails(false)
                }}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>Card Details</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <Collapse
                        in={!showDetails}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                    </Collapse>
                    <Collapse
                        in={showDetails}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Table
                            tableData={[
                                ["User ID", ":", openCardTransaction.userID],
                                ["Transaction ID", ":", openCardTransaction.transactionID],
                                ["Transaction Status", ":", openCardTransaction.status ? (<Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />) : (<Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />)],
                                ["CPR", ":", openCardTransaction.cpr],
                                ["Payment Type", ":", openCardTransaction.paymentType],
                                ["Corporate ID", ":", openCardTransaction.tabibData ? openCardTransaction.tabibData.corporateID : ""],
                                ["Corporate Name", ":", openCardTransaction.tabibData ? openCardTransaction.tabibData.corporateName : ""],

                            ]}
                        />
                    </Collapse>
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setShowDetails(false);
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </>
    );
}