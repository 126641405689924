import React, { useEffect } from "react";
import Table from "components/Table/Table.js";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import config from "config";
import { resolveTypeReferenceDirective } from "typescript";
const styles = (theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
        marginLeft: "700px",
    },
    docName: {
        fontSize: "18px !important",
        fontWeight: "800",
        marginBottom: "10px !important",
        marginTop: "10px !important",
        marginLeft:"20px"
    },
    headerStyle: {
        backgroundColor: '#144B85',
        padding: '10px',
        height: "5%",
        borderRadius: "6px",
    },
    headerTitle: {
        fontSize: '18px',
        fontWeight: '4500',
        color: "#fff",
        
    },
    headStyle: {
        position: 'relative',
        top: '20px',
        left: '-5%',
        // backgroundColor: 'red',
        color: 'rgb(0 0 0 / 90%)',
        padding: '10px',
        textAlign: 'left',
        //borderRadius: '10px',
        width: '80%',
        marginLeft: '10%',
        marginRight: '10%',
        marginBottom: '10%',
        borderRadius: '6px',
        background: '#001D8F',
        backgroundColor: "transparent",
        boxShadow: 'inset -6px -6px 12px transparent, inset 6px 6px 12px transparent',
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      float: "left",
      display: "inlineBlock",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1",
      },
  
    },
    // uploadButton: {
    //     marginBottom: "10px !important",
    //     marginTop: "10px !important",
    // },
    ...modalStyle(theme),
});
// const allItems = ["Option 1", "Option 2", "Option 3"]; // array of all items for dropdowns
const useStyles = makeStyles(styles);
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
export default function SmsSwitching () {
    const [showMigrate, setShowMigrate] = React.useState(true);
    // const [prioirty, setPriority] = React.useState('')
    const [openSnack, setOpenSnack] = React.useState(false);
    // const [respAlert, setRespAlert] = React.useState(null);
    const [allItems, setAllItems] = React.useState([]);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [selectedItem, setSelectedItem] = React.useState("");
    const [selectedItemTwo, setSelectedItemTwo] = React.useState("");
    const [snackBarType, setSnackBarType] = React.useState("error")
    const [dropdown2Items, setDropdown2Items] = React.useState(allItems);
    const override = css`
    display: block;
    margin:0;
    border-color: red;
  `;
    const hideAlert = () => {
        // setRespAlert(null);
    };
    // const handleChange = (e) => {
    //     setPriority(e.target.value);
    //   };
    function openSnackBar(msg,success) {
        setOpenSnack(true)
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        console.log(openSnackBar)
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }
    React.useEffect (()=>{
        prioirty()
    }, [])
    const prioirty= () => {
        axios.get(`${config.url().baseURL}/get-sms-priorities` )
        .then((res)=>{
            // console.log(res.data.data)
            let response = res.data.data
            setAllItems(response)
            const priority1 = response.find(vendor => vendor.priority === 1);
            const priority2 = response.find(vendor => vendor.priority === 2);
            setDropdown2Items(response.filter(item => (item._id != priority1._id)));
            //setDropdown2Items(res.data.data)
            setSelectedItem(priority1._id)
            // console.log(priority2._id)
            setSelectedItemTwo(priority2._id)

        })
        .catch((err)=>{
        })
        }
    const dataSave = () =>{
        let data = {
            "priority1": selectedItem,
            "priority2": selectedItemTwo
        }
        axios.post(`${config.url().baseURL}/update-sms-priorities`,data,{
            headers: {
                auth: sessionStorage.getItem("cx_id"),
           },
        })
        .then((res)=>{
             if(res.data.err){
                 openSnackBar(res.data.msg )
             }
             else{
                openSnackBar(res.data.msg,"success")
                prioirty()
                // setSelectedItem("")
                // setDropdown2Items([])
             }
          console.log(data)
        })
        .catch((err)=>{
        })
    }
  
     const handleDropdown1Selection = (event) => {
        setSelectedItem(event.target.value);
        console.log(event.target.value)
        setDropdown2Items(allItems.filter(item => (item._id != event.target.value))); // filter out selected item from second dropdown
    };
    const handleDropdown2Selection = (event) => {
        setSelectedItemTwo(event.target.value);
        // setDropdown2Items(allItems.filter(item => item !== event.target.value)); // filter out selected item from first dropdown
    };
    const classes = useStyles();
    return (
        <div>
        {/* {console.log(dropdown2Items)}  */}
            {/* {console.log(selectedItemTwo)} */}
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>SMS SWITCHING </h4>
              </CardHeader>
              {/* <CardBody > */}
                <GridContainer>
                  <div>
                    
                                            <h3 className={classes.docName}>
                                                PRIORITY 1
                                            </h3>
                                            <div style={{marginLeft:"50px"}}>   
                                              <FormControl sx={{ m: 5, minWidth: 180}} size="large">
                                              {/* <InputLabel id="dropdown1-label">Dropdown 1</InputLabel> */}
                                              <Select
                                                //   labelId="dropdown1-label"
                                                //   id="dropdown1"
                                                  value={selectedItem}
                                                  onChange={handleDropdown1Selection}
                                              >
                                                  {
                                                  allItems.map((item, index) => (
                                                      <MenuItem key={index} value={item._id}>
                                                          {item.DisplayName}
                                                      </MenuItem>
                                                  ))}
                                              </Select>
                                            </FormControl>
                                            </div>
                                            <h3 className={classes.docName}>
                                                PRIORITY 2
                                            </h3>
                                            <div style={{marginLeft:"50px"}}>       
                                             <FormControl sx={{ m: 5, minWidth: 180 }} size="large">
                                             <Select
                                             value={selectedItemTwo}
                                            onChange={handleDropdown2Selection}
                                                 >
                                            {dropdown2Items.map((item, index) => (
                                            <MenuItem key={index} value={item._id}>
                                            {item.DisplayName}
                                           </MenuItem>
                                              ))}
                                               
                                           </Select>
                                           </FormControl>
                                           </div>
                                            <div className={classes.addButton}>
                                            <Button  variant="contained" color="primary" onClick={ dataSave }>
                                              Submit
                                            </Button>
                                            </div>
                               </div>
                                        
                                        
               </GridContainer>
              {/* </CardBody> */}
            </Card>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    );
                }









