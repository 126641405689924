import React from "react";
//import ProductForm from "./BfcBillerForm";
// VendorForm from "./VendorBIllerForm";
import EditBillerForm from "./EditBillerForm";
export default function MainForm({ type, back, snack, alertPop,billRef }) {
    let componentToRender;

    switch (type) {
        
        case "Edit":
            componentToRender = <EditBillerForm back={back} alertPop={alertPop} snack={snack}  billRef={billRef}/>;
            break;

        default:
            componentToRender=<></>
            break;
    }

    return (
        <div>
            {componentToRender}
        </div>
    );
}
