import * as React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Box from '@mui/material/Box';
import moment from 'moment';
// import { LicenseKey } from '@mui/x-license-pro';

export default function BasicDatePicker(props) {
    const [value, setValue] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null)
    const [endDate, setEndDate] = React.useState(null)
    const [maxDate, setMaxDate] = React.useState(null)
    React.useEffect(() => {
        if (props.FromTo.length !== 0) {
            setStartDate(props.FromTo[0])
            setEndDate(props.FromTo[1])
            setMaxDate(moment(props.FromTo[0]).add(30, 'd').format('YYYY-MM-DD'))
        } else {
            setStartDate(null);
            setEndDate(null)
            setMaxDate(null)
        }
    }, [props.FromTo])

    const handleDate = () => {
        const value = []
        value.push(startDate, endDate)
        props.dateSetter(value)
    }
    React.useEffect(() => {
        handleDate()
    }, [startDate, endDate])
    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            localeText={{ openPicker: 'Open calendar', dateFormat: 'dd/MM/yyyy' }}
        >
            <DatePicker
                value={startDate}
                label="Start Date"
                minDate={moment(new Date(), "MMMM D, YYYY").add(1, 'd')}
                maxDate={moment(new Date(), "MMMM D, YYYY").add(10, 'd')}
                disabled={props.flag}
                autoComplete="off"
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                    setStartDate(newValue);
                    setMaxDate(moment(newValue, "MMMM D, YYYY").add(30, 'd'))
                }}

                renderInput={(params) => <TextField {...params} style={{ minHeight: "60px !important", height: "60px !important", padding: "16.5px 14px" }} />}
            />
            <DatePicker
                value={endDate}

                label="End Date"
                minDate={startDate}
                maxDate={maxDate}
                disabled={props.flag}
                autoComplete="off"
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                    console.log(newValue)
                    setEndDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} style={{ minHeight: "60px !important", height: "60px !important", padding: "16.5px 14px" }} />}
            />
        </LocalizationProvider>
    );
}




