import React from "react";
import config from "config.js"
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import Divider from "@material-ui/core/Divider";
import Paper from '@material-ui/core/Paper';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import moment from "moment";
import JSONPretty from 'react-json-pretty';

const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
    },
    tabContent: {
        maxHeight: "60vh"
    },
    logPaper: {
        margin: "15px",
        padding: "15px"
    }
}));

export default function FullWidthTabs(props) {
    const classes = useStyles();
    const [newAvenuesSysLog, setNewAvenuesLog] = React.useState([]);
    const [noNewAvenuesSysLog, setNoNewAvenuesLog] = React.useState(false);

    React.useEffect(() => {
        if (props.userID !== null) {
            setNewAvenuesLog([]);
            setNoNewAvenuesLog(false);
            getNewAvenuesLogs(props.userID)
        } else {
            setNewAvenuesLog([]);
            setNoNewAvenuesLog(false);
        }
    }, []);



    const getNewAvenuesLogs = (id) => {
        //function to fetch netsys logs for a customer
        if (newAvenuesSysLog.length == 0) {
            axios.get(`${config.url().baseURL}/get-netsys-log-new/${id}/${0}`)
                .then((resp) => {
                    let respData = resp.data;
                    if (!respData.err) {
                        setNewAvenuesLog(respData.data)
                    } else {
                        setNewAvenuesLog([]);
                        setNoNewAvenuesLog(true)
                    }
                })
                .catch((err) => {
                    setNewAvenuesLog([]);
                    setNoNewAvenuesLog(true)
                });
        }
    }


    return (
        <div className={classes.root}>
            <Collapse
                in={noNewAvenuesSysLog}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <h4>No Log Found.</h4>
            </Collapse>
            <Collapse
                in={!noNewAvenuesSysLog}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <Collapse
                    in={newAvenuesSysLog.length == 0}
                    style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                    {...(true ? { timeout: 500 } : {})}>
                    <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                </Collapse>
                <Collapse
                    in={newAvenuesSysLog.length !== 0}
                    style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                    {...(true ? { timeout: 500 } : {})}>
                    <div className={classes.logContainer}>
                        {newAvenuesSysLog.map((log) => {
                            return (
                                <>
                                    <Paper elevation={3} className={classes.logPaper} >
                                        {log._id ? (
                                            <>
                                                Log ID :{log._id}
                                                <br />
                                            </>
                                        ) : null}
                                        {log.name ? (
                                            <>
                                                Log Type :{log.name}
                                                <br />
                                            </>
                                        ) : null}
                                        {log.timeStamp ? (
                                            <>
                                                Date : {moment(moment(new Date(log.timeStamp))).format("DD/MM/YYYY")}
                                                <br />
                                                Time :  {moment(moment(new Date(log.timeStamp))).format("hh:mm:ss A")}
                                                <br />
                                            </>
                                        ) : null}

                                        {log.url ? (
                                            <>
                                                URL : {log.url}
                                                <br />
                                            </>
                                        ) : null}
                                        {log.timeRequired ? (
                                            <>
                                                Duration : {log.timeRequired}
                                                <br />
                                            </>
                                        ) : null}
                                        {log.response ? (
                                            <Accordion  >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header">
                                                    <Typography>Response</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails  >
                                                    <div style={{ width: "750px !important" }}>
                                                        <JSONPretty data={log.response} />
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        ) : null}
                                        {log.request ? (
                                            <Accordion  >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header">
                                                    <Typography>Request</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails  >
                                                    <div style={{ width: "750px !important" }}>
                                                        <JSONPretty data={log.request} />
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        ) : null}
                                    </Paper>
                                    <br />
                                </>
                            );
                        })}
                    </div>
                </Collapse>
            </Collapse>
        </div >
    );
}
