import * as React from 'react';
import config from "config.js"
import axios from "axios";
import libs from "controllers/libs";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SimpleButton from '@mui/lab/LoadingButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from "assets/jss/material-dashboard-react/components/buttonStyle.js";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import { setStatus } from 'redux/actions/user';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Button from "components/CustomButtons/Button.js";
import ListAltIcon from "@material-ui/icons/ListAlt";

import { Document, Page } from 'react-pdf';
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import Dropzone from "react-dropzone";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete"
import VisibilityIcon from '@material-ui/icons/Visibility';

import Transactions from "./Transactions.js"
import { setRef } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({
    ...styles,
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    autocomplete: {
        marginTop: "27px"
    },
    ...modalStyle(theme)
}));

export default function NewAdjustmentForm(props) {
    const classes = useStyles();
    const [fileType, setFileType] = React.useState("FEE_ADJUSTMENT")
    const [corporateList, setCorporateList] = React.useState([])
    const [selectedCorporate, setSelectedCorporate] = React.useState({})
    const [cpr, setCpr] = React.useState("")
    const [transactionRefNo, setTransactionRefNo] = React.useState("")
    const [amount, setAmount] = React.useState("")
    const [originalAmount, setOriginalAmount] = React.useState("")
    const [accountDetails, setAccountDetails] = React.useState(null)
    const [submitting, setSubmitting] = React.useState(false)

    const [showTransactionsModal, setShowTransactionsModal] = React.useState(false);
    const [transactionModel, setTransactionModel] = React.useState(null);

    const [document, setDocument] = React.useState("");
    const [documentName, setDocumentName] = React.useState(null);
    const [openImageViewer, setOpenImageViewer] = React.useState(false);
    const [openPDFeViewer, setOpenPDFViewer] = React.useState(false);
    const [numPages, setNumPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [uploadButtonColour, setUploadButtonColour] = React.useState("primary")

    const [values, setValues] = React.useState({
        corporateErr: null,
        amountErr: null,
        cprErr: null
    });

    React.useEffect(() => {
        fetchCorporateList();
    }, []);

    const fetchCorporateList = async () => {
        //function to fetch corporate list
        try {
            let corporateResp = await axios.get(`${config.url().baseURL}/get-corp-list-fee-adj`)
            if (corporateResp.data.err) props.snack(corporateResp.data.msg)
            else setCorporateList(corporateResp.data.data)
        } catch (error) {
            props.snack("Unable to fetch corporate list.")
        }
    }

    const fetchCorporateAccountDetails = async (id) => {
        //function to fetch corporate account details
        try {
            setAccountDetails(null)
            let accountDetails = await axios.get(`${config.url().baseURL}/get-corp-account-details/${id}`)
            if (accountDetails.data.err) props.snack(accountDetails.data.msg)
            else setAccountDetails(accountDetails.data.data[0])
        } catch (error) {
            props.snack("Unable to fetch account details.")
        }
    }

    const verifyLength = (value, length) => {
        // function that verifies if a string has a given length or not
        if (value !== undefined && value.length >= length) {
            return true;
        }
        return false;
    };


    const validateForm = () => {
        //function to validate form data
        let flag = true
        let corporateErr = false, amountErr = false, cprErr = false, snackbarMsg = ""
        if ((fileType != "DISPUTE_REFUND") && (selectedCorporate._id == (null || undefined))) {
            corporateErr = true;
            snackbarMsg = "Please select corporate."
            flag = false
        }


        if (fileType == "DISPUTE_REFUND") {
            if (!verifyLength(cpr, 9)) {

                snackbarMsg = (snackbarMsg == "" ? "Please enter valid cpr." : snackbarMsg);
                flag = false
            }
            if (!verifyLength(transactionRefNo, 1)) {
                snackbarMsg = (snackbarMsg == "" ? "Please enter transaction reference no." : snackbarMsg);
                flag = false
            }
        }
        if (accountDetails == null) {
            snackbarMsg = (snackbarMsg == "" ? "Account data is not loaded, Please wait." : snackbarMsg);
            flag = false
        }
        if (!verifyLength(amount, 1)) {
            if (amount == 0) {
                amountErr = true;
                snackbarMsg = (snackbarMsg == "" ? "Please enter valid amount." : snackbarMsg);
                flag = false
            }
        }
        if (documentName == null) {
            setUploadButtonColour("danger")
            snackbarMsg = (snackbarMsg == "" ? "Please select document." : snackbarMsg);
            flag = false
        }
        setValues({ ...values, corporateErr, amountErr, cprErr });
        if (!flag) {
            props.snack(snackbarMsg)
        }
        return flag
    }

    const submitTransaction = async () => {
        //function to submit transaction
        if (validateForm()) {
            setSubmitting(true)
            try {
                let adjustmentData = {
                    TransactionFileType: fileType,
                    Customer: fileType == "DISPUTE_REFUND" ? cpr : selectedCorporate._id,
                    Account: accountDetails.AccountNumber,
                    Balance: accountDetails.AccountBalance,
                    SettlementAccount: accountDetails.SettlementAccount,
                    CurrencyCode: accountDetails.Currency,
                    Amount: amount,
                    Document: document,
                }
                let response = await axios.post(`${config.url().baseURL}/save-adjustment`, adjustmentData)
                if (response.data.err) {
                    props.snack(response.data.msg)
                    setSubmitting(false)
                }
                else {
                    props.openAlert("success", "Transaction Created", response.data.msg)
                }
            } catch (error) {
                console.log(error)
                props.snack("Unable to create transaction.",)
            }

        }
    }

    const idViewer = async (file) => {
        //function to view docs uploaded
        let extension = file.substring(file.indexOf("/") + 1, file.indexOf(";base64"));
        if (extension == "pdf") {
            setDocument(file)
            setOpenPDFViewer(true)
        } else {
            setDocument(file)
            setOpenImageViewer(true)
        }
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const adjustmentDocDrop = (images) => {
        //function to handle dropping of adjustment documents
        if (/\.(jpe?g|png|pdf)$/i.test(images[0].name) === true) {
            if (images[0].size < 2097152) {
                setUploadButtonColour("primary")
                libs.getBase64(images[0], (result) => {
                    setDocument(result);
                });
                setDocumentName(images[0].name);
            } else {
                props.snack("File size is too large, Maximum file size is 2 Mb.");
            }
        } else {
            props.snack("Please upload jpg,jpeg,png and pdf files");
        }
    }

    const deleteDoc = () => {
        setDocumentName("")
        setDocumentName(null)
    }

    const getUserDetails = async (cpr) => {
        //function to get customer details 
        if (cpr.length != 9) props.snack("Please enter valid cpr")
        else {
            try {
                let response = await axios.get(`${config.url().baseURL}/get-adjustment-customer/${cpr}`)
                if (response.data.err) {
                    props.snack(response.data.msg)
                    setAccountDetails({})
                }
                else {
                    setAccountDetails(response.data.data)
                    openTransactions()
                }
            } catch (error) {
                alert(error)
                props.snack("Unable to fetch customer details")
            }
        }
    }

    const closeTransactionModal = () => {
        setShowTransactionsModal(false);
        setTransactionModel(null)
    }

    const openTransactions = () => {
        //function to open transaction modal
        setTransactionModel(<Transactions cpr={cpr} getRefNo={getRefNo} />)
        setShowTransactionsModal(true)
    }

    const getRefNo = (ref, amount) => {
        setTransactionRefNo(ref)
        setAmount(amount)
        setOriginalAmount(parseFloat(amount));
        setShowTransactionsModal(false)
        setTimeout(function () {
            setTransactionModel(null)
        }, 1000);
    }

    return (
        <>
            <Card>
                <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>New Adjustment</h4>
                </CardHeader>
                <CardBody>
                    <Table
                        tableData={[
                            ["Transaction File Type", ":",
                                <FormControl fullWidth>
                                    <Select value={fileType} disableUnderline onChange={(e) => { setFileType(e.target.value); setAccountDetails({}); setCpr(null) }}>
                                        <MenuItem value="FEE_ADJUSTMENT">Fee Adjustment</MenuItem>
                                        <MenuItem value="TAX_ADJUSTMENT">Tax Adjustment</MenuItem>
                                        <MenuItem value="DISPUTE_REFUND">Dispute Refund</MenuItem>
                                    </Select>
                                </FormControl>
                            ],
                            ["Customer", ":",
                                fileType == "DISPUTE_REFUND" ?
                                    <>
                                        <TextField
                                            style={{ width: "90%" }}
                                            type="text"

                                            error={values.cprErr}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton onClick={(e) => getUserDetails(cpr)}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>,
                                            }}
                                            inputProps={{

                                                autoComplete: "off",
                                                value: cpr,
                                                onChange: (e) => {
                                                    setCpr(libs.validate(e.target.value, "number", 9) ? e.target.value : cpr);
                                                },
                                                onBlur: (e) => {
                                                    setCpr(e.target.value.trim())
                                                    if (e.target.value.length == 9) getUserDetails(e.target.value)
                                                    else setAccountDetails({})
                                                }
                                            }}
                                        />
                                        {accountDetails && accountDetails.AccountNumber ?
                                            <IconButton onClick={(e) => openTransactions(cpr)}>
                                                <ListAltIcon />
                                            </IconButton>
                                            : null
                                        }
                                    </>
                                    :
                                    <Autocomplete
                                        options={corporateList}
                                        value={selectedCorporate}
                                        getOptionLabel={(option) => option.corp}
                                        fullWidth
                                        onChange={(value, item) => {
                                            if (item !== null) {
                                                setSelectedCorporate(item);
                                                fetchCorporateAccountDetails(item._id)
                                                setValues({ ...values, corporateErr: false })
                                            }
                                        }}
                                        renderInput={(params) => <TextField error={values.corporateErr} fullWidth {...params} />}
                                    />
                            ],
                            ["Account", ":", accountDetails && accountDetails.AccountNumber ? accountDetails.AccountNumber : null],
                            ["Balance", ":", accountDetails && accountDetails.AccountBalance !== (null || undefined) ? accountDetails.AccountBalance : null],
                            ["Fee settlement Account", ":", accountDetails && accountDetails.SettlementAccount ? accountDetails.SettlementAccount : null],
                            ["Currency Code", ":", accountDetails && accountDetails.Currency ? accountDetails.Currency : null],
                            ["Reference No", ":", fileType == "DISPUTE_REFUND" ? transactionRefNo : "Not Applicable"],
                            ["Amount", ":",
                                fileType == "DISPUTE_REFUND" ?
                                    <TextField
                                        type="text"
                                        // fullWidth

                                        style={{ width: "90%" }}
                                        error={values.amountErr}
                                        inputProps={{
                                            autoComplete: "off",
                                            value: amount,
                                            onChange: (e) => {
                                                const value = e.target.value;
                                                const re = /^\d*\.?\d*$/;
                                                if ((value === "" || re.test(value)) && value <= originalAmount) {
                                                    setAmount(e.target.value)
                                                }
                                                setValues({ ...values, amountErr: false })
                                            },
                                            onBlur: (e) => {
                                                let data = e.target.value.trim()
                                                if (data) {
                                                    setAmount(parseFloat(data))
                                                } else {
                                                    setAmount(data)
                                                }
                                            }
                                        }}
                                    />
                                    :
                                    <TextField
                                        type="text"
                                        // fullWidth

                                        style={{ width: "90%" }}
                                        error={values.amountErr}
                                        inputProps={{
                                            autoComplete: "off",
                                            value: amount,
                                            onChange: (e) => {
                                                const value = e.target.value;
                                                const re = /^\d*\.?\d*$/;
                                                if ((value === "" || re.test(value))) {
                                                    setAmount(e.target.value)
                                                }
                                                setValues({ ...values, amountErr: false })
                                            },
                                            onBlur: (e) => {
                                                let data = e.target.value.trim()
                                                if (data) {
                                                    setAmount(parseFloat(data))
                                                } else {
                                                    setAmount(data)
                                                }
                                            }
                                        }}
                                    />],
                            ["Document", ":", documentName ? (<p style={{ width: "400px" }} >{documentName}   <Tooltip placement="right" title="Delete">
                                <IconButton
                                    onClick={() => deleteDoc()}
                                    style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                                    aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                                <Tooltip placement="bottom" title="View Document">
                                    <IconButton
                                        onClick={() => idViewer(document)}
                                        style={{ marginTop: "-10px", marginBottom: "-10px", color: "#014FA4" }}
                                        aria-label="delete">
                                        <VisibilityIcon />
                                    </IconButton>
                                </Tooltip></p>)
                                :
                                (<p style={{ width: "400px", margin: "-5px" }}>
                                    <Dropzone onDrop={adjustmentDocDrop}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <Button size="sm" color={uploadButtonColour}>
                                                    Upload Document
                                                </Button>
                                            </div>
                                        )}
                                    </Dropzone>
                                </p>
                                )

                            ]
                        ]}
                    />
                    <br />
                    <SimpleButton size="sm" simple color="primary" onClick={() => props.back()}><ArrowBackIcon />Back</SimpleButton> <SimpleButton color="primary" loading={submitting} variant="contained" onClick={submitTransaction}>Save Transaction</SimpleButton>
                </CardBody>
            </Card>
            <Dialog
                style={{ textAlign: "center" }}
                open={openPDFeViewer}
                TransitionComponent={Transition}
                onClose={() => {
                    setOpenPDFViewer(false);

                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <Document
                        file={document}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <p>Page {pageNumber} of {numPages}</p>
                    <SimpleButton size="sm" color="primary" onClick={() => {
                        if (pageNumber < 2) {
                            setPageNumber(1)
                        } else {
                            let newPageNumber = pageNumber - 1
                            setPageNumber(newPageNumber)
                        }
                    }} >Pervious</SimpleButton>
                    <SimpleButton size="sm" color="primary"
                        onClick={() => {
                            if (numPages > pageNumber) {
                                let newPageNumber = pageNumber + 1
                                setPageNumber(newPageNumber)
                            } else {
                                setPageNumber(numPages)
                            }
                        }}
                    >Next</SimpleButton>
                </DialogContent>
            </Dialog>
            <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.transactionsModal, }}
                open={showTransactionsModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    closeTransactionModal()
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
                    {transactionModel}
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            closeTransactionModal()
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {
                openImageViewer && (
                    <Lightbox
                        image={document}
                        onClose={() => setOpenImageViewer(false)}
                    />
                )
            }
        </>
    );
}