import React from "react";
import config from "config.js"
import Dropzone from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import Button from "components/CustomButtons/Button.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import axios from "axios";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from "@material-ui/core/Chip";
import { title } from "assets/jss/material-dashboard-react";
import SweetAlert from 'react-bootstrap-sweetalert';
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import { ThreeDots } from 'react-loader-spinner';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Lightbox from 'react-image-lightbox';
import { Document, Page } from 'react-pdf';
import TimelineIcon from '@mui/icons-material/Timeline';
import moment from 'moment'
import DialogTitle from "@material-ui/core/DialogTitle";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'; import DialogActions from "@material-ui/core/DialogActions";
import MuiTable from '@material-ui/core/Table';




const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
  
`;

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        textAlign: "center"
    },
    sideHead: {
        fontWeight: "500",
        fontSize: "14px",
        marginBottom: "0px !important",
        marginTop: "25px !important",
        color: "#474747",
    },
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },
    loaderWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Adjust the height as per your requirement
    },

    ...modalStyle(theme),

}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const options = {
    exportButton: false,
    showTitle: false,
};


const logColumns = [
    { id: 'date', label: 'Date', width: 70 },
    { id: 'updatedAt', label: 'Time', width: 70 },
    { id: 'user', label: 'User' },
    { id: 'status', label: 'Status', width: 70 },
    { id: 'status_message', label: 'Status Info', minWidth: 170 },
    // { id: 'remarks', label: 'Remarks', minWidth: 170 },
];

const columns = [
    {
        field: "name",
        title: "Full Name",
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        field: "designation",
        title: "Designation",
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        field: "cpr",
        title: "C P R",
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        field: "email",
        title: "Email",
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        field: "mobile",
        title: "Mobile",
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        field: "landline",
        title: "Landline",
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        field: "login_enabled",
        title: "Login Enabled",
        options: {
            filter: false,
            sort: false,
        },
    },


];

export default function FeeEditForm(props) {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [showLorAdd, setShowLorAdd] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [serviceFee, setServiceFee] = React.useState("");
    const [lorData, setLorData] = React.useState([]);
    const [lorName, setLorName] = React.useState("");
    const [lorDesignation, setLorDesignation] = React.useState("");
    const [lorCPR, setLorCPR] = React.useState("");
    const [lorEmail, setLorEmail] = React.useState("");
    const [lorMobile, setLorMobile] = React.useState("");
    const [lorLandLine, setLorLandLine] = React.useState("");
    const [lorIDProof, setLorIDProof] = React.useState("");
    const [lorIDProofFileName, setLorIDProofFileName] = React.useState("");
    const [lorUpButtonColor, setLorUpButtonColor] = React.useState("secondary");

    const [agreement, setAgreement] = React.useState("");
    const [agreementName, setAgreementName] = React.useState("");
    const [agreementBtnClr, setAgreementBtnClr] = React.useState("secondary");
    const [respAlert, setRespAlert] = React.useState(null);

    const [dialogueButtons, setDialogueButtons] = React.useState("");
    const [addButton, setAddButton] = React.useState("");
    const [showButtons, setShowButtons] = React.useState(true);
    const [documentViewerData, setDocumentViewerData] = React.useState("");
    const [openImageViewer, setOpenImageViewer] = React.useState(false);
    const [openPDFeViewer, setOpenPDFViewer] = React.useState(false);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [numPages, setNumPages] = React.useState(null);


    const [onBoardingLogs, setOnBoardingLogs] = React.useState([]);
    const [onBoardingLogModal, setOnBoardingLogModal] = React.useState(false);


    // const [documentViewerData, setDocumentViewerData] = React.useState("");


    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="down" ref={ref} {...props} />;
    });


    const [values, setValues] = React.useState({
        serviceFeeErr: null,
        bankNameErr: null,
        accountNameErr: null,
        accountNumberErr: null,
        feeTypeErr: null,
        serviceFeeTypeErr: null
    });

    const [submitting, setSubmitting] = React.useState(false);




    React.useEffect(() => {
        console.log(props)
        if (props.corpID !== null) {
            setServiceFee("")
            fetchLorDetails(props.corpID)
        }
    }, [props.corpID, props.status]);

    const fetchLorDetails = (id) => {
        //function to fetch company details
        setLorData([])
        axios.get(`${config.url().baseURL}/get-legal-reps/${id}`).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                let service = user.services.find(service => service.name == "Corporate LOR Edit")
                if (service.type == "maker") {
                    setAddButton(<Button color="primary" size="lg" simple onClick={() => setShowLorAdd(true)}>
                        Add new lor
                    </Button>)
                    setDialogueButtons(<Button onClick={() => { closeModal() }} color="danger" size="sm"> Close </Button>)
                }
                if (service.type === "checker" && respData.data.corpLorEditStatus == "Updated") {
                    setAddButton("");
                    setDialogueButtons(
                        <>
                            {showButtons && (
                                <>
                                    <Button onClick={() => { changeStatus("Recheck") }} color="danger" size="sm">Recheck</Button>
                                    <Button color="primary" onClick={() => { changeStatus("Approved") }} size="sm">Approve</Button>
                                </>
                            )}
                        </>
                    );
                } else {
                    setDialogueButtons(
                        <Button onClick={() => { closeModal() }} color="danger" size="sm"> Close </Button>
                    );
                }

                setLorData(respData.data.lorData)
            } else {
                openSnackBar(respData.msg);
                closeModal()
            }
        }).catch((err) => {

            closeModal()
            openSnackBar("Unable to process.");
        });

    };

    const enableBtnstatus = (lor) => {
        setRespAlert(
            <SweetAlert
                warning
                style={{ display: "block" }}
                title="Do you want to enable?"
                onConfirm={() => {
                    enableStatus(lor);
                }}
                onCancel={() => {
                    setRespAlert(null)
                }}
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                cancelBtnCssClass={classes.button}
                confirmBtnCssClass={classes.button + " " + classes.danger} api
            >
            </SweetAlert>
        );
    };

    const handleCloseLightbox = () => {
        setOpenImageViewer(false);
    };

    const enableStatus = (lor) => {
        const payload = {
            lorId: lor._id,
            companyId: props.corpID,
            status: "Requested",
            user: user._id,
        };

        axios.post(`${config.url().baseURL}/enable-legal-rep`, payload)
            .then((resp) => {
                let respData = resp.data;
                if (!respData.error) {
                    setRespAlert(
                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title={respData.msg}
                            onConfirm={() => {
                                setRespAlert(null);
                                closeModal()
                            }}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                    );
                } else {
                    openSnackBar(respData.msg);
                }
            });
    };

    const remark = remark;

    const approveStatus = (lor) => {

        const payload = {
            lorId: lor._id,
            companyId: props.corpID,
            status: "Approved",
            user: user._id,
            remark: remark,
        };

        axios.post(`${config.url().baseURL}/enable-legal-rep`, payload)

            .then((resp) => {
                let respData = resp.data
                if (!respData.error) {
                    setRespAlert(

                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title={respData.msg}
                            onConfirm={() => {
                                setRespAlert();
                                closeModal()
                            }}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                    );

                } else {
                    openSnackBar(respData.msg)

                }
            })
    }
    const changeStatus = (status) => {


        axios.post(`${config.url().baseURL}/approve-legal-rep/${props.corpID}`, { status: status, user: user._id }, {
            headers: {
                auth: sessionStorage.getItem("cx_id"),
            },
        })
            .then((resp) => {
                let respData = resp.data
                if (!respData.error) {
                    setRespAlert(

                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title={respData.msg}
                            onConfirm={() => {
                                setRespAlert();
                                setShowButtons(false)
                                // props.fetchCorporateData()
                                closeModal()
                            }}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                    );

                } else {
                    openSnackBar(respData.msg)

                }
            })
            .catch((err) => {
                openSnackBar("Unable to process.");
            });
    }

    const verifyLength = (value, length) => {
        // function that verifies if a string has a given length or not
        if (value.length >= length) {
            return true;
        }
        return false;
    };

    const verifyEmail = (value) => {
        // function that returns true if value is email, false otherwise
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    };

    const verify = (value, type, errVariable, length) => {
        switch (type) {
            case "email":
                if (verifyEmail(value)) {
                    setValues({ ...values, [errVariable]: false });
                } else {
                    setValues({ ...values, [errVariable]: true });
                }
                break;
            case "length":
                if (verifyLength(value, length)) {
                    setValues({ ...values, [errVariable]: false });
                } else {
                    setValues({ ...values, [errVariable]: true });
                }
                break;
            case "cpr":
                if (value.length <= 9) {
                    const re = /^[0-9\b]+$/;
                    if (value === "" || re.test(value)) {
                        if (verifyLength(value, length)) {
                            setLorCPR(value);
                            setValues({ ...values, [errVariable]: false });
                        } else {
                            setLorCPR(value);
                            setValues({ ...values, [errVariable]: true });
                        }
                    }
                }
            default:
                break;
        }
    };



    function openSnackBar(msg) {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const closeModal = () => {
        //function to close modal
        setServiceFee("")
        props.close()
    }

    const findExistingCPR = (cpr) => {
        //function to find cpr is already added
        if (lorData.find(lor => lor.cpr == cpr) !== undefined) {
            openSnackBar("Person with same cpr exist")
            setValues({ ...values, lorCPRErr: true });
        }
    }

    const findExistingMobile = (mobile) => {
        //function to find cpr is already added
        if (lorData.find(lor => lor.mobile == mobile) !== undefined) {
            openSnackBar("Person with same mobile no exist")
            setValues({ ...values, lorMobileErr: true });
        }
    }

    const findExistingEmail = (email) => {
        //function to find cpr is already added
        if (lorData.find(lor => lor.email == email) !== undefined) {
            openSnackBar("Person with same email exist")
            setValues({ ...values, lorEmailErr: true });
        }
    }
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }


    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    const uploadLorID = async (images) => {
        if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
            if (images[0].size < 10000000) {
                getBase64(images[0], (result) => {
                    setLorIDProof(result);
                });
                setLorUpButtonColor("secondary");
                setLorIDProofFileName(images[0].name);
            } else {
                openSnackBar("File size is too large.");
            }
        } else {
            openSnackBar("Please upload jpg,jpeg,png  and pdf files");
        }
    };

    const cancelAddLor = () => {
        //function to cancel adding lor
        setShowLorAdd(false);
        setLorName("");
        setLorDesignation("");
        setLorCPR("");
        setLorEmail("");
        setLorMobile("");
        setLorLandLine("");
        setLorIDProof("");
        setLorIDProofFileName("");
        setAgreement("");
        setAgreementName("");
    };
    const validateLor = () => {
        //function to validate contact
        let flag = true;
        let lorNameErr = false,
            lorDesignationErr = false,
            lorCPRErr = false,
            lorEmailErr = false,
            lorMobileErr = false,
            lorLandlineErr = false;

        if (!verifyLength(lorName, 2)) {
            lorNameErr = true;
            flag = false;
        }
        if (!verifyLength(lorDesignation, 2)) {
            lorDesignationErr = true;
            flag = false;
        }
        if (!verifyLength(lorCPR, 9)) {
            lorCPRErr = true;
            flag = false;
        }
        if (!verifyEmail(lorEmail)) {
            lorEmailErr = true;
            flag = false;
        }
        if (!verifyLength(lorMobile, 8)) {
            lorMobileErr = true;
            flag = false;
        }
        if (!props.edit) {
            if (!verifyLength(lorIDProofFileName, 1)) {
                setLorUpButtonColor("danger")
                flag = false;
            }
        }

        if (values.lorCPRErr) {
            lorCPR = true
            flag = false
        }
        if (values.lorMobileErr) {
            lorMobile = true
            flag = false
        }
        if (values.lorEmailErr) {
            lorEmail = true
            flag = false
        }
        // if (!verifyLength(agreementName, 2)) {
        //     setAgreementBtnClr("danger")
        //     flag = false
        // }

        setValues({
            ...values,
            lorNameErr: lorNameErr,
            lorDesignationErr: lorDesignationErr,
            lorCPRErr: lorCPRErr,
            lorEmailErr: lorEmailErr,
            lorMobileErr: lorMobileErr,
            lorLandlineErr: lorLandlineErr
        });
        return flag;
    };
    const addLor = () => {
        //function to add lor to db
        if (validateLor()) {
            let newLorData = {
                name: lorName,
                designation: lorDesignation,
                cpr: lorCPR,
                mobile: lorMobile,
                landline: lorLandLine,
                email: lorEmail,
                id_proof: lorIDProof,
                user: user._id,
                // agreement: agreement
            }
            setSubmitting(true);
            axios.post(`${config.url().baseURL}/add-legal-rep/${props.corpID}`, newLorData,
                {
                    headers: {
                        auth: sessionStorage.getItem("cx_id"),
                    },
                }).then((resp) => {
                    setSubmitting(false);
                    let respData = resp.data;
                    if (!respData.err) {
                        fetchLorDetails(props.corpID)
                        cancelAddLor()
                    } else {
                        openSnackBar(respData.msg);
                    }
                })
                .catch((err) => {

                    closeModal()
                    openSnackBar("Unable to process.");
                });
        } else {
            openSnackBar("Please fill all LOR details.");
        }

    }

    const deleteLOR = (id) => {
        //function to mark delete lor
        let data = {
            lrid: id
        }
        axios.post(`${config.url().baseURL}/mark-legal-rep/${props.corpID}`, data)
            .then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    fetchLorDetails(props.corpID)
                } else {
                    openSnackBar(respData.msg);
                }
            })
            .catch((err) => {
                alert(err)
                closeModal()
                openSnackBar("Unable to process.");
            });
    }


    const agreementDrop = async (images) => {
        if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
            if (images[0].size < 2000000) {
                getBase64(images[0], (result) => {
                    setAgreement(result);
                });
                setAgreementBtnClr("secondary");
                setAgreementName(images[0].name);
            } else {
                openSnackBar("File size is too large.");
            }
        } else {
            openSnackBar("Please upload jpg,jpeg and png files");
        }
    };

    const enableLorLogin = (id) => {
        //function to enable lor login
        let data = {
            lrid: id
        }
        axios.post(`${config.url().baseURL}/enable-lr-login/${props.corpID}`, data)
            .then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    fetchLorDetails(props.corpID)
                } else {
                    openSnackBar(respData.msg);
                }
            })
            .catch((err) => {
                alert(err)
                closeModal()
                openSnackBar("Unable to process.");
            });
    }

    const fetchDoc = (id_proof) => {
        let data = {
            id_proof,
        };
        axios
            .post(`${config.url().baseURL}/get-company-docs-id/${id_proof}`)
            .then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    if (respData.data.Content) {
                        let extension = respData.data.Content.substring(
                            respData.data.Content.indexOf('/') + 1,
                            respData.data.Content.indexOf(';base64')
                        );
                        setDocumentViewerData(respData.data.Content, respData.data.DocumentType);
                        setOpenSnack(false);
                        if (extension === 'pdf') {
                            setOpenPDFViewer(true);
                            setOpenImageViewer(false);
                        } else {
                            setOpenImageViewer(true);
                            //closeModal();
                            setOpenPDFViewer(false);
                        }
                    } else {
                        openSnackBar('Document not found.');
                    }
                } else {
                    setDocumentViewerData(null);
                    openSnackBar(respData.msg);
                }
            })
            .catch((er) => {
                console.log(er)
                // Handle error
            });
    };
    const fetchEnableLor = (_id) => {
        try {
            axios.get(`${config.url().baseURL}/get-corporate-lor-enable-logs/${_id}`).then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    if (respData?.log) {
                        setOnBoardingLogs(respData.log);
                        setOnBoardingLogModal(true)

                    } else {
                        setOnBoardingLogModal(false);
                        openSnackBar("No Log found.")
                    }
                } else {
                    setOnBoardingLogModal(false);
                    openSnackBar(respData.msg);
                }

            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div >

            {/* {(!openImageViewer && !openPDFeViewer) &&  */}
            <div className={classes.root}>
                <Collapse
                    in={lorData.length == 0}
                    style={{ transformOrigin: "0 0 0", textAlign: "center", height: "100px" }}
                    {...(true ? { timeout: 500 } : {})}>
                    <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                </Collapse>
                <Collapse
                    in={lorData.length !== 0}
                    style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                    {...(true ? { timeout: 500 } : {})}>
                    <Paper style={{ marginLeft: "25px", marginRight: "25px", marginBottom: "10px", paddingLeft: "15px", paddingRight: "15px" }} elevation={0}>
                        <GridContainer>
                            <GridItem sm="12" md="2">
                                <p> Name</p>
                            </GridItem>
                            <GridItem sm="12" md="2">
                                <p> Designation</p>
                            </GridItem>
                            <GridItem sm="12" md="2">
                                <p> CPR</p>
                            </GridItem>
                            <GridItem sm="12" md="3">
                                <p> Email</p>
                            </GridItem>
                            <GridItem sm="12" md="2">
                                <p> Mobile</p>
                            </GridItem>
                            <GridItem sm="12" md="1">
                                <p> Actions</p>
                            </GridItem>

                        </GridContainer>
                    </Paper>
                    {
                        lorData.map((lor, key) => {
                            let action = null
                            let service = user.services.find(service => service.name == "Corporate LOR Edit")
                            if (!lor.LRID && (lor.createStatus == "Created" || lor.createStatus == "Rechecked")) {
                                action = (
                                    <>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <IconButton style={{}}>
                                                <VisibilityIcon onClick={() => fetchDoc(lor.id_proof)} />
                                            </IconButton>
                                            {lor.createStatus === "Rechecked" ? (
                                                <Chip
                                                    size="small"
                                                    style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "90px" }}
                                                    label="Recheck"
                                                />
                                            ) : (
                                                <Chip
                                                    size="small"
                                                    style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "90px" }}
                                                    label="New"
                                                />
                                            )}
                                        </div>
                                    </>

                                );
                            } else if (lor.LRID && !lor.subjectTodelete && service.type === "maker") {
                                action = (

                                    <>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <IconButton style={{}}>
                                                <VisibilityIcon onClick={() => fetchDoc(lor.id_proof)} />
                                            </IconButton>
                                            {
                                                (lor.enableStatus !== "Requested" && lor.login_enabled === false) &&

                                                <Button variant="contained" style={{ width: '10px', height: '30px', background: '#004fa4' }} onClick={() => { enableBtnstatus(lor, "Reuested") }}>                                            Enable
                                                </Button>
                                            }
                                            {respAlert}
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <IconButton style={{}}>
                                                <TimelineIcon onClick={() => fetchEnableLor(lor._id)} />
                                            </IconButton>
                                            {/* <Chip size="small" style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "90px" }} label="New" /> */}
                                        </div>
                                    </>
                                );
                            } else if (lor.LRID && !lor.subjectTodelete && service.type === "checker") {
                                action = (
                                    <>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <IconButton style={{}}>
                                                <VisibilityIcon onClick={() => fetchDoc(lor.id_proof)} />
                                            </IconButton>
                                            {
                                                (lor.enableStatus === "Requested" && lor.login_enabled === false) &&

                                                <Button variant="contained" style={{ width: '10px', height: '30px', background: '#004fa4' }} onClick={() => { approveStatus(lor) }}>
                                                    Approve
                                                </Button>
                                            }
                                            {respAlert}
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <IconButton style={{}}>
                                                    <TimelineIcon onClick={() => fetchEnableLor(lor._id)} />
                                                </IconButton>
                                                {/* <Chip size="small" style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "90px" }} label="New" /> */}
                                            </div>
                                        </div>
                                    </>
                                );
                            } else if (lor.LRID && lor.subjectTodelete) {
                                action = <Chip size="small" style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "90px", marginTop: "15px" }} label="Deleted" />;
                            } else {
                                action = null;
                            }
                            return (
                                <Paper style={{ marginLeft: "25px", marginRight: "25px", paddingLeft: "15px", paddingRight: "15px", marginBottom: "10px" }} elevation={3}>
                                    <GridContainer>
                                        <GridItem sm="12" md="2">
                                            <p> {lor.name}</p>
                                        </GridItem>
                                        <GridItem sm="12" md="2">
                                            <p> {lor.designation}</p>
                                        </GridItem>
                                        <GridItem sm="12" md="2">
                                            <p> {lor.cpr}</p>
                                        </GridItem>
                                        <GridItem sm="12" md="3">
                                            <p> {lor.email}</p>
                                        </GridItem>
                                        <GridItem sm="12" md="2">
                                            <p> {lor.mobile}</p>
                                        </GridItem>

                                        <GridItem sm="12" md="1">
                                            {action}

                                        </GridItem>
                                    </GridContainer>
                                </Paper>


                            )
                        })
                    }

                    {/* <MaterialTable
                    components={{
                        Container: (props) => <Paper {...props} elevation={0} />,
                    }}
                    data={lorData}
                    columns={columns}
                    options={options}

                /> */}

                    {!showLorAdd ? (
                        <div style={{ textAlign: "center" }}>
                            {addButton}
                        </div>
                    ) : null}
                    <Collapse
                        in={showLorAdd}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <br />
                        <Paper style={{ marginLeft: "25px", marginRight: "25px", paddingLeft: "15px", paddingRight: "15px" }} elevation={0} >
                            <GridContainer>
                                <GridItem xs={12} sm={3}>
                                    <CustomInput
                                        error={values.lorNameErr}
                                        labelText={
                                            <span>
                                                Name <small className={classes.required}>*</small>
                                            </span>
                                        }
                                        id="lor-name"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            autoComplete: "off",
                                            value: lorName,
                                            onChange: (e) => {
                                                setLorName(e.target.value);
                                                verify(e.target.value, "length", "lorNameErr", 2);
                                            },
                                            onBlur: (e) => {
                                                setLorName(e.target.value.trim());
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <CustomInput
                                        error={values.lorDesignationErr}
                                        labelText={
                                            <span>
                                                Designation <small className={classes.required}>*</small>
                                            </span>
                                        }
                                        id="lor-designation"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            autoComplete: "off",
                                            value: lorDesignation,
                                            onChange: (e) => {
                                                setLorDesignation(e.target.value);
                                                verify(e.target.value, "length", "lorDesignationErr", 2);
                                            },
                                            onBlur: (e) => {
                                                setLorDesignation(e.target.value.trim());
                                            }
                                        }}
                                    />
                                </GridItem>



                                <GridItem xs={12} sm={3}>
                                    <CustomInput
                                        error={values.lorCPRErr}
                                        labelText={
                                            <span>
                                                CPR <small className={classes.required}>*</small>
                                            </span>
                                        }
                                        id="lor-cpr"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            autoComplete: "off",
                                            value: lorCPR,
                                            onChange: (e) => {
                                                verify(e.target.value, "cpr", "lorCPRErr", 9);
                                            },
                                            onBlur: (e) => {
                                                // findExistingCPR(e.target.value)
                                            },
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <CustomInput
                                        error={values.lorMobileErr}
                                        labelText={
                                            <span>
                                                Mobile <small className={classes.required}>*</small>
                                            </span>
                                        }
                                        id="lor-mobile"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            autoComplete: "off",
                                            value: lorMobile,
                                            onChange: (e) => {
                                                const re = /^[0-9\b]+$/;
                                                var res = e.target.value.substring(0, 1)
                                                if (res == 3 || res == 6 || e.target.value == "") {
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                        if (e.target.value.length <= 8) {
                                                            setLorMobile(e.target.value);
                                                        }
                                                    }
                                                }
                                                verify(e.target.value, "length", "lorMobileErr", 8);
                                            },
                                            onBlur: (e) => {
                                                // findExistingMobile(e.target.value)
                                            },
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <CustomInput
                                        error={values.lorLandlineErr}
                                        labelText={
                                            <span>
                                                LandLine
                                            </span>
                                        }
                                        id="lor-landline"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            autoComplete: "off",
                                            value: lorLandLine,
                                            onChange: (e) => {
                                                const re = /^[0-9\b]+$/;
                                                if (e.target.value === "" || re.test(e.target.value)) {
                                                    if (e.target.value.length <= 8) {
                                                        setLorLandLine(e.target.value);
                                                    }
                                                }
                                                if (e.target.value.length == 0) {
                                                    setValues({ ...values, lorLandlineErr: false })
                                                } else {
                                                    verify(e.target.value, "length", "lorLandlineErr", 8);
                                                }
                                            },
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={4}>
                                    <CustomInput
                                        error={values.lorEmailErr}
                                        labelText={
                                            <span>
                                                Email <small className={classes.required}>*</small>
                                            </span>
                                        }
                                        id="lor-email"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            autoComplete: "off",
                                            value: lorEmail,
                                            onChange: (e) => {
                                                setLorEmail(e.target.value);
                                                verify(e.target.value, "email", "lorEmailErr", 3);
                                            },
                                            onBlur: (e) => {
                                                setLorEmail(e.target.value.trim());
                                                // findExistingEmail(e.target.value)
                                            },
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2}>
                                    {lorIDProofFileName !== "" ? (
                                        <CustomInput
                                            labelText="Certified copy of identification"
                                            id="dir-id-proof"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                autoComplete: "off",
                                                readOnly: true,
                                                value: lorIDProofFileName,
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <br /> <br />
                                            <Dropzone onDrop={uploadLorID}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <Button size="sm" fullWidth color={lorUpButtonColor}>
                                                            ID Proof
                                                        </Button>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </>
                                    )}

                                </GridItem>
                                <Dialog
                                    classes={{
                                        root: classes.left + " " + classes.modalRoot,
                                        paper: classes.companyStatusLogModal,
                                    }}
                                    open={onBoardingLogModal}
                                    // TransitionComponent={Transition}
                                    keepMounted
                                    onClose={() => {
                                        setOnBoardingLogModal(false);
                                        setOnBoardingLogs([]);
                                    }}
                                    aria-labelledby="classic-modal-slide-title"
                                    aria-describedby="classic-modal-slide-description">
                                    <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                                        <h4 className={classes.modalTitle}>GPW user status log</h4>
                                    </DialogTitle>
                                    <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                                        <TableContainer className={classes.container}>
                                            <MuiTable stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        {logColumns.map((column) => (
                                                            <TableCell
                                                                key={column._id}
                                                                align={column.align}
                                                                style={{ minWidth: column.minWidth }}
                                                            >
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {onBoardingLogs.map((row, key) => {
                                                        let status = row.status
                                                        // if (row.status == "Step One Completed") {
                                                        //     status = "First Level Form Completed"
                                                        // } else if (row.status == "Step Two completed") {
                                                        //     status = "Second Level Form Completed"
                                                        // } else {
                                                        //     status = row.status
                                                        // }
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                                                                <TableCell>
                                                                    {moment(row.date).format("DD/MM/YYYY")}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {moment(row.date).format("HH:mm:ss")}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {row.user ? row.user.firstName + " " + row.user.lastName : null}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {status}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {row.status_message}
                                                                </TableCell>
                                                                {/* <TableCell>
                                                {row.remarks}
                                            </TableCell> */}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </MuiTable>
                                        </TableContainer>
                                    </DialogContent>
                                    <DialogActions className={classes.modalFooterCenter}>
                                        <Button
                                            onClick={() => {
                                                setOnBoardingLogModal(false);
                                                setOnBoardingLogs([]);
                                            }}
                                            color="danger"
                                            size="sm">
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog
                                    classes={{
                                        root: classes.center + " " + classes.modalRoot,
                                        // paper: classes.processModal,
                                    }}
                                    open={openPDFeViewer}
                                    // TransitionComponent={Transition}
                                    onClose={() => {
                                        setOpenPDFViewer(false);
                                        setDocumentViewerData("");
                                    }}
                                    aria-labelledby="classic-modal-slide-title"
                                    aria-describedby="classic-modal-slide-description">
                                    <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                                        <Document
                                            file={documentViewerData}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                        >
                                            <Page pageNumber={pageNumber} />
                                        </Document>
                                        <p>Page {pageNumber} of {numPages}</p>
                                        <Button size="sm" color="primary" onClick={() => {
                                            if (pageNumber < 2) {
                                                setPageNumber(1)
                                            } else {
                                                let newPageNumber = pageNumber - 1
                                                setPageNumber(newPageNumber)
                                            }
                                        }} >Pervious</Button>
                                        <Button size="sm" color="primary"
                                            onClick={() => {
                                                if (numPages > pageNumber) {
                                                    let newPageNumber = pageNumber + 1
                                                    setPageNumber(newPageNumber)
                                                } else {
                                                    setPageNumber(numPages)
                                                }
                                            }}
                                        >Next</Button>
                                    </DialogContent>
                                </Dialog>

                                <Dialog
                                    open={openImageViewer}
                                    onClose={() => {
                                        setOpenImageViewer(false);
                                        setDocumentViewerData("");
                                    }}
                                    style={{ backgroundColor: "transparent" }}
                                >
                                    <DialogContent id="classic-modal-slide-description" >
                                        <Lightbox
                                            mainSrc={documentViewerData}
                                            onCloseRequest={handleCloseLightbox}
                                            imageTitle="Image"
                                            reactModalStyle={{
                                                overlay: {
                                                    zIndex: 9999, // Adjust this as needed
                                                    backgroundColor: "transparent",
                                                    // Make the background transparent
                                                },
                                                content: {
                                                    top: "0",
                                                    left: "0",
                                                    right: "0",
                                                    bottom: "0",
                                                    border: "none",
                                                    borderRadius: "0",
                                                },
                                            }}
                                            enableZoom={true} // Enable zooming for large images
                                            enablePanzoom={true} // Enable panning for large images
                                            imageLoadErrorMessage="Failed to load image"
                                        />
                                    </DialogContent>
                                </Dialog>



                                <GridItem xs={12} sm={12} md={12}>

                                    <>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                            <Button
                                                color="primary"
                                                className={classes.addOwnerActions}
                                                size="sm"
                                                simple
                                                onClick={cancelAddLor}>
                                                Cancel
                                            </Button>

                                            {!submitting ? (
                                                <Button color="primary" className={classes.addOwnerActions} size="sm" onClick={addLor}>
                                                    Add
                                                </Button>
                                            ) : (
                                                <ThreeDots
                                                    height="10"
                                                    width="100"
                                                    radius="9"
                                                    color="#004fa4"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            )}
                                        </div>
                                    </>

                                </GridItem>
                            </GridContainer>
                        </Paper>
                    </Collapse>
                </Collapse>
                <div style={{ textAlign: "center" }}>

                    <br />
                    {dialogueButtons}
                </div>
                <Snackbar
                    className={classes.snackbar}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    open={openSnack}
                    autoHideDuration={6000}>
                    <Alert
                        onClose={() => {
                            setOpenSnack(false);
                            setSnackBarMsg("");
                        }}
                        severity="error">
                        {snackBarMsg}
                    </Alert>
                </Snackbar>

            </div >
            {/* } */}

            {/* <Dialog open={openImageViewer} onClose={()=>{setOpenImageViewer(false)}}>
                <DialogContent>
                <Lightbox
                    mainSrc={documentViewerData}
                    // setOpenImageViewer={openImageViewer}
                    onCloseRequest={handleCloseLightbox}
                />
                </DialogContent>
            </Dialog> */}





        </div >
    );
}
