import * as React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Collapse from '@material-ui/core/Collapse'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PropagateLoader from 'react-spinners/PropagateLoader'
import MUIDataTable from 'mui-datatables'
import { InputBase } from '@material-ui/core'
import moment from 'moment'
import { Paper } from '@material-ui/core'
import Snackbar from "@material-ui/core/Snackbar";
import BounceLoader from 'react-spinners/BounceLoader'
import JSONPretty from 'react-json-pretty';
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import SearchIcon from '@material-ui/icons/Search';
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import { creatCsvFile, downloadFile } from "download-csv";
import MuiAlert from "@material-ui/lab/Alert";
import config from "config.js"

const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    companyStatusLogModal12: {
        margin: "auto",
        minWidth: "800px",
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


export default function RewardReport() {
    const classes = useStyles();
    const [showLoader, setShowLoader] = useState(false);
    const [showRewardTbale, setShowRewardTable] = useState(true)
    const [fromDate, setFromDate] = useState(moment(new Date).format("YYYY-MM-DD"))
    const [toDate, setToDate] = useState(moment(new Date).format("YYYY-MM-DD"))
    const [openSnack, setOpenSnack] = useState(false)
    const [snackBarMsg, setSnackBarMsg] = useState("")
    const [snackBarType, setSnackBarType] = useState("error")
    const [csvData, setCsvData] = React.useState(false);
    const [respAlert, setRespAlert] = React.useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [rewardDetails, setRewardDetails] = useState([])
   
    const columns = [
        {
            name: "date",
            label: "Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "cpr",
            label: "CPR",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "campaignID",
            label: "Campaign ID",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "userID",
            label: "User ID",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "reward_value",
            label: "Rewards Value",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "reward_type",
            label: "Reward Type",
            options: {
                filter: true,
                sort: true
            }

        },

        {
            name: "eventName",
            label: "Event",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "refnum",
            label: "Refrence Number",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "reward_category",
            label: "Type",
            options: {
                filter: true,
                sort: true
            }
        },
        
    ]
    const options = {
        selectableRows: false,
        elevation: 0,
        print: false,
        onDownload: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = "Reward Report.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        }
    };
    React.useEffect(() => {
        searchRewardDetails();
    }, [])

    const validateSearch = () => {
        //function validate search params
        let snackBarMsg = ""
        let flag = true
        if (fromDate.length == 0) {
            flag = false
            snackBarMsg = "Please select from date."
        }
        if (toDate.length == 0) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select To date." : snackBarMsg);
        }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }

    const searchRewardDetails = () => {
        try {
            if (validateSearch()) {
                let data = {
                    "fromDate": moment(fromDate).format("MM/DD/YYYY"),
                    "toDate": moment(toDate).format("MM/DD/YYYY")
                }
                setShowLoader(true);
                setSubmitting(true);
                setShowRewardTable(false);
                setRewardDetails([])
                axios.post(`${config.url().baseURL}/get-flyy-transactions-report`, data)
                    .then((resp) => {
                        setShowLoader(false);
                        setSubmitting(false);
                        setShowRewardTable(true)
                        let resData = resp.data
                        if (resData.err) {
                            openSnackBar(resData.msg)
                        }
                        else {
                            let updatedDetails = []
                            resData.data.map((activations) => {
                        
                                activations.date = activations.time_reported ? moment(activations.time_reported).format("DD/MM/YYYY  hh:mm A") : ""
                                activations.cpr = activations.cpr ? activations.cpr : ""
                                activations.campaignID = activations.campaignID ? activations.campaignID :""
                                activations.userID = activations.userID ? activations.userID : ""                               
                                activations.reward_value = activations.reward_value ? activations.reward_value : ""                               
                                activations.reward_type = activations.reward_type ? activations.reward_type : ""
                                activations.eventName = activations.eventName ? activations.eventName : ""
                                activations.refnum = activations.refnum ? activations.refnum : ""

                               
                                updatedDetails.push(activations)
                            })
                            setRewardDetails(updatedDetails);
                            var downloadedData = resData.data.map((activations) => {
                                let temp = {};
                                temp.Date = activations.date;
                                temp.CPR = activations.cpr;
                                temp.CampaignID = activations.campaignID;
                                temp.UserId = activations.userID;
                                temp.RewardValue = activations.reward_value;
                                temp.RewardType = activations.reward_type;
                                temp.Event = activations.eventName;
                                temp.Reference_Number= activations.refnum;
                                temp.Type = activations.reward_category;
                                return temp;
                            })
                            setCsvData(downloadedData);
                        }
                     })
                    .catch((err) => {
                        openSnackBar("Unable to process")
                    })
            }
        }
        catch (error) {
            openSnackBar("Unable to fetch the details")

        }
    }

    
    function openSnackBar(msg, success) {
        setOpenSnack(true)
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    return (
        <div>{respAlert}
            <GridContainer justify='center'>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={true}
                        style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                        {...true ? { timeout: 500 } : {}}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>From Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setFromDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setFromDate("");
                                                    openSnackBar("Please enter a valid Date")
                                                } else {
                                                    setFromDate(event.target.value)
                                                }
                                            },
                                            value: fromDate,
                                        }}>
                                    </InputBase>
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>To Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setToDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setToDate("")
                                                    openSnackBar("Please enter valid date.")
                                                } else {
                                                    setToDate(event.target.value)
                                                }
                                            }, value: toDate,
                                        }}
                                    />
                                    {!submitting ? (
                                        <Button className={classes.addDeptButton}
                                            onClick={() => searchRewardDetails()}
                                            size="lg" color="primary">
                                            <SearchIcon fontSize="medium" />
                                        </Button>
                                    ) : (
                                        <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
                                    )}
                                </Paper>
                            </GridItem>
                        </GridContainer>
                    </Collapse>

                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{
                            transformOrigin: "0 0 0", textAlign: 'center'
                        }}
                        {...true ? { timeout: 500 } : {}}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showRewardTbale}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...true ? { timeout: 500 } : {}}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}> Rewards Report</h4>
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={rewardDetails} columns={columns} options={options} />
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    )
} 