import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import avatar from "assets/img/school.jpg";
import Collapse from "@material-ui/core/Collapse";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";

import libs from "controllers/libs.js"
import axios from "axios";
import config from "config";
import Dropzone from "react-dropzone";
import { Dialog, FormHelperText, MenuItem, Select } from "@material-ui/core";
import Switch from '@mui/material/Switch';


const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;



export default function SchoolForm(props) {
    const [billerName, setbillerName] = React.useState("")
    const [category, setcategory] = React.useState("")
    const [group, setgroup] = React.useState("")
    const [productName, setproductName] = React.useState("")
    const [issuerId, setissuerId] = React.useState("")
    const [issuerName, setissuerName] = React.useState("")
    const [logo, setLogo] = React.useState("")
    const [productCode, setproductCode] = React.useState("")
    const [values, setValues] = React.useState({ nameErr: null, });
    const [formView, setFormView] = React.useState(1); //0:-loader , 1:-school form
    const [submitting, setSubmitting] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [billerList, setBillerList] = React.useState(['BFC_Pay', 'PIE', 'Sadad', 'Other'])
    const [isProducts, setIsProducts] = React.useState(false)
    
    const validateFrom = () => {
        //function to validate form
        let nameErr = false, flag = true, msg = ""
        if (billerName.length == 0) {
            flag = false
            nameErr = true
            msg = "Please enter biller name."
        }
        if (!flag) {
            props.snack(msg)
        }
        setValues({ ...values, nameErr })
        return flag
    }
    const label = { inputProps: { 'aria-label': 'Switch demo' } };


    const uploadLogo = async (img) => {
        //function to upload image 
        if (/\.(jpe?g|png)$/i.test(img[0].name) === true) {
            if (img[0].size < 2097152) {
                libs.getBase64(img[0], (result) => { setLogo(result); });
            } else {
                props.snack("File size is too large, Maximum file size is 2 Mb.");
            }
        } else {
            props.snack("Please upload image files.");
        }
    }

    const submitData = async () => {
        //function to submit data
        setSubmitting(true)
        if (!validateFrom()) setSubmitting(false)
        else {
            try {
                const data = {
                    billRef: props.id,
                    value: {
                        name: billerName,
                        category,
                        issuerName,
                        // isActive,
                        issuerId,
                        type: props.billerType
                        // inHome,


                    }
                }

                const dataSubmit = {
                    data: {
                        billerName,
                        category,
                        issuerId,
                        issuerName,
                        productCode,
                        productName,
                        isProducts,
                        type: props.billerType
                    },
                    // address: {
                    //     flat,
                    //     road,
                    //     block
                    // },
                    // documents: {
                    //     licence,
                    //     aggrement,
                    //     moi,
                    //     cr
                    // }

                }
                let resp
                if (props.edit) resp = await axios.post(`${config.url().app_URL}/bill/update-category`, data)
                else resp = await axios.post(`${config.url().app_URL}/bill/create-category`, dataSubmit)
                if (resp.data.status.err) {
                    props.snack(props.data.status.message)
                    setSubmitting(false)
                }
                else {
                    setSubmitting(false)
                    props.alert("success", "biller data saved", resp.data.msg)
                }
            } catch (error) {
                console.log(error)
                setSubmitting(false)
                props.snack("Unable to save.")
            }
        }
    }

    const getSchoolDetails = async (id) => {
        //function to fetch school details 
        try {
            console.log(id);
            let resp = await axios.post(`${config.url().app_URL}/bill/get-category`, { "billRef": id })
            if (resp.data.err) props.snack(props.data.msg)
            else {
                let biller = resp.data.data
                setbillerName(biller.billerName ? biller.billerName : "")
                setcategory(biller.category ? biller.category : "")
                setgroup(biller.group ? biller.group : "")
                setproductName(biller.productName ? biller.productName : "")
                setissuerId(biller.issuerId ? biller.issuerId : "")
                setissuerName(biller.issuerName ? biller.issuerName : "")
                setLogo(biller.logo ? biller.logo : "")
                setproductCode(biller.productCode ? biller.productCode : "")
                setFormView(1)
                setIsProducts(biller.isProducts)
                getLogo(id)
            }
        } catch (error) {
            props.snack("Unable to fetch biller details.")
        }
    }

    //logo when its come use it
    const getLogo = async (id) => {
        //function to fetch school details 
        try {
            let resp = await axios.post(`${config.url().baseURL}/fetch-school-logo`, { "_id": id })
            if (resp.data.err) props.snack(props.data.msg)
            else {
                let school = resp.data.data
                setLogo(school.logo ? school.logo : "")

            }
        } catch (error) {
            // props.snack("Unable to fetch logo.")
        }
    }


    React.useEffect(() => {
        if (props.id) { setFormView(0); getSchoolDetails(props.id) }
        return (() => {
            setbillerName("")
            setcategory("")
            setgroup("")
            setproductName("")
            setissuerId("")
            setissuerName("")
            setLogo("")
            setproductCode("")

        })
    }, [])

    return (
        <div>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <Collapse
                        in={formView == 0}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                    <Collapse
                        in={formView == 1}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Card profile>
                            <CardAvatar profile>
                                {
                                    props.view ?
                                        <img src={logo ? logo : avatar} alt="..." /> :
                                        <Dropzone onDrop={uploadLogo}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                                        <img src={logo ? logo : avatar} alt="..." />
                                                    </a>
                                                </div>
                                            )}
                                        </Dropzone>
                                }

                            </CardAvatar>
                            <CardBody>

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Biller Name"
                                            id="biller Name`"
                                            error={values.nameErr}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                value: billerName,
                                                onChange: (e) => {
                                                    setbillerName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : billerName);
                                                    setValues({ ...values, nameErr: false })
                                                },
                                                onBlur: (e) => {
                                                    setbillerName(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <CustomInput
                                            labelText="category                                            "
                                            id="category"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                value: category,
                                                onChange: (e) => {
                                                    setcategory(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : category);
                                                },
                                                onBlur: (e) => {
                                                    setcategory(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <CustomInput
                                            labelText="group"
                                            id="group"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                value: group,
                                                onChange: (e) => {
                                                    setgroup(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : group);
                                                },
                                                onBlur: (e) => {
                                                    setgroup(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                    {!props.edit&& <CustomInput
                                            labelText="productName"
                                            id="productName"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                multiline: true,
                                                maxRows: 5,
                                                value: productName,
                                                onChange: (e) => {
                                                    setproductName(e.target.value);
                                                },
                                                onBlur: (e) => {
                                                    setproductName(e.target.value.trim());
                                                }
                                            }}
                                        />}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <CustomInput
                                            labelText="Issuer Id"
                                            id="productName"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                multiline: true,
                                                maxRows: 5,
                                                value: issuerId,
                                                onChange: (e) => {
                                                    setissuerId(e.target.value);
                                                },
                                                onBlur: (e) => {
                                                    // setproductName(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={7}>
                                        <CustomInput
                                            labelText="issuerName"
                                            id="issuerName"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                value: issuerName,
                                                onChange: (e) => {
                                                    setissuerName(libs.validate(e.target.value, "all", 150) ? e.target.value : issuerName);
                                                },
                                                onBlur: (e) => {
                                                    // setissuerName(libs.validate(e.target.value.trim(), "issuerName", 150) ? e.target.value : "")
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                    {!props.edit&& <CustomInput
                                            labelText="productCode"
                                            id="productCode"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                multiline: true,
                                                maxRows: 3,
                                                value: productCode,
                                                onChange: (e) => {
                                                    setproductCode(libs.validate(e.target.value, "all", 150) ? e.target.value : productCode);
                                                },
                                                onBlur: (e) => {
                                                    setproductCode(e.target.value.trim());
                                                }
                                            }}
                                        />}
                                    </GridItem>
                                    {!props.edit&&<GridItem xs={12} sm={12} md={6}>
                                        <p>Is Products</p>

                                        <Switch {...label} 
                                        defaultChecked={isProducts}
                                        checked={isProducts}
                                        onChange={e=>setIsProducts(e.target.checked)}
                                        

                                        
                                        />
                                        
                                        <FormHelperText id="username-helper"
                                        // error={selectBool}
                                        // style={{ paddingLeft: "41px" }}
                                        >
                                            {isProducts ? "Biller Is Now Have Sub Products" : "Biller Is Now Didn't have Sub Products"}
                                            {/* {helperText} */}
                                        </FormHelperText>
                                    </GridItem>}
                                </GridContainer>
                            </CardBody>
                            {
                                props.view ?
                                    <div style={{ textAlign: "center", marginBottom: "20px" }}>
                                        <Button simple onClick={() => props.back()} color="primary">Back</Button>
                                    </div> :
                                    <div style={{ textAlign: "center", marginBottom: "20px" }}>
                                        <Button simple onClick={() => props.back()} color="primary">Back</Button>  <Button disabled={submitting} onClick={submitData} color="primary">Save</Button>
                                    </div>
                            }
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>
        </div>
    );
}
