import {useState } from "react";
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Stack } from "@mui/material";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const title = {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '10px',
    color: '#3F51B5',
}
const body = {
    fontSize: '18px',
    textAlign: 'center',
    padding: '40px',
    color: '#555',
}
const button = {
    margin: '10px',
    backgroundColor: '#3F51B5',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#2F3F9E',
    }
}
const textField = {
    margin: '10px',
    width: '90%'
}
function BillerModal({ open, onClose, onConfirm }) {
    const [text, setText] = useState("");
    const handleConfirm = () => {
        if (text.trim() === "") {
            alert("Please enter a value before submitting");
            return;
        }
        onConfirm(text);
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={title}>
                    Add New Group
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }} style={body}>
                    <Stack direction="column">
                        <TextField style={textField} value={text} onChange={(e) => { setText(e.target.value) }} />
                        <div>
                            <Button style={button} onClick={onClose}>cancle</Button>
                            <Button style={button} onClick={handleConfirm}>submit</Button>
                        </div>
V1.18.8
Bill Payments
Your session will be expired in 00:10:33￼
￼
From Date

￼
19/04/2023
To Date

￼
19/04/2023
Type

Wallet
￼
￼
Select Biller

Avenues Bill Payments
￼
￼
￼
ALL(0)
￼
SUCCESS(0)
￼
FAILED(0)
￼
FAILED TO ACKNOWLEDGE(0)
search

                    </Stack>
                </Typography>
            </Box>
        </Modal>
    )
}
export default BillerModal