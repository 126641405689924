import * as React from 'react';
import axios from "axios";
import config from "config.js";
import libs from "controllers/libs.js"
import Divider from '@mui/material/Divider';
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from '@material-ui/core';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import moment from 'moment';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dropzone from "react-dropzone";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete"
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


import DocumentViewer from './DocumentViewer';

const styles = {
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    statusText: {
        color: "#000 !important",
    },
    noMargin: {
        margin: "0px",
        //  marginBottom: "-20px",
        float: "left"
    },
    minusMargin: {
        margin: "0px",
        marginBottom: "-20px",
        float: "left"
    },
    mdMargin: {
        margin: "20px",
    },
    mdBold: {
        fontWeight: "400"
    },
    customLink: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    docName: {
        fontWeight: 400
    }

};
const useStyles = makeStyles(styles);


export default function DocumentList(props) {
    const classes = useStyles();
    const [documents, setDocuments] = React.useState([])
    const [docTypes, setDocTypes] = React.useState([])
    const [docID, setDocID] = React.useState(null)
    const [values, setValues] = React.useState({ docTypeErr: null })
    const [submitting, setSubmitting] = React.useState(false)

    const [selectedDocument, setSelectedDocument] = React.useState("")
    const [otherDocumentName, setOtherDocumentName] = React.useState("")
    const [document, setDocument] = React.useState("");
    const [documentName, setDocumentName] = React.useState(null);
    const [uploadButtonColour, setUploadButtonColour] = React.useState("primary")

    const [docChangeFlag, setDocChangeFlag] = React.useState(0);

    const documentTypes = ["VALID_COMMERCIAL_REGISTRATION", "REGULATOR_LICENSE", "MEMORANUM_ARTICLES_OF_INCORPORATION", "AUDITED_FINANCIALS", "OWNERSHIP_STRUCTURE", "BUSINESS_REGISTRATION_FORM", "CORPORATE_AGREEMENT", "UPLOAD_CORPORATE_AGREEMENT_SIGNED", "OTHER"]
    const getCompanyDocumentsList = async (id) => {
        //function to fetch company document list
        try {
            let resp = await axios.post(`${config.url().baseURL}/get-company-docs/${id}`)
            if (resp.data.err) props.snack(resp.data.msg)
            else {
                setDocuments(resp.data.data.Documents)
                let docTypeUpdated = []
                await Promise.all(
                    resp.data.data.Documents.map((doc) => {
                        docTypeUpdated.push(doc.DocumentType)
                    })
                )
                addDocumentsToList(docTypeUpdated)

            }
        } catch (error) {
            props.snack("Unable to fetch document list.")
        }
    }
    React.useEffect(() => { if (props.company._id) getCompanyDocumentsList(props.company._id) }, [props.company._id])


    const checkDocTypeAdded = (type) => {
        //function to check doc type already added or not

        let status = documentTypes.includes(type)
        return status
    }


    const addDocumentsToList = async (docs) => {
        //function to add document type list
        let updatedDoc = []
        await Promise.all(
            documentTypes.map((doc) => {
                if (!docs.includes(doc)) updatedDoc.push(doc)
            })
        )
        setDocTypes(updatedDoc)
        console.log(updatedDoc)
    }

    const openSnackBar = (msg) => { props.snack(msg) }
    const openDoc = (id) => {
        setDocChangeFlag(docChangeFlag + 1)
        setDocID(id)
    }

    const docDrop = (images) => {
        //function to handle dropping of documents
        if (/\.(jpe?g|png|pdf)$/i.test(images[0].name) === true) {
            if (images[0].size < 2097152) {
                setUploadButtonColour("primary")
                libs.getBase64(images[0], (result) => {
                    setDocument(result);
                });
                setDocumentName(images[0].name);
            } else {
                props.snack("File size is too large, Maximum file size is 2 Mb.");
            }
        } else {
            props.snack("Please upload jpg,jpeg,png and pdf files");
        }
    }

    const deleteDoc = () => {
        setDocumentName("")
        setDocumentName(null)
    }

    const validateDoc = async () => {
        if (selectedDocument.length == 0) return ({ status: false, msg: "Please select doc type." })
        if (selectedDocument == "OTHER") {
            if (otherDocumentName == null || otherDocumentName.length == 0) return ({ status: false, msg: "Please enter other document type." })
        }
        if (documentName == null || documentName.length == 0) return ({ status: false, msg: "Please select document to upload." })
        return ({ status: true, msg: "validated." })
    }

    const submitDoc = async () => {
        //function to upload document 
        let validate = await validateDoc()
        if (validate.status) {
            try {
                setSubmitting(true)
                let resp = await axios.post(`${config.url().baseURL}/add-company-kyc-docs/${props.company.companyCR}`, { fileType: selectedDocument == "OTHER" ? otherDocumentName : selectedDocument, file: document })
                if (resp.data.err) {
                    setSubmitting(false)
                    props.snack(resp.data.msg)
                } else {
                    props.snack(resp.data.msg, "success")
                    setDocument(null)
                    setDocumentName(null)
                    setOtherDocumentName("")
                    setSelectedDocument(null)
                    setSubmitting(false)
                    getCompanyDocumentsList(props.company._id)
                }

            } catch (error) {
                setSubmitting(false)
                props.snack("Unable upload document.")
            }
        } else {
            props.snack(validate.msg)
        }
    }
    return (
        <>
            <Card plain>
                <CardBody>
                    <GridContainer >
                        <GridItem xs={12} sm={12} md={12}>
                            <h4 className={classes.noMargin} ><b>{props.company.companyName ? props.company.companyName : null} </b></h4>
                            <div style={{ float: "right" }}><Button style={{ margin: "0px", marginBottom: "-10px" }} onClick={() => { props.back() }} size="md" simple color="primary"> <ArrowBackIcon /> Back</Button></div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Divider />
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
            <GridContainer >
                <GridItem xs={12} sm={12} md={4}>
                    <Card >
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Add Document</h4>
                        </CardHeader>
                        <CardBody>
                            <FormControl className={classes.selectFormControl} error={values.docTypeErr} fullWidth>
                                <InputLabel className={classes.selectLabel}>
                                    Document Type <small className={classes.required}>*</small>
                                </InputLabel>
                                <Select MenuProps={{ className: classes.selectMenu, }} classes={{ select: classes.select, }} value={selectedDocument} onChange={(e) => { setSelectedDocument(e.target.value) }}>
                                    {
                                        docTypes.map((doc) => {
                                            return (
                                                <MenuItem classes={{
                                                    root: classes.selectMenuItem,
                                                }}
                                                    value={doc}>
                                                    {doc}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <Collapse in={selectedDocument == "OTHER"} style={{ transformOrigin: "0 0 0", textAlign: "center" }} {...(true ? { timeout: 500 } : {})}>
                                <CustomInput
                                    labelText="Custom Document Type"
                                    formControlProps={{ fullWidth: true, }}
                                    inputProps={{
                                        value: otherDocumentName,
                                        onChange: (e) => { setOtherDocumentName(libs.validate(e.target.value, "all", 150) ? e.target.value : otherDocumentName); },
                                    }}
                                />
                            </Collapse>
                            {
                                documentName ? (
                                    <p  >{documentName}   <Tooltip placement="right" title="Delete">
                                        <IconButton
                                            onClick={() => deleteDoc()}
                                            style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                                            aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                    </p>
                                ) : (
                                    <p>
                                        <Dropzone onDrop={docDrop}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <Button size="sm" fullWidth color={uploadButtonColour}>
                                                        Upload Document
                                                    </Button>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </p>
                                )
                            }
                            <div style={{ textAlign: "center" }}>
                                <Button disabled={submitting} onClick={() => { submitDoc() }} color="success">
                                    Upload
                                </Button>
                            </div>

                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>

                    {
                        documents.map((document) => {
                            return (
                                <Paper className={classes.mdMargin}>
                                    <CardBody>
                                        <span className={classes.docName}>{document.DocumentType.replace(/_/g, ' ')}</span>
                                        <Button style={{ float: "right" }} size="sm" onClick={() => { openDoc(document.DocumentID) }} color="primary">View</Button>
                                        <br />
                                        <span className={classes.doID}>Document ID : {document.DocumentID}</span>
                                        <br />
                                        <span className={classes.doID}>Uploaded On : {moment(document.CreatedDate).format("DD/MM/YYYY") != "Invalid date" ? <span> {moment(document.CreatedDate).format("DD/MM/YYYY") + " " + moment(document.CreatedDate).format("hh:mm:ss A")}</span> : document.CreatedDate}</span>
                                        <br />
                                        <span className={classes.doID}>Uploaded By : {document.UploadBy}</span>

                                    </CardBody>
                                </Paper>
                            )
                        })
                    }
                </GridItem>
            </GridContainer>

            <DocumentViewer id={docID} snack={openSnackBar} flag={docChangeFlag} company={props.company._id} />
        </>
    );
}