import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createStore } from "redux";
import allReducers from "./redux/reducers/index.js";
import { Provider } from "react-redux";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import axios from "axios"
import config from "config.js"


// core components

import RTL from "layouts/RTL.js";
import Login from "views/LoginPage/LoginPage.js";
import AdminLogin from "views/AdminLogin/AdminLogin.js";
import ForgotPasswordAdmin from "views/ForgotPasswordAdmin/ForgotPassword.js";
import ForgotPassword from "views/ForgotPassword/ForgotPassword.js";
import setPassword from "views/SetPassword/SetPassword.js";
import Admin from "layouts/Admin.js";
import Payment from "views/Payment/Payment.js";
import TermsAndConditions from "views/Disclaimer/termsAndConditions.js";
import UserAgreement from "views/Disclaimer/userAgreement2.js";
import CardPinChange from "views/CardPinChange/CardPinChange.js";
import "assets/css/material-dashboard-react.css?v=1.9.0";
import AdminOTPVerify from "views/AdminOTPVerify/AdminOTPVerify.js";

// const override = css`position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);`;

// const Admin = lazy(() => import("layouts/Admin.js"))

const cx_store = createStore(allReducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

const hist = createBrowserHistory();
const instance = axios.create()
let sessionExpired = false
axios.interceptors.request.use(
  request => {
    if (sessionStorage.getItem("cx_id")) {
      if (request.url.includes('mobile.bfcpay.com/api') || request.url.includes('bfcp-mob-alb-uat.bfcpayments.com/api') || request.url.includes('bfcp-mob-alb-sand.bfcpayments.com/api') || request.url.includes('0.0.0.0:3001') || request.url.includes('.ngrok.io')) {
        request.headers['auth'] = sessionStorage.getItem("cx_id")
      }
    }
    return request
  },
  error => {
    return Promise.reject(error)
  }
)


axios.interceptors.response.use(
  (res) => { return res }, async (err) => {
    const originalConfig = err.config;
    if (err.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        let resp = await axios.post(`${config.url().baseURL}/refreshToken`, { refresh_token: sessionStorage.getItem("cx_refresh_id") })
        if (resp.data.err) { clearSession(); hist.push("/login") }
        else {
          await clearSession()
          await setSession(resp.data.data.AuthToken, resp.data.data.RefreshToken, resp.data.data.Time)
          originalConfig.headers.auth = resp.data.data.AuthToken
          return instance(originalConfig);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    } else return err
  }
)

const setSession = async (token, refreshToken, time) => {
  sessionStorage.setItem("cx_id", token);
  sessionStorage.setItem("cx_refresh_id", refreshToken);
  sessionStorage.setItem("cx_id_time", time);
}


const clearSession = async () => {
  //function to clear session
  sessionStorage.removeItem("cx_id");
  sessionStorage.removeItem("cx_refresh_id");
  sessionStorage.removeItem("cx_id_time");

}


ReactDOM.render(
  <Provider store={cx_store}>
    <Router history={hist}>
      <Switch>
        {/* <Route path="/dashboard">
          <Suspense fallback={<PropagateLoader color="#004fa4" loading={true} css={override} size={15} />}>
            <Admin />
          </Suspense>
        </Route> */}
        <Route path="/dashboard" component={Admin} />
        <Route path="/rtl" component={RTL} />
        <Route from="/login" component={Login} />
        <Route from="/set-password" component={setPassword} />
        <Route from="/login-admin" component={AdminOTPVerify} />
        <Route from="/forgot-password-admin" component={ForgotPasswordAdmin} />
        <Route from="/forgot-password" component={ForgotPassword} />
        <Route from="/terms&conditions" component={UserAgreement} />
        <Route from="/privacy-policy" component={TermsAndConditions} />
        <Route from="/card-pin-change" component={CardPinChange} />
        <Route path="/cards/en/card/index" component={Payment} />
        <Route path="/cards/en/card/error.html" component={Payment} />
        {/* <Route from="/payment" component={Payment} /> */}

        <Redirect from="/" to="/login" />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
