import * as React from 'react';
import axios from "axios";
import MUIDataTable from "mui-datatables";
import Collapse from "@material-ui/core/Collapse";
import config from "config.js"
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import Tooltip from "@material-ui/core/Tooltip";
import PreviewIcon from '@mui/icons-material/Preview';
import IconButton from "@material-ui/core/IconButton";
import moment from 'moment';
import { useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import BounceLoader from "react-spinners/BounceLoader";
import SearchIcon from '@material-ui/icons/Search';
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";




const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;


const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),
}));

const columns = [
    {
        name: "cardId",
        label: "Card ID",
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: "cardNumber",
        label: "Card Number",
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: "cpr",
        label: "CPR",
        options: {
            filter: true,
            sort: true,
        },
    },

    {
        name: "reasonCode",
        label: "Reason ",
        options: {
            filter: true,
            sort: true,
        },
    },
    // {
    //     name: "actions",
    //     label: "Actions",
    //     options: {
    //         filter: false,
    //         sort: false,
    //     },
    // }
];

const options = {
    selectableRows: false,
    elevation: 0,
    textLabels: {
        body: {
            noMatch: "No matching records found",
        },
    }
};
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function ReplacedCards() {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [showTable, setShowTable] = React.useState(false)
    const [showLoader, setShowLoader] = React.useState(false)
    const [snackBarType, setSnackBarType] = React.useState("error");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [respAlert, setRespAlert] = React.useState(null);

    const [fromDate, setFromDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
    const [toDate, setToDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
    const [replacedCardDetails, setReplacedCardDetails] = React.useState([]);

    const [searchKey, setSearchKey] = React.useState("");
    const [showDetails, setShowDetails] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [addBtn, setAddBtn] = React.useState(false);

    React.useEffect(() => {
        searchReplacedCardDetails();
    }, []);

    function openSnackBar(msg, success) {
        //function to tost snackbar message
        setOpenSnack(true);
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const searchReplacedCardDetails = async () => {
        //function to fetch replaced card details.
        try {
            setShowLoader(true);
            //setSubmitting(true);
            axios.post(`${config.url().baseURL}/GetCardsReplaceReport`).then((resp) => {
                    console.log(resp);
                    //setSubmitting(false);
                    setShowLoader(false);
                    setShowTable(true)
                    let resData = resp.data;
                    if (resData.err) {
                        openSnackBar(resp.data.msg)
                        setShowTable(false);
                    }
                    else {
                       // let updatedReplacedCardDetails =[]
                        //setReplacedCardDetails(updatedReplacedCardDetails)
                        setReplacedCardDetails(resData.data)
                    }
                }).catch((err) => {
                    openSnackBar('Unable To Process !!')
                })
        } catch (error) {
            openSnackBar("Unable to fetch Replaced card details.")
        }
    }
    const openUserDetails = (id) => {

        setShowDetails(true);
    }

    return (
        <>
            {respAlert}
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Replaced Card Details</h4>
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable
                                    data={replacedCardDetails}
                                    columns={columns} options={options}
                                />
                                {/* <MaterialTable data={replacedCardDetails} columns={columns} options={options} /> */}
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.companyLogModal,
                }}
                open={showDetails}
                onClose={() => {
                    setShowDetails(false)
                    //setTransactionDetails(null)
                }}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>User Details</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>

                    <Collapse
                        //in={userDetails.userID == undefined}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                    </Collapse>

                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setShowDetails(false);
                            //setTransactionDetails(null) 
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </>
    );
}