import * as React from 'react';
import config from "config.js"
import axios from "axios";
import moment from "moment";
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import Button from "components/CustomButtons/Button.js";

const override = css`
display: block;
margin: 32px auto;b
border-color: white;
`;


export default function Transactions(props) {
    const [wallet, setWallet] = React.useState([]);
    const [noWallet, setNoWallet] = React.useState(false);
    const [pageValue, setPageValue] = React.useState(1);
    const [remainingDocs, setRemainingDocs] = React.useState(0);
    console.log(pageValue)
    const walletColumns = [
        {
            field: "time",
            title: "Date",

            cellStyle: {
                width: 300,
                maxWidth: 300
            },
            render: rowData => moment(rowData.time).format("DD/MM/YYYY") != "Invalid date" ? <span> {moment(rowData.time).format("DD/MM/YYYY") + " " + moment(rowData.time).format("hh:mm:ss A")}</span> : rowData.time,
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "refID",
            title: "Reference No",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "type",
            title: "Type",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "otherCustomer",
            title: "Other Customer",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "amountIn",
            title: "Dr/Cr",
            cellStyle: {
                width: 300,
                maxWidth: 300
            },
            render: rowData => rowData.amountIn == "False" ? <span> Dr</span> : <span> Cr</span>,
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            field: "amount",
            title: "Amount",
            options: {
                filter: false,
                sort: false,
            },
        },

    ];

    const walletOptions = {
        actionsColumnIndex: -1,
        exportButton: false,
        showTitle: false,
        pageSizeOptions: [],
        paging: false,
        rowStyle: rowData => {
            if (rowData.amountIn == "False") {
                return { backgroundColor: '#f0fcf0' };
            } else {
                return { backgroundColor: '#fcf0f0' };
            }
        },

    };

    const fetchWalletTransactions = (id, value) => {
        //fetch all transaction done by a user
        setWallet([]);
        axios.get(`${config.url().baseURL}/get-customer-transactions/${id}/${value}`)

            .then((resp) => {
                if (!resp['data']['err']) {
                    setWallet(resp['data']['data']['transactions'])
                    setRemainingDocs(resp.data.data.remainingDocs);
                    if (resp['data']['data']['transactions'].length == 0) setNoWallet(true)
                } else {
                    setWallet([])
                    setNoWallet(true)
                }
            })
            .catch((err) => {
                setWallet([])
                setNoWallet(true)
            });
    };

    const nextTransaction = () => {
        setPageValue(pageValue => pageValue + 1);
    }

    const previousTransaction = () => {
        setPageValue(pageValue => pageValue - 1);
    }


    React.useEffect(() => {
        if (props.cpr !== null) {
            fetchWalletTransactions(props.cpr, pageValue)
            console.log(pageValue);
            setWallet([]);
            setNoWallet(false);
        } else {
            setWallet([]);
            setNoWallet(false);
        }
    }, [props.cpr, pageValue]);

    const walletActions = [
        (rowData) => {
            if (rowData.amountIn != "False") return { icon: 'check_circle_icon', onClick: (event, rowData) => { selectTransaction(rowData.refID, rowData.amount) } }
        }
    ]

    const selectTransaction = (ref, amount) => {
        //function to select transaction
        props.getRefNo(ref, amount)
    }

    return (
        <>
            <Collapse
                in={noWallet}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <h4>No Transactions Found.</h4>
            </Collapse>
            <Collapse
                in={!noWallet}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <Collapse
                    in={wallet.length == 0}
                    style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                    {...(true ? { timeout: 500 } : {})}>
                    <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                </Collapse>
                <Collapse
                    in={wallet.length !== 0}
                    style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                    {...(true ? { timeout: 500 } : {})}>
                    <MaterialTable
                        components={{
                            Container: (props) => <Paper {...props} elevation={0} />,
                        }}
                        actions={walletActions}
                        data={wallet}
                        columns={walletColumns}
                        options={walletOptions}
                    />
                </Collapse>
                <Button 
                    disabled={pageValue <= 1 ? true : false}
                    onClick={() => previousTransaction()}
                    color="primary"
                    size="sm">
                    Previous
                </Button>
                <Button 
                    disabled = {remainingDocs == 0 ? true : false}
                    onClick={() =>  nextTransaction()}
                    color="primary"
                    size="sm">
                    Next
                </Button>
            </Collapse>
        </>
    );
}