import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
    body: {
        padding: "20px"
    },
    mainHead: {
        color: "#0A3B93",
        fontWeight: "500",
    },
    subHead: {
        color: "#531879",
    }

});

const useStyles = makeStyles(styles);

export default function TermsAndConditions() {
    const classes = useStyles();
    return (
        <div className={classes.body} >
            <h2 className={classes.mainHead}>BFC Payments Privacy Policy</h2>
            <h3 className={classes.subHead} >Privacy Commitment</h3>
            <p>
                BFC Payments B.S.C (Closed) is committed to protecting consumer privacy during usage of our mobile app and recognizes our responsibility to hold your information secure and confidential. When you give us any personal information about yourself or others, we promise to treat it securely, fairly and lawfully. Any information collected, stored or processed will be done so in accordance with all applicable laws and regulations in all operating jurisdictions including the EU’s General Data Protection Regulation and Bahrain’s Personal Data Protection Law.
            </p>
            <p>
                When we ask you for personal information online, it will only be in response to you using one of our online products or services. We may use personal information you provide for the purpose of providing more relevant content to you.
            </p>
            <p>
                This Privacy policy sets out the basis upon which any personal data we collect from you, or provided to us, will be processed by us. Please read this policy carefully to understand how your personal information will be treated. By submitting your personal details to us, you are deemed as agreeing to us using them in line and in accordance with the terms and conditions in this policy. However, should applicable laws or regulations dictate, no personal information will be stored or processed without your explicit written approval which you may revoke at any time. This policy may be changed from time to time, so please read it periodically.
            </p>
            <h3 className={classes.subHead} >Information Collected About You</h3>
            <p>
                We collect two types of information about you: (1) personally-identifiable information provided by you to us as well as information we learn about you from your use of our services and the App; and (2) information we collect that allows us to understand and optimize the operation of the App, which cannot be used to identify you.
            </p>
            <p>
                We collect personally identifiable information about you only when you specifically give it to us. We may also collect information relating to the transactions you carry out, including details of any bank accounts you transact to and from the App. We do not generally disclose this information to third parties except in certain circumstances set out in Section ‎” Disclosure of your information” below.
            </p>
            <h3 className={classes.subHead} >Sharing your personal information with third parties</h3>
            <p>
                We may share personal information with third party providers so they can provide you with certain payment services through the App. We will require these third party providers to take steps to ensure that your information is kept secure and used in accordance with this Privacy Policy. However, we shall not be liable for any unauthorised use of your personal information by a third party provider.
            </p>
            <p>
                This Privacy Policy only applies to personal information collected on the App. Although the App may provide links to websites of third parties, such as banks, this Privacy Policy does not apply to any other application or website that you connect to from the App. We are not responsible for the content or practices of applications and websites operated by third parties that are linked to or from the App.
            </p>
            <h3 className={classes.subHead} >Marketing communications</h3>
            <p>
                The transmission of information via the Internet is not completely secure. We will do our best to protect your personal information while it is in our possession, however, we cannot guarantee the security of your data transmitted online or over the App.
            </p>
            <h3 className={classes.subHead} >Disclosure of your information</h3>
            <p>
                We may disclose your personal information to other members of the Saudi Telecom Company group of companies. We may also disclose your personal information to third parties in the following circumstances:
                <ul>
                    <li>
                        in order to provide services (including the e-wallet services) available through the App;
                    </li>
                    <li>
                        where those third parties assist us in supplying our services to you or perform certain functions on our behalf, including IT support services or professional services;
                    </li>
                    <li>
                        in the event of a sale, merger, liquidation, receivership or transfer of assets of BFC pay or one of our affiliated companies, to the prospective buyer of the business and their professional advisers; and
                    </li>
                    <li>
                        if required to do so by law, a court order or by a regulatory authority of competent jurisdiction or if we believe that such disclosure is necessary, to protect, defend or enforce our rights. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
                    </li>
                </ul>
            </p>
            <p>
                Please note that in order to cooperate with governmental requests, summons or court orders, or to protect other users or our systems, we reserve the right to disclose any information we consider necessary or appropriate, including your user profile information (ie CPR, name, mobile no, email address, etc.), usage history, and content submitted to the App.
            </p>
            <h3 className={classes.subHead} >Changes To Our Privacy Statement</h3>
            <p>
                This privacy policy may be updated from time to time so you may wish to check it each time you submit personal information on our website. The date of the most recent revisions will appear on this page.
            </p>
            <h3 className={classes.subHead} >Contact Us About This Privacy Policy</h3>
            <p>
                If you have any questions or comments about this Privacy Policy, please contact:
                <p>
                    Customer Service Manager / Data Protection Officer <br />
                    BFC Payments B.S.C. (C) <br />
                    P.O. Box 243 <br />
                    Manama <br />
                    Kingdom of Bahrain <br /><br />
                    Tel: +973 1771 1775<br />
                    Email: customer.services@bfcpayments.com
                </p>
            </p>
        </div>
    );
}
