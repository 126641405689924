import React from "react";
import config from "config.js"
import Dropzone from "react-dropzone";
import MoonLoader from "react-spinners/MoonLoader";
import MUIDataTable from "mui-datatables";
import Table from "components/Table/Table.js";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import BounceLoader from "react-spinners/BounceLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import moment from "moment";
import { creatCsvFile, downloadFile } from "download-csv";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PeopleIcon from "@material-ui/icons/People";
import EditIcon from "@material-ui/icons/Edit";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import { SettingsPhoneTwoTone } from "@material-ui/icons";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

const styles = (theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    addGroupContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
    },
    addGroupButton: {
        marginRight: "-5px !important",
    },
    statusText: {
        color: "#000 !important",
    },
    statusHead: {
        marginBottom: "0px !important",
    },
    center: {
        textAlign: "center",
    },
    right: {
        textAlign: "right",
    },
    left: {
        textAlign: "left",
    },
    marginRight: {
        marginRight: "5px",
    },
    modalSectionTitle: {
        marginTop: "30px",
    },
    userContainer: {
        maxHeight: "500px",
        overflowY: "scroll",
    },
    modelTag: {
        marginBottom: "-10px !important"
    },
    subHead: {
        fontWeight: "500",
        marginBottom: "-2px !important",
        float: "left",
        display: "inlineBlock",
    },
    docName: {
        fontSize: "14px !important",
        fontWeight: "500",
        marginBottom: "10px !important",
        marginTop: "10px !important",
    },
    uploadButton: {
        marginBottom: "10px !important",
        marginTop: "10px !important",
    },
    ...modalStyle(theme),
});

const useStyles = makeStyles(styles);
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function Migration() {
    const [showMigrate, setShowMigrate] = React.useState(true);

    const [amthalCSV, setAmthalCSV] = React.useState(1);
    const [corporateCSV, setCorporateCSV] = React.useState(1);
    const [dobXlsx, setDobXlsx] = React.useState(1);

    const [openSnack, setOpenSnack] = React.useState(false);
    const [respAlert, setRespAlert] = React.useState(null);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");

    const override = css`
    display: block;
    margin:0;
    border-color: red;
  `;

    const hideAlert = () => {
        setRespAlert(null);
    };

    const uploadAmthal = async (images) => {
        if (/\.(csv)$/i.test(images[0].name) === true) {
            if (images[0].size < 10000000) {
                let data = new FormData();
                data.set("file", images[0]);
                setAmthalCSV(2);
                axios
                    .post(`${config.url().baseURL}/amtal-user-migration`, data, {
                        headers: {
                            auth: sessionStorage.getItem("cx_id"),
                        },
                    })
                    .then((resp) => {
                        let respData = resp.data;
                        if (!respData.err) {
                            setRespAlert(
                                <SweetAlert
                                    success
                                    style={{ display: "block" }}
                                    title={respData.msg}
                                    onConfirm={() => {
                                        hideAlert()
                                        setAmthalCSV(1)
                                    }}
                                    confirmBtnCssClass={classes.button + " " + classes.success}
                                />
                            );
                        } else {
                            setAmthalCSV(1)
                            openSnackBar(respData.msg);
                        }
                    })
                    .catch((err) => {
                        setAmthalCSV(1)
                        openSnackBar("Unable to process.");
                    });
            } else {
                setAmthalCSV(1)
                openSnackBar("File size is too large.");
            }
        } else {
            setAmthalCSV(1)
            openSnackBar("Please upload csv file");
        }

    };

    const uploadCorporate = async (images) => {
        if (/\.(csv)$/i.test(images[0].name) === true) {
            if (images[0].size < 10000000) {
                let data = new FormData();
                data.set("corp", images[0]);
                setCorporateCSV(2);
                axios.post(`${config.url().baseURL}/corp-migrate`, data, {
                    headers: {
                        auth: sessionStorage.getItem("cx_id"),
                    },
                })
                    .then((resp) => {
                        let respData = resp.data;
                        if (!respData.err) {
                            setRespAlert(
                                <SweetAlert
                                    success
                                    style={{ display: "block" }}
                                    title={respData.msg}
                                    onConfirm={() => {
                                        hideAlert()
                                        setCorporateCSV(1)
                                    }}
                                    confirmBtnCssClass={classes.button + " " + classes.success}
                                />
                            );
                        } else {
                            setCorporateCSV(1)
                            openSnackBar(respData.msg);
                        }
                    })
                    .catch((err) => {
                        setCorporateCSV(1)
                        openSnackBar("Unable to process.");
                    });
            } else {
                setCorporateCSV(1)
                openSnackBar("File size is too large.");
            }
        } else {
            setCorporateCSV(1)
            openSnackBar("Please upload csv file");
        }
    };

    const uploadDOB = async (images) => {
        if (/\.(xlsx)$/i.test(images[0].name) === true) {
            if (images[0].size < 10000000) {
                let data = new FormData();
                data.set("cpr-dob", images[0]);
                setDobXlsx(2);
                axios.post(`${config.url().baseURL}/cpr-dob-update`, data, {
                    headers: {
                        auth: sessionStorage.getItem("cx_id"),
                    },
                })
                    .then((resp) => {
                        let respData = resp.data;
                        if (!respData.err) {
                            setRespAlert(
                                <SweetAlert
                                    success
                                    style={{ display: "block" }}
                                    title={respData.msg}
                                    onConfirm={() => {
                                        hideAlert()
                                        setDobXlsx(1)
                                    }}
                                    confirmBtnCssClass={classes.button + " " + classes.success}
                                >
                                    {respData.data.FailedProcess.lenth}
                                    {
                                        respData.data.FailedProcess.lenth !== 0 ?
                                            (<>
                                                <h5>Failed CPR</h5>
                                                <Stack spacing={1}>
                                                    {
                                                        respData.data.FailedProcess.map((cpr) => {
                                                            return (<div>{`${cpr.failedCpr} : ${cpr.failedReason}`} </div>)
                                                        })
                                                    }
                                                </Stack>
                                            </>) : null

                                    }
                                </SweetAlert>

                            );
                        } else {
                            setCorporateCSV(1)
                            openSnackBar(respData.msg);
                        }
                    })
                    .catch((err) => {
                        setCorporateCSV(1)
                        openSnackBar("Unable to process.");
                    });
            } else {
                setCorporateCSV(1)
                openSnackBar("File size is too large.");
            }
        } else {
            setCorporateCSV(1)
            openSnackBar("Please upload xlsx file");
        }
    };

    function openSnackBar(msg) {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const classes = useStyles();
    return (
        <div>
            {respAlert}
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showMigrate}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Card>
                            <CardBody style={{ paddingTop: "-150px" }}>
                                <Table
                                    tableHeaderColor="primary"
                                    tableData={[
                                        [
                                            <h6 className={classes.docName}>
                                                AMTHAL USERS
                                            </h6>,
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            amthalCSV === 1 ? (
                                                <Dropzone onDrop={uploadAmthal}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <Button className={classes.uploadButton} size="sm" fullWidth color="primary">
                                                                Upload
                                                            </Button>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                            ) : null,
                                            amthalCSV === 2 ? (
                                                <MoonLoader color="#004fa4" loading={true} css={override} size={25} />
                                            ) : null,
                                            "",
                                            "",
                                            "",
                                            "",

                                        ],
                                        [
                                            <h6 className={classes.docName}>
                                                AMTHAL CORPORATE
                                            </h6>,
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            corporateCSV === 1 ? (
                                                <Dropzone onDrop={uploadCorporate}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <Button className={classes.uploadButton} size="sm" fullWidth color="primary">
                                                                Upload
                                                            </Button>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                            ) : null,
                                            corporateCSV === 2 ? (
                                                <MoonLoader color="#004fa4" loading={true} css={override} size={25} />
                                            ) : null,
                                            "",
                                            "",
                                            "",
                                            "",

                                        ],
                                        [
                                            <h6 className={classes.docName}>
                                                DOB Bulk Update
                                            </h6>,
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            dobXlsx === 1 ? (
                                                <Dropzone onDrop={uploadDOB}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <Button className={classes.uploadButton} size="sm" fullWidth color="primary">
                                                                Upload
                                                            </Button>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                            ) : null,
                                            dobXlsx === 2 ? (
                                                <MoonLoader color="#004fa4" loading={true} css={override} size={25} />
                                            ) : null,
                                            "",
                                            "",
                                            "",
                                            "",

                                        ]
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>


            </GridContainer>

            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    );
}
