import * as React from "react";


import { css } from "@emotion/core";
import { styled } from '@mui/material/styles';
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
// import XlxUploadAndCheck from "./XlxUploadAndCheck";

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import MuiAlert from "@material-ui/lab/Alert";

import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import { Card, Collapse, FormHelperText } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import { PropagateLoader } from "react-spinners";
import CardBody from "components/Card/CardBody";
import { Button } from "@material-ui/core";
import axios from "axios";
import config from "config";


const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;




const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "center",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400",
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px",
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff",
    },
    indicator: {
        backgroundColor: "white",
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function BfcBiller({ back,type }) {
    const classes = useStyles();
    const [billerName, setBillerName] = React.useState("");
    const [isProducts, setIsProducts] = React.useState();
    const [category, setCategory] = React.useState("");
    const [issuerId, setIssuserId] = React.useState("");
    const [issuerName, setIssuerName] = React.useState("");
    const [formView, setFormView] = React.useState(1); //0:-loader , 1:-school form
    const [checkboxChecked, setCheckboxChecked] =React.useState(false);
    const formData = new FormData();

    async function handleSubmit(){
        formData.append('billerName',billerName)
        formData.append('category',category)
        formData.append('issuerId',issuerId)
        formData.append('billerName',billerName)
        formData.append('issuerName',issuerName)
        formData.append('isProducts',checkboxChecked)
        formData.append('type',type)


        const resp = await axios.post(`${config.url().app_URL}/bill/create-category`,formData)
        console.log(resp);
    } 

    return (


//    <GridContainer>
<>

<GridItem xs={12} sm={12} md={6}>
    <CustomInput
        labelText="Biller Name"
        id="name_of_school`"
        // error={values.nameErr}
        formControlProps={{
            fullWidth: true
        }}
        inputProps={{
            // readOnly: props.view,
            value: billerName,
            onChange: (e) => {
                setBillerName(e.target.value)
                // setSchoolName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : schoolName);
                // setValues({ ...values, nameErr: false })
            },
            onBlur: (e) => {
                // setSchoolName(e.target.value.trim());
            }
        }}
    />
</GridItem>



<GridItem xs={12} sm={12} md={3}>
    <CustomInput
        labelText="CR Number"
        id="cr number`"
        // error={values.nameErr}
        formControlProps={{
            fullWidth: true
        }}
        inputProps={{
            // readOnly: props.view,
            // value: schoolName,
            onChange: (e) => {
                
                // setSchoolName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : schoolName);
                // setValues({ ...values, nameErr: false })
            },
            onBlur: (e) => {
                // setSchoolName(e.target.value.trim());
            }
        }}
    />
</GridItem>

<GridItem xs={12} sm={12} md={3}>

    <CustomInput
        labelText="Flat "
        id="flat`"
        // error={values.nameErr}
        formControlProps={{
            fullWidth: true
        }}
        inputProps={{
            // readOnly: props.view,
            // value: schoolName,
            onChange: (e) => {
                // setSchoolName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : schoolName);
                // setValues({ ...values, nameErr: false })
            },
            onBlur: (e) => {
                // setSchoolName(e.target.value.trim());
            }
        }}
    />
</GridItem>
<GridItem xs={12} sm={12} md={3}>
    <CustomInput
        labelText="Building"
        id="building`"
        // error={values.nameErr}
        formControlProps={{
            fullWidth: true
        }}
        inputProps={{
            // readOnly: props.view,
            // value: schoolName,
            onChange: (e) => {
                // setSchoolName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : schoolName);
                // setValues({ ...values, nameErr: false })
            },
            onBlur: (e) => {
                // setSchoolName(e.target.value.trim());
            }
        }}
    />
</GridItem>

<GridItem xs={12} sm={12} md={3}>
    <CustomInput
        labelText="Road"
        id="Road`"
        // error={values.nameErr}
        formControlProps={{
            fullWidth: true
        }}
        inputProps={{
            // readOnly: props.view,
            // value: schoolName,
            onChange: (e) => {
                // setSchoolName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : schoolName);
                // setValues({ ...values, nameErr: false })
            },
            onBlur: (e) => {
                // setSchoolName(e.target.value.trim());
            }
        }}
    />
</GridItem>


<GridItem xs={12} sm={12} md={3}>
    <CustomInput
        labelText="Block"
        id="block`"
        // error={values.nameErr}
        formControlProps={{
            fullWidth: true
        }}
        inputProps={{
            // readOnly: props.view,
            // value: schoolName,
            onChange: (e) => {
            
                // setSchoolName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : schoolName);
                // setValues({ ...values, nameErr: false })
            },
            onBlur: (e) => {
                // setSchoolName(e.target.value.trim());
            }
        }}
    />
</GridItem>

{/* <GridItem xs={12} sm={12} md={3}>
    <input type="file" />
</GridItem> */}
<GridItem xs={12} sm={6} md={2}>
    <div style={{ paddingTop: '48px' }}>
        <FormControlLabel control={<Checkbox />} label="Sub Products"
        //  labelPlacement="top"
        onChange={e=> setCheckboxChecked(e.target.checked)}
        />
        <FormHelperText id="username-helper"
            // error={selectBool}
            style={{paddingLeft:"41px"}}
            >
               {checkboxChecked ? "SUB PRODUCT ENABLED" : "SUB PRODUCT DISABLED"}
                {/* {helperText} */}
        </FormHelperText>

    </div>
</GridItem>

<GridItem xs={12} sm={6} md={2}>
    <div style={{ paddingTop: '48px' }}>
        <input type="file"></input>

    </div>
</GridItem>


<GridItem xs={12} sm={12} md={3}>
    <CustomInput
        labelText="Category"
        id="category`"
        // error={values.nameErr}
        formControlProps={{
            fullWidth: true
        }}
        inputProps={{
            // readOnly: props.view,
            value: category,
            onChange: (e) => {
                setCategory(e.target.value)

                // setSchoolName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : schoolName);
                // setValues({ ...values, nameErr: false })
            },
            onBlur: (e) => {
                // setSchoolName(e.target.value.trim());
            }
        }}
    />
</GridItem>

<GridItem xs={12} sm={12} md={3}>
    <CustomInput
        labelText="Issuer Id"
        id="issuerId`"
        // error={values.nameErr}
        formControlProps={{
            fullWidth: true
        }}
        inputProps={{
            // readOnly: props.view,
            value: issuerId,
            onChange: (e) => {
                setIssuserId(e.target.value)

                // setSchoolName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : schoolName);
                // setValues({ ...values, nameErr: false })
            },
            onBlur: (e) => {
                // setSchoolName(e.target.value.trim());
            }
        }}
    />
</GridItem>


<GridItem xs={12} sm={12} md={3}>
    <CustomInput
        labelText="Issuer Name"
        id="issuerName`"
        // error={values.nameErr}
        formControlProps={{
            fullWidth: true
        }}
        inputProps={{
            // readOnly: props.view,
            value: issuerName,
            onChange: (e) => {
                setIssuerName(e.target.value)

                // setSchoolName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : schoolName);
                // setValues({ ...values, nameErr: false })
            },
            onBlur: (e) => {
                // setSchoolName(e.target.value.trim());
            }
        }}
    />
</GridItem>


{/* </GridContainer> */}
</>
        

    );
}





        // // <div>
        // // <GridContainer justify="center">
        // <>
        //     {/* <GridItem xs={12} sm={12} md={8}> */}
        //     {/* <Collapse
        //             in={formView == 0}
        //             style={{ transformOrigin: "0 0 0", textAlign: "center" }}
        //             {...(true ? { timeout: 500 } : {})}>
        //             <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
        //         </Collapse> */}
        //     <Collapse
        //         in={formView == 1}
        //         style={{ transformOrigin: "0 0 0", textAlign: "center" }}
        //         {...(true ? { timeout: 500 } : {})}>
        //         {/* <Card profile> */}
        //         {/* <CardAvatar profile>
        //                     {
        //                         props.view ?
        //                             <img src={logo ? logo : avatar} alt="..." /> :
        //                             <Dropzone onDrop={uploadLogo}>
        //                                 {({ getRootProps, getInputProps }) => (
        //                                     <div {...getRootProps()}>
        //                                         <input {...getInputProps()} />
        //                                         <a href="#pablo" onClick={e => e.preventDefault()}>
        //                                             <img src={logo ? logo : avatar} alt="..." />
        //                                         </a>
        //                                     </div>
        //                                 )}
        //                             </Dropzone>
        //                     }

        //                 </CardAvatar> */}


        //         <GridContainer>

        //             <GridItem xs={12} sm={12} md={6}>
        //                 <CustomInput
        //                     labelText="Biller Name"
        //                     id="name_of_school`"
        //                     // error={values.nameErr}
        //                     formControlProps={{
        //                         fullWidth: true
        //                     }}
        //                     inputProps={{
        //                         // readOnly: props.view,
        //                         value: billerName,
        //                         onChange: (e) => {
        //                             setBillerName(e.target.value)
        //                             // setSchoolName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : schoolName);
        //                             // setValues({ ...values, nameErr: false })
        //                         },
        //                         onBlur: (e) => {
        //                             // setSchoolName(e.target.value.trim());
        //                         }
        //                     }}
        //                 />
        //             </GridItem>
        //             <GridItem xs={12} sm={12} md={3}>
        //                 <CustomInput
        //                     labelText="category"
        //                     id="category"
        //                     formControlProps={{
        //                         fullWidth: true
        //                     }}
        //                     inputProps={{
        //                         // readOnly: props.view,
        //                         value: category,
        //                         onChange: (e) => {
        //                             setCategory(e.target.value)
        //                             // setBranch(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : branch);
        //                         },
        //                         onBlur: (e) => {
        //                             // setBranch(e.target.value.trim());
        //                         }
        //                     }}
        //                 />
        //             </GridItem>
        //             <GridItem xs={12} sm={6} md={2}>
        //                 <div style={{ paddingTop: '48px' }}>
        //                     <FormControlLabel control={<Checkbox />} label="Sub Products"
        //                      labelPlacement="top"
        //                     />
        //                     <FormHelperText id="username-helper"
        //                         // error={selectBool}
        //                         style={{ paddingLeft: "41px" }}
        //                     >
        //                         SUB PRODUCT ENABLED
        //                         SUB PRODUCT DISABLED
        //                         {/* {helperText} */}
        //                     </FormHelperText>

        //                 </div>
        //             </GridItem>
        //         </GridContainer>
        //         <GridContainer>
        //             <GridItem xs={12} sm={12} md={12}>
        //                 <CustomInput
        //                     labelText="Address"
        //                     id="address"
        //                     formControlProps={{
        //                         fullWidth: true
        //                     }}
        //                     inputProps={{
        //                         // readOnly: props.view,
        //                         multiline: true,
        //                         maxRows: 5,
        //                         // value: address,
        //                         onChange: (e) => {
        //                             // setAddress(libs.validate(e.target.value, "alphanumeric", 500) ? e.target.value : address);
        //                         },
        //                         onBlur: (e) => {
        //                             // setAddress(e.target.value.trim());
        //                         }
        //                     }}
        //                 />
        //             </GridItem>
        //             <GridItem xs={12} sm={12} md={5}>
        //                 <CustomInput
        //                     labelText="Contact Number"
        //                     id="contact-number"
        //                     formControlProps={{
        //                         fullWidth: true
        //                     }}
        //                     inputProps={{
        //                         // readOnly: props.view,
        //                         // value: contactNumber,
        //                         onChange: (e) => {
        //                             // setContactNumber(libs.validate(e.target.value, "number", 9) ? e.target.value : contactNumber);
        //                         },
        //                         onBlur: (e) => {
        //                             // setContactNumber(e.target.value.trim());
        //                         }
        //                     }}
        //                 />
        //             </GridItem>
        //             <GridItem xs={12} sm={12} md={7}>
        //                 <CustomInput
        //                     labelText="Email"
        //                     id="email"
        //                     formControlProps={{
        //                         fullWidth: true
        //                     }}
        //                     inputProps={{
        //                         // readOnly: props.view,
        //                         // value: email,
        //                         onChange: (e) => {
        //                             // setEmail(libs.validate(e.target.value, "all", 150) ? e.target.value : email);
        //                         },
        //                         onBlur: (e) => {
        //                             // setEmail(libs.validate(e.target.value.trim(), "email", 150) ? e.target.value : "")
        //                         }
        //                     }}
        //                 />
        //             </GridItem>
        //             <GridItem xs={12} sm={12} md={12}>
        //                 <CustomInput
        //                     labelText="Website"
        //                     id="website"
        //                     formControlProps={{
        //                         fullWidth: true
        //                     }}
        //                     inputProps={{
        //                         // readOnly: props.view,
        //                         multiline: true,
        //                         maxRows: 3,
        //                         // value: website,
        //                         onChange: (e) => {
        //                             // setWebsite(libs.validate(e.target.value, "all", 150) ? e.target.value : website);
        //                         },
        //                         onBlur: (e) => {
        //                             // setWebsite(e.target.value.trim());
        //                         }
        //                     }}
        //                 />
        //             </GridItem>
        //         </GridContainer>

        //         {
        //             // props.view ?
        //             //     <div style={{ textAlign: "center", marginBottom: "20px" }}>
        //             //         <Button simple onClick={() => back()} color="primary">Back</Button>
        //             //     </div> :
        //             //     <div style={{ textAlign: "center", marginBottom: "20px" }}>
        //             //         <Button simple onClick={() => back()} color="primary">Back</Button>  
        //             //         {/* <Button disabled={submitting} onClick={submitData} color="primary">Save</Button> */}
        //             //     </div>
        //         }
        //         {/* </Card> */}

        //     </Collapse>
        //     {/* </GridItem> */}
        // </>
        // //     </GridContainer>
        // // </div>



