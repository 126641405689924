import React from "react"
import axios from "axios";
import config from "config.js"
import libs from "controllers/libs";

import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import IconButton from "@material-ui/core/IconButton";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { Typography } from "@material-ui/core";
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import { TextField } from "@material-ui/core";

const override = css`
display: block;
margin: 0px;
border-color: red;
`;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}



export default function Fees() {
    const [view, setView] = React.useState(0) //0:-loader, 1:-fee component
    const [showEdit, setShowEdit] = React.useState(false)
    const [newFee, setNewFee] = React.useState("")
    const [fee, setFee] = React.useState("")

    const [snackBarType, setSnackBarType] = React.useState("error");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [submitting, setSubmitting] = React.useState(false)


    function openSnackBar(msg, success) {
        //function to tost snackbar message
        setOpenSnack(true);
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const handleEditFeeClick = (fee) => {
        //function to handel fee edit click
        setNewFee(fee)
        setShowEdit(true)
    }

    const fetchCardFee = async () => {
        //function to fetch fee
        try {
            let resp = await axios.get(`${config.url().baseURL}/get-cards-fee`)
            setView(1)
            if (resp.data.err) openSnackBar(resp.data.msg)
            else setFee(resp.data.data.fee)
        } catch (error) {
            openSnackBar("Unable to fetch card fee.")
        }
    }
    
    React.useEffect(() => { fetchCardFee() }, [])

    const cancel = () => {
        //function to cancel updating fee
        setShowEdit(false)
    }
    const submit = async () => {
        //function to submit fee
        try {
            setSubmitting(true)
            if (newFee.length == 0) openSnackBar("Please enter fee.")
            else {
                let resp = await axios.post(`${config.url().baseURL}/set-cards-fee`, { fee: newFee })
                setSubmitting(false)
                setShowEdit(false)
                if (resp.data.err) openSnackBar(resp.data.msg)
                else {
                    fetchCardFee()
                    openSnackBar(resp.data.msg, true)
                }
            }
        } catch (error) {
            setSubmitting(false)
            openSnackBar("Unable to submit new card fee.")
        }
    }
    return (
        <>
            {
                view == 0 ?
                    <div style={{ textAlign: "center" }}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </div>
                    :
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem md={6}>
                                    <Typography variant="h6" component="h6" style={{ fontSize: "18px" }}>Card Fee</Typography>
                                </GridItem>
                                <GridItem md={1}>
                                    <Typography variant="h6" component="h6" style={{ fontSize: "18px" }}>:</Typography>
                                </GridItem>
                                <GridItem md={5}>
                                    {
                                        showEdit ?
                                            <GridContainer>
                                                <GridItem md={8}>
                                                    <TextField
                                                        fullWidth
                                                        id="filled-hidden-label-small"
                                                        value={newFee}
                                                        size="small"
                                                        onChange={(e) => setNewFee(libs.validate(e.target.value, "number", 9) ? e.target.value : newFee)}
                                                        onBlur={(e) => setNewFee(e.target.value.trim())}
                                                    />
                                                </GridItem>
                                                <GridItem md={1}>
                                                    <IconButton style={{ marginTop: "-10px", marginBottom: "-10px", color: "green" }} disabled={submitting} onClick={() => submit()} ><DoneIcon /></IconButton>
                                                </GridItem>
                                                <GridItem md={1}>
                                                    <IconButton style={{ marginTop: "-10px", marginBottom: "-10px", color: "red" }} onClick={() => cancel()} ><CancelIcon /></IconButton>
                                                </GridItem>
                                            </GridContainer>
                                            :
                                            <Typography variant="h6" component="h6" style={{ fontSize: "18px", display: "inlineBlock", }}>{fee}  &nbsp;&nbsp; <IconButton style={{ marginTop: "-15px", marginBottom: "-10px" }} onClick={() => handleEditFeeClick(fee)}  ><EditIcon /></IconButton></Typography>
                                    }
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card >

            }
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </>
    )
}

