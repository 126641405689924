import React from "react";
import GPWForm from "./GPWForm";


export default function MainForm({ type, back, snack, alertPop,id }) {
    let componentToRender;

    switch (type) {
        
        
        case "View":
            componentToRender = <GPWForm back={back} alertPop={alertPop} snack={snack}  id={id} type="View"/>;
            break;

        default:
            componentToRender = <GPWForm   type="Edit" back={back} alertPop={alertPop} snack={snack}  id={id}/>;
            break;
    }

    return (
        <div>
            {componentToRender}
        </div>
    );
}
