import React from "react";
import config from "config.js"
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import Button from "components/CustomButtons/Button.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Table from "components/Table/Table.js";
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import { Place } from "@material-ui/icons";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";


const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
  
`;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        textAlign: "center"
    },
    sideHead: {
        fontWeight: "500",
        fontSize: "14px",
        marginBottom: "0px !important",
        marginTop: "25px !important",
        color: "#474747",
    },

}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function FeeEditForm(props) {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [tableData, setTableData] = React.useState([]);
    const [dialogueButtons, setDialogueButtons] = React.useState("");
    const [remarks, setRemarksValue] = React.useState("")
    const [respAlert, setRespAlert] = React.useState(null);
    
    // var remarks = ""
    let service = user.services.find(service => service.name == "Corporate Fee Edit")

    React.useEffect(() => {
        if (props.corpID !== null) {
            fetchFeeDetails(props.corpID)
            setRemarksValue("")

        }
    }, [props.corpID]);

    // const setRemarksValue = (value) => {
    //     remarks = value
    // }

    const fetchFeeDetails = (id) => {
        //function to fetch company details
        axios.get(`${config.url().baseURL}/get-fee-details/${id}`).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                // let service = user.services.find(service => service.name == "Corporate Fee Edit")
                if (service.type == "maker") {
                    setDialogueButtons(<Button onClick={() => { closeModal() }} color="danger" size="sm"> Close </Button>)
                } 
              
                else {
                    setDialogueButtons(<Button onClick={() => { closeModal() }} color="danger" size="sm"> Close </Button>)
                }
                if (respData.data.fee_type == "monthly") {

                    setTableData([
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Fee Type</b>, ":", "Monthly Fee"],
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Fee</b>, ":", respData.data.service_fee],
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >VAT</b>, ":", respData.data.fee_tax],
                    ])

                } else if (respData.data.fee_type == "no fee") {
                    setTableData([
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Fee Type</b>, ":", "No Fee"],
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Fee</b>, ":", respData.data.service_fee],
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >VAT</b>, ":", respData.data.fee_tax],
                    ])

                } else {
                    setTableData([
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Fee Type</b>, ":", "Per Employee Service Fee"],
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Fee</b>, ":", respData.data.service_fee],
                        [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >VAT</b>, ":", respData.data.fee_tax],
                    ])

                }

            } else {
                openSnackBar(respData.msg);
                closeModal()
            }
        }).catch((err) => {
            console.log(err)
            closeModal()
            openSnackBar("Unable to process.");
        });

    };

    function openSnackBar(msg) {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const closeModal = () => {
        //function to close modal
        props.close()

    }

    const changeStatus = (status) => {
        //function to change status of fee
        if (remarks.length !== 0 && remarks.trim().length !== 0) {
            axios.put(`${config.url().baseURL}/approve-fee-profile/${props.corpID}`, { status: status, user: user._id, remarks: remarks }, {
                headers: {
                    auth: sessionStorage.getItem("cx_id"),
                },
            }).then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    if (status == "Recheck") {
                        setRespAlert(
                            <SweetAlert
                                success
                                style={{ display: "block" }}
                                title="Rechecked "
                                onConfirm={() => {
                                    // setSubmitting(false)
                                    setRespAlert(null)
                                    setRemarksValue("")
                                    closeModal()
                                }}
                                confirmBtnCssClass={classes.button + " " + classes.success}
                            />
                        );
                    }
                    else {
                        setRespAlert(
                            <SweetAlert
                                success
                                style={{ display: "block" }}
                                title="Corporate fee approved"
                                onConfirm={() => {
                                    // setSubmitting(false)
                                    setRespAlert(null)
                                    setRemarksValue("")
                                    closeModal()
                                }}
                                confirmBtnCssClass={classes.button + " " + classes.success}
                            />
                        );
                    }
                } else {
                    setRemarksValue("")
                    openSnackBar(respData.msg);
                }
            }).catch((err) => {
                setRemarksValue("")
                openSnackBar("Unable to process.");
            });
        } else {
            openSnackBar("Please enter remarks.")
        }
    }

    return (
        <div className={classes.root}>
            {respAlert}
            <Collapse
                in={tableData.length == 0}
                style={{ transformOrigin: "0 0 0", textAlign: "center", height: "100px" }}
                {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
            </Collapse>
            <Collapse
                in={tableData.length !== 0}
                style={{ transformOrigin: "0 0 0", textAlign: "center", paddingLeft: "15px", paddingRight: "15px" }}
                {...(true ? { timeout: 500 } : {})}>

                <Table tableData={tableData} />
                <br />
                {(service.type == "checker" && props.status == "Updated") ?
                    <>
                        <TextField label="Remarks" fullWidth onChange={(e) => setRemarksValue(e.target.value)} />
                        <Button onClick={() => { changeStatus("Recheck") }} color="danger" size="sm">Recheck</Button>
                        <Button color="primary" onClick={() => { changeStatus("Checked") }} size="sm">Approve</Button>

                    </>
                    : ((service.type == "approver" && props.status == "Checked") ? <div>
                        <TextField label="Remarks" fullWidth onChange={(e) => setRemarksValue(e.target.value)} />
                        <Button onClick={() => { changeStatus("Recheck") }} color="danger" size="sm">Recheck</Button>
                        <Button color="primary" onClick={() => { changeStatus("Approved") }} size="sm">Approve</Button>
                    </div> : dialogueButtons)}
                {/* {dialogueButtons} */}
            </Collapse>
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div >
    );
}
