import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import swal from 'sweetalert';
import { Visibility } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

export default function ShowDuplicateCpr(props) {
    // console.log("called...............");
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    return (
        <Dialog open={props.open} maxWidth="md" fullWidth>
                {/* <DialogTitle>Excel Sheet</DialogTitle> */}
                <DialogContent>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Duplicate CPR</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* Map over your duplicate CPRs and generate table rows */}
                        {props.duplicateCprs.map((cpr, index) => (
                          <TableRow key={index}>
                            <TableCell>{cpr}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DialogContent>
                <IconButton aria-label="close" onClick={()=>props.BackToHome()} style={{ position: 'absolute', top: 10, right: 10 }}>
                  <CloseIcon />
                </IconButton>
              </Dialog>
    )
}