import { Button } from '@material-ui/core';
import axios from 'axios';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import * as React from 'react';
import W2wReport from '../W2wReport';
import WBReport from '../WBReport';
import "./style.css"
import UploadFileComponent from '../UploadFileComponent';
import LoadingComponent from '../Loder/Loader';
import { Share } from '@mui/icons-material';
import toast, { Toaster } from 'react-hot-toast';
import { useW2wContext } from '../../Provider/W2wContext';
import config from '../../../../config'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MISReport from '../MISReport';
//const urls = new Map(["W2wReport","bill/add-w2w-data"],["WBReport","bill/add-wb-data"])
const urls = Object.freeze({
    "W2wReport": "bill/add-w2w-data",
    "WBReport": "bill/add-wb-data"

})

function MainCard(props) {
    const { date, setDate, html, handleShareReport } = useW2wContext()
    const [type, setType] = React.useState("W2wReport");
    const [showFile, setShowFile] = React.useState(true)
    const [loader, showLoader] = React.useState(false)
    const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);

    const memoizedReportRenderer = React.useCallback(() => {
        switch (type) {
            case "W2wReport":
                return <W2wReport date={date} />;
            case "WBReport":
                return <WBReport />;
            case "MisReport":
                return <MISReport />
            default:
                return null;
        }
    }, [type, date]);

    const handleShareConfirmation = async () => {
        try {
            showLoader(true);
            await toast.promise(
                handleShareReport(type),
                {
                    loading: 'Sending...',
                    success: (response) => {
                        showLoader(false);
                        return <b>{response}</b>;
                    },
                    error: (error) => {
                        showLoader(false);
                        console.log(error);
                        return <b>{error.message}</b>;
                    },
                }
            );
        } catch (error) {
            console.log(error);
            showLoader(false);
        }
    };

    const handleOpenConfirmationDialog = () => {
        setOpenConfirmationDialog(true);
    };

    const handleCloseConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
    };



    // React.useEffect(() => {
    //     getW2wReport(date);
    // }, []);


    return (
        <>
            <Card className="card-container">
                <CardHeader>
                    <div className="header-container">
                        <div className="date-input-container">
                            <select
                                className="select-input custom-select" // Add custom-select class
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                <option value='W2wReport'> Wallet to Wallet Report</option>
                                <option value='WBReport'>Wallet Balance Report</option>
                                <option value='MisReport'>MIS Report</option>
                            </select>
                            <input
                                type="date"
                                className="date-input custom-date" // Add custom-date class
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                            />
                        </div>
                        <div className="buttons-container">
                            {(!loader) ? (
                                <Button
                                    className="share-button"
                                    variant="outlined"
                                    color="primary"
                                    component="span"
                                    startIcon={<Share />}
                                    onClick={handleOpenConfirmationDialog}
                                >
                                    Share
                                </Button>
                            ) : (
                                <LoadingComponent className="loading-spinner" />
                            )}

                        </div>
                    </div>
                </CardHeader>
                <CardBody style={{ minWidth: 800 }}>
                    {memoizedReportRenderer()}
                </CardBody>
            </Card>

            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Dialog
                open={openConfirmationDialog}
                onClose={handleCloseConfirmationDialog}
            >
                <DialogTitle>Confirm Share</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to share this report?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmationDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleShareConfirmation} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default MainCard;
