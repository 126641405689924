import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader } from '@material-ui/core';
import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import BillerModal from './BillerModal';
import config from "config.js"
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { width } from '@mui/system';


// styles
const useStyles = makeStyles({
    root: {

        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "rgb(213 213 213 / 50%)",
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: "1123"

    },

    headerStyle: {
        backgroundColor: '#144B85',
        padding: '25px',
        height: "5%",
        borderRadius: "6px",
    },
    headerTitle: {
        fontSize: '28px',
        fontWeight: '4500',
        color: "#fff",
    },
    headStyle: {
        position: 'relative',
        top: '10px',
        left: '-5%',
        // backgroundColor: 'red',
        color: 'rgb(0 0 0 / 90%)',
        padding: '10px',
        textAlign: 'left',
        //borderRadius: '10px',
        width: '80%',
        marginLeft: '10%',
        marginRight: '10%',
        marginBottom: '10%',
        borderRadius: '6px',
        background: '#001D8F',
        backgroundColor: "transparent",
        boxShadow: 'inset -6px -6px 12px transparent, inset 6px 6px 12px transparent',
    },
    cardContainer: {
        marginTop: '5%',
        height: "fit-content",
        padding: '10px',
        marginLeft: "100px",
        minWidth: "400px",
        maxWidth: "600px",
        backgroundColor: "rgb(0 0 0 / 10%)",
        border: '1px solid rgba(255,255,255,0.25)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
        borderRadius: '8px',
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '0px 0px 10px gray'
        },
        // Add a media query to adjust the layout on small screens
        '@media only screen and (max-width: 600px)': {
            flexDirection: 'column'
        }
    },
    mainContainer: {
        display: "flex",
        flexDirection: "row",
        paddingRight: '80px',
        maxHeight: '90vh',
        height: '90vh',
        overflowY: "scroll",
        //background: "#1A599C",
        perspective: "1px",
        backgroundColor: "rgba(202, 202, 202, 0.15)",
        //overflowX:"hidden"
        //flexBasis
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
    listContainer: {
        marginBottom: "4%",
        borderRadius: '6px',
        backgroundColor: '#fff',
        paddingRight: "8%",
        //overflowY: "hidden",
        minHeight: "100px",
        // minWidth:"20%",
        //sbackgroundColor: 'white',
        '&:hover': {
            cursor: 'grab'
        }
    },
    listHeader: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        backgroundColor: '#0079BF',
        color: 'white',
        padding: '5px'
    },
    listItem: {
        padding: '10px'
    },
});
const HomeBillers = () => {
    const classes = useStyles();
    const dragItem = useRef();
    const dragOverItem = useRef();
    const [lists, setLists] = useState([]);
    const [msg, setMsg] = useState("")
    const [status, setStatus] = useState()
    const [main, setMain] = useState(null)
    const [startX, setStartX] = useState(0)
    const [startY, setStartY] = useState(0)
    const [scrollLeft, setScrollLeft] = useState(0)
    const [scrollTop, setScrollTop] = useState(0)
    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [loader, setLoader] = useState("flex")
    const handleOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    //function  to add new biller title on the home screen
    const handleConfirm = async (value) => {
        try {
            setLoader("flex")
            let resp = await axios.post(`${config.url().baseURL}/create-home-group`, { group: value })
            if (resp.data.status) {
                getInitailData()
                setModalOpen(false);
                handleClick("Updated Successfully.", "success")
            }
        } catch (error) {
            setModalOpen(false);
            handleClick("Unable to Update.", "error")
        }
    }

    const handleClick = (msg, status) => {
        setOpen(true);
        setMsg(msg)
        setStatus(status)
    };

    const handleClose = (event) => {
        setOpen(false);
        setMsg()
    }
    // function to fetch  all the billers
    let getInitailData = async () => {
        try {
            if (lists.length === 0) {
                setLoader("flex")
            }
            let resp = await axios.get(`${config.url().baseURL}/get-biller-group`)
            setLists(resp.data.data)
            setLoader("none")
        } catch (error) {
            console.log(error);
        }
    }

    // function to swap the billers
    let updateBiller = async (keyIndex, value) => {
        try {
            setLoader("flex")
            let { key } = lists[keyIndex]
            if (value.group === key) {
                handleClick("It is not possible to swap the order.", "error")
                setLoader("none")
                return false
            } else {
                value.group = key
                let resp = await axios.post(`${config.url().baseURL}/update-biller`, { biller: value })
                if (resp.data.status) {
                    handleClick("Updated Successfully.", "success")
                    setLoader("none")
                    return true
                }
            }
        } catch (error) {
            handleClick("Unable to Update.", "error")
            getInitailData()
            return false
        }
    }

    useEffect(() => {
        getInitailData();
        setMain(document.getElementById("cardCont"))
    }, [])
    const apple = (e) => {
        // e.preventDefault();
        setStartX(e.pageX + main.offsetLeft)
        setScrollLeft(main.scrollLeft)
        setStartY(e.pageY + main.offsetTop)
        setScrollTop(main.scrollTop)
    };

    const test = (e) => {
        //e.preventDefault();
        const x = e.pageX - main.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        main.scrollLeft = scrollLeft + walk;
        const y = e.pageY + main.offsetTop;
        const walky = (y - startY) * 3; //scroll-fast
        main.scrollTop = scrollTop + walky;
    };

    const dragStart = (e, position, listIndex) => {
        dragItem.current = { position, listIndex };
    };

    const dragEnter = (e, position, listIndex) => {
        dragOverItem.current = { position, listIndex };
    };

    const drop = async (e) => {
        const copyListItems = [...lists];
        const { position: dragItemPosition, listIndex: dragListIndex } = dragItem.current;
        const { position: dragOverPosition, listIndex: dragOverListIndex } = dragOverItem.current;
        const dragItemContent = copyListItems[dragListIndex].value[dragItemPosition];
        copyListItems[dragListIndex].value.splice(dragItemPosition, 1);
        copyListItems[dragOverListIndex].value.splice(dragOverPosition, 0, dragItemContent);
        await updateBiller(dragOverItem.current.listIndex, dragItemContent)
        //await getInitailData()
        // if (status===true){
        //  alert("here")
        //  setLists(copyListItems)
        // }else{
        //  setLists(lists)
        // }
        dragItem.current = null;
        dragOverItem.current = null;
    };

    //function to delete the card
    const handleCardDelete = async (title) => {
        try {
            setLoader("flex")
            let resp = await axios.post(`${config.url().baseURL}/remove-group`, { group: title })
            if (resp.data.status) {
                getInitailData()
                handleClick("Removed Successfully.", "success")
            }
        } catch (error) {
            setLoader("none")
            handleClick("Unable to Remove.", "error")
        }
    }


    return (


        <>
            <div className={classes.headerStyle}  >
                <span className={classes.headerTitle}> BFC Billers</span>
                <Tooltip title="Add new Title">

                    <AddIcon style={{ marginLeft: "90%", color: "#fff" }} onClick={handleOpen} />
                </Tooltip>
            </div>

            <div className={classes.mainContainer} id="cardCont">
                {/* onMouseMove={(e)=>{test(e)}} onMouseDown={(e)=>{apple(e)}} */}
                <div className={classes.root} style={{ display: loader }}>
                    <CircularProgress />
                </div>
                {lists.map((list, listIndex) => (
                    <div className={classes.cardContainer} onDrag={(e) => { test(e) }} onMouseDown={(e) => { apple(e) }}>
                        <CardHeader
                            className={classes.headStyle}
                            title={list.key}
                            action={

                                (list.key !== "Billers") ?

                                    <Tooltip title="Delete">
                                        <DeleteIcon
                                            onClick={() => { handleCardDelete(list.key) }}
                                        />
                                    </Tooltip> : ""
                            }
                        />
                        {list.value.length > 0 ? (
                            list.value.map((item, index) => (
                                <Card
                                    className={classes.listContainer}
                                    onDragStart={(e) => dragStart(e, index, listIndex)}
                                    onDragEnter={(e) => dragEnter(e, index, listIndex)}
                                    onDragEnd={drop}
                                    key={index}
                                    draggable>
                                    <CardHeader
                                        className={classes.listItem}
                                        title={item.issureName}
                                        avatar={<img src={item.icon ? item.icon : "https://picsum.photos/200"} alt="" height="80px" draggable="false" />}
                                    />
                                </Card>
                            ))
                        ) : (
                            <Card className={classes.listContainer}
                                //onDragStart={(e) => dragStart(e, index, listIndex)}
                                onDragEnter={(e) => dragEnter(e, 0, listIndex)}
                                onDragEnd={drop}
                                key={0}>
                                <CardHeader
                                    style={{ margin: "5%" }}
                                    title="No Billers"
                                // avatar={<img src="https://picsum.photos/200" height="50%" width="50%" />}
                                />
                            </Card>
                        )}
                    </div>
                ))}

            </div >


            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={status} sx={{ width: '100%' }}>
                    {msg}
                </Alert>
            </Snackbar>
            <BillerModal open={modalOpen} onClose={handleModalClose} onConfirm={handleConfirm} />



        </>
    );
};
export default HomeBillers;