import React from "react";
import config from "config.js"
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import Button from "components/CustomButtons/Button.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import Dropzone from "react-dropzone";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Document, Page } from 'react-pdf';
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import GridContainer from "components/Grid/GridContainer";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";


import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
  
`;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        textAlign: "center"
    },
    sideHead: {
        fontWeight: "500",
        fontSize: "14px",
        marginBottom: "0px !important",
        marginTop: "25px !important",
        color: "#474747",
    },

}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function FeeEditForm(props) {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [respAlert, setRespAlert] = React.useState(null);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [serviceFee, setServiceFee] = React.useState("");
    const [feeType, setFeeType] = React.useState("");
    const [agreement, setAgreement] = React.useState("");
    const [agreementName, setAgreementName] = React.useState("");
    const [agreementBtnClr, setAgreementBtnClr] = React.useState("secondary");

    const [documentViewerData, setDocumentViewerData] = React.useState("");
    const [openImageViewer, setOpenImageViewer] = React.useState(false);
    const [openPDFeViewer, setOpenPDFViewer] = React.useState(false);
    const [numPages, setNumPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [submitting, setSubmitting] = React.useState(false);

    const [vat, setVat] = React.useState("");

    const [values, setValues] = React.useState({
        serviceFeeErr: null,
        serviceFeeTypeErr: null
    });




    React.useEffect(() => {
        if (props.corpID !== null) {
            setServiceFee("")
            fetchFeeDetails(props.corpID)
        }
    }, [props.corpID]);

    const fetchFeeDetails = (id) => {
        //function to fetch company details
        axios.get(`${config.url().baseURL}/get-fee-details/${id}`).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                setServiceFee(respData.data.service_fee)
                setVat(respData.data.fee_tax)
                if (respData.data.fee_type == "transaction" || respData.data.fee_type == "service") {
                    setFeeType('transaction')
                } else {
                    setFeeType(respData.data.fee_type)
                }

            } else {
                openSnackBar(respData.msg);
                closeModal()
            }
        }).catch((err) => {
            closeModal()
            openSnackBar("Unable to process.");
        });

    };

    const verifyLength = (value, length) => {
        // function that verifies if a string has a given length or not

        if (value.length >= length) {
            return true;
        }
        return false;
    };

    const verify = (value, type, errVariable, length) => {
        switch (type) {
            case "length":
                if (verifyLength(value, length)) {
                    setValues({ ...values, [errVariable]: false });
                } else {
                    setValues({ ...values, [errVariable]: true });
                }
                break;
            default:
                break;
        }
    };

    const validateForm = () => {
        //function to validate form
        var flag = true, serviceFeeErr = false, serviceFeeTypeErr = false, vatErr = false;
        let snackBarMsg = ""
            if (!verifyLength(feeType, 1)) {
            serviceFeeTypeErr = true
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select the Fee type." : snackBarMsg);

        }
        if (feeType !== "no fee") {
            if (!verifyLength(serviceFee, 1)) {
                serviceFeeErr = true
                flag = false
            snackBarMsg = (snackBarMsg == "" ? "Fee should be greater than zero." : snackBarMsg);
            }
            if (!verifyLength(vat, 1)) {
                vatErr = true
                flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select VAT." : snackBarMsg);

            }
            if(serviceFee <= 0){
                serviceFeeErr = true
                flag = false
            snackBarMsg = (snackBarMsg == "" ? "Fee should be greater than zero." : snackBarMsg);

            }
        }
        if (!verifyLength(agreementName, 2)) {
            setAgreementBtnClr("danger")
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please Select Agreement" : snackBarMsg);

        }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }
    const saveFeeDetails = () => {
        //function to save fee details

        if (validateForm()) {
            let newData = {
                type: feeType,
                fee: serviceFee,
                user: user._id,
                agreement: agreement,
                vat: vat ? vat : 0
            }
            if (!submitting) {
                axios.put(`${config.url().baseURL}/update-fee-profile/${props.corpID}`, newData, {
                    headers: {
                        auth: sessionStorage.getItem("cx_id"),
                    },
                }).then((resp) => {
                    let respData = resp.data;
                    if (!respData.err) {
                        setRespAlert(
                            <SweetAlert
                                success
                                style={{ display: "block" }}
                                title={respData.msg}
                                onConfirm={() => {
                                    setSubmitting(false)
                                    deleteAgreement()
                                    setRespAlert(null)
                                    closeModal()
                                }}
                                confirmBtnCssClass={classes.button + " " + classes.success}
                            />
                        );
                    } else {
                        setSubmitting(false)
                        openSnackBar(respData.msg);
                    }
                }).catch((err) => {
                    setSubmitting(false)
                    openSnackBar("Unable to process.");
                });
            }
        } else {
            // openSnackBar("Please fill All details.")
            setSubmitting(false)
        }
    }


    function openSnackBar(msg) {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const closeModal = () => {
        //function to close modal
        setServiceFee("")
        setVat("")
        setAgreementBtnClr("secondary");
        setAgreementName("")
        props.close()
    }

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    const agreementDrop = async (images) => {
        if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
            if (images[0].size < 2000000) {
                getBase64(images[0], (result) => {
                    setAgreement(result);
                });
                setAgreementBtnClr("secondary");
                setAgreementName(images[0].name);
            } else {
                openSnackBar("File size is too large.");
            }
        } else {
            openSnackBar("Please upload jpg,jpeg and png files");
        }
    };

    function fileViewer(file) {
        let extension = file.substring(file.indexOf("/") + 1, file.indexOf(";base64"));
        if (extension == "pdf") {
            console.log(file)
            setDocumentViewerData(file)
            setOpenPDFViewer(true)
        } else {
            setDocumentViewerData(file)
            setOpenImageViewer(true)
        }
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const deleteAgreement = () => {
        setAgreement("")
        setAgreementName("")
    }

    const customStyles = {
        content: {
            zIndex: "2147483647"
        },
        overlay: {
            zIndex: "2147483647"
        }
    };
    return (
        <div className={classes.root}>
            {respAlert}
            <Collapse
                in={feeType == ""}
                style={{ transformOrigin: "0 0 0", textAlign: "center", height: "100px" }}
                {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
            </Collapse>
            <Collapse
                in={feeType}
                style={{ transformOrigin: "0 0 0" }}
                {...(true ? { timeout: 500 } : {})}>

                <GridItem xs={12} sm={12}>
                    <p style={{ textAlign: "left" }}>
                        Fee Type
                        <small className={classes.required}>*</small>
                    </p>
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <FormControl
                        className={classes.radioGroup}
                        error={values.serviceFeeTypeErr}
                        style={{ textAlign: "left !important" }}
                        fullWidth
                        component="fieldset">
                        <RadioGroup
                            row
                            aria-label="position"
                            value={feeType}
                            disabled={props.edit}

                            onChange={(e) => {
                                setFeeType(e.target.value);
                                verify(e.target.value, "length", "feeTypeErr", 3);
                                if (e.target.value == "no fee") {
                                    // setServiceFee("0.00")
                                }
                                if (e.target.value != "transaction") {
                                    //  setVat('')
                                }
                            }}
                            name="position">
                            <FormControlLabel
                                className={classes.statusText}
                                value="monthly"
                                control={<Radio color="primary" />}
                                label="Monthly Fee"
                            />

                            <FormControlLabel
                                className={classes.statusText}
                                value="transaction"
                                control={<Radio color="primary" />}
                                label="Per Employee Service Fee"
                            />
                            <FormControlLabel
                                className={classes.statusText}
                                value="no fee"
                                control={<Radio color="primary" />}
                                label="No Fee"
                            />
                        </RadioGroup>
                    </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <CustomInput
                        error={values.serviceFeeErr}
                        labelText={
                            <span>
                                Fee in BHD <small className={classes.required}>*</small>
                            </span>
                        }
                        id="service-fee"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            autoComplete: "off",
                            value: serviceFee,
                            disabled: feeType == "no fee",
                            onChange: (e) => {
                                const re = /^\d*\.?\d*$/;
                                if (e.target.value === "" || re.test(e.target.value)) {
                                    
                                    setServiceFee(e.target.value);
                                }
                                verify(e.target.value, "length", "serviceFeeErr", 1);
                            },
                            onBlur: (e) => {
                                setServiceFee(e.target.value.trim());
                            }
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <br />
                    <FormControl style={{ textAlign: "left" }} fullWidth>
                        <Select
                            value={vat}
                            disabled={feeType == "no fee"}
                            onChange={(e) => {
                                setVat(e.target.value);
                            }}>
                            <MenuItem value="">Select </MenuItem>
                            <MenuItem value="5">5% </MenuItem>
                            <MenuItem value="10">10%</MenuItem>
                        </Select>
                    </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            {agreementName !== "" ? (
                                <CustomInput
                                    labelText="Agreement"
                                    id="dir-id-proof"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        autoComplete: "off",
                                        readOnly: true,
                                        value: agreementName,
                                    }}
                                />
                            ) : (
                                <>
                                    <br />
                                    <Dropzone onDrop={agreementDrop}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <Button size="sm" fullWidth color={agreementBtnClr}>
                                                    Agreement
                                                </Button>
                                            </div>
                                        )}
                                    </Dropzone>
                                </>
                            )}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} style={{ textAlign: "left" }}>
                            {agreementName !== "" ? (
                                <>
                                    <br /><br />
                                    <Tooltip placement="right" title="Delete Document">
                                        <IconButton
                                            onClick={() => deleteAgreement()}
                                            style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                                            aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip placement="bottom" title="View Document">
                                        <IconButton
                                            onClick={() => fileViewer(agreement)}
                                            style={{ marginTop: "-10px", marginBottom: "-10px", color: "#014FA4" }}
                                            aria-label="delete">
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            ) : null}
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <br />
                <Button
                    onClick={() => {
                        closeModal();
                        deleteAgreement()
                    }}
                    color="danger"
                    size="sm">
                    Close
                </Button>
                <Button
                    onClick={() => {
                        saveFeeDetails()
                    }}
                    color="primary"
                    size="sm" >
                    Save
                </Button>
                           </Collapse>
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    // paper: classes.processModal,
                }}
                open={openPDFeViewer}
                TransitionComponent={Transition}
                onClose={() => {
                    setOpenPDFViewer(false);
                    setDocumentViewerData("");
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <Document
                        file={documentViewerData}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <p>Page {pageNumber} of {numPages}</p>
                    <Button size="sm" color="primary" onClick={() => {
                        if (pageNumber < 2) {
                            setPageNumber(1)
                        } else {
                            let newPageNumber = pageNumber - 1
                            setPageNumber(newPageNumber)
                        }
                    }} >Pervious</Button>
                    <Button size="sm" color="primary"
                        onClick={() => {
                            if (numPages > pageNumber) {
                                let newPageNumber = pageNumber + 1
                                setPageNumber(newPageNumber)
                            } else {
                                setPageNumber(numPages)
                            }
                        }}
                    >Next</Button>
                </DialogContent>
            </Dialog>
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
            {
                openImageViewer && (
                    <Lightbox
                        reactModalStyle={customStyles}
                        mainSrc={documentViewerData}
                        onCloseRequest={() => setOpenImageViewer(false)}
                    />
                )
            }
        </div >
    );
}
