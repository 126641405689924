import * as React from 'react';
import axios from "axios";
import MUIDataTable from "mui-datatables";
import Collapse from "@material-ui/core/Collapse";
import config from "config.js"
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import moment from 'moment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import { creatCsvFile, downloadFile } from "download-csv";
import BounceLoader from "react-spinners/BounceLoader";
import SearchIcon from '@material-ui/icons/Search';
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Table from "components/Table/Table.js";

const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
export default function CardsActivationsReport() {
    const classes = useStyles();
    const [showgigInsuranceTable, setGigInsuranceTable] = React.useState(false)
    const [showLoader, setShowLoader] = React.useState(false)
    const [snackBarType, setSnackBarType] = React.useState("error");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [respAlert, setRespAlert] = React.useState(null);

    const [fromDate, setFromDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
    const [toDate, setToDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
    const [filter, setFilter] = React.useState("Gig motor insurance");

    const [gigInsuranceDetails, setGigInsuranceDetails] = React.useState([]);

    // const [showDetails, setShowDetails] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [csvData, setCsvData] = React.useState([]);

    React.useEffect(() => {
        searchGIGInsurance();
    }, []);
    const columns = [

        {
            name: "Date",
            label: "Date",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "Name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "Insurance Type",
            label: "Insurance Type",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "CPR",
            label: "CPR",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "MobileNo",
            label: "Mobile",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "Status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
            },
        },
        // {
        //     name: "actions",
        //     label: "Actions",
        //     options: {
        //         filter: false,
        //         sort: false,
        //     },
        // }
    ];

    const options = {
        selectableRows: false,
        elevation: 0,
        print: false,
        onDownload: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = "GIG Insurance.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        }
    };
    const validateSearch = () => {
        let snackBarMsg = ""
        let flag = true
        if (fromDate.length == 0) {
            flag = false
            snackBarMsg = "Please select from date."
        }
        if (toDate.length == 0) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select To date." : snackBarMsg);
        }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }
    function openSnackBar(msg, success) {
        setOpenSnack(true);
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }
    const searchGIGInsurance = () => {
        try {
            if (validateSearch()) {
                let data = {
                    "filter": filter,
                    "fromDate": moment(fromDate).format("MM-DD-YYYY"),
                    "toDate": moment(toDate).format("MM-DD-YYYY")
                }
                setShowLoader(true);
                setSubmitting(true);
                setGigInsuranceDetails([]);
                setGigInsuranceTable(false);
                axios.post(`${config.url().appURL}/CXPortal/v17/cx/Insurance/status`, data)
                    .then((resp) => {
                        setSubmitting(false);
                        setShowLoader(false);
                        setGigInsuranceTable(true)
                        let resData = resp.data;
                        if (resData.err) {
                            openSnackBar(resp.data.msg)
                        }
                        else {
                            let updatedActivationDetails = []
                            resp.data.data.map((activations) => {
                                activations.Date = activations.Date ? moment(activations.Date).format("DD/MM/YYYY") : "";
                                activations.Name = activations.Name ? activations.Name : "";
                                activations.CPR = activations.CPR ? activations.CPR : "";
                                activations.MobileNo = activations.MobileNo ? activations.MobileNo : "";
                                activations.Status = activations.Status ? activations.Status : "";
                                activations.Insurance_Type = activations.Insurance_Type ? activations.Insurance_Type : ""
                                updatedActivationDetails.push(activations);
                                // activations.actions =
                                //     <Tooltip placement='top' title="View Details">
                                //         <IconButton
                                //             onClick={() => openUserDetails(activations.userID)}
                                //             style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                //             aria-label="view">
                                //             <AccountCircleIcon />
                                //         </IconButton>
                                //     </Tooltip>

                            })
                            setGigInsuranceDetails(updatedActivationDetails)
                            var downloadedData = resp.data.data.map((activations) => {
                                console.log(activations,"444");
                                let temp = {};
                                temp.Date = activations.Date;
                                temp.Name = activations.Name;
                                temp.Insurance_Type = activations.Insurance_Type
                                temp.CPR = activations.CPR;
                                temp.MobileNo = activations.MobileNo;
                                temp.Status = activations.Status;
                                return temp;
                            })
                            setCsvData(downloadedData);
                        }
                    }).catch((err) => {
                        openSnackBar('Unable To Process !!')
                    })
            }
        } catch (error) {
            openSnackBar("Unable to fetch Activation Report.")
        }
    }

    return (
        <>
            {respAlert}
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={true}
                        style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>From Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setFromDate(event.target.value) }, onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setFromDate("")
                                                    openSnackBar("Please enter valid date.")
                                                } else {
                                                    setFromDate(event.target.value)
                                                }
                                            },
                                            value: fromDate,
                                        }}
                                    />
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>To Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setToDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setToDate("")
                                                    openSnackBar("Please enter valid date.")
                                                } else {
                                                    setToDate(event.target.value)
                                                }
                                            }, value: toDate,
                                        }}
                                    />
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>Type</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <FormControl className={classes.groupSelect} fullWidth>
                                        <option value="Gig motor insurance">Insurance</option>
                                    </FormControl>
                                    {!submitting ? (
                                        <Button className={classes.addDeptButton}
                                            onClick={() => searchGIGInsurance()}
                                            size="lg" color="primary">
                                            <SearchIcon fontSize="medium" />
                                        </Button>
                                    ) : (
                                        <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
                                    )}
                                </Paper>

                            </GridItem>
                        </GridContainer>
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showgigInsuranceTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}> Insurance</h4>
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={gigInsuranceDetails} columns={columns} options={options} />
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </>
    );
}