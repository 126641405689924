import { Collapse } from "@mui/material";
import React, { useState } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import axios from "axios"
import config from "config.js";
import moment from 'moment';
import libs from "controllers/libs";

import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from '@material-ui/core/Dialog';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from "components/CustomButtons/Button.js";
import InputBase from "@material-ui/core/InputBase";
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { forwardRef } from 'react';


const override = css`
display: block;
margin: 0px;
border-color: red;
`;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        display: "flex",
    },
    input: {
        flex: 1,
        marginTop: "10px",
        height: "45px",
        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    remarksHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
}));

const columns = [
    {
        field: "date",
        title: "Created On",
        render: rowData => moment(rowData.date).format("DD/MM/YYYY") != "Invalid date" ? <span> {moment(rowData.date).format("DD/MM/YYYY") + " " + moment(rowData.date).format("hh:mm:ss A")}</span> : rowData.date,
    },
    {
        field: "companyName",
        title: "Corporate Name",
    },
    {
        field: "companyCR",
        title: "Company ID",
    },
    {
        field: "kycApplicationID",
        title: "KYC Application ID",
    },
    {
        field: "card_fee",
        title: "Card Fee    ",
    },
    {
        field: "tabiib_fee",
        title: "Tabiib Card Fee    ",
    }
];
const options = {
    showTitle: false,
    actionsColumnIndex: -1,
    exportButton: false,
};

function CorporateCardFee() {
    const classes = useStyles();
    const [view, setView] = useState(0); //0=loader 
    const [corporateList, setCorporateList] = useState([]);
    const [snackType, setSnackType] = useState("error");
    const [snackMsg, setSnackMsg] = useState("")
    const [openSnack, setOpenSnack] = React.useState(false);

    const [corporateID, setCorporateID] = useState("")
    const [newFee, setNewFee] = useState("")
    const [newTabibFee, setNewTabibFee] = useState("");
    const [openFeeEdit, setOpenFeeEdit] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [openTabiibFeeEdit, setOpenFeeTabiibFeeEdit] = useState(false);
    const [tabibSubmitting, setTabibSubmitting] = useState(false);


    const openSnackBar = (msg, success) => {
        setOpenSnack(true);
        if (success) {
            setSnackType("success")
        }
        else {
            setSnackType("error")
        }
        setSnackMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false)
            setSnackMsg("");
        }, 5000);
    }
    const actions = [
        {
            icon: "edit_icon",
            tooltip: 'Edit card fee',
            onClick: (event, rowData) => handleEditButtonClick(rowData._id, rowData.card_fee, rowData.tabiib_fee)
        },
        {
            // icon: "save" ,
            icon:forwardRef((props, ref) => <CreditScoreIcon {...props} ref={ref} />),
            tooltip: 'Edit Tabib Card fee',
            onClick: (event, rowData) => handleEditTabibButtonCLick(rowData._id,rowData.card_fee, rowData.tabiib_fee)
        }
    ]


    const fetchCorporateList = async () => {
        //function to fetch corporate list
        try {
            let res = await axios.get(`${config.url().baseURL}/get-card-fee-companies`)
            setView(1)
            if (res.data.err) openSnackBar(res.data.msg)
            else 
            {
                setCorporateList(res.data.data)

            }
        } catch (error) {
            setView(0)
            openSnackBar("Unable to fetch the data.")
        }
    }
    React.useEffect(() => { fetchCorporateList() }, [])

    const submitFee = async () => {
        //function to submit new fee
        if (newFee.length == 0) openSnackBar("Please enter fee.")
        else {
            try {
                setSubmitting(true)
                let res = await axios.post(`${config.url().baseURL}/update-card-fee/${corporateID}`, { CardFee: newFee , tabiibCardFee :newTabibFee})
                setView(1)
                setSubmitting(false)
                if (res.data.err) openSnackBar(res.data.msg)
                else {
                    openSnackBar(res.data.msg, true)
                    fetchCorporateList()
                    setNewFee("")
                    setOpenFeeEdit(false)
                }
            } catch (error) {
                setSubmitting(false)
                openSnackBar("Unable to update the fee.")
            }
        }
    }

    const submitTabiibFee = async () => {
        //Function to submit Tabib Card fee
        if (newTabibFee.length == 0) openSnackBar("Please enter new tabib card fee")
        else {
            try {
                setTabibSubmitting(true);
                let resp = await axios.post(`${config.url().baseURL}/update-card-fee/${corporateID}`, { CardFee:newFee ,tabiibCardFee: newTabibFee })
                setTabibSubmitting(false);
                console.log(resp.data);
                if (resp.data.err) openSnackBar(resp.data.msg)
                else {
                    openSnackBar(resp.data.msg, true)
                    fetchCorporateList()
                    setNewTabibFee("")
                    setOpenFeeTabiibFeeEdit(false);
                }
            } catch (error) {
                setTabibSubmitting(false)
                openSnackBar("unable to update the fee");
            }
        }

    }
    const handleEditButtonClick = (id, fee, tabibfee) => {
        //function to edit card fee for corporate
        setCorporateID(id)
        setNewFee(fee)
        setNewTabibFee(tabibfee)
        setOpenFeeEdit(true)
    }

    const handleEditTabibButtonCLick = (id,fee,tabibFee) => {
        setCorporateID(id)
        setNewFee(fee);
        setNewTabibFee(tabibFee)
        setOpenFeeTabiibFeeEdit(true);
    }

    return (
        <>
            <Collapse in={view == 0} style={{ transformOrigin: "0 0 0", textAlign: "center" }} {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
            </Collapse>
            <Collapse in={view == 1} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 500 } : {})}>
                <Card>
                    <CardBody>
                        <MaterialTable actions={actions} components={{ Container: (props) => <Paper {...props} elevation={0} /> }} data={corporateList} columns={columns} options={options} />
                    </CardBody>
                </Card>
            </Collapse>
            <Dialog onClose={() => setOpenFeeEdit(false)} aria-labelledby="simple-dialog-title" PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }} open={openFeeEdit}>
                <h4 className={classes.remarksHead}>Please enter new fee.</h4>
                <Paper className={classes.dialogContainer}>
                    <InputBase
                        autoFocus={true}
                        fullWidth
                        className={classes.input}
                        placeholder=" Card Fee"
                        inputProps={{
                            onBlur: (e) => setNewFee(e.target.value.trim()),
                            onChange: (e) => { setNewFee(libs.validate(e.target.value, "float", 9) ? e.target.value : newFee) },
                            value: newFee,
                        }}
                    />
                    <Button className={classes.nextButton}
                        onClick={() => submitFee()}
                        simple
                        disabled={submitting}
                        size="lg" color="primary">
                        <ArrowForwardIcon fontSize="medium" />
                    </Button>
                </Paper>
            </Dialog>
            <Dialog onClose={() => setOpenFeeTabiibFeeEdit(false)} aria-labelledby="simple-dialog-title" PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }} open={openTabiibFeeEdit}>
                <h4 className={classes.remarksHead}>Please enter new Tabiib Card fee.</h4>
                <Paper className={classes.dialogContainer}>
                    <InputBase
                        autoFocus={true}
                        fullWidth
                        className={classes.input}
                        placeholder="Tabiib Card Fee"
                        inputProps={{
                            onBlur: (e) => setNewTabibFee(e.target.value.trim()),
                            onChange: (e) => { setNewTabibFee(libs.validate(e.target.value, "float", 9) ? e.target.value : newTabibFee) },
                            value: newTabibFee,
                        }}
                    />
                    <Button className={classes.nextButton}
                        onClick={() => submitTabiibFee()}
                        simple
                        disabled={tabibSubmitting}
                        size="lg" color="primary">
                        <ArrowForwardIcon fontSize="medium" />
                    </Button>
                </Paper>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackMsg("");
                    }}
                    severity={snackType}>
                    {snackMsg}
                </Alert>
            </Snackbar>
        </>

    )
}
export default CorporateCardFee;