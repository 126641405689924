import React from "react";
import { useHistory } from "react-router-dom";
import config from "config.js"
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import styles from "assets/jss/material-dashboard-react/views/loginPage.js";

const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function LoginPage(props) {
  const history = useHistory();
  const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

  React.useEffect(() => {
    checkUserSignedIn()
  }, []);

  const checkUserSignedIn = () => {
    //function to check user is already signed in
    if (sessionStorage.getItem('cx_id') !== undefined && sessionStorage.getItem("cx_id") !== null) {
      history.push("/dashboard");
    }
  }

  const signOn = () => {
    //function to get user signed in
    setSubmitting(true);
    let validForm = validate();
    if (validForm) {
      if (username !== "" || password !== "") {
        if (!submitting) {
          let userData = { username: username, password: password };
          axios.post(`${config.url().baseURL}/sign-in`, userData).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
              if (respData.token !== undefined) {
                sessionStorage.setItem("cx_id", respData.token.AuthToken);
                sessionStorage.setItem("cx_refresh_id", respData.token.RefreshToken);
                sessionStorage.setItem("cx_id_time", respData.token.Time);
                sessionStorage.setItem("cx_data", JSON.stringify(respData.data))
                setSubmitting(false);
                setSubmitting(false);
                history.push("/dashboard");
              } else {
                openSnackBar(respData.msg);
                setSubmitting(false);
              }
            } else {
              openSnackBar(respData.msg);
              setSubmitting(false);
            }
          })
            .catch((err) => { });
        }
      } else {
        openSnackBar("Invalid credentials");
        setSubmitting(false);
      }
    }
  };

  function openSnackBar(msg) {
    //function to tost snackbar message
    setOpenSnack(true);
    setSnackBarMsg(msg);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 1300);
  }
  const validate = () => {
    //function to validate user credentials
    if (username === "") {
      openSnackBar("Username field cannot be left blank");
      setSubmitting(false);
      return false;
    } else if (password === "") {
      openSnackBar("Password field cannot be left blank");
      setSubmitting(false);
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      <div
        className={classes.pageHeader}
        style={{
          backgroundColor: "#001e42",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={3}>
              <Card className={classes[cardAnimation]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4 className={classes.cardHead}>Welcome to BFC C X Portal</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="Username"
                      id="username"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setUsername(e.target.value);
                        },
                        value: username,
                        endAdornment: (
                          <InputAdornment position="end">
                            <AccountCircleIcon className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="pass"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setPassword(e.target.value);
                        },
                        value: password,
                        type: "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>lock_outline</Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                  </CardBody>
                  <p className={classes.divider}>
                    Forgot password ?{" "}
                    <span onClick={() => history.push("/forgot-password-admin")} className={classes.createLink}>
                      Reset Here.
                    </span>
                  </p>
                  <p className={classes.divider}>
                    <span onClick={() => history.push("/login")} className={classes.createLink}>
                      Switch to normal
                    </span>
                  </p>
                  <CardFooter className={classes.cardFooter}>
                    {!submitting ? (
                      <Button color="primary" onClick={() => signOn()} size="md">
                        Login
                      </Button>
                    ) : (
                      <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                    )}
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
            setSnackBarMsg("");
          }}
          severity="error">
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
