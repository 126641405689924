import React from "react";
import config from "config.js"
import { useHistory } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import InputBase from "@material-ui/core/InputBase";
import Collapse from "@material-ui/core/Collapse";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MuiAlert from "@material-ui/lab/Alert";
import styles from "assets/jss/material-dashboard-react/views/forgotPasswordStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
const useStyles = makeStyles(styles);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function PricingPage() {
    const classes = useStyles();
    const history = useHistory();
    const [newPassword, setNewPassword] = React.useState("");
    const [newConfPassword, setNewConfPassword] = React.useState("");
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackType, setSnackType] = React.useState("error");
    const [respAlert, setRespAlert] = React.useState(null);


    const hideAlert = () => {
        setRespAlert(null);
    };

    ;

    function validate() {
        //function to validate password and otp
        if (newPassword.length !== 0) {
            if (newPassword === newConfPassword) {
                var prog = [/[$@$!%*#?&]/, /[A-Z]/, /[0-9]/, /[a-z]/].reduce(
                    (memo, test) => memo + test.test(newPassword),
                    0
                );
                if (prog > 2 && newPassword.length > 5) {
                    prog++;
                }
                if (prog === 5) {
                    return true;
                } else {
                    openSnackBar(
                        "Password should contain one digit one special character one small letter and one capital letter",
                        "error"
                    );
                    return false;
                }
            } else {
                openSnackBar("Passwords didn't match.", "error");
                return false;
            }
        } else {
            openSnackBar("Please enter password.", "error");
            return false;
        }
    }
    function openSnackBar(msg, type) {
        //function to tost snackbar message
        setSnackBarMsg(msg);
        setSnackType(type);
        setOpenSnack(true);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const successChange = () => {
        hideAlert();
        setTimeout(function () {
            sessionStorage.removeItem("cx_id")
            history.push("/");
        }, 700);
    };

    const savePassword = () => {
        let valid = validate();
        if (valid) {
            axios.post(`${config.url().baseURL}/set-system-user-password`, { newPassword: newPassword }, { headers: { token: sessionStorage.getItem("cx_id") } })
                .then((resp) => {
                    let respData = resp.data;
                    console.log(respData);
                    if (!respData.err) {
                        setRespAlert(
                            <SweetAlert
                                success
                                style={{ display: "block" }}
                                title="Password has been changed."
                                onConfirm={() => successChange()}
                                confirmBtnCssClass={classes.button + " " + classes.success}
                            />
                        );
                    } else {
                        openSnackBar(respData.msg, "error");
                    }
                })
                .catch((err) => {
                    openSnackBar("Unable to process.", "error");
                });
        }
    };


    return (
        <div
            className={classes.pageHeader}
            style={{
                backgroundColor: "#001e42",
                backgroundSize: "cover",
                backgroundPosition: "top center",
            }}>
            {respAlert}
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={6}>
                        <h3 className={classes.title}>Set New Password</h3>
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={3}>
                        <Collapse
                            in={true}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <Paper component="form" className={classes.root}>
                                <InputBase
                                    className={classes.input}
                                    placeholder="Password"
                                    inputProps={{
                                        "aria-label": "Password",
                                        type: "password",
                                        value: newPassword,
                                        onChange: (event) => setNewPassword(event.target.value),
                                    }}
                                />
                            </Paper>
                            <Paper component="form" className={classes.root}>
                                <InputBase
                                    className={classes.input}
                                    placeholder="Confirm Password"
                                    inputProps={{
                                        "aria-label": "Confirm Password",
                                        type: "password",
                                        value: newConfPassword,
                                        onChange: (event) => setNewConfPassword(event.target.value),
                                    }}
                                />
                            </Paper>
                            <Button size="md" onClick={() => savePassword()} className={classes.backButton} color="green">
                                Submit
                            </Button>
                        </Collapse>
                        <Button
                            onClick={() => {
                                history.push("/");
                                sessionStorage.removeItem("cx_id")
                            }}
                            size="lg"
                            fullWidth
                            className={classes.backButton}
                            simple
                            color="white">
                            <ArrowBackIcon />
                            Back to login
                        </Button>
                    </GridItem>
                </GridContainer>
            </div>
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    );
}
