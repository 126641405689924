import * as React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Collapse from '@material-ui/core/Collapse'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PropagateLoader from 'react-spinners/PropagateLoader'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import Snackbar from "@material-ui/core/Snackbar";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import { creatCsvFile, downloadFile } from "download-csv";
import { useSelector } from 'react-redux';
import MuiAlert from "@material-ui/lab/Alert";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AssignmentIcon from "@material-ui/icons/Assignment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from '@material-ui/icons/Edit';
import config from "config.js"
import SpecialFeeEdit from "./Components/SpecialFeeEdit"
import CampaignDetailsView from './Components/CampaignDetailsView'
import CampaignLogs from './Components/CampaignLogs'
import { ViewList, DoneAll, ClearAll, Cancel } from '@material-ui/icons'
import CustomerTable from './Components/CustomerTable';
import Test from './Components/test'
import ShowCampaginDetialView from './Components/ShowCampaginDetials'
import { Dialog, DialogActions, DialogContent, DialogTitle, Modal, TextField } from '@material-ui/core'
const override = css`
display: block;
margin: 32px auto;
border-color: red;
`;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function SpecialRates() {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [showLoader, setShowLoader] = useState(false);
    const [showCorporateTable, setShowCorporateTable] = useState(true)
    const [openSnack, setOpenSnack] = useState(false)
    const [snackBarMsg, setSnackBarMsg] = useState("")
    const [snackBarType, setSnackBarType] = useState("error")
    const [csvData, setCsvData] = React.useState(false);
    const [respAlert, setRespAlert] = React.useState(null);
    const [showAdminViewBtn, setShowAdminViewBtn] = React.useState(false);
    const [statusCampaign, setStausCampaign] = React.useState("");
    const [showAddCorpBtn, setShowAddCorpBtn] = useState(true);
    const [openEdit, setOpenEdit] = useState(false);
    //new 
    const [openEditCustomer, setOpenEditCustomer] = useState(false);

    const [campaignList, setCampaignList] = useState([])
    const [showCompanyView, setShowCompanyView] = useState(false)
    const [companyId, setCompanyId] = useState("")
    const [approvalEdit, setApprovalEdit] = useState(false)
    const [makerEdit, setMakerEdit] = useState(false);
    const [log, setLog] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10); // Current number of rows per page
    const [count, setCount] = useState(100); // Current number of rows per page
    const [showEachCampaign, setShowEachCampaign] = useState(false);
    const [campaignID, setCampainID] = useState("")
    const [showModal, setShowModal] = useState(false);
    const [remark, setRemark] = useState("");
    const [modalText, setModalText] = useState("");
    const [buttonText, setButtonText] = useState("");
    const [toolTipText, setToolTipText] = useState("Check/ReCheck All Campagin");
    const [Id, setID] = useState("")


    // const [status, setStatus] = useState("")
    let status
    const [openConfirmation, setOpenConfirmation] = useState(false)
    // let id;
    // const [campaignID, setCampainID] = useState("");

    const today = moment().startOf('day');

    const stopCampaign = async () => {
        status = "STOPPED"
        if (!remark) {
            openSnackBar("Please Enter Remark")
        } else
            approveAllCampaign()
    }

    const RecheckCampaign = async (id) => {
        // setOpenConfirmation(true)
        status = "RECHECK"

        // setStatus(status)
        // setCampainID(id)
        if (!remark) {
            openSnackBar("Please Enter Remark")
        } else
            approveAllCampaign()

    }

    const ApproveCampaign = async (id) => {
        // setOpenConfirmation(true)
        status = "APPROVED"

        // setStatus(status)
        // setCampainID(id)
        if (!remark) {
            openSnackBar("Please Enter Remark")
        } else
            approveAllCampaign()

    }


    const RejectCampaign = async (id) => {
        // setOpenConfirmation(true)
        status = "REJECTED"

        // setStatus(status)
        // setCampainID(id)
        if (!remark) {
            openSnackBar("Please Enter Remark")
        } else
            approveAllCampaign()

    }

    const CheckCampaign = async (id) => {
        // setOpenConfirmation(true)
        status = "CHECKED"
        // setCampainID(id)
        if (!remark) {
            openSnackBar("Please Enter Remark")
        } else
            approveAllCampaign()

    }

    let service = user && user.services && user.services.find(service => service.name == "Special Rates")
    let superUser = user && user.admin
    const columns = [
        // {
        //     name: "created_Date",
        //     label: "Created Date",
        //     options: {
        //         filter: true,
        //         sort: true
        //     }
        // },
        {
            name: "campaignName",
            label: "campaignName",
            options: {
                filter: true,
                sort: true,
                display: true,
                // customBodyRenderLite: (dataIndex) => {
                //     const companyName = campaignList[dataIndex].company_Name;
                //     const campaignName = campaignList[dataIndex].campaignName;
                //     return `${companyName ? companyName : campaignName}`;
                // },
            },
        },
        // {
        //     name: "company_Name",
        //     label: "Company Name",
        //     options: {
        //       filter: true,
        //       sort: true,
        //       display: "true",
        //       customBodyRenderLite: (dataIndex) => {
        //         const value = campaignList[dataIndex].company_Name;
        //         return value ? value : "";
        //       },
        //       customHeadLabel: (columnIndex, colOptions) => {
        //         const hasValue = campaignList.some((item) => item.company_Name !== "");
        //         return hasValue ? colOptions.label : "";
        //       },
        //     },
        //   },
        //   {
        //     name: "cpr",
        //     label: "CPR",
        //     options: {
        //       filter: true,
        //       sort: true,
        //       display: "true",
        //       customBodyRenderLite: (dataIndex) => {
        //         const value = campaignList[dataIndex].cpr;
        //         return value ? value : "";
        //       },
        //       customHeadLabel: (columnIndex, colOptions) => {
        //         const hasValue = campaignList.some((item) => item.cpr !== "");
        //         return hasValue ? colOptions.label : "";
        //       },
        //     },
        //   },
        {
            name: "StartDate",
            label: "Start Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "EndDate",
            label: "End Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "level",
            label: "Level",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
            },
        }
    ]

    const searchFromServer = (serachTerm) => {
        fetchCompanyDetails(1, 10, serachTerm)
    }

    const handlePageChange = (page) => {

        // Make an API call to fetch data for the selected page

        const nextPage = page + 1;

        // console.log(nextPage);
        if (nextPage === currentPage) {
            console.log("enter prev");
            // Already fetched, no need to make another API call
            const previousPage = currentPage - 1;
            setCurrentPage(previousPage)
            fetchCompanyDetails(previousPage)
            return
        }

        setShowLoader(true);
        setCurrentPage(nextPage);
        console.log(currentPage);
        fetchCompanyDetails(nextPage); // Assuming the server expects 1-based page index
    };
    const options = {
        // serverSide: true,
        // page: currentPage,
        // search: true,
        // searchPlaceholder: 'Search...',
        // count: count, // Total number of records
        // rowsPerPage: rowsPerPage,

        // rowsPerPageOptions: [10, 25, 50],
        // onTableChange: (action, tableState) => {
        //     console.log(action);
        //     // Handle pagination changes
        //     if (action === 'changePage') {
        //         handlePageChange(tableState.page);
        //     }else if (action === 'changeRowsPerPage') {
        //         fetchCompanyDetails(currentPage,tableState.rowsPerPage)
        //         const newRowsPerPage = tableState.rowsPerPage;
        //         if (tableState.rowsPerPageOptions.includes(newRowsPerPage)) {
        //           setRowsPerPage(newRowsPerPage);
        //         }
        //       }else if(action=='search'){
        //         console.log(tableState);
        //         searchFromServer(tableState.searchText)
        //       }
        // },
        selectableRows: false,
        elevation: 0,
        print: false,
        onDownload: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = " Special Rates.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        }
    };


    React.useEffect(() => {
        checkUserType()
        fetchCompanyDetails()

    }, [])

    const checkUserType = () => {
        if (user.admin !== undefined && user.admin == true) {
            setShowAdminViewBtn(true)
        }
        else {
            if (user && user.services && user.services !== undefined && user.services !== null && user.services.length !== 0) {
                let service = user.services.find(service => service.name == "Special Rates")
                if (service) {
                    switch (service.type) {
                        case "maker":
                            setShowAddCorpBtn(true)
                            setApprovalEdit(false);

                            break;
                        case "checker":
                            setShowAddCorpBtn(false)
                            setApprovalEdit(false);
                            setToolTipText("Check/ReCheck All Campagin")
                            break;
                        case "checkerTwo":
                            setShowAddCorpBtn(false)
                            break;
                        case "approver":
                            setShowAddCorpBtn(false)
                            setToolTipText("Approve/Reject All Campagin")

                            break;
                        case "viewer":
                            setShowAddCorpBtn(false)
                            break;

                        default:
                            setShowAddCorpBtn(false)
                            break;
                    }
                }
            }
        }
    }

    const approveAllCampaign = async () => {
        try {

            const resp = await axios.post(`${config.url().baseURL}/approve-allCampaign`, {
                campaignID,
                "status": status,
                "remarks": remark
            })

            // console.log(resp);
            if (resp.data.err) {
                openSnackBar(resp.data.msg)
            } else {
                setRemark("")
                setShowModal(false)
                openSnackBar(resp.data.msg, true)
                fetchCompanyDetails()
                setOpenConfirmation(false)

            }


        } catch (err) {
            console.log(err);
            openSnack("Something Went Wrong")
        }
    }



    const handleOpenEdit = () => {
        setShowCorporateTable(false);
        setOpenEdit(true)
        setMakerEdit(false)
        setCompanyId("")

    }

    // const handleOpenEditCustomer = () => {
    //     setShowCorporateTable(false);
    //     setOpenEditCustomer(true)
    //     setMakerEdit(false)
    //     setCompanyId("")

    // }

    function openSnackBar(msg, success) {
        setOpenSnack(true)
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg)?.replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }
    function comeBack() {

        setOpenEdit(false);
        setOpenEditCustomer(false)
        setShowCorporateTable(true)
        setShowCompanyView(false)
        fetchCompanyDetails()
        setLog(false)
        setShowEachCampaign(false)
        setStausCampaign("")
        setApprovalEdit(false)
        setMakerEdit(false);
        setCompanyId("")
    }
    const fetchCampaignDetails = (id, level) => {
        //function for passing values to CamapignDetails component
        setShowCorporateTable(false);
        if (service && service.type && service.type == "approver" && level == "CHECKED") {
            setApprovalEdit(true); //Approver can edit campaigndate.
        } else { setApprovalEdit(false) }
        setShowCompanyView(true)
        setMakerEdit(true);
        setCompanyId(id)
        setLog(false)
        setStausCampaign(level)

    }
    const editCampaignData = (id) => {
        //Function for opening SpecialFeeEdit component  
        setShowCorporateTable(false)
        setShowCompanyView(false)
        setOpenEdit(true);
        setMakerEdit(true)
        setCompanyId(id)
        setLog(false)
    }

    const editCampaignCustomer = (id) => {
        //Function for opening SpecialFeeEdit component  
        // setShowCorporateTable(false);
        setOpenEditCustomer(true)
        //     setMakerEdit(false)
        //     setCompanyId("")
        setShowCorporateTable(false)
        setShowCompanyView(false)
        // setOpenEdit(true);
        setMakerEdit(true)
        setCompanyId(id)
        setLog(false)
    }
    const fetchLog = (id) => {
        //Displaying logs from CamapignLogs component
        setShowCorporateTable(false)
        setShowCompanyView(false)
        setOpenEdit(false)
        setMakerEdit(false)
        setLog(true)
        setCompanyId(id)

    }


    const fetchEachCampaign = (campaignID, id) => {
        setCampainID(campaignID)
        setID(id)
        //Displaying logs from CamapignLogs component
        setShowCorporateTable(false)
        setShowCompanyView(false)
        setOpenEdit(false)
        setMakerEdit(false)
        setShowEachCampaign(true)
        setLog(false)
        // setCompanyId(id)

    }
    const fetchCompanyDetails = (pageNumber = 1, pageSize = 10, search) => {
        if (pageNumber < 1) {
            pageNumber = 1
        }
        //Function to dispaly all the campaign details in table---Landing page
        let updatedData = []
        let updatedCSV = []
        setApprovalEdit(false);
        setShowLoader(true)
        axios.get(`${config.url().baseURL}/list-groupCampaignByID`, {
            // params: {
            //   pageNumber: pageNumber,
            //   pageSize: pageSize,
            //   searchQuerry: search || "", // Pass null if search text is empty
            // },
        })

            // ?pageNumber=${pageNumber}&pageSize=${pageSize}&searchQuerry=${search}`)


            .then((res) => {
                setShowLoader(false)
                let resData = res.data
                setCount(resData.count);
                if (resData.err) {
                    openSnackBar(resData.msg)
                }
                else {
                    // console.log(res.data.data);
                    res.data.data.map((cList) => {
                        if (superUser == true) {

                            // if (cList && cList.logs.length && cList.logs[cList.logs.length - 1].status == "APPROVED" ||
                            //     cList && cList.logs.length && cList.logs[cList.logs.length - 1].status == "DISABLED" ||
                            //     cList && cList.logs.length && cList.logs[cList.logs.length - 1].status == "ENABLED") {
                            // cList.level = cList && cList.logs.length && cList.logs[cList.logs.length - 1].status ? cList.logs[cList.logs.length - 1].status : ""
                            cList.created_Date = moment(cList.createdAt).format("DD/MM/YYYY")
                            // cList.company_Name = cList && cList?.companyData?.length && cList.companyData[0].companyName ? cList.companyData[0].companyName : ""
                            cList.campaignID = cList && cList.campaignID
                            cList.campaignName = cList.campaigns && cList.campaigns.campaignName

                            cList.StartDate = cList.campaigns && cList.campaigns.startDate && cList.campaigns.startDate ? moment(cList.campaigns.startDate).format("DD/MM/YYYY") : "";
                            cList.EndDate = cList.campaigns && cList.campaigns.endDate && cList.campaigns.endDate != "NA" ? moment(cList.campaigns.endDate).format("DD/MM/YYYY") : ""
                            cList.level = cList.campaigns && cList?.campaigns.Status ? cList?.campaigns.Status : ""

                            const endDate = moment(cList.EndDate, 'DD/MM/YYYY', true);
                            cList.actions = (
                                <>
                                    {(cList.level === 'Created' ||
                                        cList.level === 'Updated' ||
                                        cList.level === 'CREATED' ||
                                        cList.level !== "STOPPED" ||
                                        cList.level === 'UPDATED') ? (
                                        <>
                                            {cList.level !== 'STOPPED' && cList.level !== 'APPROVE' && cList.level !== 'REJECT' && cList.level === "CHECKED" ?
                                                <Tooltip placement="left" title="Approve/Reject All Campagin">
                                                    <IconButton
                                                        onClick={() => {
                                                            setShowModal(true)
                                                            setModalText("Approve All Campains")
                                                            setCampainID(cList.campaignID)



                                                        }}
                                                        style={{ marginTop: '-10px', marginBottom: '-10px' }}
                                                        aria-label="log"
                                                    >
                                                        <DoneAll />
                                                    </IconButton>
                                                </Tooltip>
                                                : ""}
                                        </>
                                    ) : ""}

                                    {/* <Tooltip placement="right" title="View Log">
                                            <IconButton
                                                onClick={() => fetchLog(cList._id)}
                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                aria-label="log">
                                                <AssignmentIcon />
                                            </IconButton>
                                        </Tooltip> */}

                                    {/* <Tooltip placement="right" title="View GPW/WPS">
                                            <IconButton
                                                onClick={() => fetchEachCampaign(cList.campaignID)}
                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                aria-label="log">
                                                <ViewList />
                                            </IconButton>
                                        </Tooltip> */}
                                    {

                                        today.isBefore(moment(endDate).startOf('day')) & cList.level !== 'STOPPED' ? (
                                            <Tooltip placement="right" title="Stop Campaign">
                                                <IconButton
                                                    onClick={() => {
                                                        setOpenConfirmation(true)
                                                        // setStatus("STOPPED")

                                                        setCampainID(cList.campaignID)
                                                    }}
                                                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                    aria-label="log"
                                                >
                                                    <Cancel />
                                                </IconButton>
                                            </Tooltip>
                                        ) : ""}
                                </>)
                            updatedData.push(cList)
                            let temp = {}
                            temp.Created_Date = cList.created_Date;
                            // temp.Company_Name = cList.company_Name.replaceAll(/,/g, '')
                            temp.Campaign_StartDate = cList.StartDate
                            temp.Campiagn_EndDate = cList.EndDate
                            temp.Level = cList.level
                            temp.campaignID = cList.campaignID
                            temp.Campaign_Name = cList.campaignName

                            // console.log("temp..........",temp);
                            updatedCSV.push(temp)

                            // }

                        } else {

                            cList.created_Date = moment(cList.createdAt).format("DD/MM/YYYY")
                            cList.company_Name = cList && cList?.companyData?.length && cList.companyData[0].companyName ? cList.companyData.companyName : ""
                            cList.campaignID = cList && cList.campaignID
                            cList.campaignName = cList.campaigns && cList.campaigns.campaignName
                            cList.isUser = cList.campaigns && cList.campaigns.cpr ? true : false
                            // console.log(cList.campaigns.cpr);
                            cList.StartDate = cList.campaigns && cList.campaigns.startDate && cList.campaigns.startDate ? moment(cList.campaigns.startDate).format("DD/MM/YYYY") : "";
                            cList.EndDate = cList.campaigns && cList.campaigns.endDate && cList.campaigns.endDate != "NA" ? moment(cList.campaigns.endDate).format("DD/MM/YYYY") : ""
                            cList.level = cList.campaigns.Status || "";
                            cList.id = cList.campaigns._id
                            // if (cList.campaigns && cList.campaigns.length > 0) {
                            // let hasUpdatedStatus = false;

                            // for (let i = 0; i < cList.campaigns.length; i++) {
                            //     const campaign = cList.campaigns[i];
                            //     if (campaign.Status === "UPDATED") {
                            //         cList.level = "UPDATED";
                            //         hasUpdatedStatus = true;
                            //         break;
                            //     }
                            // }
                            // if (!hasUpdatedStatus) {
                            //     cList.level = cList.campaigns.Status || "";
                            // }
                            // }

                            cList.actions = (
                                <>
                                    {/* <Tooltip placement="right" title="View Log">
                                        <IconButton
                                            onClick={() => fetchLog(cList._id)}
                                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                            aria-label="log">
                                            <AssignmentIcon />
                                        </IconButton>
                                    </Tooltip> */}

                                    <Tooltip placement="right" title="View GPW/WPS">
                                        <IconButton
                                            onClick={() => fetchEachCampaign(cList.campaignID, cList.id)}
                                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                            aria-label="log">
                                            <ViewList />
                                        </IconButton>
                                    </Tooltip>
                                    {/* <Tooltip placement="right" title="View Details">
                                        <IconButton
                                            onClick={() => fetchCampaignDetails(cList._id, cList.level)}
                                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                            aria-label="log">
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Tooltip> */}
                                    {service.type == "maker" && (cList.level == "Created" || cList.level == "RECHECK" || cList.level == "CREATED") ?
                                        <Tooltip placement="right" title="Edit">
                                            <IconButton
                                                onClick={() => {
                                                    cList.isUser ? editCampaignCustomer(cList.campaignID) : editCampaignData(cList.campaignID)


                                                }}
                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                aria-label="log">
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        : null}
                                    {/* || service.type === 'approver' */}

                                    {(service.type === 'checker') &&
                                        (cList.level === 'Created' ||
                                            cList.level === 'Updated' ||
                                            cList.level === 'CREATED' ||
                                            cList.level === 'UPDATED') ? (
                                        <>
                                            <Tooltip placement="left" title={service.type == 'checker' ? `Check/Recheck All Campagin` : `Approve/Reject All Campagin`}>
                                                <IconButton
                                                    onClick={() => {
                                                        setShowModal(true)
                                                        setModalText("Approve/Recheck All Campains")
                                                        setCampainID(cList.campaignID)



                                                    }}
                                                    style={{ marginTop: '-10px', marginBottom: '-10px' }}
                                                    aria-label="log"
                                                >
                                                    <DoneAll />
                                                </IconButton>
                                            </Tooltip>

                                        </>
                                    ) : null}

                                    {(service.type === 'approver' && cList.level === 'CHECKED') ? (
                                        <>
                                            <Tooltip placement="left" title={service.type == 'checker' ? `Check/Recheck All Campagin` : `Approve/Reject All Campagin`}>
                                                <IconButton
                                                    onClick={() => {
                                                        setShowModal(true)
                                                        setModalText("Approve/Reject All Campains")
                                                        setCampainID(cList.campaignID)



                                                    }}
                                                    style={{ marginTop: '-10px', marginBottom: '-10px' }}
                                                    aria-label="log"
                                                >
                                                    <DoneAll />
                                                </IconButton>
                                            </Tooltip>

                                        </>
                                    ) : null}


                                    {/* {service.type == "checker" && (cList.level == "Created" || cList.level == "Updated" || cList.level == "CREATED" || cList.level == "UPDATED") ?
                                        <Tooltip placement="left" title="Recheck All Campaigin">
                                            <IconButton
                                                // onClick={() => editCampaignData(cList._id)}
                                                onClick={() => {
                                                    setShowModal(true);
                                                    setModalText("Recheck All Campaigins")
                                                    setStatus("RECHECK")
                                                }}
                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                aria-label="log">
                                                <ClearAll />
                                            </IconButton>
                                        </Tooltip>
                                        : null} */}

                                </>)

                            updatedData.push(cList)
                            let temp = {}
                            // temp.Created_Date = cList.created_Date;
                            // temp.Campaign_ID = cList.campaignID
                            // cList.company_Name.replaceAll(/,/g, '') ? cList.company_Name.replaceAll(/,/g, '') : cList?.campaign_Name?.replaceAll(/,/g, '')
                            temp.campaign_Name = cList?.campaignName?.replaceAll(/,/g, '')

                            temp.Start_Date = cList.StartDate
                            temp.End_Date = cList.EndDate
                            temp.Level = cList.level
                            updatedCSV.push(temp)

                            // console.log("temp.....",temp);

                        }
                    })
                    setCampaignList(updatedData);
                    setCsvData(updatedCSV)

                }
            }).catch((err) => {
                console.log(err);
                setShowLoader(false)
                openSnackBar("Unable to fetch the Campaign Details")
            })

    }

    return (
        <div>{respAlert}
            <GridContainer justify='center'>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={true}
                        style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                        {...true ? { timeout: 500 } : {}}>
                    </Collapse>

                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{
                            transformOrigin: "0 0 0", textAlign: 'center'
                        }}
                        {...true ? { timeout: 500 } : {}}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showCorporateTable&&!showLoader}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...true ? { timeout: 500 } : {}}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}> Special Fee</h4>
                                {showAddCorpBtn ? (
                                    <Button onClick={handleOpenEdit} color="secondary" size="sm" className={classes.addButton}>
                                        Add Campaign
                                    </Button>
                                ) : null}
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={campaignList} service={service} columns={columns} options={options} filterCellStyle={{ width: "300px" }}
                                />
                            </CardBody>
                        </Card>
                    </Collapse>

                    <Collapse
                        in={showEachCampaign}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...true ? { timeout: 500 } : {}}>
                        <ShowCampaginDetialView back={comeBack} snack={openSnackBar} campaignID={campaignID} setShowLoader={setShowLoader} showLoader={showLoader} id={Id} stopRerender={!showEachCampaign} />
                        {/* <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}> Special Fee</h4>
                                {showAddCorpBtn ? (
                                    <Button onClick={handleOpenEdit} color="secondary" size="sm" className={classes.addButton}>
                                        Add Campaign
                                    </Button>
                                ) : null}
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={campaignList} columns={columns} options={options} filterCellStyle={{ width: "300px" }}
                                />
                            </CardBody>
                        </Card> */}

                    </Collapse>

                </GridItem>
                <Modal open={showModal} onClose={() => setShowModal(false)}>
                    <Card style={{ width: 1000, margin: '0 auto', marginTop: 100, boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
                        <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 40 }}>
                            <h2 style={{ textAlign: 'center', marginBottom: 30, fontSize: 24 }}>{modalText}</h2>
                            <div style={{ flex: 1 }}>
                                <TextField
                                    label="Remark"
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e) => setRemark(e.target.value)}
                                    value={remark}
                                    required  // Add the required attribute here
                                />
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <Button variant="contained" color="primary" onClick={() => {
                                    // setStatus("CHECKED")
                                    // approveAllCampaign()
                                    service?.type == 'checker' ? CheckCampaign() : ApproveCampaign()
                                }}> Approve</Button>
                                <Button variant="contained" color="danger" onClick={() => {
                                    // setStatus("RECHECK")
                                    // approveAllCampaign()
                                    // RecheckAllCampaign()
                                    service?.type == 'checker' ? RecheckCampaign() : RejectCampaign()
                                }}>{service?.type == 'checker' ? `RecCheck` : `Reject`}</Button>

                            </div>
                        </CardBody>
                    </Card>
                </Modal>

                <Dialog open={openConfirmation} onClose={() => setOpenConfirmation(false)}>
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent>
                        <p>Are you sure you want to stop the campaign?</p>
                        <div style={{ flex: 1 }}>
                            <TextField label="Remark" variant="outlined" fullWidth onChange={(e) => setRemark(e.target.value)} value={remark} />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenConfirmation(false)}>
                            Cancel
                        </Button>
                        <Button onClick={() => stopCampaign()} color="danger">
                            Stop Campaign
                        </Button>
                    </DialogActions>
                </Dialog>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={openEdit}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <SpecialFeeEdit back={comeBack} snack={openSnackBar} companyId={companyId} edit={makerEdit} AllCampagin={true} />
                    </Collapse>
                    <Collapse
                        in={openEditCustomer}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Test back={comeBack} snack={openSnackBar} companyId={companyId} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    {log ?
                        <CampaignLogs ComapanyID={companyId} back={comeBack} snack={openSnackBar} />
                        : ""}
                </GridItem>
            </GridContainer>
            <Collapse
                in={showCompanyView}
                style={{ transformOrigin: "0 0 0" }}
                {...(true ? { timeout: 500 } : {})}>
                <CampaignDetailsView companyId={companyId} back={comeBack} snack={openSnackBar} approvalEdit={approvalEdit} campaignStatus={statusCampaign} superUser={superUser} />
            </Collapse>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    )
} 
