import React from "react";
import config from "config.js"
import SweetAlert from "react-bootstrap-sweetalert";
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/core";
import axios from "axios";
import moment from "moment";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import CustomSelectStyle from "assets/jss/material-dashboard-react/customSelectStyle";
const override = css`
  padding: 0;
  margin: 0;
`;

const styles = {
    ...CustomSelectStyle,
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },

    },
    formControl: {
        margin: "27px 0 0 0",
        textAlign: "left",
    },
    cardFooter: {
        textAlign: "center !important",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "20px",
    },
    required: {
        color: "red",
    },
    dateLabel: {
        marginTop: "-18px",
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "12px",
        fontWeight: "400",
    },
    dateInput: {
        marginTop: "20px !important",
    },
    subHead: {
        fontWeight: "500",
        marginBottom: "-2px !important",
        float: "left",
        display: "inlineBlock",
    },
};

const useStyles = makeStyles(styles);
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SystemUsers(props) {
    const classes = useStyles();
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [respAlert, setRespAlert] = React.useState(null);
    const [submitting, setSubmitting] = React.useState(false);

    const [values, setValues] = React.useState({
        fnameErr: false,
        mnameErr: false,
        lnameErr: false,
        genderErr: false,
        cprErr: false,
        cprExpiryErr: false,
        dobErr: false,
        addressTypeErr: false,
        flatNumberErr: false,
        buildingNumberErr: false,
        roadNumberErr: false,
        blockNumberErr: false,
        phoneErr: false,
        emailErr: false,
        placeOfBirthErr: false,
        nationalityErr: false,
        occupationErr: false,
        prefLanguageErr: false,
        passportNumberErr: false,
        passportExpiryErr: false,
    });

    const [firstName, setFirstName] = React.useState("");
    const [middleName, setMiddleName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [gender, setGender] = React.useState("");
    const [dateOfBirth, setDateOfBirth] = React.useState("");
    const [cpr, setCpr] = React.useState("");
    const [cprExpiry, setCprExpiry] = React.useState("");
    const [addressType, setAddressType] = React.useState("");
    const [flatNumber, setFlatNumber] = React.useState("");
    const [buildingNumber, setBuildingNumber] = React.useState("");
    const [roadNumber, setRoadNumber] = React.useState("");
    const [blockNumber, setBlockNumber] = React.useState("");
    const [phoneNo, setPhone] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [placeOfBirth, setPlaceOfBirth] = React.useState("");
    const [nationality, setNationality] = React.useState("");
    const [occupation, setOccupation] = React.useState("");
    const [prefLanguage, setPrefLanguage] = React.useState("");
    const [passportNumber, setPassportNumber] = React.useState("");
    const [passportExpiry, setPassportExpiry] = React.useState("");

    React.useEffect(() => {
        if (props.data !== null) {
            setData()
        }
    }, [props.data]);

    const setData = () => {
        //function to set form data 
        let SampleOne = props.data.DateOfBirth.split('/');
        let DOB = `${SampleOne[1]}/${SampleOne[0]}/${SampleOne[2]}`;

        let SampleTwo = props.data.Passport.PassportExpiry.split('/');
        let PassportExp = `${SampleTwo[1]}/${SampleTwo[0]}/${SampleTwo[2]}`;

        let SampleThree = props.data.CPRExpiryDate.split('-');
        let cprExp = `${SampleThree[1]}/${SampleThree[0]}/${SampleThree[2]}`;

        setFirstName(props.data.FirstName)
        setMiddleName(props.data.MiddleName)
        setLastName(props.data.LastName)
        setGender(props.data.Gender)
        setDateOfBirth(moment(DOB).format("YYYY-MM-DD"))
        setCpr(props.data.EmployeeCPRId)
        setCprExpiry(moment(cprExp).format("YYYY-MM-DD"))
        setAddressType(props.data.EmployeeAddress.AddressType)
        setFlatNumber(props.data.EmployeeAddress.FlatNumber)
        setBuildingNumber(props.data.EmployeeAddress.BuildingNumber)
        setRoadNumber(props.data.EmployeeAddress.RoadNumber)
        setBlockNumber(props.data.EmployeeAddress.BlockNumber)
        setPhone(props.data.MobileNumber)
        setEmail(props.data.EmailId)
        setPlaceOfBirth(props.data.PlaceOfBirth)
        setNationality(props.data.Nationality)
        setOccupation(props.data.Occupation)
        setPrefLanguage(props.data.PreferedCommunicationLanguage)
        setPassportNumber(props.data.Passport.PassportNumber)
        setPassportExpiry(moment(PassportExp).format("YYYY-MM-DD"))
    }


    const openSnackBar = (msg) => {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    };
    const verifyLength = (value, length) => {
        // function that verifies if a string has a given length or not
        if (value !== undefined && value.length >= length) {
            return true;
        }
        return false;
    };

    const verify = (value, type, errVariable, length) => {
        switch (type) {
            case "length":
                if (verifyLength(value, length)) {
                    setValues({ ...values, [errVariable]: false });
                } else {
                    setValues({ ...values, [errVariable]: true });
                }
                break;
            case "phone":
                if (value.length <= 8) {
                    const re = /^[0-9\b]+$/;
                    if (value === "" || re.test(value)) {
                        if (verifyLength(value, length)) {
                            setValues({ ...values, [errVariable]: false });
                        } else {
                            setValues({ ...values, [errVariable]: true });
                        }
                    }
                }
                break;
            case "cpr":
                if (value.length <= 9) {
                    const re = /^[0-9\b]+$/;
                    if (value === "" || re.test(value)) {
                        if (verifyLength(value, length)) {
                            setValues({ ...values, [errVariable]: false });
                        } else {
                            setValues({ ...values, [errVariable]: true });
                        }
                    }
                }
                break;
            default:
                break;
        }
    };


    const validateForm = () => {
        //function to validate user creation form
        const reEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let flag = true;
        let fnameErr = false,
            mnameErr = false,
            lnameErr = false,
            genderErr = false,
            cprErr = false,
            cprExpiryErr = false,
            dobErr = false,
            addressTypeErr = false,
            flatNumberErr = false,
            buildingNumberErr = false,
            roadNumberErr = false,
            blockNumberErr = false,
            phoneErr = false,
            emailErr = false,
            placeOfBirthErr = false,
            nationalityErr = false,
            occupationErr = false,
            prefLanguageErr = false,
            passportNumberErr = false,
            passportExpiryErr = false;

        if (firstName.length < 1) {
            fnameErr = true;
            flag = false;
        }
        if (lastName.length < 1) {
            lnameErr = true;
            flag = false;
        }
        if (gender.length < 1) {
            genderErr = true;
            flag = false;
        }
        if (dateOfBirth.length < 1) {
            dobErr = true;
            flag = false;
        }
        if (cpr.length < 1) {
            cprErr = true;
            flag = false;
        }
        if (cprExpiry.length < 1) {
            if (passportNumber.length < 1) {
                passportNumberErr = true;
                flag = false;
            }
            if (passportExpiry.length < 1) {
                passportExpiryErr = true;
                flag = false;
            }
        }
        if (addressType.length < 1) {
            addressTypeErr = true;
            flag = false;
        }
        if (flatNumber.length < 1) {
            flatNumberErr = true;
            flag = false;
        }
        if (buildingNumber.length < 1) {
            buildingNumberErr = true;
            flag = false;
        }
        if (roadNumber.length < 1) {
            roadNumberErr = true;
            flag = false;
        }
        if (blockNumber.length < 1) {
            blockNumberErr = true;
            flag = false;
        }
        if (phoneNo.length < 1) {
            phoneErr = true;
            flag = false;
        }
        // if (!reEmail.test(email)) {
        //     emailErr = true;
        //     flag = false;
        // }
        if (placeOfBirth.length < 1) {
            placeOfBirthErr = true;
            flag = false;
        }
        if (nationality.length < 1) {
            nationalityErr = true;
            flag = false;
        }
        if (occupation.length < 1) {
            occupationErr = true;
            flag = false;
        }
        if (prefLanguage.length < 1) {
            prefLanguageErr = true;
            flag = false;
        }

        setValues({
            ...values,
            fnameErr: fnameErr,
            mnameErr: mnameErr,
            lnameErr: lnameErr,
            genderErr: genderErr,
            cprErr: cprErr,
            cprExpiryErr: cprExpiryErr,
            dobErr: dobErr,
            addressTypeErr: addressTypeErr,
            flatNumberErr: flatNumberErr,
            buildingNumberErr: buildingNumberErr,
            roadNumberErr: roadNumberErr,
            blockNumberErr: blockNumberErr,
            phoneErr: phoneErr,
            emailErr: emailErr,
            placeOfBirthErr: placeOfBirthErr,
            nationalityErr: nationalityErr,
            occupationErr: occupationErr,
            prefLanguageErr: prefLanguageErr,
            passportNumberErr: passportNumberErr,
            passportExpiryErr: passportExpiryErr,
        });
        return flag;
    };



    const submitUser = () => {
        //function to submit user for adding and updating
        let valid = validateForm();
        if (valid) {
            if (!submitting) {
                setSubmitting(true);
                let data = {
                    "FirstName": firstName,
                    "MiddleName": middleName,
                    "LastName": lastName,
                    "Gender": gender,
                    "EmployeeCPRId": cpr,
                    "CPRExpiryDate": moment(cprExpiry).format("DD-MM-YYYY"),
                    "MobileNumber": phoneNo,
                    "EmailId": email,
                    "PlaceOfBirth": placeOfBirth,
                    "DateOfBirth": moment(dateOfBirth).format("DD/MM/YYYY"),
                    "Nationality": nationality,
                    "EmployeeAddress": {
                        "AddressType": addressType,
                        "FlatNumber": flatNumber,
                        "BuildingNumber": buildingNumber,
                        "RoadNumber": roadNumber,
                        "BlockNumber": blockNumber
                    },
                    "Passport": {
                        "PassportNumber": passportNumber,
                        "PassportExpiry": moment(passportExpiry).format("DD/MM/YYYY")
                    },
                    "PreferedCommunicationLanguage": prefLanguage,
                    "ValidProofOfIdentification": "cpr",
                    "Occupation": occupation
                }
                axios.put(`${config.url().baseURL}/update-employee/${props.data._id}`, data, {
                    headers: {
                        auth: sessionStorage.getItem("cx_id"),
                    },
                })
                    .then((resp) => {
                        let respData = resp.data;
                        if (!respData.err) {
                            setSubmitting(false);
                            setRespAlert(
                                <SweetAlert
                                    success
                                    style={{ display: "block" }}
                                    title="Employee update successful."
                                    onConfirm={() => {
                                        props.success();
                                        setRespAlert(null);
                                    }}
                                    confirmBtnCssClass={classes.button + " " + classes.success}
                                />
                            );
                        } else {
                            openSnackBar(respData.msg);
                            setSubmitting(false);
                        }
                    })
                    .catch((err) => {
                        setSubmitting(false);
                        openSnackBar("Unable to process.");
                    });
            }

        } else {
            openSnackBar("Empty or Incorrect values.");
        }
    };


    return (
        <div>
            {respAlert}
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={true}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Edit Employee</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            labelText={
                                                <span>
                                                    First Name <small className={classes.required}>*</small>
                                                </span>
                                            }
                                            error={values.fnameErr}

                                            id="first-name"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: firstName,
                                                readOnly: true,
                                                onChange: (e) => {
                                                    if (e.target.value !== "") {
                                                        if (e.target.value.match(/^[a-zA-Z ]+$/)) {
                                                            verify(e.target.value, "length", "fnameErr", 2);
                                                            setFirstName(e.target.value);
                                                        }
                                                    } else {
                                                        setFirstName(e.target.value);
                                                    }
                                                },
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            error={values.mnameErr}
                                            labelText={
                                                <span>
                                                    Middle Name <small className={classes.required}>*</small>
                                                </span>
                                            }
                                            id="middle-name"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: middleName,
                                                readOnly: true,
                                                onChange: (e) => {
                                                    if (e.target.value !== "") {
                                                        if (e.target.value.match(/^[a-zA-Z ]+$/)) {
                                                            verify(e.target.value, "length", "mnameErr", 2);
                                                            setMiddleName(e.target.value);
                                                        }
                                                    } else {
                                                        setMiddleName(e.target.value);
                                                    }
                                                },
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            readOnly={true}
                                            labelText={
                                                <span>
                                                    Last Name <small className={classes.required}>*</small>
                                                </span>
                                            }
                                            id="last-name"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: lastName,
                                                readOnly: true,
                                                onChange: (e) => {
                                                    if (e.target.value !== "") {
                                                        if (e.target.value.match(/^[a-zA-Z ]+$/)) {
                                                            verify(e.target.value, "length", "lnameErr", 2);
                                                            setLastName(e.target.value);
                                                        }
                                                    } else {
                                                        setLastName(e.target.value);
                                                    }
                                                },
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <CustomInput
                                            error={values.genderErr}
                                            labelText={
                                                <span>
                                                    Gender <small className={classes.required}>*</small>
                                                </span>
                                            }
                                            id="empGender"
                                            inputProps={{
                                                value: gender,
                                                readOnly: true,
                                                onChange: (e) => {
                                                    if (e.target.value !== "") {
                                                        if (e.target.value.match(/^[a-zA-Z ]+$/)) {
                                                            setGender(e.target.value);
                                                            verify(e.target.value, "length", "genderErr", 2);
                                                        }
                                                    } else {
                                                        verify(e.target.value, "length", "genderErr", 2);
                                                        setGender(e.target.value);
                                                    }
                                                },
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={3}>
                                        <FormControl className={classes.selectFormControl} fullWidth>
                                            <InputLabel className={classes.dateLabel}>
                                                <span>
                                                    Date of Birth <small className={classes.required}>*</small>
                                                </span>
                                            </InputLabel>
                                            <TextField
                                                error={values.dobErr}
                                                className={classes.dateInput}
                                                type="date"
                                                inputProps={{
                                                    autoComplete: "off",
                                                    type: "date",
                                                    readOnly: true,
                                                    max: moment(new Date()).format("YYYY-MM-DD"),
                                                    value: dateOfBirth,
                                                    onChange: (e) => {
                                                        setDateOfBirth(e.target.value);
                                                        verify(e.target.value, "length", "dobErr", 2);
                                                    },
                                                    onBlur: (e) => {
                                                        if (moment(e.target.value).isAfter(moment(new Date()))) {
                                                            setValues({ ...values, dobErr: true })
                                                        } else {
                                                            setValues({ ...values, dobErr: false })
                                                        }
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <CustomInput
                                            error={values.cprErr}
                                            labelText={
                                                <span>
                                                    CPR <small className={classes.required}>*</small>
                                                </span>
                                            }
                                            id="emp-cpr"
                                            inputProps={{
                                                value: cpr,
                                                readOnly: true,
                                                onChange: (e) => {
                                                    const re = /^[0-9\b]+$/;
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                        if (e.target.value.length <= 9) {
                                                            verify(e.target.value, "length", "cprErr", 9);
                                                            setCpr(e.target.value);
                                                        }
                                                    }
                                                    if (e.target.value.length < 9) {
                                                        setValues({ ...values, cprErr: true });
                                                    } else {
                                                        setValues({ ...values, cprErr: false });
                                                    }
                                                },
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={3}>
                                        <FormControl className={classes.selectFormControl} fullWidth>
                                            <InputLabel className={classes.dateLabel}>
                                                <span>
                                                    Expiry Date <small className={classes.required}>*</small>
                                                </span>
                                            </InputLabel>
                                            <TextField
                                                error={values.cprExpiryErr}
                                                className={classes.dateInput}
                                                type="date"
                                                inputProps={{
                                                    autoComplete: "off",
                                                    type: "date",
                                                    max: "9999/12/30",
                                                    min: moment(new Date()).format("YYYY-MM-DD"),
                                                    value: cprExpiry,
                                                    onChange: (e) => {
                                                        verify(e.target.value, "length", "cprExpiryErr", 3);
                                                        setCprExpiry(e.target.value);
                                                    },
                                                    onBlur: (e) => {
                                                        if (moment(e.target.value).isBefore(moment(new Date()))) {
                                                            setValues({ ...values, cprExpiryErr: true })
                                                        } else {
                                                            setValues({ ...values, cprExpiryErr: false })
                                                        }
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={12}>
                                        <h6 className={classes.subHead}> Address</h6>
                                    </GridItem>
                                    <GridItem xs={12} sm={12}>
                                        <Divider style={{ background: "black" }} fullWidth />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            error={values.addressTypeErr}
                                            labelText={
                                                <span>
                                                    Address Type <small className={classes.required}>*</small>
                                                </span>
                                            }
                                            id="addressType"
                                            inputProps={{
                                                value: addressType,
                                                onChange: (e) => {
                                                    const re = /^[a-zA-Z0-9_.-]*$/;
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                        verify(e.target.value, "length", "addressTypeErr", 2);
                                                        setAddressType(e.target.value);
                                                    }
                                                },
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            error={values.flatNumberErr}
                                            labelText={
                                                <span>
                                                    Flat Number <small className={classes.required}>*</small>
                                                </span>
                                            }
                                            id="phone-no"
                                            inputProps={{
                                                value: flatNumber,
                                                onChange: (e) => {
                                                    const re = /^[a-zA-Z0-9_.-]*$/;
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                        verify(e.target.value, "length", "flatNumberErr", 2);
                                                        setFlatNumber(e.target.value);
                                                    }
                                                },
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            error={values.buildingNumberErr}
                                            labelText={
                                                <span>
                                                    Building Number <small className={classes.required}>*</small>
                                                </span>
                                            }
                                            id="build-no"
                                            inputProps={{
                                                value: buildingNumber,
                                                onChange: (e) => {
                                                    const re = /^[a-zA-Z0-9_.-]*$/;
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                        verify(e.target.value, "length", "buildingNumberErr", 2);
                                                        setBuildingNumber(e.target.value);
                                                    }
                                                },
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            error={values.roadNumberErr}
                                            labelText={
                                                <span>
                                                    Road Number <small className={classes.required}>*</small>
                                                </span>
                                            }
                                            id="road-no"
                                            inputProps={{
                                                value: roadNumber,
                                                onChange: (e) => {
                                                    const re = /^[a-zA-Z0-9_.-]*$/;
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                        verify(e.target.value, "length", "roadNumberErr", 2);
                                                        setRoadNumber(e.target.value);
                                                    }
                                                },
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            error={values.blockNumberErr}
                                            labelText={
                                                <span>
                                                    Block Number <small className={classes.required}>*</small>
                                                </span>
                                            }
                                            id="block-no"
                                            inputProps={{
                                                value: blockNumber,
                                                onChange: (e) => {
                                                    const re = /^[a-zA-Z0-9_.-]*$/;
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                        verify(e.target.value, "length", "blockNumberErr", 2);
                                                        setBlockNumber(e.target.value);
                                                    }
                                                },
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12}>
                                        <h6 className={classes.subHead}> Contact</h6>
                                    </GridItem>
                                    <GridItem xs={12} sm={12}>
                                        <Divider style={{ background: "black" }} fullWidth />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            error={values.phoneErr}
                                            labelText={
                                                <span>
                                                    Phone Number <small className={classes.required}>*</small>
                                                </span>
                                            }
                                            id="phoneNo"
                                            inputProps={{
                                                value: phoneNo,
                                                onChange: (e) => {
                                                    const re = /^[0-9\b]+$/;
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                        if (e.target.value.length <= 8) {
                                                            verify(e.target.value, "length", "phoneErr", 8);
                                                            setPhone(e.target.value);
                                                        }
                                                    }
                                                },
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            error={values.emailErr}
                                            labelText={
                                                <span>
                                                    Email <small className={classes.required}>*</small>
                                                </span>
                                            }
                                            id="email"
                                            inputProps={{
                                                type: "email",
                                                value: email,
                                                onChange: (e) => {
                                                    let reEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                                    setEmail(e.target.value);
                                                    setValues({ ...values, emailErr: false });
                                                    if (!reEmail.test(e.target.value)) {
                                                        setValues({ ...values, emailErr: true });
                                                    } else {
                                                        setValues({ ...values, emailErr: false });
                                                    }
                                                },
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12}>
                                        <h6 className={classes.subHead}> Others</h6>
                                    </GridItem>
                                    <GridItem xs={12} sm={12}>
                                        <Divider style={{ background: "black" }} fullWidth />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            error={values.placeOfBirthErr}
                                            labelText={
                                                <span>
                                                    Place Of Birth <small className={classes.required}>*</small>
                                                </span>
                                            }
                                            id="place-of-birth"
                                            inputProps={{
                                                value: placeOfBirth,
                                                onChange: (e) => {
                                                    const re = /^[a-zA-Z0-9_.-]*$/;
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                        verify(e.target.value, "length", "placeOfBirthErr", 2);
                                                        setPlaceOfBirth(e.target.value);
                                                    }
                                                },
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            error={values.nationalityErr}
                                            labelText={
                                                <span>
                                                    Nationality <small className={classes.required}>*</small>
                                                </span>
                                            }
                                            id="nationality"
                                            inputProps={{
                                                value: nationality,
                                                readOnly: true,
                                                onChange: (e) => {
                                                    const re = /^[a-zA-Z0-9_.-]*$/;
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                        verify(e.target.value, "length", "nationalityErr", 2);
                                                        setNationality(e.target.value);
                                                        setValues({ ...values, nationalityErr: false });
                                                    }
                                                },
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            error={values.occupationErr}
                                            labelText={
                                                <span>
                                                    Occupation <small className={classes.required}>*</small>
                                                </span>
                                            }
                                            id="occupation"
                                            inputProps={{
                                                value: occupation,
                                                onChange: (e) => {
                                                    const re = /^[a-zA-Z0-9_.-]*$/;
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                        verify(e.target.value, "length", "occupationErr", 2);
                                                        setOccupation(e.target.value);
                                                    }
                                                },
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            error={values.prefLanguageErr}
                                            labelText={
                                                <span>
                                                    Preferredered Communication Language <small className={classes.required}>*</small>
                                                </span>
                                            }
                                            id="pref-language"
                                            inputProps={{
                                                value: prefLanguage,
                                                onChange: (e) => {
                                                    const re = /^[a-zA-Z0-9_.-]*$/;
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                        verify(e.target.value, "length", "prefLanguageErr", 2);
                                                        setPrefLanguage(e.target.value);
                                                    }
                                                },
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            error={values.passportNumberErr}
                                            labelText={
                                                <span>
                                                    Passport Number <small className={classes.required}>*</small>
                                                </span>
                                            }
                                            id="passport-no"
                                            inputProps={{
                                                value: passportNumber,
                                                onChange: (e) => {
                                                    const re = /^[a-zA-Z0-9_.-]*$/;
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                        verify(e.target.value, "length", "passportNumberErr", 2);
                                                        setPassportNumber(e.target.value);
                                                    }
                                                },
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <FormControl className={classes.selectFormControl} fullWidth>
                                            <InputLabel className={classes.dateLabel}>
                                                <span>
                                                    Expiry Date <small className={classes.required}>*</small>
                                                </span>
                                            </InputLabel>
                                            <TextField
                                                error={values.passportExpiryErr}
                                                className={classes.dateInput}
                                                type="date"
                                                inputProps={{
                                                    autoComplete: "off",
                                                    type: "date",
                                                    max: "9999/12/30",
                                                    min: moment(new Date()).format("YYYY-MM-DD"),
                                                    value: passportExpiry,
                                                    onChange: (e) => {
                                                        setPassportExpiry(e.target.value);
                                                        verify(e.target.value, "length", "passportExpiryErr", 2);
                                                    },
                                                    onBlur: (e) => {
                                                        if (moment(e.target.value).isBefore(moment(new Date()))) {
                                                            setValues({ ...values, passportExpiryErr: true })
                                                        } else {
                                                            setValues({ ...values, passportExpiryErr: false })
                                                        }
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </GridItem>
                                    <CardFooter className={classes.cardFooter}>
                                        <Button onClick={() => props.back()} size="md" simple color="primary">
                                            <ArrowBackIcon />
                                            Back
                                        </Button>
                                        <Button onClick={() => submitUser()} size="md" color="primary">
                                            {!submitting ? (
                                                "Save"
                                            ) : (
                                                <PulseLoader color="#fff" loading={true} css={override} size={5} margin={2} />
                                            )}
                                        </Button>
                                    </CardFooter>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    );
}
