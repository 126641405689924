import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import avatar from "assets/img/school.jpg";
import Collapse from "@material-ui/core/Collapse";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";

import libs from "controllers/libs.js"
import axios from "axios";
import config from "config";
import Dropzone from "react-dropzone";


const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;



export default function SchoolForm(props) {
    const [schoolName, setSchoolName] = React.useState("")
    const [branch, setBranch] = React.useState("")
    const [branchCode, setBranchCode] = React.useState("")
    const [address, setAddress] = React.useState("")
    const [contactNumber, setContactNumber] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [logo, setLogo] = React.useState("")
    const [website, setWebsite] = React.useState("")
    const [values, setValues] = React.useState({ nameErr: null, });
    const [formView, setFormView] = React.useState(1); //0:-loader , 1:-school form
    const [submitting, setSubmitting] = React.useState(false)

    const validateFrom = () => {
        //function to validate form
        let nameErr = false, flag = true, msg = ""
        if (schoolName.length == 0) {
            flag = false
            nameErr = true
            msg = "Please enter school name."
        }
        if (!flag) {
            props.snack(msg)
        }
        setValues({ ...values, nameErr })
        return flag
    }

    const uploadLogo = async (img) => {
        //function to upload image 
        if (/\.(jpe?g|png)$/i.test(img[0].name) === true) {
            if (img[0].size < 2097152) {
                libs.getBase64(img[0], (result) => { setLogo(result); });
            } else {
                props.snack("File size is too large, Maximum file size is 2 Mb.");
            }
        } else {
            props.snack("Please upload image files.");
        }
    }

    const submitData = async () => {
        //function to submit data
        setSubmitting(true)
        if (!validateFrom()) setSubmitting(false)
        else {
            try {
                let schoolData = {
                    "id": props.id ? props.id : "",
                    "name": schoolName,
                    "branch": branch,
                    "branch_code": branchCode,
                    "address": address,
                    "contact_number": contactNumber,
                    "email": email,
                    "logo": logo,
                    "website": website,
                }
                let resp
                if (props.edit) resp = await axios.post(`${config.url().baseURL}/update-school`, schoolData)
                else resp = await axios.post(`${config.url().baseURL}/add-school`, schoolData)
                if (resp.data.err) {
                    props.snack(props.data.msg)
                    setSubmitting(false)
                }
                else {
                    setSubmitting(false)
                    props.alert("success", "School data saved", resp.data.msg)
                }
            } catch (error) {
                console.log(error)
                setSubmitting(false)
                props.snack("Unable to save.")
            }
        }
    }

    const getSchoolDetails = async (id) => {
        //function to fetch school details 
        try {
            let resp = await axios.post(`${config.url().baseURL}/fetch-school`, { "_id": id })
            if (resp.data.err) props.snack(props.data.msg)
            else {
                let school = resp.data.data
                setSchoolName(school.name ? school.name : "")
                setBranch(school.branch ? school.branch : "")
                setBranchCode(school.branch_code ? school.branch_code : "")
                setAddress(school.address ? school.address : "")
                setContactNumber(school.contact_number ? school.contact_number : "")
                setEmail(school.email ? school.email : "")
                setLogo(school.logo ? school.logo : "")
                setWebsite(school.website ? school.website : "")
                setFormView(1)
                getLogo(id)
            }
        } catch (error) {
            props.snack("Unable to fetch school details.")
        }
    }

    const getLogo = async (id) => {
        //function to fetch school details 
        try {
            let resp = await axios.post(`${config.url().baseURL}/fetch-school-logo`, { "_id": id })
            if (resp.data.err) props.snack(props.data.msg)
            else {
                let school = resp.data.data
                setLogo(school.logo ? school.logo : "")

            }
        } catch (error) {
            props.snack("Unable to fetch logo.")
        }
    }


    React.useEffect(() => { if (props.id) { setFormView(0); getSchoolDetails(props.id) } }, [])

    return (
        <div>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <Collapse
                        in={formView == 0}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                    <Collapse
                        in={formView == 1}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Card profile>
                            <CardAvatar profile>
                                {
                                    props.view ?
                                        <img src={logo ? logo : avatar} alt="..." /> :
                                        <Dropzone onDrop={uploadLogo}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                                        <img src={logo ? logo : avatar} alt="..." />
                                                    </a>
                                                </div>
                                            )}
                                        </Dropzone>
                                }

                            </CardAvatar>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Name of School"
                                            id="name_of_school`"
                                            error={values.nameErr}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                value: schoolName,
                                                onChange: (e) => {
                                                    setSchoolName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : schoolName);
                                                    setValues({ ...values, nameErr: false })
                                                },
                                                onBlur: (e) => {
                                                    setSchoolName(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <CustomInput
                                            labelText="Branch"
                                            id="branch"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                value: branch,
                                                onChange: (e) => {
                                                    setBranch(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : branch);
                                                },
                                                onBlur: (e) => {
                                                    setBranch(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <CustomInput
                                            labelText="Branch Code"
                                            id="branch-code"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                value: branchCode,
                                                onChange: (e) => {
                                                    setBranchCode(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : branchCode);
                                                },
                                                onBlur: (e) => {
                                                    setBranchCode(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText="Address"
                                            id="address"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                multiline: true,
                                                maxRows: 5,
                                                value: address,
                                                onChange: (e) => {
                                                    setAddress(libs.validate(e.target.value, "alphanumeric", 500) ? e.target.value : address);
                                                },
                                                onBlur: (e) => {
                                                    setAddress(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <CustomInput
                                            labelText="Contact Number"
                                            id="contact-number"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                value: contactNumber,
                                                onChange: (e) => {
                                                    setContactNumber(libs.validate(e.target.value, "number", 9) ? e.target.value : contactNumber);
                                                },
                                                onBlur: (e) => {
                                                    setContactNumber(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={7}>
                                        <CustomInput
                                            labelText="Email"
                                            id="email"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                value: email,
                                                onChange: (e) => {
                                                    setEmail(libs.validate(e.target.value, "all", 150) ? e.target.value : email);
                                                },
                                                onBlur: (e) => {
                                                    setEmail(libs.validate(e.target.value.trim(), "email", 150) ? e.target.value : "")
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText="Website"
                                            id="website"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                readOnly: props.view,
                                                multiline: true,
                                                maxRows: 3,
                                                value: website,
                                                onChange: (e) => {
                                                    setWebsite(libs.validate(e.target.value, "all", 150) ? e.target.value : website);
                                                },
                                                onBlur: (e) => {
                                                    setWebsite(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            {
                                props.view ?
                                    <div style={{ textAlign: "center", marginBottom: "20px" }}>
                                        <Button simple onClick={() => props.back()} color="primary">Back</Button>
                                    </div> :
                                    <div style={{ textAlign: "center", marginBottom: "20px" }}>
                                        <Button simple onClick={() => props.back()} color="primary">Back</Button>  <Button disabled={submitting} onClick={submitData} color="primary">Save</Button>
                                    </div>
                            }
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>
        </div>
    );
}
