import React from 'react';
import axios from "axios";
import config from "config.js"
import moment from "moment";
import PropagateLoader from "react-spinners/PropagateLoader";
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import InputBase from "@material-ui/core/InputBase";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import MuiAlert from "@material-ui/lab/Alert";
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MuiTable from '@material-ui/core/Table';
import MUIDataTable from "mui-datatables";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PreviewIcon from '@mui/icons-material/Preview';

import EditForm from 'views/Customers/Components/EditForm.js';

import styles from "assets/jss/material-dashboard-react/views/customersStyle.js";

const useStyles = makeStyles(styles);

const override = css`
display: block;
margin: 0px;
border-color: red;
`;

const columns = [
    {
        name: "date",
        label: "Date",
    },
    {
        name: "cpr",
        label: "CPR",
    },
    {
        name: "mobile",
        label: "Mobile",
    },
    {
        name: "userType",
        label: "User Type",
    },
    {
        name: "status",
        label: "Status",
    },
    {
        name: "actions",
        label: "Actions",
    },
];



const options = {
    selectableRows: false,
    elevation: 0,
    print: false,
    download: false,
    textLabels: {
        body: {
            noMatch: "No matching records found",
        },
    }
};

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const logColumns = [
    { id: 'date', label: 'Date', width: 70 },
    { id: 'time', label: 'Time', width: 70 },
    { id: 'user', label: 'User' },
    { id: 'status', label: 'Status', width: 70 },
    { id: 'status_message', label: 'Status Info', minWidth: 170 },
    { id: 'remarks', label: 'Remarks', minWidth: 170 },
];

export default function CustomerEdit() {
    const classes = useStyles();
    const [customersList, setCustomerData] = React.useState([]);
    const [searchKey, setSearchKey] = React.useState("");
    const [submitting, setSubmitting] = React.useState(false);
    const [respAlert, setRespAlert] = React.useState(null);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [openSnack, setOpenSnack] = React.useState(false);

    const [showLoader, setShowLoader] = React.useState(true);
    const [showCustomersTable, setShowCustomersTable] = React.useState(false);
    const [customersTable, setCustomersTable] = React.useState(null);
    const [showEdit, setShowEdit] = React.useState(false);
    const [editForm, setEditForm] = React.useState(null);

    const [editLogModal, setEditLogModal] = React.useState(false);
    const [editLogs, setEditLogs] = React.useState([]);

    function openSnackBar(msg) {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const actions = [
        {
            icon: "article_rounded_icon",
            tooltip: 'Log',
            onClick: (event, rowData) => fetchEditLog(rowData.id),
            // onClick: (event, rowData) => alert(rowData.cpr),
        },
        {
            icon: "preview_icon",
            tooltip: 'View & Edit',
            onClick: (event, rowData) => openEditEmployee(rowData),
            // onClick: (event, rowData) => alert(rowData.cpr),
        },

    ]
    React.useEffect(() => {
        setSearchKey("")
    }, [])

    const searchCustomer = () => {
        if (searchKey.length !== 0) {
            axios.get(`${config.url().baseURL}/search-edit-users/${searchKey}`).then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    setCustomerList(respData.data)
                } else {
                    setCustomerData([]);
                    openSnackBar(respData.msg);
                }
            }).catch((err) => {
                setCustomerData([]);
                fetchCustomers()
                openSnackBar("Unable to process");

            });
        }
    }

    const resetSearch = () => {
        //function to reset search
        setSearchKey("")
        fetchCustomers()
    }


    React.useEffect(() => {
        fetchCustomers();
        setSearchKey("")
    }, []);
    const fetchCustomers = () => {
        //function to fetch customers list
        setShowLoader(true);
        setShowCustomersTable(false);
        setCustomerData([]);
        setCustomersTable(null)
        axios.get(`${config.url().baseURL}/get-edit-users`).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                setCustomerList(respData.data)
                setShowLoader(false);
                setShowCustomersTable(true);
            } else {
                setShowLoader(false);
                setShowCustomersTable(true);
                openSnackBar(respData.msg);
            }
        }).catch((err) => {
            setCustomerData([]);
            setShowLoader(false);
            setShowCustomersTable(true);
            console.log(err)
            openSnackBar("Unable to process");
        });
    }

    const setCustomerList = (data) => {
        //function to ser customer list
        let updatedCustomers = []
        Promise.all(data.map((customer) => {
            updatedCustomers.push({
                date: moment(customer.updatedAt).format("DD/MM/YYYY"),
                mobile: customer.mobile,
                cpr: customer.cpr,
                userType: customer.userType,
                userID: customer.userID,
                status: customer.status,
                id: customer._id,
                actions: <>
                    <Tooltip placement="top" title="Log">
                        <IconButton
                            onClick={() => fetchEditLog(customer._id)}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="view">
                            <AssignmentIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip placement="top" title="View & Edit">
                        <IconButton
                            onClick={() => openEditEmployee(customer)}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="view">
                            <PreviewIcon />
                        </IconButton>
                    </Tooltip>
                </>
            })

        }))
        setCustomersTable(<MUIDataTable data={updatedCustomers} columns={columns} options={options} />)
        setCustomerData(updatedCustomers)
    }

    const openEditEmployee = (emp) => {
        //function open employee details edit
        setShowEdit(true)
        setShowCustomersTable(false)
        setCustomersTable(null)
        setEditForm(<EditForm emp={emp} close={closeEdit} />)
    }

    const closeEdit = () => {
        //function to close edit form modal
        setEditForm(null)
        setShowEdit(false)
        setShowLoader(true)
        fetchCustomers()
    }

    const fetchEditLog = (id) => {
        //function to fetch company logs
        setEditLogModal(true);
        axios.get(`${config.url().baseURL}/get-cust-edit-logs/${id}`).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                //console.log(JSON.stringify(respData.data[0].log))
                if (respData.data.length !== 0) {
                    setEditLogs(respData.data);
                } else {
                    setEditLogModal(false);
                    openSnackBar("No log found.");
                }

            } else {
                setEditLogModal(false);
                openSnackBar(respData.msg);
            }
        });
    };
    return (
        <>
            <Collapse in={showLoader} style={{ transformOrigin: "0 0 0", textAlign: "center" }} {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
            </Collapse>
            <Collapse in={showCustomersTable} style={{ transformOrigin: "0 0 0", textAlign: "center" }} {...(true ? { timeout: 500 } : {})}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={6} autoComplete="off">
                        <Paper elevation={1} className={classes.addDeptContainer}>
                            <InputBase
                                type='search'
                                fullWidth
                                className={classes.input}
                                placeholder="Search"
                                inputProps={{
                                    "aria-label": "Search",
                                    autoComplete: "off",
                                    autoFocus: "off",
                                    onChange: (event) => { setSearchKey(event.target.value) },
                                    onBlur: (event) => { setSearchKey(event.target.value.trim()) },
                                    value: searchKey,
                                    onKeyDown: (e) => {
                                        if (e.key == "Enter") {
                                            searchCustomer()
                                        }
                                    }
                                }}
                            />
                            {!submitting ? (
                                <Button className={classes.addDeptButton}
                                    onClick={() => searchCustomer()}
                                    size="lg" color="primary">
                                    <SearchIcon fontSize="medium" />
                                </Button>
                            ) : (
                                <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
                            )}
                        </Paper>
                        <Tooltip placement="bottom" title="Reset">
                            <IconButton className={classes.addDeptButton}
                                onClick={() => resetSearch()}
                                size="lg" color="primary">
                                <RotateLeftIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardBody>
                                {customersTable}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </Collapse>
            <Collapse in={showEdit} style={{ transformOrigin: "0 0 0", textAlign: "center" }} {...(true ? { timeout: 500 } : {})}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Edit Customer</h4>
                    </CardHeader>
                    <CardBody>
                        {editForm}
                    </CardBody>
                </Card>
            </Collapse>
            <Dialog
                classes={{
                    root: classes.left + " " + classes.modalRoot,
                    paper: classes.companyStatusLogModal,
                }}
                open={editLogModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setEditLogModal(false);
                    setEditLogs([]);
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>Edit Log</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <TableContainer className={classes.container}>
                        <MuiTable stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {logColumns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {editLogs.map((row, key) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                                            <TableCell>
                                                {moment(row.date).format("DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell>
                                                {moment(row.date).format("HH:MM:SS")}
                                            </TableCell>
                                            <TableCell>
                                                {row.user ? row.user.firstName + " " + row.user.lastName : null}
                                            </TableCell>
                                            <TableCell>
                                                {row.status}
                                            </TableCell>
                                            <TableCell>
                                                {row.status_message}
                                            </TableCell>
                                            <TableCell>
                                                {row.remarks}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </MuiTable>
                    </TableContainer>
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setEditLogModal(false);
                            setEditLogs([]);
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </>
    );
}