
import * as React from 'react';
import axios from "axios";
import config from "config.js"
import Collapse from "@material-ui/core/Collapse";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Dropzone from "react-dropzone";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import moment from 'moment';
import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";
import download from "js-file-download";
import { Modal, TextField } from '@mui/material';
import { Grid, IconButton, Input } from '@material-ui/core';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import "./school.css"
import GridItem from 'components/Grid/GridItem';


const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
}));

export default function StudentList(props) {
    const [respAlert, setRespAlert] = React.useState(null);
    const [uploadedFile, setUploadedFile] = React.useState(null);

    const classes = useStyles();

    // const submitDoc = async (doc) => {

    //     //function to upload doc to the server 
    //     setRespAlert(null)
    //     let requestBody = new FormData();
    //     requestBody.set('file', doc);

    //     try {
            
    //         // setListView(0)
    //         let resp = await axios.post(`${config.url().wallet_URL}/upload-file`, requestBody)
    //         if (resp.data.err) {
    //             // setListView(1)
    //             props.snack(resp.data.msg)
    //             // setListView(1)
    //             setOpen(false)
    //             setRecordName(false)
    //         } else {
    //             if(fileName=="Licence"){
    //                 setLicence(resp.data.data.filePath.Location)
    //             }else if(fileName=="Moi"){
    //                 setMoi(resp.data.data.filePath.Location)
    //             }else if(fileName=="Aggrement"){
    //                 setAggrement(resp.data.data.filePath.Location)
    //             }
    //             props.snack(resp.data.msg, true)
    //             setOpen(false)
    //             setRecordName(false)

    //         }
    //     } catch (error) {
    //         console.log(error)
    //         props.snack("Unable to upload.")
    //         setOpen(false)
    //         setRecordName(false)
    //         //    fetchStudents(props.id)
    //     }
    // }

    // const uploadNewStudentList = async (doc) => {
    //     // if (/\.(xlsx|xls|csv)$/i.test(doc[0].name) === true) {
    //     if (doc[0].size < 2097152) {
    //         setRespAlert(
    //             <SweetAlert
    //                 warning
    //                 input
    //                 style={{ display: "block" }}
    //                 title="Are you sure?"
    //                 onConfirm={() => {
    //                     props.submitDoc(doc[0])
    //                 }}
    //                 onCancel={() => {
    //                     setRespAlert(null)
    //                 }} Data
    //                 showCancel
    //                 confirmBtnText="Yes"
    //                 cancelBtnText="No"
    //                 cancelBtnCssClass={classes.button}
    //                 confirmBtnCssClass={classes.button + " " + classes.danger}
    //             >
    //                 Are You Sure Want To Upload
    //             </SweetAlert>)



    //     } else {

    //         props.snack("File size is too large, Maximum file size is 2 Mb.");
    //     }
    //     // } else {
    //     //     snack("Please upload xlx files.");
    //     // }


    //     //function to upload excel file
    // }

    return (
        

            <Grid>
                <GridItem xs={12}>
                    <Dropzone onDrop={(doc) => {
                        setUploadedFile(doc)
                        
                        props.uploadNewStudentList(doc,(props.name).replace(/[*\s]/g, ''))
                        // setFileName("Licence")

                    }} >
                        {({ getRootProps, getInputProps }) => (
                            <>
                            <p>{props.name}</p>
                                <div {...getRootProps()} style={{ padding: 10 }}>
                                    <input {...getInputProps()} />
                                    <CloudUploadIcon size="sm" fullWidth color="secondary">
                                        {props.name}
                                    </CloudUploadIcon>
                                </div>
                               
                                {(props.fileName&&uploadedFile) && (
                                    <p>Uploaded file: {props.fileName}</p>
                                )}
                            </>
                        )}
                    </Dropzone>
                            
                </GridItem>
            </Grid>
        
    )

}