import React from "react";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";




export default function LoginPage(props) {
    const override = css`
    display: block;
    margin-top:80%
  `;



    function isDate(val) {
        return Object.prototype.toString.call(val) === "[object Date]";
    }

    function isObj(val) {
        return typeof val === "object";
    }

    function stringifyValue(val) {
        if (isObj(val) && !isDate(val)) {
            return JSON.stringify(val);
        } else {
            return val;
        }
    }

    function buildForm({ action, params }) {
        const form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", action);

        Object.keys(params).forEach((key) => {
            const input = document.createElement("input");
            input.setAttribute("type", "hidden");
            input.setAttribute("name", key);
            input.setAttribute("value", stringifyValue(params[key]));
            form.appendChild(input);
        });

        return form;
    }

    function post(details) {
        console.log(details);
        const form = buildForm(details);
        document.body.appendChild(form);
        form.submit();
        form.remove();
    }

    function getUrlData() {
        const queryParams = new URLSearchParams(window.location.search);
        // alert(queryParams)
    }

    React.useEffect(() => {
        getUrlData()
    }, []);


    return (
        <div style={{ textAlign: "center", minHeight: "80vh", paddingTop:"40vh", height: "auto" }}>
            <PropagateLoader color="#004fa4"  loading={true} size={10} />
        </div>
    );
}
