import React, { useState } from "react";
import "./styles.css";
import Select from "react-select";
import FormControl from "@material-ui/core/FormControl";

export default function App(props) {
    // React state to manage selected options
    const [selectedOptions, setSelectedOptions] = useState();
    const [optionList, setOptionList] = useState([])
    React.useEffect(() => {
        SetCorporate()
        ClearCorpNames()
    }, [props])
    React.useEffect(() => {
        setSelectedOptions("")

    }, [])
    const SetCorporate = () => {
        const optionList = []
        props && props.corporats && props.corporats.map((corp) => {
            corp.value = corp._id
            corp.label = corp.companyName
            optionList.push(corp)
        })
        setOptionList(optionList)
    }
    const ClearCorpNames = () => {
        const clearOptionList = []
        props && props.clearSelect && props.clearSelect.map((corp) => {
            corp.value = corp._id
            corp.label = corp.companyName
            clearOptionList.push(corp)
        })
        setSelectedOptions(clearOptionList)
    }
    // Function triggered on selection
    function handleSelect(data) {
        console.log(data)
        if (data.length <= 10) {
            props.corpotareSelected(data);
        }
        else {
            props.snack("Maximum no of selection is restricted to 10 corporates")
        }
    }
    return (

        <FormControl className="dropdown-container" autoComplete="off">
            
            <Select
                options={optionList}
                placeholder="Select Corporate"
                onChange={handleSelect}
                value={selectedOptions}
                isSearchable={true}
                autoComplete={"none" || "off" || "nope"}
                type="search"
                autoFocus={false}
                isMulti
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 1,
                    colors: {
                        ...theme.colors,
                        text: 'black',
                        primary25: 'blue',
                        primary: 'black',
                    },
                })}
            />
        </FormControl>


    );
}