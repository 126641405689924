import React from "react";
import config from "config.js"
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import Paper from '@material-ui/core/Paper';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import moment from "moment";
import JSONPretty from 'react-json-pretty';
import { Button } from "@material-ui/core";

const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
    },
    tabContent: {
        maxHeight: "60vh"
    },
    logPaper: {
        margin: "15px",
        padding: "15px"
    },
    logContainer: {
        maxHeight: "500px",
        overflowY: "scroll"
    },
}));

export default function FullWidthTabs(props) {
    const classes = useStyles();
    const [newAvenuesSysLog, setNewAvenuesLog] = React.useState([]);
    const [noNewAvenuesSysLog, setNoNewAvenuesLog] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(true);
    const [showLoadMore, setShowLoadMore] = React.useState(true);
    const [pageNo, setPageNo] =React.useState(0);


    React.useEffect(() => {
        getNewAvenuesLogs(pageNo)
    }, []);



    const getNewAvenuesLogs = (page) => {
        //function to fetch netsys logs for a customer
        // if (newAvenuesSysLog.length == 0) {
            setShowLoader(true);
            setShowLoadMore(true);
            axios.get(`${config.url().baseURL}/failed-cpr/${page}`)
                .then((resp) => {
                    let respData = resp.data;
                    setPageNo(page+1);
                    if (!respData.err) {
                        if(page == 0){
                        setNewAvenuesLog(respData.data)
                        setShowLoader(false);
                    }
                        else{
                            setNewAvenuesLog(p=>[...p,...respData.data])
                        setShowLoader(false);
                        }
                    } else {
                        if(page == 0){
                            setNewAvenuesLog([]);
                            setNoNewAvenuesLog(true)
                        }
                        else{
                        setShowLoadMore(false);
                        setShowLoader(false);
                    }}
                })
                .catch((err) => {
                    setNewAvenuesLog([]);
                    setNoNewAvenuesLog(true)
                    setShowLoadMore(false);
                });
        // }
    }


    return (
        <div className={classes.root}>
            <Collapse
                in={noNewAvenuesSysLog}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <h4>No Log Found.</h4>
            </Collapse>
            <Collapse
                in={!noNewAvenuesSysLog}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <Collapse
                    in={newAvenuesSysLog.length == 0}
                    style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                    {...(true ? { timeout: 500 } : {})}>
                    <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                </Collapse>
                <Collapse
                    in={showLoader}
                    style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                    {...(true ? { timeout: 500 } : {})}>
                    <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                </Collapse>
                <Collapse
                    in={newAvenuesSysLog.length !== 0}
                    style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                    {...(true ? { timeout: 500 } : {})}>
                    <div className={classes.logContainer}>
                        {newAvenuesSysLog.map((log) => {
                            return (
                                <>
                                    <Paper elevation={3} className={classes.logPaper} >
                                        {log._id ? (
                                            <>
                                                Log ID :{log._id}
                                                <br />
                                            </>
                                        ) : null}
                                        {log.cpr ? (
                                            <>
                                                CPR :{log.cpr}
                                                <br />
                                            </>
                                        ) : null}
                                        {log.updatedAt ? (
                                            <>
                                                Date : {moment(moment(new Date(log.updatedAt))).format("DD/MM/YYYY")}
                                                <br />
                                                Time :  {moment(moment(new Date(log.updatedAt))).format("hh:mm:ss A")}
                                                <br />
                                            </>
                                        ) : null}
                                        {log.errorMsg ? (
                                            <Accordion  >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header">
                                                    <Typography>Error Messages</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails  >
                                                    <div style={{ width: "750px !important" }}>
                                                        <JSONPretty data={log.errorMsg} />
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        ) : null}
                                    </Paper>
                                </>
                            );
                        })}
                        {showLoadMore ? <Button fullWidth onClick={() => getNewAvenuesLogs(pageNo)}>Load More</Button> : null}
                    </div>
                </Collapse>
            </Collapse>
        </div >
    );
}
