import React from "react";
import config from "config.js"
import libs from "controllers/libs";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import axios from "axios";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { useDispatch } from "react-redux";
import { setCompanyID } from "redux/actions/corporateOnboarding.js";
import { useSelector } from 'react-redux';
import Dropzone from "react-dropzone";
import countries from "variables/countries.json";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Document, Page } from 'react-pdf';
import EditIcon from '@material-ui/icons/Edit';
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete"
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomSelectStyle from "assets/jss/material-dashboard-react/customSelectStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import { setPhone } from "redux/actions/user";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const override = css`
  display: block;
  margin: 0px;
  border-color: red;
`;

const styles = (theme) => ({
  ...CustomSelectStyle,
  autocomplete: {
    marginTop: "27px"
  },
  submitButton: {
    float: "right !important",
    display: "inlineBlock",
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  backButton: {
    float: "left !important",
    display: "inlineBlock",
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  required: {
    color: "red",
  },
  subHead: {
    fontWeight: "400",
    marginBottom: "-2px !important",
    float: "left",
    display: "inlineBlock",
  },
  switch: {
    float: "right",
    display: "inlineBlock",
    marginTop: "20px !important",
  },
  question: {
    fontSize: "15px",
    fontWeight: "400",
  },
  center: {
    textAlign: "center",
  },
  dateLabel: {
    marginTop: "-18px",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "12px",
    fontWeight: "400",
  },
  dateInput: {
    marginTop: "20px !important",
  },
  addOwnerActions: {
    float: "right",
    display: "inlineBlock",
  },
  ...modalStyle(theme),
});

const useStyles = makeStyles(styles);

export default function CompanyInformation(props) {
  const dispatch = useDispatch();
  var temContactData = []
  var temContactTableData = []
  const corpData = useSelector(state => state.corporateOnboarding.companyInfo);
  const user = useSelector(state => state.user);
  const titles = ["Mr", "Mrs", "Miss", "Ms"]
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [submittingLabel, setSubmittingLabel] = React.useState("Saving Data");
  const [showForm, setShowForm] = React.useState(false);
  const [copyRegisterAdd, setCopyRegisterAdd] = React.useState(false);
  const [showAddContact, setShowAddContact] = React.useState(true);
  const [contactIDBtnColor, setContactIDBtnColor] = React.useState("secondary");
  const [idTypeLabel, setIDTypeLabel] = React.useState("Commercial Registration ID ");
  const [tempKey, setTempKey] = React.useState(0);

  const [compID, setCompID] = React.useState("");
  const [companyName, seCompanyName] = React.useState("");
  const [entityType, setEntityType] = React.useState("");

  const [regFlatNo, setRegFlatNo] = React.useState("");
  const [regBuildingNo, setRegBuildingNo] = React.useState("");
  const [regRoad, setRegRoad] = React.useState("");
  const [regBlock, setRegBlock] = React.useState("");
  const [regArea, setRegArea] = React.useState("");
  const [regCountry, setRegCountry] = React.useState({ "ISO": "BH", "country": "Bahrain" });

  const [trFlatNo, setTrFlatNo] = React.useState("");
  const [trBuildingNo, setTrBuildingNo] = React.useState("");
  const [trRoad, setTrRoad] = React.useState("");
  const [trBlock, setTrBlock] = React.useState("");
  const [trArea, setTrArea] = React.useState("");
  const [trCountry, setTrCountry] = React.useState({ "ISO": "BH", "country": "Bahrain" });

  const [title, setTitle] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [contactID, setContactID] = React.useState("");
  const [contactCPR, setContactCPR] = React.useState("");
  const [contactIDName, setContactIDName] = React.useState("");
  const [contactData, setContactData] = React.useState([]);
  const [contactTableJSONData, setContactTableJSONData] = React.useState([]);
  const [contactTableData, setContactTableData] = React.useState([]);

  const [typeOfID, setTypeOfID] = React.useState("");
  const [commercialRegistrationID, setCommercialRegistrationID] = React.useState("");
  const [expiryDate, setExpiryDate] = React.useState("");
  const [dateOfIncorporation, setDateOfIncorporation] = React.useState("");
  const [countryOfIncorporation, setCountryOfIncorporation] = React.useState({ "ISO": "BH", "country": "Bahrain" });
  const [listedOnStockExchange, setListedOnStockExchange] = React.useState("");
  const [stockExchangeName, setStockExchangeName] = React.useState("");

  const [regulatoryAuthority, setRegulatoryAuthority] = React.useState("");
  const [licenseNumber, setLicenseNumber] = React.useState("");
  const [enforcementActions, setEnforcementActions] = React.useState("");
  const [actionDetails, setActionDetails] = React.useState("");

  const [documentViewerData, setDocumentViewerData] = React.useState("");
  const [openImageViewer, setOpenImageViewer] = React.useState(false);
  const [openPDFeViewer, setOpenPDFViewer] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [editData, setEditData] = React.useState({});

  const [contactEdit, setContactEdit] = React.useState(false);
  const [contactEditTemp, setContactEditTemp] = React.useState([]);
  const [deletedContact, setDeletedContact] = React.useState([]);
  const [editFlag, setEditFlag] = React.useState({ status: false, id: null });

  const [editContactID, setEditContactID] = React.useState(null);
  const [values, setValues] = React.useState({
    comNameErr: null,
    entityErr: null,

    regFlatNoErr: null,
    regBuildingNoErr: null,
    regRoadErr: null,
    regBlockErr: null,
    regAreaErr: null,
    regCountryErr: false,

    trFlatNoErr: null,
    trBuildingNoErr: null,
    trRoadErr: null,
    trBlockErr: null,
    trAreaErr: null,
    trCountryErr: null,

    titleErr: null,
    fullNameErr: null,
    mobileErr: null,
    emailErr: null,
    contactCPRERR: null,

    compIDErr: null,
    commercialRegistrationIDErr: null,
    expiryDateErr: null,
    dateOfIncorporationErr: null,
    countryOfIncorporationErr: null,
    listedOnStockExchangeErr: null,
    stockExchangeNameErr: null,

    regulatoryAuthorityErr: null,
    licenseNumberErr: null,
    enforcementActionsErr: null,
    actionDetailsErr: null,
  });

  React.useEffect(() => {

    if (props.edit) {
      setCompanyData()
    }
  }, [props.edit]);

  React.useEffect(() => {
    if ((props.back) || (props.cacheEdit)) {
      if (props.id !== undefined) {
        setBackData(props.id)
      }
    }
  }, [props.back, props.cacheEdit]);

  React.useEffect(() => {
    setValues({
      ...values,
      comNameErr: false,
      entityErr: false,

      regFlatNoErr: false,
      regBuildingNoErr: false,
      regRoadErr: false,
      regBlockErr: false,
      regAreaErr: false,
      regCountryErr: false,

      compIDErr: false,
      commercialRegistrationIDErr: false,
      expiryDateErr: false,
      dateOfIncorporationErr: false,
      countryOfIncorporationErr: false,
      listedOnStockExchangeErr: false,
      stockExchangeNameErr: false,

      actionDetailsErr: false,
    });
  }, []);
  const setCompanyData = async () => {
    //function to set company data from redux
    setEditData(corpData)
    seCompanyName(corpData.NameOfCompany)
    setEntityType(corpData.LegalForm.TypeOfEntity)
    setRegFlatNo(corpData.RegisteredBusinessAddress.FlatNumber)
    setRegBuildingNo(corpData.RegisteredBusinessAddress.BuildingNumber)
    setRegRoad(corpData.RegisteredBusinessAddress.RoadNumber)
    setRegBlock(corpData.RegisteredBusinessAddress.BlockNumber)
    setRegArea(corpData.RegisteredBusinessAddress.Area)
    let regCountryTemp = countries.find(country => country.ISO == corpData.RegisteredBusinessAddress.Country)
    setRegCountry(regCountryTemp)
    setTrFlatNo(corpData.TradingAddress.FlatNumber)
    setTrBuildingNo(corpData.TradingAddress.BuildingNumber)
    setTrRoad(corpData.TradingAddress.RoadNumber)
    setTrBlock(corpData.TradingAddress.BlockNumber)
    setTrArea(corpData.TradingAddress.Area)
    let trCountryTemp = countries.find(country => country.ISO == corpData.TradingAddress.Country)
    setTrCountry(trCountryTemp)
    setValues({
      ...values,
      regFlatNoErr: false,
      regBuildingNoErr: false,
      regRoadErr: false,
      regBlockErr: false,
      regCountryErr: false,
      regAreaErr: false
    });

    let tempJson = [];
    let tempTableJSON = [];
    setTypeOfID(corpData.IncorporationDetails.TypeOfID)
    setCommercialRegistrationID(corpData.IncorporationDetails.RegistrationID)
    setExpiryDate(corpData.IncorporationDetails.ExpiryDate)
    setDateOfIncorporation(corpData.IncorporationDetails.DateOfIncorporation)
    let incorpCountryTemp = countries.find(country => country.ISO == corpData.IncorporationDetails.CountryOfIncorporation)
    setCountryOfIncorporation(incorpCountryTemp)
    if (corpData.IncorporationDetails.IdListedOnStock == "YES") {
      setListedOnStockExchange("true")
      setStockExchangeName(corpData.IncorporationDetails.StockListed[0])
    } else {
      setListedOnStockExchange("false")
    }

    if (corpData.RegulatoryStatus.RegulatoryAuthorityName) {
      setRegulatoryAuthority(corpData.RegulatoryStatus.RegulatoryAuthorityName)
      setLicenseNumber(corpData.RegulatoryStatus.LicenseNumber)
      if (corpData.RegulatoryStatus.IsAnyViolationOnRegulations == "YES") {
        setEnforcementActions("true")
        setActionDetails(corpData.RegulatoryStatus.ViolationDetails)
      } else {
        setEnforcementActions("false")
      }
    }

    Promise.all(corpData.ContactDetails.map((contact, key) => {
      tempJson.push({
        title: contact.Title,
        fullName: contact.FullName,
        mobile: contact.Mobile,
        email: contact.Email,
        id_proof: "",
        DocumentID: contact.DocumentID,
        cpr: contact.CPRNumber
      })
      tempTableJSON.push({
        title: contact.Title,
        fullName: contact.FullName,
        mobile: contact.Mobile,
        email: contact.Email,
        id_proof: contact.DocumentID,
        cpr: contact.CPRNumber
      })
    })).then(() => {
      temContactData = tempJson
      contactsToArray(tempTableJSON);
      setContactTableJSONData(tempTableJSON)
      setContactData(tempJson);
      setShowAddContact(false)
    })



  }

  const openSnackBar = (msg) => {
    //function to tost snackbar message
    setOpenSnack(true);
    setSnackBarMsg(msg);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  };

  setTimeout(function () {
    setShowForm(true);
  }, 500);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const verifyEmail = (value) => {
    // function that returns true if value is email, false otherwise
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  const verifyLength = (value, length) => {
    // function that verifies if a string has a given length or not
    if (value !== undefined && value.length >= length) {
      return true;
    }
    return false;
  };

  const verify = (value, type, errVariable, length) => {
    switch (type) {
      case "email":
        if (libs.validate(value, "email", 64)) {
          setValues({ ...values, [errVariable]: false });
        } else {
          setValues({ ...values, [errVariable]: true });
        }
        break;
      case "length":
        if (verifyLength(value, length)) {
          setValues({ ...values, [errVariable]: false });
        } else {
          setValues({ ...values, [errVariable]: true });
        }
        break;
      case "phone":
        if (value.length <= 8) {
          if (libs.validate(value, "number", 8)) {
            if (verifyLength(value, length)) {
              setMobile(value);
              setValues({ ...values, [errVariable]: false });
            } else {
              setMobile(value);
              setValues({ ...values, [errVariable]: true });
            }
          }
        }
        break;
      case "cpr":
        if (value.length <= 9) {
          if (libs.validate(value, "number", 9)) {
            if (verifyLength(value, length)) {
              setContactCPR(value);
              setValues({ ...values, [errVariable]: false });
            } else {
              setContactCPR(value);
              setValues({ ...values, [errVariable]: true });
            }
          }
        }
        break;
      default:
        break;
    }
  };


  const handleSameAddress = (event) => {
    //function to set registered address as trading address
    setCopyRegisterAdd(event.currentTarget.checked);
    if (!event.currentTarget.checked) {
      setTrFlatNo("");
      setTrBuildingNo("");
      setTrRoad("");
      setTrBlock("");
      setTrArea("");
      setTrCountry("");
    } else {
      if (
        values.regFlatNoErr !== null &&
        values.regBuildingNoErr !== null &&
        values.regRoadErr !== null &&
        values.regBlockErr !== null &&
        values.regAreaErr !== null &&
        values.regCountryErr !== null &&
        values.regFlatNoErr === false &&
        values.regBuildingNoErr === false &&
        values.regRoadErr === false &&
        values.regBlockErr === false &&
        values.regAreaErr === false &&
        values.regCountryErr === false
      ) {
        setTrFlatNo(regFlatNo);
        setTrBuildingNo(regBuildingNo);
        setTrRoad(regRoad);
        setTrBlock(regBlock);
        setTrArea(regArea);
        setTrCountry(regCountry);
        if (props.edit) {
          let editDataTemp = editData
          editDataTemp.TradingAddress.FlatNumber = regFlatNo;
          editDataTemp.TradingAddress.BuildingNumber = regBuildingNo;
          editDataTemp.TradingAddress.RoadNumber = regRoad;
          editDataTemp.TradingAddress.BlockNumber = regBlock;
          editDataTemp.TradingAddress.Area = regArea;
          editDataTemp.TradingAddress.Country = regFlatNo;
          setEditData(editDataTemp)
          // setCopyRegisterAdd(true);
        }
      } else {
        setCopyRegisterAdd(false);
        openSnackBar("Please fill all Registered address details.");
      }
    }
  };

  const validateForm = () => {
    //function to validate form
    let flag = true;
    let comNameErr = false,
      entityErr = false,
      regFlatNoErr = false,
      regBuildingNoErr = false,
      regRoadErr = false,
      regBlockErr = false,
      regAreaErr = false,
      regCountryErr = false,
      commercialRegistrationIDErr = false,
      expiryDateErr = false,
      dateOfIncorporationErr = false,
      countryOfIncorporationErr = false,
      listedOnStockExchangeErr = false,
      stockExchangeNameErr = false,
      actionDetailsErr = false,
      regulatoryAuthorityErr = false,
      compIDErr = false;
    let snackBarMsg = ""
    if (!verifyLength(companyName, 3)) {
      comNameErr = true;
      snackBarMsg = "Please fill company name."
      flag = false
    }
    if (!verifyLength(entityType, 3)) {
      entityErr = true;
      snackBarMsg = (snackBarMsg == "" ? "Please select entity type." : snackBarMsg);
      flag = false
    }
    if (!verifyLength(regFlatNo, 1)) {
      regFlatNoErr = true;
      snackBarMsg = (snackBarMsg == "" ? "Please enter registered flat no." : snackBarMsg);
      flag = false
    }
    if (!verifyLength(regBuildingNo, 1)) {
      regBuildingNoErr = true;
      snackBarMsg = (snackBarMsg == "" ? "Please enter registered building no." : snackBarMsg);
      flag = false
    }
    if (!verifyLength(regRoad, 2)) {
      regRoadErr = true;
      snackBarMsg = (snackBarMsg == "" ? "Please enter registered road no." : snackBarMsg);
      flag = false
    }
    if (!verifyLength(regBlock, 2)) {
      regBlockErr = true;
      snackBarMsg = (snackBarMsg == "" ? "Please enter registered block no." : snackBarMsg);
      flag = false
    }
    if (!verifyLength(regArea, 2)) {
      regAreaErr = true;
      snackBarMsg = (snackBarMsg == "" ? "Please enter registered area no." : snackBarMsg);
      flag = false
    }
    if (!verifyLength(regCountry.country, 2)) {
      regCountryErr = true;
      snackBarMsg = (snackBarMsg == "" ? "Please enter registered country." : snackBarMsg);
      flag = false
    }
    if (contactData.length == 0) {
      snackBarMsg = (snackBarMsg == "" ? "Please add minimum one contact person." : snackBarMsg);
      flag = false
    }
    if (!verifyLength(typeOfID, 2)) {
      compIDErr = true;
      snackBarMsg = (snackBarMsg == "" ? "Please select type of registration ID." : snackBarMsg);
      flag = false
    }
    if (!verifyLength(commercialRegistrationID, 2)) {
      commercialRegistrationIDErr = true;
      snackBarMsg = (snackBarMsg == "" ? "Please enter registration ID." : snackBarMsg);
      flag = false
    }
    if ((moment(expiryDate).isBefore(moment(new Date()))) || (moment(expiryDate).isAfter(moment("2040-12-31"))) || (expiryDate == "")) {
      expiryDateErr = true;
      snackBarMsg = (snackBarMsg == "" ? "Please enter the valid expiry date." : snackBarMsg);
      flag = false;
    }
    if ((moment(dateOfIncorporation).isAfter(moment(new Date()))) || (moment(dateOfIncorporation).isBefore(moment("1900-12-31"))) || (dateOfIncorporation == "")) {
      dateOfIncorporationErr = true;
      snackBarMsg = (snackBarMsg == "" ? "Please enter the valid incorporation date." : snackBarMsg);
      flag = false;
    }
    if (!verifyLength(countryOfIncorporation.country, 2)) {
      countryOfIncorporationErr = true;
      snackBarMsg = (snackBarMsg == "" ? "Please enter the country of incorporation." : snackBarMsg);
      flag = false;
    }
    if (!verifyLength(listedOnStockExchange, 2)) {
      listedOnStockExchangeErr = true;
      snackBarMsg = (snackBarMsg == "" ? "Please specify the corporate is listed on any stock exchange or not." : snackBarMsg);
      flag = false;
    } else {
      if (listedOnStockExchange === "true") {
        if (!verifyLength(stockExchangeName, 2)) {
          stockExchangeNameErr = true;
          snackBarMsg = (snackBarMsg == "" ? "Please enter the stock exchange name" : snackBarMsg);
          flag = false;
        }
      }
    }
    if (commercialRegistrationID) {
      const hasSpacesBetweenChars = commercialRegistrationID.split('').some((char, i, arr) => {
        return char !== ' ' && arr[i + 1] === ' ';
      });
      if (hasSpacesBetweenChars) {
        commercialRegistrationIDErr = true;
        snackBarMsg = (snackBarMsg == "" ? "Spaces are not allowed in CR" : snackBarMsg);
        flag = false
      }
    }
    if (regulatoryAuthority == "" && licenseNumber) {
      regulatoryAuthorityErr = true;
      snackBarMsg = (snackBarMsg == "" ? "Please enter the regulatory authority name" : snackBarMsg);
      flag = false;
    }
    if (enforcementActions === "true") {
      if (!verifyLength(actionDetails, 2)) {
        actionDetailsErr = true;
        snackBarMsg = (snackBarMsg == "" ? "Please enter the enforcement violation details. " : snackBarMsg);
        flag = false;
      }
    }
    setValues({
      ...values,
      comNameErr: comNameErr,
      entityErr: entityErr,

      regFlatNoErr: regFlatNoErr,
      regBuildingNoErr: regBuildingNoErr,
      regRoadErr: regRoadErr,
      regBlockErr: regBlockErr,
      regAreaErr: regAreaErr,
      regCountryErr: regCountryErr,

      compIDErr: compIDErr,
      commercialRegistrationIDErr: commercialRegistrationIDErr,
      expiryDateErr: expiryDateErr,
      dateOfIncorporationErr: dateOfIncorporationErr,
      countryOfIncorporationErr: countryOfIncorporationErr,
      listedOnStockExchangeErr: listedOnStockExchangeErr,
      stockExchangeNameErr: stockExchangeNameErr,

      actionDetailsErr: actionDetailsErr,
    });
    if (values.comNameErr == true || values.entityErr == true || values.regFlatNoErr == true || values.regBuildingNoErr == true || values.regRoadErr == true || values.regBlockErr == true || values.regAreaErr == true || values.regCountryErr == true || values.commercialRegistrationIDErr == true || values.expiryDateErr == true || values.dateOfIncorporationErr == true || values.countryOfIncorporationErr == true || values.listedOnStockExchangeErr == true || values.stockExchangeNameErr == true || values.actionDetailsErr == true || values.compIDErr == true || comNameErr == true || entityErr == true || regFlatNoErr == true || regBuildingNoErr == true || regRoadErr == true || regBlockErr == true || regAreaErr == true || regCountryErr == true || commercialRegistrationIDErr == true || expiryDateErr == true || dateOfIncorporationErr == true || countryOfIncorporationErr == true || listedOnStockExchangeErr == true || stockExchangeNameErr == true || actionDetailsErr == true || compIDErr == true) {
      flag = false
    }
    if (!flag) {
      openSnackBar(snackBarMsg)
    }
    return flag;
  };

  const submitData = () => {
    setSubmitting(true);
    if (validateForm()) {
      if ((props.back) || (props.cacheEdit)) {
        let data = {
          status: props.cacheEdit ? "update" : "step",
          user: user._id,
          companyName: companyName,
          entityType: entityType,
          reg_bus_addr: {
            flatNo: regFlatNo,
            buildingNo: regBuildingNo,
            road: regRoad,
            block: regBlock,
            area: regArea,
            country: regCountry,
          },
          reg_trading_addr: {
            flatNo: trFlatNo,
            buildingNo: trBuildingNo,
            road: trRoad,
            block: trBlock,
            area: trArea,
            country: trCountry,
          },
          contact_details: contactData,
          incorp_details: {
            id_type: typeOfID,
            comm_reg_id: commercialRegistrationID,
            exp_date: expiryDate,
            date_of_incorp: dateOfIncorporation,
            country_of_incorp: countryOfIncorporation,
            listed_in_public: listedOnStockExchange,
            organisation: stockExchangeName,
          },
          regulatory_status: {
            name: regulatoryAuthority,
            licenseNumber: licenseNumber,
            violationsOrCriminalOffense: enforcementActions,
            violationDetails: actionDetails,
          },
        };
        axios.put(`${config.url().baseURL}/update-step-one-details/${props.id}`, data, {
          headers: {
            auth: sessionStorage.getItem("cx_id"),
          },
        }).then((resp) => {
          let respData = resp.data;
          if (!respData.err) {
            props.nextStep(true, props.id);
          } else {
            openSnackBar(respData.msg);
            setSubmitting(false);
          }
        }).catch((err) => {
          setSubmitting(false);
          openSnackBar("Unable to process.");
        });
      } else {
        if (props.edit) {
          let data = editData
          let contactData = editData.ContactDetails
          let updatedContact = [...contactData, ...deletedContact]
          data.ContactDetails = updatedContact
          if (data.edit = props.approvedCorp !== undefined && props.approvedCorp) {
            data.edit = true
          } else {
            data.edit = false
          }
          data.user = user._id
          axios.post(`${config.url().baseURL}/kyc-update-customer-one/${corpData.companyID}`, data, {
            headers: {
              auth: sessionStorage.getItem("cx_id"),
            },
          }).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
              dispatch(setCompanyID(corpData.companyID));
              props.nextStep();
            } else {
              openSnackBar(respData.msg);
              setSubmitting(false);
            }
          }).catch((err) => {
            setSubmitting(false);
            openSnackBar("Unable to process.");
          });
        } else {
          let data = {
            user: user._id,
            companyName: companyName,
            entityType: entityType,
            reg_bus_addr: {
              flatNo: regFlatNo,
              buildingNo: regBuildingNo,
              road: regRoad,
              block: regBlock,
              area: regArea,
              country: regCountry,
            },
            reg_trading_addr: {
              flatNo: trFlatNo,
              buildingNo: trBuildingNo,
              road: trRoad,
              block: trBlock,
              area: trArea,
              country: trCountry,
            },
            contact_details: contactData,
            incorp_details: {
              id_type: typeOfID,
              comm_reg_id: commercialRegistrationID,
              exp_date: expiryDate,
              date_of_incorp: dateOfIncorporation,
              country_of_incorp: countryOfIncorporation,
              listed_in_public: listedOnStockExchange,
              organisation: stockExchangeName,
            },
            regulatory_status: {
              name: regulatoryAuthority,
              licenseNumber: licenseNumber,
              violationsOrCriminalOffense: enforcementActions,
              violationDetails: actionDetails,
            },
          };
          axios.post(`${config.url().baseURL}/create-company`, data, {
            headers: {
              auth: sessionStorage.getItem("cx_id"),
            },
          })
            .then((resp) => {
              let respData = resp.data;
              if (!respData.err) {
                dispatch(setCompanyID(respData.data.id));
                props.nextStep();
              } else {
                openSnackBar(respData.msg);
                setSubmitting(false);
              }
            })
            .catch((err) => {
              setSubmitting(false);
              openSnackBar("Unable to process.");
            });
        }
      }
    } else {

      setSubmitting(false);
    }
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const contactIDDrop = async (images) => {
    if (editFlag.status) {
      let tempEditData = editData
      if (/\.(jpe?g|png|pdf)$/i.test(images[0].name) === true) {
        if (images[0].size < 2097152) {
          getBase64(images[0], (result) => {
            setContactID(result);
            delete tempEditData.ContactDetails[editFlag.id].DocumentID
            tempEditData.ContactDetails[editFlag.id].CPRCopy = result;
          });
          setContactIDBtnColor("secondary");
          setContactIDName(images[0].name);
        } else {
          openSnackBar("File size is too large, Maximum file size is 2 Mb.");
        }
      } else {
        openSnackBar("Please upload jpg,jpeg,png and pdf files");
      }
      setEditData(tempEditData)
    } else {
      if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
        if (images[0].size < 2097152) {
          getBase64(images[0], (result) => {
            setContactID(result);
          });
          setContactIDBtnColor("secondary");
          setContactIDName(images[0].name);
        } else {
          openSnackBar("File size is too large, Maximum file size is 2 Mb.");
        }
      } else {
        openSnackBar("Please upload jpg,jpeg,png and pdf files");
      }
    }
  };

  const validateContact = () => {
    //function to validate contact
    let flag = true, snackBarMsg = "";
    let titleErr = false,
      fullNameErr = false,
      mobileErr = false,
      emailErr = false,
      cprErr = false;
    if (!verifyLength(title, 2)) {
      snackBarMsg = (snackBarMsg == "" ? "Please choose title." : snackBarMsg);
      titleErr = true;
      flag = false;
    }
    if (!verifyLength(fullName, 3)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter the full name." : snackBarMsg);
      fullNameErr = true;
      flag = false;
    }
    if (!verifyLength(mobile, 8)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter the mobile number." : snackBarMsg);
      mobileErr = true;
      flag = false;
    }
    if (!verifyEmail(email)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter the email." : snackBarMsg);
      emailErr = true;
      flag = false;
    }
    if (!verifyLength(contactCPR, 9)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter the cpr number." : snackBarMsg);
      cprErr = true;
      flag = false;
    }
    // if (!props.edit) {
    if (!verifyLength(contactIDName, 1)) {
      snackBarMsg = (snackBarMsg == "" ? "Please upload the id proof." : snackBarMsg);
      setContactIDBtnColor("danger")
      flag = false;
      // }
    }
    if (!flag) {
      openSnackBar(snackBarMsg)
    } setValues({
      ...values,
      titleErr: titleErr,
      fullNameErr: fullNameErr,
      mobileErr: mobileErr,
      emailErr: emailErr,
      contactCPRERR: cprErr
    });
    return flag;
  };

  const addContact = () => {
    //function to add contact
    //debugger;
    if (validateContact()) {
      if (props.edit) {
        if (contactData.length !== 0) {
          let tempJson = contactData;
          let tempTableJSON = contactTableJSONData;
          tempJson.push({
            title: title,
            fullName: fullName,
            mobile: mobile,
            email: email,
            id_proof: contactID,
            cpr: contactCPR
          });
          tempTableJSON.push({
            title: title,
            fullName: fullName,
            mobile: mobile,
            email: email,
            id_proof: contactIDName,
            cpr: contactCPR
          });
          if (props.edit) {
            let tempEditData = editData
            tempEditData.ContactDetails.push({
              "Title": title,
              "FullName": fullName,
              "Mobile": mobile,
              "Email": email,
              "CPRNumber": contactCPR,
              "CPRCopy": contactID
            })
            setEditData(tempEditData)
          }
          temContactData = tempJson
          setContactData(tempJson);
          setContactTableJSONData(tempTableJSON)
          contactsToArray(tempTableJSON);
          setShowAddContact(false);
          setTitle("");
          setFullName("");
          setMobile("");
          setEmail("");
          setContactID("");
          setContactIDName("");
          setContactCPR("")
        } else if (temContactData.length !== 0) {
          let tempJson = temContactData;
          let tempTableJSON = contactTableJSONData;
          tempJson.push({
            "title": title,
            "title": title,
            "mobile": mobile,
            "email": email,
            "cpr": contactCPR,
            "idProof": contactID
          });
          tempTableJSON.push({
            title: title,
            fullName: fullName,
            mobile: mobile,
            email: email,
            id_proof: contactIDName,
            cpr: contactCPR
          });
          if (props.edit) {
            let tempEditData = editData
            tempEditData.ContactDetails.push({
              "Title": title,
              "FullName": fullName,
              "Mobile": mobile,
              "Email": email,
              "CPRNumber": contactCPR,
              "idProof": contactID
            })
            setEditData(tempEditData)
          }
          temContactData = tempJson
          setContactData(tempJson);
          setContactTableJSONData(tempTableJSON)
          contactsToArray(tempTableJSON);
          setShowAddContact(false);
          setTitle("");
          setFullName("");
          setMobile("");
          setEmail("");
          setContactID("");
          setContactIDName("");
          setContactCPR("")
        } else {
          let tempJson = [];
          let tempTableJSON = [];
          tempJson.push({
            "title": title,
            "fullName": fullName,
            "mobile": mobile,
            "email": email,
            "cpr": contactCPR,
            "id_proof": contactID
          });
          tempTableJSON.push({
            title: title,
            fullName: fullName,
            mobile: mobile,
            email: email,
            id_proof: contactIDName,
            cpr: contactCPR
          });
          if (props.edit) {
            let tempEditData = editData
            tempEditData.ContactDetails.push({
              "Title": title,
              "FullName": fullName,
              "Mobile": mobile,
              "Email": email,
              "CPRNumber": contactCPR,
              "CPRCopy": contactID
            })
            setEditData(tempEditData)
          }
          temContactData = tempJson
          setContactData(tempJson);
          setContactTableJSONData(tempTableJSON)
          contactsToArray(tempTableJSON);
          setShowAddContact(false);
          setTitle("");
          setFullName("");
          setMobile("");
          setEmail("");
          setContactID("");
          setContactIDName("");
          setContactCPR("")
        }

      } else {
        let tempJson = contactData;
        let tempTableJSON = contactTableJSONData;
        tempJson.push({
          title: title,
          fullName: fullName,
          mobile: mobile,
          email: email,
          id_proof: contactID,
          cpr: contactCPR
        });
        tempTableJSON.push({
          title: title,
          fullName: fullName,
          mobile: mobile,
          email: email,
          id_proof: contactIDName,
          cpr: contactCPR
        });
        temContactData = tempJson
        setContactData(tempJson);
        setContactTableJSONData(tempTableJSON)
        contactsToArray(tempTableJSON);
        setShowAddContact(false);
        setTitle("");
        setFullName("");
        setMobile("");
        setEmail("");
        setContactID("");
        setContactIDName("");
        setContactCPR("")
      }

    }
  };


  const contactsToArray = (data) => {
    //function to convert owner json to array
    let temp = [];
    data.map((contact, key) => {
      temp.push([
        key + 1,
        contact.title,
        contact.fullName,
        contact.mobile,
        contact.email,
        contact.cpr,
        // libs.validate(contact.id_proof, "base64", contact.id_proof.length) ? `${contact.fullName}_ID_Proof` : contact.id_proof,
        <>
          {/* {props.edit !== true ? ( */}
          <Tooltip placement="right" title="Delete">
            <IconButton
              onClick={() => deleteContact(key)}
              style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
              aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          {/* ) : null} */}
          <Tooltip placement="bottom" title="View Document">
            <IconButton
              onClick={() => idViewer(key)}
              style={{ marginTop: "-10px", marginBottom: "-10px", color: "#014FA4" }}
              aria-label="delete">
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          {
            props.edit ?
              <Tooltip placement="bottom" title="Edit">
                <IconButton
                  onClick={() => editContact(key)}
                  style={{ marginTop: "-10px", marginBottom: "-10px", color: "#014FA4" }}
                  aria-label="delete">
                  <EditIcon />
                </IconButton>
              </Tooltip>
              : null
          }

        </>
      ]);
      return 0;
    });
    setContactTableData(temp);

  };

  const deleteContact = (id) => {
    //function to delete a owner from array
    //debugger
    if (contactData.length == 0) {
      // alert("data 1")
      let tempContacts = temContactData;
      let tempJSONContacts = temContactData;
      let arrayOne = [...tempContacts.slice(0, id), ...tempContacts.slice(id + 1)];
      if (arrayOne.length === 0) {
        setShowAddContact(true);
      }
      let tempTableJSON = []
      Promise.all(arrayOne.map((contact, key) => {
        if ((props.back) || (props.cacheEdit)) {
          tempTableJSON.push({
            title: contact.title,
            fullName: contact.fullName,
            mobile: contact.mobile,
            email: contact.email,
            id_proof: `${contact.fullName}_IDProof`,
            cpr: contact.cpr
          })
        } else {
          tempTableJSON.push({
            title: contact.title,
            fullName: contact.fullName,
            mobile: contact.mobile,
            email: contact.email,
            id_proof: contact.id_proof ? contact.id_proof : contact.DocumentID,
            cpr: contact.cpr
          })
        }
      }))
      temContactData = arrayOne
      temContactTableData = tempTableJSON
      setContactData(arrayOne);
      setContactTableJSONData(tempTableJSON)
      contactsToArray(tempTableJSON);

    } else {
      //alert("data 2")
      let tempContacts = temContactData;
      let tempJSONContacts = contactTableJSONData;
      if (tempContacts.length < tempJSONContacts.length) {
        tempJSONContacts = tempContacts
      }

      let arrayOne = [...tempContacts.slice(0, id), ...tempContacts.slice(id + 1)];
      let arrayTwo = [...tempJSONContacts.slice(0, id), ...tempJSONContacts.slice(id + 1)];

      if (arrayOne.length === 0) {
        setShowAddContact(true);
      }

      temContactData = arrayOne
      setContactData(arrayOne);
      setContactTableJSONData(arrayTwo)
      contactsToArray(arrayTwo);

    }
    if (props.edit) {

      let tempEditData = corpData
      let contactData = tempEditData.ContactDetails
      let arrayOne = [...contactData.slice(0, id), ...contactData.slice(id + 1)];
      if (contactData[id].DocumentID) {
        let deleted = contactData[id]
        let tempDeleted = deletedContact
        // alert(JSON.stringify(deleted))
        deleted.Delete = true
        tempDeleted.push(deleted)
        // alert(JSON.stringify(tempDeleted))
        setDeletedContact(tempDeleted)
      }
      tempEditData.ContactDetails = arrayOne
      setEditData(tempEditData)
    }
  };

  const cancelAddContact = () => {
    //function to cancel adding owner
    if (contactEdit) {
      setContactEdit(false);
      setTitle("");
      setFullName("");
      setMobile("");
      setEmail("");
      setContactID("");
      setContactIDName("");
      setContactCPR("")
    } else {
      setShowAddContact(false);
      setTitle("");
      setFullName("");
      setMobile("");
      setEmail("");
      setContactID("");
      setContactIDName("");
      setContactCPR("")
    }
    setValues({
      ...values,
      titleErr: false,
      fullNameErr: false,
      mobileErr: false,
      emailErr: false,
      contactCPRERR: false
    });
    setContactIDBtnColor("secondary")
  };

  const idViewer = (id) => {
    //function to set id proof viewer
    setPageNumber(1)
    if ((props.back) || (props.cacheEdit)) {
      if (contactData.length == 0) {
        let file = temContactData[id].id_proof
        let extension = file.substring(file.indexOf("/") + 1, file.indexOf(";base64"));
        if (extension == "pdf") {
          setDocumentViewerData(file)
          setOpenPDFViewer(true)
        } else {
          setDocumentViewerData(file)
          setOpenImageViewer(true)
        }
      } else {
        let file = contactData[id].id_proof
        let extension = file.substring(file.indexOf("/") + 1, file.indexOf(";base64"));
        if (extension == "pdf") {
          setDocumentViewerData(file)
          setOpenPDFViewer(true)
        } else {
          setDocumentViewerData(file)
          setOpenImageViewer(true)
        }
      }
    } else {
      setTempKey(tempKey + 1)
      if (contactData.length == 0) {
        if (temContactData[id].DocumentID !== undefined) {
          fetchDoc(temContactData[id].DocumentID)
        } else {
          let file = temContactData[id].id_proof
          let extension = file.substring(file.indexOf("/") + 1, file.indexOf(";base64"));
          if (extension == "pdf") {
            setDocumentViewerData(file)
            setOpenPDFViewer(true)
          } else {
            setDocumentViewerData(file)
            setOpenImageViewer(true)
          }
        }

      } else {
        if (props.edit) {
          if (corpData.ContactDetails[id].CPRCopy) {
            let file = corpData.ContactDetails[id].CPRCopy
            let extension = file.substring(file.indexOf("/") + 1, file.indexOf(";base64"));
            if (extension == "pdf") {
              setDocumentViewerData(file)
              setOpenPDFViewer(true)
            } else {
              setDocumentViewerData(file)
              setOpenImageViewer(true)
            }
          } else if (contactData[id].id_proof) {
            let file = contactData[id].id_proof
            let extension = file.substring(file.indexOf("/") + 1, file.indexOf(";base64"));
            if (extension == "pdf") {
              setDocumentViewerData(file)
              setOpenPDFViewer(true)
            } else {
              setDocumentViewerData(file)
              setOpenImageViewer(true)
            }
          }
          // else if (contactData[id].CPRCopy) {
          //   let file = contactData[id].CPRCopy
          //   let extension = file.substring(file.indexOf("/") + 1, file.indexOf(";base64"));
          //   if (extension == "pdf") {
          //     setDocumentViewerData(file)
          //     setOpenPDFViewer(true)
          //   } else {
          //     setDocumentViewerData(file)
          //     setOpenImageViewer(true)
          //   }
          // } 
          else {

            fetchDoc(contactTableJSONData[id].id_proof)
          }
        } else {
          let file = contactData[id].id_proof
          let extension = file.substring(file.indexOf("/") + 1, file.indexOf(";base64"));
          if (extension == "pdf") {
            setDocumentViewerData(file)
            setOpenPDFViewer(true)
          } else {
            setDocumentViewerData(file)
            setOpenImageViewer(true)
          }
        }
      }
    }
  }


  const fetchDoc = (id) => {
    //function to fetch docs
    if (id !== undefined && id !== null) {
      setDocumentViewerData(null)
      axios.post(`${config.url().baseURL}/get-company-docs-id/${id}`).then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          if (respData.data.Content) {
            let extension = respData.data.Content.substring(respData.data.Content.indexOf("/") + 1, respData.data.Content.indexOf(";base64"));
            setDocumentViewerData(respData.data.Content, respData.data.DocumentType)
            if (extension == "pdf") {
              setOpenPDFViewer(true)
              setOpenImageViewer(false)
            } else {
              setOpenImageViewer(true)
              setOpenPDFViewer(false)
            }
          } else {
            openSnackBar("Can not get document from netSys.");
          }
        } else {
          setDocumentViewerData(null)
          openSnackBar(respData.msg);
        }
      });
    } else {
      openSnackBar("Document ID Not found.");
    }
  };

  const handleEditData = (keyOne, keyTwo, keyThree, value) => {
    //function to handle edit data
    let data = editData
    if (props.edit) {
      if (keyThree !== null) {
        data[`${keyOne}`][`${keyTwo}`][`${keyThree}`] = value
      } else if (keyTwo !== null) {
        data[`${keyOne}`][`${keyTwo}`] = value
      } else {
        data[`${keyOne}`] = value
      }
      setEditData(data)
    }
  }

  const deleteContactID = () => {
    setContactIDName("")
    setContactID("")
  }

  const editContact = (id) => {
    setContactEdit(true)
    setEditFlag({ status: true, id: id })
    setEditContactID(id)
    if (props.edit) {
      if (contactData.length == 0) {
        //alert("one")
        let tempEdit = [...temContactData]
        setContactEditTemp(tempEdit)
        setFullName(tempEdit[id].fullName)
        setTitle(tempEdit[id].title)
        setMobile(tempEdit[id].mobile)
        setEmail(tempEdit[id].email)
        setContactCPR(tempEdit[id].cpr)
        setContactIDName(tempEdit[id].fullName + "id_Proof")
      } else {
        // alert("two")
        // alert(JSON.stringify(contactData))
        setContactEditTemp(contactData)
        let contactDataTemp = [...contactData]
        setFullName(contactDataTemp[id].fullName)
        setTitle(contactDataTemp[id].title)
        setMobile(contactDataTemp[id].mobile)
        setEmail(contactDataTemp[id].email)
        setContactCPR(contactDataTemp[id].cpr)
        setContactIDName(contactDataTemp[id].fullName + "_idProof")
      }
    } else {
      if (contactData.length == 0) {
        let tempEditContacts = [...temContactData]
        setFullName(tempEditContacts[id].fullName)
        setTitle(tempEditContacts[id].title)
        setMobile(tempEditContacts[id].mobile)
        setEmail(tempEditContacts[id].email)
        setContactCPR(tempEditContacts[id].cpr)
        setContactData(tempEditContacts)
        // setShowAddContact(true)
        //temContactData.splice(id, 1);
        // alert(JSON.stringify(newContactData))
        setContactData(tempEditContacts)
        setContactTableJSONData(tempEditContacts)
        contactsToArray(tempEditContacts)
      } else {
        let contactDataTemp = [...contactData]
        setFullName(contactDataTemp[id].fullName)
        setTitle(contactDataTemp[id].title)
        setMobile(contactDataTemp[id].mobile)
        setEmail(contactDataTemp[id].email)
        setContactCPR(contactDataTemp[id].cpr)
        // setShowAddContact(true)
        deleteContact(id)
      }
    }
  }

  const checkCorporate = (id) => {
    libs.getCorporate(id, (resp) => {
      if (!resp.err) {
        setValues({ ...values, commercialRegistrationIDErr: true })
        openSnackBar(`Corporate already exist as ${resp.data.businessNameLegal} `)
      }
    })
  }

  const setBackData = (id) => {
    //function to set back button data
    setSubmittingLabel("Fetching Data..")
    setSubmitting(true)
    axios.post(`${config.url().baseURL}/get-step-one-details`, { _id: id }).then((resp) => {
      let respData = resp.data;
      if (!respData.err) {
        setSubmitting(false)
        setTimeout(function () {
          setSubmittingLabel("Saving Data..")
        }, 1000);
        let data = respData.data
        dispatch(setCompanyID(data._id));
        seCompanyName(data.companyName)
        setEntityType(data.entityType)
        setRegFlatNo(data.reg_bus_addr.flatNo)
        setRegBuildingNo(data.reg_bus_addr.buildingNo)
        setRegRoad(data.reg_bus_addr.road)
        setRegBlock(data.reg_bus_addr.block)
        setRegArea(data.reg_bus_addr.area)
        setRegCountry(data.reg_bus_addr.country)
        setTrFlatNo(data.reg_trading_addr.flatNo)
        setTrBuildingNo(data.reg_trading_addr.buildingNo)
        setTrRoad(data.reg_trading_addr.road)
        setTrBlock(data.reg_trading_addr.block)
        setTrArea(data.reg_trading_addr.area)
        setTrCountry(data.reg_trading_addr.country)
        setValues({
          ...values,
          regFlatNoErr: false,
          regBuildingNoErr: false,
          regRoadErr: false,
          regBlockErr: false,
          regCountryErr: false,
          regAreaErr: false
        });

        setTypeOfID(data.incorp_details.id_type)
        if (data.incorp_details.id_type == "UnitCode") {
          setIDTypeLabel("Unit Code")
        }
        setCommercialRegistrationID(data.incorp_details.comm_reg_id)
        setExpiryDate(data.incorp_details.exp_date)
        setDateOfIncorporation(data.incorp_details.date_of_incorp)
        setCountryOfIncorporation(data.incorp_details.country_of_incorp)
        if (data.incorp_details.listed_in_public) {
          setListedOnStockExchange("true")
          setStockExchangeName(data.incorp_details.organisation)
        } else {
          setListedOnStockExchange("false")
        }

        if (data.regulatory_status.name) {
          setRegulatoryAuthority(data.regulatory_status.name)
          setLicenseNumber(data.regulatory_status.licenseNumber)
          if (data.regulatory_status.violationsOrCriminalOffense == "true") {
            setEnforcementActions("true")
            setActionDetails(data.regulatory_status.violationDetails)
          } else {
            setEnforcementActions("false")
          }
        }

        let tempJson = [], tempTableJSON = []
        Promise.all(data.contact_details.map((contact, key) => {
          tempJson.push({
            title: contact.title,
            fullName: contact.fullName,
            mobile: contact.mobile,
            email: contact.email,
            id_proof: contact.id_proof,
            cpr: contact.cpr
          })
          tempTableJSON.push({
            title: contact.title,
            fullName: contact.fullName,
            mobile: contact.mobile,
            email: contact.email,
            id_proof: `${contact.fullName}_ID_Proof`,
            cpr: contact.cpr
          })
        })
        )
        temContactData = tempJson
        setContactData(tempJson)
        setContactTableJSONData(tempTableJSON)
        contactsToArray(tempTableJSON);
        cancelAddContact()
      } else {
        setSubmitting(false)
        setSubmittingLabel("Saving Data..")
        openSnackBar(`Unable to process.`)
      }
    });
  }

  const goBack = () => {
    //function to go back
    seCompanyName("")
    setEntityType("")
    setRegFlatNo("")
    setRegBuildingNo("")
    setRegRoad("")
    setRegBlock("")
    setRegArea("")
    setRegCountry({ "ISO": "BH", "country": "Bahrain" })
    setTrFlatNo("")
    setTrBuildingNo("")
    setTrRoad("")
    setTrBlock("")
    setTrArea("")
    setTrCountry({ "ISO": "BH", "country": "Bahrain" })
    setTypeOfID("")
    setCommercialRegistrationID("")
    setExpiryDate("")
    setDateOfIncorporation("")
    setCountryOfIncorporation({ "ISO": "BH", "country": "Bahrain" })
    setContactData([]);
    setContactTableJSONData([])
    contactsToArray([]);
    setTitle("");
    setFullName("");
    setMobile("");
    setEmail("");
    setContactID("");
    setContactIDName("");
    setContactCPR("")
    props.previousStep()
  }

  const updateContact = (id) => {
    //function to update contact details
    //alert(id)
    if (validateContact()) {
      let tempContact = [...contactData]
      let temEditTableContact = [...contactTableJSONData]
      tempContact[id].title = title
      tempContact[id].fullName = fullName
      tempContact[id].mobile = mobile
      tempContact[id].email = email
      tempContact[id].cpr = contactCPR

      temEditTableContact[id].title = title
      temEditTableContact[id].fullName = fullName
      temEditTableContact[id].mobile = mobile
      temEditTableContact[id].email = email
      temEditTableContact[id].cpr = contactCPR
      if (corpData.ContactDetails[id].DocumentID) temEditTableContact[id].id_proof = corpData.ContactDetails[id].DocumentID
      else temEditTableContact[id].id_proof = contactIDName



      if (props.edit) {
        let contactTemp = corpData.ContactDetails
        contactTemp[id].Title = title
        contactTemp[id].FullName = fullName
        contactTemp[id].Mobile = mobile
        contactTemp[id].Email = email
        if (contactTemp[id].DocumentID) {
          contactTemp[id].DocumentID = contactTemp[id].DocumentID
        }

        contactTemp[id].CPRNumber = contactCPR
        let newEditData = editData
        newEditData.ContactDetails = contactTemp
        setEditData(newEditData)
      }
      setContactData(tempContact)
      setContactTableData(temEditTableContact)
      contactsToArray(temEditTableContact)
      setContactEdit(false)
      cancelAddContact()
    }
    setEditFlag({ status: false, id: null })

  }
  const findExistingCPR = (cpr) => {
    //function to find cpr is already added
    if (contactTableJSONData.find(owner => owner.cpr == cpr) !== undefined) {
      openSnackBar("Contact with same id value exist")
      setContactCPR("")
      setValues({ ...values, contactCPRERR: true });
    }
  }

  const classes = useStyles();
  return (
    <div>
      {/* <button onClick={testFunc}>Test</button> */}
      <Collapse
        in={props.showPage}
        style={{ transformOrigin: "0 0 0", width: "100%" }}
        {...(true ? { timeout: 500 } : {})}>
        <Card>
          <CardBody>
            <GridContainer justify="center">
              <GridItem xs={12} sm={7}>
                <CustomInput
                  error={values.comNameErr}
                  labelText={
                    <span>
                      Company Name <small className={classes.required}>*</small>
                    </span>
                  }
                  id="company-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    value: companyName,
                    disabled: props.edit,
                    onChange: (e) => {
                      seCompanyName(libs.validate(e.target.value, "all", 150) ? e.target.value : companyName);
                      verify(libs.validate(e.target.value, "all", 150) ? e.target.value : companyName, "length", "comNameErr", 3);
                    },
                    onBlur: (e) => {
                      if (!isNaN(e.target.value.trim())) {
                        seCompanyName("");
                        openSnackBar("Invalid company name.")
                      } else {
                        seCompanyName(e.target.value.trim());
                        handleEditData("NameOfCompany", null, null, e.target.value)
                      }
                    }
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={5}>
                <FormControl className={classes.selectFormControl} error={values.entityErr} fullWidth>
                  <InputLabel className={classes.selectLabel}>
                    Entity Type <small className={classes.required}>*</small>
                  </InputLabel>
                  <Select
                    id="entity-select"
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={entityType}
                    disabled={props.edit}
                    onChange={(e) => {
                      setEntityType(e.target.value);
                      verify(e.target.value, "length", "entityErr", 3);
                    }}
                    onBlur={(e) => {
                      handleEditData("CompanyInformation", "LegalForm", "TypeOfEntity", e.target.value)
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      disabled={props.edit}
                      value="Limited Liability">
                      Limited Liability
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      disabled={props.edit}
                      value="Partnership">
                      Partnership
                    </MenuItem>
                    <MenuItem
                      classes={{

                        root: classes.selectMenuItem,
                      }}
                      disabled={props.edit}
                      value="Sole Proprietorship">
                      Sole Proprietorship
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12}>
                <h6 className={classes.subHead}>Registered Business Address</h6>
              </GridItem>
              <GridItem xs={12} sm={12}>
                <Divider style={{ background: "black" }} fullWidth />
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  error={values.regFlatNoErr}
                  labelText={
                    <span>
                      Flat No <small className={classes.required}>*</small>
                    </span>
                  }
                  id="reg-flat-no"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    readOnly: copyRegisterAdd,
                    value: regFlatNo,
                    onChange: (e) => {
                      setRegFlatNo(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : regFlatNo);
                      verify(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : regFlatNo, "length", "regFlatNoErr", 2);
                    },
                    onBlur: (e) => {
                      setRegFlatNo(e.target.value.trim());
                      handleEditData("RegisteredBusinessAddress", "FlatNumber", null, e.target.value)
                    }
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  error={values.regBuildingNoErr}
                  labelText={
                    <span>
                      Building/Villa No <small className={classes.required}>*</small>
                    </span>
                  }
                  id="reg-building-no"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    readOnly: copyRegisterAdd,
                    value: regBuildingNo,
                    onChange: (e) => {
                      setRegBuildingNo(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : regBuildingNo);
                      verify(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : regBuildingNo, "length", "regBuildingNoErr", 2);
                    },
                    onBlur: (e) => {
                      setRegBuildingNo(e.target.value.trim());
                      handleEditData("RegisteredBusinessAddress", "BuildingNumber", null, e.target.value)
                    }
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  error={values.regRoadErr}
                  labelText={
                    <span>
                      Road <small className={classes.required}>*</small>
                    </span>
                  }
                  id="reg-road"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    readOnly: copyRegisterAdd,
                    value: regRoad,
                    onChange: (e) => {
                      setRegRoad(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : regRoad);
                      verify(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : regRoad, "length", "regRoadErr", 2);
                    },
                    onBlur: (e) => {
                      setRegRoad(e.target.value.trim());
                      handleEditData("RegisteredBusinessAddress", "RoadNumber", null, e.target.value)
                    }
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  error={values.regBlockErr}
                  labelText={
                    <span>
                      Block <small className={classes.required}>*</small>
                    </span>
                  }
                  id="reg-block"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    readOnly: copyRegisterAdd,
                    value: regBlock,
                    onChange: (e) => {
                      setRegBlock(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : regBlock);
                      verify(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : regBlock, "length", "regBlockErr", 2);
                    },
                    onBlur: (e) => {
                      setRegBlock(e.target.value.trim());
                      handleEditData("RegisteredBusinessAddress", "BlockNumber", null, e.target.value)
                    }
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  error={values.regAreaErr}
                  labelText={
                    <span>
                      Area <small className={classes.required}>*</small>
                    </span>
                  }
                  id="reg-area"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    readOnly: copyRegisterAdd,
                    value: regArea,
                    onChange: (e) => {
                      setRegArea(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : regArea);
                      verify(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : regArea, "length", "regAreaErr", 2);
                    },
                    onBlur: (e) => {
                      setRegArea(e.target.value.trim());
                      handleEditData("RegisteredBusinessAddress", "Area", null, e.target.value)
                    }
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <Autocomplete
                  className={classes.autocomplete}
                  options={countries}
                  value={regCountry}
                  getOptionLabel={(option) => option.country}
                  fullWidth
                  disabled={copyRegisterAdd}
                  onChange={(value, item) => {
                    if (item !== null) {
                      setRegCountry(item);
                      if (props.edit) {
                        handleEditData("RegisteredBusinessAddress", "Country", null, item.ISO)
                      }
                    }
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} readOnly={copyRegisterAdd} error={values.regCountryErr} label="Country *" readOnly={copyRegisterAdd} />}
                />
              </GridItem>
              <GridItem xs={12} sm={12}>
                <h6 className={classes.subHead}>Trading Address</h6>
                <FormGroup className={classes.switch} row>
                  <FormControlLabel
                    control={
                      <Switch checked={copyRegisterAdd} onChange={handleSameAddress} name="checkedB" color="primary" />
                    }
                    labelPlacement="end"
                    label="Same As Registered Address"
                  />
                </FormGroup>
              </GridItem>
              <GridItem xs={12} sm={12}>
                <Divider style={{ background: "black" }} fullWidth />
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  error={values.trFlatNoErr}
                  labelText={<span>Flat No</span>}
                  id="tr-flat-no"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    readOnly: copyRegisterAdd,
                    value: trFlatNo,
                    onChange: (e) => {
                      setTrFlatNo(libs.validate(e.target.value, "alphanumeric", 50) ? e.target.value : trFlatNo);
                    },
                    onBlur: (e) => {
                      setTrFlatNo(e.target.value.trim());
                      handleEditData("TradingAddress", "FlatNumber", null, e.target.value)
                    }
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  error={values.trBuildingNoErr}
                  labelText={<span>Building/Villa No</span>}
                  id="tr-building-no"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    readOnly: copyRegisterAdd,
                    value: trBuildingNo,
                    onChange: (e) => {
                      setTrBuildingNo(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : trBuildingNo);
                    },
                    onBlur: (e) => {
                      setTrBuildingNo(e.target.value.trim());
                      handleEditData("TradingAddress", "BuildingNumber", null, e.target.value)
                    }
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  error={values.trRoadErr}
                  labelText={<span>Road</span>}
                  id="tr-road"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    readOnly: copyRegisterAdd,
                    value: trRoad,
                    onChange: (e) => {
                      setTrRoad(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : trRoad);
                    },
                    onBlur: (e) => {
                      setTrRoad(e.target.value.trim());
                      handleEditData("TradingAddress", "RoadNumber", null, e.target.value)
                    }
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  error={values.trBlockErr}
                  labelText={<span>Block</span>}
                  id="tr-block"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    readOnly: copyRegisterAdd,
                    value: trBlock,
                    onChange: (e) => {
                      setTrBlock(libs.validate(e.target.value, "alphanumeric", 10) ? e.target.value : trBlock);
                    },
                    onBlur: (e) => {
                      setTrBlock(e.target.value.trim());
                      handleEditData("TradingAddress", "BlockNumber", null, e.target.value)
                    }
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  error={values.trAreaErr}
                  labelText={<span>Area</span>}
                  id="tr-area"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    readOnly: copyRegisterAdd,
                    value: trArea,
                    onChange: (e) => {
                      setTrArea(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : trArea);
                    },
                    onBlur: (e) => {
                      setTrArea(e.target.value.trim());
                      handleEditData("TradingAddress", "Area", null, e.target.value)
                    }
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <Autocomplete
                  className={classes.autocomplete}
                  options={countries}
                  value={trCountry}
                  getOptionLabel={(option) => option.country}
                  fullWidth
                  disabled={copyRegisterAdd}
                  onChange={(value, item) => {
                    if (item !== null) {
                      setTrCountry(item);
                      if (props.edit) {
                        handleEditData("TradingAddress", "Country", null, item.ISO)
                      }
                    }
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} error={values.trCountryErr} label="Country" />}
                />
              </GridItem>
              <GridItem xs={12} sm={12}>
                <h6 className={classes.subHead}>Contact Details </h6>
              </GridItem>
              <GridItem xs={12} sm={12}>
                <Divider style={{ background: "black" }} fullWidth />
              </GridItem>
              <GridItem xs={12} sm={12}>
                <Collapse
                  in={contactTableData.length !== 0}
                  style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                  {...(true ? { timeout: 500 } : {})}>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["#", "Title", "Full Name", "Mobile", "Email", "CPR", "ID Proof", ""]}
                    tableData={contactTableData}
                  />
                  {!showAddContact ? (
                    <>
                      <Button color="primary" size="lg" simple onClick={() => setShowAddContact(true)}>
                        Add Contact
                      </Button>
                    </>
                  ) : null}
                </Collapse>
              </GridItem>

              <Collapse
                in={showAddContact}
                style={{ transformOrigin: "0 0 0", textAlign: "center", width: "100%", padding: "0px 15px" }}
                {...(true ? { timeout: 500 } : {})}>
                <GridContainer>
                  <GridItem xs={12} sm={3}>
                    <FormControl style={{ textAlign: "left" }} className={classes.selectFormControl} error={values.titleErr} fullWidth>
                      <InputLabel className={classes.selectLabel}>
                        Title
                        {<small className={classes.required}>*</small>}
                      </InputLabel>
                      <Select
                        id="listed-on-stock-exchange"
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value.trim());
                          verify(e.target.value, "length", "titleErr", 2);
                        }}
                      >
                        {titles.map((title) => {
                          return <MenuItem classes={{ root: classes.selectMenuItem }} value={title}>{title}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={9}>
                    <CustomInput
                      error={values.fullNameErr}
                      labelText={
                        <span>
                          Full Name <small className={classes.required}>*</small>
                        </span>
                      }
                      id="full-name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        autoComplete: "off",
                        value: fullName,
                        onChange: (e) => {
                          setFullName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : fullName);
                          verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : fullName, "length", "fullNameErr", 3);
                        },
                        onBlur: (e) => {
                          setFullName(e.target.value.trim());
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <CustomInput
                      error={values.mobileErr}
                      labelText={
                        <span>
                          Mobile No<small className={classes.required}>*</small>
                        </span>
                      }
                      id="mobile"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        autoComplete: "off",
                        value: mobile,
                        onChange: (e) => {
                          var res = e.target.value.substring(0, 1)
                          if (res == 3 || res == 6 || e.target.value == "") {
                            verify(e.target.value, "phone", "mobileErr", 8);
                          }
                        },
                        onBlur: (e) => {
                          setPhone(e.target.value.trim());
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <CustomInput
                      error={values.emailErr}
                      labelText={
                        <span>
                          Email<small className={classes.required}>*</small>
                        </span>
                      }
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        autoComplete: "off",
                        value: email,
                        onChange: (e) => {
                          setEmail(e.target.value);
                          verify(e.target.value, "email", "emailErr", 3);
                        },
                        onBlur: (e) => {
                          setEmail(e.target.value.trim());
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <CustomInput
                      error={values.contactCPRERR}
                      labelText={
                        <span>
                          CPR No.<small className={classes.required}>*</small>
                        </span>
                      }
                      id="cpr"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        autoComplete: "off",
                        value: contactCPR,
                        onChange: (e) => {
                          verify(e.target.value, "cpr", "contactCPRERR", 9);
                        },
                        onBlur: (e) => {
                          setContactCPR(e.target.value.trim());
                          findExistingCPR(e.target.value.trim())
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {contactIDName !== "" ? (
                      <CustomInput
                        labelText="Certified copy of identification"
                        id="dir-id-proof"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          autoComplete: "off",
                          readOnly: true,
                          value: contactIDName,
                        }}
                      />
                    ) : (
                      <>
                        <br /> <br />
                        <Dropzone onDrop={contactIDDrop}>
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <Button size="sm" fullWidth color={contactIDBtnColor}>
                                ID Proof
                              </Button>
                            </div>
                          )}
                        </Dropzone>
                      </>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    {contactTableData.length !== 0 ? (
                      <>
                        <Button
                          color="primary"
                          className={classes.addOwnerActions}
                          size="sm"
                          simple
                          onClick={cancelAddContact}>
                          Cancel
                        </Button>
                      </>
                    ) : null}

                    <Button color="primary" className={classes.addOwnerActions} size="sm" onClick={addContact}>
                      Add  Contact
                    </Button>
                  </GridItem>
                </GridContainer>
              </Collapse>
              <GridItem xs={12} sm={12}>
                <h6 className={classes.subHead}>Incorporation Details</h6>
              </GridItem>
              <GridItem xs={12} sm={12}>
                <Divider style={{ background: "black" }} fullWidth />
              </GridItem>
              <GridItem xs={12} sm={3}>
                <FormControl className={classes.selectFormControl} error={values.compIDErr} fullWidth>
                  <InputLabel className={classes.selectLabel}>
                    Type of ID
                    <small className={classes.required}>*</small>
                  </InputLabel>
                  <Select
                    id="listed-on-stock-exchange"
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={typeOfID}
                    disabled={props.edit}
                    onChange={(e) => {
                      setTypeOfID(e.target.value);
                      if (e.target.value == "UnitCode") setIDTypeLabel("Unit Code");
                      else setIDTypeLabel("Commercial Registration ID");
                      verify(e.target.value, "length", "compIDErr", 2);

                      if (commercialRegistrationID) {
                        if (e.target.value == "UnitCode") {
                          if (e.target.value.length !== 10) {
                            setCommercialRegistrationID("")
                            setValues({ ...values, commercialRegistrationIDErr: true })
                          }
                        } else {
                          var array = commercialRegistrationID.split('-')
                          if (!array[0].length > 1 && !array[0].length <= 9) {
                            setCommercialRegistrationID("")
                            setValues({ ...values, commercialRegistrationIDErr: true })
                          }
                        }
                      }
                    }}
                    onBlur={(e) => {
                      handleEditData("IncorporationDetails", "TypeOfID", null, e.target.value)
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value="UnitCode">
                      Unit Code
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value="CR">
                      Commercial Registration
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  error={values.commercialRegistrationIDErr}
                  labelText={
                    <span>
                      {idTypeLabel} <small className={classes.required}>*</small>
                    </span>
                  }
                  id="commercial-registration-id"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    value: commercialRegistrationID,
                    disabled: props.edit,
                    // disabled: ,
                    onChange: (e) => {
                      const re = /^[0-9 \-]+$/;
                      if (re.test(e.target.value) || e.target.value == "") {
                        setCommercialRegistrationID(e.target.value);
                        verify(e.target.value, "length", "commercialRegistrationIDErr", 3);
                      }
                    },
                    onBlur: (e) => {
                      if (idTypeLabel != "Unit Code") {
                        if (e.target.value !== "") {
                          var array = e.target.value.split('-')
                          if (array[1] == undefined) {
                            let value = e.target.value + "-00"
                            setCommercialRegistrationID(value.trim());
                            checkCorporate(value.trim())
                            handleEditData("IncorporationDetails", "RegistrationID", null, value)
                          } else if (array[1] == "") {
                            let value = array[0] + '-00'
                            setCommercialRegistrationID(value.trim());
                            checkCorporate(value.trim())
                            handleEditData("IncorporationDetails", "RegistrationID", null, value)
                          } else if (array[1].length < 2) {
                            let value = array[0] + '-0' + array[1]
                            checkCorporate(value.trim())
                            setCommercialRegistrationID(value.trim());
                            handleEditData("IncorporationDetails", "RegistrationID", null, value)
                          } else if (array[1].length > 2) {
                            let value = array[0] + "-" + array[1].slice(0, 2)
                            checkCorporate(value.trim())
                            setCommercialRegistrationID(value.trim());
                            handleEditData("IncorporationDetails", "RegistrationID", null, value)
                          } else {
                            setCommercialRegistrationID(e.target.value.trim());
                            checkCorporate(e.target.value.trim())
                            handleEditData("IncorporationDetails", "RegistrationID", null, e.target.value.trim())
                          }
                          if (array[0].length > 1 && array[0].length <= 9 && array[0] > 9) {
                            setValues({ ...values, commercialRegistrationIDErr: false })
                          } else {
                            setCommercialRegistrationID("")
                            setValues({ ...values, commercialRegistrationIDErr: true })
                            openSnackBar("Invalid CR Entered")
                          }

                        } else {
                          checkCorporate(e.target.value.trim())
                          setCommercialRegistrationID(e.target.value.trim());
                          handleEditData("IncorporationDetails", "RegistrationID", null, e.target.value)
                        }

                      } else {

                        if (e.target.value.length > 7 && e.target.value.length < 11) {
                          checkCorporate(e.target.value.trim())
                          setCommercialRegistrationID(e.target.value.trim());
                          setValues({ ...values, commercialRegistrationIDErr: false })
                        } else {
                          setCommercialRegistrationID("")
                          setValues({ ...values, commercialRegistrationIDErr: true })
                          openSnackBar("Invalid Unit code Entered")
                        }
                      }
                    }
                  }
                  }
                />
              </GridItem>
              <GridItem xs={12} sm={3}>
                <FormControl className={classes.selectFormControl} fullWidth>
                  <InputLabel className={classes.dateLabel}>
                    <span>
                      Expiry Date <small className={classes.required}>*</small>
                    </span>
                  </InputLabel>
                  <TextField
                    error={values.expiryDateErr}
                    className={classes.dateInput}
                    type="date"
                    inputProps={{
                      autoComplete: "off",
                      type: "date",
                      min: moment(new Date()).format("YYYY-MM-DD"),
                      max: "9999-12-31",
                      value: expiryDate,
                      onChange: (e) => {
                        verify(e.target.value, "length", "expiryDateErr", 3);
                        setExpiryDate(e.target.value);
                      },
                      onBlur: (e) => {
                        setExpiryDate(e.target.value);
                        if (moment(e.target.value).isBefore(moment(new Date()))) {
                          setValues({ ...values, expiryDateErr: true })
                          openSnackBar("Please enter valid expiry date.")
                        } else {
                          handleEditData("IncorporationDetails", "ExpiryDate", null, e.target.value)
                        }
                      }

                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <FormControl className={classes.selectFormControl} fullWidth>
                  <InputLabel className={classes.dateLabel}>
                    <span>
                      Date of Incorporation <small className={classes.required}>*</small>
                    </span>
                  </InputLabel>
                  <TextField
                    error={values.dateOfIncorporationErr}
                    className={classes.dateInput}
                    type="date"
                    inputProps={{
                      autoComplete: "off",
                      disabled: props.edit,
                      type: "date",
                      max: moment(new Date()).format("YYYY-MM-DD"),
                      value: dateOfIncorporation,
                      onChange: (e) => {
                        verify(e.target.value, "length", "dateOfIncorporationErr", 3);
                        setDateOfIncorporation(e.target.value);
                        if (moment(e.target.value).isAfter(moment(new Date()))) {
                          setValues({ ...values, dateOfIncorporationErr: true })
                          openSnackBar("Please enter valid incorporation date.")
                        } else {
                          handleEditData("IncorporationDetails", "DateOfIncorporation", null, e.target.value)
                        }
                      },
                      onBlur: (e) => {
                        verify(e.target.value, "length", "dateOfIncorporationErr", 3);
                        if (moment(e.target.value).isAfter(moment(new Date()))) {
                          setValues({ ...values, dateOfIncorporationErr: true })
                        }
                      },
                    }}
                  />
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={4}>

                <Autocomplete
                  className={classes.autocomplete}
                  options={countries}
                  value={countryOfIncorporation}
                  getOptionLabel={(option) => option.country}
                  fullWidth
                  disabled={props.edit}
                  onChange={(value, item) => {
                    if (item !== null) {
                      setCountryOfIncorporation(item);
                      if (props.edit) {
                        handleEditData("IncorporationDetails", "CountryOfIncorporation", null, item.ISO)
                      }
                    }
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} error={values.countryOfIncorporationErr} label={<span>Country of Incorporation <small className={classes.required}>*</small> </span>} />}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <FormControl className={classes.selectFormControl} error={values.listedOnStockExchangeErr} fullWidth>
                  <InputLabel className={classes.selectLabel}>
                    Is the organization publicly listed on any stock exchange?{" "}
                    <small className={classes.required}>*</small>
                  </InputLabel>
                  <Select
                    id="listed-on-stock-exchange"
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={listedOnStockExchange}
                    onChange={(e) => {
                      setListedOnStockExchange(e.target.value);
                      verify(e.target.value, "length", "listedOnStockExchangeErr", 3);
                      if (props.edit) {
                        if (e.target.value == "true") {
                          handleEditData("IncorporationDetails", "IdListedOnStock", null, "YES")
                        } else {
                          handleEditData("IncorporationDetails", "IdListedOnStock", null, "NO")
                        }
                      }
                    }}>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value="true">
                      Yes
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value="false">
                      No
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <Collapse
                  in={listedOnStockExchange === "true"}
                  style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                  {...(true ? { timeout: 500 } : {})}>
                  <CustomInput
                    error={values.stockExchangeNameErr}
                    labelText={
                      <span>
                        Stock Exchange Name <small className={classes.required}>*</small>
                      </span>
                    }
                    id="stock-exchange-name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: stockExchangeName,
                      onChange: (e) => {
                        setStockExchangeName(libs.validate(e.target.value, "alphanumeric", 100) ? e.target.value : stockExchangeName);
                        verify(libs.validate(e.target.value, "alphanumeric", 100) ? e.target.value : stockExchangeName, "length", "stockExchangeNameErr", 3);
                      },
                      onBlur: (e) => {
                        let editDataTemp = editData
                        setStockExchangeName(e.target.value.trim());
                        if (props.edit) {
                          editDataTemp.IncorporationDetails.StockListed = []
                          editDataTemp.IncorporationDetails.StockListed.push(e.target.value)
                          setEditData(editDataTemp)
                        }
                      }
                    }}
                  />
                </Collapse>
              </GridItem>
              <GridItem xs={12} sm={12}>
                <h6 className={classes.subHead}>
                  Regulatory Status <small>(if applicable)</small>
                </h6>
              </GridItem>
              <GridItem xs={12} sm={12}>
                <Divider style={{ background: "black" }} fullWidth />
              </GridItem>
              <GridItem xs={12} sm={8}>
                <CustomInput
                  error={values.regulatoryAuthorityErr}
                  labelText={<span>Regulatory Authority</span>}
                  id="regulatory-authority"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    value: regulatoryAuthority,
                    onChange: (e) => {
                      setRegulatoryAuthority(libs.validate(e.target.value, "alphabet", 100) ? e.target.value : regulatoryAuthority);
                      verify(libs.validate(e.target.value, "alphabet", 100) ? e.target.value : regulatoryAuthority, "length", "regulatoryAuthorityErr", 3);
                    },
                    onBlur: (e) => {
                      setRegulatoryAuthority(e.target.value.trim());
                      handleEditData("RegulatoryStatus", "RegulatoryAuthorityName", null, e.target.value)
                    }
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  error={values.licenseNumberErr}
                  labelText={<span>License Number</span>}
                  id="license-number"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    value: licenseNumber,
                    onChange: (e) => {
                      setLicenseNumber(libs.validate(e.target.value, "alphanumeric", 20) ? e.target.value : licenseNumber);
                      verify(libs.validate(e.target.value, "alphanumeric", 20) ? e.target.value : licenseNumber, "length", "licenseNumberErr", 3);
                    },
                    onBlur: (e) => {
                      setLicenseNumber(e.target.value.trim());
                      handleEditData("RegulatoryStatus", "LicenseNumber", null, e.target.value)
                    }
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12}>
                <br />
              </GridItem>
              <GridItem xs={12} sm={8}>
                <p className={classes.question}>
                  Organization ever had any regulatory or criminal enforcement actions resulting from violations of
                  anti-money laundering laws or regulations?
                </p>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <FormControl
                  error={values.enforcementActionsErr}
                  style={{ textAlign: "left !important" }}
                  fullWidth
                  component="fieldset">
                  <RadioGroup
                    row
                    aria-label="position"
                    value={enforcementActions}
                    onChange={(e) => {
                      setEnforcementActions(e.target.value);
                      verify(e.target.value, "length", "enforcementActionsErr", 3);
                      if (e.target.value == "true") {
                        handleEditData("RegulatoryStatus", "isAnyViolationOnRegulations", null, "YES")
                      } else {
                        handleEditData("RegulatoryStatus", "isAnyViolationOnRegulations", null, "NO")
                      }
                    }}
                    name="position">
                    <FormControlLabel
                      className={classes.statusText}
                      value="true"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />

                    <FormControlLabel
                      className={classes.statusText}
                      value="false"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12}>
                <Collapse
                  in={enforcementActions === "true"}
                  style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                  {...(true ? { timeout: 500 } : {})}>
                  <CustomInput
                    error={values.actionDetailsErr}
                    labelText={
                      <span>
                        Details <small className={classes.required}>*</small>
                      </span>
                    }
                    id="commercial-registration-id"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: actionDetails,
                      onChange: (e) => {
                        setActionDetails(e.target.value);
                        verify(e.target.value, "length", "licenseNuactionDetailsErrmberErr", 3);
                      },
                      onBlur: (e) => {
                        setActionDetails(e.target.value.trim());
                        handleEditData("RegulatoryStatus", "ViolationDetails", null, e.target.value)
                      }
                    }}
                  />
                </Collapse>
              </GridItem>
              <GridItem xs={12} sm={12}>
                <Divider style={{ marginTop: "25px", marginBottom: "10px" }} fullWidth />
              </GridItem>
              <GridItem xs={12} sm={12}>
                {/* <Button color="primary" className={classes.backButton} onClick={() => goBack()}>
                  Back
                </Button> */}
                <Button color="primary" className={classes.submitButton} onClick={submitData}>
                  Next
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </Collapse>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.processModal,
        }}
        open={submitting}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
          <h4 className={classes.modelTag}> {submittingLabel}</h4>
        </DialogContent>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          // paper: classes.processModal,
        }}
        open={openPDFeViewer}
        TransitionComponent={Transition}
        onClose={() => {
          setOpenPDFViewer(false);
          setDocumentViewerData("");
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <Document
            file={documentViewerData}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <p>Page {pageNumber} of {numPages}</p>
          <Button size="sm" color="primary" onClick={() => {
            if (pageNumber < 2) {
              setPageNumber(1)
            } else {
              let newPageNumber = pageNumber - 1
              setPageNumber(newPageNumber)
            }
          }} >Pervious</Button>
          <Button size="sm" color="primary"
            onClick={() => {
              if (numPages > pageNumber) {
                let newPageNumber = pageNumber + 1
                setPageNumber(newPageNumber)
              } else {
                setPageNumber(numPages)
              }
            }}
          >Next</Button>
        </DialogContent>
      </Dialog>

      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.companyLogModal,
        }}
        open={contactEdit}
        TransitionComponent={Transition}
        onClose={() => {
          setContactEdit(false);
          cancelAddContact()
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12} sm={3}>
              <FormControl style={{ textAlign: "left" }} className={classes.selectFormControl} error={values.titleErr} fullWidth>
                <InputLabel className={classes.selectLabel}>
                  Title
                  {<small className={classes.required}>*</small>}
                </InputLabel>
                <Select
                  id="listed-on-stock-exchange"
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value.trim());
                    verify(e.target.value, "length", "titleErr", 2);
                  }}
                >
                  {titles.map((title) => {
                    return <MenuItem classes={{ root: classes.selectMenuItem }} value={title}>{title}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={9}>
              <CustomInput
                error={values.fullNameErr}
                labelText={
                  <span>
                    Full Name <small className={classes.required}>*</small>
                  </span>
                }
                id="full-name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: fullName,
                  onChange: (e) => {
                    setFullName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : fullName);
                    verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : fullName, "length", "fullNameErr", 3);
                  },
                  onBlur: (e) => {
                    setFullName(e.target.value.trim());
                  }
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <CustomInput
                error={values.mobileErr}
                labelText={
                  <span>
                    Mobile No<small className={classes.required}>*</small>
                  </span>
                }
                id="mobile"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: mobile,
                  onChange: (e) => {
                    var res = e.target.value.substring(0, 1)
                    if (res == 3 || res == 6 || e.target.value == "") {
                      verify(e.target.value, "phone", "mobileErr", 8);
                    }
                  },
                  onBlur: (e) => {
                    setPhone(e.target.value.trim());
                  }
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <CustomInput
                error={values.emailErr}
                labelText={
                  <span>
                    Email<small className={classes.required}>*</small>
                  </span>
                }
                id="email"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: email,
                  onChange: (e) => {
                    setEmail(e.target.value);
                    verify(e.target.value, "email", "emailErr", 3);
                  },
                  onBlur: (e) => {
                    setEmail(e.target.value.trim());
                  }
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <CustomInput
                error={values.contactCPRERR}
                labelText={
                  <span>
                    CPR No.<small className={classes.required}>*</small>
                  </span>
                }
                id="cpr"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: contactCPR,
                  disabled: props.edit,
                  onChange: (e) => {
                    verify(e.target.value, "cpr", "contactCPRERR", 9);
                  },
                  onBlur: (e) => {
                    setContactCPR(e.target.value.trim());
                  }
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              {contactIDName !== "" ? (
                <CustomInput
                  labelText="ID Proof"
                  id="dir-id-proof"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    readOnly: true,
                    value: contactIDName,
                  }}
                />
              ) : (
                <>
                  <br /> <br />
                  <Dropzone onDrop={contactIDDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button size="sm" fullWidth color={contactIDBtnColor}>
                          ID Proof
                        </Button>
                      </div>
                    )}
                  </Dropzone>
                </>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
              <br />  <br />
              <Tooltip placement="right" title="Delete ID">
                <IconButton
                  onClick={() => deleteContactID()}
                  style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                  aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                color="primary"
                className={classes.addOwnerActions}
                size="sm"
                simple
                onClick={cancelAddContact}>
                Cancel
              </Button>
              <Button color="primary" className={classes.addOwnerActions} size="sm" onClick={() => updateContact(editContactID)}>
                Update
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
          }}
          severity="error">
          {snackBarMsg}
        </Alert>
      </Snackbar>
      {
        openImageViewer && (
          <Lightbox
            mainSrc={documentViewerData}
            onCloseRequest={() => setOpenImageViewer(false)}
          />
        )
      }
    </div >
  );
}
