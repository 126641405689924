import React from "react";
import config from "config.js"
import MUIDataTable from "mui-datatables";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import BounceLoader from "react-spinners/BounceLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import moment from "moment";
import { creatCsvFile, downloadFile } from "download-csv";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PeopleIcon from "@material-ui/icons/People";
import EditIcon from "@material-ui/icons/Edit";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import { SettingsPhoneTwoTone } from "@material-ui/icons";

const styles = (theme) => ({
  ...SweetAlertStyle,
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    float: "left",
    display: "inlineBlock",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  addButton: {
    float: "right !important",
    display: "inlineBlock",
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  input: {
    flex: 1,
    height: "45px",
    marginLeft: "15px",
  },
  addGroupContainer: {
    height: "49px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  addGroupButton: {
    marginRight: "-5px !important",
  },
  statusText: {
    color: "#000 !important",
  },
  statusHead: {
    marginBottom: "0px !important",
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  left: {
    textAlign: "left",
  },
  marginRight: {
    marginRight: "5px",
  },
  modalSectionTitle: {
    marginTop: "30px",
  },
  userContainer: {
    maxHeight: "500px",
    overflowY: "scroll",
  },
  modelTag: {
    marginBottom: "-10px !important"
  },
  ...modalStyle(theme),
});

const useStyles = makeStyles(styles);
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function UserGroups() {
  const [groups, setGroups] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [groupName, setGroupName] = React.useState("");
  const [showAddGroup, setShowAddGroup] = React.useState(false);
  const [showGroupTable, setShowGroupTable] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(true);
  const [usersModal, setUsersModal] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [respAlert, setRespAlert] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);
  const [heading, setHeading] = React.useState("Enter Group Name");
  const [buttonLabel, setButtonLabel] = React.useState("Add");
  const [editGroupID, setEditGroupID] = React.useState("");
  const [editGroupStatus, setEditGroupStatus] = React.useState("");
  const [csvData, setCsvData] = React.useState([]);

  const override = css`
    display: block;
    margin: 32px auto;b
    border-color: red;
  `;
  const columns = [
    {
      name: "groupName",
      label: "Group Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "_id",
      label: "Group ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "noOfUsers",
    //   label: "No of Users",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    {
      name: "dateOfCreation",
      label: "Date of creation",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "actions",
      label: " Actions",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];
  const options = {
    selectableRows: false,
    elevation: 0,
    onDownload: () => {
      let csvFile = creatCsvFile(csvData);
      let fileName = "groups.csv";
      downloadFile(csvFile, fileName);
      return false;
    },
  };

  const hideAlert = () => {
    setRespAlert(null);
  };
  const handleAddClick = () => {
    //function to handle add button click
    setHeading("Enter Group Name");
    setButtonLabel("ADD");
    setShowAddGroup(true);
    setShowGroupTable(false);
  };

  const handleEditClick = (id, name, status) => {
    //function to handle add button click
    setEditGroupStatus(status);
    setEditGroupID(id);
    setGroupName(name);
    setHeading("Edit Group");
    setButtonLabel("SAVE");
    setShowAddGroup(true);
    setShowGroupTable(false);
  };
  function openSnackBar(msg) {
    //function to tost snackbar message
    setOpenSnack(true);
    setSnackBarMsg(msg);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  }
  const addGroup = () => {
    //function to add group
    setSubmitting(true);
    if (groupName.length !== 0) {
      if (groupName.length >= 5) {
        if (buttonLabel === "ADD") {
          axios
            .post(
              `${config.url().baseURL}/create-group`,
              {
                groupName: groupName,
              },
              {
                headers: {
                  auth: sessionStorage.getItem("cx_id"),
                },
              }
            )
            .then((resp) => {
              let respData = resp.data;
              if (!respData.err) {
                setRespAlert(
                  <SweetAlert
                    success
                    style={{ display: "block" }}
                    title="Group added successfully."
                    onConfirm={() => successAdd()}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                  />
                );
              } else {
                openSnackBar(respData.msg);
                setSubmitting(false);
              }
            })
            .catch((err) => {
              openSnackBar("Unable to process.");
            });
        } else {

          axios.put(
            `${config.url().baseURL}/edit-group/${editGroupID}`,
            {
              groupName: groupName,
              status: editGroupStatus,
            },
            {
              headers: {
                auth: sessionStorage.getItem("cx_id"),
              },
            }
          )
            .then((resp) => {
              let respData = resp.data;
              if (!respData.err) {
                setRespAlert(
                  <SweetAlert
                    success
                    style={{ display: "block" }}
                    title="Group updated."
                    onConfirm={() => successAdd()}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                  />
                );
              } else {
                openSnackBar(respData.msg);
                setSubmitting(false);
              }
            })
            .catch((err) => {
              openSnackBar("Unable to process.");
            });
        }
      } else {
        setSubmitting(false);
        openSnackBar("Group name must be 5 letters long.");
      }
    } else {
      setSubmitting(false);
      openSnackBar("Group Name can't be left blank.");
    }
  };
  const successAdd = () => {
    hideAlert();
    setShowAddGroup(false);
    setShowGroupTable(true);
    setSubmitting(false);
    setGroupName("");
    fetchGroups();
  };

  React.useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = () => {
    //function to fetch groups data
    axios.get(`${config.url().baseURL}/get-groups`, {
      headers: {
        auth: sessionStorage.getItem("cx_id"),
      },
    })
      .then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          var updatedData = respData.data.map((group) => {
            group.actions = (
              <>
                <Tooltip placement="top" title="Users">
                  <IconButton
                    onClick={() => fetchUsers(group._id)}
                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                    aria-label="delete">
                    <PeopleIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip placement="top" title="Edit">
                  <IconButton
                    onClick={() => handleEditClick(group._id, group.groupName, group.status)}
                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                    aria-label="delete">
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                {/* <Tooltip placement="top" title="Delete">
                  <IconButton
                    onClick={() => confirmDelete(group._id)}
                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                    aria-label="edit">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip> */}
              </>
            );
            group.dateOfCreation = moment(group.creation_date).format("DD/MM/YYYY");
            return group;
          });
          setGroups(updatedData);
          setShowGroupTable(true);
          setShowLoader(false);
          var updatedCsvData = respData.data.map((group) => {
            let temp = {};
            temp.Group_Name = group.groupName;
            temp.Group_ID = group._id;
            temp.Created_On = moment(group.creation_Date).format("DD/MM/YYYY");
            temp.Status = group.status;
            return temp;
          });
          setCsvData(updatedCsvData);
        } else {
          setGroups([]);
          setCsvData([])
          setShowGroupTable(true);
          setShowLoader(false);
          openSnackBar(respData.msg);
        }
      })
      .catch((err) => {
        openSnackBar("Unable to fetch user groups.");
      });
  };

  const confirmDelete = (id) => {
    //function confirms user want to delete group.
    setRespAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are sure ?"
        onConfirm={() => deleteGroup(id)}
        showCancel
        onCancel={() => hideAlert()}
        focusCancelBtn
        confirmBtnText="Yes, delete it!"
        confirmBtnCssClass={classes.button + " " + classes.danger}
      />
    );
  };

  const deleteGroup = (id) => {
    //function to delete customer
    axios.delete(`${config.url().baseURL}/delete-group/${id}`).then((resp) => {
      let respData = resp.data;
      if (!respData.err) {
        fetchGroups();
        setRespAlert(
          <SweetAlert
            success
            style={{ display: "block" }}
            title="Group deleted successfully."
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classes.button + " " + classes.success}
          />
        );
      }
    });
  };

  const fetchUsers = (id) => {
    //function to fetch users data
    setUsersModal(true);
    axios.get(`${config.url().baseURL}/get-group-members/${id}`, {
      headers: {
        auth: sessionStorage.getItem("cx_id"),
      },
    })
      .then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          var updatedData = respData.data.map((user) => {
            if (user.userid !== null) {
              user.fullName = user.userid.firstName + " " + user.userid.lastName;
            }
            return user;
          });
          setUsers(updatedData);
        } else {
          setUsersModal(false);
          setUsers([]);
          openSnackBar(respData.msg);
        }
      })
      .catch((err) => {
        alert(err)
        openSnackBar("Unable to fetch group users.");
      });
  };
  const classes = useStyles();
  return (
    <div>
      {respAlert}
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showLoader}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
          </Collapse>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showGroupTable}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>User Groups</h4>
                <Button onClick={() => handleAddClick()} color="secondary" size="sm" className={classes.addButton}>
                  Add User Group
                </Button>
              </CardHeader>
              <CardBody>
                <MUIDataTable data={groups} columns={columns} options={options} />
              </CardBody>
            </Card>
          </Collapse>
        </GridItem>

        <GridItem xs={12} sm={12} md={8}>
          <Collapse
            in={showAddGroup}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <h3>{heading}</h3>
            <br />
            {buttonLabel === "SAVE" ? (
              <FormControl component="fieldset">
                <FormLabel className={classes.statusHead} component="legend">
                  Status
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="position"
                  onChange={(e) => setEditGroupStatus(e.target.value)}
                  value={editGroupStatus}
                  name="position"
                  defaultValue="top">
                  <FormControlLabel
                    className={classes.statusText}
                    value="Active"
                    control={<Radio color="primary" />}
                    label="Active"
                  />
                  <FormControlLabel
                    className={classes.statusText}
                    value="Pending"
                    control={<Radio color="primary" />}
                    label="Pending"
                  />
                  <FormControlLabel
                    className={classes.statusText}
                    value="Disabled"
                    control={<Radio color="primary" />}
                    label="Disabled"
                  />
                </RadioGroup>
              </FormControl>
            ) : null}
            <Paper component="form" className={classes.addGroupContainer}>
              <InputBase
                autoFocus={true}
                fullWidth
                className={classes.input}
                placeholder="Group Name"
                inputProps={{
                  "aria-label": "Group name",
                  onChange: (event) => {
                    if (event.target.value !== "") {
                      if (event.target.value.match(/^[a-zA-Z ]+$/)) {
                        setGroupName(event.target.value);
                      }
                    } else {
                      setGroupName(event.target.value);
                    }
                  },
                  value: groupName,
                }}
              />
              {!submitting ? (
                <Button className={classes.addGroupButton} onClick={() => addGroup()} size="lg" color="primary">
                  {buttonLabel}
                </Button>
              ) : (
                <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
              )}
            </Paper>
            <Button
              onClick={() => {
                setShowGroupTable(true);
                setShowAddGroup(false);
                setGroupName("");
              }}
              size="lg"
              fullWidth
              className={classes.backButton}
              simple
              color="primary">
              <ArrowBackIcon />
              Back
            </Button>
          </Collapse>
        </GridItem>
      </GridContainer>

      <Dialog
        classes={{
          root: classes.left + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={usersModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setUsersModal(false);
          setUsers([]);
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
          <h4 className={classes.modalTitle}>Users</h4>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <div className={classes.userContainer}>
            <List component="nav" className={classes.root} aria-label="mailbox folders">
              {users.map((user) => {
                return (
                  <>
                    <ListItem button>
                      <ListItemText primary={user.fullName} secondary={moment(user.addedOn).format("DD/MM/YYYY")} />
                    </ListItem>
                    <Divider />
                  </>
                );
              })}
            </List>
          </div>
        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
          <Button
            onClick={() => {
              setUsersModal(false);
              setUsers([]);
            }}
            color="danger"
            size="sm">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
            setSnackBarMsg("");
          }}
          severity="error">
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
