import * as React from 'react';
import axios from "axios";
import MUIDataTable from "mui-datatables";
import Collapse from "@material-ui/core/Collapse";
import config from "config.js"
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import Tooltip from "@material-ui/core/Tooltip";
import PreviewIcon from '@mui/icons-material/Preview';
import IconButton from "@material-ui/core/IconButton";
import moment from 'moment';
import { useSelector } from 'react-redux';

import NewAdjustmentForm from './Components/AdjustmentForm';
import AdjustmentDetailsView from './Components/AdjustmentDetails';



const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;


const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
}));

const columns = [
    {
        name: "updatedDate",
        label: "Date",
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: "ReferenceNumber",
        label: "Reference No",
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: "Customer",
        label: "Customer",
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: "updatedType",
        label: "Type",
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: "Amount",
        label: "Amount",
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: "CurrencyCode",
        label: "Currency",
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: "status",
        label: "Status",
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: "actions",
        label: "Actions",
        options: {
            filter: false,
            sort: false,
        },
    }
];
const options = {
    selectableRows: false,
    elevation: 0,
    // onDownload: () => {
    //     let csvFile = creatCsvFile(csvData);
    //     let fileName = "departments.csv";
    //     downloadFile(csvFile, fileName);
    //     return false;
    // },
    textLabels: {
        body: {
            noMatch: "No matching records found",
        },
    }
};



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Adjustments() {
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [showTransactionTable, setShowTransactionTable] = React.useState(true)
    const [showLoader, setShowLoader] = React.useState(false)
    const [showNewForm, setShowNewForm] = React.useState(false)
    const [showDetails, setShowDetails] = React.useState(false)

    const [newForm, setNewForm] = React.useState(null)
    const [detailsView, setDetailsView] = React.useState(null)
    const [snackBarType, setSnackBarType] = React.useState("error");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [respAlert, setRespAlert] = React.useState(null);

    const [adjustments, setAdjustments] = React.useState([]);
    const [addBtn, setAddBtn] = React.useState(false);

    const newAdjustment = () => {
        //function handle new 
        setShowTransactionTable(false)
        setNewForm(<NewAdjustmentForm snack={openSnackBar} openAlert={openAlert} back={comeBack} />)
        setShowNewForm(true)
    }



    function openSnackBar(msg, success) {
        //function to tost snackbar message
        setOpenSnack(true);
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const comeBack = () => {
        //function to come back to the transaction home
        setShowTransactionTable(true)
        setShowNewForm(false)
        setNewForm(null)
        setShowDetails(false)
        setDetailsView(null)
        fetchTransactions()
    }

    const openAlert = (type, title, text) => {
        //function to open sweet alert
        setRespAlert(
            <SweetAlert
                type={type}
                style={{ display: "block" }}
                title={title}
                onConfirm={() => {
                    setRespAlert(null);
                    comeBack()
                }}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                {text}
            </SweetAlert>)
    }

    const checkUserType = () => {
        //function to check user  type
        let service = user.services.find(service => service.name == "Adjustments")
        let riskService = user.riskServices.find(riskService => riskService.name == "Adjustments")
        if ((service && service.type == "maker") || (riskService && riskService.type == "maker")) {
            setAddBtn(true)
        } else {
            setAddBtn(false)
        }
    }

    const fetchTransactions = async () => {
        //function to fetch transactions 
        try {
            checkUserType()
            let resp = await axios.get(`${config.url().baseURL}/get-adjustments`)
            if (resp.data.err) openSnackBar(resp.data.msg)
            else {
                let updatedAdjustments = []
                resp.data.data.map(transaction => {
                    transaction.updatedType = transaction.TransactionFileType == "Fee adjustment" || transaction.TransactionFileType == "FEE_ADJUSTMENT" ? "Fee adjustment" : transaction.TransactionFileType == "TAX_ADJUSTMENT" ? "Tax Adjustment" : transaction.TransactionFileType == "DISPUTE_REFUND" ? "Dispute Refund" : null
                    transaction.updatedDate = `${moment(transaction.date).format("DD/MM/YYYY")} ${moment(transaction.date).format("hh:mm:ss A")} `
                    transaction.actions = <Tooltip placement="top" title="View Details">
                        <IconButton
                            onClick={() => openAdjustmentDetails(transaction._id)}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="view">
                            <PreviewIcon />
                        </IconButton>
                    </Tooltip>
                    updatedAdjustments.push(transaction)
                })
                setAdjustments(updatedAdjustments)
            }
        } catch (error) {
            openSnackBar("Unable to fetch adjustments.")
        }
    }

    React.useEffect(() => {
        fetchTransactions();
    }, []);


    const openAdjustmentDetails = async (id) => {
        //function to open adjustment details view
        setShowTransactionTable(false)
        setDetailsView(<AdjustmentDetailsView snack={openSnackBar} id={id} openAlert={openAlert} back={comeBack} />)
        setShowDetails(true)
    }
    return (
        <>
            {respAlert}
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showTransactionTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Adjustments</h4>
                                {
                                    addBtn ?
                                        <Button onClick={newAdjustment} color="secondary" size="sm" className={classes.addButton}>
                                            New Adjustment
                                        </Button>
                                        :
                                        null
                                }
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={adjustments} columns={columns} options={options} />
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showNewForm}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        {newForm}
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showDetails}
                        style={{ transformOrigin: "0 0 0" }}
                        {...(true ? { timeout: 500 } : {})}>
                        {detailsView}
                    </Collapse>
                </GridItem>
            </GridContainer>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </>
    );
}