import React, { useState } from 'react';
import { TextField, Checkbox, FormControlLabel, FormGroup, Select, MenuItem } from '@material-ui/core';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { css } from "@emotion/core";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Divider } from '@mui/material';
import config from 'config';
import axios from 'axios';
import Dropzone from "react-dropzone";



import Button from "components/CustomButtons/Button.js";
import { makeStyles } from '@material-ui/core/styles';
import Grid from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem';

const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    formContainer: {

        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        padding: theme.spacing(2),

    },
    sectionContainer: {
        marginBottom: theme.spacing(2),
    },
    fileInput: {
        display: 'none',
    },
    uploadButton: {
        marginRight: theme.spacing(2),
    },
    submitButton: {
        marginTop: theme.spacing(2),
    },

}));
const VendorForm = ({ back, snack, alertPop,type }) => {
    const classes = useStyles();

    const [billerName, setBillerName] = useState('');
    const [category, setCategory] = useState()
    const [tranCode, settranCode] = useState(type);
    const [isProducts, setisProducts] = useState(false);
    const [productCode, setProductCode] = useState('');
    const [producttranCode, setProducttranCode] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [isFileUpload, setisFileUpload] = useState(false)
    const [issuerId,setissuerId] = useState('')
    const [issuerName,setissuerName]= useState('')
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [uploadedFile, setUploadedFile] = useState()

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();


            // Perform form submission or data handling here
            let data = {
                data: {
                    billerName,
                    category,
                    productCode,
                    producttranCode,
                    isProducts,
                    isFileUpload,
                    issuerName,
                    issuerId,
                    tranCode
                }
            }

            let check = await Object.keys(data.data).some(key => {
                if (["billerName", "tranCode", "category","issuerName","issuerId"].includes(key)) {
                    const value = data.data[key];
                    return value === null || value === undefined || value === '';
                } else {
                    return false
                }

            });
            if (check) {
                snack("Please fill all the details.")

            } else {
                const resp = await axios.post(`${config.url().app_URL}/bill/create-category`, data)
                if (resp.data.status.err) {
                    snack(resp.data.message)
                    setBillerName('');
                    settranCode('');
                    setisProducts(false);
                    setProductCode('');
                    setProducttranCode('');
                    setAddressLine1('');
                    setAddressLine2('');
                    setCity('');
                    setState('');
                    setZipCode('');
                }
                else {
                    alertPop("success", "Biller data saved", resp.data.msg)
                }
            }
            // Reset the form

        } catch (error) {
            console.log(error)
            snack(error.message)

        }

    };

    return (
        <Card>
            <CardHeader color="primary" >
                <h4 className={classes.cardTitleWhite}>Vendor Biller</h4>

            </CardHeader>
            <CardBody style={{ padding: 50 }}>
                <Grid xs={12} >
                    <GridItem xs={4}>
                        <TextField
                            label="Biller Name *"
                            value={billerName}
                            onChange={(e) => setBillerName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />

                    </GridItem>
                    <GridItem xs={4}>
                        <TextField
                            label="Tarn Code *"
                            value={tranCode}
                            onChange={(e) => settranCode(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </GridItem>
                    <GridItem xs={2} >
                        <FormGroup style={{ marginTop: 25 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isProducts}
                                        onChange={(e) => setisProducts(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Is Product"
                            />
                        </FormGroup>
                    </GridItem>
                    <GridItem xs={2}>
                        <FormGroup style={{ marginTop: 25 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isFileUpload}
                                        onChange={(e) => setisFileUpload(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Is File Upload Needed"
                            />
                        </FormGroup>
                    </GridItem>


                    <GridItem xs={4}>
                        <TextField
                            label="Category *"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </GridItem>
                    <GridItem xs={4}>
                        <TextField
                            label="issuerId *"
                            value={issuerId}
                            onChange={(e) => setissuerId(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </GridItem>
                    <GridItem xs={4}>
                        <TextField
                            label="issuerName *"
                            value={issuerName}
                            onChange={(e) => setissuerName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </GridItem>



                    <GridItem item xs={4}>
                        {!isProducts && (

                            <TextField
                                label="Product Code"
                                value={productCode}
                                onChange={(e) => setProductCode(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        )}
                    </GridItem>
                    <GridItem item xs={4}>
                        {!isProducts && (

                            <Select
                                style={{ marginTop: 27 }}
                                fullWidth
                                labelId="Product tranCode"
                                value={producttranCode}
                                label="Product tranCode"
                                onChange={(e) => {
                                    setProducttranCode(e.target.value)
                                }}
                            >

                                <MenuItem key={"Voucher"} value={"Voucher"}>Voucher</MenuItem>
                                <MenuItem key={"Wultiple"} value={"Wultiple"}>Bill with Balance Fetch</MenuItem>
                                <MenuItem key={"BillPay"} value={"BillPay"}>Bill with out Balance Fetch</MenuItem>

                            </Select>
                        )}
                    </GridItem>
                </Grid>

                <div style={{ justifyContent: "center" , padding:10 ,marginLeft:600}}>
                    <Button
                        tranCode="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submitButton}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                    <Button
                        tranCode="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submitButton}
                        onClick={back}
                    >
                        Back
                    </Button>

                </div>
            </CardBody>
        </Card >
    );
};

export default VendorForm;
