import * as React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Collapse from '@material-ui/core/Collapse'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PropagateLoader from 'react-spinners/PropagateLoader'
import MUIDataTable from 'mui-datatables'
import { InputBase } from '@material-ui/core'
import moment from 'moment'
import { Paper } from '@material-ui/core'
import Snackbar from "@material-ui/core/Snackbar";
import BounceLoader from 'react-spinners/BounceLoader'
import JSONPretty from 'react-json-pretty';
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import SearchIcon from '@material-ui/icons/Search';
import swal from 'sweetalert';
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import { creatCsvFile, downloadFile } from "download-csv";
import MuiAlert from "@material-ui/lab/Alert";
import config from "config.js"
import AddReward from './components/AddReward'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import MainForm from './components/MainFormAndTable'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from "components/Table/Table.js";

import Tooltip from '@material-ui/core/Tooltip';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import download from "js-file-download";
// import { saveAs } from 'file-saver';
import TimelineIcon from '@mui/icons-material/Timeline';




import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { ViewList } from '@material-ui/icons'
const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    companyStatusLogModal12: {
        margin: "auto",
        minWidth: "800px",
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


export default function RewardReport() {
    const classes = useStyles();
    const [showLoader, setShowLoader] = useState(false);
    const [showRewardTbale, setShowRewardTable] = useState(true)
    // const [fromDate, setFromDate] = useState(moment(new Date).format("YYYY-MM-DD"))
    // const [toDate, setToDate] = useState(moment(new Date).format("YYYY-MM-DD"))
    const [openSnack, setOpenSnack] = useState(false)
    const [snackBarMsg, setSnackBarMsg] = useState("")
    const [snackBarType, setSnackBarType] = useState("error")
    const [csvData, setCsvData] = React.useState(false);
    const [respAlert, setRespAlert] = React.useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [rewardDetails, setRewardDetails] = useState([])
    const [child, setChild] = useState(null)
    const [isEdit, setIsEdit] = React.useState(false)
    const [onBoardingLogs, setOnBoardingLogs] = React.useState([]);
    const [onBoardingLogModal, setOnBoardingLogModal] = React.useState(false);

    const user = useSelector(state => state.user);


    const [openAddTable, setOpenAddTable] = useState(false)


    const logColumns = [
        { id: 'date', label: 'Date', width: 70 },
        { id: 'updatedAt', label: 'Time', width: 70 },
        { id: 'user', label: 'User' },
        { id: 'status', label: 'Status', width: 70 },
        { id: 'status_message', label: 'Status Info', minWidth: 170 },
        { id: 'remarks', label: 'Remarks', minWidth: 170 },
    ];




    const columns = [
        {
            name: "rewardType",
            label: "Reward",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "merchantName",
            label: "Merchant Name",
            options: {
                filter: true,
                sort: true
            }
        },

        {
            name: "currency",
            label: "Denomination Type",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "redemptionAccepted",
            label: "Accepted Redemption",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "productId",
            label: "Product Id",
            options: {
                filter: true,
                sort: true
            }

        },
        {
            name: "allocatedCount",
            label: "Allocated Count",
            options: {
                filter: true,
                sort: true
            }

        },

        {
            name: "unallocatedCount",
            label: "Un Allocated Count",
            options: {
                filter: true,
                sort: true
            }

        },
        {
            name: "totalCount",
            label: "Total Count",
            options: {
                filter: true,
                sort: true
            }

        },

        {
            name: "redeemedCount",
            label: "Redeemed Count",
            options: {
                filter: true,
                sort: true
            }

        },
        {
            name: "createStatus",
            label: "Status",
            options: {
                filter: true,
                sort: true
            }

        },


        {
            name: 'Actions',
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (_, tableMeta) => {
                    const rowData = rewardDetails[tableMeta.rowIndex];
                    let service = user.services.find(service => service.name == "Reward Inventory")

                    return (
                        <>
                            <Tooltip title="View Reward">
                                <span
                                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                                    onClick={() => viewReward(rowData.productId)}
                                >
                                    <VisibilityIcon />
                                </span>
                            </Tooltip>
                            <span style={{ marginRight: '10px' }}></span>
                            {
                                (service.type == 'maker' && rowData.createStatus && rowData.createStatus != 'APPROVED') ? (
                                    <Tooltip title="Edit Reward">
                                        <span
                                            style={{ textDecoration: 'none', cursor: 'pointer' }}
                                            onClick={() => editReward(rowData.productId)}
                                        >
                                            <EditIcon />
                                        </span>
                                    </Tooltip>
                                ) : null
                            }


                            <span style={{ marginRight: '10px' }}></span>

                            <Tooltip title="View Coupon">
                                <span
                                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                                    onClick={() => { viewCoupon(rowData.productId); setIsEdit(true) }}
                                >
                                    <ViewList />
                                </span>
                            </Tooltip>

                            {/* {rowData.couponCodeType === "UNIQUE" && (
                                <Tooltip title="Download Excel">
                                    <span
                                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                                        onClick={() => downloadExcel(rowData.productId)}
                                    >
                                        <CloudDownloadIcon />
                                    </span>
                                </Tooltip>
                            )} */}
                            <Tooltip title="User Log">
                                <span
                                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                                    onClick={() => fetchGPWLog(rowData.productId)}
                                >
                                    <TimelineIcon />
                                </span>
                            </Tooltip>

                        </>
                    );
                },
            },
        },




    ]



    const options = {
        selectableRows: false,
        elevation: 0,
        print: false,
        // onDownload: () => {
        //     let csvFile = creatCsvFile(csvData);
        //     let fileName = "Reward Report.csv";
        //     downloadFile(csvFile, fileName);
        //     return false;
        // },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        },

        selectableRows: false,
        elevation: 0,
        print: false,
        // onDownload: () => {
        //     let csvFile = creatCsvFile(csvData);
        //     let fileName = "Reward Report.csv";
        //     downloadFile(csvFile, fileName);
        //     return false;
        // },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        },

    };



    React.useEffect(() => {
        searchRewardDetails();
    }, [])

    const viewReward = (id) => {
        setShowRewardTable(false);
        setOpenAddTable(true)
        setChild(<MainForm type="View" back={comeBack} snack={openSnackBar} id={id} />)
    }
    const editReward = (id) => {
        setShowRewardTable(false);
        setOpenAddTable(true)
        setChild(<MainForm type="Edit" back={comeBack} snack={openSnackBar} id={id} />)
        // setChild()

    }

    const viewCoupon = (id) => {
        setShowRewardTable(false);
        setOpenAddTable(true)
        setChild(<MainForm type="CouponTable" back={comeBack} snack={openSnackBar} id={id} />)
        // setChild()

    }
    const validateSearch = () => {
        //function validate search params
        let snackBarMsg = ""
        let flag = true
        // if (fromDate.length == 0) {
        //     flag = false
        //     snackBarMsg = "Please select from date."
        // }
        // if (toDate.length == 0) {
        //     flag = false
        //     snackBarMsg = (snackBarMsg == "" ? "Please select To date." : snackBarMsg);
        // }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }
    const fetchGPWLog = (productId) => {
        setOnBoardingLogModal(true);
        axios.get(`${config.url().baseURL}/get-mc-reward-inventory-logs/${productId}`).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                if (respData.data[0] !== undefined) {
                    setOnBoardingLogs(respData.data);
                } else {
                    setOnBoardingLogModal(false);
                    openSnackBar("No log found.");
                }

            } else {
                setOnBoardingLogModal(false);
                openSnackBar(respData.msg);
            }
        });
    };
    
    const handleAddClick = () => {
        //function to handle add button click
        setShowRewardTable(false);
        setOpenAddTable(true)
        setChild(<MainForm type="Add" back={comeBack} snack={openSnackBar} />)

        // setMakerEdit(false)
        // setCompanyId("")
    };

    function comeBack() {
        setShowRewardTable(true);
        setOpenAddTable(false)
        searchRewardDetails()
        setChild("")

    }

    const searchRewardDetails = async () => {
        try {
            if (validateSearch()) {
                // let data = {
                //     "fromDate": moment(fromDate).format("MM/DD/YYYY"),
                //     "toDate": moment(toDate).format("MM/DD/YYYY")
                // }
                setShowLoader(true);
                setSubmitting(true);
                setShowRewardTable(false);
                setRewardDetails([])

                const resp = await axios.post(`${config.url().app_URL}/CXPortal/v13/customRewardsDashboard`)
                console.log(resp);
                //     .then((resp) => {
                if (resp) {
                    setShowLoader(false);
                    setSubmitting(false);
                    setShowRewardTable(true)
                }
                let resData = resp.data
                if (resData.err) {
                    openSnackBar(resData.msg)
                }
                else {

                    let updatedDetails = []
                    resData.data.map((activations) => {

                        // activations.date = activations.time_reported ? moment(activations.time_reported).format("DD/MM/YYYY  hh:mm A") : ""
                        activations.merchantName = activations.merchantName ? activations.merchantName : ""
                        activations.denomination = activations.denomination ? activations.denomination : ""
                        activations.productId = activations.productId ? activations.productId : ""
                        activations.redemptionAccepted = activations.redemptionAccepted ? activations.redemptionAccepted : ""
                        activations.validTo = activations.validTo ? moment(activations.validTo).format("DD/MM/YYYY") : ""
                        activations.rewardType = activations.rewardType ? activations.rewardType : ""
                        activations.count = activations.count ? activations.count : ""
                        activations.refnum = activations.refnum ? activations.refnum : ""
                        // activations.createStatus = activations.createStatus ? activations.createStatus : ""


                        updatedDetails.push(activations)
                    })
                    setRewardDetails(updatedDetails);
                    let temp = {}

                    var downloadedData = [
                        'SiNo'
                    ]
                    setCsvData(rewardDetails);
                }
                //  })
                // .catch((err) => {
                //     openSnackBar("Unable to process")
                // })
            }
        }
        catch (error) {
            console.log(error);
            openSnackBar("Unable to fetch the details")

        }
    }


    function openSnackBar(msg, success) {
        setOpenSnack(true)
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    return (
        <div>
            {respAlert}
            
            <Dialog
                classes={{
                    root: classes.left + " " + classes.modalRoot,
                    paper: classes.companyStatusLogModal,
                }}
                open={onBoardingLogModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setOnBoardingLogModal(false);
                    setOnBoardingLogs([]);
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>Reward Inventory status log</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <TableContainer className={classes.container}>
                        <MuiTable stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {logColumns.map((column) => (
                                        <TableCell
                                            key={column._id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {onBoardingLogs.map((row, key) => {
                                    let status = row.status
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                                            <TableCell>
                                                {moment(row.date).format("DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell>
                                                {moment(row.date).format("HH:mm:ss")}
                                            </TableCell>
                                            <TableCell>
                                                {row.user ? row.user.firstName + " " + row.user.lastName : null}
                                            </TableCell>
                                            <TableCell>
                                                {status}
                                            </TableCell>
                                            <TableCell>
                                                {row.status_message}
                                            </TableCell>
                                            <TableCell>
                                                {row.remarks}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </MuiTable>
                    </TableContainer>
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setOnBoardingLogModal(false);
                            setOnBoardingLogs([]);
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <GridContainer justify='center'>

                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{
                            transformOrigin: "0 0 0", textAlign: 'center'
                        }}
                        {...true ? { timeout: 500 } : {}}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showRewardTbale}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...true ? { timeout: 500 } : {}}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}> Rewards</h4>
                                <Button onClick={() => handleAddClick()} color="secondary" size="sm" className={classes.addButton}>
                                    Add Reward
                                </Button>
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={rewardDetails} columns={columns} options={options} />
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={openAddTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        {child}
                        {/* <AddReward back={comeBack} snack={openSnackBar} /> */}
                    </Collapse>
                </GridItem>
            </GridContainer>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    )
} 