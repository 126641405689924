import React, { useEffect, useState } from "react";
import config from "config.js"
import MUIDataTable from "mui-datatables";
import { Tooltip } from "@material-ui/core";
import { Category, Flag, Tv } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from "@material-ui/core/styles";
import ExpandSharpIcon from '@mui/icons-material/ExpandSharp';
import { Collapse } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import SweetAlert from "react-bootstrap-sweetalert";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";


//import custom components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from "components/Card/CardHeader";
import Button from 'components/CustomButtons/Button';
import SadadBillers from "./Components/SadadBillers";
import CategoryForm from "./Components/CategoryForm";
import axios from "axios";




//custom css
const useStyles = makeStyles((them) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
}));


const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;




export default function SadadCategory() {
    const classes = useStyles()
    const [tableData, setTableData] = useState([]);
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [categoryList, setCategoryList] = useState([])
    const [flag, setFlag] = useState("card")
    const [billers, setBillers] = useState(null)
    const [respAlert, setRespAlert] = React.useState(null);
    const [form, setForm] = useState()
    const [snackBarType, setSnackBarType] = React.useState("error");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [showLoader, setShowLoader] = React.useState(true)
   





    const parseCategory = async () => {
        try {
            let resp = await axios.get(`${config.url().appURL}/CXPortal/v3/view-sadad-category`)
            setShowLoader(false)
            setCategoryList(resp.data.data)

        } catch (error) {
            setShowLoader(false)
            openSnackBar('Unable to fetch Category details')
        }

    }


    useEffect(() => {
        parseCategory()

    }, [])


    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled"  {...props} />;
    }


    function openSnackBar(msg, success) {
        //function to tost snackbar message
        setOpenSnack(true);
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const handleBillers = (category) => {
        //billers button click
        setFlag("biller")
        setBillers(<SadadBillers category={category} onBack={onBackClick} categoryList={categoryList} />)
    }

    function onBackClick() {
        //back function
        setFlag('card')
        setBillers(null)
        setForm(null)
        parseCategory()

    }

    function imageClick(item) {
        //image click
        setFlag("table")
        setForm(<CategoryForm name={item.img_name} img={item.image_url} _id={item._id} onBack={onBackClick} Alert={openAlert} snack={openSnackBar}/>)

    }


    const openAlert = (type, title, text) => {
        //function to open sweet alert
        setRespAlert(
            <SweetAlert
                type={type}
                style={{ display: "block" }}
                title={title}
                onConfirm={() => {
                    setRespAlert(null);
                    onBackClick()

                }}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                {text}
            </SweetAlert>)
    }


    return (
        <>
            {respAlert}
            <Collapse
                in={showLoader} 
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
            </Collapse>
            <Collapse in={flag === 'card' && !showLoader} >
                <Card>
                    <CardHeader color="primary" >
                        <h4 className={classes.cardTitleWhite} >Billers Category</h4>
                    </CardHeader>
                    <CardBody direction="column">
                        <br /> <br /> <br />
                        <GridContainer>
                            {categoryList.map((item) => {
                                return (
                                    <GridItem xs={2}>
                                        <Card profile >
                                            <CardBody profile>
                                                <CardMedia
                                                    style={{ marginTop: "-20px" ,cursor: "pointer"}}
                                                    component="img"
                                                    height='100%'
                                                    width='100%'
                                                    image={`${item.image_url}.png`}
                                                    onClick={() => imageClick(item)}
                                                />
                                                <h5 style={{ fontWeight: "500", marginTop: "5px" }} className={classes.cardCategory}>{item.img_name}</h5>
                                                <div style={{ textAlign: "center" }} >
                                                    <Button style={{ height: "20px", background: "#144b85" }} value={item.img_name} round size="sm" onClick={() => { handleBillers(item.img_name) }} >
                                                        Billers
                                                    </Button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                )
                            })}
                        </GridContainer>
                    </CardBody>
                </Card>
            </Collapse>
            <Collapse in={flag === 'biller' && !showLoader}>

                {billers}

            </Collapse>
            <Collapse in={flag === 'table' && !showLoader}>

                {form}

            </Collapse>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}
                >
                    {snackBarMsg}
                </Alert>

            </Snackbar>
        </>

    )
}
