import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const UploadFileComponent = ({ name, handleFileUpload }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    handleFileUpload(file, name);
  };

  return (
    <>
      <input
        accept=".xlsx,.csv,.xlsb"
        id={`upload-file-${name}`}
        type="file"
        style={{ display: 'none' }}
        onChange={handleChange}
        disabled={selectedFile !== null}
      />
      <label htmlFor={`upload-file-${name}`}>
        <Button
          variant="outlined"
          color="primary"
          component="span"
          startIcon={<CloudUploadIcon />}
          disabled={selectedFile !== null}
        >
          {name}*
        </Button>
      </label>
    </>
  );
};

export default UploadFileComponent;
