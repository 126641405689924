import * as React from 'react';
import axios from "axios";
import MUIDataTable from "mui-datatables";
import Collapse from "@material-ui/core/Collapse";
import config from "config.js"
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import moment from 'moment';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import { creatCsvFile, downloadFile } from "download-csv";
import SearchIcon from '@mui/icons-material/Search';
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import { TextField } from '@material-ui/core';
import Dropzone from "react-dropzone";
import SweetAlert from "react-bootstrap-sweetalert";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import MuiTable from '@material-ui/core/Table';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TimelineIcon from '@material-ui/icons/Timeline';
import Transactions from 'views/Customers/Components/Transactions'
import Logs from "views/Customers/Components/Logs"
import * as XLSX from 'xlsx';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';






const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    dialogContainer: {
        display: "flex",
    },
    dialogContent: {
        textalign: "center",
    },
    ...modalStyle(theme),
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
export default function CardsActivationsReport({ setSheet, data, setData }) {
    let exelData;
    const classes = useStyles();
    const [showBlockListTable, setShowBlockListTable] = React.useState(false)
    const [snackBarType, setSnackBarType] = React.useState("error");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [respAlert, setRespAlert] = React.useState(null);
    const [fromDate, setFromDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
    const [toDate, setToDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
    const [cpr, setcpr] = React.useState('');
    const [blockListDetails, setBlockListDetails] = React.useState([]);
    const [searchKey, setSearchKey] = React.useState()
    const [type, setType] = React.useState("ALL")

    const [submitting, setSubmitting] = React.useState(false);
    const [csvData, setCsvData] = React.useState([]);
    const [showDetails, setShowDetails] = React.useState(false);

    const [page, setPage] = React.useState(0);

    const [circularRefNo, setCircularRefNo] = React.useState("")
    const [circularDate, setCircularDate] = React.useState("")
    const [listType, setListType] = React.useState("")
    const [listedBy, setListedBy] = React.useState("")
    const [file, setFile] = React.useState(null);
    const [inSubmit, setInSubmit] = React.useState(false);

    const [deleteBlockList, setDeleteBlockList] = React.useState("")


    const [viewBlockList, setViewBlockList] = React.useState("")
    const [viewBlockListLogModal, setViewBlockListLogModal] = React.useState("")
    const [viewBlockListModal, setViewlockListModal] = React.useState("")

    const [onBoardingLogs, setOnBoardingLogs] = React.useState([]);
    const [onBoardingLogModal, setOnBoardingLogModal] = React.useState(false);

    const [transactionModel, setTransactionModel] = React.useState(null);
    const [showTransactionsModal, setShowTransactionsModal] = React.useState(false);
    const [userID, setUserID] = React.useState('');

    const [showLogModal, setShowLogModal] = React.useState(false);
    const [logModel, setLogModel] = React.useState(null);
    const [logUserType, setLogUserType] = React.useState("");

    const [files, setFiles] = React.useState(null);
    const [mandatoryFields] = React.useState(['CPR', 'Mobile', 'Passport']);


    const [values, setValues] = React.useState({

        refNoErr: null,
        circularDateErr: null,
        listTypeErr: null,
        listedByErr: null,
    })

    const formData = new FormData()


    React.useEffect(() => {
        setValues({
            ...values,
            refNoErr: false,
            circularDateErr: false,
            listTypeErr: false,
            listedByErr: false,
        })
    }, [])


    React.useEffect(() => {
        getBlockList();
    }, []);
    const columns = [
        {
            name: "createdAt",
            label: "Date",
            options: {
                filter: true,
                sort: true,
            },

        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "cpr",
            label: "CPR",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "passport",
            label: "Passport",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "mobile",
            label: "Mobile",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "nationality",
            label: "Nationality",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "listType",
            label: "List Type",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
            },
        }
    ];
    const alertColumn = [
        { id: 'createdAt', label: 'Date', width: 70 },
        { id: 'createdAt', label: 'Time', width: 70 },
        { id: 'cpr', label: 'CPR' },
        { id: 'mobile', label: 'Mobile', minWidth: 70 },
        { id: 'status', label: 'Status', width: 70 },
        { id: 'action', label: 'Action' }
    ];

    const validateBlockListForm = () => {
        let flag = true;
        let refNoErr = false;
        let circularDateErr = false;
        let listTypeErr = false;
        let listedByErr = false;
        let snackBarMsg = "";


        if (circularRefNo.trim() === "") {
            refNoErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please enter Circular Ref no" : snackBarMsg);
            setOpenSnack(true)
            flag = false
        }
        if (circularDate.trim() === "") {
            circularDateErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please enter Circular Date" : snackBarMsg);
            setOpenSnack(true)
            flag = false
        }
        if (listType.trim() === "") {
            listTypeErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please select List Type" : snackBarMsg);
            setOpenSnack(true)
            flag = false
        }
        if (listedBy.trim() === "") {
            listedByErr = true;
            snackBarMsg = (snackBarMsg === "" ? "Please select Listed By" : snackBarMsg);
            setOpenSnack(true)
            flag = false
        }
        setValues({
            ...values,
            refNoErr: false,
            circularDateErr: false,
            listTypeErr: false,
            listedByErr: false,
        })
        if (values.refNoErr == true || values.circularDateErr == true || values.listTypeErr == true || values.listedByErr == true) {
            flag = false;
        }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag;

    }


    const handleFileUploaded = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setFiles(acceptedFiles[0]);
            validateExcelFile(acceptedFiles[0]);
        }
    };

    const handleDeleteFile = () => {
        setFile(null);
    };


    const validateExcelFile = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const excelData = XLSX.utils.sheet_to_json(worksheet);
            checkMandatoryFields(excelData);
        };
        reader.readAsArrayBuffer(file);
    };

    const checkMandatoryFields = (excelData) => {
        const missingFields = mandatoryFields.filter(field => !excelData[0]?.hasOwnProperty(field));

        if (missingFields.length > 0) {
            const snackBarMsg = `Mandatory field(s) ${missingFields.join(', ')} missing in the Excel file.`;
            setSnackBarMsg(snackBarMsg);
            setSnackBarMsg(true);
        } else {
            setSnackBarMsg('');
        }
    };

    const handleSnackBarClose = () => {
        // setSnackBarOpen(false);
    };

    const options = {
        selectableRows: false,
        elevation: 0,
        print: false,
        onDownload: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = "BlockList/WatchList Report.csv";
            downloadFile(csvFile, fileName);
            return false;
        },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name == "circularRefNo") {
            setCircularRefNo(value)
        } else if (name == "circularDate") {
            setCircularDate(value)
        } else if (name == "listType") {
            setListType(value)
        } else if (name == "listedBy") {
            setListedBy(value)
        }
    };

    const openAddList = () => {
        setShowDetails(true)
    }

    const closeModal = () => {
        setShowDetails(false)
    }


    const openTransactionsModel = (cpr, id) => {
        setTransactionModel(<Transactions userID={id} cpr={cpr} />)
        setUserID(id)
        console.log(id, "id")
        setShowTransactionsModal(true)

    }

    const closeTransactionModal = () => {
        setShowTransactionsModal(false);
        setTransactionModel(<Transactions userID={null} cpr={null} />)
    }

    const openLogModal = (id, userType) => {
        console.log(id, 'id');
        setLogModel(<Logs userID={id} userType={userType} />)
        setUserID(id)
        setShowLogModal(true);
        setLogUserType(userType)
    }

    const closeLogModal = () => {
        setShowLogModal(false);
        setLogModel(<Logs userID={null} userType={null} />)
    }

    // ---------------------- CREATE BLOCK-LIST API ----------------------------

    const handleSubmit = () => {
        if (validateBlockListForm()) {
            setInSubmit(true);
            formData.append("refNo", circularRefNo);
            formData.append("circularDate", circularDate);
            formData.append("listType", listType);
            formData.append("listedBy", listedBy);
            formData.append("file", file);
            console.log("FormData:", formData);

            axios.post(`${config.url().baseURL}/create-new-blocklist`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then((resp) => {
                    let respData = resp.data;
                    if (!respData.err) {
                        setRespAlert(
                            <SweetAlert
                                success
                                style={{ display: "block" }}
                                title={respData.msg}
                                onConfirm={() => {
                                    setRespAlert("");
                                    setFile('')
                                    getBlockList();
                                    closeModal();
                                }}
                                confirmBtnCssClass={classes.button + " " + classes.success}
                            />
                        );
                    }
                    else {
                        setRespAlert(
                            <SweetAlert
                                warning
                                style={{ display: "block" }}
                                title={respData.msg}
                                onConfirm={() => {
                                    setRespAlert("");
                                    setFile('')
                                    getBlockList();
                                    closeModal();
                                    setCircularDate("");
                                    setCircularRefNo("");
                                    setListType("");
                                    setListedBy("");
                                }}
                                confirmBtnCssClass={classes.button + " " + classes.warning}
                            />
                        );


                    }
                })
                .catch(error => {
                    console.error("Error:", error);


                });
        }
    }

    const validateSearch = () => {
        let snackBarMsg = ""
        let flag = true
        if (fromDate.length == 0) {
            flag = false
            snackBarMsg = "Please select from date."
        }
        if (toDate.length == 0) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select To date." : snackBarMsg);
        }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }
    function openSnackBar(msg, success) {

        setOpenSnack(true);
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const getBlockList = () => {
        try {
            if (validateSearch()) {
                let data = {
                    "type": type,
                    "fromDate": moment(fromDate).format("MM-DD-YYYY"),
                    "toDate": moment(toDate).format("MM-DD-YYYY"),
                    "cpr": "",
                }
                setSubmitting(true);
                setBlockListDetails([]);
                setShowBlockListTable(false);
                axios.post(`${config.url().baseURL}/get-blocklist-users`, data)
                    .then((resp) => {
                        setShowBlockListTable(true)
                        let resData = resp.data;

                        if (resData.err) {
                            openSnackBar(resp.data.msg)
                        }
                        else {
                            let updatedActivationDetails = []
                            resData.data.map((activations) => {
                                activations.createdAt = moment(activations.createdAt ? activations.createdAt : "").format('DD-MM-YY, h:mm:ss a');
                                activations.name = activations.name ? activations.name : ""
                                activations.cpr = activations.cpr ? activations.cpr : ""
                                activations.passport = activations.passport ? activations.passport : ""
                                activations.mobile = activations.mobile ? activations.mobile : ""
                                activations.nationality = activations.nationality ? activations.nationality : ""
                                activations.listType = activations.listType ? activations.listType : ""
                                updatedActivationDetails.push(activations);
                                activations.actions = (

                                    <>
                                        <Tooltip placement='top' title="View Details">
                                            <IconButton
                                                onClick={() => blockListView(activations.cpr)}
                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                aria-label="view">
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip placement='top' title="Delete">
                                            <IconButton
                                                onClick={() => msgDeleteBlockList(activations.cpr)}
                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                aria-label="view">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>

                                )

                            })
                            setBlockListDetails(updatedActivationDetails)
                            var downloadedData = resp.data.data.map((activations) => {
                                let temp = {};
                                temp.date = activations.createdAt;
                                temp.name = activations.name;
                                temp.cpr = activations.cpr;
                                temp.passport = activations.passport;
                                temp.mobile = activations.mobile;
                                temp.nationality = activations.nationality;
                                temp.listType = activations.listType
                                return temp;
                            })
                            setCsvData(downloadedData);
                        }
                    }).catch((err) => {
                        openSnackBar('Unable To Process !!')
                    })
            }
        } catch (error) {
            openSnackBar("Unable to fetch Activation Report.")
        }
    }

    const handleFileUpload = (droppedFiles) => {
        const allowedFormats = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];

        if (droppedFiles.length === 0) {
            console.error("No file dropped.");
            return;
        }

        const file = droppedFiles[0];
        const fileType = file.type;

        if (!allowedFormats.includes(fileType)) {
            console.error("Unsupported file format.");
            return;
        }
        setPage(0);
        setFile(file);
    };


    const searchCustomer = () => {
        if (searchKey.length !== 0) {
            let data = {
                searchKey
            }
            axios.post(`${config.url().baseURL}/get-blocklist-users`, data).then((resp) => {
                let respData = resp.data;
                if (!respData.err) {

                    let updatedActivationDetails = []
                    let temp = {}
                    temp.name = respData.data[0].name;
                    temp.cpr = respData.data[0].cpr;
                    temp.mobile = respData.data[0].mobile;
                    temp.createdAt = moment(respData.data[0].createdAt).format("MM-DD-YYYY, h:mm:ss a");
                    temp.updatedAt = moment(respData.data[0].updatedAt).format("MM-DD-YYYY, h:mm:ss a");
                    temp.nationality = respData.data[0].nationality;
                    temp.listType = respData.data[0].listType;
                    temp.passport = respData.data[0].passport;
                    updatedActivationDetails.push(temp);
                    temp.actions = (
                        <>
                            <Tooltip placement='top' title="View Details">
                                <IconButton
                                    onClick={() => blockListView(temp.cpr)}
                                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                    aria-label="view">
                                    <VisibilityIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip placement='top' title="Delete">
                                <IconButton
                                    onClick={() => msgDeleteBlockList(temp.cpr)}
                                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                    aria-label="view">
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    )

                    setBlockListDetails(updatedActivationDetails)
                } else {
                    setBlockListDetails([]);
                    openSnackBar(respData.msg);
                }
            }).catch((err) => {
                console.log(err, "Error occured at 596")
                setBlockListDetails([]);
                getBlockList()
                openSnackBar("Unable to process");

            });
        }
    }

    const blockListView = (cpr) => {
        try {
            let data = {
                cpr: cpr
            };
            axios.post(`${config.url().baseURL}/get-blocklist-user-details`, data)

                .then((resp) => {
                    let respData = resp.data
                    if (!respData.err) {

                        setViewBlockList(respData);
                        setViewBlockListLogModal(true);
                    } else {
                        console.error("Block List :", respData.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    const msgDeleteBlockList = (cpr) => {
        setRespAlert(
            <SweetAlert
                warning
                style={{ display: "block" }}
                title="Are you sure to delete this user ?"
                onConfirm={() => {
                    blockListDelete(cpr)
                }}
                onCancel={() => {
                    setRespAlert(null)
                }}
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                cancelBtnCssClass={classes.button}
                confirmBtnCssClass={classes.button + " " + classes.danger} api
            >

            </SweetAlert>
        )
    }

    const blockListDelete = (cpr) => {
        try {
            let data = {
                cpr: cpr
            };
            axios.post(`${config.url().baseURL}/delete-blocklisted-user`, data)

                .then((resp) => {
                    let respData = resp.data
                    setRespAlert(
                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title={respData.msg}
                            onConfirm={() => {
                                setRespAlert("");
                                getBlockList();
                                closeModal();
                            }}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                    );
                    if (!respData.err) {
                        setDeleteBlockList(respData);
                    } else {
                        console.error("Block List :", respData.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };



    const fetchAlertBlockList = (pageNo) => {
        setOnBoardingLogModal(true);
        axios.get(`${config.url().baseURL}/get-blocklist-users-onboard-logs/${pageNo}`).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                if (respData.data[0] !== undefined) {
                    setOnBoardingLogs(respData.data);
                } else {
                    setOnBoardingLogModal(false);
                    openSnackBar("No log found.");
                }

            } else {
                setOnBoardingLogModal(false);
                openSnackBar(respData.msg);
            }
        });
    };

    const handleDownload = () => {
        window.location.href = "https://s3.me-south-1.amazonaws.com/bfcp-cxportal-alb-uat.bfcpayments.com/blockListing.xlsx";
    }

    return (
        <>
            {respAlert}
            <div style={{ marginBottom: '25px' }}>
                <Collapse in={showBlockListTable} style={{ transformOrigin: "0 0 0", textAlign: "center" }} {...(true ? { timeout: 500 } : {})}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={6} autoComplete="off">
                            <Paper elevation={1} className={classes.addDeptContainer}>
                                <InputBase
                                    type='search'
                                    fullWidth
                                    className={classes.input}
                                    placeholder="Search"
                                    inputProps={{
                                        "aria-label": "Search",
                                        autoComplete: "off",
                                        autoFocus: "off",
                                        onChange: (event) => { setSearchKey(event.target.value) },
                                        onBlur: (event) => { setSearchKey(event.target.value.trim()) },
                                        value: searchKey,
                                        onKeyDown: (e) => {
                                            if (e.key == "Enter") {
                                                searchCustomer()
                                            }
                                        }
                                    }}
                                />
                                <Button className={classes.addDeptButton}
                                    onClick={() => searchCustomer()}
                                    size="lg" color="primary">
                                    <SearchIcon fontSize="medium" />
                                </Button>

                            </Paper>
                        </GridItem>
                    </GridContainer>
                </Collapse>

            </div>


            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} >
                    <Collapse
                        in={true}
                        style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>From Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setFromDate(event.target.value) }, onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setFromDate("")
                                                    openSnackBar("Please enter valid date.")
                                                } else {
                                                    setFromDate(event.target.value)
                                                }
                                            },
                                            value: fromDate,
                                        }}
                                    />
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>To Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setToDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setToDate("")
                                                    openSnackBar("Please enter valid date.")
                                                } else {
                                                    setToDate(event.target.value)
                                                }
                                            }, value: toDate,
                                        }}
                                    />
                                </Paper>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>Type</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <FormControl className={classes.groupSelect} fullWidth>
                                        <Select
                                            id="group-select"
                                            value={type}
                                            disableUnderline
                                            onChange={(e) => {
                                                setType(e.target.value);
                                            }}>
                                            <MenuItem value="ALL">ALL</MenuItem>
                                            <MenuItem value="BLOCKLIST">BLOCK LIST</MenuItem>
                                            <MenuItem value="WATCHLIST">WATCH LIST</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <Button className={classes.addDeptButton}
                                        onClick={() => getBlockList()}
                                        size="lg" color="primary">
                                        <SearchIcon fontSize="medium" />
                                    </Button>
                                </Paper>

                            </GridItem>



                            {/* -------------ADDLIST BUTTON----------------- */}
                            <div >
                                <Chip onClick={() => openAddList()} style={{ backgroundColor: '#004fa4', color: 'white', marginTop: '30px', marginLeft: '30px' }} size='sm' label="+ ADD LIST" />
                            </div>


                            <Tooltip placement='top' title="Notification">
                                <IconButton
                                    onClick={() => fetchAlertBlockList(0)}
                                    style={{ marginTop: "20px", marginBottom: "10px" }}
                                    aria-label="view">
                                    <NotificationsOutlinedIcon style={{ fontSize: "30px" }} />
                                </IconButton>
                            </Tooltip>

                        </GridContainer>
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        // in={showLoader}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showBlockListTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Watch List</h4>
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={blockListDetails} columns={columns} options={options} />
                                {console.log(blockListDetails, "202")}
                            </CardBody>
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>

            {/*--------------- ADD LIST DIALOG ---------------*/}

            <Dialog
                open={showDetails}
                onClose={() => {
                    setShowDetails(false)
                    setFile('')
                }}
                TransitionComponent={Transition}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography >
                    <h5 style={{ fontFamily: 'serif', marginLeft: '130px' }}>ADD LIST</h5>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <form style={{ maxWidth: '700px', marginLeft: '30px', marginRight: '30px', marginBottom: '30px' }} >
                        <div>
                            <label>Circular Ref No:</label>
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="circularRefNo"
                                error={values.refNoErr}
                                value={formData.circularRefNo}
                                onChange={(e) => {
                                    setValues({
                                        ...values,
                                        refNoErr: false,
                                    });
                                    handleChange(e);
                                }}
                            />
                        </div>
                        <div>
                            <label>Circular Date:</label>
                            <TextField
                                type='date'
                                fullWidth
                                variant="outlined"
                                name="circularDate"
                                error={values.circularDateErr}
                                value={formData.circularDate}
                                onChange={(e) => {
                                    setValues({
                                        ...values,
                                        circularDateErr: false,
                                    });
                                    handleChange(e)
                                }}
                                inputProps={{
                                    max: new Date().toISOString().split('T')[0] 
                                }}
                            />
                        </div>

                        <div>
                            <label>List Type:</label>
                            <Select
                                fullWidth
                                variant="outlined"
                                name="listType"
                                error={values.listTypeErr}
                                value={formData.listType}
                                onChange={(e) => {
                                    setValues({
                                        ...values,
                                        listTypeErr: false,
                                    })
                                    handleChange(e)
                                }}
                            >
                                <MenuItem value="WATCHLIST">WATCHLIST</MenuItem>
                                <MenuItem value="BLOCKLIST">BLOCKLIST</MenuItem>
                            </Select>
                        </div>
                        <div>
                            <label>Listed By:</label>
                            <Select
                                fullWidth
                                variant="outlined"
                                name="listedBy"
                                error={values.listedByErr}
                                value={formData.listedBy}
                                onChange={(e) => {
                                    setValues({
                                        ...values,
                                        listedByErr: false,
                                    })
                                    handleChange(e)
                                }}
                            >
                                <MenuItem value="CBB">CBB</MenuItem>
                                <MenuItem value="InternalAudit">Internal Audit</MenuItem>
                                <MenuItem value="InternationalList">International List</MenuItem>

                            </Select>


                        </div>
                    </form>

                </DialogContent>
                <DialogActions style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {file && (
                        <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                            <div style={{ fontSize: "18px", fontFamily: 'serif' }}>
                                upload file : {file.name}
                            </div>
                            <DeleteForeverIcon onClick={handleDeleteFile} style={{ cursor: 'pointer' }} />
                        </div>
                    )}
                    <div style={{ display: 'flex' }}>
                        <Button
                            onClick={handleSubmit}
                            color="success"
                            style={{ borderRadius: "11px" }}
                        >
                            Submit
                        </Button>
                        <Dropzone onDrop={handleFileUpload}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Button
                                        component="label"
                                        style={{ borderRadius: "11px", backgroundColor: "#1a599c", maxWidth: "100px" }}
                                        htmlFor="upload-document"
                                        startIcon={<FileUploadOutlinedIcon />}
                                    >
                                        Upload
                                    </Button>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                    <Tooltip placement='top' title="Samble Download">
                        <IconButton
                            onClick={handleDownload}
                            aria-label="view">
                            <CloudDownloadIcon />
                        </IconButton>
                    </Tooltip>


                    <Snackbar autoHideDuration={6000} onClose={handleSnackBarClose}>
                        <MuiAlert onClose={handleSnackBarClose} severity="error" elevation={6} variant="filled">
                            {snackBarMsg}
                        </MuiAlert>
                    </Snackbar>
                </DialogActions>
            </Dialog >


            {/* -------------------USER VIEW MODAL------------------------- */}

            <Dialog
                open={viewBlockListLogModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setViewBlockListLogModal(false);
                    setViewlockListModal([]);
                }}
                maxWidth="md"
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle style={{ justifyContent: 'center', textAlign: 'center', height: '1px', width: '300px' }}>
                    {/* <h4 style={{ fontFamily: 'serif',marginTop:'40px' }}>user view</h4> */}
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}    >
                    <p><strong>Circular Ref No - </strong><strong>{viewBlockList?.data?.listReference?.circularRefNo}</strong></p>
                    <p><strong>Circular Date - </strong><strong>{moment(viewBlockList?.data?.listReference?.circularDate).format("DD-MM-YYYY")}</strong></p>
                    <p><strong>List Type - </strong><strong>{viewBlockList?.data?.listReference?.listType}</strong></p>
                    <p><strong>Listed By - </strong><strong>{viewBlockList?.data?.listReference?.listedBy}</strong></p>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                        onClick={() => {
                            setViewBlockListLogModal(false);
                            setViewlockListModal([]);
                        }}
                        color="success"
                        size="sm"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>


            {/*--------------------------- NOTIFICATION MODAL---------------------- */}

            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.companyStatusLogModal,
                }}
                open={onBoardingLogModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setOnBoardingLogModal(false);
                    setOnBoardingLogs([]);
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 style={{ textAlign: "center", fontFamily: "serif" }}>BlockList/WatchList Notification</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    {/* Wrap TableContainer with a div to enable scrolling */}
                    <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                        <TableContainer className={classes.container}>
                            <MuiTable stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {alertColumn.map((column) => (
                                            <TableCell
                                                key={column._id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {onBoardingLogs.map((onBoardingLogs, key) => {
                                        console.log(onBoardingLogs, "404");
                                        return (

                                            <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                                                <TableCell>
                                                    {moment(onBoardingLogs.createdAt).format("DD/MM/YYYY")}
                                                </TableCell>
                                                <TableCell>
                                                    {moment(onBoardingLogs.createdAt).format("HH:mm:ss")}
                                                </TableCell>
                                                <TableCell>
                                                    {onBoardingLogs.cpr}
                                                </TableCell>
                                                <TableCell>
                                                    {onBoardingLogs.mobile}
                                                </TableCell>
                                                <TableCell>
                                                    {onBoardingLogs.status}
                                                </TableCell>
                                                <TableCell>
                                                    {onBoardingLogs.status !== "BLOCKLIST" && (
                                                        <Tooltip placement='top' title="Transaction">
                                                            <IconButton
                                                                onClick={() => openTransactionsModel(onBoardingLogs.cpr, onBoardingLogs.userID)}
                                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                                aria-label="view">
                                                                <ReceiptIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}

                                                    {onBoardingLogs.status !== "BLOCKLIST" && (
                                                        <Tooltip placement='top' title="Log">
                                                            <IconButton
                                                                onClick={() => openLogModal(onBoardingLogs.userID)}
                                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                                aria-label="view">
                                                                <TimelineIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </MuiTable>
                        </TableContainer>
                    </div>
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setOnBoardingLogModal(false);
                            setOnBoardingLogs([]);
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* -------------------TRANSACTION MODAL---------------------- */}

            <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.transactionsModal, }}
                open={showTransactionsModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    closeTransactionModal()
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
                    {transactionModel}
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            closeTransactionModal()
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* -------------------------LOG MODAL------------------------------- */}

            <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.companyLogModal, }}
                open={showLogModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    closeLogModal()
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
                    {logModel}
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            closeLogModal()
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>


            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </>
    );
}