import React from 'react';
import PropagateLoader from "react-spinners/PropagateLoader";
import axios from "axios";
import config from "config.js"
import moment from 'moment';

import { css } from "@emotion/core";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";


import DocumentList from "./Components/DocumentList.js"
const override = css`
display: block;
margin: 0px;
border-color: red;
`;

const columns = [
    {
        field: "date",
        title: "Created On",
        render: rowData => moment(rowData.date).format("DD/MM/YYYY") != "Invalid date" ? <span> {moment(rowData.date).format("DD/MM/YYYY") + " " + moment(rowData.date).format("hh:mm:ss A")}</span> : rowData.date,
    },
    {
        field: "companyName",
        title: "Corporate Name",
    },
    {
        field: "companyCR",
        title: "Company ID",
    },
    {
        field: "kycApplicationID",
        title: "KYC Application ID",
    },
];

const options = {
    showTitle: false,
    actionsColumnIndex: -1,
    exportButton: false,
};



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function CorporateDocuments() {

    const [corporateList, setCorporateList] = React.useState([]);

    const [snackBarType, setSnackBarType] = React.useState("error");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [respAlert, setRespAlert] = React.useState(null);

    const [view, setView] = React.useState(0); //0:-Loader, 1:-Corporate List, 2:-DocumentList
    const [documentListView, setDocumentListView] = React.useState(null)
    function openSnackBar(msg, success) {
        //function to tost snackbar message
        setOpenSnack(true);
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const actions = [
        {
            icon: "list_lt_icon",
            tooltip: 'Show Document List',
            onClick: (event, rowData) => openDocumentList(rowData),
        },
    ]


    const fetchCorporateList = async () => {
        //function to fetch corporate list 
        try {
            let resp = await axios.get(`${config.url().baseURL}/get-document-company-list`)
            setView(1)
            if (resp.data.err) openSnackBar(resp.data.msg)
            else {
                setCorporateList(resp.data.data)
            }
        } catch (error) {
            setView(0)
            openSnackBar("Unable to fetch corporate list.")
        }
    }
    React.useEffect(() => { fetchCorporateList() }, [])

    const openDocumentList = async (company) => {
        //function to open document list
        setView(2)
        setDocumentListView(<DocumentList snack={openSnackBar} company={company} back={back} />)
    }

    const back = () => {
        //function to load home view
        setView(1)
        setDocumentListView(null)
        fetchCorporateList()
    }
    return (
        <>
            {respAlert}
            <Collapse in={view == 0} style={{ transformOrigin: "0 0 0", textAlign: "center" }} {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
            </Collapse>
            <Collapse in={view == 1} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 500 } : {})}>
                <Card>
                    <CardBody>
                        <MaterialTable actions={actions} components={{ Container: (props) => <Paper {...props} elevation={0} />, }} data={corporateList} columns={columns} options={options} />
                    </CardBody>
                </Card>
            </Collapse>
            <Collapse in={view == 2} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 500 } : {})}>
                {documentListView}
            </Collapse>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity={snackBarType}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </>
    );
}