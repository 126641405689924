import React, { useEffect, useState } from "react";
import {
    TextField,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Select,
    MenuItem,
    Modal,
    Tooltip,
    CircularProgress,
    InputLabel,
} from "@material-ui/core";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { css } from "@emotion/core";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Divider, Stack } from "@mui/material";
import config from "config";
import axios from "axios";
import Dropzone from "react-dropzone";


import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SweetAlert from "react-bootstrap-sweetalert";
import FileUploadBfc from "./FileUploadBfc";
import { TrendingUp } from "@material-ui/icons";
import { PropagateLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 40px auto;
  border-color: red;
`;

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        // alignItems: 'center',
        padding: theme.spacing(2),
    },
    sectionContainer: {
        marginBottom: theme.spacing(2),
    },
    fileInput: {
        display: "none",
    },
    uploadButton: {
        marginRight: theme.spacing(2),
    },
    submitButton: {
        marginTop: theme.spacing(2),
    },
}));
const ProductForm = ({ back, snack, alertPop, type }) => {
    const classes = useStyles();
    const documentsName = ["CR *", "VAT *", "AGGREMENT *", "ADDITIONAL"];
    const [billerName, setBillerName] = useState("");
    const [category, setCategory] = useState();
    const [tranCode, settranCode] = useState(type);
    const [isProducts, setisProducts] = useState(true);
    const [productCode, setProductCode] = useState("");
    const [productType, setProductType] = useState("");
    const [house, sethouse] = useState("");
    const [road, setroad] = useState("");
    const [isFileUpload, setisFileUpload] = useState(true);
    const [block, setblock] = useState("");
    const [area, setarea] = useState("");
    const [country, setcountry] = useState("Bahrain");
    const [uploadedFile, setUploadedFile] = useState();
    const [open, setOpen] = useState("");
    const [recordName, setRecordName] = useState("");
    const [respAlert, setRespAlert] = React.useState(null);
    const [fileName, setFileName] = React.useState("");
    // const [cr, setCR] = React.useState("");
    // const [vat, setVat] = React.useState("");
    // const [aggrement, setAggrement] = React.useState("");
    // const [additional, setAdditional] = React.useState("");
    const [crNumber, setCrNumber] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);

    const [docs, setDocs] = React.useState({});


    let categoryList = ['Telecom', 'Schools', 'Credit Card', 'Government Services', 'Gift Cards', 'Broadband', 'Investment', 'Calling Card', 'Pay Rent']

    const uploadNewStudentList = async (doc, name) => {
        // if (/\.(xlsx|xls|csv)$/i.test(doc[0].name) === true) {
        if (doc[0].size < 2097152) {
            setRespAlert(
                <SweetAlert
                    warning
                    input
                    style={{ display: "block" }}
                    title="Are you sure?"
                    onConfirm={() => {
                        setUploadedFile(doc[0].name)
                        submitDoc(doc[0], name);
                    }}
                    onCancel={() => {

                        setRespAlert(null);
                        setUploadedFile("")

                    }}
                    Data
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    cancelBtnCssClass={classes.button}
                    confirmBtnCssClass={classes.button + " " + classes.danger}
                >
                    Are You Sure Want To Upload
                </SweetAlert>
            );
        } else {
            snack("File size is too large, Maximum file size is 2 Mb.");
        }
        // } else {
        //     snack("Please upload xlx files.");
        // }

        //function to upload excel file
    };

    //   const handleUploadDoc = async () => {
    //     try {
    //         formData.append("file", uploadedFile)

    //         const resp = await axios.post(`${config.url().wallet_URL}/upload-file`, formData)
    //         console.log(resp.data.data);
    //         if (fileName === 'Licence') {
    //             setLicence(resp.data.data.filePath.Location)
    //             console.log(licence);
    //         } else if (fileName === 'CR') {
    //             setCr(resp.data.data.filePath.Location)
    //         } else if (fileName === 'MOI') {
    //             setMoi(resp.data.data.filePath.Location)
    //         } else if (fileName === 'Aggrement') {
    //             setAggrement(resp.data.data.filePath.Location)

    //         }

    //     } catch (err) {
    //         console.log(err);
    //     }
    // }

    const submitDoc = async (doc, name) => {
        //function to upload doc to the server
        setRespAlert(null);
        let requestBody = new FormData();
        requestBody.set("file", doc);

        try {
            // setListView(0)
            let resp = await axios.post(
                `${config.url().wallet_URL}/upload-file`,
                requestBody
            );
            if (resp.data.err) {
                // setListView(1)
                snack(resp.data.msg);
                // setListView(1)
                setOpen(false);
                setRecordName(false);
            } else {
                console.log(name);

                // "CR", "VAT", "AGGREMENT", "ADDITIONAL"
                // if (name === "CR" || name === "VAT" || name === "AGGREMENT") {
                docs[name] = resp.data.data.filePath.Location;
                //   } else {
                //     docs["ADDITIONAL"] = resp.data.data.filePath.Location;
                //   }
                snack(resp.data.msg, true);
                setOpen(false);
                setRecordName(false);
            }
        } catch (error) {
            console.log(error);
            snack("Unable to upload.");
            setOpen(false);
            setRecordName(false);
            //    fetchStudents(props.id)
        }
    };

    const handleGenarateProductCode = async () => {
        try {
            const resp = await axios.get(`${config.url().app_URL}/bill/generate-code`)
            if (resp.data.status.err) {
                snack(resp.data.status.message)

            } else {
                setProductCode(resp.data.data.productCode)
            }
        } catch (err) {
            console.log(err);
            snack("Something Went Wrong Try Again")
        }
    }
    const handleSubmit = async (e) => {
        try {
            setIsLoading(true)
            e.preventDefault();

            // Perform form submission or data handling here
            let data = {
                data: {
                    billerName,
                    category,
                    productCode,
                    productType: productType,
                    isProducts,
                    isFileUpload,
                    type: tranCode,
                },
                address: {
                    block,
                    country,
                    road,
                    house,
                    crNumber
                },

                docs
            };

            let check = await Object.keys(data.data).some((key) => {
                if (["billerName", "tranCode", "category"].includes(key)) {
                    const value = data.data[key];
                    return value === null || value === undefined || value === "";
                } else {
                    return false;
                }
            });

            let checkFile =true
            // docs.hasOwnProperty('CR') && docs.hasOwnProperty('VAT') && docs.hasOwnProperty('AGGREMENT');

            if (!checkFile || check) {
                snack(`${check ? 'Please fill all the details.' : 'Please Upload All Required File'}`);
            } else {
                const resp = await axios.post(
                    `${config.url().app_URL}/bill/create-category`,
                    data
                );
                if (resp.data.status.err) {
                    snack(resp.data.message);
                    setBillerName("");
                    settranCode("");
                    setisProducts(false);
                    setProductCode("");
                    setProductType("");
                    sethouse("");
                    setroad("");
                    setblock("");
                    setarea("");
                    setcountry("");
                } else {
                    alertPop("success", "Biller data saved", resp.data.msg);
                }
            }
            // Reset the form
        } catch (error) {
            console.log(error);
            snack(error.message);
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => { handleGenarateProductCode() }, [])

    let renderedFileupload = documentsName.map((item) => (
        <div>
            <FileUploadBfc name={item} uploadNewStudentList={uploadNewStudentList} fileName={uploadedFile} />
        </div>
    ));

    return (
        <Card>
            {respAlert}
            <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>BFC Biller</h4>
            </CardHeader>
            <CardBody style={{ padding: 50 }}>
                <Grid xs={12}>
                    <GridItem xs={4}>
                        <TextField
                            label="Biller Name *"
                            value={billerName}
                            onChange={(e) => setBillerName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </GridItem>
                    <GridItem xs={4}>
                        <TextField
                            label="Tran Code *"
                            value={tranCode}
                            onChange={(e) => settranCode(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </GridItem>


                    <GridItem xs={4}>
                        <>
                            <InputLabel id="productType" style={{ paddingTop: "10px" }}>Category *</InputLabel>

                            <Select
                                  style={{ marginTop: 10 }}
                                fullWidth
                                labelId="category"
                                value={category}
                                label="Product category"
                                onChange={(e) => {
                                    setCategory(e.target.value);
                                }}
                            >
                                {categoryList.map((item) => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                               
                            </Select>
                        </>
                    </GridItem>

                    <GridItem xs={4}>
                        <TextField
                            label="CR Number"
                            value={crNumber}
                            onChange={(e) => setCrNumber(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </GridItem>

                    {/* <GridItem item xs={4}>
                        {!isProducts && (

                            <TextField
                                label="Product Code *"
                                value={productCode}
                                disabled={true}
                                onChange={(e) => setProductCode(e.target.value)}
                                fullWidth
                                margin="normal"
                            // InputProps={{
                            //     endAdornment: (
                            //         <InputAdornment position="end">

                            //             <Tooltip title="Generate Product Code">
                            //                 <BorderColorIcon onClick={handleGenarateProductCode} />
                            //             </Tooltip>
                            //         </InputAdornment>
                            //     ),
                            // }}
                            />

                        )}
                    </GridItem> */}
                    <GridItem item xs={4}>
                   
                            <>
                                <InputLabel id="productType" style={{ paddingTop: "10px" }}>Options *</InputLabel>

                                <Select
                                    style={{ marginTop: 10 }}
                                    fullWidth
                                    labelId="productType"
                                    value={productType}
                                    label="Product tranCode"
                                    onChange={(e) => {
                                        setProductType(e.target.value);
                                    }}
                                >
                                    <MenuItem key={"Voucher"} value={"Voucher"}>
                                        Voucher
                                    </MenuItem>
                                    <MenuItem key={"Wultiple"} value={"Wultiple"}>
                                        Bill with Balance Fetch
                                    </MenuItem>
                                    <MenuItem key={"BillPay"} value={"BillPay"}>
                                        Bill with out Balance Fetch
                                    </MenuItem>
                                </Select>
                            </>
                        
                    </GridItem>
                </Grid>

                <div className={classes.sectionContainer}>
                    <h4 style={{ fontWeight: "bold" }}>Basic Address Details</h4>
                    <Divider
                        sx={{ margin: "10px 0", backgroundColor: "black", height: 1 }}
                    />
                    <Grid spacing={2}>
                        <GridItem xs={4}>
                            <TextField
                                label=" Flat no/Building no"
                                value={house}
                                onChange={(e) => sethouse(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </GridItem>
                        <GridItem xs={4}>
                            <TextField
                                label="Road"
                                value={road}
                                onChange={(e) => setroad(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </GridItem>
                        <GridItem xs={4}>
                            <TextField
                                label="Block"
                                value={block}
                                onChange={(e) => setblock(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </GridItem>
                        <GridItem xs={4}>
                            <TextField
                                label="Area"
                                value={area}
                                onChange={(e) => setarea(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </GridItem>
                        <GridItem xs={4}>
                            <TextField
                                label="Country"
                                value={country}
                                onChange={(e) => setcountry(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </GridItem>
                    </Grid>
                </div>

                {/* <div className={classes.sectionContainer}>
                    <h4 style={{ fontWeight: "bold" }}>Document Uploads </h4>
                    <p>Document Size Is Max of 2MB</p>
                    <Divider
                        sx={{ margin: "10px 0", backgroundColor: "black", height: 1 }}
                    />
                    <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                        {renderedFileupload}
                    </div>
                </div> */}
                <Divider
                    sx={{ margin: "10px 0", backgroundColor: "black", height: 1 }}
                />

                <div style={{ justifyContent: "center", marginLeft: 600, padding: 10 }}>
                    {!isLoading ? (
                        <>
                            <Button
                                tranCode="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submitButton}
                                onClick={handleSubmit}
                            >
                                Submit

                            </Button>
                            <Button
                                tranCode="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submitButton}
                                onClick={back}
                            >
                                Back
                            </Button>
                        </>
                    ) : (

                        <PropagateLoader color="#004fa4" loading={true} css={override} size={20}  />

                    )}

                </div>
            </CardBody>
        </Card>
    );
};

export default ProductForm;
