import * as React from 'react';
import axios from "axios";
import config from "config.js"
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import styles from "assets/jss/material-dashboard-react/views/customersStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import download from "js-file-download";
import DownloadIcon from '@mui/icons-material/Download';
import Chip from "@material-ui/core/Chip";
import libs from 'controllers/libs';
import { Collapse } from '@material-ui/core';


const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;

const customStyles = {
    overlay: {
        zIndex: "2147483647"
    }
}

const useStyles = makeStyles(styles);
export default function ShuftiDetails(props) {
    const classes = useStyles();

    const [shuftiData, setShuftiData] = React.useState([]);
    const [documentViewerData, setDocumentViewerData] = React.useState("");
    const [openImageViewer, setOpenImageViewer] = React.useState(false);
    const [loader, setLoader] = React.useState(true);
    const [shuftiFaceValue, setShuftiFaceValue] = React.useState("");

    React.useEffect(() => {
        if (props.shuftiRef !== null) {
            fetchShuftiData(props.cpr)
        }
    }, [props.shuftiRef]);
    React.useEffect(() => {
        if (loader) {
            setTimeout(() => {
                setLoader(false);
            }, 3000);
        }
        return () => clearTimeout();
    }, [loader]);

    const fetchShuftiData = async (cpr) => {
        //function to fetch shufti data
        try {
            let respData = (await axios.get(`${config.url().baseURL}/get-user-docs/${cpr}`)).data
            console.log(respData)
            if (!respData.data.err) {
                setShuftiData(respData.data)
            } else {
                setShuftiData([]);
                setLoader(false);
                props.snack(respData.data.msg);
                props.back()
                setLoader(false);
            }
        } catch (error) {
            console.log(error)
            setShuftiData([]);
            setLoader(false);
            props.snack("Unable fetch shufti data.");
            props.back()

        }
    };

    const selectDocument = (doc) => {
        //function to set doc data to image viewer
        setLoader(false);
        setOpenImageViewer(true)
        setDocumentViewerData(doc)
    }

    const closeDetails = () => {
        //function to go back
        setShuftiData([])
        props.back()
    }

    const downloadDoc = async (doc, name) => {
        let respData = await axios.get(doc, { responseType: "blob", })
        console.log(respData)
        setLoader(false);
        download(respData.data, name)
    }

    return (
        <>
            <GridContainer>
                {shuftiData ? (
                    <>
                        <GridItem xs={12} sm={12} md={12}>
                            <Collapse
                                in={loader}
                                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                                {...(true ? { timeout: 500 } : {})}
                                onExit={() => { setLoader(false) }}  >
                                <PropagateLoader color="#004fa4" loading={true}
                                    css={override}
                                    size={15} />
                            </Collapse>
                        </GridItem>

                        {!["", undefined, null].includes(shuftiData.cprFrontPage) ? (
                            <GridItem xs={12} sm={4}>
                                <img
                                    onClick={() => {
                                        selectDocument(shuftiData.cprFrontPage);
                                    }}
                                    src={shuftiData.cprFrontPage}
                                    alt="..."
                                    className={`${classes.imgRaised} ${classes.imgRounded} ${classes.imgFluid}`}
                                    loading="lazy"
                                />
                                <br />
                                <div style={{ textAlign: "center" }}>
                                    <a
                                        href={shuftiData.cprFrontPage}
                                        download="cprFrontPage.jpg"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Button size="md" simple color="primary">
                                            <DownloadIcon />
                                        </Button>
                                    </a>
                                </div>
                            </GridItem>
                        ) : null}

                        {!["", undefined, null].includes(shuftiData.cprBackPage) ? (
                            <GridItem xs={12} sm={4}>
                                <img
                                    src={shuftiData.cprBackPage}
                                    alt="..."
                                    onClick={() => { selectDocument(shuftiData.cprBackPage) }}
                                    loading="lazy"
                                    className={classes.imgRaised + " " + classes.imgRounded + " " + classes.imgFluid}
                                />
                                <br />
                                <div style={{ textAlign: "center" }}>
                                    <a
                                        href={shuftiData.cprBackPage}
                                        download="cprBackPage.jpg"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Button size="md" simple color="primary">
                                            <DownloadIcon />
                                        </Button>
                                    </a>
                                </div>
                            </GridItem>
                        ) : null}

                        {!["", undefined, null].includes(shuftiData.passportFrontPage) ? (<>

                            <GridItem xs={12} sm={4}>
                                <img
                                    onClick={() => { selectDocument(shuftiData.passportFrontPage) }}
                                    loading="lazy"
                                    src={shuftiData.passportFrontPage}
                                    alt="..."
                                    className={classes.imgRaised + " " + classes.imgRounded + " " + classes.imgFluid}
                                />
                                <br />
                                <div style={{ textAlign: "center" }}>
                                    <a
                                        href={shuftiData.passportFrontPage}
                                        download="passportFrontPage.jpg"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Button size="md" simple color="primary">
                                            <DownloadIcon />
                                        </Button>
                                    </a>
                                </div>
                            </GridItem></>
                        ) : null}

                        {!["", undefined, null].includes(shuftiData.passportBackPage) ? (<>

                            <GridItem xs={12} sm={4}>
                                <img
                                    onClick={() => { selectDocument(shuftiData.passportBackPage) }}
                                    loading="lazy"
                                    src={shuftiData.passportBackPage}
                                    alt="..."
                                    className={classes.imgRaised + " " + classes.imgRounded + " " + classes.imgFluid}
                                />
                                <br />
                                <div style={{ textAlign: "center" }}>
                                    <a
                                        href={shuftiData.passportBackPage}
                                        download="passportBackPage.jpg"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Button size="md" simple color="primary">
                                            <DownloadIcon />
                                        </Button>
                                    </a>
                                </div>
                            </GridItem></>
                        ) : null}

                        {!["", undefined, null].includes(shuftiData.personPhotoImage) ? (<>
                            <GridItem xs={12} sm={4}>
                                <img
                                    onClick={() => { selectDocument(shuftiData.personPhotoImage) }}
                                    loading="lazy"
                                    src={shuftiData.personPhotoImage}
                                    alt="..."
                                    className={classes.imgRaised + " " + classes.imgRounded + " " + classes.imgFluid}
                                />
                                <br />
                                <div style={{ textAlign: "center" }}>
                                    <a
                                        href={shuftiData.personPhotoImage}
                                        download="consentDocument.jpg"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Button size="md" simple color="primary">
                                            <DownloadIcon />
                                        </Button>
                                    </a>
                                </div>
                            </GridItem></>
                        ) : null}

                        {!["", undefined, null].includes(shuftiData.consentDocument) ? (<>

                            <GridItem xs={12} sm={4}>
                                <img
                                    onClick={() => { selectDocument(shuftiData.consentDocument) }}
                                    loading="lazy"
                                    src={shuftiData.consentDocument}
                                    alt="..."
                                    className={classes.imgRaised + " " + classes.imgRounded + " " + classes.imgFluid}
                                />
                                <br />
                                <div style={{ textAlign: "center" }}>
                                    <a
                                        href={shuftiData.consentDocument}
                                        download="consentDocument.jpg"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Button size="md" simple color="primary">
                                            <DownloadIcon />
                                        </Button>
                                    </a>
                                </div>
                            </GridItem></>
                        ) : null}

                        <GridItem xs={12} sm={12}>
                            <div style={{ textAlign: "center" }}>
                                <Button onClick={closeDetails} size="md" simple color="primary"> <ArrowBackIcon />Back </Button>
                            </div>

                        </GridItem>
                    </>
                ) : (
                    <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                )}
            </GridContainer>
            {
                openImageViewer && (
                    <Lightbox
                        //    reactModalStyle={customStyles}
                        image={documentViewerData}
                        onClose={() => setOpenImageViewer(false)}
                    />
                )
            }
        </>
    );
}