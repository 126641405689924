import React from "react";
import config from "config.js"
import MUIDataTable from "mui-datatables";
import axios from "axios";
import PropagateLoader from "react-spinners/PropagateLoader";
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/core";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import Collapse from "@material-ui/core/Collapse";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import InputBase from "@material-ui/core/InputBase";
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import PeopleIcon from '@material-ui/icons/People';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";

const styles = (theme) => ({
  ...SweetAlertStyle,
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    float: "left",
    display: "inlineBlock",
  },
  addButton: {
    float: "right !important",
    display: "inlineBlock",
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  serviceHead: {
    fontWeight: "400",
    fontSize: "18px",
    marginTop: "0px",
    marginBottom: "0px",
    color: "#144D88",
    // float:"left"
  },
  serviceContainer: {
    maxHeight: "500px",
    overflowY: "auto",
    whiteSpace: "nowrap",
    "&::-webkit-scrollbar-track": {
      width: "8px",
      backgroundColor: "#F5F5F5",
    },
    "&::-webkit-scrollbar": {
      width: "7px",
      backgroundColor: "#F5F5F5",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "8px",
      backgroundColor: "#144D88",
    },
  },
  addServiceContainer: {
    height: "49px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  addServiceButton: {
    marginRight: "-5px !important",
  },
  input: {
    flex: 1,
    height: "45px",
    marginLeft: "15px",
  },
  ...modalStyle(theme),
});

const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;

const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Services() {
  const [respAlert, setRespAlert] = React.useState(null);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(true);
  const [showServicesTable, setShowServicesTable] = React.useState(false);
  const [showGroupsTable, setShowGroupsTable] = React.useState(false);
  const [showAddGroup, setShowAddGroup] = React.useState(false);
  const [showAddService, setShowAddService] = React.useState(false);
  const [risk, setRisk] = React.useState(false);

  const [serviceName, setServiceName] = React.useState("");
  const [services, setServices] = React.useState([]);
  const [servicesCSVData, setServicesCSVData] = React.useState([]);

  const [serviceID, setServiceID] = React.useState("");
  const [selectedServiceType, setSelectedServiceType] = React.useState("");
  const [selectedGroup, setSelectedGroup] = React.useState("");
  const [groups, setGroups] = React.useState([]);

  const [makers, setMakers] = React.useState([]);
  const [checkers, setCheckers] = React.useState([]);
  const [checkersTwo, setCheckersTwo] = React.useState([]);
  const [checkersThree, setCheckersThree] = React.useState([]);
  const [approvers, setApprovers] = React.useState([]);
  const [viewers, setViewers] = React.useState([]);


  const openSnackBar = (msg) => {
    //function to tost snackbar message
    setOpenSnack(true);
    setSnackBarMsg(msg);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  };

  const handleBackClick = () => {
    //function to handle back button click
    setShowGroupsTable(false);
    setShowAddService(false);
    setShowServicesTable(true);
  };

  const handleGroupClick = (id) => {
    setServiceID(id)
    setShowServicesTable(false);
    setShowGroupsTable(true);
  };

  const handleServiceAddClick = (id) => {
    //function to handle service buttin click
    setShowServicesTable(false);
    setShowAddService(true);
  };

  const classes = useStyles();
  const serviceColumns = [
    {
      name: "serviceName",
      label: "Service Name",
    },
    {
      name: "_id",
      label: "ID",
    },
    {
      name: "created_date",
      label: "Date of Creation",
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const serviceOptions = {
    selectableRows: false,
    elevation: 0,
    onDownload: () => {
      // let csvFile = creatCsvFile(csvData);
      // let fileName = "departments.csv";
      // downloadFile(csvFile, fileName);
      return false;
    },
    textLabels: {
      body: {
        noMatch: "No matching records found",
      },
    }
  };

  React.useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = () => {
    //function to fetch services data
    axios
      .get(`${config.url().baseURL}/get-services`, {
        headers: {
          auth: sessionStorage.getItem("cx_id"),
        },
      })
      .then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          console.log(respData.data)
          var updatedData = respData.data.map((service) => {

            service.actions = (
              <>
                <Tooltip placement="top" title="Groups">
                  <IconButton
                    onClick={() => fetchServiceGroups(service._id, false)}
                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                    aria-label="delete">
                    <PeopleIcon />
                  </IconButton>
                </Tooltip>
                {service.serviceName == "Corporate Onboarding" || service.serviceName == "Corporate Edit" || service.serviceName == "Employee" ?
                  (
                    <Tooltip tip placement="top" title="Risk Groups">
                      <IconButton
                        onClick={() => fetchServiceGroups(service._id, true)}
                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                        aria-label="delete">
                        <SupervisedUserCircleIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
              </>
            );
            service.created_date = moment(services.created_date).format("DD/MM/YYYY");
            return service;
          });
          setServices(updatedData);
          setShowLoader(false);
          setShowServicesTable(true);
          var updatedCsvData = respData.data.map((service) => {
            let temp = {};
            temp.Service_Name = service.serviceName;
            temp.Department_ID = service._id;
            temp.Created_On = service.created_date;
            temp.Status = service.status;
            return temp;
          });
          setServicesCSVData(updatedCsvData);
        } else {
          setServices([]);
          setShowLoader(false);
          setShowServicesTable(true);
          openSnackBar(respData.msg);
        }
      })
      .catch((err) => {
        setServices([]);
        setShowLoader(false);
        setShowServicesTable(true);
        openSnackBar("Unable to fetch user departments.");
      });
  };

  const submitService = () => {
    //function to submit department
    if (serviceName.length >= 5) {
      setSubmitting(true);
      axios
        .post(
          `${config.url().baseURL}/create-service`,
          {
            sName: serviceName,
          },
          {
            headers: {
              auth: sessionStorage.getItem("cx_id"),
            },
          }
        )
        .then((resp) => {
          let respData = resp.data;
          if (!respData.err) {
            setRespAlert(
              <SweetAlert
                success
                style={{ display: "block" }}
                title="Service added successfully."
                onConfirm={() => {
                  setShowAddService(false);
                  setShowServicesTable(true);
                  setRespAlert(null);
                  setServiceName([]);
                  fetchServices();
                  setSubmitting(false)
                }}
                confirmBtnCssClass={classes.button + " " + classes.success}
              />
            );
          } else {
            openSnackBar(respData.msg);
            setSubmitting(false);
          }
        })
        .catch((err) => {
          setSubmitting(false);
          openSnackBar("Unable to process.");
        });
    } else {
      setSubmitting(false);
      openSnackBar("Service name must be 5 letters long.");
    }
  };

  const fetchGroups = (serviceType) => {
    //function to fetch groups data & set selected service type
    setSelectedServiceType(serviceType)
    let url = ""
    if (risk) url = `${config.url().baseURL}/get-service-risk-groups/${serviceID}`
    else url = `${config.url().baseURL}/get-service-groups/${serviceID}`
    axios.post(
      url,
      {},
      {
        headers: {
          auth: sessionStorage.getItem("cx_id"),
        },
      }
    ).then((resp) => {
      let respData = resp.data;
      if (!respData.err) {
        setGroups(respData.data)
      } else {
        setGroups([]);
        openSnackBar(respData.msg);
      }
    })
      .catch((err) => {
        alert(err)
        setGroups([]);
        openSnackBar("Unable to fetch user groups.");
      });
  };

  const fetchServiceGroups = (id, riskStatus) => {
    //function to fetch services data
    setServiceID(id)
    setShowServicesTable(false);
    setShowLoader(true)
    setRisk(riskStatus)
    let url = ""
    if (riskStatus) url = `${config.url().baseURL}/get-service-risk-details/${id}`
    else url = `${config.url().baseURL}/get-service-details/${id}`

    axios.get(url, {
      headers: {
        auth: sessionStorage.getItem("cx_id"),
      },
    })
      .then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          let tmpMakers = [], tmpCheckers = [], tmpCheckersTwo = [], tmpCheckersThree = [], tmpApprovers = [], tmpViewers = [];
          // alert(JSON.stringify(resp.data.data[0].group))
          resp.data.data[0].group.map((data) => {
            switch (data.service_type) {
              case "maker":
                tmpMakers.push({
                  name: data.group_id.groupName,
                  id: data.group_id._id,
                  status: data.status
                })

                break;
              case "checker":
                tmpCheckers.push({
                  name: data.group_id.groupName,
                  id: data.group_id._id,
                  status: data.status
                })
                break;
              case "checkerTwo":
                tmpCheckersTwo.push({
                  name: data.group_id.groupName,
                  id: data.group_id._id,
                  status: data.status
                })
                break;
              case "checkerThree":
                tmpCheckersThree.push({
                  name: data.group_id.groupName,
                  id: data.group_id._id,
                  status: data.status
                })
                break;
              case "approver":
                tmpApprovers.push({
                  name: data.group_id.groupName,
                  id: data.group_id._id,
                  status: data.status
                })
                break;
              case "viewer":
                tmpViewers.push({
                  name: data.group_id.groupName,
                  id: data.group_id._id,
                  status: data.status
                })
                break;

              default:
                break;
            }
          });
          setMakers(tmpMakers);
          setCheckers(tmpCheckers);
          setCheckersTwo(tmpCheckersTwo)
          setCheckersThree(tmpCheckersThree)
          setApprovers(tmpApprovers);
          setViewers(tmpViewers);
          setShowLoader(false)
          setShowGroupsTable(true);
        } else {
          setMakers([]);
          setCheckers([]);
          setCheckersTwo([]);
          setCheckersThree([])
          setApprovers([]);
          setViewers([]);
          setShowLoader(false);
          setShowServicesTable(true);
          openSnackBar(respData.msg);
        }
      })
      .catch((err) => {
        setMakers([]);
        setCheckers([]);
        setCheckersTwo([]);
        setCheckersThree([])
        setApprovers([]);
        setViewers([]);
        setShowLoader(false);
        setShowServicesTable(true);
        openSnackBar("Unable to fetch user departments.");
      });
  };

  const addGroup = () => {
    //function to add group to service
    if (selectedGroup !== "") {
      setSubmitting(true);
      let url = ""
      if (risk) url = `${config.url().baseURL}/add-service-risk-group/${serviceID}`
      else url = `${config.url().baseURL}/add-service-group/${serviceID}`
      axios.post(url,
        {
          service_type: selectedServiceType,
          group_id: selectedGroup
        },
        {
          headers: {
            auth: sessionStorage.getItem("cx_id"),
          },
        }
      )
        .then((resp) => {
          let respData = resp.data;
          if (!respData.err) {
            setRespAlert(
              <SweetAlert
                success
                style={{ display: "block" }}
                title="Group added successfully."
                onConfirm={() => {
                  setSubmitting(false)
                  setSelectedGroup("")
                  setSelectedServiceType("")
                  setRespAlert(null)
                  setShowAddGroup(false)
                  setGroups([])
                  fetchServiceGroups(serviceID, risk)

                }}
                confirmBtnCssClass={classes.button + " " + classes.success}
              />
            );
          } else {
            openSnackBar(respData.msg);
            setSubmitting(false);
          }
        })
        .catch((err) => {
          setSubmitting(false);
          openSnackBar("Unable to process.");
        });
    } else {
      setSubmitting(false);
      openSnackBar("Please select all group details.")
    }
  }

  const removeGroupConfirm = (id, serviceType) => {
    //function to alert user with a confirmation for removing group from a service type
    setRespAlert(
      <SweetAlert
        warning

        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          removeGroup(id, serviceType)
        }}
        onCancel={() => {
          setRespAlert(null)
        }}
        showCancel
        confirmBtnText="Yes"
        cancelBtnText="No"
        cancelBtnCssClass={classes.button}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        Do you really want to remove group from {serviceType} ?
      </SweetAlert>)
  }

  const removeGroup = (id, serviceType) => {
    //function to remove group from a service type
    if (!submitting) {
      setSubmitting(true)
      let url = ""
      if (risk) url = `${config.url().baseURL}/remove-service-risk-group/${serviceID}`
      else url = `${config.url().baseURL}/remove-service-group/${serviceID}`
      axios.post(url,
        {
          service_type: serviceType,
          group_id: id
        },
        {
          headers: {
            auth: sessionStorage.getItem("cx_id"),
          },
        }
      )
        .then((resp) => {
          let respData = resp.data;
          if (!respData.err) {
            setRespAlert(null)
            setSubmitting(false);
            fetchServiceGroups(serviceID, risk)
          } else {
            setRespAlert(null)
            openSnackBar(respData.msg);
            setSubmitting(false);
          }
        })
        .catch((err) => {
          setRespAlert(null)
          setSubmitting(false);
          openSnackBar("Unable to process.");
        });
    }
  }


  return (
    <div>
      {respAlert}
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showLoader}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
          </Collapse>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showServicesTable}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Services</h4>
                <Button color="secondary" onClick={handleServiceAddClick} size="sm" className={classes.addButton}>
                  Add Service
                </Button>
              </CardHeader>
              <CardBody>
                <MUIDataTable data={services} columns={serviceColumns} options={serviceOptions} />
              </CardBody>
            </Card>
          </Collapse>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showGroupsTable}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <Card plain>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Services</h4>
                <Button onClick={() => setShowAddGroup(true)} color="secondary" size="sm" className={classes.addButton}>
                  Add Group
                </Button>
              </CardHeader>
              <CardBody>
                <GridContainer  >
                  <GridItem xs={12} sm={12} md={6} lg={3}>
                    <Card>
                      <CardBody>
                        <h6 className={classes.serviceHead}>Makers</h6>
                        <div className={classes.serviceContainer}>
                          <List dense={false}>
                            {makers.map((maker) => {
                              return (
                                <ListItem>
                                  <ListItemText primary={maker.name} />
                                  <ListItemSecondaryAction>
                                    <IconButton onClick={() => removeGroupConfirm(maker.id, "maker")} edge="end" aria-label="delete">
                                      <DeleteIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              )
                            })}
                          </List>
                        </div>
                      </CardBody>
                    </Card>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} lg={3}>
                    <Card>
                      <CardBody>
                        <h6 className={classes.serviceHead}>Checkers</h6>
                        <div className={classes.serviceContainer}>
                          <List dense={false}>
                            {checkers.map((checker) => {
                              return (
                                <ListItem>
                                  <ListItemText primary={checker.name} />
                                  <ListItemSecondaryAction>
                                    <IconButton onClick={() => removeGroupConfirm(checker.id, "checker")} edge="end" aria-label="delete">
                                      <DeleteIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              )
                            })}
                          </List>
                        </div>
                      </CardBody>
                    </Card>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} lg={3}>
                    <Card>
                      <CardBody>
                        <h6 className={classes.serviceHead}>Checkers Two</h6>
                        <div className={classes.serviceContainer}>
                          <List dense={false}>
                            {checkersTwo.map((checker) => {
                              return (
                                <ListItem>
                                  <ListItemText primary={checker.name} />
                                  <ListItemSecondaryAction>
                                    <IconButton onClick={() => removeGroupConfirm(checker.id, "checkerTwo")} edge="end" aria-label="delete">
                                      <DeleteIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              )
                            })}
                          </List>
                        </div>
                      </CardBody>
                    </Card>
                  </GridItem>
                  {checkersThree.length !== 0 ? (
                    <GridItem xs={12} sm={12} md={6} lg={3}>
                      <Card>
                        <CardBody>
                          <h6 className={classes.serviceHead}>Checkers Three</h6>
                          <div className={classes.serviceContainer}>
                            <List dense={false}>
                              {checkersThree.map((checker) => {
                                return (
                                  <ListItem>
                                    <ListItemText primary={checker.name} />
                                    <ListItemSecondaryAction>
                                      <IconButton onClick={() => removeGroupConfirm(checker.id, "checkerThree")} edge="end" aria-label="delete">
                                        <DeleteIcon />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                )
                              })}
                            </List>
                          </div>
                        </CardBody>
                      </Card>
                    </GridItem>
                  ) : null}

                  <GridItem xs={12} sm={12} md={6} lg={3}>
                    <Card>
                      <CardBody>
                        <h6 className={classes.serviceHead}>Approvers</h6>
                        <div className={classes.serviceContainer}>
                          <List dense={false}>
                            {approvers.map((approver) => {
                              return (
                                <ListItem>
                                  <ListItemText primary={approver.name} />
                                  <ListItemSecondaryAction>
                                    <IconButton onClick={() => removeGroupConfirm(approver.id, "approver")} edge="end" aria-label="delete">
                                      <DeleteIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              )
                            })}
                          </List>
                        </div>
                      </CardBody>
                    </Card>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} lg={3}>
                    <Card>
                      <CardBody>
                        <h6 className={classes.serviceHead}>Viewers</h6>
                        <div className={classes.serviceContainer}>
                          <List dense={false}>
                            {viewers.map((viewer) => {
                              return (
                                <ListItem>
                                  <ListItemText primary={viewer.name} />
                                  <ListItemSecondaryAction>
                                    <IconButton onClick={() => removeGroupConfirm(viewer.id, "viewer")} edge="end" aria-label="delete">
                                      <DeleteIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              )
                            })}
                          </List>
                        </div>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Button onClick={handleBackClick} size="lg" className={classes.backButton} simple color="primary">
              <ArrowBackIcon />
              Back
            </Button>
          </Collapse>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Collapse
            in={showAddService}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <h3>Add Service</h3>
            <br />
            <Paper component="form" className={classes.addServiceContainer}>
              <InputBase
                autoFocus={true}
                fullWidth
                className={classes.input}
                placeholder="Service Name"
                inputProps={{
                  "aria-label": "service name",
                  onChange: (event) => {
                    if (event.target.value !== "") {
                      if (event.target.value.match(/^[a-zA-Z ]+$/)) {
                        setServiceName(event.target.value);
                      }
                    } else {
                      setServiceName(event.target.value);
                    }
                  },
                  value: serviceName,
                }}
              />
              {!submitting ? (
                <Button className={classes.addServiceButton} onClick={submitService} size="lg" color="primary">
                  ADD
                </Button>
              ) : (
                <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
              )}
            </Paper>
            <Button
              onClick={() => {
                handleBackClick();
              }}
              size="lg"
              className={classes.backButton}
              simple
              color="primary">
              <ArrowBackIcon />
              Back
            </Button>
          </Collapse>
        </GridItem>
      </GridContainer>
      <Dialog
        classes={{
          root: classes.left + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showAddGroup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setShowAddGroup(false);
          setSelectedGroup("");
          setSelectedServiceType("")
          setGroups([])
        }}>
        <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
          <h4 className={classes.modalTitle}>Add Group</h4>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <Table
            tableHeaderColor="primary"
            tableData={[
              [
                <h6 style={{ fontWeight: 500, fontSize: "16px", marginTop: "0px", marginBottom: "0px" }}>Type</h6>,
                ":",
                <FormControl fullWidth>
                  <Select disableUnderline value={selectedServiceType} onChange={(e) => fetchGroups(e.target.value)} style={{ textAlign: "left" }} id="department-select">
                    <MenuItem value={"maker"}>Maker</MenuItem>
                    <MenuItem value={"checker"}>Checker</MenuItem>
                    <MenuItem value={"checkerTwo"}>Checker Two</MenuItem>
                    <MenuItem value={"checkerThree"}>Checker Three</MenuItem>
                    <MenuItem value={"approver"}>Approver</MenuItem>
                    <MenuItem value={"viewer"}>Viewer</MenuItem>
                  </Select>
                </FormControl>,
              ],
              [
                <h6 style={{ fontWeight: 500, fontSize: "16px", marginTop: "0px", marginBottom: "0px" }}>Group</h6>,
                ":",
                <FormControl fullWidth>
                  <Select value={selectedGroup} disabled={groups.length == 0} onChange={(e) => setSelectedGroup(e.target.value)} disableUnderline style={{ textAlign: "left" }} id="department-select">
                    {groups.map((group) => {
                      return <MenuItem value={group._id}>{group.groupName}</MenuItem>;
                    })}
                  </Select>
                </FormControl>,
              ],

            ]}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => {
              setShowAddGroup(false);
              setSelectedGroup("");
              setSelectedServiceType("")
              setGroups([])
            }}
            color="danger"
            simple
            size="sm">
            Close
          </Button>
          <Button
            onClick={() => {
              addGroup();
            }}
            color="primary"
            size="sm">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
            setSnackBarMsg("");
          }}
          severity="error">
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
